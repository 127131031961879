import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Demonstrations from '../../pages/demonstrations/Demonstrations';
import { getApp } from '../../store/slices/app.slice';
import productDemoSlice from '../../store/slices/products-demo.slice';
import productsSlice, { getProducts } from '../../store/slices/products.slice';
import DemoDownloadType from '../../types/product-download.type';
import useBEM from '../../utils/hooks/useBEM';
import useTranslation from '../../utils/hooks/useTranslation';
import Modal from '../Modal';
import Button from '../ui/Button';
import ProductSelection from './components/ProductSelection';
// JUMP
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import variluxG9Videos from '../../utils/varilux-g9-videos'
import Utils from '../../utils/Utils';

interface Props {

}

const Products : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('products');
    const { isOpen, view, lensDemo, downloadStatus, downloadCountText } = useSelector(getProducts);
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));
    const { get, insert } = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const { downloadAssetBase64 } = Utils()

    const modalStyle = {
        width: "100%",
        height: "69.8rem",
        borderRadius: "15px"
    }


    // HOOKS
    // JUMP DOWNLOAD VARILUX ASSET HERE!
    useEffect(() => {      
        downloadXRAssets()  
        dispatch(productsSlice.actions.attachedProductDemo({
            demos: lensDemo
        }))
    }, [])


    useEffect(() => {
        if(view === 'selection'){
            dispatch(productDemoSlice.actions.setActiveProductDemo(undefined));
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(undefined));
            dispatch(productDemoSlice.actions.changeDemoType(undefined));
            dispatch(productsSlice.actions.selectBrandTodemo(undefined))
        }
    }, [view])

    // METHODS
    const closeProducts = () => {
        dispatch(productsSlice.actions.reset());
    }

    const back = () => {
        dispatch(productsSlice.actions.changeView('selection'));
        dispatch(productsSlice.actions.clearSelectedProduct())
    }

    const isDownloading = () => {
        return downloadStatus === DemoDownloadType.DOWNLOADING;
    }

    const downloadCount = () => {
        return `${t('demo_downloading')} ${downloadCountText}`;
    }

    // JUMP
    const isXRDemoAlreadyPreloaded = async (category: string, i: number) => {
        const id = `${category}_${i}`;
        const asset = await get('variluxG9Store', id);
        return asset ? true : false;
    }

    const downloadXRAssets = () => {
        for (const category of variluxG9Videos) {
            category?.videos?.map(async (asset, i) => {
                const isXRAlreadyDownloaded = await isXRDemoAlreadyPreloaded(category.directory, i);

                if (!isXRAlreadyDownloaded) {
                    const base64 = await downloadAssetBase64(asset)
                    await insert('variluxG9Store', {
                        id: `${category.directory}_${i}`,
                        asset: base64,
                    });
                } else {
                }
            })
        }
    }
    // JUMP


    return (
        <Modal 
            style={modalStyle}
            noPadding={true}
            show={isOpen}
            onClose={closeProducts}  
            hideCloseButton={isDownloading()}
            closeModalOnOutsideClick={!isDownloading()}
            rightHeaderDetail={downloadCount()}
        >
            <div className={B()}>
                
                <div className={E('head')}>
                    {
                        view === 'demo' &&
                        <Button
                            outlineType="light"
                            text={t('demo_demo__back')} 
                            icon="left-arrow-circle-gray" 
                            iconPosition="left" type="secondary" 
                            click={back}
                        />
                    }
                    
                    { view === 'selection' && <h4>{t('products_prod_products')}</h4> }                    
                </div>

                { view === 'selection' &&  <ProductSelection />}                            
                { view === 'demo' &&  <Demonstrations type="modal"/>}                            
                
            </div>
        </Modal>
    )
}

export default Products;