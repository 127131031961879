import React from 'react';

import { Provider } from 'react-redux';
import { backgroundPerRow, roundCorner } from '../../constants/email-css.constants';
import iAppStore from '../../store/interface/app-store.interface';
import store from '../../store/store';
import useTranslation from '../../utils/hooks/useTranslation';
import Footer from './components/Footer';
import Header from './components/Header';
import SummaryDetails from './components/SummaryDetails';
interface Props {
    summaryList1: any;
    summaryList2: any;
    summaryNotes: any;
    appStore: iAppStore
}

const SummaryTemplate: React.FC<Props> = ({ summaryList1, summaryList2, appStore, summaryNotes }) => {
    //VARIABLES
    const { t } = useTranslation(appStore);
    //HOOKS
    //METHOD
    const onNull = (list: any) => {
        if (!list) return false;
        const arr = list.filter((el: any) => el !== null);
        // const condition = arr.length !== 0 && arr !== undefined         
        // ? true : false;
        return arr && arr.length > 0;
    }
    return (
        <Provider store={store} >
            <div style={{ backgroundColor: "#d9edf9" }}>
                <div style={{ backgroundColor: "#d9edf9" }}><br></br></div>
                <div style={{ backgroundColor: "#d9edf9" }}><br></br></div>
                {/* rounded corners */}
                <div style={{ paddingRight: "0px", paddingLeft: "0px", textAlign: "center" }}>
                    <img src="https://i.imgur.com/qpK2SF8.png" alt="" style={roundCorner} width="650" />
                    <div style={backgroundPerRow}>
                        <Header title={t('email__hello')} description={t("email__y_lens_recomm")}></Header>
                    </div>
                    {onNull(summaryList1) && <SummaryDetails data={summaryList1} pair={1}></SummaryDetails>}
                    {onNull(summaryList2) && <SummaryDetails data={summaryList2} pair={2}></SummaryDetails>}
                    <Footer title={t('summary_sum_notes')} iconDisplay={true} text={summaryNotes}></Footer>
                </div>
            </div>
        </Provider>
    )
}
export default SummaryTemplate;