import iLensDemo from "../../interface/lens-demo";
import ProductDemoType from "../../types/product-demo.type";
import Utils from "../../utils/Utils";
const { lensDemoURL } = Utils();

const bedroom: iLensDemo = {
    demo: 'bedroom',
    demoType: ProductDemoType.SLIDER_IMAGE,
    compareByDefault: true,
    hideCenterButton: true,
    hideCompareButton: true,
    withSellout: true,
    // Note: Correct demo
    products:[
        {
            id: 'stellest',
            label: 'Stellest',
            asset: lensDemoURL('Stellest/indoor_correct.png'),
            description: [
                'demo_new_sellout_myopia1',
                'demo_new_sellout_myopia2',
                'demo_new_sellout_myopia3'
            ]
        },
        {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: lensDemoURL('Stellest/indoor_correct.png'),
        }
    ],

    control: {
        demo: 'bedroom-control',
        parent: 'bedroom',
        demoType: ProductDemoType.SLIDER_IMAGE,
        compareByDefault: true,
        hideCompareButton: true,
        hideCenterButton: true,
        label: 'demo_stellest_now',
        withSellout: true,
        instruction: 'demo_stellest_nocontrol',

        children: [
            {
                demo: 'bedroom-control-child',
                parent: 'bedroom',
                demoType: ProductDemoType.SLIDER_IMAGE,
                label: 'demo_stellest_+2yrs',
                compareByDefault: true,
                hideCompareButton: true,
                hideCenterButton: true,
                withSellout: true,

                products: [
                    {
                        id: 'stellest',
                        label: 'Stellest',
                        asset: lensDemoURL('Stellest/indoor_control_2years-right.png'),                        
                        description: [
                            'demo_new_sellout_myopia1',
                            'demo_new_sellout_myopia2',
                            'demo_new_sellout_myopia3'
                        ]
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: lensDemoURL('Stellest/indoor_control_2years-left.png'),
                    }
                ]
            }
        ],

        products: [
            {
                id: 'stellest',
                label: 'Stellest',
                asset: lensDemoURL('Stellest/indoor_control_now.png'),
                description: [
                    'demo_new_sellout_myopia1',
                    'demo_new_sellout_myopia2',
                    'demo_new_sellout_myopia3'
                ],

            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_standard',
                asset: lensDemoURL('Stellest/indoor_control_now.png'),
            }
        ],
    },
    children: [
        {
            demo: 'vertical-compare',
            parent: 'bedroom',
            demoType: ProductDemoType.VERTICAL_SLIDER_VIDEO,
            label: 'demo_stellest_myopia',
            compareByDefault: true,
            hideCompareButton: true,
            hideCenterButton: true,
            withSellout: true,

            products: [
              {
                id: 'stellest',
                label: 'Stellest',
                description: [
                    'demo_new_sellout_myopia_prog1',
                    'demo_new_sellout_myopia_prog2',
                    'demo_new_sellout_myopia_prog3',
                    'demo_new_sellout_myopia_prog4'
                ],
              },
              {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_standard',
                asset: lensDemoURL('Stellest/sidebyside-3D.mp4'),
              }
            ]
        }
    ]
}

const garage: iLensDemo = {
    demo: 'garage',
    demoType: ProductDemoType.SLIDER_IMAGE,
    compareByDefault: true,
    hideCenterButton: true,
    hideCompareButton: true,
    withSellout: true,
    // Note: Correct demo
    products:[
        {
            id: 'stellest',
            label: 'Stellest',
            asset: lensDemoURL('Stellest/outdoor_correct.png'),
            description: [
                'demo_new_sellout_myopia1',
                'demo_new_sellout_myopia2',
                'demo_new_sellout_myopia3'
            ]
        },
        {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: lensDemoURL('Stellest/outdoor_correct.png'),
        }
    ],

    control: {
        demo: 'garage-control',
        parent: 'garage',
        demoType: ProductDemoType.SLIDER_IMAGE,
        compareByDefault: true,
        hideCompareButton: true,
        hideCenterButton: true,
        label: 'demo_stellest_now',
        withSellout: true,
        instruction: 'demo_stellest_nocontrol',

        children: [
            {
                demo: 'garage-control-child',
                parent: 'garage',
                demoType: ProductDemoType.SLIDER_IMAGE,
                label: 'demo_stellest_+2yrs',
                compareByDefault: true,
                hideCompareButton: true,
                hideCenterButton: true,
                withSellout: true,

                products: [
                    {
                        id: 'stellest',
                        label: 'Stellest',
                        asset: lensDemoURL('Stellest/outdoor_control_2years-right.png'),                        
                        description: [
                            'demo_new_sellout_myopia1',
                            'demo_new_sellout_myopia2',
                            'demo_new_sellout_myopia3'
                        ]
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: lensDemoURL('Stellest/outdoor_control_2years-left.png'),
                    }
                ]
            }
        ],

        products: [
            {
                id: 'stellest',
                label: 'Stellest',
                asset: lensDemoURL('Stellest/outdoor_control-now.png'),
                description: [
                    'demo_new_sellout_myopia1',
                    'demo_new_sellout_myopia2',
                    'demo_new_sellout_myopia3'
                ],

            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_standard',
                asset: lensDemoURL('Stellest/outdoor_control-now.png'),
            }
        ],
    },
    children: [
        {
            demo: 'vertical-compare',
            parent: 'garage',
            demoType: ProductDemoType.VERTICAL_SLIDER_VIDEO,
            label: 'demo_stellest_myopia',
            compareByDefault: true,
            hideCompareButton: true,
            hideCenterButton: true,
            withSellout: true,

            products: [
              {
                id: 'stellest',
                label: 'Stellest',
                description: [
                    'demo_new_sellout_myopia_prog1',
                    'demo_new_sellout_myopia_prog2',
                    'demo_new_sellout_myopia_prog3',
                    'demo_new_sellout_myopia_prog4'
                ],
              },
              {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_standard',
                asset: lensDemoURL('Stellest/sidebyside-3D.mp4'),
              }
            ]
        }
    ]
}

export const stellestDemo: iLensDemo[] = [
    bedroom,
    garage
];

