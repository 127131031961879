import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import useBEM from '../../../utils/hooks/useBEM';
import Icon from '../../ui/Icon';
import productDemoSlice, { getProductDemo } from '../../../store/slices/products-demo.slice';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import iProduct from '../../../interface/product.inteface';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';

interface Props {
    products: iProduct[],
    use?: 'homepage' | 'default'
}

const SubProducts : React.FC<Props>  = ({
    products,
    use = 'default'
}) =>  {
    // VARIABLES
    const dispatch = useDispatch();
    const [B, E] = useBEM('sub-products');
    const ga = useGoogleAnalytics();
    const { t } = useTranslation(useSelector(getApp));
    const { accountDetails } = useSelector(getApp);
    const  { openBrand } = useSelector(getProducts);
    const isSlovakia = accountDetails?.shop.country === 'sk'
    const gtmId = use === 'default' ? '' : '_hs'
    // HOOKS

    // METHODS
    const demoProduct = (product: iProduct) => {
        if(!product.demo || product.demo?.length < 1)return;
        // ga.event({
        //     category: `Demo - ${product.label}`, 
        //     action: 'Demonstration - Product',
        //     label: `See Demo - ${product.demo[0].demo}`
        // });

        ga.event({
            category: `Demo`, 
            action: product.label
        });
        const path = {
            id: '',
            path: '',
            label: ''
        }
        dispatch(productsSlice.actions.open(true))
        dispatch(productDemoSlice.actions.setDemoFrom(path))
        dispatch(productsSlice.actions.selectProductTodemo(product))
        dispatch(productsSlice.actions.changeView('demo'));
    }

    // const onClose = () => {
    //     dispatch(productsSlice.actions.selectBrandTodemo(undefined));
    // }

    const hasVirtualTryOn = () =>{
        return openBrand === 'transitions'
    }

    const openQRCode = () =>{
        const id = 'transitions_signature_gen_8'
        const videoBackground = products.filter(product => product.id === id)[0].demo
        const product = products.filter(product => product.id === id)
        dispatch(productsSlice.actions.selectProductTodemo(product[0]))
        dispatch(productsSlice.actions.openQrCode(true))
    }
    
    const indiaBusinessRule = (id:string) => {
        const indiaHideProducts = [
            'transitions_signature_gen_8_iconic',
            'transitions_signature_gen_8_style',
            'transitions_signature_gen_8_classic',
            'transitions_xtractive_mirror',
            'transitions_classic'
        ]
        if(accountDetails?.shop.country === 'in'){
            return indiaHideProducts.includes(id)
        }
    }


    return (
        <div className={B()}>
            
            {/* <div className={E('head')} onClick={onClose}>
                <h5>{title}</h5>
                <Arrow type="down" color="#fff"/>
            </div> */}

            <ul className={E('list')}>
                {
                    products.map((p: iProduct, index) => {
                        if(!p.enabled || !p.demo || p.demo.length < 1 || indiaBusinessRule(p.id))return null;
                        return (
                            <li key={index} onClick={() => demoProduct(p)} id={`${p.id}${gtmId}`}>
                                {t(p.label)}
                                <Icon name="eye-blue"/>
                            </li>
                        )
                    })
                }
                {/* {
                    hasVirtualTryOn() && !isSlovakia &&           
                <li onClick={openQRCode} id='vto_GTM1'>
                        {t('vto_transitions_label')}
                    <div className={E('new-QrCode')}>
                        <span>
                            {t('vto_new')}
                        </span>
                    </div>
                    <Icon name="qrcode"/>
                </li>
                } */}


            </ul>
        </div>
    )
}

export default SubProducts;