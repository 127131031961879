import axios, { AxiosResponse } from 'axios'
import ENDPOINTS from '../../constants/endpoints'

export const getFrametraceList = async (
  countryCode: string, shopId:string
) => {
  const endpoint = ENDPOINTS.frametraces(countryCode,shopId)

  return axios.get(endpoint).then((res: AxiosResponse) => res.data)
}

export const getFrametrace = async (
  id: string
) => {
  const endpoint = ENDPOINTS.frametrace(id)

  return axios.get(endpoint).then((res: AxiosResponse) => res.data)
}

export const deleteFrametrace = async (
  id: string
) => {
  const endpoint = ENDPOINTS.frametrace(id)

  return axios.delete(endpoint).then((res: AxiosResponse) => res.data)
}
