
import React, {useEffect,useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import iTranslation from '../../../interface/translation.interface';
import  appSlice, { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Modal from '../../Modal';
import Button from '../../ui/Button';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import { TRANSLATIONS_MULTIPLE_LANG } from '../../../constants/translations.contants';
import { elacES, elacEN } from '../../../constants/language-code.constants';


interface Props {

}

const Language : React.FC<Props>  = () =>  {

    // VARIABLES
    // const { translations  } = useSelector(getApp);
    const [translations, setTranslations] = useState<any>(undefined);

    const { firstLogin } = useSelector(getApp);
    const [B, E] = useBEM('first-login');
    const dispatch = useDispatch();
    const { t, generateIconImage, sortCountries } = useTranslation(useSelector(getApp));
    const [showMultiple, setShowMultiple] = useState(false);
    const [listLanguage, setlistLanguage] = useState<iTranslation>();
    const { 
        subLanguage, 
        translationRegion, 
        translationCountry, 
        translation,
        translationCountryLanguage
    } = useSelector(getApp);
    const [selectedMLanguage, setSelectedMLanguage] = useState<{country: any, code: any}>();
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;



    const popupStyle = {
        width: "50rem",
        height: "30rem",
        borderRadius: "15px"
    }
    


    // HOOKS

    useEffect(() => {
        const getTranslations = async () => {
            const translationData = await get('languageStore', 'translations');
            setTranslations(translationData.value);
        }
        getTranslations()
     }, [firstLogin?.selectedLanguage]);


    const isMultiLanguage = (code: string) => {
        const keys = Object.keys(TRANSLATIONS_MULTIPLE_LANG);
        const isMulti = keys.includes(code);

        return {
            condition: isMulti,
            data: TRANSLATIONS_MULTIPLE_LANG[code]
        };
    }

    const onSubLanguageSelect = (code:any) =>{
        setSelectedMLanguage({country: firstLogin?.selectedLanguage, code: code});
        if(firstLogin?.selectedLanguage)dispatch(appSlice.actions.selectedSubLanguage({code, country: firstLogin?.selectedLanguage }));

        dispatch(appSlice.actions.setTranslation(translations[code]));
        setTimeout(() => {
            modalOnClose();
        }, 500);
    }

    

    const onLanguageSelect = (id: string, country:any) => {
        const  languages = translations[id].language;

        const availableTranslations = Object.keys(languages).filter( x => {
            return languages[x] != null;
        });

        dispatch(appSlice.actions.setTranslationCountry({
            id,
            ...country
        }))

        //Note: multiple language
        if(availableTranslations.length > 1){
            dispatch(appSlice.actions.setTranslationCountryLanguage(undefined));
            setShowMultiple(true);
        } else {
            dispatch(appSlice.actions.setTranslation({
                code: availableTranslations[0],
                country: country.label,
                translation: languages[availableTranslations[0]]
            }));
            dispatch(appSlice.actions.setTranslationCountryLanguage({
                country: id,
                languageCode: availableTranslations[0]
            }));
        }    
    }

    const modalOnClose = () => {
        setShowMultiple(false);
    }

    const highlightButton = (countryCode:any, keyCode:any) =>{
        if(selectedMLanguage?.country === countryCode &&
        keyCode === selectedMLanguage?.code) return true;
        return false;
    }

    const isTranslationsAvailable = (key: string, languages: string[]) => {
        const translation = translations[key];
        let available = false;
        if(translation != undefined) {
            const avalalableTranslationKeys: string[] = [];

            Object.keys(translation.language).forEach( x => {
                if(translation.language[x] !== null) avalalableTranslationKeys.push(x);
            })

            const foundTranslationCount = languages.filter(val => avalalableTranslationKeys.includes(val)).length;
            available = foundTranslationCount > 0;
        } 

        return available;
    }

    const multipleLanguageSelectedClass = (countryId: string, languageCode: string) => {
        return (
            translationCountryLanguage?.languageCode === languageCode &&
            translationCountryLanguage?.country === countryId
        ) ? 'selected' : '';
    }

    const selectCountryLanguage = (key: number) => {
        if(!translationCountry)return;
        const code = translationCountry?.language[key].id;
        const country = translations[translationCountry.id];
        
        dispatch(appSlice.actions.setTranslation({
            code,
            country: country.country,
            translation: country.language[code]
        }));
        
        dispatch(appSlice.actions.setTranslationCountryLanguage({
            country: translationCountry.id,
            languageCode: code
        }));
        setTimeout( x => setShowMultiple(false), 250)
    }

    return <>
            <ul className={E('body list-language')}>
                { translations && translationRegion && sortCountries(translationRegion?.country)
                    .map(([key, value]: any[])=>{
                    return(
                            <li key={key}>

                                <Button
                                    disabled={!isTranslationsAvailable(key, value.language.map( (x: any) => x.id))}
                                    text={value.label} 
                                    type="secondary" weight="normal"
                                    icon={generateIconImage(value.label)} 
                                    className={"country-btn "+ (translationCountry?.id === key ? ' selected' : '')}
                                    click={()=>onLanguageSelect(key, value)}
                                    iconPosition="left"
                                    outlineType="light"
                                />
                            </li>
                    )
                }) }
            </ul>
            <Modal show={showMultiple} style={popupStyle} className={B()}  onClose={modalOnClose} closeModalOnOutsideClick = {false}>
                <div className={E('modal')}>
                    <div className={E('header')}>
                        <h3>{t('language_lang_select_your_language')}</h3>
                    </div>
                    <div className={E('body multiple')}>
                        <ul>
                            { 
                                translationCountry && 
                                translationCountry?.language && 
                                Object.keys(translationCountry?.language).map((key:any, i:number) => (
                                    <li key={i}>
                                        <Button
                                            text={translationCountry?.language[key].label}       
                                            type="secondary" weight="normal"
                                            iconPosition="left"
                                            outlineType="light"
                                            className={"country-btn " + multipleLanguageSelectedClass(
                                                translationCountry.id, 
                                                translationCountry?.language[key].id
                                            )} 
                                            click={() => selectCountryLanguage(key)}
                                        />
                                    </li>
                                )) 
                            }
                        </ul> 
                        
                    </div>
                </div>
            </Modal>
    </>
}

export default Language;