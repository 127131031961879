import React from 'react';

interface Props {
    label?: string,
    checked?: boolean
}

const Checkbox : React.FC<Props>  = ({
    label,
    checked = false
}) =>  {
    // VARIABLES

    // HOOKS

    // METHODS

    return (
        <div className={`checkbox ${checked ? 'checkbox--checked' : ''}`}>
            {
                label && <label>{label}</label>
            }
        </div>
    )
}

export default Checkbox;