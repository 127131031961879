import React, { useEffect, useState } from 'react';
import Icon from '../../components/ui/Icon';
import Button from '../../components/ui/Button';
import ContinueWithID from './components/ContinueWithID';
import Modal from '../../components/Modal';
import InputText from '../../components/ui/InputText';
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { getUser } from '../../store/slices/user.slice';
import useBEM from '../../utils/hooks/useBEM';
import routes from '../../constants/routes.constant';
import HomeStart from './components/HomeStart';
import useGoto from '../../utils/hooks/useGoto';
import CircleArrow from '../../components/ui/CircleArrow';
import needExplorerSlice from '../../store/slices/need-explorer.slice';
import appSlice, { getApp } from '../../store/slices/app.slice';
import { KIDS_THEMES } from '../../constants/theme.constants';
import Utils from '../../utils/Utils';
import lensThicknessSlice from '../../store/slices/lens-thickness.slice';
import lensConsultationSlice from '../../store/slices/lens-consultation.slice';
import ReactHtmlParser from 'react-html-parser';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../utils/hooks/useTranslation';
import measureSlice from '../../store/slices/measure.slice';
import useENV from '../../utils/hooks/useEnv';
import summarySlice from '../../store/slices/summary.slice';
import axios, { AxiosResponse } from 'axios';
import ENDPOINTS from '../../constants/endpoints';
import settings from '../../constants/settings.constants';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import deliverySlice, { getDelivery } from '../../store/slices/delivery.slice';
import iUserStore from '../../store/interface/user-store.interface';
import pushNotificationSlice, { getPushNotification } from '../../store/slices/push-notification.slice';
import BadgeNotification from '../../components/ui/BadgeNotification';
import PatientListModal from './components/PatientListModal';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import Alert from '../../components/ui/Alert';
import ExperienceType from '../../types/experience.type';
import ProductSelectionHome from './components/ProductSelectionHome';

interface Props {

}

const HomePage : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.home.id);
    const env = useENV();
    const goto = useGoto();
    const [isStart, setStart] = useState(false);
    const [showStartFromIDModal, setShowStartFromIDModal] = useState(false);
    const [showPatientListModal, setShowPatientListModal] = useState(false)
    const dispatch = useDispatch();
    const [startFromIDField, setStartFromIDField] = useState<string>();
    const { id, ageCategory, lensConsultation, sessionStart } = useSelector(getUser);
    const appStore = useSelector(getApp);
    const { generateRandomNumbers, getThemeVariables, getDateTimeDifference} = Utils();
    const themes = KIDS_THEMES;
    const ga = useGoogleAnalytics();
    const { t } = useTranslation(appStore);
    const { accountDetails, licenseValidationDate, firstLogin, theme, dataRetention } = appStore;
    const environment = settings.environment;
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const { validatedTimestamp } = useSelector(getDelivery);
    const { whatsNewHasNewUpdate, eMatHasNewUpdate, notificationEvent } = useSelector(getPushNotification);
    const country = accountDetails?.shop?.country
	
	// JUMP
    if (localStorage.getItem("country") === null) {
        if (country) localStorage.setItem("country", JSON.stringify(country))
    }

    const { db } = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const isNordicsCountries = country && ['dk', 'se', 'fi', 'no', 'be', 'ph', 'in', 'in2',].includes(country)
    const enableVarilux = country && ['ro','bg','de','pt','gb','be','es', 'si', 'hr', 'gr', 'ph'].includes(country)
    // const cards = country && isNordicsCountries ? [
    //     { icon: 'discovery', iconType: 'svg', label: `${t('home_home_disc_essilor')} <sup>®</sup>`, labelKey: 'home_home_disc_essilor', route: routes.discovery }
    // ]:[
    //     { icon: 'mic', iconType: 'svg', label: t('home_hom_whats_new'), labelKey: 'home_hom_whats_new', route: routes.whatsNew },
    //     { icon: 'emat', iconType: 'svg', label: `${t('home_hommemat')} <sup>™</sup>`, labelKey: 'home_hommemat', route: routes.emat },
    //     { icon: 'discovery', iconType: 'svg', label: `${t('home_home_disc_essilor')} <sup>®</sup>`, labelKey: 'home_home_disc_essilor', route: routes.discovery }
    // ];

    const cards = () => {
        if(country){
            if(isNordicsCountries && !enableVarilux){
                return [
                    { icon: 'discovery', iconType: 'svg', label: `${t('home_home_disc_essilor')} <sup>®</sup>`, labelKey: 'home_home_disc_essilor', route: routes.discovery }
                ]
            }
            if(isNordicsCountries && enableVarilux){
                return []
            }
            if(!isNordicsCountries && !enableVarilux){
                return [
                    { icon: 'mic', iconType: 'svg', label: t('home_hom_whats_new'), labelKey: 'home_hom_whats_new', route: routes.whatsNew },
                    { icon: 'emat', iconType: 'svg', label: `${t('home_hommemat')} <sup>™</sup>`, labelKey: 'home_hommemat', route: routes.emat },
                    { icon: 'discovery', iconType: 'svg', label: `${t('home_home_disc_essilor')} <sup>®</sup>`, labelKey: 'home_home_disc_essilor', route: routes.discovery }
                ]
            }
            if(!isNordicsCountries && enableVarilux){
                return [
                    { icon: 'mic', iconType: 'svg', label: t('home_hom_whats_new'), labelKey: 'home_hom_whats_new', route: routes.whatsNew },
                    { icon: 'emat', iconType: 'svg', label: `${t('home_hommemat')} <sup>™</sup>`, labelKey: 'home_hommemat', route: routes.emat },
                ]
            }
        }
        return []
    }
    
    const { queryByDateRange, remove } = useIndexedDB();
    const [showAlert, setShowAlert] = useState(false);
    const [serverDateError, setServerDateError] = useState("")

    

    // HOOKS
    useEffect(() => {
        validateLicense();
        validateFirstLogin();

        const currentTimeStamp = new Date();

        if(lensConsultation.isValidated && sessionStart){
            dispatch(userSlice.actions.sessionStart(undefined));
            const timediff = getDateTimeDifference(new Date(sessionStart), currentTimeStamp);
            ga.event({
                category: 'Patient Journey',
                action: 'Engagement - LC',
                label: timediff
            });
        }

        if(validatedTimestamp){
            dispatch(deliverySlice.actions.clearValidatedTimestamp());
            const timediff = getDateTimeDifference(new Date(validatedTimestamp), currentTimeStamp);
            ga.event({
                category: 'Patient Journey',
                action: 'Engagement - Delivery',
                label: timediff
            });
        }

        dataRetentionProcess();
    }, [])

    useEffect(() => {
        setStartFromIDField(id || '');
    }, [id])

    // METHODS
    const validateFirstLogin = () =>{
        if(!accountDetails)return;
        if(!firstLogin?.firstStatus && accountDetails){
            dispatch(appSlice.actions.setOpenFirstLogin(true));
        }
    }
    const validateLicense = () => {
        if(!accountDetails)return;
        const today = new Date().setHours(0,0,0,0);
        //offline
        if(!navigator.onLine){
            if(!accountDetails.expiration_date)return true;
            const expirationDate = new Date(accountDetails.expiration_date).setHours(0,0,0,0);
            if(today >= expirationDate){
                dispatch(appSlice.actions.updateLicenseStatus('Expired'));
                goto.route(routes.licenseActivation);
            }
            return;
        }

        //online
        if(licenseValidationDate){
            const lastValidationDate = new Date(licenseValidationDate).setHours(0,0,0,0);
            if(lastValidationDate === today)return;
        }

        dispatch(appSlice.actions.setLicenseValidationDate(new Date().toDateString()));
        axios.get(ENDPOINTS.account, { headers: {Authorization: true, background: true} }).then((res) => {
            if(res.data.state !== "Active"){
                goto.route(routes.licenseActivation);
                return;
            }
            dispatch(appSlice.actions.saveAccountDetails(res.data));

        });
    }

    const onSelectStart = (type: string) => {
        ga.event({
            category: 'Home Screen',
            action: 'Button Click',
            label: type
        });

        if(type === "id"){
            setShowStartFromIDModal(true);
        } else if (type === 'patient-list') {
            setShowPatientListModal(true)
        }
    }

    const modalOnClose = () => {
        // setId('');
        setShowStartFromIDModal(false);
        setShowPatientListModal(false)
    }

    const idOnchange = (event: any) => {
        setStartFromIDField(event.target.value);
    }

    // TODO: Create utility function/ or custom hooks
    const transformNeedExplorer = (userData: iUserStore) => {
        let newData = userData.needExplorer;

        // Note: set canSelectMultiple: false for question '6.3' for kids
        // select only one answer
        if(userData.ageCategory?.value === 'kids'){
            newData = {
                ...newData,
                questions: newData.questions.map( q => {
                    if(q.id === '6'){
                        return {
                            ...q,
                            questions: q.questions.map( x => {
                                if(
                                    x.id === '6.3' &&
                                    x.canSelectMultiple &&
                                    x.answer
                                ){
                                    return {
                                        ...x,
                                        answer: (x.answer as any[])[0],
                                        canSelectMultiple: false
                                    }
                                }
                                return x;
                            })
                        }
                    }
                    return q;
                })
            }
        }

        return newData;
    }

    const sentIDManual = async () => {

        const user = await get('userStore',String(startFromIDField));
        if(!startFromIDField || startFromIDField.length < 6 || !user){
            alert('You have entered an invalid  or not existing ID, Please Try Again');
            return;
        }
        dispatch(userSlice.actions.setUserData(user));
        const finalNeedExplorer = transformNeedExplorer(user);
        dispatch(needExplorerSlice.actions.setData(finalNeedExplorer));
        dispatch(lensThicknessSlice.actions.setLensThickness(user.lensThickness))
        dispatch(lensConsultationSlice.actions.saveLensRecommendationDataFromStore(user.lensRecommendation));
        dispatch(measureSlice.actions.saveMeasureFromStore(user.measures));
        dispatch(summarySlice.actions.saveSummary1(user.summaryList1));
        dispatch(summarySlice.actions.saveSummary2(user.summaryList2));
        dispatch(userSlice.actions.sessionStart(new Date().toISOString()));
        modalOnClose();

        goto.routeWithTransition(
            routes.home,
            routes.lensProposalBuilder,
            'SLIDE_LEFT',
            500
        );
    }

    const startHandler = () => {
        ga.event({
            category: 'Home Screen',
            action: 'Button Click',
            label:'Start - LPB'
        });

        setTimeout(() => {
            setStart(true);
        }, 250);
    }
    const gotoRoute = (route: any) => {
        if (!route)return;
        goto.route(route);
    }

    const getAge = () => {
        const age = ageCategory?.value;
        return age ? age : ''
    }

    const themeStyle = () => {
        if(!theme || theme.id !== 'multi')return {};
        const randomNumbers = generateRandomNumbers(themes.length - 1);
        const randomTheme = themes[randomNumbers[0]];
        return getThemeVariables(randomTheme)
    }

    const onCardSelect = (card: any, index: number) => {
        // if(card.icon ==='discovery' && environment === "dev") gotoRoute(card.route);
        // gotoRoute(card.route);
        // if(environment === "uat" && card.icon !=='discovery') {
        //     return;
        // }
        updateNotification(index);
        gotoRoute(card.route);

        ga.event({
            category: 'Home Screen',
            action: 'Button Click',
            label: t(card.labelKey, 'enUS')
        });
    }

    const checkNotification = async (latestEvent:any) => {
        if (latestEvent !== null) {
            // Get Current Event
            let currentEvent: any = await localStorage.getItem('realtimeEvent');
            if (currentEvent !== null) currentEvent = JSON.parse(currentEvent);

            // eMat
            const latestEmat = (latestEvent.emat) ? latestEvent.emat : 0;
            const currentEmat = (currentEvent.emat) ? currentEvent.emat : 0;

            let newEmat = 0;
            if (latestEmat > currentEmat) {
                newEmat = latestEmat - currentEmat;
            }
            dispatch(pushNotificationSlice.actions.setEmatNotification(newEmat));

            // WhatsNew
            const latestWhatsNew = (latestEvent.whats_new) ? latestEvent.whats_new : 0;
            const currentWhatsNew = (currentEvent.whats_new) ? currentEvent.whats_new : 0;

            let newWhatsNew = 0;
            if (latestWhatsNew > currentWhatsNew) {
                newWhatsNew = latestWhatsNew - currentWhatsNew;
            }
            dispatch(pushNotificationSlice.actions.setWhatsNewNotification(newWhatsNew));

        }
    }


    const showBadge = (index: number) => {
        // Index0 - What's New
        // Index1 - eMat
        let badgeCount = 0;
        if (index === 0) {
            badgeCount = whatsNewHasNewUpdate;
        } else if (index === 1) {
            badgeCount = eMatHasNewUpdate;
        }
        if (badgeCount > 0)
            return <BadgeNotification count={badgeCount}/>
        else
            return <></>
    }

    const updateNotification = async (index: number) => {
        // Index0 - What's New
        // Index1 - eMat

        // Get Current Event
        let currentEvent: any = await localStorage.getItem('realtimeEvent');
        if (currentEvent !== null) currentEvent = JSON.parse(currentEvent);

        // Whats new
        if (index === 0) {
            await dispatch(pushNotificationSlice.actions.setWhatsNewNotification(0));
        }
        // eMat
        if (index === 1) {
            await dispatch(pushNotificationSlice.actions.setEmatNotification(0));
        }

        localStorage.setItem('realtimeEvent', JSON.stringify(notificationEvent));
    }

    const getExpiredOrders = (days: number) => {
        const now = new Date();
        const thisDate = new Date();
        thisDate.setDate(now.getDate() - days);
        const date = new Date(thisDate.setHours(0,0,0,0))

        return queryByDateRange(db, 'userStore','createdDate', {
            to: date
        });
    }

    const deleteExpiredOrders = async (today: Date) => {
        const expiredOrders = await getExpiredOrders(60);
        const expiredOrderIds = expiredOrders.map( (x: any) => x.id);
        expiredOrderIds.forEach( async (id: string) => {
            await remove(db,'userStore', id)
        });
        dispatch( appSlice.actions.updateDataRententionData({
            key: 'lastDeletionDate',
            value: today
        }));
    }

    const getServerDetails = async () => {
        return axios.get(
            ENDPOINTS.account,
            { headers: {Authorization: true, background: true} }
        ).then((res: AxiosResponse) => res.data.shop.current_date)
    }

    const dataRetentionProcess = async (checkOnline = true) => {

        const today = new Date(new Date().setHours(0,0,0,0));
        const status = navigator.onLine &&
                            checkOnline && (
                                !dataRetention?.lastCheckedServerDate || (
                                    dataRetention.lastCheckedServerDate &&
                                    new Date(dataRetention.lastCheckedServerDate).getTime() !== today.getTime()
                                )
                            )

        if (status) {
            try {
                const serverDateString = accountDetails ? await getServerDetails() : null

                if (serverDateString !== null) {
                    const serverDate = new Date((serverDateString || '').replace(/-/g,'/'));

                    dispatch( appSlice.actions.updateDataRententionData({ key: 'lastCheckedServerDate', value: serverDate }));
                    dispatch( appSlice.actions.updateDataRententionData({ key: 'isDateSync', value: true }));

                    if(serverDate.getTime() !== today.getTime()){
                        dispatch( appSlice.actions.updateDataRententionData({ key: 'isDateSync', value: false }));
                        setShowAlert(true);
                        return
                    }
                    dataRetentionProcess(false);
                    return;
                }

            } catch(err) {
                setServerDateError(String(err))
            }
        }

        if( !dataRetention || !dataRetention.lastDeletionDate ){
            deleteExpiredOrders(today);
            return;
        }
        const lastDeletionDate = new Date(new Date(dataRetention.lastDeletionDate).setHours(0,0,0,0));
        //Note: delete only once per day
        if(today.getTime() === lastDeletionDate.getTime())return;
        deleteExpiredOrders(today);
    }

    return (
        <div className={B() + ' page'} style={themeStyle()}>

           {isNordicsCountries &&
                <div className='home__productSelection'>
                    <ProductSelectionHome use='homepage'/>
                </div>
            }
            {/* {cards.map((card,index) => (
                <div className="home__card" key={index} onClick={() => onCardSelect(card, index)}>
                    {showBadge(index)}
                    <div className="home__card--content">
                        <Icon name={card.icon} type={card.iconType as any}/>
                        <h2>{ReactHtmlParser(card.label)}</h2>
                    </div>
                </div>
            ))} */}
            {cards().map((card,index) => (
                <div className="home__card" key={index} onClick={() => onCardSelect(card, index)}>
                    {showBadge(index)}
                    <div className="home__card--content">
                        <Icon name={card.icon} type={card.iconType as any}/>
                        <h2>{ReactHtmlParser(card.label)}</h2>
                    </div>
                </div>
            ))}

            {/* varilux g9 card */}
            {enableVarilux &&
                <div className={E('varilux_card')} onClick={()=>{goto.route(routes.variluxG9)}} id='varilux_homepage_card'>
                    <Icon name='homepage-bg' 
                        background={true} 
                        type='png' 
                        extension='png' 
                        className={E('varilux_card_bg')}
                    />
                    <div className={E('varilux_card_logo')}>
                    <Icon
                        name='variluxG9-logo-final'
                        type='png'
                        extension='png'
                        />
                    </div>
                    <span className={E('varilux_card_tag')}>
                        {t('varilux_card_new', 'New')}
                    </span>
                </div>
            }

            <div className={E('main', getAge() )}>
                <div className={E('content')}>
                    <Icon className={E('eyeglass')} name="eyeglass"/>
                    <h1 className={E('banner')}>
                    {t('home_hom_lp_builder')}
                    </h1>

                    {
                        !id &&
                        <div className={E('start-button-wrapper', [isStart ? 'animate' : ''])}>
                            <Button
                                click={startHandler}
                                icon={<CircleArrow type="right" background="#f1f1f1" size=".4rem"/>}
                                text={t('home_hom_start')}
                                type="secondary"
                                outlined={false}
                                className={E('start-button')}
                            />
                        </div>
                    }
                </div>

                { id && <ContinueWithID/> }

                <HomeStart
                    onSelect={ id => onSelectStart(id)}
                    isSelectAge={isStart} onClose={() => setStart(false)}
                />
            </div>

            <Modal show={showStartFromIDModal} onClose={() => modalOnClose()}>
                <div className={E('start-from-id-popup')}>
                <h2>{t('home_hom_start_from_an_id')}</h2>

                    <div>
                        <InputText
                            value={startFromIDField}
                            placeholder={t('home_hom_enter_an_id')}
                            maxlength={25}
                            onChange={idOnchange}/>

                        {
                            startFromIDField &&
                            <Button text={t('home_hom_next')} icon="right-arrow-circle-white" click={sentIDManual}/>
                        }
                    </div>
                </div>
            </Modal>

            <PatientListModal
                showModal={showPatientListModal}
                onModalClose={() => modalOnClose()}
            />

            <label className={E('version')}>
                {env.version}
            </label>

            <Alert
                show={showAlert}
                buttons={[{
                    label: 'Ok',
                    id: 'ok'
                }]}
                message={t('data_sharing_offline_message')}
                onButtonSelect={() => setShowAlert(false)}
            />
        </div>
    )
}

export default HomePage;
