const categories = [
    // [
    //     {
    //         value: 'ava',
    //         label: 'AVA Precision Lenses',
    //         icon: 'ava-lens'
    //     }
    // ],
    [
        {
            value: 'correct',
            label: 'my_practice_my_correct',
            icon: 'correct-lens'
        },
        {
            value: 'protect',
            label: 'my_practice_my_protect',
            icon: 'protect-lens'
        },
        {
            value: 'enhance',
            label: 'my_practice_my_enhance',
            icon: 'enhance-lens'                    
        }
    ],
    [
        {
            value: 'thickness',
            label: 'my_practice_my_lens_thick',
            icon: 'thickness-lens'
        },
        {
            value: 'secondpair',
            label: 'my_practice_my_second_pair_disc',
            icon: 'secondpair-lens'                    
        },
        {
            value: 'advancesetting',
            label: 'advancedpricing_advance_settings',
            icon: 'secondpair-lens'                    
        }
    ]
];


const lensThickness = [
    {
        value: 1.5,
        price: 0,
        enabled: true
    },
    // {
    //     value: 1.56,
    //     price: 0,
    //     enabled: true
    // },
    {
        value: 1.59,
        price: 0,
        enabled: true
    },
    {
        value: 1.6,
        price: 0,
        enabled: true
    },
    {
        value: 1.67,
        price: 0,
        enabled: true
    },
    {
        value: 1.74,
        price: 0,
        enabled: true
    }
]

const discount = [
    {
        label: 'price_bdown_amount_percent',
        value: 'p0',
        discountAmount: 0,
        discountPercentage: 0,
        enabled: false,
        amountEnabled: false,
        discountEnabled: true
    },
    {
        label: 'companion_discount_1',
        value: 'p1',
        discountAmount: 0,
        discountPercentage: 0,
        enabled: false
    },
    {
        label: 'companion_discount_2',
        value: 'p2',
        discountAmount: 0,
        discountPercentage: 0,
        enabled: false
    },
]


export {
    categories,
    lensThickness,
    discount
}
