import { useSelector } from "react-redux";
import { getApp } from "../../store/slices/app.slice";

type hookType = [
    enabledCountry: boolean,
]

const useDisableCountry  = (countries: string | string[]): hookType =>  {
    const {
        accountDetails,
    }= useSelector(getApp);
    const country = accountDetails?.shop.country
    let enabledCountry:boolean = true

    if(country){
        enabledCountry = Array.isArray(countries) ? countries.includes(country) : countries === country
    }

    return [enabledCountry]
 
}

export default useDisableCountry;