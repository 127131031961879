import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApp } from '../../store/slices/app.slice';
import myCompanionSlice from '../../store/slices/mycompanion.slice';
import MyCompanionFooter from './components/MyCompanionFooter';
import MyCompanionLeft from './components/MyCompanionLeft';
import MyCompanionList from './components/MyCompanionList';

interface Props {

}

const MyCompanion : React.FC<Props>  = () =>  {
    // VARIABLES
    const dispatch = useDispatch();
    const { myCompannion } = useSelector(getApp);

    // HOOKS
    useEffect(() => {
        // retrieve data from app store mycompannion
        dispatch(myCompanionSlice.actions.saveMyCompannionData(myCompannion));
        return () => {
            dispatch(myCompanionSlice.actions.selectCategory('correct'))
        }
    },[])

    // METHODS

    return (
        <div className="mycompanion page">
            <MyCompanionLeft/>
            <MyCompanionList/>
            <MyCompanionFooter/>
        </div>
    )
}

export default MyCompanion;