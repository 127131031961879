import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iLensColor from '../../../interface/lens-color.inteface';
import { getApp } from '../../../store/slices/app.slice';
import lensConsultationSlice from '../../../store/slices/lens-consultation.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    colors: iLensColor[],
    onSelect?: (color: iLensColor) => void,
    selected?: iLensColor
    brand? : string;
}

const ProtectColorPicker : React.FC<Props>  = ({
    colors,
    onSelect,
    selected,
    brand
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('protect-color-picker');
    const [showColors, setShowColors] = useState(false);
    const {t} = useTranslation(useSelector(getApp));
    const dispatch = useDispatch();
    const lensColors = colors;

    // HOOKS
    // METHODS
    const inlineStyle = (color: string) => {
        return {
            backgroundColor: color,
            color: color
        }
    }

    const toggleColors = () => {
        setShowColors(prev => !prev);
    }

    const selectColor = (color: iLensColor) => {
        dispatch(lensConsultationSlice.actions.saveSelectProtect(brand));
        if(onSelect)onSelect(color);
    }

    const optionClassModifier = () => {
        const classes = ['toggle'];
        if(showColors)classes.push('toggle-closed');
        if(selected)classes.push('with-selected-color');

        return classes;
    }

    return (
        <div className={B()}>
            <ul className={E('options')}>
                <li
                    key={-1}
                    onClick={toggleColors}
                    className={E('option', optionClassModifier())}>
                        <span>
                            {selected ? t(selected.label) : t('lens_consultation_color')}
                        </span>
                </li>
                {
                    showColors && lensColors.map( (c:iLensColor) => (
                        <li
                            onClick={() => selectColor(c)}
                            className={E('option', (selected && selected.id === c.id) ? 'selected' : '')}
                            key={c.id} style={inlineStyle(c.value)}></li>
                    ))
                }
            </ul>
        </div>
    )
}

export default ProtectColorPicker;
