import React from 'react';
import { backgroundPerRow, flexSpaceBetween, footerLogo, footerText, h3, roundCorner, summaryRow } from '../../../constants/email-css.constants';
import Icon from '../../ui/Icon';
interface Props {
    title: string,
    text: string,
    iconDisplay: boolean
}

const Footer : React.FC<Props>  = ({
    title,
    text,
    iconDisplay
}) =>  {
    //VARIABLES
    //HOOKS

    //METHOD
    return (
    <div style={backgroundPerRow}>
        <h3 style={h3}>
            {title.charAt(0).toUpperCase() + title.slice(1)}
        </h3>
        {/* <p style={footerText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut erat et ex rutrum congue eu non metus. Donec congue ullamcorper nulla eget pellentesque. Aliquam condimentum libero in orci consequat aliquet.
        </p> */}
        <br/>
        <p style={footerText}>
            {text.charAt(0).toUpperCase() + text.slice(1)}
        </p>
        {iconDisplay && (
            <div style={flexSpaceBetween}>
                <Icon name="qrcode-sample" type="image" customStyle={footerLogo}/>
                <Icon name="essilor-logo" type="image" customStyle={footerLogo}/>
            </div>
        )}        
        <div style={{backgroundColor: "#d9edf9"}}>
            <img src="https://i.imgur.com/fLP86Lr.png" alt="" style={roundCorner} width="650"/>
        </div>
        <div style={{backgroundColor: "#d9edf9"}}><br></br></div>
        <div style={{backgroundColor: "#d9edf9"}}><br></br></div>
    </div>
    )
}
export default Footer;