import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../components/ui/Button';
import routes from '../../constants/routes.constant';
import useBEM from '../../utils/hooks/useBEM';
import PackageProduct from './components/PackageProduct';
import packageOfferSlice, { getPackageOffer } from './../../store/slices/package-offer.slice';
import lensConsultationSlice, { getLensConsultation } from '../../store/slices/lens-consultation.slice';
import { fetchBrandDetailsViaProductId, fetchProductDetailsViaId, fetchSubProductDetailsViaSubProductId } from '../../utils/misc';
import userSlice from '../../store/slices/user.slice';
import LensPairType from '../../types/lens-pair.type';
import iPackages from '../../interface/packages.interface';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import useLensConsultation from '../lens-consultation/useLensConsultation';
import LensFamilyType from '../../types/lens-family.type';
import productsSlice, { getProducts } from '../../store/slices/products.slice';
import productDemoSlice from '../../store/slices/products-demo.slice';
import useGoto from '../../utils/hooks/useGoto';
import useTranslation from '../../utils/hooks/useTranslation';
import { getApp } from '../../store/slices/app.slice';
import BlinkEye from '../../components/bink-eye';
import Loading from '../../components/Loading';
import usePricing from '../../utils/hooks/usePricing';
import useAdvancePricing from '../advance-price-setting/useAdvancePricing';

interface Props {

}

const PackageOffer : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('package-offer');
    const history = useHistory();
    const [selectedPackage, setSelectedPackage] = useState<any>()
    const { data } = useSelector(getPackageOffer); 
    const { lensRecommendation, activePair } = useSelector(getLensConsultation); 
    const dispatch = useDispatch();
    const currentLensConsultation = useSelector(getLensConsultation);
    const { temporaryProductList } = useSelector(getProducts);
    const [isPair1Active, setPair1Active] = useState<boolean>()
    const ga = useGoogleAnalytics();
    const { getProductById } = useLensConsultation();
    const goto = useGoto();
    const isDemoClick = useRef<boolean>();
    const [packageCount, setPackageCount] = useState(0);
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const [loading, setLoading] = useState(false);
    const { getPackageOfferPrice, getDiscount, getCurrency } = usePricing();
    const { isAdvancePricing, getPriceCombination, computePrice} = useAdvancePricing(appStore);

    // HOOKS
    useEffect(()=>{
        setLoading(true)
        prefilledDataInPackageOffer()
    },[lensRecommendation, temporaryProductList]) 

    useEffect(()=>{
        //preselect if there is disabled item
        if(data[1].isDisabled || data[2].isDisabled){
            setSelectedPackage(data[0])
        }

        setPackageCount(data.filter( p => !p.isDisabled).length)
    
    },[data]) 

    // METHODS
    const prefilledDataInPackageOffer = () => {
        setTimeout(() => {
            setLoading(false)

            //Prefilled data based on Lens Consultation Products
            const { productDowngrade, brandDowngrade } = fetchDowngrade(lensRecommendation, activePair)
            setPair1Active(activePair === 'pair1')
            dispatch(packageOfferSlice.actions.setPackageOfferData({
                lensRecommendation: lensRecommendation,
                activePair: activePair,
                productDowngrade: productDowngrade,
                brandDowngrade: brandDowngrade,
                temporaryProductList: temporaryProductList,
                getPackageOfferPrice: getPackageOfferPrice,
                getDiscount: getDiscount,
                getCurrency: getCurrency,
                isAdvancePricing,
                getAdvancePrice
            }))
       
        }, 2000);
    }
    const getAdvancePrice = (params: {
        correct: string,
        enhance: string,
        protect1: string,
        protect2: string,
        index: number,
    }, pair: 'p1' | 'p2') => {
        const combi = getPriceCombination(params);
        const price = computePrice(combi, pair);
        return `${price.total} ${getCurrency()}`;
    }

    const back = () => {
        dispatch(lensConsultationSlice.actions.changePair(isPair1Active ? LensPairType.PAIR1 : LensPairType.PAIR2));
        history.push(routes.lensConsultation.path);
    }

    const fetchDowngrade = (lr:any, ap:any) => {
        const { pair1, pair2 } = lr
        const pair = ap === 'pair1' ? pair1 : pair2
        const downgrade = pair.correct.product.downgrade && pair.correct.product.downgrade.length > 0 ? pair.correct.product.downgrade : false

        if(downgrade){
            const downgrade1 = fetchProductDetailsViaId(downgrade[0], temporaryProductList)
            const downgrade2 = fetchProductDetailsViaId(downgrade[1], temporaryProductList)
            const downgrade3 = fetchProductDetailsViaId(downgrade[2], temporaryProductList)

            const downgrade1_brand = fetchBrandDetailsViaProductId(downgrade[0], temporaryProductList)
            const downgrade2_brand = fetchBrandDetailsViaProductId(downgrade[1], temporaryProductList)
            const downgrade3_brand = fetchBrandDetailsViaProductId(downgrade[2], temporaryProductList)

            return {
                productDowngrade: [downgrade1,downgrade2,downgrade3],
                brandDowngrade: [downgrade1_brand, downgrade2_brand, downgrade3_brand]
            }
        }

        return {
            productDowngrade: [],
            brandDowngrade: [],
        }
    }

    const onSave = () => {
        //Prefilled data in Lens Consultation
        const recom = prefilledDataInLC()

        const temp = {
            ...currentLensConsultation,
            lensRecommendation: recom
        }

        dispatch(userSlice.actions.savePrefilledData(temp)); 
        dispatch(lensConsultationSlice.actions.setPrefilledDataFromNeedExplorer(recom));
        dispatch(lensConsultationSlice.actions.changePair(isPair1Active ? LensPairType.PAIR1 : LensPairType.PAIR2));

        history.push(routes.lensConsultation.path);
    }

    const prefilledDataInLC = () => {
        const { categories, product } = selectedPackage
        const { value } = categories[0]

        const protect = isPair1Active ? 
        currentLensConsultation.lensRecommendation?.pair1?.protect :
        currentLensConsultation.lensRecommendation?.pair2?.protect

        const enhance = isPair1Active ? 
        currentLensConsultation.lensRecommendation?.pair1?.enhance :
        currentLensConsultation.lensRecommendation?.pair2?.enhance

        const pair =  {
            correct: {
                brand: fetchBrandDetailsViaProductId(product.id, temporaryProductList),
                product: fetchProductDetailsViaId(product.id, temporaryProductList),
                subProduct: fetchSubProductDetailsViaSubProductId(value, temporaryProductList)
            },
            protect: protect,
            enhance: enhance
        }

        return {
            pair1: isPair1Active ? pair : currentLensConsultation.lensRecommendation?.pair1,
            pair2: isPair1Active ? currentLensConsultation.lensRecommendation?.pair2 : pair
        }
    }

    const isSaveButtonDisabled = () => {
        return selectedPackage === undefined
    }

    const selectPackage = (item: iPackages) => {
        if(isDemoClick.current){
            isDemoClick.current = false;
            return;
        }

        if(item.isDisabled)return;
        setSelectedPackage(item);
        ga.event({
            category: 'Lens Consultation - Package',
            action: 'Button Click',
            label: t(item.label, 'enUS')
        });
    }


    const getPackageDemo = (obj: any) => {
        let correct = getProductById({
            id: obj.product.id,
            brandId: obj.product.brandId,
            family: LensFamilyType.CORRECT,
            list: temporaryProductList
        });
        correct = correct?.demo && correct?.demo.length > 0 ? correct : undefined;

        let protect = getProductById({
            id: obj.categories[1].value,
            family: LensFamilyType.PROTECT,
            list: temporaryProductList
        });
        protect = protect?.demo && protect?.demo.length > 0 ? protect : undefined;

        let enhance = getProductById({
            id: obj.categories[2].value,
            family: LensFamilyType.ENHANCE,
            list: temporaryProductList
        });
        enhance = enhance?.demo && enhance?.demo.length > 0 ? enhance : undefined;

        let initialDemo: any;
        if(correct)initialDemo =  { family: LensFamilyType.CORRECT, product: correct };
        if(protect && !correct)initialDemo = { family: LensFamilyType.PROTECT, product: protect };
        if(enhance && !correct && !protect)initialDemo = { family: LensFamilyType.ENHANCE, product: enhance };

        return {
            data: {
                correct,
                protect,
                enhance
            },
            initial: initialDemo
        }
    }


    const demo = (obj: any) => {
        isDemoClick.current = true;
        const packageDemo = getPackageDemo(obj);
        if(!packageDemo.initial)return;
        dispatch(productsSlice.actions.selectProductTodemo(packageDemo.initial.product))
        dispatch(productDemoSlice.actions.setRecommendationDemo(packageDemo.data));
        dispatch(productDemoSlice.actions.selectLensFamily(packageDemo.initial.family));
        dispatch(productDemoSlice.actions.setDemoFrom(routes.packageOffer));

        goto.route(routes.demonstration2);        
    }

    return (
        <div className={B() + ' page'}>
            <Loading show={loading}/>
            
            <div className={E('buttons')}>
                <Button 
                    outlineType="light"
                    click={back}
                    text={t('packaged_offer_popup_po_popup_back_')} 
                    icon="left-arrow-circle-gray" 
                    iconPosition="left" type="secondary" />
                    
                <Button text={t('packaged_offer_popup_po_popup_save')}  icon="check" icontype='png' click={onSave} disabled={isSaveButtonDisabled()}/>
            </div>


            <div className={E('cards', packageCount < 3 ? 'center' : '' )}>
                {
                    !loading && data.map((item, index) => {
                        if(item.isDisabled)return;

                        return ( <div onClick={() => selectPackage(item)}
                                    className={E('card', [item.id, selectedPackage?.id === item.id ? 'selected' : ''])} 
                                    key={index}>

                                    <div className={E('card-info')}>
                                        <label>{t(item.label)}</label>
                                        {
                                            getPackageDemo(item).initial && 
                                            <div onClick={() => demo(item)}>
                                                {/* <img src={eyeImage} alt=""/> */}
                                                <BlinkEye/>
                                            </div>
                                        }                                
                                    </div>

                                    <PackageProduct 
                                        selected={selectedPackage?.id === item.id}
                                        name={item.product.name} 
                                        price={item.product.price}
                                        label={item.subLabel}
                                        categories={item.categories}
                                        isDisabled={item.isDisabled}
                                        />

                        </div> )
                    })
                }
            </div>

        </div>
    )
}

export default PackageOffer;