import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNumber } from 'util';
import packages from '../../constants/package-offer.constants';
import { fetchProductCombi, fetchProductDetailsViaId, fetchSubProductDetailsViaSubProductId } from '../../utils/misc';
import iPackageOfferStore from '../interface/package-offer-store-interface';
import iRootState from '../interface/root-state.interface';

const initialState: iPackageOfferStore  = {
    isAdvancePricing:false,
    data: packages,
}

const packageOfferSlice = createSlice({
    name: 'packageOffer',
    initialState,
    reducers: {
        setPackageOfferData: (state, action: PayloadAction<any>) => {
            const {
                lensRecommendation, 
                activePair, 
                productDowngrade, 
                brandDowngrade, 
                temporaryProductList,
                getPackageOfferPrice,
                getDiscount,
                getCurrency,
                isAdvancePricing,
                getAdvancePrice
            } = action.payload
            const { pair1, pair2 } = lensRecommendation

            const pair = activePair === 'pair1' ? pair1 : pair2

            const downgrade = pair.correct.product.downgrade && pair.correct.product.downgrade.length > 0 && pair.correct !== undefined ? pair.correct.product.downgrade : false
            const correct = pair.hasOwnProperty('correct') && pair.correct !== null && pair.correct !== undefined ? pair.correct.subProduct : false
            const protect = pair.hasOwnProperty('protect') && (pair.protect || []).length > 0 && pair.protect !== undefined ? pair.protect[0].product : false
            const protect2 = pair.hasOwnProperty('protect') && (pair.protect || []).length > 0 && pair.protect !== undefined && pair.protect[1] ? pair.protect[1].product : false
            const enhance = pair.hasOwnProperty('enhance') && pair.enhance !== null && pair.enhance !== undefined ? pair.enhance.product : false

            const newData = []
            const cats = [
                { value: correct ? correct.id : '', label: correct ? correct.label : ''},
                { value: protect ? protect.id : '' , label: protect ? protect.label : ''},
                { value: enhance ? enhance.id : '' , label: enhance ? enhance.label : ''},
            ]

            const getCorrect = (ref: any) => {
                return {
                    id: ref.id,
                    code: ref.code,
                    label: ref.label,
                    index: ref.index
                } 
            }

            const getSubProduct = (downgrade: any) => {
                const product = fetchProductDetailsViaId(downgrade.id, temporaryProductList)

                const productDetails = product.subProducts
                let id = ''
                let label = ''
                let code = ''
                let index = 0

                if(productDetails.length > 0){
                    id = productDetails[0].id
                    label = productDetails[0].label
                    code = productDetails[0].code
                    index = productDetails[0].index
                }

                return {
                    id: id,
                    label: label,
                    code: code,
                    index: index
                }
            }

            const getPrice = (
                correct_id: string | undefined,
                correct_code: string | undefined,
                correct_index: number | undefined,
                protect_id: string | undefined,
                protect2_id: string | undefined,
                enhance_id: string | undefined
            ) => {

                const price = getPackageOfferPrice(
                    correct_id,
                    correct_code,
                    correct_index,
                    protect_id,
                    protect2_id,
                    enhance_id,
                ) 

                return price === -1 ? 0 : price
            }

            if(downgrade){
                // For Personalized (index=0), For Optimized (index=1), For Standart (index=2)
                for (var i = 0; i < 3; i++) {

                    const params = {
                        subProduct: i === 0 ? getCorrect(correct).code : getSubProduct(productDowngrade[i]).code,
                        index: i === 0 ? getCorrect(correct).index : getSubProduct(productDowngrade[i]).index,
                        protect1: protect && productDowngrade[i].hasOwnProperty('id') ? protect.id : '',
                        protect2: protect2 && productDowngrade[i].hasOwnProperty('id') ? protect2.id : '',
                        enhance: enhance && productDowngrade[i].hasOwnProperty('id') ? enhance.id : ''
                    }

                    const price = getPrice(
                        i === 0 ? getCorrect(correct).id : getSubProduct(productDowngrade[i]).id,
                        params.subProduct,
                        params.index,
                        params.protect1,
                        params.protect2,
                        params.enhance                    
                    )

                    let finalPrice = `${(price-getDiscount(activePair === 'pair1', price)).toString()} ${getCurrency()}`;

                    if(isAdvancePricing){

                         finalPrice = getAdvancePrice({
                            correct: params.subProduct,
                            enhance: params.enhance,
                            protect1: params.protect1,
                            protect2: params.protect2,
                            index: params.index,
                        }, activePair === 'pair1' ? 'p1' : 'p2');

                    }
    
                    let temp = {
                        ...packages[i],
                        product: {
                            ...packages[i].product,
                            name: productDowngrade[i].label,
                            id: productDowngrade[i].id,
                            brandId: brandDowngrade[i].id,
                            price: finalPrice
                        },
                        categories: [
                            { 
                              value: i === 0 ? getCorrect(correct).id : getSubProduct(productDowngrade[i]).id,
                              label: i === 0 ? getCorrect(correct).label : getSubProduct(productDowngrade[i]).label,
                            },
                            { 
                              value: protect && productDowngrade[i].hasOwnProperty('id') ? protect.id : '' ,
                              label: protect && productDowngrade[i].hasOwnProperty('id') ? protect.label : ''},
                            { 
                              value: enhance && productDowngrade[i].hasOwnProperty('id') ? enhance.id : '' ,
                              label: enhance && productDowngrade[i].hasOwnProperty('id') ? enhance.label : ''
                            },
                        ],
                        isDisabled: !productDowngrade[i].hasOwnProperty('id'),
                    }

                    newData.push(temp)
                }
            }else{
                // For Personalized (index=0), For Optimized (index=1), For Standard (index=2)

                const params = {
                    subProduct: getCorrect(correct).code,
                    index: getCorrect(correct).index,
                    protect1: protect ? protect.id : '',
                    protect2: protect2 ? protect2.id : '',
                    enhance: enhance ? enhance.id : ''
                }

                const price = getPrice(
                    getCorrect(correct).id,
                    params.subProduct,
                    params.index,
                    params.protect1,
                    params.protect2,
                    params.enhance
                )

                let finalPrice = `${(price-getDiscount(activePair === 'pair1', price)).toString()} ${getCurrency()}`;

                if(isAdvancePricing){
                    finalPrice = getAdvancePrice({
                        correct: params.subProduct,
                        enhance: params.enhance,
                        protect1: params.protect1,
                        protect2: params.protect2,
                        index: params.index,
                    }, activePair === 'pair1' ? 'p1' : 'p2');                    
                }

                const personalized = {
                    ...packages[0],
                    product: {
                        ...packages[0].product,
                        name: getCorrect(correct).label,
                        id: getCorrect(correct).id,
                        price: finalPrice
                    },
                    categories: cats,
                }
                const optimized = {
                    ...packages[1],
                    isDisabled: true,
                }
                const standard = {
                    ...packages[2],
                    isDisabled: true,
                }

                newData.push(personalized)
                newData.push(optimized)
                newData.push(standard)
            }


            return {
                ...state,
                data: newData,
            }
        },
    }
});

export const getPackageOffer = (state:iRootState) => state.packageOffer;
export default packageOfferSlice;