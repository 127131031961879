import React, { useEffect, useState } from 'react';
import RouterLink from './ui/RouterLink';
import Icon from './ui/Icon';
import sidebarSlice from '../store/slices/sidebar.slice';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../constants/routes.constant';
import { useLocation } from 'react-router';
import Utils from '../utils/Utils';
import productsSlice, { getProducts } from '../store/slices/products.slice';
import useGoogleAnalytics from '../utils/hooks/useGoogleAnalytics';
import useTranslation from '../utils/hooks/useTranslation';
import appSlice, { getApp } from '../store/slices/app.slice';
import ExperienceType from '../types/experience.type';
import refresh from '../assets/svg/refresh.svg';
import refreshNew from '../assets/svg/refresh-new.svg';
import playVideo from '../assets/svg/demo.svg';
import hamburger from '../assets/svg/hamburger.svg';
import hamburgerNew from '../assets/svg/hamburger-new.svg';

import logo from '../assets/png/logo.png';
import essilorLogoWhite from '../assets/svg/essilor-logo-white.svg';
import useGoto from '../utils/hooks/useGoto';
import userSlice from '../store/slices/user.slice';
import needExplorerSlice from '../store/slices/need-explorer.slice';
import lensThicknessSlice from '../store/slices/lens-thickness.slice';
import lensConsultationSlice from '../store/slices/lens-consultation.slice';
import measureSlice from '../store/slices/measure.slice';
import summarySlice from '../store/slices/summary.slice';
import prescriptionSlice from '../store/slices/prescription.slice';
import useProducts from '../utils/hooks/useProducts';
import useENV from '../utils/hooks/useEnv';
import { IndexedDBContext, IndexedDBContextType } from '../utils/context/indexedDBContext';
import ReactGA from 'react-ga';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { LogLevel } from '@microsoft/signalr';
import pushNotificationSlice, { getPushNotification } from '../store/slices/push-notification.slice';
import Tooltip from './ui/Tooltip';
import ENDPOINTS from '../constants/endpoints';
import axios from 'axios'
import deliverySlice from '../store/slices/delivery.slice';
import educationSlice from '../store/slices/education.slice';
import useEprom from '../utils/hooks/useEprom';
import productDemoSlice from '../store/slices/products-demo.slice';
// import lensConsultationSun from '../store/slices/lens-consultation-sun-exp.slice';
import useDisableOnVariluxG9 from '../utils/hooks/useDisableOnVariluxG9';
import variluxgG9Slice from '../store/slices/variluxG9.slice';

interface Props {

}

const NavBar : React.FC<Props>  = () =>  {
    // VARIABLES
    const dispatch = useDispatch();
    const location = useLocation();
    const { cloneObject } = Utils();
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const [ activeRoute, setActiveRoute ] = useState(routes.home);
    const ga = useGoogleAnalytics();
    const { t, getTranslations2} = useTranslation(appStore);
    const goto = useGoto();
    const productStore = useSelector(getProducts);
    const { getPriceCombination2, getProductData2, mapProducts, getCurrency} = useProducts(useSelector(getProducts));
    const {
        accountDetails,
        token,
        dataStructureVersion,
        globalInformation,
        translationCountryLanguage,
        translationRegion
    } = appStore;

    const env = useENV();
    const {get, update} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const [ inAppShowTooltip, setInAppShowTooltip ] = useState(false);
    const [ tooltipSecond, setTooltipSecond ] = useState(180); // 3 mins interval for tooltip
    const { inAppHasNewUpdate, countryCode, notificationEvent, tutorialHasNewUpdate } = useSelector(getPushNotification);
    const {
        getEPROMData,
        formatData
    } = useEprom();
    const [disableNavbarOnVarilux] = useDisableOnVariluxG9(activeRoute?.id)
    // HOOKS
    useEffect(() => {
        pushGADataLayers()
        trackUserGoogleAnalytics()
        checkDataStructureVersion();
        dispatch(appSlice.actions.loading(false));
        dispatch(productsSlice.actions.open(false));
    }, []);

    useEffect( () => {
        setActiveRoute(getActiveRoute(location.pathname));
    }, [location]);

    useEffect( () => {
        initSignalR();
    }, [])

    useEffect(() => {
        checkNotification(notificationEvent);
    }, [notificationEvent])

    useEffect(() => {
        // showTooltip(10);
        displayTooltip()
    }, [inAppHasNewUpdate])

    // METHODS
    const pushGADataLayers = () => {
        const currencyCode = accountDetails?.currency?.code;
        const gaCode = accountDetails?.ga_code;
        if(!currencyCode)return;

        const win: any = window;
        win.dataLayer.push({
            //for custom GA code
            // trackingId: gaCode !== undefined || gaCode !== "" ? gaCode : env.GA,
            trackingId: env.GA,
            // license: globalInformation.license
        })

        ReactGA.set({ License: globalInformation.license });
    }

    const trackUserGoogleAnalytics = () => {
        let win: any = window
        win.dataLayer.push({
          event: 'userData',
          license_id: globalInformation.license,
        })
    }

    const checkDataStructureVersion = () => {
        const newDataVersion = env.dataStructureVersion;
        const current = dataStructureVersion;
        if(!newDataVersion)return;

        if(newDataVersion !== current){
            dispatch(appSlice.actions.updateDataStructureVersion(newDataVersion))
            clearData();
        }
    }

    const closeNavOnly = () => {
        return (
            // activeRoute?.id === routes.legalNotices.id ||
            activeRoute?.id === routes.myCompanion.id
        )
    }

    const getActiveRoute = (path: string) => {
        const routeKeys = Object.keys(routes);
        const clonedRoutes = cloneObject<any>(routes);
        let activeRouteKey = routeKeys.filter( (k: string) => clonedRoutes[k].path === path)[0];
        return clonedRoutes[activeRouteKey];
    }

    const openProducts = () => {
        dispatch(productDemoSlice.actions.openQrPopup(false))
        dispatch(productsSlice.actions.open(true));

        const page = location.pathname.replace(/\-/g,' ').replace('/','');

        ga.event({
            category: 'Home page',
            action: 'Product Click',
            label: page.length ? page : 'home'
        });

    }
    const renderLogo = () => {
        if(activeRoute.id !== 'discovery') {
            return <img src={logo} alt=""/>
        }
        return <img src={essilorLogoWhite} alt=""/>
    }

    const sorter = ( a: string, b: string ): number => {
        const aa = a.toLocaleLowerCase();
        const bb = b.toLocaleLowerCase();
        if ( aa < bb )return -1;
        if ( aa > bb )return 1;
        return 0;
    }

    const showLanguageSelection = () => {
        dispatch(sidebarSlice.actions.selectMenu({
            menu:{
                id:'settings_language',
                label: 'my_practice_my_language',
                icon: 'globe',
            },
            callerId: 'reloadPage'
        }));
    }
    const reloadPage = async () => {

        if(!token)return;
        if(!navigator.onLine)return;

        const machineID = await get('applicationStore', 'machineId');
        if(!machineID){
            localStorage.clear();
            window.location.reload();
            return;
        }

        dispatch(appSlice.actions.loading(true));

        axios.get(ENDPOINTS.account, { headers: {Authorization: true, background: true} }).then((res: any) => {
            dispatch(appSlice.actions.saveAccountDetails(res.data));
        });

        try {
            let regionIdToUse = accountDetails?.shop.region;
            if(translationRegion && typeof translationRegion === 'object') regionIdToUse = translationRegion.id;

            const {responseData: {data: translationsData}} = await getTranslations2(regionIdToUse as string);
            await update('languageStore', {
                id: 'translations',
                value: translationsData.country
            });
            dispatch(appSlice.actions.saveRegions(translationsData.region));

            const defaultRegion = translationsData.region.filter( (x: any) => {
                return x.id.toLowerCase() === regionIdToUse?.toLocaleLowerCase();
            })[0];
            dispatch(appSlice.actions.setTranslationRegion(defaultRegion));

            updateTranslationData(translationsData);
            const products = await getProductData2();
            const mappedProductData = mapProducts(products.responseData.data.products, true);
            dispatch(appSlice.actions.setProducts(mappedProductData));
            dispatch(productsSlice.actions.updateProductList(mappedProductData));
            dispatch(productsSlice.actions.attachedProductDemo({
                demos: productStore.lensDemo,
                productList: mappedProductData
            }))

            const prices = await getPriceCombination2();
            dispatch(appSlice.actions.setProductCombi({data: prices.responseData.data, isInAppRefresh: true}));
            const currency = await getCurrency();
            dispatch(lensConsultationSlice.actions.setCurrency(currency.responseData));
            dispatch(appSlice.actions.loading(false));

            if(accountDetails && accountDetails?.shop.eprom) {
                const {region, country} = accountDetails?.shop;
                const result: any = await getEPROMData(region, country);
                const formatted = formatData(result.data, country);
                dispatch(appSlice.actions.saveEPROMData(formatted));
            }

            // Trigger update event in SignalR
            try {
                await updateEvent();
            } catch (error) { console.log('SIGNALR ERROR',error) }

            const cancelReload = !translationCountryLanguage ? true: false;
            reloadPageCallback(cancelReload);
            if(cancelReload)showLanguageSelection();


        } catch (error: any) {
            dispatch(appSlice.actions.loading(false));

            if(!error.responseData.response){
                const errorMsg = error.responseData.message === 'Network Error' ? t('data_sharing_offline_message') : undefined;
                apiCallFailed(errorMsg);
                return;
            }

            const errorStatus = error.responseData.response.status;

            if(errorStatus === 401) {
                dispatch(appSlice.actions.updateLicenseStatus('Expired'));
                goto.route(routes.licenseActivation);
                return;
            }

            apiCallFailed();
        }
    }

    const updateTranslationData = (translationsData: any) => {
        const countryCode = translationCountryLanguage?.country as string;
        const countryLanguageCode = translationCountryLanguage?.languageCode as string;
        const countryTranslationData = translationsData.country[countryCode];
        dispatch(appSlice.actions.setTranslation({
            code: countryLanguageCode,
            country: countryTranslationData.country,
            translation: countryTranslationData.language[countryLanguageCode]
        }));
    }

    const apiCallFailed = (message?: string) => {
        setTimeout( () => {
            dispatch(appSlice.actions.showGlobalAlert({
                show: true,
                message: message || 'Unable to fetch update, please retry again',
            }));
        }, 100);
    }

    const reloadPageCallback = (cancelReload?:boolean) => {
        clearData();

        setTimeout(() => {
            goto.route(routes.home);
        }, 200);

        if(cancelReload)return;
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    const clearData = () => {
        dispatch(userSlice.actions.clearUserState());
        dispatch(needExplorerSlice.actions.resetData());
        dispatch(lensThicknessSlice.actions.resetData());
        dispatch(lensConsultationSlice.actions.resetData());
        dispatch(measureSlice.actions.resetData());
        dispatch(summarySlice.actions.resetData());
        dispatch(prescriptionSlice.actions.resetData());
        dispatch(appSlice.actions.resetData());
        dispatch(productsSlice.actions.reset());
        dispatch(productsSlice.actions.attachedProductDemo({
            demos: productStore.lensDemo
        }))
        // dispatch(deliverySlice.actions.reset({all: true}));
        dispatch(deliverySlice.actions.reset());
        dispatch(educationSlice.actions.resetState())
        dispatch(variluxgG9Slice.actions.resetData())
        
        //temp - fix for restructured data to prevent from clearing local storage
        // dispatch(appSlice.actions.saveMyCompannionData())
    }

    // SECTION connectSignalR
    const connectSignalR = (userId: string, groupId: string) => {

        const connection = new HubConnectionBuilder()
            .withUrl(env.signalRUrl + `/api?userId=${userId}`)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        connection.on('newMessage', async (message: any) => {
            var tempCurrentEventFly: any = null;
            let currentEvent: any = localStorage.getItem('realtimeEvent');
            if (notificationEvent === null || notificationEvent === undefined) {
                if (currentEvent !== null) {
                    tempCurrentEventFly = JSON.parse(currentEvent);
                    dispatch(pushNotificationSlice.actions.setNotificationEvent(tempCurrentEventFly))
                }
            }

            if (message.transaction === 'emat') {
                dispatch(pushNotificationSlice.actions.setEmatCount(message.data.record_count))
            }

            if (message.transaction === 'whats_new') {
                dispatch(pushNotificationSlice.actions.setWhatsNewCount(message.data.record_count))
            }

            if (message.transaction === 'tutorial') {
                dispatch(pushNotificationSlice.actions.setTutorialCount(message.data.record_count))
            }

            if (message.transaction === 'product') {
                dispatch(pushNotificationSlice.actions.setProductTimestamp(message.timestamp))
            }

            if (message.transaction === 'pricing') {
                dispatch(pushNotificationSlice.actions.setPricingTimestamp(message.timestamp))
            }

            if (message.transaction === 'translation') {
                dispatch(pushNotificationSlice.actions.setTranslationTimestamp(message.timestamp))
            }
        });

        connection
          .start()
          .then(function () {
                register(userId,groupId)
                retrieve(groupId);
          })
          .catch(console.error);
    }

    const initSignalR = async () => {
        const machineID = await get('applicationStore', 'machineId');
        if (machineID) {
            const countryCode: any = await accountDetails?.shop?.country;
            await dispatch(pushNotificationSlice.actions.setCountryCode(countryCode));
            connectSignalR(machineID.value, countryCode)
        }
    }

    const checkNotification = async (latestEvent:any) => {
        if (latestEvent !== null) {
            // Get Current Event
            let currentEvent: any = await localStorage.getItem('realtimeEvent');
            if (currentEvent !== null) currentEvent = JSON.parse(currentEvent);

            // InApp Refresh
            const latestProductDate = new Date(latestEvent.product);
            const latestPricingDate = new Date(latestEvent.pricing);
            const latestTranslationDate = new Date(latestEvent.translation);

            const currentProductDate = currentEvent && new Date((currentEvent.product) ? currentEvent.product : "2020/1/1");
            const currentPricingDate = currentEvent && new Date((currentEvent.pricing) ? currentEvent.pricing : "2020/1/1");
            const currentTranslationDate = currentEvent && new Date((currentEvent.translation) ? currentEvent.translation : "2020/1/1");

            if ((latestProductDate > currentProductDate) ||
                (latestPricingDate > currentPricingDate) ||
                (latestTranslationDate > currentTranslationDate)) {
                dispatch(pushNotificationSlice.actions.setInAppNotification(true));
            } else {
                dispatch(pushNotificationSlice.actions.setInAppNotification(false));
            }

            // eMat
            const latestEmat = (latestEvent.emat) ? latestEvent.emat : 0;
            const currentEmat = currentEvent && (currentEvent.emat) ? currentEvent.emat : 0;

            if (latestEmat > currentEmat) {
                const newEmat = latestEmat - currentEmat;
                dispatch(pushNotificationSlice.actions.setEmatNotification(newEmat));
            }

            // WhatsNew
            const latestWhatsNew = (latestEvent.whats_new) ? latestEvent.whats_new : 0;
            const currentWhatsNew = currentEvent && (currentEvent.whats_new) ? currentEvent.whats_new : 0;

            if (latestWhatsNew > currentWhatsNew) {
                const newWhatsNew = latestWhatsNew - currentWhatsNew;
                dispatch(pushNotificationSlice.actions.setWhatsNewNotification(newWhatsNew));
            }

            // Tutorial
            const latestTutorial = (latestEvent.tutorial) ? latestEvent.tutorial : 0;
            const currentTutorial = currentEvent && (currentEvent.tutorial) ? currentEvent.tutorial : 0;

            if (latestTutorial > currentTutorial) {
                const newTutorial = latestTutorial - currentTutorial;
                dispatch(pushNotificationSlice.actions.setTutorialNotification(newTutorial));
            }
        }
    }

    const updateEvent = async () => {
        let resp = await fetch(env.signalRUrl + `/api/retrieve?country=${countryCode}`, {
          method: 'GET',
        });
        let respEvent = await resp.json();

        let currentEvent = await localStorage.getItem('realtimeEvent');
        currentEvent = respEvent;

        await localStorage.setItem('realtimeEvent', JSON.stringify(currentEvent));
        checkNotification(respEvent);
    }

    const register = async (userId: string, groupName: string) => {
        let resp =  await fetch(env.signalRUrl + `/api/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userId: userId, groupName: groupName})
        });
    }

    const retrieve = async (country: string) => {
        let resp =await fetch(env.signalRUrl + `/api/retrieve?country=${country}`, {
          method: 'GET',
        });
        let respEvent = await resp.json();

        let currentEvent: any = await localStorage.getItem('realtimeEvent');
        if (currentEvent !== null) {
            if (currentEvent.country !== respEvent.country || currentEvent.country == "")
                currentEvent = respEvent;
            else {
                currentEvent = JSON.parse(currentEvent);
                currentEvent.emat = respEvent?.emat;
                currentEvent.whats_new = respEvent.whats_new;
                currentEvent.tutorial = respEvent.tutorial;
            }
        } else {
          currentEvent = respEvent;
        }

        if (!localStorage.getItem('realtimeEvent')) {
            await localStorage.setItem('realtimeEvent', JSON.stringify(currentEvent));
        }

        await checkNotification(respEvent);


    }

    const showTooltip = (second: number) => {
        let delay = 0;
        const timer = setInterval(() => {
            if (inAppHasNewUpdate && delay === 0) {
                setInAppShowTooltip(true);
                setTimeout(() => {
                    setInAppShowTooltip(false);
                    delay = tooltipSecond
                }, second * 1000);
            }
            delay--;
            if (!inAppHasNewUpdate)
                clearInterval(timer);
        }, 1000);
    }

    const displayTooltip = () => {
        if (inAppHasNewUpdate) {
            setInAppShowTooltip(true)
            setTimeout(() => {
                setInAppShowTooltip(false)
            }, 5000)
        }
    }
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")
    return (
        <>
        {!disableNavbarOnVarilux &&
            <div className={'navbar navbar--'+ activeRoute?.id}>

                <RouterLink route={accountDetails ? routes.home : routes.licenseActivation} className="navbar__logo">
                    {renderLogo()}
                </RouterLink>

                <div className="navbar__center">
                    <div className="navbar__title h2">
                        {t(activeRoute?.label)}
                    </div>
                    {/* {activeRoute?.path === '/' && <div className="navbar__subtitle">
                        <div className="navbar__for">for</div>
                        <img alt="" className='navbar__cobrand' src={accountDetails?.shop.shop_logo} />
                    </div> } */}
                </div>


                <div className="navbar__navigation">

                    {!closeNavOnly() && accountDetails && (
                        <>
                                <RouterLink click={reloadPage}>
                                    {
                                        (inAppHasNewUpdate) ?
                                        <div className={(inAppShowTooltip) ? "refresh-diag active" : "refresh-diag"}>
                                            <img src={inAppHasNewUpdate ? refreshNew : refresh} alt="" className="refresh" />
                                            <Tooltip message={t("inapp_inapprefresh")} />
                                        </div>
                                        :
                                        <img src={refresh} alt="" className="refresh"/>
                                    }
                                </RouterLink>

                                {
                                    (window.location.pathname !== "/demonstrations") && (
                                        <RouterLink click={openProducts} className='navbar__demo-button'>
                                            <img src={playVideo}/>
                                        </RouterLink>
                                    )
                                }

                                {/*
                                    Note: put back when functionality is developed
                                    use this icon image
                                    https://www.flaticon.com/free-icon/rotate-smartphone_81564?term=screen%20rotation&page=1&position=3&page=1&position=3&related_id=81564&origin=search
                                    
                                    <RouterLink>
                                    <img src={flip}/>
                                    </RouterLink>
                                */}

                                <span className="spacer"></span>

                                <RouterLink click={() => dispatch(sidebarSlice.actions.open())}>
                                    {
                                        (tutorialHasNewUpdate > 0) ?
                                        <img src={hamburgerNew}/> :
                                            <img src={hamburger}/>
                                    }
                                </RouterLink>
                            </>
                    )}


                    {
                        closeNavOnly() &&
                        <RouterLink route={routes.home} className="close">
                            <Icon name={`close-gray${selectIcon()}`}/>
                        </RouterLink>
                    }


                </div>

            </div>}
        </>
    )
}

export default NavBar;
