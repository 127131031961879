import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Checkbox from '../../../components/ui/Checkbox';
import iLensProposalField from '../../../interface/lens-proposal-field.interface';
import { getApp } from '../../../store/slices/app.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import { getUser } from '../../../store/slices/user.slice';
import AgeCategoryType from '../../../types/age-category.type';
import LensCategoryType from '../../../types/lens-category.type';
import LensEnhanceType from '../../../types/lens-enhance.type';
import LensFamilyType from '../../../types/lens-family.type';
import LensProposalEquipmentType from '../../../types/lens-proposal-section.type';
import LensProtectType from '../../../types/lens-protect.type';
import PrescriptionViewType from '../../../types/prescription-view.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {

}

const PrescriptionLensProposal : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription-lens-proposal');
    const { 
        tempGeneralEquipment, 
        tempComplementaryEquipment, 
        tempPrescriptionData
    } = useSelector(getPrescription);
    const { 
        generalEquipment, 
        complementaryEquipment, 
        ageCategory
    } = useSelector(getUser);
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));
    const [showPersonalized, setShowPersonalized] = useState(false);
    const [showCorrect, setShowCorrect] = useState(false);

    // HOOKS
    useEffect(() => {        
        setPreSelectedValues();
    }, [])

    // METHODS
    const setPreSelectedValues = () => {
        let general = generalEquipment || tempGeneralEquipment;
        let complementary = complementaryEquipment || tempComplementaryEquipment;

        const sphereValues = tempPrescriptionData.values.sphere;
        const highest = Number(sphereValues?.od) > Number(sphereValues?.os) ? 
                        sphereValues.od : 
                        sphereValues.os;

        // Note: preselect only if theres no saved data
        // if(!generalEquipment){
            if(ageCategory?.value === AgeCategoryType.KIDS){
                const correctPreSelectedValue = Number(highest) >= -0.5 ? 
                    LensCategoryType.SINGLE_VISION : 
                    LensCategoryType.MYOPIA;

                const protectPreSelectedValue = Number(highest) >= -0.5 ? 
                    LensProtectType.BLUE_CUT : undefined;

                general = preSelectValue(general , LensFamilyType.CORRECT, correctPreSelectedValue);
                general = preSelectValue(general, LensFamilyType.PROTECT, protectPreSelectedValue);

                if(!generalEquipment){
                    general = preSelectValue(general, LensFamilyType.ENHANCE, LensEnhanceType.AR);
                }

                // general = hideProtectTinted(general)
            } else {
                const correctPreSelectedValue = !tempPrescriptionData.values.addition ? 
                LensCategoryType.SINGLE_VISION : 
                LensCategoryType.PROGRESSIVE;

                general = preSelectValue(general, LensFamilyType.CORRECT, correctPreSelectedValue);
            }
        // }
     
        dispatch(prescriptionSlice.actions.saveTemporaryLensEquipment({
            general,
            complementary
        }))
    }

    const hideProtectTinted = (equipment: iLensProposalField[]) => {
        const hideTinted = true;            
        return equipment.map( x => {
            if(x.field === LensFamilyType.PROTECT) {
                return {
                    ...x,
                    options: x.options.map ( o => {
                        if(o.value === LensProtectType.TINTED)return { ...o, hidden: hideTinted }
                        return o;
                    }),
                    value: (hideTinted && x.value?.value === LensProtectType.TINTED) ? undefined : x.value
                }
            }
            return x;
        })
    }

    const preSelectValue = (
        general: iLensProposalField[],
        family: LensFamilyType, 
        value: LensCategoryType | LensProtectType | LensEnhanceType | undefined
    ) => {                
        const familyType = general.filter( x => x.field === family)[0];
        const preSelectedValue = familyType.options.filter( x => x.value === value)[0];
        general = general.map( x => {
            if(x.field === family){
                return { ...x, value: preSelectedValue }
            }
            return x;
        });
        return general;
    }
    
    const back = () => {
        dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.PRESCRIPTION));
    }

    const onSelectFieldOption = (
        equipment: LensProposalEquipmentType, 
        field:iLensProposalField, 
        option: any,
        unSelectSelected = true
    ) => {
        const dataToUpdate = (equipment === LensProposalEquipmentType.GENERAL) ? 
        tempGeneralEquipment : tempComplementaryEquipment;

        const newState: iLensProposalField[] = dataToUpdate.map( f => {
            if (f.field === field.field) {
                if(field.field=== "correct" && option.value !== 'personalized'){
                    if(!showPersonalized) setShowCorrect(true);
                    else setShowCorrect(!showCorrect);
                    if(showPersonalized)setShowPersonalized(true);
                }
                if(field.field=== "correct" && option.value === 'personalized'){
                    const condition = !showPersonalized;
                    setShowPersonalized(condition);
                }
                const isSameValue = (field.value?.value === option.value && !field.required) && unSelectSelected;
                return { ...f, value: !isSameValue ? option : null}
            }
            
            
            return f;
        });


        dispatch(prescriptionSlice.actions.updateLensEquipment({
            data: newState,
            equipment
        }));
    }
    
    const renderFiendOptions = (field: iLensProposalField, equipment: LensProposalEquipmentType) => {
        
        return field.options.map( (option ) => {
            // Note: filter options base on age category
            if( option.ageCategories && 
                !option.ageCategories.includes(ageCategory?.value as AgeCategoryType)) {
                return null;
            }
            if(field.field === "correct")
            {
                let renderData;
                if(option.value !== 'personalized'){
                    renderData = 
                    <li 
                        className={`list__item list__item--${field?.value?.value === option.value ? 'selected' : ''}`} 
                        onClick={() => onSelectFieldOption(equipment ,field, option)} 
                        key={option.value}>
                        
                        {t(option.label)}
                        <Checkbox checked={field?.value?.value === option.value}/>
                    </li>
                }
                else{
                    
                    renderData = 
                    <li 
                        className={`list__item list__item--${showPersonalized ? 'selected' : ''}`} 
                        onClick={() => onSelectFieldOption(equipment ,field, option)} 
                        key={option.value}>
                        
                        {t(option.label)}
                        <Checkbox checked={(showPersonalized) }/>
                    </li>
                }
                return renderData;
            }

            if(option.label === "Correct" || option.hidden)return null;

            return <li 
                        className={`list__item list__item--${field?.value?.value === option.value ? 'selected' : ''}`} 
                        onClick={() => onSelectFieldOption(equipment ,field, option)} 
                        key={option.value}>
                        
                        {t(option.label)}
                        <Checkbox checked={field?.value?.value === option.value}/>

                    </li>
        })
    }

    const renderFields = (fields: iLensProposalField[], section: LensProposalEquipmentType) => {   
        return fields.map((field: any) => (
            <Fragment key={field.field}>
                { field.label && <label className={E('list-label')}>{t(field.label)}</label> }
                <ul className="list">
                    {renderFiendOptions(field, section)}
                </ul>
            </Fragment>
        ))
        
    }

    return (
        <div className={B()}>
            
            <Button 
                click={back}
                text={t('demo_demo__back')} 
                icon="left-arrow-circle-gray" 
                iconPosition="left"
                type="secondary" 
                />


            <div className={E('scroll')}>

                <div className={E('section')}>
                    <label>{t('prescription_rx_gen_equipment')}</label>                
                </div>
                {renderFields(tempGeneralEquipment, LensProposalEquipmentType.GENERAL)}

                <div className={E('section')}>
                    <label>{t('prescription_rx_comp_equip')}</label>
                </div>
                {renderFields(tempComplementaryEquipment, LensProposalEquipmentType.COMPLEMENTARY)}

            </div> 
            
        </div>   
    )
}

export default PrescriptionLensProposal;