const LENS_THICKNESS_RULES = [
    {
        value: 1.5,
        range: {
            negative: {
                min: -1.75,
                max: 0
            },
            positive: {
                min: 0,
                max: 1.50
            }
        }
    },
    // {
    //     value: 1.56,
    //     range: null
    // },
    {
        value: 1.59,
        range: null
    },
    {
        value: 1.6,
        range: {
            negative: {
                min: -3.50,
                max: -2.00
            },
            positive: {
                min: 1.75,
                max: 2.75
            }
        }
    },
    {
        value: 1.67,
        range: {
            negative: {
                min: -5.00,
                max: -3.75
            },
            positive: {
                min: 3.00,
                max: 4.25
            }
        }
    },
    {
        value: 1.74,
        range: {
            negative: {
                min: -20.00,
                max: -5.25
            },
            positive: {
                min: 4.50,
                max: 20.00
            }
        }
    }
]

export {
    LENS_THICKNESS_RULES
}