const pricingBreakdown = {
    currency: '$',
    summaryTotal: 0,
    deliveryTotal: 0,
    isValidated: false,
    pair1: {
        enabled: true,
        categoryLens: 'Sun Vision',
        summaryPrice: 0,
        deliveryPrice: 0,
        discount: 0,
        discountPercentage: 0,
        lensThickness: 0,
        measures: 0,
        price: 0,
        correctPrice: 0,
        protectPrice: 0,
        protect2Price: 0,
        enhancePrice: 0,
        hasNocombi: false
    },
    pair2: {
        enabled: true,
        categoryLens: 'Sun Vision',
        summaryPrice: 0,
        deliveryPrice: 0,
        discount: 0,
        discountPercentage: 0,
        lensThickness: 0,
        measures: 0,
        price: 0,
        correctPrice: 0,
        protectPrice: 0,
        protect2Price: 0,
        enhancePrice: 0,
        hasNocombi: false
    }
}

export default pricingBreakdown;