import React from 'react';
import useBEM from '../utils/hooks/useBEM';
import Arrow from './ui/Arrow';

interface Props {
    title: string,
    open: boolean,
    onToggle?: () => void
}

const ToggleCard : React.FC<Props>  = ({
    title,
    open,
    onToggle,
    children
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('toggle-card');
    // HOOKS

    // METHODS
    const openClassModifier = () => {
        return !open ? [] : ['open']
    }

    return (
        <div className={B()}>
            <div className={E('title', openClassModifier())}>
                <label>{title}</label>
                <span className={E('arrow')} onClick={onToggle}>
                    <Arrow type={ open ? 'up' : 'down' } size="1rem"/>
                </span>
            </div>
            <div className={E('content', openClassModifier())}>
                {children}
            </div>
        </div>
    )
}

export default ToggleCard;