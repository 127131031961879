import React from 'react';
import iPrescriptionRule from '../../../interface/prescription-rules.interface';
import useBEM from '../../../utils/hooks/useBEM';
import { useDispatch, useSelector } from 'react-redux';
import lensThicknessSlice from '../../../store/slices/lens-thickness.slice';
interface Props {
    valid: boolean,
    validation: {
        valid: boolean,
        message: string
    },
    label?: string,
    value?: number | undefined | string,
    rules: iPrescriptionRule,
    readOnly: boolean,
    onChange?: (value: number) => void,
    parent?: 'lens-consultation'
}

const PrescriptionInput : React.FC<Props>  = ({
    valid,
    validation,
    label,
    value,
    rules,
    readOnly = false,
    onChange,
    parent
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription-input');    
    const dispatch = useDispatch()
    // METHODS
    const onChangeHandler = (event: any) => {
        const target = event.target;
        if(onChange)onChange(target.value);
    }

    const onBlurHandler = () => {
        dispatch(lensThicknessSlice.actions.changeError('prescription'))
        submitData()
    }

    const onKeyDownHandler = (event: any) => {
        const key = String(event.key).toLocaleLowerCase();
        if (key === 'enter') {
            submitData();
        }
    }

    const submitData = () => {
        if(!validation.valid || String(value).length === 0) return;
        let fieldValue = value;

        if(rules.suffix) fieldValue = String(fieldValue).replace(rules.suffix, '');

        let transformedData = incrementValue(Number(fieldValue));
        
        // Note: convert to 2 decimal
        if(transformedData || transformedData === 0) transformedData = Number(transformedData).toFixed(2);        
        
        // Note: append + sign
        if(rules.validation.requiredSign)transformedData = appendSign(transformedData);  

        // Note: append degrees sign
        if(rules.suffix && value )transformedData = transformedData + ' '+ rules.suffix;
        
        if(transformedData === value)return;
        if(onChange)onChange(transformedData)
    }
    
    const appendSign = (value: string) => {
        if(!value || value.length === 0)return value;

        const firstCharacter = value.substr(0,1);
        const withSign = ['-','+'].includes(firstCharacter);
        if(withSign || value === '0.00')return value;
        return `+${value}`;
    }
    
    const incrementValue = (data: number) => {
        if(rules.increment === 0)return data;

        // Note: get decimal value
        const isNegative = (data || 0) < 0;
        const fieldValue = Math.abs(data || 0);
        let decimalValue = fieldValue % 1;
        const floorValue = Math.floor(fieldValue);
        const incrementBase = Math.floor(decimalValue / rules.increment) * rules.increment;


        if( decimalValue > 0) {            
            const halfOfIncrement = rules.increment / 2;
            const difference = decimalValue - incrementBase;
            let finalValue:any = floorValue + 
                            incrementBase + 
                        ( halfOfIncrement > difference ? 0 : rules.increment );     
                        
           finalValue = rules.validation.requiredSign ? addSign(finalValue, isNegative) : finalValue;

            return finalValue;
        }
        return data;
    }

    const addSign = (data: number, isNegative: boolean) => {
        return `${isNegative ? '-' : '+'}${data}`
    }

    return (
        <div className={B(parent)}>
            
            { label && 
                <label className={E('label')}>
                    {label} {valid ? '.' : ''}
                </label> }
            
            <input 
                className={E('value')} type="text" value={(!value && String(value) !== '0') ? '' : value} 
                onBlur={onBlurHandler}
                onKeyDown={(event) => onKeyDownHandler(event)}
                onChange={(event) => onChangeHandler(event)}
                readOnly={readOnly}
            />
            <span className={E('content-measure')}>D</span>

            {
                !valid && !readOnly &&
                <span className={E('error')}>{validation.message}</span>
            }

        </div>   
    )
}

export default PrescriptionInput;