import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LENS_DEMO, TEMPORARY_PRODUCT_LIST } from '../../constants/products.constants';
import iLensDemo from '../../interface/lens-demo';
import iProductBrand from '../../interface/product-brand.interface';
import iProductDemo from '../../interface/product-demo';
import iProductDownloadInfo from '../../interface/product-download-info.interface';
import iProductFamily from '../../interface/product-family.interface';
import iProduct from '../../interface/product.inteface';
import ProductDemoType from '../../types/product-demo.type';
import DemoDownloadType from '../../types/product-download.type';
import iProductsStore from '../interface/products-store.interface';
import iRootState from '../interface/root-state.interface';

const initialState: iProductsStore  = {
    isOpen: false,
    downloadStatus: DemoDownloadType.WAITING,
    downloadCountText: '',
    demoDownloadStatus: {},
    preloadedProductDemos: [],
    view: 'selection',
    temporaryProductList: TEMPORARY_PRODUCT_LIST,
    lensDemo: LENS_DEMO,
    enabledProducts: [], 
    openBrand: '', // JUMP - CAN BE USED TO TARGET TO ONLY SHOW THE XR DEMO IN VARILUX PRODUCTS,
    selectedProduct: '',
    isQrCodeOpen: false,
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<boolean>) => ({...state, isOpen: action.payload }),
        changeDownloadStatus: (state, action: PayloadAction<DemoDownloadType>) => ({...state, downloadStatus: action.payload }),
        changeDownloadCount: (state, action: PayloadAction<string>) => ({...state, downloadCountText: action.payload }),
        updateDownloadStatus: (state, action: PayloadAction<{
            brandId: string,
            info: iProductDownloadInfo
        }>) => {
            const current = state.demoDownloadStatus || {};
            return {
                ...state,
                demoDownloadStatus: {
                    ...current,
                    [action.payload.brandId]: action.payload.info
                }
            }
        },
        resetDownloadPendingStatus: (state) => {
            const entries = Object.entries(state.demoDownloadStatus);
            let newState = {};
            entries.forEach(([key, value]) => {
                const newStatus = value.status === DemoDownloadType.COMPLETED ?
                DemoDownloadType.COMPLETED :
                DemoDownloadType.WAITING;
                newState = {
                    ...newState,
                    [key]: {
                        ...value,
                        status: newStatus
                    }
                }
            });

            return {
                ...state,
                demoDownloadStatus: newState
            }
        },
        productPreloadComplete: (state, action: PayloadAction<string>) => {
            const currentList = state.preloadedProductDemos || [];
            const found = currentList.includes(action.payload);            
            return {
                ...state,
                preloadedProductDemos: found ? state.preloadedProductDemos : [
                    ...currentList,
                    action.payload
                ],
                // JUMP
                selectedProduct: action.payload
            }
        },
        // JUMP
        clearSelectedProduct: (state) => {
            return {...state, selectedProduct: ''}
        },
        changeView: (state, action: PayloadAction<'selection' | 'demo'>) => ({...state, view: action.payload }),
        selectBrandTodemo: (state, action: PayloadAction<any>) => ({...state, brandToDemo: action.payload}),
        
        openBrandCard: (state, action: PayloadAction<string>) => ({
            ...state, 
            openBrand: state.openBrand === action.payload ? '' : action.payload
        }),
        
        selectProductTodemo: (state, action: PayloadAction<any>) => ({...state, productToDemo: action.payload}),
        selectProductToCompare: (state, action: PayloadAction<any>) => ({...state, productToCompare: action.payload}),
        updateProductList: (state, action: PayloadAction<any>) => {

            const products = action.payload;

            // consolidate enabled product ids
            let enabledProducts: string[] = [];
            products.forEach((family: any) => {
                family.brands.forEach((brand: any) => {
                    brand.products.forEach((product: any) => {
                        if(product.enabled){
                            enabledProducts.push(product.id);
                        }
                    });
                });
            });

            return {
                ...state,
                temporaryProductList: products,
                enabledProducts: enabledProducts
            }
        },

        attachedProductDemo: (state, action: PayloadAction<{
            demos: iLensDemo[],
            productList?: any[]
        }>) => {
            const {productList, demos} = action.payload
            const arrayOfProductWithId = <any>[]
            const arrayOfXperioAndTransitions = ['xperio_mirrors',
            'xperio_polarised',
            'xperio_tinted',
            'transitions_signature_gen_8',
            'transitions_signature_gen_8_iconic',
            'transitions_signature_gen_8_style',
            'transitions_signature_gen_8_classic',
            'transitions_classic',
            'transitions_xtractive',
            'transitions_xtractive_mirror',
            'xperio_tinted_mirrors',
            'transitions_style_mirrors',
            'xperio_polarised_mirrors'
        ]
            //appending the label and id to arrayOfProductWithId
            const labelFromTemporaryProductList = state.temporaryProductList.forEach( (family:any) => {
                family.brands.forEach((brands:any)=>{
                    brands.products.forEach((product:any)=>{
                        arrayOfProductWithId.push(
                            {
                            id:product.id,
                            label:product.label
                        })
                    })
                })
            })
            const getProductLabelById = (productId:string, product:any) => {
                const products = ['standard', 'xperio_polarised', 'varilux_digitime']
                if(productId && products.includes(productId) || productId && productId.includes('xperio_tinted'))return product.label
                const foundProductId = arrayOfProductWithId.find( (product:any) => product.id === productId)
                return foundProductId?.label
            }

            const getProductDemo = (id: string, label: string) => {
                return demos.filter( demo => {
                    if([
                        ProductDemoType.NESTED_DEMO_TRANSVERSAL,
                        ProductDemoType.NESTED_DEMO
                    ].includes(demo.demoType)){
                        let foundCount = 0;
                        demo.products.forEach( (lensDemo) => {
                            const found  = ((lensDemo as iLensDemo).products as iProductDemo[]).filter( p => p.id === id);
                            if(found.length > 0)foundCount += 1;
                        })
                        return foundCount > 0;
                    }
                    const found = (demo.products as iProductDemo[]).filter(product => product.id === id);
                    return found.length > 0;
                })
                // Note: replace the hardcoded label
                // put the product label from API
                .map( p => ({
                    ...p,
                    label,
                    products: p.products.map( (x:any) => {
                        if(!arrayOfProductWithId.length)return x;
                        if(x.id && (x.id.includes("standard") || arrayOfXperioAndTransitions.includes(x.id))) return x;
                        const isWithSubProducts = !!x.products;
                        return { 
                            ...x,
                            label:getProductLabelById(x.id, x),
                            //Note: get sub products label
                            ...(
                                isWithSubProducts ? {
                                    products: x.products
                                    .map( (sb:any) => {
                                        return {
                                            ...sb, 
                                            label: getProductLabelById(sb.id, sb)
                                        }
                                    })
                                } : {}
                            )
                         }

                    })
                }));
            }

            const newState = (state.temporaryProductList || productList ).map((family: iProductFamily) => (
                { ...family, brands: family.brands.map((brand: iProductBrand) => ({
                        ...brand, products: brand.products.map((p: iProduct) => ({
                            ...p,
                            demo: getProductDemo(p.id, p.label)
                        }))
                    }))
                }
            ));

            return {
                ...state,
                temporaryProductList: newState
            }
        },

        reset: (state) => ({
            ...state,
            brandToDemo: undefined,
            productToDemo: undefined,
            productToCompare: undefined,
            isOpen: false,
            view: 'selection',
            lensDemo: LENS_DEMO,
            demoDownloadStatus: state.demoDownloadStatus ? state.demoDownloadStatus : {},
            openBrand: '',
            selectedProduct: ''
        }),
        openQrCode: (state, action: PayloadAction<boolean>) => ({...state, isQrCodeOpen: action.payload}),
    }
});

export const getProducts = (state:iRootState) => state.products;
export default productsSlice;
