import React, { useState } from 'react';
import useBEM from '../../utils/hooks/useBEM';
import Arrow from './Arrow';

interface Props {
    value: number,
    min?: number;
    max?: number;
    withIndex?: boolean;
    minValue?: number;
    onChange?: (value: number) => void
}

const Islayder : React.FC<Props>  = ({
    min = 0,
    max = 10,
    withIndex = false,
    value,
    minValue,
    onChange
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('islayder');
    const [currentValue, setCurrentValue] = useState<any>()

    // HOOKS

    // METHODS
    const renderIndexGuide = () => {
        const indexes = new Array(max - (min + 1)).fill('').map( (i, index) => (<span key={index}/>) )
        return <div className={E('index')}>{indexes}</div>;
    }

    const onChangeHandler = (e: any) => {
        const newValue = Number(e.target.value);
        if(onChange){
            if(minValue && minValue > newValue)return;
            onChange(Number(newValue));
        }
    }

    const setThumbPosition = () => {
        let adjustment = (value === min) ? '0' : '-50%';
        if(value === max) adjustment = '-100%';

        return {
            left: `${((value * 10))}%`,
            transform: `translateX(${adjustment})`
        }
    }

    const setProgress = () => {
        return {
            width: `${((value * 10))}%`,
        }
    }

    const objectDynamicStyle = () => {
        return {
            'left': `calc(${value * 10}% - 12px)`
        }
    }

    return (
        <div className={B()}>

            <div className={E('thumb')} style={setThumbPosition()}>
                <Arrow type="right" size=".3rem"/>
            </div>
            <div className={E('progress')} style={setProgress()}/>

            <input type="range" min={min} max={max} onChange={(e) => onChangeHandler(e)}/>

            {
                withIndex &&
                renderIndexGuide()
            }

            <div className={E('tooltip')} style={objectDynamicStyle()}>
                <span>{value}h</span>
            </div>
        </div>
    )
}

export default Islayder;
