import React from 'react';

interface Props {
    value?: string | number,
    placeholder?: string,
    className?: string,
    maxlength?: number,
    onChange?: (event: any) => void,
    type?: 'default' | 'light' | 'light-gray',
    inputType?: 'text' | 'password' | 'number' | 'email' | 'date'
}

const InputText = React.forwardRef<HTMLInputElement, Props>(({
    placeholder,
    className,
    maxlength,
    onChange,
    value,
    type = 'default',
    inputType = 'text'
}, ref) => {

    const blockClass = 'input-text';
    const onChangeHandler = (event: any) => {
        if(onChange)onChange(event);
    }

    const dynamicClass = () => {
        const classes = [blockClass, className];
        classes.push(`${blockClass} ${blockClass}--${type}`);
        return classes.join(" ");
    }

    return <input 
            onChange={onChangeHandler}
            ref={ref} 
            placeholder={placeholder}
            type={inputType}
            className={dynamicClass()}
            maxLength={maxlength}
            value={value}
    />
    }
);

export default InputText;

