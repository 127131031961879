import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iLensDemo from '../../../interface/lens-demo';
import { getApp } from '../../../store/slices/app.slice';
import productDemoSlice, { getProductDemo } from '../../../store/slices/products-demo.slice';
import ProductDemoType from '../../../types/product-demo.type';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    type: ProductDemoType,
    demo: iLensDemo
}

const SeeThrougDemo : React.FC<Props>  = ({
    type,
    demo
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('see-throug-demo');
    const videoRef = useRef<any>();
    const [assetSource, setAssetSource] = useState<string | null>(null);
    const { activeProductDemo, syncVideoPlayFlag } = useSelector(getProductDemo);
    const dispatch = useDispatch();
    const [fadeOut, setFadeOUt] = useState(false);
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS
    useEffect(() => {
        return () => {
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(0));
        }
    }, [])

    useEffect(() => {
        if(syncVideoPlayFlag >= 2 && videoRef.current) {
            setTimeout(() => {
                videoRef?.current?.play();
            }, 1000)
        }
    }, [syncVideoPlayFlag])

    useEffect(() => {
        if(!videoRef.current)return;
        videoRef.current.pause();
        videoRef.current.currentTime = 0;    
        setTimeout(() => {
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(null));
        });
    }, [assetSource])

    useEffect(() => {        
        getAssetSource();        
    }, [activeProductDemo])

    // METHODS

    const getAssetSource = () => {
        setAssetSource(null);

        setTimeout(async () => {
            const asset = await get('lensDemoStore', `${demo?.demo}_standard`);
            if(!asset)return
            setAssetSource(asset.asset);
        },0)
    }

    const mouseDown = () => {
        setFadeOUt(true);
        dispatch(productDemoSlice.actions.activateSeeThrough(true));
    }

    const mouseUp = () => {
        setFadeOUt(false);
        dispatch(productDemoSlice.actions.activateSeeThrough(false));
    }

    return (
        <div 
            className={B([type, fadeOut ? 'fade-out' : ''])} 
            onMouseDown={mouseDown} 
            onMouseUp={mouseUp}
            onTouchStart={mouseDown} 
            onTouchEnd={mouseUp}
            onContextMenu={(e) => {e.preventDefault(); return false}}
        >

            { assetSource && type === ProductDemoType.SEE_THROUGH_VIDEO &&
                <video 
                    ref={videoRef}
                    className={E('video')}
                    playsInline
                    autoPlay={false} loop={true}  muted>
                    <source src={assetSource} type="video/mp4"/> 
                    Your browser does not support the video tag.
                </video> 
            }

            <div className={E('circle')}>
                <div className="animation"></div>
                <label>{t('click_click_demo')}</label>
            </div>
        </div>
    )
}

export default SeeThrougDemo;