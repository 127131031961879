const landingCards = [
    { image: 'education-a', label: 'education_edu_eye_new', icon: 'eye', id: 'eye', soon: true},
    { image: 'education-b', label: 'education_edu_lens_new', soon: true },
    { image: 'education-c', label: 'Frame advices', soon: true }
];

const controlOptions = {
    visualDefect1: [
        { label: 'Myopia', value: 'myopia' },
        { label: 'Hyperopia', value: 'hyperopia' },
        { label: 'Astigmatism', value: 'astigmatism' },
        { label: 'Presbyopia', value: 'presbyopia' },
    ],
    visualDefect2: {
        myopia: [
            { label: 'Astigmatism', value: 'astigmatism' },
            { label: 'Presbyopia', value: 'presbyopia' },
        ],
        hyperopia: [
            { label: 'Astigmatism', value: 'astigmatism' },
            { label: 'Presbyopia', value: 'presbyopia' },
        ],
        presbyopia: [
            { label: 'Myopia', value: 'myopia' },
            { label: 'Hyperopia', value: 'hyperopia' },
            { label: 'Astigmatism', value: 'astigmatism' },
        ],
        astigmatism: [
            { label: 'Presbyopia', value: 'presbyopia' },
        ],
        none:[]
    },
    item: [
        { label: 'Standard', value: 'standard' },
        { label: 'Car', value: 'car' },
        { label: 'Flower', value: 'flower' },
    ],
    environment: [
        { label:'education_edu_sunlight', value: 'sunlight' }, 
        {label:'education_edu_night', value: 'night'}
    ],
    screenMode: [
        { label:'Simplify', value: 'simplify' }, 
        {label:'Environment', value: 'environment'}
    ]
}


export {
    controlOptions
};
export default landingCards;