import React, { useEffect, useState } from 'react';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';
import isummaryDetails from '../../../interface/summary.interface';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import PROTECT_LENS_DEMO from '../../../constants/lens-demo/lens-protect-summary.constants';
import XPERIO_SUNFIT_SUMMARY from '../../../constants/xperio-sunfit-benefit.constants';
import useGoto from '../../../utils/hooks/useGoto';
import routes from '../../../constants/routes.constant';
import LensPairType from '../../../types/lens-pair.type';

interface Props {
    data: isummaryDetails[] | any,
    title?: string,
    pair: number
}

const SummaryDetails : React.FC<Props>  = ({
    data,
    title,
    pair
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('summary-details');
    const { t } = useTranslation(useSelector(getApp));
    const goto = useGoto()
    const [assetSource, setAssetSource] = useState<string | undefined>("");
    const [isColorSelected, setColorSelected] = useState(false);
    const { lensRecommendation, activePair } = useSelector(getLensConsultation);
    const appStore = useSelector(getApp);
    const { experience, accountDetails } = appStore;
    const [isSunfit, setSunfit] = useState(false);
    const dispatch = useDispatch();
    const isSlovakia = accountDetails?.shop.country === 'sk'
    // HOOKS

    useEffect(() => {
        if (!lensRecommendation) return;
        const active = lensRecommendation[activePair];

        if (
            !active?.protect ||
            !active?.protect.length ||
            !active?.protect[0].color
        ) {
            setColorSelected(false);
            return;
        }

        setColorSelected(true);
        const selectedColor = active?.protect[0].color;
        const idColor = selectedColor.id;
        const test = PROTECT_LENS_DEMO.find((demo) => demo.id == idColor)
        const colorUrl = test?.url;
        setAssetSource(colorUrl);

    }, [lensRecommendation])

    // METHODS
    const groupBy = (data: any[], type: number) =>{
        const newData = data.filter((
            filter) =>
            parseInt(filter.type) === type )
        return newData;
    }
    const validateExisting = (data: any, order: number) =>{
        if(data === undefined || data.products.length === 1) return '';
        return 'translate';


    }

    const isSunProtect = (id: any) => {
        if (id === 'protect' && experience === 'sun') {
            return true;
        } else { return false }
    }
    const modify = (pair: number, isLensThickness: boolean) => {
        dispatch(lensConsultationSlice.actions.changePair(pair == 1 ? LensPairType.PAIR1 : LensPairType.PAIR2))
        dispatch(lensConsultationSlice.actions.setIsLensThickness(isLensThickness))
        isLensThickness && dispatch(lensConsultationSlice.actions.setSliderPage(3))
        goto.route(routes.lensConsultation)
    }

    return (
        <div className={B()} id={'pair' + pair}>
            <div className={E('wrapper')}>
                <div className={E('data')}>
                    {data.map((data: any, i: number) => (
                        data && data !== undefined && (
                            <div className={E('lens')} key={i}>
                                <div className={E('lens-type ' + data.id + ' ' + validateExisting(data, i))}>
                                    <label>{t(data.title)}</label>
                                    <span onClick={() => modify(pair, false)} className={E('icons-container')}>
                                    {/* temporary hidden */}
                                    {/* {data.products.map((product:any)=>{
                                        if(product?.title?.toLowerCase().includes('transitions') && !isSlovakia) 
                                        return (
                                            <div className={E('qr-code-icon')}>
                                                <span>{t('vto_transitions_label')}</span>
                                                <Icon name='vtoqrcode'/>
                                            </div>
                                        )
                                    })} */}
                                        {/* {
                                            isSunExp ?
                                            <Icon name={iconExp()} /> : */}
                                            <Icon name='pencil-circle-gray' /> 
                                        {/* } */}
                                    </span>
                                </div>
                                <div className={E('lens-products ' + data.id)}>
                                    {data.products.map((product: any, ii: number) => (
                                        <div className={E('lens-product ' + data.id)} key={ii}>
                                            {isSunProtect(data.id) ? <label>{XPERIO_SUNFIT_SUMMARY.title}</label> : <label>{t(product.title)}</label>}
                                            <div className={E('lens-desc')}>
                                                {product.descriptions && (
                                                    <ul>
                                                        {groupBy(isSunProtect(data.id) ? XPERIO_SUNFIT_SUMMARY.descriptions : product.descriptions, 1).map((desc: any, iii: number) => (
                                                            <li key={iii}>
                                                                <Icon type="png" name={`${desc.icon}-blue`} />
                                                                <label>
                                                                    {
                                                                        (Array.isArray(desc.label)) ? t(desc.label[0], desc.label[1]) : t(desc.label)
                                                                    }
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                {(product.descriptions && (product.descriptions.length) > 3 || isSunProtect(data.id)) && (
                                                    <ul>
                                                        {groupBy(isSunProtect(data.id) ? XPERIO_SUNFIT_SUMMARY.descriptions : product.descriptions, 2).map((desc: any, iii: number) => (
                                                            <li key={iii}>
                                                                <Icon type="png" name={`${desc.icon}-blue`} />
                                                                <label>
                                                                    {
                                                                        (Array.isArray(desc.label)) ? t(desc.label[0], desc.label[1]) : t(desc.label)
                                                                    }
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    ))
                    }
                    {data[0] && (
                        <div className={E('lens')}>
                            <div className={E('lens-thickness')}>
                                <label>{t('summary_sum_lens_thickness')}</label>
                                <span onClick={() => modify(pair, true)}>
                                    <Icon name="pencil-circle-gray" />
                                </span>
                            </div>
                            <span className={E('lens-thickness-calculation')}>{data[0].thickness}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SummaryDetails;
