import React, { useEffect, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    onComplete?: () => void,
    trigger?: 'init' | 'class'
}

const LensTransitionInAnimation : React.FC<Props>  = ({
    onComplete,
    trigger = 'init'
}) =>  {
    // VARIABLES
    const [B] = useBEM('lens-transition-in-animation');
    const [fadeOut, setFadeOut] = useState(false);

    // HOOKS
    useEffect(() => {
        setTimeout(() => {
            setFadeOut(true);

            setTimeout(() => {
                if(onComplete)onComplete();
            }, 500);
        }, 2000);
    }, [])
    

    return (
        <div className={B( [fadeOut? 'fade-out' : '', trigger] )}></div>
    )
}

export default LensTransitionInAnimation;