import React from 'react';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    id: string,
    label: string,
    value?: string,
    onClick?: () => void,
    onClear?: () => void,
}

const AdvanceSettingFilterField : React.FC<Props>  = ({
    id,
    label,
    value,
    onClick,
    onClear
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('advance-setting-filter-field');

    // HOOKS
    const onClickHandler = () => {
        if(onClick)onClick();
    }

    const onClearHandler = () => {
        if(onClear)onClear();
    }


    // METHODS

    return (
        <div className={B()}>
            <label className={E('label')}>{label}</label>
            <div>
                <input value={value} className={E('input')}type="text" readOnly onClick={onClickHandler}/>
                
                {
                    value ?
                    <Icon className={E('clear')} name="close-gray" type="svg" click={onClearHandler}/> :
                    <Icon className={E('arrow')} name="right-arrow-circle" type="svg" click={onClickHandler}/>
                }

            </div>
        </div>
    )
}

export default AdvanceSettingFilterField;