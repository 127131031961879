import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import ENDPOINTS from '../../../constants/endpoints';
import { LENS_THICKNESS_RULES } from '../../../constants/lens-thickness.constants';
import routes from '../../../constants/routes.constant';
import iLensColor from '../../../interface/lens-color.inteface';
import iPrescriptionData from '../../../interface/prescription-data.interface';
import iProductBrand from '../../../interface/product-brand.interface';
import iProductFamily from '../../../interface/product-family.interface';
import iProduct from '../../../interface/product.inteface';
import iSubProduct from '../../../interface/sub-product.inteface';
import { getApp } from '../../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import lensThicknessSlice, { getlensThickness } from '../../../store/slices/lens-thickness.slice';
import { getProducts } from '../../../store/slices/products.slice';
import summarySlice, { getSummary } from '../../../store/slices/summary.slice';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import LensFamilyType from '../../../types/lens-family.type';
import LensPairType from '../../../types/lens-pair.type';
import useBEM from '../../../utils/hooks/useBEM';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import useGoto from '../../../utils/hooks/useGoto';
import usePricing from '../../../utils/hooks/usePricing';
import useTranslation from '../../../utils/hooks/useTranslation';
import Utils from '../../../utils/Utils';

import { fetchProductCombi, fetchProductDetailsViaId, fetchSubProductDetailsViaSubProductId } from '../../../utils/misc';
import ProtectColorPicker from '../../lens-consultation/components/ProtectColorPicker';
import SubProductsDropdown from '../../lens-consultation/components/SubProductsDropdown';
import useLensThickness from '../../lens-thickness/useLensThickness';
import LensFamily from './LensFamily';
import axios from 'axios';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import Alert from '../../../components/ui/Alert';
import useEprom from '../../../utils/hooks/useEprom';
import useProducts from '../../../utils/hooks/useProducts';
import { LENS_COLOR_MODIFICATIONS } from '../../../constants/lens-consultation-modified-colors';

interface Props {

}

const LensRecommendation: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM('lens-recommendation');
    const productStore = useSelector(getProducts);
    const { temporaryProductList } = productStore;
    const dispatch = useDispatch();
    const { lensRecommendation, activePair, sliderPage, family, temporaryByPassProduct } = useSelector(getLensConsultation);
    const [validatePackageOfferModal, setValidatePackageOfferModal] = useState(false);
    const goto = useGoto();
    const ga = useGoogleAnalytics();
    const { isNullUndefined } = Utils();
    const { summaryList1, summaryList2 } = useSelector(getSummary);
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const { productCombi, accountDetails } = useSelector(getApp);
    const {
        getRecommendedSubProductByIndex,
        getRecommendedLensThickness,
        filterSubProductsWithEnabledIndex
    } = useLensThickness();
    const { prescription, measures } = useSelector(getUser);
    const { getBreakdown } = usePricing();
    const { tempBaseLensThicknessCalculation } = useSelector(getlensThickness);
    const {
        getMeasuresHighestValues,
        getLeftAndRightPrescription,
        getRequestType,
        skipEPROMValidation
    } = useLensConsultation();
    const [isConfirmEPROM, setConfirmEPROM] = useState(false);
    const online = navigator.onLine;
    const {
        getAvailableColors,
        getEpromApiEndpoint,
        isValidOnEPROM,
        isValidOnEpromBO
    } = useEprom();
    const { getColorIdPrefix } = useProducts(productStore);
    let country = accountDetails?.shop.country

    // HOOKS
    useEffect(() => {

        if (lensRecommendation?.pair1 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair1,
                pair: LensPairType.PAIR1
            }));
        }

        // dispatch(userSlice.actions.saveSummary1(summaryList1)); 
    }, [lensRecommendation?.pair1]);

    useEffect(() => {
        dispatch(lensConsultationSlice.actions.setValidOnEPROM(true))
    }, [])

    useEffect(() => {
        if (lensRecommendation?.pair2 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair2,
                pair: LensPairType.PAIR2
            }));
        }

        // dispatch(userSlice.actions.saveSummary2(summaryList2)); 
    }, [lensRecommendation?.pair2]);

    useEffect(() => {
        if (validateLensIfEmpty()) {

            const breakdown = getBreakdown()
            dispatch(lensConsultationSlice.actions.setBreakdown(breakdown));
        }
    }, [lensRecommendation])

    useEffect(() => {
        if (!lensRecommendation || sliderPage !== 0) return;
        const lastGlobalEvent = sessionStorage.getItem('GLOBAL_EVENT');
        if ('CHANGE_PROPOSAL' === lastGlobalEvent) {
            sessionStorage.setItem('GLOBAL_EVENT', '');
            return;
        }

        const isWithRX = prescription.values.sphere.od ? true : false;

        const family = lensRecommendation[activePair];
        const pair = activePair === LensPairType.PAIR1 ? 0 : 1;
        if (
            !family || !family.correct ||
            !family.correct.subProduct
        ) {
            clearLensBaseThickness(activePair);
            return;
        }

        const productCode = family.correct.product?.id;
        const skipEPROM = skipEPROMValidation(
            accountDetails?.shop.country as string
            , productCode as string,
            family.correct.subProduct.code as string
        );
        const isValidateEPROM = (accountDetails?.shop.eprom as boolean) && !skipEPROM;
        const data = {
            index: family.correct.subProduct.index,
            product_code: handleProductCode(productCode as string),
            sub_product_code: handleSubProductCode(family.correct.subProduct.code as string),
            prescription: isWithRX ? getLeftAndRightPrescription(prescription.values) : null,
            measures: getMeasuresHighestValues(measures, pair),
            request_type: getRequestType(isWithRX, isValidateEPROM)
        }

        axios.post(getEpromApiEndpoint(), { data }).then(x => {
            if (isValidateEPROM) {
                const isBelgium = accountDetails?.shop.country === "be";
                const productIdsToCheck = getProductIdsToCheck(family, isBelgium);
                const validProducts = x.data.data.eprom_recommendation;
                isValidOnEPROM(productIdsToCheck, validProducts, (result: boolean) => {
                    const correctSubProductId = family.correct.subProduct?.code;
                    let validOnEpromBO = correctSubProductId ? checkIfValidOnEpromBO(family, correctSubProductId) : true;
                    if (lensRecommendation && accountDetails?.shop.country === 'be') {
                        const protect = lensRecommendation[activePair]?.protect
                        if (protect && protect[1] &&
                            (protect[1]?.product?.id === 'xperio_mirrors' || protect[0]?.product?.id === 'xperio_mirrors')) {
                            setConfirmEPROM(false)
                            dispatch(lensConsultationSlice.actions.setValidOnEPROM(true))
                        }
                        else {
                            if (correctSubProductId === "eyezen_kids" || correctSubProductId === "stellest" || correctSubProductId === "kids_standard_design") {
                                setConfirmEPROM(false)
                                dispatch(lensConsultationSlice.actions.setValidOnEPROM(true))
                            }
                            else {
                                setConfirmEPROM(!(result && validOnEpromBO));
                                dispatch(lensConsultationSlice.actions.setValidOnEPROM(result && validOnEpromBO))
                            }
                        }
                    }else{
                        setConfirmEPROM(!(result && validOnEpromBO));
                        dispatch(lensConsultationSlice.actions.setValidOnEPROM(result && validOnEpromBO))
                    }
                })
            }

            if(
                x.data.statuscode !== 200 ||
                !x.data.data.lens_thickness ||
                Object.keys(x.data.data.lens_thickness).length === 0
            ){
                clearLensBaseThickness(activePair);
                return;
            }

            const {
                max_edge_thk,
                weight,
                center_thk
            } = x.data.data.lens_thickness;

            dispatch(lensThicknessSlice.actions.saveBaseLensThickness({
                pair: activePair,
                data: {
                    index: family?.correct?.subProduct?.index as number,
                    productCode: data.product_code as string,
                    edge: max_edge_thk,
                    weight: weight,
                    center: center_thk,
                    prescription: data.prescription,
                    measures: data.measures
                }
            }))
        })
    }, [lensRecommendation, activePair])

    //belgium additional business rule validate
    useEffect(() => {
        const productsBelgium = [
            'transitions_xtractive',
            'transitions_xtractive_polarised',
            'xperio_polarised',
            'xperio_tinted',
            'xperio_mirrors',
            'xperio_tinted_mirrors',
            'xperio_polarised_mirrors',
            'transitions_style_mirrors']

        if (lensRecommendation) {
            const protect = lensRecommendation[activePair]?.protect
            const enhance = lensRecommendation[activePair]?.enhance
            if (accountDetails?.shop.country === 'be' &&
                protect &&
                protect[0] &&
                protect[0]?.product?.id &&
                enhance &&
                enhance?.product?.id
            ) {
                setConfirmEPROM(enhance?.product?.id === 'crizal_sun_xprotect' && !productsBelgium.includes(protect[0]?.product?.id))
            }
        }
    }, [lensRecommendation, activePair, sliderPage])

    // METHODS

       // Rule: Substitute Varilux X Series product_codes and sub_product_codes when XR Series is selected
    // Only applicable for Varilux XR series
    const handleProductCode = (productCode: string) => {
        if (productCode === "varilux_xr_series") {
            return "varilux_x_series"
        } else {
            return productCode
        }
    }
    const handleSubProductCode = (subProductCode: string) => {
        switch (subProductCode) {
            case "varilux_xr_design":
                return "varilux_x_design";
            case "varilux_xr_design_short":
                return "varilux_x_design_short";
            case "varilux_xr_fit":
                return "varilux_x_f360";
            case "varilux_xr_track":
                return "varilux_x_track";
            case "varilux_xr_4d":
                return "varilux_x_4d";
            case "varilux_xr_pro":
                return "varilux_xclusive_4d";
            
            default:
                return subProductCode;
        }
    }


    const checkIfValidOnEpromBO = (family: any, correctId: string) => {
        let isValid = true;
        if (family.protect && family.protect.length) {
            const ids = family.protect.map((p: any) => p.product?.id) as string[];
            const result = isValidOnEpromBO(correctId, ids, 'masstint');
            if (!result) isValid = false;
        }

        if (family.enhance) {
            const ids = [family.enhance.product?.id] as string[];
            const result = isValidOnEpromBO(correctId, ids, 'treatment');
            if (!result) isValid = false;
        }
        return isValid;
    }

    const getProductIdsToCheck = (family: any, checkEnhance = false) => {
        const protectIdsToCheck = family?.protect ? family?.protect.map((p: any) => p.product?.id) : [];
        const enhanceIdsToCheck = (family?.enhance && checkEnhance) ? [family?.enhance.product?.id] : [];
        return [
            ...protectIdsToCheck,
            ...enhanceIdsToCheck
        ]
    }

    const withCorrect = () => {
        if (!lensRecommendation) return false;
        return lensRecommendation[activePair]?.correct ? true : false;
    }

    const validateLensIfEmpty = () => {
        return lensRecommendation?.pair1?.correct === null ? false : true
    }

    const clearLensBaseThickness = (pair: LensPairType) => {
        dispatch(lensThicknessSlice.actions.saveBaseLensThickness({
            pair: pair,
            data: undefined
        }))
        dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
            pair: pair,
            data: undefined
        }))
    }

    const eventHander = (event: 'select' | 'delete', family: iProductFamily, index: number) => {
        if(event === 'select'){
            dispatch(lensConsultationSlice.actions.setSliderPage(1));
            dispatch(lensConsultationSlice.actions.selectProductFamily(family));
            return;
        }

        if (event === 'delete') {
            dispatch(lensConsultationSlice.actions.setShowDialogue(false))
            dispatch(lensConsultationSlice.actions.removeProduct({
                lensFamily: family.id,
                index: family.id === LensFamilyType.PROTECT ? index : undefined
            }));
        }
    }

    const getRecommendedValue = (type: LensFamilyType, all = false, country?: string) => {
        if (!lensRecommendation) return;
        const pair = lensRecommendation[activePair];
        const pairType: any = pair ? pair[type] : null;

        if (!pairType) return null;

        if (type === LensFamilyType.PROTECT) {
            if (pairType && (pairType as any[]).length > 0) {

                if (country) {
                    let product = pairType[0]?.product.id
                    let checkCountry = LENS_COLOR_MODIFICATIONS?.[country] ? true : false
                    let checkProduct = LENS_COLOR_MODIFICATIONS?.[country]?.[product] ? true : false

                    if (checkCountry && checkProduct) {
                        let currentData = pairType[0]
                        let newData = {
                            ...currentData,
                            product: {
                                ...currentData.product,
                                availableColors: LENS_COLOR_MODIFICATIONS?.[country]?.[currentData.product.id],
                                colors: LENS_COLOR_MODIFICATIONS?.[country]?.[currentData.product.id]
                            }
                        }
                        return [newData]
                    } else {
                        return pairType
                    }

                } else {
                    return pairType;
                }
            }
            return null;
        }
        if (all) return pairType;
        return pairType.subProduct ? pairType.subProduct : pairType.product;
    }

    const selectSubProduct = (brand: iProductBrand, product: iProduct, subProduct: iSubProduct) => {

        let recommendedSubProduct;
        if (prescription?.values?.sphere && product.subProducts) {
            const sphere = prescription.values.sphere;
            const thickness = getRecommendedLensThickness({
                od: sphere.od as number,
                os: sphere.os as number
            }, LENS_THICKNESS_RULES);
            const subProducts = filterSubProductsWithEnabledIndex(
                product.subProducts.filter(x => x.label === subProduct.label),
                appStore.lensThicknessSettings);
            recommendedSubProduct = getRecommendedSubProductByIndex(subProducts, thickness);
        }
        if(activePair === 'pair2'){
            dispatch(lensConsultationSlice.actions.setShowDialogue(false))
        }
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            brand,
            product,
            subProduct: recommendedSubProduct || subProduct
        }));
    }

    const onSelectProtectColor = (color: iLensColor, index: number) => {
        dispatch(lensConsultationSlice.actions.setProtectColor({
            color,
            index,
        }))
    }

    const renderExtraComponent = (family: LensFamilyType, index: number, p: any) => {
        const recommendedValue = getRecommendedValue(family, true, country);
        if (!recommendedValue) return null;
        if (!validateProduct(p)) return null;

        if (family === LensFamilyType.CORRECT && !recommendedValue.subProduct) {
            return <SubProductsDropdown
                options={recommendedValue.product.subProducts}
                onSelect={(subProduct) => selectSubProduct(
                    recommendedValue.brand,
                    recommendedValue.product,
                    subProduct)} />
        }

        if (family === LensFamilyType.PROTECT) {
            const availableColors = recommendedValue[index].product.availableColors
            const colors = recommendedValue[index].product.colors
            let finalColors;
            if(availableColors && availableColors.length > 0 && !availableColors.includes(null)) finalColors = availableColors
            else finalColors = colors
            if (!finalColors) return null;
            return <ProtectColorPicker
                selected={recommendedValue[index].color}
                onSelect={(color) => onSelectProtectColor(color, index)}
                colors={filterColorsOnEPROM(finalColors, index)}
                brand={recommendedValue[index].product.id}
            />
        }

        return null;
    }

    const filterColorsOnEPROM = (colors: any[], index: number) => {
        if (!lensRecommendation || !accountDetails?.shop.eprom) return colors;
        const family = lensRecommendation[activePair];
        const correct = family?.correct?.subProduct?.code;
        const protect = family?.protect[index].product?.id;
        if (!correct || !protect) return colors;
        const available = getAvailableColors(correct, protect);

        const removeSpace = (txt: string) => txt.replace(/\ /g, '').toLowerCase();
        const colorIdPrefix = getColorIdPrefix(protect);

        const filtered = colors.filter(x => {
            return available.filter(c => {
                const idWithoutPrefix = x.id.replace(colorIdPrefix, '');
                return idWithoutPrefix.toLowerCase() === removeSpace(c);
            }).length;
        });
        return filtered;
    }

    const validateProduct = (p: any) => {
        return p && p.id !== undefined ? true : false;
    }

    const gotoPackageOffer = () => {
        const proceed = isLensRecommendationValid()

        if (!proceed) {
            setValidatePackageOfferModal(true);
            return;
        }

        ga.event({
            category: 'Lens Consulation',
            action: 'Button Click',
            label: 'Packaged Offer'
        })

        goto.route(routes.packageOffer);
    }

    const isLensRecommendationValid = () => {
        const selectedPair = activePair === 'pair1' ? lensRecommendation?.pair1?.correct : lensRecommendation?.pair2?.correct
        const isPairNotValid = selectedPair?.subProduct === null || selectedPair?.subProduct === undefined

        return !isPairNotValid
    }

    const renderLensFamily = (family: iProductFamily) => {

        const value = getRecommendedValue(family.id);
        const products = Array.isArray(value) ?
            value.map(x => x.product) :
            [value];


        return (
            <Fragment key={family.id}>
                {products.map((p, index) => (
                    <LensFamily
                    noLabel={index > 0}
                    allowMultiple={
                        family.id === LensFamilyType.PROTECT &&
                        products.length === 1
                    }
                    key={`${family.id}${index}`}
                    extraComponent={renderExtraComponent(family.id, index, p)}
                    family={family}
                    onEvent={(event, family) => eventHander(event, family, index)}
                    value={validateProduct(p) ? p : false}
                    disabled={
                        !p &&
                            accountDetails?.shop.eprom &&
                            !withCorrect() &&
                            family.id !== LensFamilyType.CORRECT
                        }
                    />
                ))}
            </Fragment>
        )
    }

    const confirmEPROM = (id: string) => {
        setConfirmEPROM(false);
        if (id === 'rx') {
            goto.route(routes.prescription);
        }
    }

    const removeFromCountries = () =>{
        const country = accountDetails?.shop.country
        return country === 'it' || country === 'sk'
    }

    const byPassXR = () => {
        let isVariluxXRRecommended = (lensRecommendation?.pair1?.correct?.product?.id) === 'varilux_xr_series'
        let isXRSubProductSelected = (lensRecommendation?.pair1?.correct?.subProduct) === undefined ? false : true
        if (lensRecommendation?.pair1?.correct?.subProduct === undefined) return false
        const xrProductsCode = [
            'varilux_xr_design',
            'varilux_xr_design_short',
            'varilux_xr_fit',
            'varilux_xr_track',
            'varilux_xr_4d',
            'varilux_xr_pro'
        ]
        return xrProductsCode.includes(temporaryByPassProduct as string) || isVariluxXRRecommended
    }

    return (
        <div className={B()}>
            {  !removeFromCountries() &&
                <div className={E('package-offer')} onClick={() => gotoPackageOffer()}>
                {t('lens_consultation_lc_packaged_offer')}
                <div className={E('package-offer-icon')}></div>
            </div>
            }

            <div className={E('lens-families')}>
                {
                    temporaryProductList.map((family: iProductFamily) => renderLensFamily(family))
                }
            </div>

            <Modal show={validatePackageOfferModal} height="32.5rem" onClose={() => setValidatePackageOfferModal(false)}>
                <div className={E('modal')}>
                    <div className={E('modal-icon')}>
                        <Icon name="exclamation" />
                    </div>
                    <p className={E('modal-text')}>
                        {/* To have access to Packaged Offer<br/> you must fill in at least one information. */}
                        {t('packaged_offer_popup_po_popup_info')}
                    </p>
                    <Button
                        click={() => setValidatePackageOfferModal(false)}
                        text={t('packaged_offer_popup_po_popup_fill_lc')}
                        type="secondary" weight="normal" />
                </div>
            </Modal>

            {
                !byPassXR() && (
                    <Alert
                        show={isConfirmEPROM}
                        message={t('eprom_popup')}
                        buttons={[
                            { label: t('eprom_popup_change_proposal'), id: 'proposal' },
                            { label: t('eprom_popup_change_rx'), id: 'rx' }
                        ]}
                        onButtonSelect={(id) => confirmEPROM(id)}
                    />
                )
            }


            {
                !online && accountDetails?.shop.eprom &&
                <p className={E('offline-eprom')}>
                    {t('eprom_offline_message')}
                </p>
            }

        </div>
    )
}

export default LensRecommendation;