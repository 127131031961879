import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../store/slices/app.slice';
import useTranslation from '../../utils/hooks/useTranslation';

interface Props {
    label?: string, 
    placeholder?: string,
    value?: string,
    readOnly?: boolean,

    // Temporary
    validation?: any[],
        // { regex: any, errorMessage: string },
        // { required: boolean, errorMessage: string }
    // Temporary
    onValidate?: (valid: boolean) => void,

    onChange?: (value: string) => void
}

const MaterialTextInput : React.FC<Props>  = ({
    label,
    placeholder,
    value,
    readOnly = false,
    validation,
    onChange,
    //TEMP
    onValidate
}) =>  {
    // VARIABLES
    // const ref = useRef<HTMLInputElement>(null);
    const [error , setError] = useState<boolean>(false);
    const [errorMessage , setErrorMessage] = useState<string[]>([]);
    const {t} = useTranslation(useSelector(getApp));

    // HOOKS
    useEffect(() => {
        //Note: Temporary validation
        //Todo: create generic validation
        if(validation && validation.length > 0){
            setErrorMessage([]);
            setError(false);

            validation.forEach( (validate: any) => {
                if(validate.required && (!value || value.length === 0)){
                    setErrorMessage( prev => [ ...prev, validate.errorMessage ])
                    setError(true);
                }

                if(validate.regex && value && value.length > 0){
                    const test = validate.regex.test(String(value).toLowerCase());
                    setError(!test);
                    if(!test) {
                        setErrorMessage( prev => [ ...prev, validate.errorMessage ])
                    }
                }
            });
        }

    }, [value])

    useEffect(() => {
        if(onValidate)onValidate(error);
    }, [error])

    // METHODS
    const inputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        // setValue(event.target.value);
        if(onChange)onChange(event.target.value);
    }

    return (
        <div className={`material-textinput ${value ? 'material-textinput--with-value' : ''}`}>
            <input 
                // ref={ref} 
                readOnly={readOnly}
                value={value}
                onChange={inputOnChange}
                className="material-textinput__input" type="text" placeholder={placeholder}/>

            { label && 
                <label className="material-textinput__label">{label}</label>
            }

            {
                error &&
                <span className="material-textinput__error-message">
                    {t(errorMessage[0])}
                </span>
            }
        </div>
    )
}

export default MaterialTextInput;