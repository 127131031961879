import React from 'react';
import { backgroundPerRow, roundCorner } from '../../constants/email-css.constants';
import iAppStore from '../../store/interface/app-store.interface';
import useTranslation from '../../utils/hooks/useTranslation';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderSummary from './components/HeaderSummary';
import Prescription from './components/Prescription';
interface Props {
    summaryList1: any;
    summaryList2: any;
    breakdown: any;
    tempPrescriptionData: any;
    appStore: iAppStore,
    complementaryEquipment: any,
}

const DeliveryTemplate : React.FC<Props>  = ({summaryList1, summaryList2, tempPrescriptionData, appStore,breakdown,
    complementaryEquipment}) =>  {
    //VARIABLES
    const { t } = useTranslation(appStore);
    //HOOKS
    //METHOD
    const onNull = (list:any) =>{
        if(!list) return false;
        const arr = list.filter((el: any) => el !== null);
        const condition = arr.length !== 0 && arr !== undefined         
        ? true : false;
        return condition;
    }
    return (
        <div style={{backgroundColor: "#d9edf9"}}>
            <div style={{backgroundColor: "#d9edf9"}}><br></br></div>
            <div style={{backgroundColor: "#d9edf9"}}><br></br></div>
            {/* rounded corners */}
            <div 
             style={{paddingRight: "0px", paddingLeft: "0px", textAlign: "center"}}>
					<img src="https://i.imgur.com/qpK2SF8.png" alt="" style={roundCorner} width="650"/>
							
                <div style={backgroundPerRow}>            
                    <Header title={t('email__hello')} description={t('email__summary_exp')}></Header>
                </div> 
                <Prescription appStore={appStore} tempPrescriptionData={tempPrescriptionData} ></Prescription>
                {onNull(summaryList1) && <HeaderSummary appStore={appStore} data={summaryList1} pair={1} breakdown={breakdown}></HeaderSummary>}
                {onNull(summaryList2) && <HeaderSummary appStore={appStore} data={summaryList2} pair={2} breakdown={breakdown} complementaryEquipment={complementaryEquipment}></HeaderSummary>}
                <Footer title={t('delivery_del_advices')} iconDisplay={false} text={t('email___if_you_have_any_questions_to_ecp')}></Footer>
            </div>
            
        </div>

    )
}
export default DeliveryTemplate;