import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import deliveryPairDesc from "../../constants/delivery-pairs.constants";
import summaryData from "../../constants/summary-data.constants";
import iLensRecommendationPair from "../../interface/lens-recommendaiton-pair.interface";
import summaryBrand from "../../interface/summary-products.interface";
import isummaryDetails from "../../interface/summary.interface";
import LensFamilyType from "../../types/lens-family.type";
import LensPairType from "../../types/lens-pair.type";
import iRootState from "../interface/root-state.interface";
import summaryStore from "../interface/summary.interface";

const initialState: summaryStore  = {
    summary: summaryData,
    summaryBrand: {
        pair1:[],
        pair2:[]
    },
    summaryNotes: '',
    summaryList1: [],
    summaryList2: []
}

const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers:{
        setPairDetails: (state, action: PayloadAction<{
            brand?: iLensRecommendationPair,
            pair?: LensPairType
        }>) => 
            {       
                    const payload = action.payload;
                    let newState1;
                    let newState2;
                    let description:any[];
                    let deliveryDescription;
                    if(payload.pair === LensPairType.PAIR1){
                        newState2 = state.summaryList2;
                        newState1 = state.summary?.map((v: isummaryDetails)=>{
                            v.products.map((sub: summaryBrand)=>{
                                description = [...sub.descriptions];
                            })
                            if(v.id===LensFamilyType.CORRECT && payload.brand?.correct !== null
                                && payload.brand?.correct !== undefined){
                                if(payload.brand?.correct.brand?.label==='Varilux'){
                                    deliveryDescription = deliveryPairDesc.progressive
                                }
                                else if(payload.brand?.correct.brand?.id==='standard_design'){
                                    deliveryDescription = deliveryPairDesc.both
                                }else{
                                    deliveryDescription = deliveryPairDesc.single
                                }
                                return{
                                    ...v,
                                    deliveryDescription: deliveryDescription,
                                    thickness: payload.brand?.correct.subProduct?.index,
                                    price: payload.brand?.correct.subProduct?.price,
                                    products: v.products.map((sub: summaryBrand)=>{
                                        return{
                                            title: payload.brand?.correct.subProduct?.label,
                                            // descriptions: [...sub.descriptions]
                                            descriptions: payload.brand?.correct.product?.benefits
                                        }
                                        
                                    })
                                }
                                
                            }
                            else if(v.id === LensFamilyType.ENHANCE && payload.brand?.enhance !== null
                                && payload.brand?.enhance !== undefined){
                                return{
                                    ...v,
                                    products: v.products.map((sub: summaryBrand)=>{
                                        return{                                        
                                            title: payload.brand?.enhance.product?.label,
                                            descriptions: payload.brand?.enhance.product?.benefits
                                        }
                                    })
                                }
                            }
                            else if(v.id === LensFamilyType.PROTECT && payload.brand?.protect !== null
                                && payload.brand?.protect !== undefined
                                && payload.brand?.protect.length !== 0){
                                return{
                                    ...v,
                                        products: payload.brand?.protect.map((prod)=>{  
                                            return {
                                                title: prod.product?.label,
                                                // descriptions: description
                                                descriptions: prod.product?.benefits
                                            }
                                        })
                                }
                            }
                        })  
                    }else if(payload.pair === LensPairType.PAIR2 ){
                        newState1 = state.summaryList1;
                        newState2 = state.summary?.map((v: isummaryDetails)=>{
                            v.products.map((sub: summaryBrand)=>{
                                description = [...sub.descriptions];
                            })
                            if(v.id===LensFamilyType.CORRECT && payload.brand?.correct !== null
                                && payload.brand?.correct !== undefined){
                                if(payload.brand?.correct.brand?.label==='Varilux'){
                                    deliveryDescription = deliveryPairDesc.progressive
                                }else{
                                    deliveryDescription = deliveryPairDesc.single
                                }
                                return{
                                    ...v,
                                    deliveryDescription: deliveryDescription,
                                    thickness: payload.brand?.correct.subProduct?.index,
                                    price: payload.brand?.correct.subProduct?.price,
                                    products: v.products.map((sub: summaryBrand)=>{
                                        return{
                                            title: payload.brand?.correct.subProduct?.label,
                                            descriptions: payload.brand?.correct.product?.benefits
                                        }
                                        
                                    })
                                }
                                
                            }
                            else if(v.id === LensFamilyType.ENHANCE && payload.brand?.enhance !== null
                                && payload.brand?.enhance !== undefined){
                                return{
                                    ...v,
                                    products: v.products.map((sub: summaryBrand)=>{
                                        return{                                        
                                            title: payload.brand?.enhance.product?.label,
                                            descriptions: payload.brand?.enhance.product?.benefits
                                        }
                                    })
                                }
                            }
                            else if(v.id === LensFamilyType.PROTECT && payload.brand?.protect !== null
                                && payload.brand?.protect !== undefined
                                && payload.brand?.protect.length !== 0){
                               
                                return{
                                    ...v,
                                        products: payload.brand?.protect.map((prod)=>{  
                                            return {
                                                title: prod.product?.label,
                                                descriptions: prod.product?.benefits
                                            }
                                        })
                                }
                            }
                        })
                    }
                    
                return { ...state, summaryList1: newState1, summaryList2: newState2 }
            }
        ,
        resetData: (state) => ({...state, ...initialState, summaryList1: undefined, summaryList2: undefined}),
        saveSummary1: (state, action: PayloadAction<summaryStore["summaryList1"]>) => ( {
            ...state, 
            summaryList1: action.payload
        }), 
        saveSummary2: (state, action: PayloadAction<summaryStore["summaryList2"]>) => ( {
            ...state, 
            summaryList2: action.payload
        }),
        saveSummaryNotes: (state, action: PayloadAction<string>) => ( {
            ...state, 
            summaryNotes: action.payload
        }),
          
    }
});

export const getSummary = (state:iRootState) => state.summary;
export default summarySlice;