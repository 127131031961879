import iNeedExplorerSummaryProducts from "../interface/need-explorer-summary-products.interface"

const iNeedExplorerProductsSummary: iNeedExplorerSummaryProducts[]  = [
    {
        text: 'questionnaires_q__sum_rec_cor_p1',
        lensType: "pair_1_tab_del_2_p1_correct",
        icon: "correct",
        pair: "1",
    },
    {
        text: 'questionnaires_q__sum_rec_cor_p2',
        lensType: "pair_1_tab_del_2_p1_correct",
        icon: "correct",
        pair: "2",
    },
    {
        text: 'questionnaires_q__sum_rec_pro_p1',
        lensType: "pair_1_tab_del_2_p1_protect",
        icon: "protect",
        pair: "1",
    },
    {
        text: 'questionnaires_q__sum_rec_pro_p1_2',
        lensType: "pair_1_tab_del_2_p1_protect",
        icon: "protect",
        pair: "2",
    },
    {
        text: 'questionnaires_q_summ_recomm',
        lensType: "pair_1_tab_del_3_p1_enhance",
        icon: "enhance",
        pair: "1",
    },
    {
        text: 'need_explorer_recom_coating_to_enhance_for_p2',
        lensType: "pair_1_tab_del_3_p1_enhance",
        icon: "enhance",
        pair: "2",
    },
]

export default iNeedExplorerProductsSummary;