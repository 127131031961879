import React from 'react';
import useBEM from '../utils/hooks/useBEM';
import Spinner from './ui/Spinner';
import ReactDOM from 'react-dom';

interface Props {
    show: boolean,
    text?: string,
    textOnly?: boolean
}

const Loading : React.FC<Props>  = ({
    text,
    textOnly,
    show
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('loading');
    const portal: any = document.getElementById("portal");

    // HOOKS

    // METHODS

    return ReactDOM.createPortal(
        <div className={B( show ? 'show' : '')}>
            {
                !textOnly &&
                <Spinner color={'rgb(0 134 214 / 33%)'}/>
            }
            

            {
                text &&
                <h1 className={E('text')}>{text}</h1>
            }
            
        </div>, portal
    )
}

export default Loading;