import iProductLogic from "../interface/product-logic.interface";

const kidsProductLogic: iProductLogic[] = [
{ answerId: '6.1.1', scoreMatrix: [2,1,0,0] },
{ answerId: '6.1.2', scoreMatrix: [2,1,0,0] },
{ answerId: '6.1.3', scoreMatrix: [2,1,0,0] },
{ answerId: '6.1.4', scoreMatrix: [2,1,0,0] },
{ answerId: '6.1.5', scoreMatrix: [1,2,0,0] },
{ answerId: '6.1.6', scoreMatrix: [1,2,0,0] },
{ answerId: '6.1.7', scoreMatrix: [1,2,0,0] },
{ answerId: '6.2.1', scoreMatrix: [0,0,3,1] },
{ answerId: '6.2.2', scoreMatrix: [0,0,1,1] },
{ answerId: '6.2.3', scoreMatrix: [0,0,1,3] },
{ answerId: '6.2.4', scoreMatrix: [0,0,1,1] },
{ answerId: '6.2.5', scoreMatrix: [5,5,0,0] },
{ answerId: '6.3.1', scoreMatrix: [0,3,0,0] },
{ answerId: '6.3.2', scoreMatrix: [1,2,0,0] },
{ answerId: '6.3.3', scoreMatrix: [3,1,0,0] },
{ answerId: '6.3.4', scoreMatrix: [3,0,0,0] },
{ answerId: '6.4.1', scoreMatrix: [0,0,0,0] },
{ answerId: '6.4.2', scoreMatrix: [0,0,0,0] },
{ answerId: '6.4.3', scoreMatrix: [0,0,0,0] },
{ answerId: '6.4.4', scoreMatrix: [0,0,0,0] },
{ answerId: '6.4.5', scoreMatrix: [0,0,0,0] },
{ answerId: '6.4.6', scoreMatrix: [0,0,0,0] },
{ answerId: '6.5.1', scoreMatrix: [0,0,1,3] },
{ answerId: '6.5.2', scoreMatrix: [0,0,2,2] },
{ answerId: '6.5.3', scoreMatrix: [0,0,0,0] },
{ answerId: '6.5.4', scoreMatrix: [0,0,0,0] },
{ answerId: '6.5.5', scoreMatrix: [0,0,0,0] },
{ answerId: '7.1.1', scoreMatrix: [5,1,0,0] },
{ answerId: '7.1.2', scoreMatrix: [5,1,0,0] },
{ answerId: '7.1.3', scoreMatrix: [5,1,0,0] },
{ answerId: '7.1.4', scoreMatrix: [5,1,0,0] },
{ answerId: '7.1.5', scoreMatrix: [0,2,0,0] },
{ answerId: '7.2.1', scoreMatrix: [3,1,0,0] },
{ answerId: '7.2.2', scoreMatrix: [0,1,0,0] },
{ answerId: '7.3.1', scoreMatrix: [3,1,0,0] },
{ answerId: '7.3.2', scoreMatrix: [2,1,0,0] },
{ answerId: '7.3.3', scoreMatrix: [1,1,0,0] },
{ answerId: '7.3.4', scoreMatrix: [1,1,0,0] },
{ answerId: '7.4.1', scoreMatrix: [1,2,0,0] },
{ answerId: '7.4.2', scoreMatrix: [1,2,0,0] },
{ answerId: '7.4.3', scoreMatrix: [2,3,0,0] },
{ answerId: '7.4.4', scoreMatrix: [3,4,0,0] },
{ answerId: '7.5.1', scoreMatrix: [0,0,0,0] },
{ answerId: '7.5.2', scoreMatrix: [0,0,0,0] },
{ answerId: '7.5.3', scoreMatrix: [0,0,0,0] },
{ answerId: '7.5.4', scoreMatrix: [0,0,0,0] },
{ answerId: '7.5.5', scoreMatrix: [0,0,0,0] },
]

export default kidsProductLogic;