import React from 'react';
import { backgroundPerRow, flexSpaceAround, h3, imageLogo, paragraph, prescriptionLabel, summaryBenefitContainer, valueContainer, valueContainerAdd } from '../../../constants/email-css.constants';
import iAppStore from '../../../store/interface/app-store.interface';
import useTranslation from '../../../utils/hooks/useTranslation';
interface Props {
    tempPrescriptionData: any;
    appStore: iAppStore
}

const Prescription : React.FC<Props>  = ({tempPrescriptionData, appStore}) =>  {
    //VARIABLES
    const { t } = useTranslation(appStore);
    //METHOD
    const onUndefinded = (value: any) =>{
        if(value === null || value === undefined) return " ";
        return value;
    }
    return (
        <div>
            <div style={backgroundPerRow}><br></br></div>
            <div style={backgroundPerRow}>
                <h3 style={h3}>{t('lens_proposal_builder_lpb__prescription')}</h3>
                {/* PRESCRIPTION VALUES */}
                <div style={flexSpaceAround}>
                    <strong style={prescriptionLabel}>{t('delivery_del__sphere_(sph)')}</strong>
                    <div style={valueContainer}>
                        <strong>{t('rx_righ_teye')}</strong> {tempPrescriptionData.values.sphere.od}
                    </div>
                    <div style={valueContainer}>
                        <strong>{t('rx_left_eye')}</strong> {tempPrescriptionData.values.sphere.os}
                    </div>
                </div>
                <div style={flexSpaceAround}>
                    <strong style={prescriptionLabel}>{t('delivery_del__cylinder_(cyl)')}</strong>
                    <div style={valueContainer}>
                        <strong>{t('rx_righ_teye')}</strong> {onUndefinded(tempPrescriptionData.values.cylinder.od) }
                    </div>
                    <div style={valueContainer}>
                        <strong>{t('rx_left_eye')}</strong> {onUndefinded(tempPrescriptionData.values.cylinder.os) }
                    </div>
                </div>
                <div style={flexSpaceAround}>
                    <strong style={prescriptionLabel}>{t('delivery_del__axis_(axe)')}</strong>
                    <div style={valueContainer}>
                        <strong>{t('rx_righ_teye')}</strong> {onUndefinded(tempPrescriptionData.values.axis.od) }
                    </div>
                    <div style={valueContainer}>
                        <strong>{t('rx_left_eye')}</strong> {onUndefinded(tempPrescriptionData.values.axis.os) }
                    </div>
                </div>   
                <div style={flexSpaceAround}>
                    <strong style={prescriptionLabel}>{t('delivery_del__addition_(add)')}</strong>
                    <div style={valueContainerAdd}>
                        {onUndefinded(tempPrescriptionData.values.addition) }
                    </div>
                </div>               
            </div>
        </div>
        
        )
}
export default Prescription;