import variluxCard from './../assets/images/varilux-card.png';
import experioCard from './../assets/images/experio-card.jpg';
import blueUVCard from './../assets/images/blue-uv-card.jpg';
import eyezenCard from './../assets/images/eyezen-card.png';
import crizalCard from './../assets/images/crizal-card.png';
import optifogCard from './../assets/images/optifog-card.png';
import transitionsCard from './../assets/images/transitions-card.png';
import stellestCard2 from './../assets/images/stellest-card2.png';
import LensCategoryType from '../types/lens-category.type';
import ComplementaryEquipmentType from '../types/complementary-equipment.type';
import LensProtectType from '../types/lens-protect.type';
import LensEnhanceType from '../types/lens-enhance.type';

export const PRODUCT_CONFIG = {
    // new product = varilux_xr_series
    "varilux_xr_series": {
        downgrade: [`varilux_xr_series`, `varilux_x_series`, `varilux_physio_30`],
        smallTitle: 'questionnaires_q_recomm_short_description',
        description: 'questionnaires_q_recomm_long_desc',
        benefits: [
            {
                icon: "instant",
                label: ['ec_varilux_xr_benefit_instant','Instant sharpness at all distances, even in motion'],
                type: 1,
            },
            {
                icon: "low-light",
                label: ['ec_varilux_xr_benefit_sharp', "High visual acuity even in low light conditions"],
                type: 1
            },
            {
                icon: "natural-posture",
                label: ['ec_varilux_xr_benefit_extended', "Natural posture in all circumstances"],
                type: 1
            },
            {
                icon: "panoramic",
                label: ['ec_varilux_xr_benefit_panoramic', "Panoramic field of vision"],
                type: 2
            },
            {
                icon: "stabilised",
                label: ['ec_varilux_xr_benefit_stabilized', 'Stabilized vision even in the most demanding situations'],
                type: 2

            },
        ]
    },
    "varilux_x_series": {
        downgrade: [`varilux_x_series`, `varilux_e_series`, `varilux_physio_30`],
        smallTitle: 'questionnaires_q_recomm_short_description',
        description: 'questionnaires_q_recomm_long_desc',
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
            {
                icon: "effortless-vision",
                label: "pair_1_tab_del_p1__effortless_",
                type: 1
            },
            {
                icon: "balance",
                label: "pair_1_tab_del_p1__balance_when_moving",
                type: 2
            },
            {
                icon: "head-move",
                label: "pair_1_tab_del_p1_visual_fluidity",
                type: 2

            },
        ]
    },
    "varilux_sunmax": {
        id: 'varilux_sunmax',
        label: 'Varilux® SunMax',
        lensDesign: LensCategoryType.PROGRESSIVE,
        enabled: true,
        downgrade: [],
        subProducts: [
            {id: "1varilux_sunMax", label: "Varilux® SunMax", index: 1.59,price:0, enabled: true },
            {id: "2varilux_sunMax", label: "Varilux® SunMax", index: 1.6,price:0, enabled: true }
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
        ]
    },
    "varilux_s_series": {
        id: 'varilux_s_series',
        label: 'Varilux® S™ Series',
        enabled: true,
        lensDesign: LensCategoryType.PROGRESSIVE,
        downgrade: [`varilux_s_series`, `varilux_e_series`, `varilux_physio_30`],
        subProducts: [
            {id: "1varilux_s_4d", label: "Varilux® S™ 4D", index: 1.5,price:0, enabled: true },
            {id: "2varilux_s_4d", label: "Varilux® S™ 4D", index: 1.59,price:0, enabled: true },
            {id: "3varilux_s_4d", label: "Varilux® S™ 4D", index: 1.6,price:0, enabled: true },
            {id: "4varilux_s_4d", label: "Varilux® S™ 4D", index: 1.67,price:0, enabled: true },
            {id: "5varilux_s_4d", label: "Varilux® S™ 4D", index: 1.74,price:0, enabled: true },
            {id: "1varilux_s_design", label: "Varilux® S™ design", index: 1.5,price:0, enabled: true },
            {id: "2varilux_s_design", label: "Varilux® S™ design", index: 1.59,price:0, enabled: true },
            {id: "3varilux_s_design", label: "Varilux® S™ design", index: 1.6,price:0, enabled: true },
            {id: "4varilux_s_design", label: "Varilux® S™ design", index: 1.67,price:0, enabled: true },
            {id: "5varilux_s_design", label: "Varilux® S™ design", index: 1.74,price:0, enabled: true },
            {id: "1varilux_s_design_short", label: "Varilux® S™ design short", index: 1.5,price:0, enabled: true },
            {id: "2varilux_s_design_short", label: "Varilux® S™ design short", index: 1.59,price:0, enabled: true },
            {id: "3varilux_s_design_short", label: "Varilux® S™ design short", index: 1.6,price:0, enabled: true },
            {id: "4varilux_s_design_short", label: "Varilux® S™ design short", index: 1.67,price:0, enabled: true },
            {id: "5varilux_s_design_short", label: "Varilux® S™ design short", index: 1.74,price:0, enabled: true },
            {id: "1varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.67,price:0, enabled: true },
            {id: "5varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.74,price:0, enabled: true },
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
            {
                icon: "effortless-vision",
                label: "pair_1_tab_del_p1__effortless_",
                type: 1
            },
            {
                icon: "balance",
                label: "pair_1_tab_del_p1__balance_when_moving",
                type: 2
            },
        ]
    },
    "varilux_physio_30": {
        id: 'varilux_physio_30',
        label: 'Varilux® Physio® 3.0',
        lensDesign: LensCategoryType.PROGRESSIVE,
        enabled: true,
        downgrade: [ `varilux_physio_30`, `varilux_comfort_max`,  `varilux_liberty_30`],
        subProducts: [
            {id: "1varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.5,price:0, enabled: true },
            {id: "2varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.59,price:0, enabled: true },
            {id: "3varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.6,price:0, enabled: true },
            {id: "4varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.67,price:0, enabled: true },
            {id: "5varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.74,price:0, enabled: true },
            {id: "1varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.67,price:0, enabled: true },
            {id: "5varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.74,price:0, enabled: true },
            {id: "1varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.5,price:0, enabled: true },
            {id: "2varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.59,price:0, enabled: true },
            {id: "3varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.6,price:0, enabled: true },
            {id: "4varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.67,price:0, enabled: true },
            {id: "5varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.74,price:0, enabled: true },
            {id: "1varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.5,price:0, enabled: true },
            {id: "2varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.59,price:0, enabled: true },
            {id: "3varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.6,price:0, enabled: true },
            {id: "4varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.67,price:0, enabled: true },
            {id: "5varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.74,price:0, enabled: true },
            {id: "1varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.5,price:0, enabled: true },
            {id: "2varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.59,price:0, enabled: true },
            {id: "3varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.6,price:0, enabled: true },
            {id: "4varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.67,price:0, enabled: true },
            {id: "5varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.74,price:0, enabled: true }
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
            {
                icon: "effortless-vision",
                label: "pair_1_tab_del_p1__effortless_",
                type: 1
            },
        ]
    },
    "varilux_liberty_30":{
        id: 'varilux_liberty_30',
        label: 'Varilux® Liberty™ 3.0',
        lensDesign: LensCategoryType.PROGRESSIVE,
        enabled: true,
        downgrade: [],
        subProducts: [
            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.5,price:0, enabled: true },
            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.59,price:0, enabled: true },
            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.6,price:0, enabled: true },
            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.67,price:0, enabled: true },
            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.5,price:0, enabled: true },
            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.59,price:0, enabled: true },
            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.6,price:0, enabled: true },
            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.67,price:0, enabled: true },
            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.5,price:0, enabled: true },
            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.59,price:0, enabled: true },
            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.6,price:0, enabled: true },
            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.67,price:0, enabled: true },
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
        ]
    },
    "varilux_e_series":{
        id: 'varilux_e_series',
        label: 'Varilux® E Series',
        enabled: true,
        lensDesign: LensCategoryType.PROGRESSIVE,
        downgrade: [`varilux_e_series`,`varilux_physio_30`,  `varilux_comfort_max`],
        subProducts: [
            {id: "1varilux_e_design", label: "Varilux® E design", index: 1.5,price:0, enabled: true },
            {id: "2varilux_e_design", label: "Varilux® E design", index: 1.59,price:0, enabled: true },
            {id: "3varilux_e_design", label: "Varilux® E design", index: 1.6,price:0, enabled: true },
            {id: "4varilux_e_design", label: "Varilux® E design", index: 1.67,price:0, enabled: true },
            {id: "5varilux_e_design", label: "Varilux® E design", index: 1.74,price:0, enabled: true },
            {id: "1varilux_e_design_short", label: "Varilux® E design short", index: 1.5,price:0, enabled: true },
            {id: "2varilux_e_design_short", label: "Varilux® E design short", index: 1.59,price:0, enabled: true },
            {id: "3varilux_e_design_short", label: "Varilux® E design short", index: 1.6,price:0, enabled: true },
            {id: "4varilux_e_design_short", label: "Varilux® E design short", index: 1.67,price:0, enabled: true },
            {id: "5varilux_e_design_short", label: "Varilux® E design short", index: 1.74,price:0, enabled: true },
            {id: "1varilux_e_f-360", label: "Varilux® E f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_e_f-360", label: "Varilux® E f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_e_f-360", label: "Varilux® E f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_e_f-360", label: "Varilux® E f-360°", index: 1.67,price:0, enabled: true },
            {id: "5varilux_e_f-360", label: "Varilux® E f-360°", index: 1.74,price:0, enabled: true },
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
            {
                icon: "effortless-vision",
                label: "pair_1_tab_del_p1__effortless_",
                type: 1
            },
            {
                icon: "balance",
                label: "pair_1_tab_del_p1__balance_when_moving",
                type: 2
            },
        ]
    },
    "varilux_digitime":{
        id: 'varilux_digitime',
        label: 'Varilux® Digitime™',
        complementaryEquipments: [ComplementaryEquipmentType.COMPUTER],
        lensDesign: LensCategoryType.PROGRESSIVE,
        enabled: true,
        downgrade: [`varilux_digitime`, ``,  ``],
        subProducts: [
            {id: "1varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.67,price:0, enabled: true },
            {id: "1varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.5,price:0, enabled: true },
            {id: "2varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.59,price:0, enabled: true },
            {id: "3varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.6,price:0, enabled: true },
            {id: "4varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.67,price:0, enabled: true },
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_var_digi',
        description: 'questionnaires_q_recom_long_desc_var_digi',
        benefits: [
            {
                icon: "relax-screen",
                label: "summary_bene1",
                type: 1,
            },
        ]
    },
    "varilux_comfort_max":{
        id: 'varilux_comfort_max',
        label: 'Varilux® Comfort Max',
        enabled: true,
        lensDesign: LensCategoryType.PROGRESSIVE,
        downgrade: [ `varilux_comfort_max`, `varilux_liberty_30`,  `spherical_essilor`],
        subProducts: [
            {id: "1varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.5,price:0, enabled: true },
            {id: "2varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.59,price:0, enabled: true },
            {id: "3varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.6,price:0, enabled: true },
            {id: "4varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.67,price:0, enabled: true },
            {id: "5varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.74,price:0, enabled: true },
            {id: "1varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.67,price:0, enabled: true },
            {id: "5varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.74,price:0, enabled: true },
            {id: "1varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.5,price:0, enabled: true },
            {id: "2varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.59,price:0, enabled: true },
            {id: "3varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.6,price:0, enabled: true },
            {id: "4varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.67,price:0, enabled: true },
            {id: "5varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.74,price:0, enabled: true },
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
        ]
    },
    "varilux_comfort_30":{
        id: 'varilux_comfort_30',
        label: 'Varilux® Comfort 3.0',
        enabled: true,
        lensDesign: LensCategoryType.PROGRESSIVE,
        downgrade: [`varilux_comfort_30`, `varilux_liberty_30`,  `spherical_essilor`],
        subProducts: [
            {id: "1varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.5,price:0, enabled: true },
            {id: "2varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.59,price:0, enabled: true },
            {id: "3varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.6,price:0, enabled: true },
            {id: "4varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.67,price:0, enabled: true },
            {id: "1varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.5,price:0, enabled: true },
            {id: "2varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.59,price:0, enabled: true },
            {id: "3varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.6,price:0, enabled: true },
            {id: "4varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.67,price:0, enabled: true },
            {id: "1varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.5,price:0, enabled: true },
            {id: "2varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.59,price:0, enabled: true },
            {id: "3varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.6,price:0, enabled: true },
            {id: "4varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.67,price:0, enabled: true },
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "seamless",
                label: "pair_1_tab_del_p1__seamless_vision_",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "pair_1_tab_del_p1__instant_sharp",
                type: 1
            },
        ]
    },
    "varilux_road_pilot_ii":{
        id: 'varilux_road_pilot_ii',
        label: 'Varilux Road Pilot® II',
        enabled: true,
        lensDesign: LensCategoryType.PROGRESSIVE,
        downgrade: [`varilux_road_pilot_ii`, `essidrive`,  ``],
        subProducts: [
            {id: "varilux_road_pilot_ii", label: "Varilux Road Pilot® II", index: 1.5,price:0, enabled: true },
            {id: "varilux_road_pilot_ii", label: "Varilux Road Pilot® II", index: 1.6,price:0, enabled: true },
            {id: "varilux_road_pilot_ii", label: "Varilux Road Pilot® II", index: 1.67,price:0, enabled: true },
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_var_rpii',
        description: "questionnaires_q_recom_long_desc_var_rpii",
        benefits: [
            {
                icon: "adapt-quickly",
                label: "summary_bene2",
                type: 1,
            },
            {
                icon: "optimize-driving",
                label: "summary_bene3",
                type: 1
            },
        ]
    },
    "kids_standard_design":{
        id: 'kids_standard_design',
        label: 'Standard Design Kids',
        enabled: true,
        lensDesign: LensCategoryType.PROGRESSIVE_SV_LENS,
        subProducts: [
            {id: "1standard_design_kids" ,label: "Standard Design Kids" ,index:1.5 ,price: 20, enabled: true },
            {id: "2standard_design_kids" ,label: "Standard Design Kids" ,index:1.59 ,price: 20, enabled: true },
            {id: "3standard_design_kids" ,label: "Standard Design Kids" ,index:1.6 ,price: 20, enabled: true },
        ],
        isKids: true,
        downgrade: [],
        smallTitle: '',
        description: ""
    },
    "spherical_essilor":{
        id: 'spherical_essilor',
        label: 'Spherical Essilor',
        lensDesign: LensCategoryType.PROGRESSIVE_SV_LENS,
        enabled: true,
        subProducts: [
            {id: "1spherical_essilor" ,label: "Spherical Essilor" ,index:1.5 ,price: 0, enabled: true },
            {id: "2spherical_essilor" ,label: "Spherical Essilor" ,index:1.59 ,price: 0, enabled: true },
            {id: "3spherical_essilor" ,label: "Spherical Essilor" ,index:1.6 ,price: 0, enabled: true },
            {id: "4spherical_essilor" ,label: "Spherical Essilor" ,index:1.67 ,price: 0, enabled: true },
        ],
        downgrade: [],
        smallTitle: '',
        description: ""
    },
    "aspherical_essilor":{
        id: 'aspherical_essilor',
        label: 'Aspherical Essilor',
        lensDesign: LensCategoryType.PROGRESSIVE_SV_LENS,
        enabled: true,
        subProducts: [
            {id: "1aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.5 ,price: 0, enabled: true },
            {id: "2aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.59 ,price: 0, enabled: true },
            {id: "3aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.6 ,price: 0, enabled: true },
            {id: "4aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.67 ,price: 0, enabled: true },
            {id: "5aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.74 ,price: 0, enabled: true },
        ],
        downgrade: [],
        smallTitle: '',
        description: ""
    },
    "eyezen_start":{
        id: 'eyezen_start',
        label: 'Eyezen® Start',
        lensDesign: LensCategoryType.SINGLE_VISION,
        enabled: true,
        subProducts: [
            {id: "1eyezen_start", label: "Eyezen® Start", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_start", label: "Eyezen® Start", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_start", label: "Eyezen® Start", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_start", label: "Eyezen® Start", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_start", label: "Eyezen® Start", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.74,price:0, enabled: true },
        ],
        downgrade: ['eyezen_start' , 'eyezen_boost', 'spherical_essilor'],
        smallTitle: 'questionnaires_q_recom_short_desc_eyes_start',
        description: "questionnaires_q_recom_long_desc_eyes_start",
        benefits: [
            {
                icon: "sharp-vision-icon",
                label: "summary_bene5",
                type: 1,
            },
            {
                icon: "acuity-near-vision",
                label: "pair_1_tab_del_p1_better_acuity_in_near_vision",
                type: 1,
            },
            {
                icon: "relax-screen",
                label: "summary_bene4",
                type: 1,
            },
        ]
    },
    "eyezen_boost":{
        id: 'eyezen_boost',
        label: 'Eyezen® Boost',
        enabled: true,
        lensDesign: LensCategoryType.SINGLE_VISION,
        subProducts: [
            {id: "1eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.74,price:0, enabled: true },
            {id: "1eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.5,price:0, enabled: true },
            {id: "2eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.59,price:0, enabled: true },
            {id: "3eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.6,price:0, enabled: true },
            {id: "4eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.67,price:0, enabled: true },
            {id: "5eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.74,price:0, enabled: true },    
        ],
        downgrade: ['eyezen_boost' , 'eyezen_start', 'spherical_essilor'],
        smallTitle: 'questionnaires_q_recom_short_desc_eyes_start',
        description: "questionnaires_q_recom_long_desc_eyes_boost",
        benefits: [
            {
                icon: "sharp-vision-icon",
                label: "summary_bene5",
                type: 1,
            },
            {
                icon: "acuity-near-vision",
                label: "pair_1_tab_del_p1_better_acuity_in_near_vision",
                type: 1,
            },
            {
                icon: "relax-screen",
                label: "summary_bene4",
                type: 1,
            },
        ]
    },
    "eyezen_kids":{
        id: 'eyezen_kids',
        label: 'Eyezen® Kids',
        subProducts: [
            {id: "1eyezen_kids" ,label: "Eyezen® Kids" ,index:1.5 ,price: 200, enabled: true},
            {id: "1eyezen_kids" ,label: "Eyezen® Kids" ,index:1.59 ,price: 200, enabled: true},
        ],
        isKids: true,
        enabled: true,
        downgrade: ["eyezen_kids","kids_standard_design",""],
        smallTitle: "questionnaires_q_recomm_short_desc_eye_kid",
        description: 'questionnaires_q_recomm_long_desc_eye_kid',
        kidsPrescriptionRange: {
            min: -0.50,
            max: 20
        },
        lensDesign: LensCategoryType.SINGLE_VISION,
        benefits: [
            {
                icon: "sharp-vision-icon",
                label: "summary_bene5",
                type: 1,
            },
            {
                icon: "acuity-near-vision",
                label: "pair_1_tab_del_p1_better_acuity_in_near_vision",
                type: 1,
            },
            {
                icon: "relax-screen",
                label: "summary_bene4",
                type: 1,
            },
        ]
    },
    "essidrive":{
        id: 'essidrive',
        label: 'Essidrive',
        enabled: true,
        downgrade: [],
        lensDesign: LensCategoryType.SINGLE_VISION,
        subProducts: [
            {id: "1essidrive" ,label: "Essidrive" ,index:1.5 ,price: 0, enabled: true},
            {id: "2essidrive" ,label: "Essidrive" ,index:1.6 ,price: 0, enabled: true},
            {id: "3essidrive" ,label: "Essidrive" ,index:1.67 ,price: 0, enabled: true},
            {id: "1essidrive_f-360" ,label: "Essidrive f-360" ,index:1.5 ,price: 0, enabled: true},
            {id: "2essidrive_f-360" ,label: "Essidrive f-360" ,index:1.6 ,price: 0, enabled: true},
            {id: "3essidrive_f-360" ,label: "Essidrive f-360" ,index:1.67 ,price: 0, enabled: true},

        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "optimize-driving",
                label: "summary_bene3",
                type: 1,
            },
            {
                icon: "sharp-vision-icon",
                label: "summary_bene5",
                type: 1,
            },
        ]
    },
    "stellest":{
        id: 'stellest',
        label: 'Stellest',
        lensDesign: LensCategoryType.MYOPIA,
        subProducts: [
            {id: "1stellest" ,label: "Stellest" ,index:1.5 ,price: 200, enabled: true},
            {id: "2stellest" ,label: "Stellest" ,index:1.59 ,price: 200, enabled: true},
        ],
        isKids: true,
        enabled: true,
        downgrade: ['stellest','eyezen_kids','kids_standard_design'],
        smallTitle: 'questionnaires_q_recomm_short_desc_stell',
        description: 'questionnaires_q_recomm_long_desc_stell',
        kidsPrescriptionRange: {
            min: -20,
            max: -0.75
        },
        benefits: [
            {
                icon: "control",
                label: "summary_bene6",
                type: 1,
            },
            {
                icon: "adapt-quickly",
                label: "summary_bene7",
                type: 1,
            },
        ]
    },
    "xperio_react":{
        id: 'xperio_react',
        label: 'Xperio React',
        price: 0,
        enabled: true,
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "xperio_mirrors":{
        id: 'xperio_mirrors',
        label: 'Xperio Mirrors',
        price: 0,
        enabled: true,
        smallTitle: '',
        description: "",
        colors: [
            {
                id: "lensMirrorBronzeShadow",
                label: "lens_propo_colors_bronze_shadow",
                value: "#33070e"
            },
            {
                id: "lensMirrorBronze",
                label: "lens_propo_colors_bronze",
                value: "#c66a29"
            },
            {
                id: "lensMirrorCandy",
                label: "lens_propo_colors_candy",
                value: "#9d2b49"
            },
            {
                id: "lensMirrorForestGreen",
                label: "lens_propo_colors_forest_green",
                value: "#39721a"
            },
            {
                id: "lensMirrorGoldPink",
                label: "lens_propo_colors_gold_pink",
                value: "#924a35"
            },
            {
                id: "lensMirrorGreenShadow",
                label: "lens_propo_colors_green_shadow",
                value: "#1b160f"
            },
            {
                id: "lensMirrorKaki",
                label: "lens_propo_colors_kaki",
                value: "#59621f"
            },
            {
                id: "lensMirrorMauveSilver",
                label: "lens_propo_colors_mauve_silver",
                value: "#ada5d2"
            },
            {
                id: "lensMirrorNewBlue",
                label: "lens_propo_colors_new_blue",
                value: "#3c8ea9"
            },
            {
                id: "lensMirrorNewSilver",
                label: "lens_propo_colors_new_silver",
                value: "#778fa3"
            },
            {
                id: "lensMirrorOceanBlue",
                label: "lens_propo_colors_ocean_blue",
                value: "#243459"
            },
            {
                id: "lensMirrorOrange",
                label: "lens_propo_colors_orange",
                value: "#7b1d16"
            },
            {
                id: "lensMirrorParma",
                label: "lens_propo_colors_parma",
                value: "#b9adc2"
            },
            {
                id: "lensMirrorPinkSilver",
                label: "lens_propo_colors_pink_silver",
                value: "#c1b4c0"
            },
            {
                id: "lensMirrorSilverShadow",
                label: "lens_propo_colors_silver_shadow",
                value: "#4e4340"
            },
            {
                id: "lensMirrorSilver",
                label: "lens_propo_colors_silver",
                value: "#8b8d92"
            },
            {
                id: "lensMirrorSkyBlueSilver",
                label: "lens_propo_colors_blue_silver",
                value: "#9fb6cf"
            },
            {
                id: "lensMirrorViolet",
                label: "lens_propo_colors_violet",
                value: "#21092f"
            }
        ],
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 1,
            },
        ]
    },
    "xperio_polarised":{
        id: 'xperio_polarised',
        label: 'Xperio Polarised',
        price: 0,
        enabled: true,
        complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
        colors: [
            {
                id: "lensPolarizedAmber",
                label: "lens_propo_colors_amber",
                value: "#A52A2A"
            },
            {
                id: "lensPolarizedBlueInk",
                label: "lens_propo_colors_blue_ink",
                value: "#395158"
            },
            {
                id: "lensPolarizedBluePurple",
                label: "lens_propo_colors_blue_purple",
                value: "#6437b8"
            },
            {
                id: "lensPolarizedBlueSky",
                label: "lens_propo_colors_blue_sky",
                value: "#87ceeb"
            },
            {
                id: "lensPolarizedBrown",
                label: "lens_propo_colors_brown",
                value: "#9C703B"
            },
            {
                id: "lensPolarizedGrey",
                label: "lens_propo_colors_grey",
                value: "#808080"
            },
            {
                id: "lensPolarizedGreyGreen",
                label: "lens_propo_colors_grey_green",
                value: "#656255"
            },
            {
                id: "lensPolarizedHerbal",
                label: "lens_propo_colors_herbal",
                value: "#8a8849"
            },
            {
                id: "lensPolarizedViolet",
                label: "lens_propo_colors_violet",
                value: "#6b2d7a"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_xpe_pol',
        description: "questionnaires_q_recom_long_desc_xpe_pol",
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 1,
            },
        ]
    },
    "xperio_polarised_gradient":{
        id: 'xperio_polarised_gradient',
        label: 'Xperio Polarised',
        price: 0,
        enabled: true,
        complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
        colors: [
            {
                id: "lensPolarizedAmber",
                label: "lens_propo_colors_amber",
                value: "#A52A2A"
            },
            {
                id: "lensPolarizedBlueInk",
                label: "lens_propo_colors_blue_ink",
                value: "#395158"
            },
            {
                id: "lensPolarizedBluePurple",
                label: "lens_propo_colors_blue_purple",
                value: "#6437b8"
            },
            {
                id: "lensPolarizedBlueSky",
                label: "lens_propo_colors_blue_sky",
                value: "#87ceeb"
            },
            {
                id: "lensPolarizedBrown",
                label: "lens_propo_colors_brown",
                value: "#9C703B"
            },
            {
                id: "lensPolarizedGrey",
                label: "lens_propo_colors_grey",
                value: "#808080"
            },
            {
                id: "lensPolarizedGreyGreen",
                label: "lens_propo_colors_grey_green",
                value: "#656255"
            },
            {
                id: "lensPolarizedHerbal",
                label: "lens_propo_colors_herbal",
                value: "#8a8849"
            },
            {
                id: "lensPolarizedViolet",
                label: "lens_propo_colors_violet",
                value: "#6b2d7a"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_xpe_pol',
        description: "questionnaires_q_recom_long_desc_xpe_pol",
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 1,
            },
        ]
    },
    "xperio_tinted_mirrors":{
        id: 'xperio_tinted_mirrors',
        label: 'Xperio Tinted Mirrors',
        lensDesign: LensProtectType.TINTED,
        price: 0,
        enabled: true,
        smallTitle: '',
        description: "",
        colors: [
            {
                id: "lensMirrorBronze",
                label: "lens_propo_colors_bronze",
                value: "#cd7f32"
            },
            {
                id: "lensMirrorCandy",
                label: "lens_propo_colors_candy",
                value: "#f56ef0"
            },
            {
                id: "lensMirrorForestGreen",
                label: "lens_propo_colors_forest_green",
                value: "#4bb852"
            },
            {
                id: "lensMirrorGoldPink",
                label: "lens_propo_colors_gold_pink",
                value: "#b76e79"
            },
            {
                id: "lensMirrorGreenShadow",
                label: "lens_propo_colors_green_shadow",
                value: "#0b420c"
            },
            {
                id: "lensMirrorKaki",
                label: "lens_propo_colors_kaki",
                value: "#c3b091"
            },
            {
                id: "lensMirrorOceanBlue",
                label: "lens_propo_colors_ocean_blue",
                value: "#020257"
            },
            {
                id: "lensMirrorOrange",
                label: "lens_propo_colors_orange",
                value: "#ff5349"
            },
            {
                id: "lensMirrorParma",
                label: "lens_propo_colors_parma",
                value: "#d3d3d3"
            },
            {
                id: "lensMirrorPinkSilver",
                label: "lens_propo_colors_pink_silver",
                value: "#c4aead"
            },
            {
                id: "lensMirrorSilver",
                label: "lens_propo_colors_silver",
                value: "#C0C0C0"
            },
            {
                id: "lensMirrorSilverShadow",
                label: "lens_propo_colors_silver_shadow",
                value: "#383737"
            },
            {
                id: "lensMirrorSkyBlueSilver",
                label: "lens_propo_colors_sky_blue_silver",
                value: "#a7c5d1"
            },
            {
                id: "lensMirrorViolet",
                label: "lens_propo_colors_violet",
                value: "#270238"
            },
                    
        ],
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 1,
            },
        ]
    },
    "xperio_polarised_mirrors":{
        id: 'xperio_polarised_mirrors',
        label: 'Xperio Polarised Mirrors',
        price: 0,
        enabled: true,
        complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
        colors: [
            {
                id: "lensMirrorBronze",
                label: "lens_propo_colors_bronze",
                value: "#cd7f32"
            },
            {
                id: "lensMirrorCandy",
                label: "lens_propo_colors_candy",
                value: "#f56ef0"
            },
            {
                id: "lensMirrorForestGreen",
                label: "lens_propo_colors_forest_green",
                value: "#4bb852"
            },
            {
                id: "lensMirrorGoldPink",
                label: "lens_propo_colors_gold_pink",
                value: "#b76e79"
            },
            {
                id: "lensMirrorGreenShadow",
                label: "lens_propo_colors_green_shadow",
                value: "#0b420c"
            },
            {
                id: "lensMirrorKaki",
                label: "lens_propo_colors_kaki",
                value: "#c3b091"
            },
            {
                id: "lensMirrorOceanBlue",
                label: "lens_propo_colors_ocean_blue",
                value: "#020257"
            },
            {
                id: "lensMirrorOrange",
                label: "lens_propo_colors_orange",
                value: "#ff5349"
            },
            {
                id: "lensMirrorParma",
                label: "lens_propo_colors_parma",
                value: "#d3d3d3"
            },
            {
                id: "lensMirrorPinkSilver",
                label: "lens_propo_colors_pink_silver",
                value: "#c4aead"
            },
            {
                id: "lensMirrorSilver",
                label: "lens_propo_colors_silver",
                value: "#C0C0C0"
            },
            {
                id: "lensMirrorSilverShadow",
                label: "lens_propo_colors_silver_shadow",
                value: "#383737"
            },
            {
                id: "lensMirrorSkyBlueSilver",
                label: "lens_propo_colors_sky_blue_silver",
                value: "#a7c5d1"
            },
            {
                id: "lensMirrorViolet",
                label: "lens_propo_colors_violet",
                value: "#270238"
            },
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_xpe_pol',
        description: "questionnaires_q_recom_long_desc_xpe_pol",
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 1,
            },
        ]
    },
    "xperio_tinted":{
        id: 'xperio_tinted',
        label: 'Xperio Tinted',
        lensDesign: LensProtectType.TINTED,
        price: 0,
        enabled: true,
        smallTitle: '',
        description: "",

        colors: [
            {
                id: "lensTintedBlack",
                label: "lens_propo_colors_black",
                value: "#000000"
            },
            {
                id: "lensTintedBlueInk",
                label: "lens_propo_colors_blue_ink",
                value: "#415c80"
            },
            {
                id: "lensTintedBrown",
                label: "lens_propo_colors_brown",
                value: "#6d5a45"
            },
            {
                id: "lensTintedBurgundy",
                label: "lens_propo_colors_burgundy",
                value: "#89676f"
            },
            {
                id: "lensTintedGreyGreen",
                label: "lens_propo_colors_grey_green",
                value: "#3c5854"
            },
            {
                id: "lensTintedGrey",
                label: "lens_propo_colors_grey",
                value: "#4c5d6e"
            },
            {
                id: "lensTintedHerbal",
                label: "lens_propo_colors_herbal",
                value: "#8e8565"
            },
            {
                id: "lensTintedNewBrown",
                label: "lens_propo_colors_new_brown",
                value: "#806b4d"
            },        
        ],
        benefits: [
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 1,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 1,
            },
        ]
    },
    "eye_protect_system":{
        id: 'eye_protect_system',
        label: 'Eye Protect System',
        price: 0,
        enabled: true,
        isKids: true,
        lensDesign: LensProtectType.BLUE_CUT,
        complementaryEquipments:[ComplementaryEquipmentType.COMPUTER],
        smallTitle: 'questionnaires_q_recomm_short_desc_eps',
        description: 'questionnaires_q_recomm_long_desc_eps',
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_2_p1_transparent_lens",
                type: 1,
            },
        ]
    },
    "blue_uv_capture":{
        id: 'blue_uv_capture',
        label: 'Blue UV Filter System',
        price: 0,
        enabled: true,
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_2_p1_transparent_lens",
                type: 1,
            },
        ]
    },   
    "transitions_signature_gen_8":{
        id: 'transitions_signature_gen_8',
        label: 'Transitions Gen 8',
        lensDesign: LensProtectType.PHOTOCROMIC,
        price: 0,
        enabled: true,
        isKids: true,
        colors: [
            {
                id: "lensTransitionGreen",
                label: "lens_propo_colors_green",
                value: "#3F9A49"
            },
            {
                id: "lensTransitionGrey",
                label: "lens_propo_colors_grey",
                value: "#B2B2B6"
            },
            {
                id: "lensTransitionAmber",
                label: "lens_propo_colors_amber",
                value: "#9C703B"
            },
            {
                id: "lensTransitionSapphire",
                label: "lens_propo_colors_sapphire",
                value: "#346297"
            },
            {
                id: "lensTransitionEmerald",
                label: "lens_propo_colors_emerald",
                value: "#046253"
            },
            {
                id: "lensTransitionBrown",
                label: "lens_propo_colors_brown",
                value: "#9E5425"
            },
            {
                id: "lensTransitionAmethyst",
                label: "lens_propo_colors_amethyst",
                value: "#4F3281"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_trans_gen_8',
        description: 'questionnaires_q_recom_long_desc_trans_gen_8',
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_signature_gen_8_iconic":{
        id: 'transitions_signature_gen_8_iconic',
        label: 'Transitions Signature Gen 8 Iconic',
        lensDesign: LensProtectType.PHOTOCROMIC,
        price: 0,
        enabled: true,
        isKids: true,
        colors: [
            {
                id: "lensTransitionGreen",
                label: "lens_propo_colors_green",
                value: "#3F9A49"
            },
            {
                id: "lensTransitionGrey",
                label: "lens_propo_colors_grey",
                value: "#B2B2B6"
            },
            {
                id: "lensTransitionAmber",
                label: "lens_propo_colors_amber",
                value: "#9C703B"
            },
            {
                id: "lensTransitionSapphire",
                label: "lens_propo_colors_sapphire",
                value: "#346297"
            },
            {
                id: "lensTransitionEmerald",
                label: "lens_propo_colors_emerald",
                value: "#046253"
            },
            {
                id: "lensTransitionBrown",
                label: "lens_propo_colors_brown",
                value: "#9E5425"
            },
            {
                id: "lensTransitionAmethyst",
                label: "lens_propo_colors_amethyst",
                value: "#4F3281"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_trans_gen_8',
        description: 'questionnaires_q_recom_long_desc_trans_gen_8',
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_signature_gen_8_style":{
        id: 'transitions_signature_gen_8_style',
        label: 'Transitions Signature Gen 8 Style',
        lensDesign: LensProtectType.PHOTOCROMIC,
        price: 0,
        enabled: true,
        isKids: true,
        colors: [
            {
                id: "lensTransitionGreen",
                label: "lens_propo_colors_green",
                value: "#3F9A49"
            },
            {
                id: "lensTransitionGrey",
                label: "lens_propo_colors_grey",
                value: "#B2B2B6"
            },
            {
                id: "lensTransitionAmber",
                label: "lens_propo_colors_amber",
                value: "#9C703B"
            },
            {
                id: "lensTransitionSapphire",
                label: "lens_propo_colors_sapphire",
                value: "#346297"
            },
            {
                id: "lensTransitionEmerald",
                label: "lens_propo_colors_emerald",
                value: "#046253"
            },
            {
                id: "lensTransitionBrown",
                label: "lens_propo_colors_brown",
                value: "#9E5425"
            },
            {
                id: "lensTransitionAmethyst",
                label: "lens_propo_colors_amethyst",
                value: "#4F3281"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_trans_gen_8',
        description: 'questionnaires_q_recom_long_desc_trans_gen_8',
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_signature_gen_8_classic":{
        id: 'transitions_signature_gen_8_classic',
        label: 'Transitions Signature Gen 8 Classic',
        lensDesign: LensProtectType.PHOTOCROMIC,
        price: 0,
        enabled: true,
        isKids: true,
        colors: [
            {
                id: "lensTransitionGreen",
                label: "lens_propo_colors_green",
                value: "#3F9A49"
            },
            {
                id: "lensTransitionGrey",
                label: "lens_propo_colors_grey",
                value: "#B2B2B6"
            },
            {
                id: "lensTransitionAmber",
                label: "lens_propo_colors_amber",
                value: "#9C703B"
            },
            {
                id: "lensTransitionSapphire",
                label: "lens_propo_colors_sapphire",
                value: "#346297"
            },
            {
                id: "lensTransitionEmerald",
                label: "lens_propo_colors_emerald",
                value: "#046253"
            },
            {
                id: "lensTransitionBrown",
                label: "lens_propo_colors_brown",
                value: "#9E5425"
            },
            {
                id: "lensTransitionAmethyst",
                label: "lens_propo_colors_amethyst",
                value: "#4F3281"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_trans_gen_8',
        description: 'questionnaires_q_recom_long_desc_trans_gen_8',
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_classic":{
        id: 'transitions_classic',
        label: 'Transitions Classic',
        lensDesign: LensProtectType.PHOTOCROMIC,
        price: 0,
        enabled: true,
        isKids: true,
        colors: [
            {
                id: "lensTransitionGreen",
                label: "lens_propo_colors_green",
                value: "#3F9A49"
            },
            {
                id: "lensTransitionGrey",
                label: "lens_propo_colors_grey",
                value: "#B2B2B6"
            },
            {
                id: "lensTransitionAmber",
                label: "lens_propo_colors_amber",
                value: "#9C703B"
            },
            {
                id: "lensTransitionSapphire",
                label: "lens_propo_colors_sapphire",
                value: "#346297"
            },
            {
                id: "lensTransitionEmerald",
                label: "lens_propo_colors_emerald",
                value: "#046253"
            },
            {
                id: "lensTransitionBrown",
                label: "lens_propo_colors_brown",
                value: "#9E5425"
            },
            {
                id: "lensTransitionAmethyst",
                label: "lens_propo_colors_amethyst",
                value: "#4F3281"
            }
        ],
        smallTitle: 'questionnaires_q_recom_short_desc_trans_gen_8',
        description: 'questionnaires_q_recom_long_desc_trans_gen_8',
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_xtractive":{
        id: 'transitions_xtractive',
        label: 'Transitions XTRActive',
        price: 0,
        enabled: true,
        colors: [
            {
                id: "lensTransitionGreen",
                label: "lens_propo_colors_green",
                value: "#3F9A49"
            },
            {
                id: "lensTransitionGrey",
                label: "lens_propo_colors_grey",
                value: "#B2B2B6"
            },
            {
                id: "lensTransitionAmber",
                label: "lens_propo_colors_amber",
                value: "#9C703B"
            },
            {
                id: "lensTransitionSapphire",
                label: "lens_propo_colors_sapphire",
                value: "#346297"
            },
            {
                id: "lensTransitionEmerald",
                label: "lens_propo_colors_emerald",
                value: "#046253"
            },
            {
                id: "lensTransitionBrown",
                label: "lens_propo_colors_brown",
                value: "#9E5425"
            },
            {
                id: "lensTransitionAmethyst",
                label: "lens_propo_colors_amethyst",
                value: "#4F3281"
            }
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_xtractive_mirror":{
        id: 'transitions_xtractive_mirror',
        label: 'Transitions XTRActive Mirror',
        price: 0,
        enabled: true,
        colors: [
            {
                id: "lensMirrorBronze",
                label: "lens_propo_colors_bronze",
                value: "#cd7f32"
            },
            {
                id: "lensMirrorCandy",
                label: "lens_propo_colors_candy",
                value: "#f56ef0"
            },
            {
                id: "lensMirrorForestGreen",
                label: "lens_propo_colors_forest_green",
                value: "#4bb852"
            },
            {
                id: "lensMirrorGoldPink",
                label: "lens_propo_colors_gold_pink",
                value: "#b76e79"
            },
            {
                id: "lensMirrorGreenShadow",
                label: "lens_propo_colors_green_shadow",
                value: "#0b420c"
            },
            {
                id: "lensMirrorKaki",
                label: "lens_propo_colors_kaki",
                value: "#c3b091"
            },
            {
                id: "lensMirrorOceanBlue",
                label: "lens_propo_colors_ocean_blue",
                value: "#020257"
            },
            {
                id: "lensMirrorOrange",
                label: "lens_propo_colors_orange",
                value: "#ff5349"
            },
            {
                id: "lensMirrorParma",
                label: "lens_propo_colors_parma",
                value: "#d3d3d3"
            },
            {
                id: "lensMirrorPinkSilver",
                label: "lens_propo_colors_pink_silver",
                value: "#c4aead"
            },
            {
                id: "lensMirrorSilver",
                label: "lens_propo_colors_silver",
                value: "#C0C0C0"
            },
            {
                id: "lensMirrorSilverShadow",
                label: "lens_propo_colors_silver_shadow",
                value: "#383737"
            },
            {
                id: "lensMirrorSkyBlueSilver",
                label: "lens_propo_colors_sky_blue_silver",
                value: "#a7c5d1"
            },
            {
                id: "lensMirrorViolet",
                label: "lens_propo_colors_violet",
                value: "#270238"
            },
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "transitions_style_mirrors":{
        id: 'transitions_style_mirrors',
        label: 'Transitions Style Mirrors',
        price: 0,
        enabled: true,
        colors: [
            {
                id: "lensMirrorBronze",
                label: "lens_propo_colors_bronze",
                value: "#cd7f32"
            },
            {
                id: "lensMirrorCandy",
                label: "lens_propo_colors_candy",
                value: "#f56ef0"
            },
            {
                id: "lensMirrorForestGreen",
                label: "lens_propo_colors_forest_green",
                value: "#4bb852"
            },
            {
                id: "lensMirrorGoldPink",
                label: "lens_propo_colors_gold_pink",
                value: "#b76e79"
            },
            {
                id: "lensMirrorGreenShadow",
                label: "lens_propo_colors_green_shadow",
                value: "#0b420c"
            },
            {
                id: "lensMirrorKaki",
                label: "lens_propo_colors_kaki",
                value: "#c3b091"
            },
            {
                id: "lensMirrorOceanBlue",
                label: "lens_propo_colors_ocean_blue",
                value: "#020257"
            },
            {
                id: "lensMirrorOrange",
                label: "lens_propo_colors_orange",
                value: "#ff5349"
            },
            {
                id: "lensMirrorParma",
                label: "lens_propo_colors_parma",
                value: "#d3d3d3"
            },
            {
                id: "lensMirrorPinkSilver",
                label: "lens_propo_colors_pink_silver",
                value: "#c4aead"
            },
            {
                id: "lensMirrorSilver",
                label: "lens_propo_colors_silver",
                value: "#C0C0C0"
            },
            {
                id: "lensMirrorSilverShadow",
                label: "lens_propo_colors_silver_shadow",
                value: "#383737"
            },
            {
                id: "lensMirrorSkyBlueSilver",
                label: "lens_propo_colors_sky_blue_silver",
                value: "#a7c5d1"
            },
            {
                id: "lensMirrorViolet",
                label: "lens_propo_colors_violet",
                value: "#270238"
            },
                    
        ],
        smallTitle: '',
        description: "",
        benefits: [
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 1,
            },
            {
                icon: "env-light",
                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                type: 1,
            },
            {
                icon: "colors",
                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                type: 2,
            },
            {
                icon: "outdoor-sun",
                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                type: 2,
            },
        ]
    },
    "crizal_easy":{
        id: 'crizal_easy',
        label: 'Crizal Easy',
        smallTitle: 'questionnaires_q_recomm_short_desc_criz_eas',
        description: 'questionnaires_q_recomm_long_desc_criz_eas',
        enabled: true,
        price:0,
        isKids: true,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "crizal_forte":{
        id: 'crizal_forte',
        label: 'Crizal Forte',
        smallTitle: '',
        description: "",
        enabled: true,
        price:0,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "crizal_prevencia":{
        id: 'crizal_prevencia',
        label: 'Crizal Prevencia',
        complementaryEquipments: [ComplementaryEquipmentType.COMPUTER],
        smallTitle: 'questionnaires_q_recomm_short_desc_cp',
        description: 'questionnaires_q_recomm_long_desc_cp',
        enabled: true,
        price:0,
        isKids: true,
        benefits: [
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 1,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
            {
                icon: "harmful-light",
                label: "pair_1_tab_del_p1__harmf",
                type: 2,
            },
        ]
    },
    "crizal_drive":{
        id: 'crizal_drive',
        label: 'Crizal Drive',
        complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
        smallTitle: 'questionnaires_q_recomm_short_desc_cd',
        description: 'questionnaires_q_recomm_long_desc_cd',
        enabled: true,
        price:0,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "crizal_aliz":{
        id: 'crizal_aliz',
        label: 'Crizal Alizé',
        smallTitle: '',
        description: "",
        enabled: true,
        price:0,
        isKids: true,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "crizal_sapphire":{
        id: 'crizal_sapphire',
        label: 'Crizal Sapphire',
        lensDesign: LensEnhanceType.AR,
        smallTitle: 'questionnaires_q_recom_short_desc_cri_sapp',
        description: 'questionnaires_q_recom_long_desc_cri_sapp',
        enabled: true,
        price:0,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "crizal_rock":{
        id: 'crizal_rock',
        label: 'Crizal Rock',
        smallTitle: '',
        description: "",
        enabled: true,
        price:0,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "crizal_sun_xprotect":{
        id: 'crizal_sun_xprotect',
        label: 'Crizal Sun Xprotect',
        lensDesign: LensEnhanceType.TINTED,
        smallTitle: 'questionnaires_q_recomm_short_desc_cri_sun',
        description: 'questionnaires_q_recomm_long_desc_cri_sun',
        enabled: true,
        price:0,
        benefits: [
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_p1__optimal_clarity",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "smudge-res",
                label: "pair_1_tab_del_p1__smudge_resistant",
                type: 1,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
            {
                icon: "water-repel",
                label: "pair_1_tab_del_p1__water_repellent",
                type: 2,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_p1__harmful",
                type: 2,
            },
        ]
    },
    "optifog":{
        id: 'optifog',
        label: 'Optifog',
        smallTitle: '',
        description: "",
        enabled: true,
        price:0,
        benefits: [
            {
                icon: "fog-rep",
                label: "pair_1_tab_del_p1__fog_repellence",
                type: 1,
            },
            {
                icon: "scratch-icon",
                label: "pair_1_tab_del_p1__scratch_resistant",
                type: 1,
            },
            {
                icon: "uv-protect",
                label: "pair_1_tab_del_2_p1_double_sided_uv_protection.",
                type: 1,
            },
            {
                icon: "optimal-clarity",
                label: "pair_1_tab_del_2_p1_outdoor_sun_glare_reduction",
                type: 2,
            },
            {
                icon: "dust-repel",
                label: "pair_1_tab_del_p1__dust_repellent",
                type: 2,
            },
        ]
    }
}

export const BRAND_CONFIG = {
    "varilux": {
        id: 'varilux',
        label: 'Varilux',
        background: variluxCard,
        logo:'varilux',
    },
    "standard_design": {
        id: 'standard_design',
        label: 'Standard Design',
    },
    "eyezen": {
        id: 'eyezen',
        label: 'Eyezen',
        background: eyezenCard,
        logo:'eyezen',
    },
    "essidrive": {
        id: 'essidrive',
        label: 'Essidrive',
    },
    "stellest": {
        id: 'stellest',
        label: 'Stellest',
        background: stellestCard2,
        logo:'stellest-logo',
    },
    "xperio": {
        id: 'xperio',
        label: 'Xperio',
        background: experioCard,
        logo: 'xperio-logo',
    },
    "blueuv_filter_system": {
        id: 'BlueUV_Filter_System',
        label: 'BlueUV Filter System',
        background: blueUVCard,
        logo:'BlueUV_FilterSystem',
    },
    "transitions": {
        id: 'transitions',
        label: 'Transitions',
        background: transitionsCard,
        logo: 'transitions-logo',
    },
    "crizal": {
        id: 'crizal',
        label: 'Crizal',
        background: crizalCard,
        logo: 'crizal-logo',
    },
    "optifog": {
        id: 'optifog',
        label: 'Optifog',
        logo: 'optifog-logo',
        background: optifogCard,
    }
}

export const COLOR_CONFIG = {
    'lensMirrorBronzeShadow': {
        id: "lensMirrorBronzeShadow",
        label: "Bronze Shadow",
        value: "#33070e"
    },
    'lensMirrorBronze': {
        id: "lensMirrorBronze",
        label: "Bronze",
        value: "#c66a29"
    },
    'lensMirrorCandy': {
        id: "lensMirrorCandy",
        label: "Candy",
        value: "#9d2b49"
    },
    'lensMirrorForestGreen':{
        id: "lensMirrorForestGreen",
        label: "lens_propo_colors_forest_green",
        value: "#39721a"
    },
    'lensMirrorGoldPink':{
        id: "lensMirrorGoldPink",
        label: "lens_propo_colors_gold_pink",
        value: "#924a35"
    },
    'lensMirrorGreenShadow':{
        id: "lensMirrorGreenShadow",
        label: "lens_propo_colors_green_shadow",
        value: "#1b160f"
    },
    'lensMirrorKaki':{
        id: "lensMirrorKaki",
        label: "lens_propo_colors_kaki",
        value: "#59621f"
    },
    'lensMirrorMauveSilver':{
        id: "lensMirrorMauveSilver",
        label: "lens_propo_colors_mauve_silver",
        value: "#ada5d2"
    },
    'lensMirrorNewBlue':{
        id: "lensMirrorNewBlue",
        label: "lens_propo_colors_new_blue",
        value: "#3c8ea9"
    },
    'lensMirrorNewSilver':{
        id: "lensMirrorNewSilver",
        label: "lens_propo_colors_new_silver",
        value: "#778fa3"
    },
    'lensMirrorOceanBlue':{
        id: "lensMirrorOceanBlue",
        label: "lens_propo_colors_ocean_blue",
        value: "#243459"
    },
    'lensMirrorOrange':{
        id: "lensMirrorOrange",
        label: "lens_propo_colors_orange",
        value: "#7b1d16"
    },
    'lensMirrorParma':{
        id: "lensMirrorParma",
        label: "lens_propo_colors_parma",
        value: "#b9adc2"
    },
    'lensMirrorPinkSilver':{
        id: "lensMirrorPinkSilver",
        label: "lens_propo_colors_pink_silver",
        value: "#c1b4c0"
    },
    'lensMirrorSilverShadow':{
        id: "lensMirrorSilverShadow",
        label: "lens_propo_colors_silver_shadow",
        value: "#4e4340"
    },
    'lensMirrorSilver':{
        id: "lensMirrorSilver",
        label: "lens_propo_colors_silver",
        value: "#8b8d92"
    },
    'lensMirrorSkyBlueSilver':{
        id: "lensMirrorSkyBlueSilver",
        label: "lens_propo_colors_blue_silver",
        value: "#9fb6cf"
    },
    'lensMirrorViolet':{
        id: "lensMirrorViolet",
        label: "lens_propo_colors_violet",
        value: "#21092f"
    },
    'lensPolarizedAmber':{
        id: "lensPolarizedAmber",
        label: "lens_propo_colors_amber",
        value: "#9C703B"
    },
    'lensPolarizedBlueInk':{
        id: "lensPolarizedBlueInk",
        label: "lens_propo_colors_blue_ink",
        value: "#395158"
    },
    'lensPolarizedBluePurple':{
        id: "lensPolarizedBluePurple",
        label: "lens_propo_colors_blue_purple",
        value: "#6437b8"
    },
    'lensPolarizedBlueSky':{
        id: "lensPolarizedBlueSky",
        label: "lens_propo_colors_blue_sky",
        value: "#87ceeb"
    },
    'lensPolarizedBrown':{
        id: "lensPolarizedBrown",
        label: "lens_propo_colors_brown",
        value: "#A52A2A"
    },
    'lensPolarizedGrey':{
        id: "lensPolarizedGrey",
        label: "lens_propo_colors_grey",
        value: "#808080"
    },
    'lensPolarizedGreyGreen':{
        id: "lensPolarizedGreyGreen",
        label: "lens_propo_colors_grey_green",
        value: "#656255"
    },
    'lensPolarizedHerbal':{
        id: "lensPolarizedHerbal",
        label: "lens_propo_colors_herbal",
        value: "#8a8849"
    },
    'lensPolarizedViolet':{
        id: "lensPolarizedViolet",
        label: "lens_propo_colors_violet",
        value: "#6b2d7a"
    },
    'lensTintedBlack':{
        id: "lensTintedBlack",
        label: "lens_propo_colors_black",
        value: "#000000"
    },
    'lensTintedBlueInk':{
        id: "lensTintedBlueInk",
        label: "lens_propo_colors_blue_ink",
        value: "#415c80"
    },
    'lensTintedBrown':{
        id: "lensTintedBrown",
        label: "lens_propo_colors_brown",
        value: "#6d5a45"
    },
    'lensTintedBurgundy':{
        id: "lensTintedBurgundy",
        label: "lens_propo_colors_burgundy",
        value: "#89676f"
    },
    'lensTintedGreyGreen':{
        id: "lensTintedGreyGreen",
        label: "lens_propo_colors_grey_green",
        value: "#3c5854"
    },
    'lensTintedGrey':{
        id: "lensTintedGrey",
        label: "lens_propo_colors_grey",
        value: "#4c5d6e"
    },
    'lensTintedHerbal':{
        id: "lensTintedHerbal",
        label: "lens_propo_colors_herbal",
        value: "#8e8565"
    },
    'lensTintedNewBrown':{
        id: "lensTintedNewBrown",
        label: "lens_propo_colors_new_brown",
        value: "#806b4d"
    },  
    'lensTransitionGreen':{
        id: "lensTransitionGreen",
        label: "lens_propo_colors_green",
        value: "#3F9A49"
    },
    'lensTransitionGrey':{
        id: "lensTransitionGrey",
        label: "lens_propo_colors_grey",
        value: "#B2B2B6"
    },
    'lensTransitionAmber':{
        id: "lensTransitionAmber",
        label: "lens_propo_colors_amber",
        value: "#9C703B"
    },
    'lensTransitionSapphire':{
        id: "lensTransitionSapphire",
        label: "lens_propo_colors_sapphire",
        value: "#346297"
    },
    'lensTransitionEmerald':{
        id: "lensTransitionEmerald",
        label: "lens_propo_colors_emerald",
        value: "#046253"
    },
    'lensTransitionBrown':{
        id: "lensTransitionBrown",
        label: "lens_propo_colors_brown",
        value: "#9E5425"
    },
    'lensTransitionAmethyst':{
        id: "lensTransitionAmethyst",
        label: "lens_propo_colors_amethyst",
        value: "#4F3281"
    }
}
