import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iLensDemo from '../../interface/lens-demo';
import iProductBrand from '../../interface/product-brand.interface';
import iProductDemo from '../../interface/product-demo';
import iProduct from '../../interface/product.inteface';
import iRoute from '../../interface/route.interface';
import LensFamilyType from '../../types/lens-family.type';
import iProductDemoStore from '../interface/product-demo-store.interface';
import iRootState from '../interface/root-state.interface';

const initialState: iProductDemoStore  = {
    productDescription: null,
    syncVideoPlayFlag: 0,

    isControlsDisabled: false,
    isCompareEnabled: false,
    lensFamily: LensFamilyType.CORRECT,
    triggerCenter: 0,
    enableDrawLine: true,
    seeThroughActivated: false,
    qrPopup:false
}

const productDemoSlice = createSlice({
    name: 'productDemo',
    initialState,
    reducers: {
        changeDemoType: (state, action: PayloadAction<iLensDemo | undefined>) => ({...state, demoType: action.payload }),
        setSubDemoType: (state, action: PayloadAction<iLensDemo>) => ({...state, subDemoType: action.payload }),
        setProductDescription: (state, action: PayloadAction<string[] | null>) => ({...state, productDescription: action.payload}),
        disableControls: (state, action: PayloadAction<boolean>) => ({...state, isControlsDisabled: action.payload}),
        enableCompare: (state, action: PayloadAction<boolean>) => ({...state, isCompareEnabled: action.payload}),
        selectLensFamily: (state, action: PayloadAction<LensFamilyType>) => ({...state, lensFamily: action.payload}),

        setActiveProductDemo: (state, action: PayloadAction<iProductDemo | undefined>) => ({...state, activeProductDemo: action.payload}),
        setActiveCompareProductDemo: (state, action: PayloadAction<iProductDemo | undefined>) => ({...state, activeCompareProductDemo: action.payload}),        
        updateVideoPlayFlag: (state, action: PayloadAction<number | null>) => {
            const newValue = null === action.payload ? state.syncVideoPlayFlag + 1 : action.payload;
            return {...state, syncVideoPlayFlag: newValue}
        },

        setRecommendationDemo: (state, action: PayloadAction<{
            correct?: iProduct,
            protect?: iProduct,
            enhance?: iProduct,
        }>) => {
            return {...state, recommendationDemo: action.payload}
        },
        setRecommendationDemoBrands: (state, action: PayloadAction<{
            correct?: iProductBrand,
            protect?: iProductBrand,
            enhance?: iProductBrand,
        }>) => {
            return {...state, recommendationDemoBrand: action.payload}
        },
        setDemoFrom: (state, action: PayloadAction<iRoute>) => ({...state, demoFrom: action.payload}),
        triggerCenter: (state) => ({
            ...state, 
            triggerCenter: state.triggerCenter + 1
        }),
        toggleDrawLine: (state, action: PayloadAction<boolean>) => ({
            ...state, 
            enableDrawLine: action.payload
        }),
        activateSeeThrough: (state, action: PayloadAction<boolean>) => ({...state, seeThroughActivated: action.payload }),
        openQrPopup: (state, action: PayloadAction<boolean>) => ({...state, qrPopup: action.payload }),

    }
});

export const getProductDemo = (state:iRootState) => state.productDemo;
export default productDemoSlice;