import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    count: number,
    type?: string,
}
/*
    count   - Number to display
    type    - i.e. home / menu
                > home - Card [What's new, eMat, ...]
                > menu - Right Sidebar [Tutorial, ...]

*/ 
const BadgeNotification : React.FC<Props>  = ({
    count,
    type = 'home',
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('card-notification');

    const getClass = () => {
        if (type === "menu") {
            return `${B()} ${E('side')}`;
        } else {
            return B();
        }
    }

    return (
        <span className={getClass()}>
            {count}
        </span>
    )
}

export default BadgeNotification;