import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import routes from '../../../constants/routes.constant';
import iRoute from '../../../interface/route.interface';
import iTheme from '../../../interface/theme.interface';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useGoto from '../../../utils/hooks/useGoto';
import Utils from '../../../utils/Utils';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import AgeCategoryType from '../../../types/age-category.type';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';


interface Props {
    label: string,
    icon: string,
    completed?: boolean,
    className?: string,
    footer?: React.ReactNode,
    route?: iRoute,

    theme?: iTheme
}

const LensProposalBuilderCard : React.FC<Props>  = ({
    label,
    icon,
    completed = false,
    className = '',
    footer,
    route,
    theme
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-proposal-builder-card');
    const { ageCategory } = useSelector(getUser);
    const goto = useGoto();
    const { getThemeVariables } = Utils();
    const ga = useGoogleAnalytics();
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const classModifiers = () => {
        const classes = [];
        if(completed) classes.push('completed');
        if(footer) classes.push('with-footer');
        classes.push(ageCategory?.value || '');
        return classes;
    }

    const gotoRoute = () => {
        if (!route)return;

        ga.event({
            category: `LPB - ${t(ageCategory?.label as string, 'enUS')}`, 
            action: 'Button Click',
            label: t(label, 'enUS')
        });
        
        goto.routeWithTransition(
            routes.lensProposalBuilder,
            route,
            'FADE_OUT',
            800
        );
    }

    const actionButtonIcon = () => {
        const plus = ageCategory?.value === AgeCategoryType.KIDS ? 'plus-white-outline' : 'plus';

        return completed ? 'pencil' : plus;
    }

    const themeStyle = () => {
        if(!theme)return {};

        return getThemeVariables(theme)
    }

    return (
        <div 
            style={themeStyle()}

            className={B(classModifiers()) + ` ${className}`} onClick={gotoRoute}>

            <div className={E('info')}>
                <h2>
                    <Icon name={icon}/>
                    {t(label)}
                </h2>

                { completed && <span>{t('lens_thickness_lt_completed')}</span> }
            </div>
            <Button icon={actionButtonIcon()} type="secondary"  circle={true}/>

            { footer && footer}
        </div>
    )
}

export default LensProposalBuilderCard;