import React, { useEffect, useRef, useState } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import { CircularInput, CircularProgress, CircularThumb, CircularTrack, useCircularDrag, useCircularInputContext } from 'react-circular-input';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: number) => void
}

const Dial : React.FC<Props>  = ({
    question,
    onAnswer
}) =>  {
    // VARIABLES
    const { t } =  useTranslation(useSelector(getApp));
    const [answer, setAnswer] = useState((question.answer as number)/10); 
    const min = 0.0099;
    const max = 0.96;
    

    
    // METHODS
    const valueWithinLimits = (v:number) => {
        return Math.min(Math.max(v, min), max)
    }
    const dialChanged = (data: number) => {
        const whole = Math.ceil(Math.ceil(data*100)/10)
        if(onAnswer)onAnswer(whole);
    }

    return (
        <div className="dial">
            {/* <CircularSlider
                direction={1}
                width={270}
                label={t(question.dial?.label ? question.dial.label : '')}
                min={question.dial?.min}
                max={question.dial?.max}
                dataIndex={question.answer}
                labelColor="#0386D6"
                labelBottom={true}
                knobColor="white"
                knobSize={50}
                progressColorFrom="#0386D6"
                progressSize={22}
                trackColor="rgb(242,249,253)"
                trackSize={22}
                valueFontSize="4.5rem"
                onChange={ (data:number) => { dialChanged(data); } }

            ></CircularSlider> */}
            <CircularInput
                value={valueWithinLimits(answer)}
                radius={115}
                
                onChange={v => setAnswer(valueWithinLimits(v))}
                min={question.dial?.min}
                max={question.dial?.max}
                onChangeEnd={(data:number) => { dialChanged(data); }}
            >
                    <CircularTrack strokeWidth={20} stroke="rgb(242,249,253)">
                        
                    </CircularTrack>
                    <CircularProgress strokeWidth={20} stroke="#0386D6"
                    className="addBackground"/>
			        <CircularThumb r={14} fill="white"
                    className="addBackground" />
                    <text textAnchor="middle" x="116" y="120" 
                    style={{ fontSize: "4.5rem", fontWeight: "bold" }}
                    fill="#0386D6"
                    >
                            {Math.ceil(Math.floor(answer*100)/10)}
                    </text>
                    <br></br>
                    <text textAnchor="middle" x="116" y="150"
                    style={{ fontSize: "1.25rem", fontWeight: "bold" }}
                    fill="#0386D6"
                    >
                            {t('need_explorer_hr_per_day')}   
                    </text>
            </CircularInput>
        </div>
    )
}

export default Dial;

