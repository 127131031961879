import React, { useEffect, useState } from 'react';
import useBEM from '../utils/hooks/useBEM';

interface Props {
    id: string,
    numberOfSlides: number,
    currentSlide?: number
}

const SlideView : React.FC<Props>  = ({
    id,
    numberOfSlides,
    currentSlide,
    children,    
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('slide-view');
    const [finalWidth, setFinalWidth] = useState<string>();

    // HOOKS
    useEffect(()=> {
        const element = document.getElementById(id);
        const offsetWidth = element?.offsetWidth || 0;
        // convert to REM, devide by 10
        const finalWidth = `${(offsetWidth / 10) * numberOfSlides}rem`;                
        setFinalWidth(finalWidth);
    }, [id, numberOfSlides])

    useEffect(() => {
        const assignActiveSlide = () => {
            const selector = `#${id} .slide-view__slider > div`;
            const slides = document.querySelectorAll(selector);
            slides.forEach((slide, index) => {            
                if(currentSlide === index) {
                    slide.classList.add('active-slide');
                } else {
                    slide.classList.remove('active-slide');
                }
            });
        }

        setTimeout(() => {
            assignActiveSlide();    
        });
    }, [currentSlide, id])

    // METHODS
    const getDynamicStyle = () => {
        return  {
            width: finalWidth,
            transform: `translateX(-${ ((100 / numberOfSlides) * (currentSlide || 0)) }%)`
        }
    }

    return (
        <div className={B()} id={id}>

            {
                finalWidth && 
                <div className={E('slider')} style={getDynamicStyle()} >
                    {children}
                </div>
            }
            
        </div>
    )
}

export default SlideView;