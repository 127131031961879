import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import lensConsultationSlice from '../../store/slices/lens-consultation.slice';
import userSlice from '../../store/slices/user.slice';
import useBEM from '../../utils/hooks/useBEM';
import usePricing from '../../utils/hooks/usePricing';
import DeliverySummary from './components/DeliverySummary';
import DeliveryTotal from './components/DeliveryTotal';

interface Props {

}

const Delivery : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B] = useBEM('delivery-page');
    const { getBreakdown } = usePricing()
    const dispatch = useDispatch();

    // HOOKS
    useEffect(()=>{
        
        const breakdown = getBreakdown()
        console.log('breakdown', breakdown)
        dispatch(lensConsultationSlice.actions.setBreakdown(breakdown));
        
    },[])

    // METHODS

    return (
        <div className={B()}>

            <DeliveryTotal/>
            <DeliverySummary/>

        </div>
    )
}

export default Delivery;