import iMeasureStep from "../interface/measure-step.interface";

const measuresSteps: iMeasureStep[] = [
    {
        navigationTitle: 'measures_mes_title_left',
        backgroundImage: 'measure-step-1',
        label: 'measures_mes_measure_subtitle_right',
        description: 'measures_mes_body_right',
    },
    {
        navigationTitle: 'measures_mes_2_title_left',
        backgroundImage: 'measure-step-2',
        label: 'measures_mes_2_subtitle_right',
        description: "measures_mes_2_body_right",
    },
    {
        navigationTitle: 'measures_mes__3_title_left',
        backgroundImage: 'measure-step-3',
        label: 'measures_mes__3subtitle_right',
        description: "measures_mes__3_body_right",
    }
]

export default measuresSteps;