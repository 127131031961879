import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iMyCompanionStore from '../interface/mycompanion-store.interface';
import iRootState from '../interface/root-state.interface';
import iProductBrand from '../../interface/product-brand.interface';
import iProduct from '../../interface/product.inteface';
import iSubProduct from '../../interface/sub-product.inteface';
import LensFamilyType from '../../types/lens-family.type';
import iProductFamily from '../../interface/product-family.interface';
import { lensThickness, discount } from '../../constants/my-companion.constants';
import iLensThicknessSetting from '../../interface/lens-thickness-setting';
import iDiscountSetting from '../../interface/discount-setting';

const initialState: iMyCompanionStore  = {
    isPriceOff: true,
    essilorRecommend: false,
    category: 'correct',
    tempLensThicknessSettings: lensThickness,
    tempDiscountSettings: discount,
    configUpdatedAt: ''
}

const myCompanionSlice = createSlice({
    name: 'mycompanion',
    initialState,
    reducers: {
        offPrice: (state, action: PayloadAction<boolean>) => ( {...state, isPriceOff: action.payload} ),
        offEssilorRecommended: (state, action: PayloadAction<boolean>) => ( {...state, essilorRecommend: action.payload} ),
        selectCategory: (state, action: PayloadAction<string | undefined>) => ( {...state, category: action.payload} ),
        selectPrice: (state, action: PayloadAction<any>) => ( {...state, data: action.payload} ),
        setProductList: (state, action: PayloadAction<any>) => ({...state, productList: action.payload}),

        setLensThicknessSettings: (state, action: PayloadAction<iLensThicknessSetting[]>) => {
            return {
                ...state,
                tempLensThicknessSettings: action.payload
            }
        },
        setDiscountSettings: (state, action: PayloadAction<iDiscountSetting[]>) => {
            return {
                ...state,
                tempDiscountSettings: action.payload
            }
        },
        enableDisabledLensThicknessSetting: (state, action: PayloadAction<iLensThicknessSetting>) => {
            return {
                ...state,
                tempLensThicknessSettings: state.tempLensThicknessSettings.map( x => {
                    if(x.value === action.payload.value) {
                        return { ...x, enabled: !x.enabled}
                    }
                    return x;
                })
            }
        },
        enableAmountBtn: (state, action: PayloadAction<iDiscountSetting>) => {
            return {
                ...state,
                tempDiscountSettings: state.tempDiscountSettings.map( x => {
                    if(x.value === action.payload.value) {
                        return {
                            ...x,
                            amountEnabled: !x.amountEnabled,
                            discountEnabled: !action.payload.discountEnabled
                        }
                    }
                    return x;
                })
            }
        },
        enableDiscountBtn: (state, action: PayloadAction<iDiscountSetting>) => {
            return {
                ...state,
                tempDiscountSettings: state.tempDiscountSettings.map( x => {
                    if(x.value === action.payload.value) {
                        return {
                            ...x,
                            discountEnabled: !x.discountEnabled,
                            amountEnabled: !action.payload.amountEnabled
                        }
                    }
                    return x;
                })
            }
        },

        enableDisabledDiscountSettings: (state, action: PayloadAction<iDiscountSetting>) => {
            return {
                ...state,
                tempDiscountSettings: state.tempDiscountSettings.map( x => {
                    if(x.value === action.payload.value) {
                        return { ...x, enabled: !x.enabled}
                    }
                    return x;
                })
            }
        },

        updateDiscountPair: (state, action: PayloadAction<{
            value: string,
            newPrice: number,
            isAmount: boolean | undefined
        }>) => {
            return {
                ...state,
                tempDiscountSettings: state.tempDiscountSettings.map(( x, i )=> {
                    if(i > 0){
                        if(x.value === action.payload.value) {
                            return {
                                ...x,
                                discountAmount: action.payload.isAmount ? action.payload.newPrice : x.discountAmount,
                                discountPercentage: action.payload.isAmount ? x.discountPercentage : action.payload.newPrice,
                            }
                        }
                        return x;
                    }
                    return x;
                })
            }
        },

        updatePriceLensThicknessSetting: (state, action: PayloadAction<{
            value: number,
            newPrice: number
        }>) => {
            return {
                ...state,
                tempLensThicknessSettings: state.tempLensThicknessSettings.map( x => {
                    if(x.value === action.payload.value) {
                        return { ...x, price: action.payload.newPrice}
                    }
                    return x;
                })
            }
        },
        updateBrandProductPrices: (state, action: PayloadAction<{
            familyId: string,
            brandId: string,
            newPrice: number
        }>) => {
            const {familyId, brandId, newPrice} = action.payload;
            const newState = state.productList.map( (family: iProductFamily) => {
                if(family.id === familyId){
                    return {
                        ...family,
                        brands: family.brands.map( brand => {
                            if(brand.id === brandId){
                                return {
                                    ...brand,
                                    products: brand.products.map( product => ({
                                        ...product,
                                        price: newPrice
                                    }))
                                }
                            }
                            return brand;
                        })
                    }
                }
                return family;
            });

            return {
                ...state,
                productList: newState
            }
        },
        updateProductSubProductsPrices: (state, action: PayloadAction<{
            familyId: string,
            brandId: string,
            productId: string,
            newPrice: number
        }>) => {
            const {familyId, brandId, productId, newPrice} = action.payload;
            const newState = state.productList.map( (family: iProductFamily) => {
                if(family.id === familyId){
                    return {
                        ...family,
                        brands: family.brands.map( brand => {
                            if(brand.id === brandId){
                                return {
                                    ...brand,
                                    products: brand.products.map( product => {
                                        if(product.id === productId){
                                            return {
                                                ...product,
                                                subProducts: product.subProducts?.map( x => ({
                                                        ...x,
                                                        price: newPrice
                                                }))
                                            }
                                        }

                                        return product;
                                    })
                                }
                            }
                            return brand;
                        })
                    }
                }
                return family;
            });

            return {
                ...state,
                productList: newState
            }
        },
        enableDisableProduct: (state, action: PayloadAction<{
            family: LensFamilyType,
            brand: iProductBrand,
            product: iProduct,
            subProduct?: iSubProduct
        }>) => {

            const lensFamily = state.productList.map( (family:iProductFamily) => {
                if(family.id === action.payload.family) {
                    return {
                        ...family,
                        brands: family.brands.map( brand => {
                            if(brand.id === action.payload.brand.id) {
                                return {
                                    ...brand,
                                    products: brand.products.map( product => {
                                        if(product.id === action.payload.product.id) {

                                            // PRODUCT
                                            if(!action.payload.subProduct) {
                                                return {
                                                    ...product,
                                                    enabled: !product.enabled,
                                                    subProducts: !product.subProducts ? null : product.subProducts.map( x => ({ ...x, enabled: !product.enabled }))
                                                }
                                            }

                                            //SUB PRODUCT
                                            return {
                                                ...product,
                                                enabled: !action.payload.subProduct.enabled ? true : product.enabled,
                                                subProducts: product.subProducts?.map( subProduct => {
                                                    if(subProduct.label === action.payload.subProduct?.label) {
                                                        return { ...subProduct, enabled: !subProduct.enabled }
                                                    }
                                                    return subProduct;
                                                })
                                            }

                                        }
                                        return product;
                                    })
                                }
                            }
                            return brand;
                        })
                    }
                }
                return family;
            })
            return {...state, productList: lensFamily};
        },

        updateProductPrice: (state, action: PayloadAction<{
            family: LensFamilyType,
            brand: iProductBrand,
            product: iProduct,
            subProduct?: iSubProduct,
            newPrice: number
        }>) => {

            const lensFamily = state.productList.map( (family:iProductFamily) => {
                if(family.id === action.payload.family) {
                    return {
                        ...family,
                        brands: family.brands.map( brand => {
                            if(brand.id === action.payload.brand.id) {
                                return {
                                    ...brand,
                                    products: brand.products.map( product => {
                                        if(product.id === action.payload.product.id) {

                                            // PRODUCT
                                            if(!action.payload.subProduct) {
                                                return { ...product, price: action.payload.newPrice }
                                            }

                                            //SUB PRODUCT
                                            return {
                                                ...product,
                                                subProducts: product.subProducts?.map( subProduct => {
                                                    if(subProduct.label === action.payload.subProduct?.label) {
                                                        return { ...subProduct, price: action.payload.newPrice }
                                                    }
                                                    return subProduct;
                                                })
                                            }

                                        }
                                        return product;
                                    })
                                }
                            }
                            return brand;
                        })
                    }
                }
                return family;
            })
            return {...state, productList: lensFamily};
        },


        saveMyCompannionData: (state, action: PayloadAction<iMyCompanionStore>) => ( {
            ...state,
            ...action.payload
        }),

        saveConfigurationData: (state, action: PayloadAction<any>) => ({
            ...state,
            productList: action.payload.product_selected,
            tempDiscountSettings: action.payload.pricing_discount,
            configUpdatedAt: action.payload.updated_at,
            tempLensThicknessSettings: action.payload.lens_thickness
        })
    },


});

export const getMyCompanion = (state:iRootState) => state.myCompanion;
export default myCompanionSlice;
