import React, { useCallback, useEffect, useRef, useState } from 'react';
import useBEM from '../../utils/hooks/useBEM';
import AdvanceSettingFilterResults from './components/AdvanceSettingFilterResults';
import AdvanceSettingFilter from './components/AdvancePriceSettingFilter';
import Button from '../../components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import appSlice, { getApp } from '../../store/slices/app.slice';
import iProductCombi from '../../store/interface/product-combi.interface';
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constant';
import Modal from '../../components/Modal';
import Icon from '../../components/ui/Icon';
import useTranslation from '../../utils/hooks/useTranslation';

interface Props {

}

const AdvancePriceSetting : React.FC<Props>  = () =>  {
    // VARIABLES
    const dispatch = useDispatch();
    const [B, E] = useBEM('advance-price-setting');
    const appStore = useSelector(getApp);
    const { productCombi, accountDetails} = appStore;
    const originalResults = useRef([...productCombi]);
    const [filteredResults, setFilteredResults] = useState([...productCombi]);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const goto = useGoto();
    const { t } = useTranslation(appStore);

    useEffect(() => {
        originalResults.current = appendAdvanceProperties(originalResults.current);
        setFilteredResults( originalResults.current )
    }, [])

    // METHODS
    const appendAdvanceProperties = (productCombi: iProductCombi[]) => {
        const transformed =  productCombi.map( (x, indx) => {
            return {
                ...x,
                id: String(indx + 1),
                ...( x.correctEnhanceModifier || { correctEnhanceModifier: { type: 'actual' as any, value: '0' }}),
                ...( x.protectModifier || { protectModifier: { type: 'actual' as any, value: '0' }}),
                ...( x.flatFee || { flatFee: { value: '0' }}),
            };
        });
        return transformed;
    }

    const filterOnSubmit = useCallback((values: any[]) => {
        
        const filterFieldsWithValues = values.filter( x => x.value);
        if(filterFieldsWithValues.length === 0){
            setFilteredResults(originalResults.current);
            return;
        }

        setFilteredResults(() => {
            const filtered = [...originalResults.current].filter( (x: any) => {
                const requiredFound = filterFieldsWithValues.length;
                let foundCount = 0;

                filterFieldsWithValues.forEach( f => {
                    if(x[f.id] === f.value.value)foundCount += 1;
                });
                return requiredFound === foundCount;
            });
            return filtered;
        });
    }, [])


    const onUpdateItemHandler = (
        id: string, 
        field: 'correctEnhanceModifier' | 'protectModifier' | 'flatFee', 
        value: string, 
        type?: any
    ) => {

        originalResults.current = originalResults.current.map( x => {
            if(x.id === id){
                return { ...x, [field]: { type, value } }
            }
            return x;
        })

        setFilteredResults( prev => {
            return prev.map( x => {
                if(x.id === id){
                    return { ...x, [field]: { type, value } }
                }
                return x;
            })
        });
        
    }

    const onSaveHandler = () => {
        dispatch(appSlice.actions.updateAdvancePriceCombinations(originalResults.current));
        setShowSuccessAlert(true);
        goto.route(routes.home, 2000)
    }

    const onCancelHandler = () => {
        goto.route(routes.myCompanion);
    }

    return (
        <div className={B() + ' page'}>
            <AdvanceSettingFilter onSubmit={filterOnSubmit}/>

            <AdvanceSettingFilterResults 
                currency={accountDetails?.currency?.symbol as string}
                list={filteredResults || []} 
                onUpdateItem={onUpdateItemHandler}
            />


            <div className={E('buttons')}>
                <Button text={t('advancedpricing_save')} click={onSaveHandler}/>
                <Button text={t('advancedpricing_cancel')} type="secondary" click={onCancelHandler}/>
            </div>

            <Modal
                height="23rem" width="26rem"
                show={showSuccessAlert} hideCloseButton={true}>
                <div className="my-companion-footer__saved">
                    <Icon name="saved"></Icon>
                    <h2>Success</h2>
                </div>
            </Modal>
        </div>
    )
}

export default AdvancePriceSetting;