import iLensThicknessSetting from "../../interface/lens-thickness-setting";
import iSubProduct from "../../interface/sub-product.inteface";

type hookType = {
    getRecommendedLensThickness: (sphere: {od: number, os: number}, rules: any) => number,
    getRecommendedSubProductByIndex : (subProducts: iSubProduct[], index: number) => iSubProduct | undefined,
    filterSubProductsWithEnabledIndex : (subProducts: iSubProduct[], lensThicknessSettings: iLensThicknessSetting[]) => iSubProduct[]
}


const useLensThickness  = (): hookType =>  {

    const getRecommendedLensThickness = (sphere: {od: number, os: number}, rules: any) => {
        let lensThickness = 0;
        rules.forEach( (rule: any) => {
            if(rule.range){
                const odRange = sphere.od < 0 ? rule.range.negative : rule.range.positive;
                const osRange = sphere.os < 0 ? rule.range.negative : rule.range.positive;
                const odCondition = sphere.od >= odRange.min && sphere.od <= odRange.max;
                const osCondition = sphere.os >= osRange.min && sphere.os <= osRange.max;

                if(odCondition || osCondition) {
                    // Note: get the highest index
                    lensThickness = rule.value > lensThickness ? rule.value : lensThickness;
                }
            }
        });
        return lensThickness;
    }

    const getRecommendedSubProductByIndex = (subProducts: iSubProduct[], index: number) => {
        let recommended = subProducts.filter( x => x.index === index);
        if(recommended.length === 0) {
            recommended = getTheClosestSubProductByIndex(subProducts, index);
        } 
        return recommended[0]
    }

    const getTheClosestSubProductByIndex = (subProducts: iSubProduct[], index: number) => {
        const indexes = subProducts.map( x => x.index);        
        const closest = indexes.reduce(function(prev, curr) {
            return (Math.abs(curr - index) < Math.abs(prev - index) ? curr : prev);
        }, 0);
        return subProducts.filter( x => x.index === closest);
    }

    const filterSubProductsWithEnabledIndex = (subProducts: iSubProduct[], lensThicknessSettings: iLensThicknessSetting[]) => {
        const allIndex = lensThicknessSettings
            .filter( x => x.enabled)
            .map( x => x.value);
        return subProducts.filter( x => allIndex.includes(x.index));
    }

    return {
        getRecommendedLensThickness,
        getRecommendedSubProductByIndex,
        filterSubProductsWithEnabledIndex
    }
}

export default useLensThickness;