import React, { useState, useEffect, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../../components/Modal'
import useBEM from '../../../utils/hooks/useBEM'
import { getApp } from '../../../store/slices/app.slice'
import  PlayVideoIcon from '../../../assets/svg/varilux-g9/icons/play-video.svg'
import useTranslation from '../../../utils/hooks/useTranslation'
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext'
import Icon from '../../../components/ui/Icon'

// control icons
import Icon_1_white from "../../../assets/svg/varilux-g9/icons/control-1-white.svg"
import Icon_2_white from "../../../assets/svg/varilux-g9/icons/control-2-white.svg"
import Icon_3_white from "../../../assets/svg/varilux-g9/icons/control-3-white.svg"
import Icon_4_white from "../../../assets/svg/varilux-g9/icons/control-4-white.svg"
import Icon_5_white from "../../../assets/svg/varilux-g9/icons/control-5-white.svg"
import Icon_1 from "../../../assets/svg/varilux-g9/icons/control-1.svg"
import Icon_2 from "../../../assets/svg/varilux-g9/icons/control-2.svg"
import Icon_3 from "../../../assets/svg/varilux-g9/icons/control-3.svg"
import Icon_4 from "../../../assets/svg/varilux-g9/icons/control-4.svg"
import Icon_5 from "../../../assets/svg/varilux-g9/icons/control-5.svg"

interface Props {
}

const DemonstrationPopup: React.FC<Props> = () => {
    const [B, E] = useBEM('demonstration-pop-up')
    const [showDemoModal, setShowDemoModal] = useState<boolean>(false)
    const { t } = useTranslation(useSelector(getApp))
    const { get } = useContext(IndexedDBContext) as IndexedDBContextType

    const getScreenWidth = () => {
        const modalStyle = {
            width: "100%",
            height: "67.8rem",
            borderRadius: "15px"
        }

        const modalStyleMd = {
            width: "100%",
            height: "60.8rem",
            borderRadius: "15px"
        }

        const modalStyleSm = {
            width: "100%",
            height: "58.8rem",
            borderRadius: "15px"
        }

        let screenWidth = window.innerWidth

        if (screenWidth < 1024) {
            return modalStyleMd
        }

        if (screenWidth < 800) {
            return modalStyleSm
        }

        return modalStyle
    }

    const videoController = [
        {
            name: 'GPS',
            category: 'instant',
            clickedIcon: Icon_1_white,
            defaultIcon: Icon_1,
            isClicked: true, // for initial render
            text: ["varilux_demo_card_instant"]
        },
        {
            name: 'STAIRS',
            category: 'stabilized',
            clickedIcon: Icon_2_white,
            defaultIcon: Icon_2,
            isClicked: false,
            text: ["varilux_demo_card_stabilized"]
        },
        {
            name: 'TROTT',
            category: 'panoramic',
            clickedIcon: Icon_3_white,
            defaultIcon: Icon_3,
            isClicked: false,
            text: ["varilux_demo_card_panoramic"]
        },
        {
            name: 'LIVRE',
            category: 'sharp',
            clickedIcon: Icon_4_white,
            defaultIcon: Icon_4,
            isClicked: false,
            text: ["varilux_demo_card_sharp"]
        },
        {
            name: 'TABLETTE',
            category: 'extended',
            clickedIcon: Icon_5_white,
            defaultIcon: Icon_5,
            isClicked: false,
            text: ["varilux_demo_card_extended"]
        },
    ]

    const productsArray: any = [
        {
            isSelected: true, // set to true when initial loaded
            productName: 'Varilux® X series™',
            product: "xseries",
            videoIndex: {            
                instant: "2",
                stabilized: "1",
                panoramic: "1",
                sharp: "1",
                extended: "1",
            },
        },
        {
            isSelected: false,
            productName: 'Varilux® Physio 3.0',
            product: "physio",
            videoIndex: {
                instant: "3",
                stabilized: "2",
                panoramic: "2",
                sharp: "1",
                extended: "2",
            },
        },
        {
            isSelected: false,
            productName: 'Varilux® Comfort Max',
            product: "xseries",
            videoIndex: {
                instant: "3",
                stabilized: "2",
                panoramic: "3",
                sharp: "1",
                extended: "1",
            },
        },
        {
            isSelected: false,
            productName: 'Premium Progressive Lenses',
            product: "xseries",
            videoIndex: {
                instant: "3",
                stabilized: "2",
                panoramic: "3",
                sharp: "3",
                extended: "3",
            },
        }
    ]

    const modalStyle = {
        width: "100%",
        height: "69.8rem",
        borderRadius: "15px"
    }

    const [showProductList, setShowProductList] = useState<boolean>(false)
    const [controller, setController] = useState(videoController)
    const [productList, setProductList] = useState(productsArray)
    const [caption, setCaption] = useState<string>(videoController[0].text[0])
    const [videoAssetArray, setVideoAssetArray] = useState<any>([])
    const [currentSelectedProduct, setCurrentSelectedProduct] = useState<string>("Varilux® X series™")
    const [currentDemo, setCurrentDemo] = useState<any>({
        category: "GPS",
        selectedProductIndex: 1,
        selectedButtonIndex: 0,
    })

    useEffect(() => {
        setVideoAssetArray([])
        let leftVideoKey = getAssetMatrix(currentDemo.selectedButtonIndex, currentDemo.selectedProductIndex)

        getVideoFromIndexedDB(leftVideoKey, currentDemo.category)

        return () => {
            setVideoAssetArray([])
        }
    }, [currentDemo])
    

    const getVideoFromIndexedDB = async (key: string, category?: string) => {
        // videoName: string, videoIndex?: string
        let leftVideoAsset = await get("variluxG9Store", key)
        let rightVideoAsset = await get("variluxG9Store", category + "_0")

        if (leftVideoAsset && rightVideoAsset) {
            let arr = []
            arr.push(leftVideoAsset)
            arr.push(rightVideoAsset)

            setVideoAssetArray(arr)
        }
    }

    const controllerHandler = (ii: number, category: string) => {
        setController(controller.map((control, index) => {
            return {
                ...control,
                isClicked: index === ii ? true : false
            }
        }))
        setCurrentDemo({
            ...currentDemo,
            selectedButtonIndex: ii,
            category: videoController[ii].name,
        })
        setCaption(videoController[ii].text[0])
        setShowProductList(false)
    }

    const productListHandler = (ii: number, product: any) => {
        setCurrentSelectedProduct(productList[ii].productName)
        setProductList(productList.map((product: any, index: number) => ({
            ...product,
            isSelected: index === ii ? true : false
        })))
        setCurrentDemo({
            ...currentDemo,
            selectedProductIndex: ii + 1
        })
        setShowProductList(false)
    }

    const getAssetMatrix = (categoryIndex: number, productLevel: number): string => {
        if (productLevel === 1) {
            switch (categoryIndex) {
                case 0:
                    return currentDemo.category + "_" + (productLevel + 1).toString()
                case 1:
                case 2:
                case 3:
                case 4:
                    return currentDemo.category + "_" + (productLevel).toString()

                default:
                    return ''
            }
        } else if (productLevel === 2) {
            switch (categoryIndex) {
                case 0:
                    return currentDemo.category + "_" + (productLevel + 1).toString()
                case 1:
                case 2:
                case 4:
                    return currentDemo.category + "_" + (productLevel).toString()
                case 3:
                    return currentDemo.category + "_" + (productLevel - 1).toString()
                default:
                    return ''
            }
        } else if (productLevel === 3) {
            switch (categoryIndex) {
                case 0:
                case 2:
                    return currentDemo.category + "_" + (productLevel).toString()
                case 1:
                    return currentDemo.category + "_" + (productLevel - 1).toString()
                case 3:
                case 4:
                    return currentDemo.category + "_" + (productLevel - 2).toString()
                default:
                    return ''
            }
        } else if (productLevel === 4) {
            switch (categoryIndex) {
                case 0:
                case 2:
                case 3:
                case 4:
                    return currentDemo.category + "_" + (productLevel - 1).toString()
                case 1:
                    return currentDemo.category + "_" + (productLevel - 2).toString()

                default:
                    return ''
            }
        } else {
            return 'no video'
        }
    }

    return (
        <>
            <div className={E('play-icon')} style={{cursor: 'pointer'}} onClick={() => setShowDemoModal(true)}>
                <img src={PlayVideoIcon}/>          
            </div>
            <Modal
                show={showDemoModal}
                onClose={() => setShowDemoModal(false)}
                style={getScreenWidth()}
                noPadding={true}
                // className={B()}
            >
                <div className={B()}>
                    <div className={E('header')}></div>

                    <div className={E('videos-container')}>
                        {
                            videoAssetArray.map((video: any, index: number) => {
                                if (index === 0) {
                                    return (
                                        <div className="video" key={index}>
                                            <video 
                                                autoPlay={true}
                                                loop={true}
                                                playsInline
                                                muted
                                                src={video.asset} 
                                            />
                
                                            <div className={`product-selection ${showProductList ? "show" : "close"}`}>
                                                <div className="close-section">
                                                    <span onClick={() => setShowProductList(false)}></span>
                                                </div>
                                                <div className="products-container">
                                                    {
                                                        productList.map((product: any, ii: number) => {
                                                            return (
                                                                <div 
                                                                    key={ii}
                                                                    className={`product-name ${product.isSelected ? 'selectedProduct' : ''}`}
                                                                    onClick={() => {
                                                                        productListHandler(ii, product)
                                                                    }}
                                                                >
                                                                    {product.productName}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                
                                            {
                                                !showProductList && (
                                                    <p className={`selected-product`}>
                                                        {currentSelectedProduct}
                                                        <Icon
                                                            name="pencil"
                                                            className="pencil-style"
                                                            click={() => setShowProductList(true)}
                                                        />
                                                    </p>
                                                )
                                            }
                                        </div>
                                    )
                                }

                                return (
                                    <div className="video" key={index}>
                                        <video 
                                                autoPlay={true}
                                                loop={true}
                                                playsInline
                                                muted
                                                src={video.asset} 
                                            />
                                        <p className="selected-product">
                                            Varilux XR Series
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className={(E('footer'))}>
                        <div className="controls-container">
                            <div className={`controls ${showProductList ? "disable" : ""}`}>
                                {
                                    controller.map((controls, index) => (
                                        <img 
                                            key={index}
                                            className={controls.isClicked ? 'clicked' : ''}
                                            src={controls.isClicked ? controls.clickedIcon : controls.defaultIcon} 
                                            onClick={() => controllerHandler(index, controls.name)} 
                                        />
                                    ))
                                }
                            </div>

                            <div className="caption">
                                <p>{t(caption)}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
  )
}

export default DemonstrationPopup