import iLensConsultationType from "../interface/lens-consultation-type.interface";

const lensConsultationData: iLensConsultationType[] = [
    {
        type: 'correct',
        label: 'Correct',
        brands: [
            {
              value: "varilux",
              label: "Varilux",
              products: [
                {
                  value: "varilux_x_series",
                  label: "Varilux® X Series",
                  subProducts: [
                    {
                      value: "varilux_x_design",
                      label: "Varilux® X design",
                    }
                  ],
                  packageOffersDowngrade: [
                    {
                        value: "varilux_x_design",
                        label: "Varilux® X design"
                    },
                    {
                        value: "varilux_x_design_short",
                        label: "Varilux® X design short"
                    },
                    {
                        value: 'varilux_xclusive_4d',
                        label: 'Varilux® Xclusive™ 4D'
                    }
                  ]
                },
                {
                  value: "varilux_digitime",
                  label: "Varilux® Digitime™",
                  subProducts: [
                    {
                      value: "varilux_digitime_mid",
                      label: "Varilux® Digitime™ Mid"
                    }
                  ],
                  packageOffersDowngrade: [
                    {
                        value: "varilux_digitime_mid",
                        label: "Varilux® Digitime™ Mid"
                    },
                    {
                        value: 'varilux_digitime_room',
                        label: 'Varilux® Digitime™ Room'
                    },
                    {
                        value: 'varilux_digitime_near_plus',
                        label: 'Varilux® Digitime™ Near Plus'
                    }
                ]
                }
              ]
            },
            {
              value: "eyezen",
              label: "Eyezen",
              products: [
                {
                  value: "eyezen_start",
                  label: "Eyezen® Start",
                  subProducts: [
                    {
                      value: "eyezen_start_f-360",
                      label: "Eyezen® Start f-360°"
                    }
                  ],
                  packageOffersDowngrade: [
                    {
                        value: "eyezen_start_f-360",
                        label: "Eyezen® Start f-360°"
                    },
                    {
                        value: '',
                        label: 'Eyezen® Boost 0.4'
                    },
                    {
                        value: '',
                        label: 'Eyezen® Boost 0.4 f-360°'
                    }
                ]
                },
                {
                  value: "eyezen_boost",
                  label: "Eyezen® Boost",
                  subProducts: [
                    {
                      value: "eyezen_boost_0.4",
                      label: "Eyezen® Boost 0.4"
                    }
                  ],
                  packageOffersDowngrade: [
                    {
                        value: "eyezen_boost_0.4",
                        label: "Eyezen® Boost 0.4"
                    },
                    {
                        value: '',
                        label: 'Eyezen® Boost 0.4 f-360°'
                    },
                    {
                        value: '',
                        label: 'Eyezen® Boost 0.6'
                    }
                ]
                }
              ]
            }
          ]
    },
    {
        type: 'protect',
        label: 'Protect',
        brands: [
            {
                value: 'transitions',
                label: 'Transitions',
                products: [
                    {
                        value: 'transitions',
                        label: 'Transitions',
                        subProducts: [
                            {
                                value: 'transitions_gen_8',
                                label: 'Transitions Gen 8',
                            },
                            {
                                value: 'transitions_xtractive',
                                label: 'Transitions XTRActive',
                            }
                        ],
                        packageOffersDowngrade: [
                          {
                              value: 'transitions_gen_8',
                              label: 'Transitions Gen 8',
                          },
                          {
                              value: '',
                              label: 'Transitions XTRActive'
                          },
                          {
                              value: '',
                              label: 'Transitions Style Mirrors'
                          }
                      ]
                    }
                ]
            },
            {
                value: 'experio',
                label: 'Xperio',
                products: [
                    {
                        value: 'mirror',
                        label: 'Mirror',
                        subProducts: [
                            {
                                value: 'mirror_gen_8',
                                label: 'Mirror Gen 8',
                            },
                            {
                                value: 'mirror_xtractive',
                                label: 'Mirror XTRActive',
                            }
                        ],
                        packageOffersDowngrade: [
                          {
                              value: 'polarized_gen_8',
                              label: 'Polarized Gen 8',
                          },
                          {
                              value: '',
                              label: 'Xperio Tinted'
                          },
                          {
                              value: '',
                              label: 'Xperio Tinted'
                          }
                      ]
                    },
                    {
                        value: 'polarized',
                        label: 'Polarized',
                        subProducts: [
                            {
                                value: 'polarized_gen_8',
                                label: 'Polarized Gen 8',
                            },
                            {
                                value: 'polarized_xtractive',
                                label: 'Polarized XTRActive',
                            }
                        ],
                        packageOffersDowngrade: [
                          {
                              value: 'polarized_gen_8',
                              label: 'Polarized Gen 8',
                          },
                          {
                              value: '',
                              label: 'Xperio Tinted'
                          },
                          {
                              value: '',
                              label: 'Xperio Tinted'
                          }
                      ]
                    },
                    {
                        value: 'tinted',
                        label: 'Tinted',
                        subProducts: [
                            {
                                value: 'tinted_gen_8',
                                label: 'Tinted Gen 8',
                            },
                            {
                                value: 'tinted_xtractive',
                                label: 'Tinted XTRActive',
                            }
                        ],
                        packageOffersDowngrade: [
                          {
                              value: 'polarized_gen_8',
                              label: 'Polarized Gen 8',
                          },
                          {
                              value: '',
                              label: 'Xperio Tinted'
                          },
                          {
                              value: '',
                              label: 'Xperio Tinted'
                          }
                      ]
                    },
                ]
            }
        ]
    },
    {
        type: 'enhance',
        label: 'Enhance',
        brands: [
            {
                value: 'crizal',
                label: 'Crizal',
                products: [
                    {
                        value: 'crizal',
                        label: 'Crizal',
                        subProducts: [
                            {
                                value: 'crizal_sapphire',
                                label: 'Crizal Sapphire',
                            },
                            {
                              value: 'crizal_prevencia',
                              label: 'Crizal Prevencia',
                            },
                            {
                              value: 'crizal_sun_xprotect',
                              label: 'Crizal Sun Xprotect',
                            }
                        ],
                        packageOffersDowngrade: [
                          {
                              value: 'crizal_sapphire',
                              label: 'Crizal Sapphire',
                          },
                          {
                              value: '',
                              label: 'Crizal Rock'
                          },
                          {
                              value: '',
                              label: 'Crizal Sun UV'
                          }
                      ]
                    }
                ]
            },
            {
                value: 'eyezen',
                label: 'Eyezen',
                products: [
                    {
                        value: 'crizal',
                        label: 'Crizal',
                        subProducts: [
                            {
                                value: 'crizal_easy_uv',
                                label: 'Crizal Easy UV',
                            }
                        ],
                        packageOffersDowngrade: [
                          {
                              value: 'crizal_sun_xprotect',
                              label: 'Crizal Sun Xprotect',
                          },
                          {
                              value: '',
                              label: 'Optifog'
                          },
                          {
                              value: '',
                              label: 'Optifog'
                          }
                      ]
                    }
                ]
            }
        ]
    }
];

export default lensConsultationData;