import React, { useEffect } from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    type: 'right' | 'left' | 'up' | 'down',
    size?: string,
    color?: string,
    thickness?: string,
    className?: string,
    disabled?: boolean,
    onClick?: () => void
}

const Arrow : React.FC<Props>  = ({
    type,
    color,
    size = '.5rem',
    thickness = '.1rem',
    className,
    disabled = false,
    onClick
}) =>  {
    // VARIABLES
    const [B] = useBEM('arrow');
    const stlye = {
        borderWidth: `0 ${thickness} ${thickness} 0`,
        borderColor: color,
        width: size,
        height: size
        // borderWidth: `0 ${size} ${size} 0`,
        // padding: size
    }

    // HOOKS
    useEffect(() => {

    }, [])

    // METHODS
    const onclick = () => {
        if(onClick) onClick();
    }

    const classModifers = () => {
        const modifiers: string[] = [type];
        if(disabled)modifiers.push('disabled');

        return modifiers;
    }

    return (
        // <div className={`arrow arrow--${type} ${className || ''}`} style={stlye} onClick={ onclick }/>
        <div className={B(classModifers()) + ` ${className || ''}`} style={stlye} onClick={ onclick }/>
    )
}

export default Arrow;