import { transform } from "html2canvas/dist/types/css/property-descriptors/transform";
import React, { useState, useEffect } from "react";

type carouselStyleType = {
    listen: () => void, 
    stop: () => void, 
}

const useCarouselStyle  = (distance:number, directionDist:number)  =>  {
    const [x, setX] = useState<number>(0)
    const [z, setZ] = useState<number>(0)
    const [opacity, setOpacity] = useState<number>(0)
    const direction = directionDist > 0 ? 'right' : 'left'
    const maxDistance:number = 200;
    const perc = 8
    const pixel = 2.8571
    const opac = 25
    
    useEffect(() => {
        if(distance >= 200){
            setX(maxDistance/perc)
            setZ(maxDistance/pixel)
            setOpacity(maxDistance/opac)
        }else{
            setX(distance/perc)
            setZ(distance/pixel)
            setOpacity(distance/opac)
        }
    }, [distance])

    const left =  direction === 'left' ? {
        opacity:`0.${10 - opacity}`
    }
    :
    {
        transform:`translate3d(${25 - x}%, 0, ${-70 + z}px)`,
        opacity:`1`
    }


    const right = direction === 'right' ? 
    {
        opacity:`0.${10 - opacity}`,
        transform:`translate3d(${25 - x}%, 0, ${-70 - z}px)`,
    }
    :
    {
        transform:`translate3d(${25 - x}%, 0, ${-70 + z}px)`,
        opacity:`1`
    }

    const active = direction === 'right' ? 
    {
        transform:`translate3d(${x}%, 0, ${-z}px)`,
        opacity:`0.${10 - opacity}`
    }
    :
    {
        transform:`translate3d(${-x}%, 0, ${-z}px)`,
        opacity:`0.${10 - opacity}`
    }

    //functions
    const styleSettings = (position:string, dragging:boolean) => {
        if(position === 'active' && dragging){
            return active
        }
        if(position === 'left' && dragging){
            return left
        }
        if(position === 'right' && dragging){
            return right
        }
    }


    return {
        styleSettings
    }

}

export default useCarouselStyle;