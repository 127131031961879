import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';
import EnvironmentType from '../../../types/environment.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Arrow from '../../ui/Arrow';

interface Props {
    steps: any[],
    environment: EnvironmentType | undefined
}

const EducationSteps : React.FC<Props>  = ({
    steps = [],
    environment
}) =>  {

    // VARIABLES
    const [B, E] = useBEM('education-steps');
    const [step, setStep] = useState(0);
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const isFirstStep = () => {
        return step === 0;
    }

    const isLastStep = () => {
        return (steps.length === (step + 1));
    }

    const nextStep = () => {
        if (isLastStep())return;
        setStep(step + 1);
    }

    const prevStep = () => {
        if(isFirstStep())return;
        setStep(step - 1);
    }

    
    return (
        <div className={B(environment)}>            
            <div className={E('arrows')}>

                <button onClick={prevStep}>
                    <Arrow type="left" onClick={prevStep} disabled={isFirstStep()}/>
                </button>
                <button onClick={nextStep}>
                    <Arrow type="right" disabled={isLastStep()}/>
                </button>

            </div>

            <label className={E('step')}>Step {step + 1}:</label>

            <p className={E('instruction')}>{t(steps[step].instruction)}</p>

            <div className={E('guide')}>
                {steps[step].guide && steps[step].guide}
            </div>
        </div>
    )
}

export default EducationSteps;