interface iColorModification {
    [key: string]: {
        [key: string]: {
            id: string,
            label: string,
            value: string
        }[]
    }
}

// LIST OF ALL COLORS FOR LENS_COLOR_MODIFICATIONS
// is in product-config-constant.ts line 2389
export const LENS_COLOR_MODIFICATIONS: iColorModification = {
    "co": {
        "transitions_signature_gen_8": [
            {
                "id": "lensTransitionGrey",
                "label": "lens_propo_colors_grey",
                "value": "#B2B2B6"
            },
            {
                "id": "lensTransitionBrown",
                "label": "lens_propo_colors_brown",
                "value": "#9E5425"
            },
            {
                "id": "lensTransitionGreen",
                "label": "lens_propo_colors_green",
                "value": "#3F9A49"
            },
            {
                "id": "lensTransitionSapphire",
                "label": "lens_propo_colors_sapphire",
                "value": "#346297"
            },
            {
                "id": "lensTransitionAmethyst",
                "label": "lens_propo_colors_amethyst",
                "value": "#4F3281"
            }
        ],
        "transitions_xtractive_new_gen": [
            {
                "id": "lensTransitionGrey",
                "label": "lens_propo_colors_grey",
                "value": "#B2B2B6"
            },
        ],
        "transitions_xtractive_polarised": [
            {
                "id": "lensTransitionGrey",
                "label": "lens_propo_colors_grey",
                "value": "#B2B2B6"
            },
        ],
        "transitions_style_mirrors": [
            {
                "id": "lensMirrorSilver",
                "label": "lens_propo_colors_silver",
                "value": "#8b8d92"
            },
            {
                "id": "lensMirrorBronze",
                "label": "Bronze",
                "value": "#c66a29"
            },
            {
                "id": "lensMirrorOceanBlue",
                "label": "lens_propo_colors_ocean_blue",
                "value": "#243459"
            },
            {
                "id": "lensMirrorOrange",
                "label": "lens_propo_colors_orange",
                "value": "#7b1d16"
            }
        ],
        "xperio_mirrors": [
            {
                "id": "lensMirrorSilver",
                "label": "lens_propo_colors_silver",
                "value": "#8b8d92"
            },
            {
                "id": "lensMirrorBronze",
                "label": "Bronze",
                "value": "#c66a29"
            },
            {
                "id": "lensMirrorOceanBlue",
                "label": "lens_propo_colors_ocean_blue",
                "value": "#243459"
            },
            {
                "id": "lensMirrorOrange",
                "label": "lens_propo_colors_orange",
                "value": "#7b1d16"
            }
        ]
    },
    "gr": {
        "transitions_xtractive_polarised": [
            {
                "id": "lensTransitionGrey",
                "label": "lens_propo_colors_grey",
                "value": "#B2B2B6"
            },
        ],
        "transitions_xtractive_new_generation": [
            {
                "id": "lensTransitionGrey",
                "label": "lens_propo_colors_grey",
                "value": "#B2B2B6"
            },
            {
                "id": "lensTransitionBrown",
                "label": "lens_propo_colors_brown",
                "value": "#9E5425"
            },
            {
                "id": "lensTransitionGreen",
                "label": "lens_propo_colors_green",
                "value": "#3F9A49"
            },
            {
                "id": "lensMirrorSilverShadow",
                "label": "lens_propo_colors_silver_shadow",
                "value": "#4e4340"
            },
            {
                "id": "lensMirrorGoldPink",
                "label": "lens_propo_colors_gold_pink",
                "value": "#b76e79"
            },
        ],
        "xperio_polarised": [
            {
                "id": "lensPolarizedGrey",
                "label": "lens_propo_colors_grey",
                "value": "#808080"
            },
            {
                "id": "lensPolarizedBrown",
                "label": "lens_propo_colors_brown",
                "value": "#9C703B"
            },
            {
                "id": "lensPolarizedGreyGreen",
                "label": "lens_propo_colors_grey_green",
                "value": "#656255"
            }
        ],
        "xperio_mirrors": [
            {
                "id": "lensMirrorGoldPink",
                "label": "lens_propo_colors_gold_pink",
                "value": "#924a35"
            },
            {
                "id": "lensMirrorOceanBlue",
                "label": "lens_propo_colors_ocean_blue",
                "value": "#243459"
            },
            {
                "id": "lensMirrorSilver",
                "label": "lens_propo_colors_silver",
                "value": "#8b8d92"
            },
            // GOLD - CANT FOUND
            // SILVER LILAC - CANT FOUND
        ]
    }
}

// LIST OF ALL COLORS FOR DEMO_COLOR_MODIFICATIONS
// Every product has their own specific colors
export const DEMO_COLORS_MODIFICATIONS: iColorModification = { // FOR ProductDemoSelection.tsx
    "co": {
        "transitions_style_mirrors": [
            // {
            //     id: 'transitions_style_mirrors',
            //     label: 'lens_propo_colors_bronze_shadow',
            //     value: 'lensMirrorBronzeShadow'
            // },
            {
                id: 'standard9',
                label: 'lens_propo_colors_ocean_blue',
                value: 'lensMirrorOceanBlue'
            },
            {
                id: 'standard',
                label: 'lens_propo_colors_bronze',
                value: 'lensMirrorBronze'
            },
            {
                id: 'standard14',
                label: 'lens_propo_colors_silver',
                value: 'lensMirrorSilver'
            },
            {
                id: 'standard10',
                label: 'lens_propo_colors_orange',
                value: 'lensMirrorOrange'
            },
        ],
        "xperio_mirrors": [
            // {
            //     id: 'transitions_style_mirrors',
            //     label: 'lens_propo_colors_bronze_shadow',
            //     value: 'lensMirrorBronzeShadow'
            // },
            {
                id: 'standard9',
                label: 'lens_propo_colors_ocean_blue',
                value: 'lensMirrorOceanBlue'
            },
            {
                id: 'standard',
                label: 'lens_propo_colors_bronze',
                value: 'lensMirrorBronze'
            },
            {
                id: 'standard14',
                label: 'lens_propo_colors_silver',
                value: 'lensMirrorSilver'
            },
            {
                id: 'standard10',
                label: 'lens_propo_colors_orange',
                value: 'lensMirrorOrange'
            },
        ]
    },
    "gr": {
        "xperio_mirrors": [
            {
                id: 'standard9',
                label: 'lens_propo_colors_ocean_blue',
                value: 'lensMirrorOceanBlue'
            },
            {
                id: 'standard14',
                label: 'lens_propo_colors_silver',
                value: 'lensMirrorSilver'
            },
            {
                id: "standard3",
                label: "lens_propo_colors_gold_pink",
                value: "lensMirrorGoldPink",
            }
        ],
        "xperio_polarised": [
            {
                id: "standard4",
                label: "lens_propo_colors_grey",
                value: "lensPolarizedGrey"
            },
            {
                id: "standard6",
                label: "lens_propo_colors_brown",
                value: "lensPolarizedBrown"
            },
            {
                id: "standard5",
                label: "lens_propo_colors_grey_green",
                value: "lensPolarizedGreyGreen"
            }
        ]
    }
}
