import axios from 'axios';
import ENDPOINTS from "../../constants/endpoints";
import iProductsStore from "../../store/interface/products-store.interface";
import LensFamilyType from "../../types/lens-family.type";
import { BRAND_CONFIG, COLOR_CONFIG, PRODUCT_CONFIG } from "../../constants/product-config.constants";
import { getProducts } from "../../store/slices/products.slice";
import { useSelector } from "react-redux";
import { fetchProductDetailsViaId, fetchSubProductDetailsViaSubProductId } from "../misc";
import iSubProduct from "../../interface/sub-product.inteface";
import useLensConsultation from "../../pages/lens-consultation/useLensConsultation";

type hookType = {
    getProductData: (params: {
        onSuccess?: (res: any) => void,
        onError?: (err:any) => void,
    }) => void,
    getPriceCombination: (params: {
        onSuccess?: (res: any) => void,
        onError?: (err:any) => void,
    }) => void,

    getProductData2: () => Promise<{
        status: 'success' | 'error',
        responseData: any
    }>,
    getPriceCombination2: () => Promise<{
        status: 'success' | 'error',
        responseData: any
    }>,
    getCurrency: () => Promise<{
        status: 'success' | 'error',
        responseData: any
    }>,
    mapProducts: (res: any, isInAppRefresh: boolean) => { id: string; label: string; brands: any[]; }[],
    getProductLabelById: (filter: {
        familyType: LensFamilyType,  
        brandId?: string,
        productId: string,
        subProductId?: string,
    }) => string,
    getColorIdPrefix: (productId: string) => string
}

const useProducts  = (app: iProductsStore): hookType =>  {
    const { temporaryProductList } = useSelector(getProducts);
    const { getProductById } = useLensConsultation()

    const validateProductData = (data:any) => {
        return data.hasOwnProperty('products') && Array.isArray(data.products)
    }

    const validateProductPricingData = (data:any) => {
        return Array.isArray(data)
    }

    const validateCurrencyData = (data:any) => {
        return data.hasOwnProperty('currency')
    }

    const getProductData = (params: {
        onSuccess?: (res: any) => void,
        onError?: (err:any) => void,
    }) => {
        const {
            onSuccess,
            onError
        } = params;

        axios.get(ENDPOINTS.products, { headers: {Authorization : true}})
        .then((res) => onSuccess && validateProductData(res.data) && onSuccess(res.data))
        .catch((err) => onError && onError(err))
    }

    const getCurrency = (): Promise<{
        status: 'success' | 'error',
        responseData: any
    }> => {
        
        const promise = new Promise<{
            status: 'success' | 'error',
            responseData: any
        }>((res, rej) => {
            axios.get(ENDPOINTS.account, { headers: {
                Authorization : true,
                background: true
            }})
            .then((resp) => {
                if(validateCurrencyData(resp.data)){
                   
                    res({
                        status: 'success',
                        responseData: resp.data.currency.symbol
                    })
                } else {
                    rej({
                        status: 'error',
                        responseData: resp
                    })
                }
            })
            .catch((err) => rej({
                status: 'error',
                responseData: err
            }))
        });
        return promise;
    }

    const getProductData2 = (): Promise<{
        status: 'success' | 'error',
        responseData: any
    }> => {
        
        const promise = new Promise<{
            status: 'success' | 'error',
            responseData: any
        }>((res, rej) => {
            axios.get(ENDPOINTS.products, { headers: {
                Authorization : true,
                background: true
            }})
            .then((resp) => {

                if(validateProductData(resp.data)){
                    res({
                        status: 'success',
                        responseData: resp
                    })
                } else {
                    rej({
                        status: 'error',
                        responseData: resp
                    })
                }
            })
            .catch((err) => rej({
                status: 'error',
                responseData: err
            }))
        });
        return promise;
    }

    const getPriceCombination = (params: {
        onSuccess?: (res: any) => void,
        onError?: (err:any) => void,
    }) => {
        const {
            onSuccess,
            onError
        } = params;

        axios.get(ENDPOINTS.productCombi, { headers: {Authorization : true}})
        .then((res) => onSuccess && validateProductPricingData(res.data) && onSuccess(res.data))
        .catch((err) => onError && onError(err))
    }

    const getPriceCombination2 = (): Promise<{
        status: 'success' | 'error',
        responseData: any
    }> => {
        
        const promise = new Promise<{
            status: 'success' | 'error',
            responseData: any
        }>((res, rej) => {
            axios.get(ENDPOINTS.productCombi, { headers: {
                Authorization : true,
                background: true
            }})
            .then((resp) => {

                if(validateProductPricingData(resp.data)){
                    res({
                        status: 'success',
                        responseData: resp
                    })
                } else {
                    rej({
                        status: 'error',
                        responseData: resp
                    })
                }
            })
            .catch((err) => rej({
                status: 'error',
                responseData: err
            }))
        });
        return promise;
    }

    const mapProducts = (res: any, isInAppRefresh: boolean) => {
        const correct_family = res.filter((product: any)=>{ return product.familyId === LensFamilyType.CORRECT }) 
        const protect_family = res.filter((product: any)=>{ return product.familyId === LensFamilyType.PROTECT }) 
        const enhance_family = res.filter((product: any)=>{ return product.familyId === LensFamilyType.ENHANCE }) 

        const getSubProducts = (subProducts: any[]) => {
            let subs:any[] = []
        
            if(Array.isArray(subProducts) && subProducts.length !== 0){
                subProducts.forEach((sp:any)=>{
                    if(Array.isArray(sp.index)){
                        sp.index.forEach((ind:string, i:number)=>{
                            subs.push({
                                id: `${i}-${sp.id}`,
                                code: sp.code,
                                label: sp.label,
                                index: parseFloat(ind),
                                enabled: true,
                                price: isInAppRefresh ? fetchSubProductDetailsViaSubProductId(`${i}-${sp.id}`, temporaryProductList).price : 0
                                // price: 0
                            })
                        })
                    }
                })
            }
        
            return subs
        }
        
        const getBrandLabel = (famType: any) => {
            switch (famType) {
                case LensFamilyType.CORRECT: return 'lens_consultation_lccorrect'
                case LensFamilyType.PROTECT: return 'lens_consultation_lc_protect_'
                case LensFamilyType.ENHANCE: return 'lens_consultation_lc_enhance'
                default: return ''
            }
        }
        
        const getStaticValues = (id:any, config: Object) => {
            for (const [key, val] of Object.entries(config)) {
                if (key === id) {
                  return val
                }
            }
        }
        
        const filterSelectedColor = (colors: string[], productId:string) => {
            const mappedColors = colors.map((color:string) => {
                const key = getColorIdFromType(color, productId)
        
                return getStaticValues(key, COLOR_CONFIG)
            })
        
            return mappedColors
        }

        const getColorIdFromType = (color: string, productId:string) => {
            return getColorIdPrefix(productId)+color;
        }
        
        const groupArrayOfObjects = (list:any, key:any) => {
            return list.reduce((rv:any, x:any) => {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        }

        const assignProductData = (data: any, famType: string) => {
            const getProducts = ((products: any) => {
        
                const productPerBrand = products.map((p:any) => {
                    return {
                        ...getStaticValues(p.productId, PRODUCT_CONFIG),
                        ...p.familyId === LensFamilyType.CORRECT && p.subProducts.length > 0 && { subProducts: getSubProducts(p.subProducts) } ,
                        ...p.familyId !== LensFamilyType.CORRECT && { price: isInAppRefresh ? fetchProductDetailsViaId(p.productId, temporaryProductList).price : 0 },
                        // ...p.familyId !== LensFamilyType.CORRECT && { price: 0 },

                        ...p.lensDesign && { lensDesign: p.lensDesign },
                        //isKids = depricated
                        ...p.isKids === 1 && { isKids: p.isKids },
                        ...p.familyId === LensFamilyType.PROTECT && p.color && {availableColors: transformColors(p.color, p.productId)},
                        ...Array.isArray(p.color) && p.color.length > 0 && { color: filterSelectedColor(p.color, p.productId) },
        
                        id: p.productId,
                        label: p.productLabel,
                        enabled: true,
                        ageCategoryId: p.ageCategoryId,
                    }
                });
        
                return productPerBrand
            })
        
            const transformColors = (colors:string | string[], productId: string) => {
                if(Array.isArray(colors))return []
                const availableColors = colors.replaceAll(' ', '').split(',').map((color) => {
                    return  color.charAt(0).toUpperCase() + color.slice(1);
                   })
                return filterSelectedColor(availableColors,productId)
            }

            const getBrands = (() => {
                const brandGroup = groupArrayOfObjects(data, "brandId")
                // console.log('brandGroup',brandGroup)
                
                const brands = new Array()
        
                for (var key in brandGroup) {
                    brands.push({
                        ...getStaticValues(key, BRAND_CONFIG),
                        id: key,
                        label: brandGroup[key][0].brandLabel,
                        products: getProducts(brandGroup[key])
                    })
                }
        
                return brands
            })
        
            return {
                id: famType,
                label: getBrandLabel(famType),
                brands: getBrands()
            }
        }
    
        return [
            assignProductData(correct_family, LensFamilyType.CORRECT),
            assignProductData(protect_family, LensFamilyType.PROTECT),
            assignProductData(enhance_family, LensFamilyType.ENHANCE),
        ]
    }

    const getProductLabelById = (filter: {
        familyType: LensFamilyType,  
        brandId?: string,
        productId: string,
        subProductId?: string,
    }) => {
        const {productId, familyType, brandId, subProductId} = filter;
        const product = getProductById({
            id: productId,
            list: temporaryProductList,
            family: familyType,
            brandId,
        });
        if(!subProductId)return product?.label || productId;        
        const subProduct = (product?.subProducts || []).filter( (x: iSubProduct ) => x.code === subProductId)[0];
        return subProduct?.label || subProductId;
    }

    const getColorIdPrefix = (productId:string) => {
        switch (productId) {
            case 'xperio_mirrors': return `lensMirror`
            case 'xperio_polarised': return `lensPolarized`
            case 'xperio_polarised_gradient': return `lensPolarized`
            case 'xperio_tinted': return `lensTinted`
            case 'xperio_polarised_mirrors': return `lensMirror`
            case 'xperio_tinted_mirrors': return `lensMirror`
            case 'transitions_signature_gen_8': return `lensTransition`
            case 'transitions_signature_gen_8_iconic': return `lensTransition`
            case 'transitions_signature_gen_8_style': return `lensTransition`
            case 'transitions_signature_gen_8_classic': return `lensTransition`
            case 'transitions_classic': return `lensTransition`
            case 'transitions_xtractive': return `lensTransition`
            case 'transitions_xtractive_mirror': return `lensMirror`
            case 'transitions_style_mirrors': return `lensMirror`
            case 'transitions_style_colors': return `lensTransition`
            default: return ''
        }
    }

    return {
        getProductData,
        getPriceCombination,
        getProductData2,
        getPriceCombination2,
        mapProducts,
        getCurrency,
        getProductLabelById,
        getColorIdPrefix
    }
}

export default useProducts;