import useBEM from "../../utils/hooks/useBEM"

interface Props{
    className?:string
    flexColumn?:boolean
}


const Dialogue:React.FC<Props> = ({children, className, flexColumn = false}) => {
    const [B, E] = useBEM('dialogue')
  return (
    <div className={`${B()} ${className || ''} ${flexColumn ? 'flexColumn' : ''}`}>
        {children}
    </div>
  )
}

export default Dialogue