import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import InputText from '../../../components/ui/InputText';
import iMeasurementDataThree from '../../../interface/measurement-three-data.inteface';
import { getApp } from '../../../store/slices/app.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import ExperienceType from '../../../types/experience.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import { validateMeasures } from '../../../utils/MeasuresValidation';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import Utils from '../../../utils/Utils';
import { getNeedExplorer } from '../../../store/slices/need-explorer.slice';

interface Props {
    onSave: (data: iMeasurementDataThree) => void,
    data?: iMeasurementDataThree,
    isKids: boolean
}

const MeasureStepThree : React.FC<Props>  = ({
    onSave,
    data,
    isKids
}) =>  {
    const {activeDataIndex, isIvsGenerated} = useSelector(getMeasure)
    // VARIABLES
    
        const defaultData = {
        pantoscopicAngle: {
            value: 0,
            error: false,
            errorMsg: ''
        },
        wrapAngle: {
            value: 0,
            error: false,
            errorMsg: ''
        },
        vertexDistance: {
            value:0,
            ivsValue: {
                left:0,
                right:0
            },
            error: false,
            errorMsg: ''
        },
        readingDistance: {
            value: 0,
            error: false,
            errorMsg: ''
        }
    }


    const [B, E] = useBEM('measures-step');
    const [localData, setLocalData] = useState<iMeasurementDataThree>(defaultData)
    const { t } =  useTranslation(useSelector(getApp));
    // HOOKS
    const [left, setLeft] = useState(0)
    const [right, setRight] = useState(0)
    const ga = useGoogleAnalytics()
    const { getDateTimeDifference } = Utils();
    const currentNeedExplorerData = useSelector(getNeedExplorer);

    useEffect(() => {
        setLocalData(data || defaultData)
    }, [data])

    useEffect(()=>{
        if(activeDataIndex === 0 && isIvsGenerated){
            setLeft(localData.vertexDistance.ivsValue.left)
            setRight(localData.vertexDistance.ivsValue.right)
        }

    })
    // METHODS
    const validateCurrentState = () => {
        return localData.pantoscopicAngle.error === true ||
        localData.wrapAngle.error === true ||
        localData.vertexDistance.error === true ||
        localData.readingDistance.error === true
    }

    const onSaveHandler = () => {
        onSave(localData);

        ga.event({
            category: 'Measurement',
            action: 'Measurement - Save & Next',
            variable_id: 'measurement_completed',
        });

    }
    
    const inputChangeHandler = (e: any, key: string, handedness:string) => {
        const value = e.target.value
        const result = validateMeasures(3, value, key);
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
        if(value === '' || floatRegExp.test(value)){
            setLocalData((prev: any) => {
                if(key === "vertexDistance" && handedness === 'left'){
                    return {...prev,
                    [key]:{
                        value:0,
                        ivsValue:{
                            right:right,
                            left: value && handedness === 'left'? Number(value): 0
                        },
                        error: result?.isError,
                        errorMsg: result?.errorMsg
                    }}
                }
                if(key === "vertexDistance" && handedness === 'right'){
                    return {...prev,
                    [key]:{
                        value:0,
                        ivsValue:{
                            left:left,
                            right: value && handedness === 'right'? Number(value): 0
                        },
                        error: result?.isError,
                        errorMsg: result?.errorMsg
                    }}
                }

                return {
                    ...prev,
                    [key]: {
                        value: value ? Number(value) : 0,
                        error: result?.isError,
                        errorMsg: result?.errorMsg
                    }
                }
            })
        }

    }

    const getNextButtonIcon = () => {
        if(isKids)return "right-arrow-circle";
        return <CircleArrow type='right' background='#eee'/>
    }

    return (
        <div className={B()}>
            <div className={E('form', 'three')}>

                <label>{t('measures_mes__3_pantoscopic_angle')}</label>

                <div className="container-degree" data-placeholder={"°"}>
                    <InputText inputType="number" type="light" value={String(localData?.pantoscopicAngle.value)} onChange={(v) => inputChangeHandler(v, 'pantoscopicAngle','')}/>
                    {localData?.pantoscopicAngle.error && <span>{t(localData?.pantoscopicAngle.errorMsg)}</span>}
                </div>

                <label>{t('measures_mes__3_wrap_angle')}</label>

                <div className="container-degree" data-placeholder={"°"}>
                    <InputText inputType="number" type="light" value={String(localData?.wrapAngle.value)} onChange={(v) => inputChangeHandler(v, 'wrapAngle','')}/>
                    {localData?.wrapAngle.error && <span>{t(localData?.wrapAngle.errorMsg)}</span>}
                </div>

                <label>{t('measures_mes__3_vertex_distance')}</label>

                <div className="container-mm container-vertex-distance" data-placeholder={"mm"}>

                    { activeDataIndex === 0 && isIvsGenerated ?
                    <div className='ivs-generated-input'>
                    <div>Left:</div>
                    <input type="number" className='border-right' value={String(localData?.vertexDistance.ivsValue.left)} onChange={(v) => inputChangeHandler(v, 'vertexDistance','left')}/>
                    <div>Right:</div>
                    <input type="number" value={String(localData?.vertexDistance.ivsValue.right)} onChange={(v) => inputChangeHandler(v, 'vertexDistance','right')}/>
                    </div>
                     :
                    <div>
                    <InputText inputType="number" type="light" value={String(localData?.vertexDistance.value)} onChange={(v) => inputChangeHandler(v, 'vertexDistance','')}/>                
                    </div>
                }
                    {localData?.vertexDistance.error && <span>{t(localData?.vertexDistance.errorMsg)}</span>}
                </div>

                <label>{t('measures_mes__3_reading_distance')}</label>

                <div className="container-mm" data-placeholder={"cm"}>
                    <InputText inputType="number" type="light" value={String(localData?.readingDistance.value)} onChange={(v) => inputChangeHandler(v, 'readingDistance','')}/>
                    {localData?.readingDistance.error && <span>{t(localData?.readingDistance.errorMsg)}</span>}
                </div>

            </div>

            <Button
                text={t('measures_mes__3_save_&_next')}
                icon={getNextButtonIcon()}
                className={E('button')}
                click={onSaveHandler}
                disabled={validateCurrentState()}
            />
        </div>
    )
}

export default MeasureStepThree;
