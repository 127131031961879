import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import useBEM from '../../utils/hooks/useBEM';
import { useSelector } from 'react-redux';
import useTranslation from '../../utils/hooks/useTranslation';
import { getApp } from '../../store/slices/app.slice';

interface Props {

}

const LegalNotices : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('legal-notices');
    const { t } = useTranslation(useSelector(getApp));    

    // HOOKS

    // METHODS
    const replaceString = (val: string) =>{
        // const repl = val.replace(/\r/g, '<br>')
        return val.replace(/\n/g, '<br>')
    }

    const sections = [
        {
            header:t('legal_notice_ln_01_demonstrations_title'),
            content: replaceString(t('legal_notice_ln_01_demonstrations'))
        },
        {
            header:t('legal_notice_ln_under_this_privacy_notice_data_title'),
            content: replaceString(t('legal_notice_ln_under_this_privacy_notice_data')),
        },
        {
            header:t('legal_notice_terms_of_use_title'),
            content:replaceString(t('legal_notice_terms_of_use_content')),
            content1: replaceString(t('legal_notice_agree_terms')),
            content2: replaceString(t('legal_notice_agree_terms_content')),
        },
        {
            header:t('legal_notice_data_content_title'),
            content:replaceString(t('legal_notice_data_content_content')),
        },
        {
            header:t('legal_notice_privacy_title'),
            content:replaceString(t('legal_notice_privacy_content')),
        },
        {
            header:t('legal_notice_prohibited_title'),
            content: replaceString(t('legal_notice_prohibited_content')),
        },
        {
            header:t('legal_notice_ownership_title'),
            content:replaceString(t('legal_notice_ownership_content')),
        },
        {
            header:t('legal_notice_conduct_title'),
            content:replaceString(t('legal_notice_conduct_content')),
        },
        {
            header:t('legal_notice_third_party_title'),
            content:replaceString(t('legal_notice_third_party_content')),
        },
        {
            header:t('legal_notice_disclaimer_title'),
            content:replaceString(t('legal_notice_disclaimer_content')),
        },
        {
            header:t('legal_notice_limitation_title'),
            content:replaceString(t('legal_notice_limitation_content')),
        },
        {
            header:t('legal_notice_provision_title'),
            content:replaceString(t('legal_notice_provision_content')),
        },
        // {
        //     header: "Data",
        //     content: "<p>By choosing to use the “Essilor Companion” Progressive web app you agree to accept the terms and conditions of this Legal Notice governing use of the solution.</p><p>Why does Essilor use your data? To give you a customized experience of the solution, understand how users use Essilor Companion and improve our features.</p><p>Here is a list of data Essilor Companion collects, stores and uses:<span>-Monthly usage time: hours and minutes of the licence</span><span>-Demonstration click count</span><span>-Features click count including “eMat” “Lens Proposal Builder” and “What’s New”</span></p><p>The data recipients are Essilor and selected third parties</p>"
        // },
        // {
        //     header: "Your rights",
        //     content: "<p>Following legislation your rights includes:<span>-The right to access, rectify or erase this data. Kindly ask your Essilor representative to follow your request.</span><span>-The right to stop Essilor Companion collecting data by turning off internet connection before usage</span><p>"
        // }
    ]
    const renderSections = () => {
        const sections_jsx = sections.map((section, j)=>{
            return (
                <div className={E('section')} key={j}>
                    <div className={E('bullet')}>{`0${j+1}`}</div>

                    <div className={E('content')}>
                        <h2>{section.header}</h2>
                        <div className={E('content-paragraph')}>
                            {ReactHtmlParser(section.content)}
                        </div>
                        {
                            section.content1 && (
                                <div className={E('content-paragraph')}>
                                    <br></br>
                                    <strong><u>{ReactHtmlParser(section.content1)}</u></strong>
                                    <br></br><br></br>
                                    {ReactHtmlParser(section.content2)}
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        })
        
        return sections_jsx
    }

    return (
        <div className={B()}>
            {renderSections()}
        </div>
    )
}

export default LegalNotices;