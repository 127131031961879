import iMenu from "../interface/menu.interface";
import routes from "./routes.constant";

const menus: iMenu[] = [
    {
        id:'tutorial',
        label: 'my_practice_my_tutorial',
        icon: 'question-mark'
    },
    {
        id:'education',
        label: 'my_practice_my_educ',
        icon: 'education'
    },
    {
        id:'my_companion',
        label: 'my_practice_my_my_compa',
        icon: 'grid',
        route: routes.myCompanion
    },
    {
        id:'practice_id',
        label: 'my_practice_my_practice_id',
        icon: 'practice',
        route: routes.practiceID
    },
    {
        id:'settings',
        label: 'my_practice_my_settings',
        icon: 'settings',
        activeIcon: 'settings-white',
        submenu: [
            // {
            //     id:'settings_pms',
            //     label: 'PMS',
            //     icon: 'monitor'
            // },
            {
                id:'settings_language',
                label: 'my_practice_my_language',
                icon: 'globe',
                // route: routes.home
            },
            {
                id:'settings_legan-notices',
                label: 'legal_notices_ln_legal_notices',
                icon: 'info',
                route: routes.legalNotices
            }
        ]
    }
];

export default menus;