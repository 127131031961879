import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DiscoveryView from '../../types/discovery-view.type';
import LensFamilyType from '../../types/lens-family.type';
import iDiscoveryStore from '../interface/discovery.store.interface';
import iRootState from '../interface/root-state.interface';

const initialState: iDiscoveryStore  = {
    view: DiscoveryView.INTRO,
    selectedLens: undefined
}

const discoverySlice = createSlice({
    name: 'discovery',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<DiscoveryView>) => ({...state, view: action.payload }),
        selectLens: (state, action: PayloadAction<LensFamilyType | undefined>) => ({...state, selectedLens: action.payload }),
        reset: (state) => ({...state, ...initialState})
    }
});

export const getDiscovery = (state:iRootState) => state.discovery;
export default discoverySlice;