import React, { useState } from 'react';
import iOption from '../../interface/option.interface';
import useBEM from '../../utils/hooks/useBEM';
import Arrow from '../ui/Arrow';

interface Props {
    options: iOption[],
    onSelect?: (option: string) => void,
    value?: string,
    classExtend?:string,
    // defaultValue?: iOption
}

const Dropdown : React.FC<Props>  = ({
    options = [],
    onSelect,
    value,
    classExtend,
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('dropdown');

    // VARIABLES
    const [showOptions, setShowOptions ] = useState(false);

    // METHODS
    const toggleOptions = (show: boolean) => {   
        const delay = show ? 0 : 200;
        setTimeout(() => {
            setShowOptions(show)
        }, delay);
    }

    const selectOption = (option: iOption) => {
        toggleOptions(false)
        if(onSelect)onSelect(option.value);
    }

    const focusHandler = () => {
        toggleOptions(!showOptions);        
    }

    const blurHandler = () => {
        toggleOptions(false)
    }

    const getCurrentValue = () => {
        const found = options.filter( x => x.value === value );
        if(found.length === 0)return null;
        return found[0];
    }

    const selectedOptionClass = (option: iOption) => {
        if(option.value === getCurrentValue()?.value) {
            return 'selected';
        }
        return '';
    }

    return (
        <div className={B(classExtend)} onClick={focusHandler}>
            
            <button 
                className={E('value', [value ? 'with-data' : ''])}>
                { getCurrentValue()?.label }
            </button>            
            <button 
                onBlur={blurHandler}
                onClick={focusHandler}
                className={E('arrow')}>                
                
                <Arrow type={showOptions ? 'up' : 'down' } color="#fff"/>

            </button>

            {
                showOptions && 
                <ul className={E('options')}>
                    {
                        options && options.map( (opt, index) => (
                            <li onClick={() => selectOption(opt)} key={index} className={selectedOptionClass(opt)}>
                                {opt.label}
                            </li>
                        ))
                    }
                </ul>
            }

        </div>
    )
}

export default Dropdown;