import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iPushNotificationStore from '../interface/push-notification-store.interface';
import iRootState from '../interface/root-state.interface';


const initialState: iPushNotificationStore = {
    inAppHasNewUpdate: false,
    eMatHasNewUpdate: 0,
    whatsNewHasNewUpdate: 0,
    tutorialHasNewUpdate: 0,
    countryCode: '',
    notificationEvent: null,
}

const pushNotificationSlice = createSlice({
    name: 'pushNotification',
    initialState,
    reducers: {
        setInAppNotification: (state, action: PayloadAction<boolean>) => ({
            ...state, 
            inAppHasNewUpdate: action.payload
        }),
        setEmatNotification: (state, action: PayloadAction<number>) => ({
            ...state, 
            eMatHasNewUpdate: action.payload
        }),
        setWhatsNewNotification: (state, action: PayloadAction<number>) => ({
            ...state, 
            whatsNewHasNewUpdate: action.payload
        }),
        setTutorialNotification: (state, action: PayloadAction<number>) => ({
            ...state, 
            tutorialHasNewUpdate: action.payload
        }),
        setCountryCode: (state, action: PayloadAction<string>) => ({
            ...state, 
            countryCode: action.payload
        }),
        setNotificationEvent: (state, action: PayloadAction<any>) => ({
            ...state, 
            notificationEvent: action.payload
        }),
        setProductTimestamp: (state, action: PayloadAction<string>) => ({
            ...state, 
            notificationEvent: {
                ...state.notificationEvent,
                product: action.payload
            }
        }),
        setTranslationTimestamp: (state, action: PayloadAction<string>) => ({
            ...state, 
            notificationEvent: {
                ...state.notificationEvent,
                translation: action.payload
            }
        }),
        setPricingTimestamp: (state, action: PayloadAction<string>) => ({
            ...state, 
            notificationEvent: {
                ...state.notificationEvent,
                pricing: action.payload
            }
        }),
        setEmatCount: (state, action: PayloadAction<number>) => ({
            ...state, 
            notificationEvent: {
                ...state.notificationEvent,
                emat: action.payload
            }
        }),
        setWhatsNewCount: (state, action: PayloadAction<number>) => ({
            ...state, 
            notificationEvent: {
                ...state.notificationEvent,
                whats_new: action.payload
            }
        }),
        setTutorialCount: (state, action: PayloadAction<number>) => ({
            ...state, 
            notificationEvent: {
                ...state.notificationEvent,
                tutorial: action.payload
            }
        }),
    }
});

export const getPushNotification = (state:iRootState) => state.pushNotification;
export default pushNotificationSlice;