
import React, {useEffect,useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import  appSlice, { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Button from '../../ui/Button';
import ReactHtmlParser from 'react-html-parser';
import routes from '../../../constants/routes.constant';
import useGoto from '../../../utils/hooks/useGoto';

interface Props {

}

const TermsCondition : React.FC<Props>  = () =>  {

    // VARIABLES
    const goto = useGoto();
    const [B, E] = useBEM('terms-condition');
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));

    const sections = [
        {
            header:t('legal_notice_data_content_title'),
            content:t('legal_notice_data_content_content'),
        },
        {
            header:t('legal_notice_privacy_title'),
            content:t('legal_notice_privacy_content'),
        },
        {
            header:t('legal_notice_prohibited_title'),
            content:t('legal_notice_prohibited_content'),
        },
        {
            header:t('legal_notice_ownership_title'),
            content:t('legal_notice_ownership_content'),
        },
        {
            header:t('legal_notice_conduct_title'),
            content:t('legal_notice_conduct_content'),
        },
        {
            header:t('legal_notice_third_party_title'),
            content:t('legal_notice_third_party_content'),
        },
        {
            header:t('legal_notice_disclaimer_title'),
            content:t('legal_notice_disclaimer_content'),
        },
        {
            header:t('legal_notice_limitation_title'),
            content:t('legal_notice_limitation_content'),
        },
        {
            header:t('legal_notice_provision_title'),
            content:t('legal_notice_provision_content'),
        },
        // {
        //     header: t('legal_notice_ln_under_this_privacy_notice_data_title'),
        //     multipleContent: [
        //         {
        //             subHeader: 'header 1',
        //             subContent: t('legal_notice_ln_under_this_privacy_notice_data'),
        //         },
        //         {
        //             subHeader: 'header 2',
        //             subContent: t('legal_notice_ln_under_this_privacy_notice_data'),
        //         }
        //     ]
            
            
        // },
    ]

    // HOOKS
    
    // METHODS
    const accept = () =>{
        dispatch(appSlice.actions.setOpenFirstLogin(false));
        dispatch(appSlice.actions.setFirstLoginStatus(true));
    }
    const decline = () =>{
        setTimeout(() => {            
            dispatch(appSlice.actions.setOpenFirstLogin(false));
            dispatch(appSlice.actions.setFirstLoginStatus(true));
            goto.route(routes.licenseActivation);
            }, 500);
    }
    const renderSections = () => {
        const sections_jsx = sections.map((section, j)=>{
            return (
                <div className={E('section')} key={j}>
                    <div className={E('bullet')}>{`0${j+1}`}</div>

                    <div className={E('content')}>
                        <h2>{section.header}</h2>
                        {
                            section?.content && 
                            <div className={E('content-paragraph')}>
                                {ReactHtmlParser(section?.content)}
                            </div>
                        }
                         {/* {
                            section?.multipleContent && section?.multipleContent?.length > 0 && 
                            <div className={E('content-paragraph')}>
                                {
                                    section?.multipleContent.map((v:any)=>{
                                       return <>
                                                <h4>{v.subHeader}</h4>
                                                <p>{ReactHtmlParser(v.subContent)}</p>
                                            </>
                                    })
                                }
                                
                            </div>
                        }                         */}
                    </div>
                </div>
            )
        })
        
        return sections_jsx
    }
    return (
        <div className={B()}>
            <div className={E('content')}>
                <h2>{t('legal_notice_terms_of_use_title')}</h2>
                <div className={E('content-paragraph')}>
                    <p>
                    {t('legal_notice_terms_of_use_content')}
                    <br/><br/>
                    <strong><u>{t('legal_notice_agree_terms')}</u></strong>
                    <br/><br/>
                    {t('legal_notice_agree_terms_content')}
                    <br/><br/>
                    </p>
                </div>
            </div>
            {renderSections()}
            <div className={E('footer')}>
                <Button text={t('legal_notices_ln_decline')} type="secondary"  icon="decline" icontype="png" click={accept}/>
                <Button text={t('legal_notices_ln_accept')} icon="check-circle" click={accept}/>
            </div>
        </div>
    )
}

export default TermsCondition;

