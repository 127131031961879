import React from 'react';
import { useSelector } from 'react-redux';
import iListMenuItem from '../../interface/list-menu-item.interface';
import { getApp } from '../../store/slices/app.slice';
import ExperienceType from '../../types/experience.type';
import useBEM from '../../utils/hooks/useBEM';
import useTranslation from '../../utils/hooks/useTranslation';
import Icon from './Icon';

interface Props {
    items: iListMenuItem[],
    onSelect?: (menu: iListMenuItem) => void,
    value?: iListMenuItem
    experience?: ExperienceType
}
const ListMenu: React.FC<Props> = ({
    items,
    onSelect,
    value,
    experience = ExperienceType.STANDARD
}) => {
    // VARIABLES
    const [B, E] = useBEM('list-menu');
    const { t } = useTranslation(useSelector(getApp));
    const appStore = useSelector(getApp);

    // HOOKS
    // METHODS
    const onSelectHandler = (menu: iListMenuItem) => {
        if (onSelect) onSelect(menu);
    }
    const selectedItemClass = (item: iListMenuItem) => {
        if (!value) return "";
        return value.value === item.value ? 'selected' : '';
    }
    const selectIcon = () => (appStore.experience === ExperienceType.SUN ? "-sun" : "")

    return (
        <ul className={B()}>
            {
                items.map((item, index) => (
                    <li className={E('item', selectedItemClass(item))}
                        key={index} onClick={() => onSelectHandler(item)}>{
                            item.icon &&
                            <span className={E('item-icon')}>
                                <Icon name={`${item.icon}${selectIcon()}`} />
                            </span>
                        }
                        <label className={E('item-label')}>{t(item.label)}</label>
                        {
                            value && value.value === item.value && <Icon name="right-arrow" />
                        }
                    </li>
                ))
            }
        </ul>
    )
}

export default ListMenu;
