import { useHistory } from "react-router";
import iRoute from "../../interface/route.interface";

type bemType = {
    route: (route: iRoute, delay?: number) => void,
    routeWithTransition: (
        fromRoute: iRoute, 
        toRoute: iRoute, 
        transition: 'SLIDE_LEFT' | 'FADE_OUT',
        delay?: number) => void,
}


const useGoto  = (): bemType =>  {

        const history = useHistory();

        const route = (route: iRoute, delay?: number) => {
            if(!delay){
                history.push(route.path); 
                return;
            }   

            setTimeout(() => {
                history.push(route.path); 
            }, delay);
        }

        const routeWithTransition = (
            fromRoute: iRoute,
            toRoute: iRoute,
            transition: 'SLIDE_LEFT' | 'FADE_OUT',
            delay?: number
        ) => {
            const pageElement = document.querySelector(`.${fromRoute.id}`);
            const classToAppend = 'goto-'+transition;
            pageElement?.classList.add(classToAppend);
                        
            setTimeout(() => {
                pageElement?.classList.remove(classToAppend);
                route(toRoute);
            }, delay || 1000);
        }

        return {
            route,
            routeWithTransition    
        };
}

export default useGoto;