import React, { useState } from 'react';
import Icon from '../../ui/Icon';
import iMenu from '../../../interface/menu.interface';
import { useDispatch, useSelector } from 'react-redux';
import sidebarSlice, { getSidebar } from '../../../store/slices/sidebar.slice';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import pushNotificationSlice, { getPushNotification } from '../../../store/slices/push-notification.slice';
import BadgeNotification from '../../ui/BadgeNotification';
import ExperienceType from '../../../types/experience.type';

interface Props {
    disabled?: boolean,
    title?: string,
    items: iMenu[]
}

const Menu : React.FC<Props>  = ({
    title,
    items,
    disabled = false
}) =>  {
    // VARIABLES
    const [soonHover, setSoonHover] = useState(false)
    const { selectedMenu } = useSelector(getSidebar)
    const dispatch = useDispatch()
    const ga = useGoogleAnalytics()
    const { t } = useTranslation(useSelector(getApp))
    const { tutorialHasNewUpdate, notificationEvent } = useSelector(getPushNotification)
    const appStore = useSelector(getApp)
    const { experience } = appStore
    // HOOKS

    // METHODS
    const selectMenu = (menu: iMenu, index: number) => {
        if (disabled) {
            if (selectedMenu && selectedMenu.id === menu.id) {
                dispatch(sidebarSlice.actions.selectMenu({ menu: undefined }))
                return
            }
            return
        }
        if(!menu?.submenu) {
            dispatch(sidebarSlice.actions.close())
        }
        updateNotification(index)
        ga.event({
            category: `Menu Bar`,
            action: 'Button Click',
            label: t(menu.label, 'enUS')
        });
        dispatch(sidebarSlice.actions.selectMenu({ menu }))
    }
    const isActiveMenu = (menu: iMenu) => {
        if (!selectedMenu) return false
        return (selectedMenu.id === menu.id)
    }
    const activeMenuClass = (menu: iMenu) => {
        return isActiveMenu(menu) ? 'menu__item--active' : ''
    }
    const selectIcon = (menu: iMenu) => {
        return experience === ExperienceType.SUN ? `${menu.icon}-sun` : menu.icon
    }
    const renderMenu = (items: iMenu[] = []) => {
        return items.map((menu, index) => {
            return (
                menu.id === "education" ? comingSoonMenu(menu, index) :
                    <li
                        onClick={() => selectMenu(menu, index)} className={`menu__item ${activeMenuClass(menu)}`} key={index}> {
                            <Icon name={isActiveMenu(menu) && menu.activeIcon ? menu.activeIcon : selectIcon(menu)} />
                        }
                        <h5>{t(menu.label)}</h5>
                        {showBadge(index)}
                    </li>
            )
        })
    }
    const showBadge = (index: number) => {
        // Index0 - Tutorial
        let badgeCount = 0;
        if (index === 0) {
            badgeCount = tutorialHasNewUpdate;
        }

        if (badgeCount > 0)
            return <BadgeNotification count={badgeCount} type="menu"/>
        else
            return <></>
    }
    const updateNotification = async (index: number) => {
        // Index0 - Tutorial
        let currentEvent: any = await localStorage.getItem('realtimeEvent');
        if (currentEvent !== null) currentEvent = JSON.parse(currentEvent);
        // Tutorial
        if (index === 0) {
            await dispatch(pushNotificationSlice.actions.setTutorialNotification(0));
        }
        localStorage.setItem('realtimeEvent', JSON.stringify(notificationEvent));
    }
    const comingSoonMenu = (menu: iMenu, index: number) => {
        return (
            <li
                onMouseEnter={() => setSoonHover(true)}
                onMouseLeave={() => setSoonHover(false)}
                className={`menu__item ${activeMenuClass(menu)}`}
                key={index}> {
                    <Icon name={isActiveMenu(menu) && menu.activeIcon ? menu.activeIcon : selectIcon(menu)} />
                }
                <h5>{soonHover ? t('education_edu_coming_soon', 'Coming Soon') : t(menu.label)}</h5>
                {showBadge(index)}
            </li>
        )
    }

    return (
        <div className={`menu ${disabled ? 'menu--disabled' : ''}`}>{
            title &&
            <label className="menu__title">
                {title}
            </label>
        }
            <ul className="menu__items">
                {renderMenu(items)}
            </ul>
        </div>
    )
}

export default Menu;
