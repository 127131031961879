import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import RouterLink from '../../../components/ui/RouterLink';
import NeedExplorerViewType from '../../../types/need-explorer-view.type';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import userSlice from '../../../store/slices/user.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import routes from '../../../constants/routes.constant';
import { evaluateDecisionTree } from '../../../utils/misc';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import Utils from '../../../utils/Utils';
import { getProducts } from '../../../store/slices/products.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import iLensRecommendationPair from './../../../interface/lens-recommendaiton-pair.interface';

interface Props {

}

const NeedExplorerGeneralCheckPoint : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-general-checkpoint');
    const dispatch = useDispatch();
    const currentNeedExplorerData = useSelector(getNeedExplorer);
    const currentLensConsultation = useSelector(getLensConsultation);
    const {accountDetails} = useSelector(getApp);
    const country = accountDetails?.shop.country
    const { temporaryProductList } = useSelector(getProducts);
    const history = useHistory();
    const ga = useGoogleAnalytics();
    const { getDateTimeDifference } = Utils();
    const { t } =  useTranslation(useSelector(getApp));

    // // HOOKS
    useEffect(() => {
        // Save data as soon as this page loads
        saveDataAndPrefilled()

        return () => {
            saveGeneralQuestionsSessionDuration();
        }
    }, [])

    // // METHODS
    const saveGeneralQuestionsSessionDuration = () => {
        if(!currentNeedExplorerData.generalQuestionSessionStart)return;
        const timediff = getDateTimeDifference(new Date(currentNeedExplorerData.generalQuestionSessionStart), new Date());
        ga.event({
            category: 'Need Explorer',
            action: '9 Q Complete',
            variable_id: 'need_explorer_9_question_complete',
            label: timediff
        });
        dispatch(needExplorerSlice.actions.generalQuestionSessionStart(undefined))
    }

    const modify = () => {
        dispatch(needExplorerSlice.actions.questionaireFromStart());
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }

    const gotoAnamnesis = () => {
        //Save Data and Recommend
        // saveDataAndPrefilled()

        //Move to Anamnesis Page
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.ANAMNESIS));
    }

    const saveAndNext = () => {
        //Save Data and Recommend
        saveDataAndPrefilled();
        //Move to Lens Proposal Builder Page
        history.push(routes.lensProposalBuilder.path);
    }

    const saveDataAndPrefilled = () => {

        const recom = evaluateDecisionTree(currentNeedExplorerData, true, temporaryProductList, country)

        //Save User Data for Need Explorer
        dispatch(userSlice.actions.saveNeedExplorerData(currentNeedExplorerData));

        //Evaluate Decision Tree to prefilled data in Lens Consultation
        const temp = {
            ...currentLensConsultation,
            lensRecommendation: {
                pair1: recom.pair1,
                pair2: recom.pair2
            },
            storedLensRecommendation: {
                pair1: recom.pair1,
                pair2: recom.pair2
            }
        }

        console.log('RESULT FROM GENERAL', temp)

        dispatch(userSlice.actions.savePrefilledData(temp));
        dispatch(needExplorerSlice.actions.setFinalAnswers(recom.answers));
        dispatch(lensConsultationSlice.actions.setPrefilledDataFromNeedExplorer(recom))
        dispatch(lensConsultationSlice.actions.setPrefilledDataFromNeedExplorerStored(recom))
        dispatch(lensConsultationSlice.actions.setShowDialogue(true))
        dispatch(lensConsultationSlice.actions.setShowWarning(true))

        dispatch(userSlice.actions.saveLensRecommendationData({
            pair1: recom?.pair1 as iLensRecommendationPair,
            pair2: recom?.pair2 as iLensRecommendationPair
        }));
    }

    const goToQuestionnaire = () => {
        dispatch(needExplorerSlice.actions.moreQuestionSessionStart(new Date().toISOString()));
        // Continue answering questions
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }

    const customIconStyle = { color: "white" }

    return (
        <div className={B()}>

            <div className={E('modal')}>
                <div />

                <div>
                    <div className='icons'>
                        <Icon
                            className='icon'
                            name="check-with-circle"
                            type='svg'
                        />
                        <Icon
                            className='icon'
                            name="empty-circle"
                            type='svg'
                        />
                    </div>

                    <h2>{t('need_explorer_first_step_done')}</h2>

                    <div className='save'>
                        <Button
                            className='btn'
                            text={t('need_explorer_go_back__lpb')}
                            icon="right-arrow-circle-white"
                            click={saveAndNext}
                            type='secondary'
                        />

                        <RouterLink className='modify' click={modify}>
                            {t('questionnaires_q_modify_my_answers')}
                        </RouterLink>
                    </div>

                    <div className='separator'>
                        <div />
                        <span>OR</span>
                        <div />
                    </div>

                    <div className='buttons'>
                        <Button
                            className='btn'
                            text={t('questionnaires_q__more_questions')}
                            icon="right-arrow-circle-white"
                            click={goToQuestionnaire}
                            type='secondary'
                        />

                        <Button
                            className='btn'
                            text={t('questionnaires_q_summary')}
                            type="simple"
                            icon="right-arrow"
                            click={gotoAnamnesis}
                        />


                    </div>
                </div>
            </div>
        </div>
    )
}

export default NeedExplorerGeneralCheckPoint;
