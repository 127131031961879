const measuresValidations = [
    {
        key: 'a',
        min: 10,
        max: 100
    },
    {
        key: 'b',
        min: 10,
        max: 100
    },
    {
        key: 'd',
        min: 5,
        max: 75
    },
    {
        key: 'pd',
        min: 15,
        max: 45
    },
    {
        key: 'height',
        min: 10,
        max: 40
    },
    {
        key: 'pantoscopicAngle',
        min: 0,
        max: 25
    },
    {
        key: 'wrapAngle',
        min: 0,
        max: 25
    },
    {
        key: 'vertexDistance',
        min: 1,
        max: 20
    },
    {
        key: 'readingDistance',
        min: 20,
        max: 50,
        allowZero:true
    }
]

export default measuresValidations;