import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import SideBoxBordered from '../../../components/ui/SideBoxBordered';
import useBEM from '../../../utils/hooks/useBEM';
import LensAnimation from './LensAnimation';
import eyeGif from './../../../assets/gif/eye.gif';
import eyeSVG from './../../../assets/svg/eye.svg';
import { useDispatch, useSelector } from 'react-redux';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import LensPairType from '../../../types/lens-pair.type';
import LensTransitionInAnimation from './LensTransitionInAnimation';
import LensTransitionOut from './LensTransitionOut';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import lensThicknessSlice, { getlensThickness } from '../../../store/slices/lens-thickness.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import useLensThickness from '../../lens-thickness/useLensThickness';
import routes from '../../../constants/routes.constant';
import { useHistory } from 'react-router';
import { LENS_THICKNESS_RULES } from '../../../constants/lens-thickness.constants';
import Utils from '../../../utils/Utils';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import summarySlice from '../../../store/slices/summary.slice';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import useGoto from '../../../utils/hooks/useGoto';
import productDemoSlice from '../../../store/slices/products-demo.slice';
import iLensRecommendationPair from '../../../interface/lens-recommendaiton-pair.interface';
import iProduct from '../../../interface/product.inteface';
import LensFamilyType from '../../../types/lens-family.type';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import iProductBrand from '../../../interface/product-brand.interface';
import ExperienceType from '../../../types/experience.type';
import CircleArrow from '../../../components/ui/CircleArrow';
// import LensConsultationSideSun from './sun-experience/LensConsultationSideSun';
// import lensConsultationSun, { getLensConsultationSun } from "../../../store/slices/lens-consultation-sun-exp.slice"
import { TEMPORARY_PRODUCT_LIST } from '../../../constants/products.constants';
import LensRecommendation from './LensRecommendation';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import axios from 'axios';
import useEprom from '../../../utils/hooks/useEprom';

interface Props {

}

const LensConsultationSideV2: React.FC<Props> = ({
    // experience
}) => {
    // VARIABLES
    const [B, E] = useBEM('lens-consultation-side-v2');
    const history = useHistory();
    const [onHoverEye, setOnHoverEye] = useState(false);
    const [transitionOutType, setTransitionOutType] = useState<'Convexe' | 'Concave'>('Convexe');
    const [isAnimateLensThickness, setAnimateLensThickness] = useState(false);
    const { isLensThickness, showDialogue, showWarning, temporaryByPassProduct } = useSelector(getLensConsultation)
    const [fadeOutSun, setFadeOutSun] = useState(false);
    const { temporaryProductList } = useSelector(getProducts)
    // const { solution, lensRecommendationSun,  } = useSelector(getLensConsultationSun);
    const tempList: any[] = TEMPORARY_PRODUCT_LIST;
    const {
        activePair,
        lensRecommendation,
        productFamily,
        sliderPage,
        validOnEPROM
    } = useSelector(getLensConsultation);

    const { prescription, measures } = useSelector(getUser);
    const {
        getRecommendedSubProductByIndex,
        getRecommendedLensThickness,
        filterSubProductsWithEnabledIndex
    } = useLensThickness();
    const { getEpromApiEndpoint, isValidOnEPROM } = useEprom()
    const {
        getMeasuresHighestValues,
        getLeftAndRightPrescription,
        getRequestType,
        skipEPROMValidation
    } = useLensConsultation();
    const dispatch = useDispatch();
    const [isTransitionInAnimationComplete, setTransitionInAnimationComplete] = useState(false);
    const { view, tempBaseLensThicknessCalculation } = useSelector(getlensThickness);
    const { tempPrescriptionData, isValidPrescription, temporaryData } = useSelector(getPrescription);
    const { tempLensThickness, sliderValue, sliderCount } = useSelector(getlensThickness);
    const lensThicknessRules = LENS_THICKNESS_RULES;
    const lensThicknessTimestamp = useRef<Date>();
    const lensConsultationTimestamp = useRef<Date>();
    const { getDateTimeDifference } = Utils();
    const ga = useGoogleAnalytics();
    const goto = useGoto();
    const [recommendationToDemo, setRecommendationToDemo] = useState<{
        correct?: iProduct,
        protect?: iProduct,
        enhance?: iProduct,
    }>();
    const [recommendationToDemoBrands, setRecommendationToDemoBrands] = useState<{
        correct?: iProductBrand,
        protect?: iProductBrand,
        enhance?: iProductBrand,
    }>();
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const { lensThicknessSettings, accountDetails } = appStore;
    const { deepEqual } = Utils();
    const [controlNextButton, setControlNextButton] = useState(true)

    // HOOKS
    useEffect(() => {
        lensConsultationTimestamp.current = new Date();
        setAnimateLensThickness(isLensThickness)
    }, [])
    useEffect(() => {
        if (!lensRecommendation) return;
        const pair: iLensRecommendationPair = lensRecommendation[activePair] as iLensRecommendationPair;
        if (!pair) return;
        const withDemo = (product?: iProduct) => {
            if (!product ||
                !product.demo ||
                product.demo.length < 1
            ) return undefined;
            return product;
        }

        const recommendation = {
            correct: withDemo(pair.correct?.product),
            protect: pair.protect && pair.protect.length > 0 ? withDemo(pair.protect[0].product) : undefined,
            enhance: withDemo(pair.enhance?.product),
        }

        const recommendationBrands = {
            correct: pair?.correct?.brand || undefined,
            protect: pair.protect && pair.protect.length > 0 ? pair.protect[0].brand : undefined,
            enhance: pair?.enhance?.brand || undefined
        }
        setRecommendationToDemoBrands(recommendationBrands);
        setRecommendationToDemo(recommendation);
    }, [lensRecommendation, activePair])

    useEffect(() => {
        // Note: dont update on lens thickness
        if (sliderPage === 3) return;
        const sphere = prescription.values && prescription.values.sphere;
        if (sphere !== undefined) {
            if (!sphere.od || !sphere.os) return;
            const add = Number(sphere.od) + Number(sphere.os);
            setTransitionOutType(add < 0 ? 'Concave' : 'Convexe');
        }
    }, [prescription]);

    useEffect(() => {
        // console.log(temporaryData)
        if (sliderPage === 3) {
            let sphere = temporaryData && temporaryData.sphere
            let cylinder = temporaryData && temporaryData.cylinder
            let axis = temporaryData && temporaryData.axis
  
            if (!sphere?.os && !sphere?.od) {
                setControlNextButton(false)
            } else {
                if ((sphere?.od && !sphere?.os) || (!sphere?.od && sphere?.os)) {
                    setControlNextButton(true)
                } else {
                    if (!cylinder?.od && !cylinder?.os && !axis?.os && !axis?.od) {
                        setControlNextButton(false)
                    } else if (cylinder?.od && cylinder?.os && axis?.os && axis?.od){
                        setControlNextButton(false)
                    }else {
                        setControlNextButton(true)
                    }
                }
            }
        }

    }, [temporaryData, sliderPage])

    // Note: update recommended lens thickness
    const timer = useRef<any>();
    useEffect(() => {
        if (sliderPage < 3) return;
        const isSame = deepEqual(tempPrescriptionData, prescription);
        if (!isValidPrescription || isSame) {
            clearTimeout(timer.current);
            return;
        }
        if (timer.current) clearTimeout(timer.current);
        dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
        timer.current = setTimeout(() => {
            recommendLensThickness(tempPrescriptionData);
        }, 1000);
    }, [tempPrescriptionData])

    useEffect(() => {
        if (sliderPage === 0) {
            setAnimateLensThickness(false);
        }
    }, [sliderPage]);

    // Note: should only run when the Presctiption Value has changed
    useEffect(() => {
        if (lensRecommendation) {
            const pair = activePair === LensPairType.PAIR1 ? 0 : 1;
            const isWithRX = prescription.values.sphere.od ? true : false;
            const family = lensRecommendation[activePair]
            
            if (family?.correct) {
                const skipEPROM = skipEPROMValidation(
                    accountDetails?.shop.region as string,
                    family?.correct.subProduct?.code as string,
                    family?.correct.subProduct?.code as string,
                );
                const isValidateEPROM = (accountDetails?.shop.eprom as boolean) && !skipEPROM;
    
                const data = {
                    index: lensThicknessSettings[sliderValue as number].value,
                    product_code: handleProductCode(family?.correct.product?.id as string),
                    sub_product_code: handleSubProductCode(family?.correct.subProduct?.code as string),
                    prescription: getLeftAndRightPrescription(prescription.values),
                    measures: getMeasuresHighestValues(measures, pair),
                    request_type: getRequestType(isWithRX, isValidateEPROM)
                }
    
                axios.post(getEpromApiEndpoint(), { data }).then(x => {
                    if (x.data.statuscode !== 200 || !x.data.data.lens_thickness ) {
                        return
                    }
                    const {
                        max_edge_thk,
                        weight,
                        center_thk,
                        index
                    } = x.data.data.lens_thickness;
    
                    dispatch(lensThicknessSlice.actions.saveBaseLensThickness({
                        pair: activePair,
                        data: {
                            index: index,
                            productCode: data.product_code as string,
                            edge: max_edge_thk,
                            weight: weight,
                            center: center_thk,
                            prescription: getLeftAndRightPrescription(prescription.values),
                            measures: data.measures
                        }
                    }))
                    
                    const getSavedSelectedIndex = localStorage.getItem("selectedIndex")
                    const parseSavedIndex = JSON.parse(getSavedSelectedIndex as any)
                    if (parseSavedIndex.index) {
                        dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
                            pair: activePair,
                            data: parseSavedIndex
                        }))        
                    }
                    
                    return
                })
            }
        }   
    }, [prescription])
    
    // Note: should only run when the Thickness Slider has changed
    useEffect(() => {
        if (lensRecommendation) {
            if (lensRecommendation[activePair]) {
                const pair = activePair === LensPairType.PAIR1 ? 0 : 1;
                const isWithRX = prescription.values.sphere.od ? true : false;
                const family = lensRecommendation[activePair]
                                
            if (family?.correct) {
                const skipEPROM = skipEPROMValidation(
                    accountDetails?.shop.region as string,
                    family?.correct.subProduct?.code as string,
                    family?.correct.subProduct?.code as string,
                );
                const isValidateEPROM = (accountDetails?.shop.eprom as boolean) && !skipEPROM;
                const base = tempBaseLensThicknessCalculation[activePair]
                if (base) {
                    const family = lensRecommendation[activePair]
                    const data = {
                        index: lensThicknessSettings[sliderValue as number].value,
                        product_code: handleProductCode(family?.correct.product?.id as string),
                        sub_product_code: handleSubProductCode(family?.correct.subProduct?.code as string),
                        prescription: getLeftAndRightPrescription(prescription.values),
                        measures: base?.measures,
                        request_type: getRequestType(isWithRX, isValidateEPROM) 
                    }

                    axios.post(getEpromApiEndpoint(), { data }).then(x => {
                        if (x.data.statuscode !== 200 || !x.data.data.lens_thickness) {
                            return
                        }
            
                        const {
                            max_edge_thk,
                            weight,
                            center_thk,
                            index
                        } = x.data.data.lens_thickness;
            
                        const diffData = {
                            weight: computeDifferencePercentage(base.weight, weight),
                            edge: computeDifferencePercentage(base.edge, max_edge_thk),
                            center: computeDifferencePercentage(base.center, center_thk),
                        }
                        
                        dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
                            pair: activePair,
                            data: {
                                index: index,
                                productCode: base.productCode,
                                edge: diffData.edge,
                                weight: diffData.weight,
                                center: diffData.center
                            }
                        }))

                        localStorage.setItem("selectedIndex", JSON.stringify({
                            index: index,
                            productCode: base.productCode,
                            edge: diffData.edge,
                            weight: diffData.weight,
                            center: diffData.center
                        }))        

                        const recomValue = localStorage.getItem("recomData")
                        let parseRecomValue = JSON.parse(recomValue as any)
                        if (parseRecomValue.index) {
                            dispatch(lensThicknessSlice.actions.saveBaseLensThickness({
                                pair: activePair,
                                data: parseRecomValue
                            }))
                            return
                        } else {
                            localStorage.setItem("recomData", JSON.stringify({
                                index: lensThicknessSettings[sliderValue as number].value,
                                productCode: data.product_code as string,
                                edge: max_edge_thk,
                                weight: weight,
                                center: center_thk,
                                prescription: getLeftAndRightPrescription(prescription.values),
                                measures: data.measures
                            }))
                            return
                        }
                    })
                }
            }
        }
    }
    }, [sliderValue])

    // METHODS
    
    // Rule: Substitute Varilux X Series product_codes and sub_product_codes when XR Series is selected
    // Only applicable for Varilux XR series
    const handleProductCode = (productCode: string) => {
        if (productCode === "varilux_xr_series") {
            return "varilux_x_series"
        } else {
            return productCode
        }
    }
    const handleSubProductCode = (subProductCode: string) => {
        switch (subProductCode) {
            case "varilux_xr_design":
                return "varilux_x_design";
            case "varilux_xr_design_short":
                return "varilux_x_design_short";
            case "varilux_xr_fit":
                return "varilux_x_f360";
            case "varilux_xr_track":
                return "varilux_x_track";
            case "varilux_xr_4d":
                return "varilux_x_4d";
            case "varilux_xr_pro":
                return "varilux_xclusive_4d";
            
            default:
                return subProductCode;
        }
    }

    const computeDifferencePercentage = (currentValue: number, newValue: number) => {
        let values = [currentValue, newValue];
        // if (newValue > currentValue) values = values.reverse();
        const diff = values[0] - values[1];
        const deffPercentage = (diff / values[0]) * 100;
        const finalPercent = newValue >= currentValue ? deffPercentage : (deffPercentage * -1);
        return Math.round(finalPercent);// Number(finalPercent.toFixed(2));
    }

    const getProductIdsToCheck = (family: any, checkEnhance = false) => {
        const protectIdsToCheck = family?.protect ? family?.protect.map((p: any) => p.product?.id) : [];
        const enhanceIdsToCheck = (family?.enhance && checkEnhance) ? [family?.enhance.product?.id] : [];
        return [
            ...protectIdsToCheck,
            ...enhanceIdsToCheck
        ]
    }


    const changePair = (pair: LensPairType) => {
        if (!isActivePairWithCorrectSubProduct(pair) && sliderPage === 3) return;

        dispatch(lensConsultationSlice.actions.changePair(pair));
    }

    const getLabelText = () => {
        if (sliderPage === 0) return t('lens_consultation_lc_lens_consultation');
        if (sliderPage === 3) return t('lens_thickness_lt_completed');
        if (productFamily) return t(productFamily.label);
        return '';
    }

    const getLabelIcon = () => {
        if (sliderPage === 0) return '';
        if (sliderPage === 3) return <Icon name="check-circle" />;
        if (productFamily) return <Icon name="progress-circle-white" type="png" />;
        return '';
    }
    const displayWarning = () => {
        if (!lensRecommendation) return false;

        const pair2 = lensRecommendation.pair2;
        if (
            (!pair2?.correct && (pair2?.enhance || (pair2?.protect || []).length > 0)) ||
            (pair2?.correct)
        ) return showDialogue;
        return false;
    }
    const displayWarning2 = () => {
        if (!lensRecommendation) return false;

        const pair2 = lensRecommendation.pair2;
        if ((pair2?.correct?.subProduct === null || !pair2?.correct?.subProduct) || (pair2?.protect && pair2?.protect.length < 1 ) || !pair2?.enhance?.product)return showWarning
        if(pair2?.correct?.subProduct && pair2?.protect && pair2?.protect[0]?.product && pair2?.enhance && pair2?.enhance?.product)return false
        else return false;
    }
    const clearSecondPairRecom = (clear:boolean) => {
      dispatch(lensConsultationSlice.actions.clearSecondPair(clear));
      dispatch(summarySlice.actions.saveSummary2(undefined))
    }

    const top = () => (
        // <div className={E('top', experience)}>
        <div className={E('top')}>
            <label>
                {getLabelIcon()}
                {getLabelText()}
            </label>
            <div className={E('lens-pairs', activePair)}>
                <div className="pair1-selected">
                    <Icon name="lc-pair-1" click={() => changePair(LensPairType.PAIR1)} />
                </div>
                <span className="seprate"></span>
                <div className="pair2-selected">
                    <Icon name="lc-pair-2" click={() => changePair(LensPairType.PAIR2)} />
                </div>
                    {displayWarning2() && 
                        <div className="warning-icon">
                            <span>!</span>
                        </div>
                    }
                {displayWarning() &&
                    <>
                        <div className="dialogue">
                            {t('ec_pair_2_recommend_mess', 'Do you want recommend the complementary pair?')}
                            <div className='dialogue-buttons-container'>
                                <div className='dialogue-buttons'>
                                    <div className='dialogue-buttons-yes'onClick={() => clearSecondPairRecom(true)}>
                                        {t('daily_mobility_q_sc_yes')}
                                    </div>
                                    <div className='dialogue-buttons-no' onClick={() => clearSecondPairRecom(false)}>
                                        {t('daily_mobility_q_sc_no')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )

    const onHoverHandler = (isHover: boolean) => {
        setTimeout(() => {
            setOnHoverEye(isHover);
        }, isHover ? 0 : 500);
    }

    const eyeIconGif = () => {
        return <div className={E('eye-blink')}
            onMouseEnter={() => onHoverHandler(true)}
            onMouseLeave={() => onHoverHandler(false)}
        >
            <img src={eyeGif} alt="" />
            <img className={onHoverEye ? 'gif' : 'svg'} src={onHoverEye ? eyeGif : eyeSVG} alt="" />
        </div>
    }

    const isActivePairWithCorrectSubProduct = (pair?: LensPairType) => {
        if (!lensRecommendation) return false;
        const pair1Correct = lensRecommendation.pair1?.correct;
        const pair2 = lensRecommendation.pair2;

        if (!pair1Correct || !pair1Correct.product || !pair1Correct?.subProduct)
            return false;
        if ((pair2?.correct || (pair2?.protect || []).length > 0 || pair2?.enhance)
            && (!pair2?.correct?.product || !pair2?.correct?.subProduct))
            return false;
        if (pair === LensPairType.PAIR2 && !pair2?.correct) return false;
        return true;
    }

    const disableNextButton = () => {

        if (accountDetails?.shop.country === 'be') {
            if (lensRecommendation?.pair1?.correct && !lensRecommendation.pair2?.correct) {
                if (lensRecommendation?.pair1?.correct?.subProduct === null || lensRecommendation?.pair1?.correct?.subProduct === undefined) {
                    return true
                }
                if (activePair === 'pair2') {
                    if (lensRecommendation?.pair2?.correct?.subProduct === null || lensRecommendation?.pair2?.correct?.subProduct === undefined) {
                        return true
                    }
                }
            } else if ((lensRecommendation?.pair1?.enhance?.product?.id !== null || lensRecommendation?.pair1?.enhance?.product?.id !== undefined) &&
                        (lensRecommendation?.pair2?.enhance?.product?.id !== null || lensRecommendation?.pair2?.enhance?.product?.id !== undefined)
            ) {
                if ((lensRecommendation?.pair1?.correct?.subProduct === null || lensRecommendation?.pair1?.correct?.subProduct === undefined) ||
                    (lensRecommendation?.pair2?.correct?.subProduct === null || lensRecommendation?.pair2?.correct?.subProduct === undefined)
                ) {
                    return true
                }
            }
        }
        
        if (!lensRecommendation) return true;
        if (lensRecommendation?.pair1?.correct === null) return true;

        if(lensRecommendation && accountDetails?.shop.country === 'be'){
            const protect = lensRecommendation[activePair]?.protect
            if(protect && protect[1] && (protect[1]?.product?.id === 'xperio_mirrors' || protect[0]?.product?.id === 'xperio_mirrors'))return false
        }   

        if (accountDetails?.shop.eprom && (
            validOnEPROM?.pair1 === false ||
            validOnEPROM?.pair2 === false
        )) return true;
        
        //eprom belgium new business rule
        const productsBelgiumSunX = [
            'transitions_xtractive',
            'transitions_xtractive_polarised',
            'xperio_polarised',
            'xperio_tinted',
            'xperio_mirrors',
            'xperio_tinted_mirrors',
            'xperio_polarised_mirrors',
            'style_mirrors']

        if(lensRecommendation){
            const protect = lensRecommendation[activePair]?.protect
            const enhance = lensRecommendation[activePair]?.enhance
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            protect[0]?.product?.id &&
            enhance && 
            enhance?.product?.id
            ){
                if(enhance?.product?.id === 'crizal_sun_xprotect' && !productsBelgiumSunX.includes(protect[0]?.product?.id))return true
            }
            
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            !protect[0] &&
            enhance && 
            enhance?.product?.id &&
            enhance?.product?.id === 'crizal_sun_xprotect'
            )return true
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            protect[0]?.product?.id === 'xperio_mirrors' && 
            !protect[1]
            )return true

        }



        // Lens Thickness
        if (sliderPage === 3) { 
            return !isValidPrescription;
        }
        return !isActivePairWithCorrectSubProduct(activePair);
    }

    const saveAndNext = () => {
        if (sliderPage < 3) {
            dispatch(lensConsultationSlice.actions.setSliderPage(3));
            setAnimateLensThickness(true);
            saveLensConsultationSessionDuration();
            lensThicknessTimestamp.current = new Date();
            if (lensRecommendation) {
                dispatch(userSlice.actions.saveLensRecommendationData(lensRecommendation));
                // if (experience === ExperienceType.STANDARD) dispatch(lensConsultationSlice.actions.saveLensRecommendationStandard(lensRecommendation));
                // if (experience === ExperienceType.SUN) dispatch(lensConsultationSlice.actions.saveLensRecommendationSun(lensRecommendation));
            }
        } else {
            dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
            gotoSummary();
            saveLensThicknessSessionDuration()
        }
    }

    const saveLensThicknessSessionDuration = () => {
        if (!lensThicknessTimestamp.current) return;
        const timediff = getDateTimeDifference(lensThicknessTimestamp.current, new Date());
        ga.event({
            category: 'Lens Consulation',
            action: 'Lens Thickness Completion',
            variable_id: 'lens_thickness_completion',
            label: timediff
        });
        lensThicknessTimestamp.current = undefined;
    }

    const saveLensConsultationSessionDuration = () => {
        if (!lensConsultationTimestamp.current) return;
        const timediff = getDateTimeDifference(lensConsultationTimestamp.current, new Date());
        ga.event({
            category: 'Lens Consulation',
            action: 'Consultation Completion',
            variable_id: 'lens_consultation_completion',
            label: timediff
        });
        lensConsultationTimestamp.current = undefined;
    }

    const isPrescriptionComplete = () => {
        return (prescription.values && prescription.values.sphere.od) ? true : false
    }

    const gotoSummary = () => {
        if (view === 'prescription') {
            dispatch(lensThicknessSlice.actions.clearLensThicknessCalculationData())
            dispatch(lensThicknessSlice.actions.changeView('controls'));
            dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
            recommendLensThickness(tempPrescriptionData);
            dispatch(prescriptionSlice.actions.resetData());
            return;
        }

        if (tempLensThickness) dispatch(userSlice.actions.saveLensThickness(tempLensThickness));
        if (lensRecommendation?.pair1 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair1,
                pair: LensPairType.PAIR1
            }))
        }
        if (lensRecommendation?.pair2 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair2,
                pair: LensPairType.PAIR2
            }))
        }
        history.push(routes.summary.path);
    }

    const recommendLensThickness = (prescription: any) => {
        const sphere = prescription.values.sphere;
        const thickness = getRecommendedLensThickness({
            od: sphere.od,
            os: sphere.os
        }, lensThicknessRules)
        recommendSubProductByIndex(LensPairType.PAIR1, thickness);
        recommendSubProductByIndex(LensPairType.PAIR2, thickness);
    }

    const recommendSubProductByIndex = (pair: LensPairType, index: number) => {
        if (!lensRecommendation) return;
        if (!lensRecommendation[pair]! || !lensRecommendation[pair]?.correct) return;
        const correct = lensRecommendation[pair]?.correct;
        if (!correct || !correct.product || !correct.product.subProducts) return;
        const subProducts = filterSubProductsWithEnabledIndex(
            correct.product?.subProducts.filter(x => x.label === correct.subProduct?.label),
            lensThicknessSettings
        );
        let recommended = getRecommendedSubProductByIndex(subProducts, index);
        if (!recommended) return;
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            brand: correct?.brand,
            product: correct?.product,
            subProduct: recommended,
            manual: true,
            pair: pair
        }));
    }

    const isWithDemo = (family: any) => {
        if (Array.isArray(family)) {
            if (family && family.length > 0 && family[0].product && (family[0].product.demo || []).length > 0) return true;
            return false;
        }
        if (family && family.product && (family.product.demo || []).length > 0) return true;
        return false;
    }

    const getInitialDemo = () => {
        if (!lensRecommendation) return;
        const pair: iLensRecommendationPair = lensRecommendation[activePair] as iLensRecommendationPair;
        const { correct, protect, enhance } = pair;
        if (isWithDemo(correct)) return { family: LensFamilyType.CORRECT, data: correct };
        if (isWithDemo(protect)) return { family: LensFamilyType.PROTECT, data: protect[0] };
        if (isWithDemo(enhance)) return { family: LensFamilyType.ENHANCE, data: enhance };
    }

    const demoProducts = () => {
        const initialDemo: any = getInitialDemo();
        if (!initialDemo) return;
        dispatch(productsSlice.actions.selectBrandTodemo(initialDemo.data.brand))
        dispatch(productsSlice.actions.selectProductTodemo(initialDemo.data.product))
        if (!recommendationToDemo || !recommendationToDemoBrands) return;
        dispatch(productDemoSlice.actions.setRecommendationDemo(recommendationToDemo));
        dispatch(productDemoSlice.actions.selectLensFamily(initialDemo.family));
        dispatch(productDemoSlice.actions.setDemoFrom(routes.lensConsultation));
        dispatch(productDemoSlice.actions.setRecommendationDemoBrands(recommendationToDemoBrands));
        goto.route(routes.demonstration);
    }

    const disableDemo = () => {
        if (!recommendationToDemo) return true;
        const {
            correct,
            protect,
            enhance
        } = recommendationToDemo;
        if (!correct && !protect && !enhance) return true;
        return false;
    }

    // const isSunExp = () => experience === ExperienceType.SUN;
    const bottom = () => (
        // <div className={E('bottom', (isSunExp() && sliderPage === 0) ? 'remove' : '')}>
        <div className={E('bottom')}>
            <Button
                // experience={experience}
                text={t('lens_consultation_lc_demo')}
                disabled={disableDemo()}
                icon={eyeIconGif()}
                type="simple"
                click={() => demoProducts()} />
            <Button
                // disabled={disableNextButton()}
                disabled={(sliderPage === 3 && accountDetails?.shop?.country === 'be') ? controlNextButton : disableNextButton()}
                text={t('lens_consultation_lc_save_&_next')}
                icon={
                    <CircleArrow type='right' background='#eee' />
                }
                type="secondary"
                outlined={false}
                click={() => saveAndNext()}
            />
        </div>
    )

    const transitionInAnimationComplete = () => {
        setTransitionInAnimationComplete(true);
    }

    const sunOnSaveHandler = () => {
        // if (solution) {
        //     dispatch(lensConsultationSun.actions.setSolutionId(solution.id))
        // }

        // if(!lensRecommendation?.pair1?.correct){
        const brandId = 'crizal';
        const brand = tempList
            .find((f: any) => f.id === LensFamilyType.ENHANCE).brands
            .find((b: iProductBrand) => b.id === brandId);
        const product = brand.products.find((p: iProduct) => p.id === 'crizal_sun_xprotect');
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.ENHANCE,
            brand,
            product,
            manual: true,
            pair: LensPairType.PAIR1
        }));
        // dispatch(lensConsultationSlice.actions.setSliderPage(3));
        // setAnimateLensThickness(true);
        // saveLensConsultationSessionDuration();
        setFadeOutSun(true);
        setTimeout(() => {
            saveAndNext();
        }, 500);
    }

    return (
        // <div className={B(experience)}>
        <div className={B()}>
            {/* <SideBoxBordered type={isSunExp() ? 'plain' : 'default'}> */}
            <SideBoxBordered>
                {top()}
                <LensAnimation />
                {!isTransitionInAnimationComplete && !isAnimateLensThickness &&
                    <LensTransitionInAnimation onComplete={transitionInAnimationComplete} />
                }
                {isAnimateLensThickness &&
                    <LensTransitionOut type={transitionOutType} onComplete={transitionInAnimationComplete} />
                }
                {bottom()}
                {/* {isSunExp() &&
                    <LensConsultationSideSun
                        fadeOut={fadeOutSun}
                        onSave={sunOnSaveHandler} />
                } */}
            </SideBoxBordered>
        </div>
    )
}

export default LensConsultationSideV2;