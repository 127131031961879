import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import ematDetails from '../../../interface/emat-details';
import appSlice, { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
interface Props {
    data?: ematDetails;
}
const EmatCard : React.FC<Props>  = (
    data,
) =>  {
    // VARIABLES
    // HOOKS
    const [B, E] = useBEM('emat-card');
    const { t } = useTranslation(useSelector(getApp));
    const dispatch = useDispatch();
    // METHODS
    const returnImage = (value: string="", condition: boolean = false, file:string="") =>{
        switch(value.toLowerCase()){
            case 'pdf':
            case 'document':
                if(condition) return 'document-white'
                return 'document';
            case 'video':
                if(condition) return 'play-white'
                return 'play';
            case 'image':
                if(condition) return 'camera-white'
                return 'camera';
            default:
                if(file.toLowerCase() === 'jpg' || file.toLowerCase() === 'png') return 'camera';
                return value
        }
    }
    const openModal = (list:any) => {
        dispatch(appSlice.actions.setEmatModal({open: true, detail: list}));  
    }
    const onTranslate = (word:string="", file:string="") =>{
        switch(word.toLowerCase()){
            case 'pdf':
            case 'document':
                return t('home_hom_pdf');
            case 'video':
                return t('home_hom_video');
            case 'photos':
            case 'image':
                return t('home_hom_photos');
            default:{
                if(file.toLowerCase() === 'jpg' || file.toLowerCase() === 'png') return t('home_hom_photos');
                else return word;
            }
                
        }
    }
    return (
    <div className={B()} onClick={()=> openModal(data.data)}>
        <div className={E('header')} >
            <label>
                <Icon name={returnImage(data.data?.label, false, data.data?.type)} type="png" className="icon-file"/>
                <h5>{onTranslate(data.data?.label, data.data?.type)}</h5>
            </label>
            
            <h5>{moment(data.data?.created_date, "YYYY-MM-DD").format("MM/DD/YYYY").toString()}</h5>
        </div>
        <div className={E('body')}>
            <p>{data.data?.description}</p>            
            <Icon name="see-more"></Icon>
        </div>
    </div>
    )
}

export default EmatCard;