import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    on: boolean,
    onChange?: (value: boolean) => void
}

const ToggleRadio : React.FC<Props>  = ({
    onChange,
    on
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('toggle-radio');

    // METHODS
    const click = () => {
        if(onChange)onChange(!on);
    }

    return (
        <div className={B( on ? 'on' : 'off')} onClick={click}>
            <span className={E('slide')}></span>
        </div>
    )
}

export default ToggleRadio;