interface iReplaceOptions {
    from: number | string | undefined | null | number,
    to: number | string | undefined | null | number,
}

const DeepReplaceObjectValue = (({
    from,
    to
}: iReplaceOptions) => {

    const transformArray = (obj: any[]) => {
        let newObject:any[] = [];
        obj.forEach( item => {         
            let newValue = item === from ? to : item;
            if(typeof item === 'object')newValue = replace(item);

            newObject = [
                ...newObject,
                newValue
            ]
        });
        return newObject
    }

    const transformObject = (obj: any) => {
        const entries = Object.entries(obj);
        let newObject = {};

        entries.forEach(([key, value]) => {
            let newValue = value === from ? to : value;
            if(typeof value === 'object')newValue = replace(value);
            newObject = {
                ...newObject,
                [key]: newValue
            }
        });
        return newObject;
    }

    const replace = (obj: any) => {
        if(typeof obj !== 'object' || !obj)return obj;        
        return Array.isArray(obj) ? transformArray(obj) : transformObject(obj);
    }

    return {
        replace
    }
});

export default DeepReplaceObjectValue;