import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    message?: string,
}

const Tooltip : React.FC<Props>  = ({
    message,
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('tooltip-box');

    return (
        <div className={B()}>
            {message}
        </div>
    )
}

export default Tooltip;