import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    children: React.ReactNode
}

const SideBoxBordered : React.FC<Props>  = ({
    children
}) =>  {
    // VARIABLES
    const [B] = useBEM('side-box-bordered');
    // HOOKS

    // METHODS
    

    return (
        <div className={B()}>
            {children}
        </div>
    )
}

export default SideBoxBordered;