import iLensDemoProtect from "../../interface/lens-demo-protect";


const PROTECT_LENS_DEMO: iLensDemoProtect[] = [

    { id: 'lensTransitionGreen',url:'lens-transitions-green' },
    { id: 'lensTransitionAmber',url:'lens-transitions-amber' },
    { id: 'lensTransitionAmethyst',url:'lens-transitions-amethyst' },
    { id: 'lensTransitionBrown',url:'lens-transitions-brown' },
    { id: 'lensTransitionEmerald',url:'lens-transitions-emerald' },
    { id: 'lensTransitionGrey',url:'lens-transitions-grey' },
    { id: 'lensTransitionSapphire',url:'lens-transitions-sapphire' },
    
    // Experio polarized
    { id: 'lensPolarizedAmber',url: 'lens-polarized-amber' },
    { id: 'lensPolarizedBlueInk',url:'lens-polarized-blue-ink' },
    { id: 'lensPolarizedBluePurple',url:'lens-polarized-blue-purple' },
    { id: 'lensPolarizedBlueSky',url:'lens-polarized-blue-sky' },
    { id: 'lensPolarizedBrown',url:'lens-polarized-brown' },
    { id: 'lensPolarizedGrey',url:'lens-polarized-grey' },
    { id: 'lensPolarizedGreyGreen',url:'lens-polarized-grey-green'},
    { id: 'lensPolarizedHerbal',url:'lens-polarized-herbal' },
    { id: 'lensPolarizedViolet',url:'lens-polarized-violet' },
    
    { id: 'lensTintedBlack',url:'lens-tinted-black' },
    { id: 'lensTintedBlueInk',url:'lens-tinted-blue-ink' },
    { id: 'lensTintedBrown',url:'lens-tinted-brown' },
    { id: 'lensTintedBurgundy',url:'lens-tinted-burgundy' },
    { id: 'lensTintedGreyGreen',url:'lens-tinted-grey-green' },
    { id: 'lensTintedGrey',url:'lens-tinted-grey' },
    { id: 'lensTintedHerbal',url:'lens-tinted-herbal' },
    { id: 'lensTintedNewBrown',url:'lens-tinted-new-brown' },
    
    { id: 'lensMirrorBronzeShadow',url:'lens-mirror-bronze-shadow' },
    { id: 'lensMirrorBronze',url:'lens-mirror-bronze' },
    { id: 'lensMirrorCandy',url:'lens-mirror-candy' },
    { id: 'lensMirrorForestGreen',url:'lens-mirror-forest-green' },
    { id: 'lensMirrorGoldPink',url:'lens-mirror-gold-pink' },
    { id: 'lensMirrorGreenShadow',url:'lens-mirror-green-shadow' },
    { id: 'lensMirrorKaki',url:'lens-mirror-kaki' },
    { id: 'lensMirrorMauveSilver',url:'lens-mirror-mauve-silver' },
    { id: 'lensMirrorNewBlue',url:'lens-mirror-new-blue' },
    { id: 'lensMirrorNewSilver',url:'lens-mirror-new-silver' },
    { id: 'lensMirrorOceanBlue',url:'lens-mirror-ocean-blue' },
    { id: 'lensMirrorOrange',url:'lens-mirror-orange' },
    { id: 'lensMirrorParma',url:'lens-mirror-parma' },
    { id: 'lensMirrorPinkSilver',url:'lens-mirror-pink-silver' },
    { id: 'lensMirrorSilverShadow',url:'lens-mirror-silver-shadow' },
    { id: 'lensMirrorSilver',url:'lens-mirror-silver' },
    { id: 'lensMirrorSkyBlueSilver',url:'lens-mirror-sky-blue-silver' },
    { id: 'lensMirrorViolet',url:'lens-mirror-violet' },
]   

export default PROTECT_LENS_DEMO;