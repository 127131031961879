import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/ui/Button'
import { variluxSteps } from '../../../constants/variluxG9/variluxSteps'
import { iView } from '../../../store/interface/variluxG9.interface'
import { getApp } from '../../../store/slices/app.slice'
import variluxgG9Slice, { getVariluxG9 } from '../../../store/slices/variluxG9.slice'
import PrescriptionProductTypeVarilux from '../../../types/prescription-product-varilux.type'
import useBEM from '../../../utils/hooks/useBEM'
import useTranslation from '../../../utils/hooks/useTranslation'
import useVariluxDisableButton from '../../../utils/hooks/useVariluxDisableButton'
import useVariluxMatrix from '../../../utils/hooks/useVariluxMatrix'
import ProgressBar from './ProgressBar'

const VarNavbarBottom = () => {
    //variables
    const dispatch = useDispatch()
    //hooks
    const [B, E] = useBEM('varilux-navbar-bottom')
    const { tg9 } = useTranslation(useSelector(getApp));
    const { prescriptionVarilux, view } = useSelector(getVariluxG9)
    const [stepProgress, setStepProgress] = useState<number>(1);
    const { disableButtonNav } = useVariluxDisableButton()
    const { productSelected, sphereMean, addition } = prescriptionVarilux
    const { getAddSegmentation, getAmetropia, getCategoryPercentages } = useVariluxMatrix()


    //functions
    const activeStep = (v: iView) => {
        if (view === v) {
            return 'active'
        }
    }

    const changeView = (v: iView, i: number) => {
        setStepProgress(i + 1);
        if (v === 'demonstrations') {
            if (productSelected === PrescriptionProductTypeVarilux.NONE) {
                dispatch(variluxgG9Slice.actions.setLensCategorySelected(false))
            }
            if (sphereMean && addition && productSelected !== PrescriptionProductTypeVarilux.NONE) {
                const add = getAddSegmentation(addition)
                const ametropia = getAmetropia(sphereMean)
                if (ametropia !== null && add !== null) {
                    const categoryPercentages = getCategoryPercentages(ametropia, add, productSelected)
                    dispatch(variluxgG9Slice.actions.setEyeCondition(ametropia))
                    dispatch(variluxgG9Slice.actions.setAddSegmentation(add))
                    dispatch(variluxgG9Slice.actions.setCategoryPercentages(categoryPercentages))
                    dispatch(variluxgG9Slice.actions.setLensCategorySelected(true))
                }
            }
        }
        dispatch(variluxgG9Slice.actions.changeView(v))
    }

    return (
        <div className={B()}>
            {/* buttons */}
            <div className={E('button-container')}>
                {
                    variluxSteps.map((step, i) =>
                        <Button
                            text={tg9(step.label[0], step.label[1])}
                            className={E('button', activeStep(step.id))}
                            outlined={false}
                            type='simple'
                            key={i}
                            click={() => changeView(step.id, i)}
                            disabled={disableButtonNav(step.id)}
                        />
                    )
                }
            </div>


            {/* progress bar */}
            <ProgressBar />
        </div>
    )
}

export default VarNavbarBottom