import { useSelector } from "react-redux";
import iLensRecommendationPair from "../../interface/lens-recommendaiton-pair.interface";
import iAppStore from "../../store/interface/app-store.interface";
import iProductCombi from "../../store/interface/product-combi.interface";

type hookType = {
    isAdvancePricing: boolean,
    getPriceCombination: (params: {
        correct: string,
        enhance: string,
        protect1: string,
        protect2: string,
        index: number,
    }) => iProductCombi,
    getPairPriceCombination: (pair: iLensRecommendationPair) => iProductCombi,
    computePrice: (combi: iProductCombi, discountPair: 'p1' | 'p2') => {
        total: number,
        discount: number,
        originalPrice: number
    },
    getAdvancePricingTotal: (recommendation: {
        pair1?: iLensRecommendationPair,
        pair2?: iLensRecommendationPair
    }) => {
        [key: string]: {
            total:number,
            discount:number,
            originalPrice:number
        }
    }
}

const useAdvancePricing = (appStore: iAppStore): hookType => {
    const { 
        accountDetails, 
        productCombi, 
        myCompannion,
        discountSettings
    } = appStore;

    const isAdvancePricing = () => {
        const isAdvance = accountDetails?.advance_pricing ? true : false;
        const isEssilorPrice = myCompannion.essilorRecommend ? true : false;
        return isAdvance && isEssilorPrice;
    }

    const getDiscount = (setting: 'p1' | 'p2', price: number) => {
        const settings = discountSettings.filter( x => x.value === 'p0')[0];
        const p = discountSettings.filter( x => x.value === setting)[0];
        
        if(settings.amountEnabled) return p.discountAmount;
        if(p.discountPercentage === 0)return 0;
        return price * (p.discountPercentage / 100);
    }

    const getPairPriceCombination = (pair: iLensRecommendationPair): iProductCombi => {
        const subProduct = pair?.correct.subProduct;
        let protect1 = '',protect2 = '';

        if(pair.protect && pair.protect.length){
            protect1 = pair?.protect[0].product?.id || '' ;
            protect2 = pair?.protect[1] ? (pair?.protect[1].product?.id || '') : '';    
        }

        const enhance = pair?.enhance ? (pair?.enhance.product?.id || '') : '';

        return getPriceCombination({
            correct: subProduct?.code || '',
            enhance,
            protect1,
            protect2,
            index: subProduct?.index as number
        })
    }

    const getAdvancePricingTotal = (recommendation: {
        pair1?: iLensRecommendationPair,
        pair2?: iLensRecommendationPair
    }): {
        [key: string]: {
            total:number,
            discount:number,
            originalPrice:number
        }
    } => {
        const summary = {
            pair1: {
                total:0,
                discount:0,
                originalPrice:0
            },
            pair2: {
                total:0,
                discount:0,
                originalPrice:0
            }
        }

        if(!recommendation?.pair1)return summary;

        const pair1 = getPairPriceCombination(recommendation?.pair1);
        summary.pair1 = computePrice(pair1, 'p1');

        if(recommendation?.pair2){
            const pair2 = getPairPriceCombination(recommendation?.pair2);
            summary.pair2 = computePrice(pair2, 'p2');    
        }

        return summary
    }

    const getPriceCombination = (params: {
        correct: string,
        enhance: string,
        protect1: string,
        protect2: string,
        index: number,
    }) => {
        const found = productCombi.filter( x => {
            return (
                x.correct_sub === params.correct &&
                x.enhance === params.enhance &&
                x.protect1 === params.protect1 &&
                x.protect2 === params.protect2 &&
                x.index === params.index               
            )
        });
        return found[0];
    }

    const computePrice = (combi: iProductCombi, discountType: 'p1' | 'p2'): {
        total: number,
        discount: number,
        originalPrice: number
    } => {
        if(!combi)return {
            total: 0,
            discount: 0,
            originalPrice: 0
        }

        const {
            protect1_price,
            protect2_price,
            protectModifier,
            correctEnhanceModifier,
            price,
            flatFee
        } = combi;

        const protectModifierValue = parseFloat(protectModifier?.value || '0');
        let protect = (protect1_price + protect2_price);

        protect = protectModifier?.type === 'actual' ? 
            (protect + protectModifierValue) : // if actual addition
            (protect * (protectModifierValue || 1)) // else multiply
        
        const correctEnhanceModifierValue = parseFloat(correctEnhanceModifier?.value || '0');
        const correctEnhance = correctEnhanceModifier?.type === 'actual' ? 
            (price + correctEnhanceModifierValue) : // if actual addition
            (price * (correctEnhanceModifierValue || 1)) // else multiply
        
        const flat = parseFloat(flatFee?.value || '0');

        const originalPrice = (protect + correctEnhance + flat);
        const discount = getDiscount(discountType, originalPrice) as number;
        const discountedPrice = originalPrice - discount;

        return {
            total: discountedPrice,
            discount,
            originalPrice,
        };
    }

    return {
        isAdvancePricing: isAdvancePricing(),
        getPriceCombination,
        getPairPriceCombination,
        computePrice,
        getAdvancePricingTotal
    }

}

export default useAdvancePricing;