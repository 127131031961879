import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../../components/ui/Icon';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import iQuestionOption from '../../../../interface/question-option.interface';
import { getApp } from '../../../../store/slices/app.slice';
import useBEM from '../../../../utils/hooks/useBEM';
import useTranslation from '../../../../utils/hooks/useTranslation';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: iQuestionOption[]) => void
}

const DragAndDropSorter : React.FC<Props>  = ({
    question,
    onAnswer,
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('drag-and-drop-sorter');
    const [answer, setAnswer] = useState(new Array(question.options?.length).fill(null));
    const { t } =  useTranslation(useSelector(getApp));
    const [drag, setDrag] =  useState(new Array(question.options?.length).fill(''))
    const [drop, setDrop] =  useState(new Array(question.options?.length).fill(''))

    // HOOKS
    useEffect(() => {
        if(question.answer){
            (question.answer as iQuestionOption[]).map((o:any, index)=>{
                if(o === null || o===undefined || o === "")  drag[index] = '';
                else  {
                    drag[index] = o.value;
                    answer[index] = o;
                }
            })
            setDrag([...drag]);
            setAnswer([...answer]);
        }

      }, [drop]);

    // METHODS
    const allowDrop = (event: any) => {
        event.preventDefault();
    }

    const onDrag = (event: any, option: any) => {
        event.dataTransfer.setData('option', JSON.stringify(option));
    }


    const onDrop = (event: any, index: number) => {
        event.preventDefault();
        const data = JSON.parse(event.dataTransfer.getData("option"));
        answer[index] = data;
        setAnswer([...answer]);
        drag[index]=answer[index].value;
        setDrag([...drag]);
        if(onAnswer)onAnswer([...answer]);


    }
    //remove card
    const removeDrop = (index: number) =>{
        let option:any;
        if(question.answer) {
            const answer = (question.answer as iQuestionOption[])[index];
            option = answer ?  answer : '';
        }
        const newList = answer.map((value:any = '', index) =>{
            if(value !== null && value !== undefined && value.value === option.value){
                answer[index] = null;
                // drag[index]=false;
                drag[index]='';
            }
            else return value
        })
        setDrag([...drag]);
        setAnswer([...newList]);
        if(onAnswer)onAnswer([...answer]);
    }

    const getValue = (index: number) => {
        if(question.answer) {
            const answer = (question.answer as iQuestionOption[])[index];
            if(answer) {
                return <div className='label'>
                    {t(answer.label)}
                </div>
            }
        }
        return null;
    }

    const getImage = (index: number) => {
        if(question.answer) {
            const answer = (question.answer as iQuestionOption[])[index];
            if(answer) {
                return (
                    <Icon
                        type="image"
                        name={answer.image || ''}
                        className='image'
                        background={true}
                        position='center'
                    />
                )
            }
        }
        return null;
    }

    const isAssigned = (option: iQuestionOption) => {
        const answers = question.answer as iQuestionOption[];
        if(!answers) return false;
        const found = drag.filter( o => o && o === option.value);
        return found.length > 0;
    }

    //ON CLICK FUNCTIONS
    const onPick = (event: any, option: any, index: any) =>{
        event.preventDefault();
        if(drag.indexOf(option.value) > -1 ) return;

        const data = option;
        const indexValue = drag.indexOf('')
        answer[indexValue] = data;
        setAnswer([...answer]);
        drag[indexValue]=answer[indexValue].value;
        setDrag([...drag]);

        if(onAnswer)onAnswer([...answer]);
    }

    return (
        <div className={B()}>

            <div className={E('drop')}>
                {
                    question.options && question.options.map( (option, index) => (
                        <div
                            key={index}
                            // onDrop={(event) => onDrop(event, index)}
                            // onDragOver={(event) => allowDrop(event)}
                            className={E('drop-item' ,[drag[index] ? 'with-value' : ''])}>

                            <span>
                                { index + 1}
                            </span>
                            {
                                drag[index] &&
                                <div className={E('drop-item' ,[drag[index]? 'remove-card' : ''])} onClick={()=>removeDrop(index)}>

                                </div>
                            }

                            { drag[index] && getImage(index) }

                            { drag[index] && getValue(index) }

                        </div>
                    ))
                }
            </div>


            <div className={E('drag')}>
                {
                    question.options && question.options.map( (option,index) => (

                        <div
                            onClick={(event) => onPick(event, option, index)}
                            // draggable={ drop[index] === option.value ? false : true}
                            className={E('drag-item' , [ isAssigned(option) ? 'with-value' : ''])} key={option.value}
                        >
                            <Icon
                                type="image"
                                name={option.image || ''}
                                className='image'
                                background={true}
                                position='center'
                            />

                            <span className='label'>
                                {t(option.label)}
                            </span>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}

export default DragAndDropSorter;
