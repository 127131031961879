import React, { SyntheticEvent, useState } from 'react';
import Button from '../../../components/ui/Button';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    type?: 'actual' | 'percent',
    value: string,
    currency: string,
    onChange?: (value: string, type?: 'actual' | 'percent') => void
}

const AdvancePriceModifierInput : React.FC<Props>  = ({
    type,
    value,
    currency,
    onChange
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('advance-price-modifier-input');

    // HOOKS

    // METHODS
    const changeType = (type: 'actual' | 'percent') => {
        if(onChange)onChange(value, type)
    }

    const onValueChange = (e: any) => {
        const newValue = e.target.value;
        if(onChange)onChange(newValue, type)
    }

    return (
        <div className={B()}>
            {
                type && 
                <>
                    <Button text={currency} type={type === 'actual' ? 'primary' : 'secondary'}                    
                        click={() => changeType('actual')}
                    />            
                    <Button text="%" type={type === 'percent' ? 'primary' : 'secondary'}
                        click={() => changeType('percent')}
                    />
                </>
            }

            <input type="number" value={value} onChange={onValueChange} placeholder="0"/>
        </div>
    )
}

export default AdvancePriceModifierInput;