import iNeedExplorerSummaryDetails from "../interface/need-explorer-summary-details";

const iNeedExplorerSummary: iNeedExplorerSummaryDetails[] = [
    {
        label: "questionnaires_q_screen_usage_summ_age",
        icon: "age",
        type: "1",
        answer: undefined,
        question: "1.2"
    },
    {
        label: "questionnaires_q_screen_usage_summ_gend",
        icon: "gender",
        type: "1",
        answer: undefined,
        question: "1.1"
    },
    {
        label: "questionnaires_q_screen_usage_summ_vtype",
        icon: "vision",
        type: "2",
        answer: undefined,
        question: "1.3"
    },
    {
        label: "questionnaires_q_screen_usage_spend_most",
        icon: "mostSpend",
        type: "2",
        answer: undefined,
        question: "1.6"
    },
    {
        label: "questionnaires_q_screen_usage_spend_main_points",
        icon: "main pain",
        type: "2",
        answer: undefined,
        question: "2.4"
    }

]


export default iNeedExplorerSummary;