import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../store/slices/app.slice';
import useTranslation from '../../utils/hooks/useTranslation';
import Arrow from './Arrow';

interface Props {
    title: string,
    rightTitle?: React.ReactNode,
    type?: 'parent' | 'child',
    selected?: boolean,
    className?: string,
    clicked?: (expanded: boolean) => void
    rightTitleClicked?: (expanded: boolean) => void,

}

const Accordion : React.FC<Props>  = ({
    title,
    children,
    rightTitle,
    type = 'parent',
    className,
    selected = false,
    clicked,
    rightTitleClicked,
}) =>  {
    // VARIABLES
    const [open, setOpen] = useState(false);
    const isRightTitleClick = useRef(false);
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const onClickHandler = () => {
        if(isRightTitleClick.current){
            isRightTitleClick.current = false;
            return;
        }        
        if(clicked)clicked(!open);
    }

    const onRightTitleClickHandler = () => {
        isRightTitleClick.current = true;
        if(rightTitleClicked){
            rightTitleClicked(!rightTitleClicked);            
        }
    }

    const onToggle = () => {
        isRightTitleClick.current = true;
        setOpen(!open);
    }

    return (
        <div className={`accordion accordion--${type} ${className}`}>
            <div 
                onClick={onClickHandler}
                className={`accordion__head ${open ? 'accordion__head--open' : ''} ${selected ? 'accordion__head--selected' : ''}`}>
                
                <label>{t(title)}</label>


                { rightTitle && 
                    <div className="accordion__right-title" onClick={onRightTitleClickHandler}>
                        {rightTitle}
                    </div>
                }

                { children && 
                    <div onClick={onToggle} className={`accordion__arrow-wrapper ${open ? 'up' : 'down'}`}>
                        <Arrow type={open ? 'up' : 'down'} size=".75rem"/> 
                    </div>
                }
            </div>
            {
                open && children &&
                <div className="accordion__body">
                    {children}
                </div>
            }
            
        </div>
    )
}

export default Accordion;