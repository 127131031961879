import React, { JSXElementConstructor, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SummaryTemplate from '../../components/email-template/SummaryTemplate';
import Modal from '../../components/Modal';
import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import routes from '../../constants/routes.constant';
import iLensRecommendationPair from '../../interface/lens-recommendaiton-pair.interface';
import appSlice, { getApp } from '../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../store/slices/lens-consultation.slice';
import summarySlice, { getSummary } from '../../store/slices/summary.slice';
import userSlice, { getUser } from '../../store/slices/user.slice';
import LensPairType from '../../types/lens-pair.type';
import useBEM from '../../utils/hooks/useBEM';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../utils/hooks/useTranslation';
import Utils from '../../utils/Utils';
import { saveToPdf, sendMail, SummaryExportType } from '../../utils/misc';
import SummaryDetails from './components/SummaryDetails';
import SummarySide from './components/SummarySide';
import ExperienceType from '../../types/experience.type';
import SvgIcon from '../../components/ui/svg-icon/SvgIcon';
import useAdvancePricing from '../advance-price-setting/useAdvancePricing';
import GenericToolTip from '../../components/ui/GenericToolTip';
import useGoto from '../../utils/hooks/useGoto';
import { $CombinedState } from 'redux';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';
import useDisableCountry from '../../utils/hooks/useDisableCountry';

interface Props {

}


const Summary: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM('summary');
    const goto = useGoto()
    const [heightRef, setHeightRef] = useState(0)
    const [scrollVisible, setScrollVisible] = useState("")
    const divRef = useRef<HTMLDivElement>(null)
    const [addOnHover, setAddOnHover] = useState(false);
    const [addOnClick, setAddOnClick] = useState(false);
    const [discountHover, setDiscountHover] = useState(false);
    const [discountClick, setDiscountClick] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);
    const [priceOn, setPriceOn] = useState(false);
    const { sessionStart, isValidated } = useSelector(getLensConsultation);
    const [isValidate, setIsValidate] = useState(isValidated);
    const [isShowExportModal, setShowExportModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const ga = useGoogleAnalytics();
    const { getDateTimeDifference } = Utils();
    const timestamp = useRef<Date>();
    const user = useSelector(getUser);
    const currentLensConsultation = useSelector(getLensConsultation);
    const { summaryList1, summaryList2, summaryNotes } = useSelector(getSummary);
    const summaryContainer = useRef(null);
    const { token, globalInformation } = useSelector(getApp);
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const { id, lensConsultation } = user;
    const [notes, setNotes] = useState(summaryNotes);
    const {
        isAdvancePricing,
        getAdvancePricingTotal
    } = useAdvancePricing(appStore);
    const {
        lensRecommendation,
        breakdown
    } = currentLensConsultation;
    const {
        accountDetails,
        experience,
    } = appStore;
    const [advancePrice, setAdvancePrice] = useState<any>();
    const [isShowPriceBreakDown, showPriceBreakDown] = useState(false);
    const country = accountDetails?.shop.country;
    const [qrCountry, setQRCountry] = useState('');
    const [hide] = useDisableCountry(['in', 'in2', 'gr']) 

    // HOOKS
    useOnClickOutside(divRef, () => setShowToolTip(false))
    useEffect(() => {
        if (!lensRecommendation || !isAdvancePricing) return;
        setAdvancePrice(getAdvancePricingTotal(lensRecommendation));
    }, [])

    useEffect(() => {
        timestamp.current = new Date();
        return () => {
            saveSummarySessionDuration();
            saveLensConsultationSessionDuration();
            getSummaryDetails();
        }
    }, [])

    useEffect(() => {
        if (country) {
            if (country.length > 2) return setQRCountry(country.slice(0, -1));
            return setQRCountry(country);
        }
    }, [])

    // METHODS
    const getSummaryDetails = () => {
        if (lensRecommendation?.pair1 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair1,
                pair: LensPairType.PAIR1
            }))
            dispatch(userSlice.actions.saveSummary1(summaryList1));
        }
        if (lensRecommendation?.pair2 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair2,
                pair: LensPairType.PAIR2
            }))
            dispatch(userSlice.actions.saveSummary2(summaryList2));
        }

    }

    const modify = () => {
        history.push(routes.lensConsultation.path);
    }


    const saveSummarySessionDuration = () => {
        if (!timestamp.current) return;
        const timediff = getDateTimeDifference(timestamp.current, new Date());
        ga.event({
            category: 'Lens Consulation',
            action: 'Summary',
            label: timediff
        });
        timestamp.current = undefined;
    }

    const saveLensConsultationSessionDuration = () => {
        if (!sessionStart) return;
        const timediff = getDateTimeDifference(new Date(sessionStart), new Date());
        ga.event({
            category: 'Lens Consulation',
            action: 'Completion',
            label: timediff
        });
        dispatch(lensConsultationSlice.actions.sessionStart(undefined));
    }

    const validate = () => {
        setIsValidate(!isValidate);
        dispatch(lensConsultationSlice.actions.validate(!isValidate));
        dispatch(userSlice.actions.savePrefilledData({ ...currentLensConsultation, isValidated: !isValidate }));
        dispatch(userSlice.actions.saveSummary1(summaryList1));
        dispatch(userSlice.actions.saveSummary2(summaryList2));

        if (!isValidate && user.sessionStart) {
            const products = getFinalProducts(LensPairType.PAIR1, lensRecommendation);
            const action = `Engagement - ${products.join(', ')}`;
            const timediff = getDateTimeDifference(new Date(user.sessionStart), new Date());

            ga.event({
                category: 'Patient Journey ',
                action,
                label: timediff
            });
        }

    }

    const getFinalProducts = (pairType: LensPairType, data: any) => {
        const pair: iLensRecommendationPair = data[pairType];
        const products: string[] = []
        if (!pair) return products;

        const correct = pair.correct;
        const protect = pair.protect;
        const enhance = pair.enhance;

        if (correct && correct.product) products.push(correct.product.label);
        if (protect && protect.length > 0) {
            products.push(protect.map(x => x.product?.label).join(', '));
        }
        if (enhance && enhance.product) products.push(enhance.product.label);
        return products;
    }

    const showExportModal = () => {
        setShowExportModal(true);

        ga.event({
            category: 'Lens Consultation - Summary',
            action: 'Button Click',
            label: 'Export'
        });

        setScrollVisible("-visible")

        saveSummarySessionDuration();
    }

    const getPdfHeight = (scrollHeight: number) => {
        if (scrollHeight > heightRef) setHeightRef(scrollHeight)
        return heightRef
    }

    const exportSummary = (type: string) => {
        ga.event({
            category: 'Lens Consultation - Export',
            action: 'Button Click',
            label: type
        });

        dispatch(summarySlice.actions.saveSummaryNotes(notes || ''));

        takeExportSummaryAction(type);
        saveLensConsultationSessionDuration();
    }

    const closeExportModal = () => {
        setShowExportModal(false)
        setScrollVisible("")
    }

    const takeExportSummaryAction = (type: string) => {
        switch (type) {
            case SummaryExportType.QRCODE: // qr code
                break;
            case SummaryExportType.PRINT:

                saveToPdf({
                    inputDom: summaryContainer.current,
                    pdfName: `${id}_lens_consultation_summary_${Date.now()}.pdf`,
                    pageOptions: { containerWidth: !onNull(summaryList2) ? '1000px' : '1800px' }
                })

                break;
            case SummaryExportType.EMAIL:
                sendMail(globalInformation.email, `Lens Consultation Summary for ${id},`, token ?? '', <SummaryTemplate appStore={appStore} summaryList1={summaryList1} summaryList2={summaryList2} summaryNotes={notes} />)
                break;
            default: return;
        }
    }

    const onNull = (list: any) => {
        if (!list) return false;
        const arr = list.filter((el: any) => el != null);
        return arr.length > 0;
    }

    const handleChangetext = (event: any) => {
        setNotes(event.target.value);
    }

    const isSunXP = () => experience === ExperienceType.SUN;

    // Using Validate for now:
    // const save = () => {
    //     goto.route(routes.lensProposalBuilder)
    // }

    const addOnToolTip = () => {
        return (
            <div className={E('add-on')}>
                <GenericToolTip
                    position='bottom'
                    show={showToolTip}
                    backgroundColor={[addOnClick || addOnHover ? "#0086D6" : "#fff"]}
                >
                    <div className={`${E('add-on')}-tooltip`}></div>
                    <div className={`${E('add-on')}-menu`}>
                        {addOnClick ?
                            <div className={`${E('add-on')}-menu--additional`} onClick={() => { setAddOnClick(!addOnClick) }}>
                                {`Add-on (${t('lens_thickness_lt_coming_soon')})`}
                                <Icon className='icon-modifier--check' type="png" name="add-on-checkmark" />
                            </div>
                            :
                            <div onClick={() => { setAddOnClick(!addOnClick) }} onMouseEnter={() => { setAddOnHover(true) }} onMouseLeave={() => { setAddOnHover(false) }}>
                                {`Add-on (${t('lens_thickness_lt_coming_soon')})`}
                                <Icon type="svg" name={`add-on-plus${addOnHover ? "-white" : ""}`} />
                            </div>}
                        {discountClick ?
                            <div className={`${E('add-on')}-menu--additional`} onClick={() => { setDiscountClick(!discountClick) }}>
                                {`Discount (${t('lens_thickness_lt_coming_soon')})`}
                                <Icon className='icon-modifier--check' type="png" name="add-on-checkmark" />
                            </div> :
                            <div onClick={() => { setDiscountClick(!discountClick) }} onMouseEnter={() => { setDiscountHover(true) }} onMouseLeave={() => { setDiscountHover(false) }}>
                                {`Discount (${t('lens_thickness_lt_coming_soon')})`}
                                <Icon type="svg" name={`add-on-plus${discountHover ? "-white" : ""}`} />
                            </div>}
                    </div>

                </GenericToolTip>
            </div>
        )
    }

    const isQRCodeExists = (country: string) => {
        try {
            return require(`../../assets/images/qrcode-${country}.png`);
        } catch (err) {
            return null;
        }
    }

    const qrCodeUrl = () => {
        if (!qrCountry) return 'qrcode-sample';
        return isQRCodeExists(qrCountry) ? `qrcode-${qrCountry}` : 'qrcode-sample';
    }

    return (
        <div className={B()}>
            <SummarySide priceOn={priceOn}
                showPriceBreakDown={(show) => showPriceBreakDown(show)}
                isShowPriceBreakDown={isShowPriceBreakDown}
            />
            <div className={E('main')}>
                <div className={E('buttons')}>
                    <span className="icon-container" ref={divRef} onClick={() => { setShowToolTip(true) }}>
                        <Icon className="icon-modifier" type="png" name="panel" />
                        {addOnToolTip()}
                    </span>
                   {
                    !hide && (
                        <Button
                            className={E('price-toggle', priceOn ? experience : '')}
                            experience={experience}
                            weight="normal"
                            text={t(!priceOn ? 'summary_price_off' : 'summary_price_on')}
                            type={!priceOn ? 'secondary' : 'primary'}
                            click={() => setPriceOn(!priceOn)} 
                        />
                    )
                   }
                    {
                        isSunXP() && priceOn &&
                        <Button
                            className={E('price')}
                            experience={experience}
                            text={
                                !isAdvancePricing ?
                                    `${breakdown.summaryTotal} ${breakdown.currency}` :
                                    `${(advancePrice.pair1.total + advancePrice.pair2.total).toFixed(2)} ${breakdown.currency}`
                            }
                            click={() => showPriceBreakDown(true)}
                        />
                    }
                    {
                        !isValidate &&
                        <>
                            <Button
                                experience={experience}
                                weight="normal"
                                text={t('packaged_offer_popup_po_popup_save')}
                                type="primary"
                                icon='check-without-circle-white'
                                circle={false}
                                outlined={false}
                                click={validate}
                            />
                        </>
                    }

                    {
                        isValidate &&
                        <>
                            <Button type="secondary" icon={
                                <SvgIcon
                                    name='close'
                                    circle
                                    bordered
                                    height='1.25rem'
                                />
                            } click={validate} />

                            <Button
                                weight="normal"
                                className="export"
                                text={t('summary_sum_export')}
                                type="secondary"

                                icon={isSunXP() ? 'export-sun' : 'export'}
                                click={showExportModal} />
                        </>
                    }
                </div>
                <div className={`${E('wrapper')}${scrollVisible}`}>
                    <div className={E('height-ref')} style={{ height: getPdfHeight(document.body.scrollHeight) }} ref={summaryContainer}>
                        {onNull(summaryList1) && <SummaryDetails data={summaryList1} title={t('summary_sum_custom_offer')} pair={1} />}
                        {onNull(summaryList2) && <SummaryDetails data={summaryList2} pair={2} />}
                        <div className={E('footer')}>
                            <Icon className={E('qr-code')} name={qrCodeUrl()} type="image"/>
                            <div className={E('cobrand')}>
                                {/* <img alt="Co-Brand" className={E('logo')} src={accountDetails?.shop.shop_logo} />
                                <div className={E('divider')}></div> */}
                                <Icon name="essilor-logo" type="image" />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>

            <Modal show={isShowExportModal} width="62rem" onClose={() => closeExportModal()}>
                <div className={E('export-modal')}>
                    <h2>{t('summary_sum_export')}</h2>
                    <div className="buttons">
                        <Button
                            weight="normal"
                            text={t('summary_sum_print')}
                            type="secondary"
                            icon={isSunXP() ? 'print-sun' : 'print'}
                            click={() => exportSummary(SummaryExportType.PRINT)}
                        />
                        <Button
                            weight="normal"
                            text={t('summary_sum_mail')}
                            type="secondary"
                            icon={isSunXP() ? 'mail-sun' : 'mail'}
                            click={() => exportSummary(SummaryExportType.EMAIL)}
                            disabled={globalInformation.email === ''}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Summary;
