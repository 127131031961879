import React from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import { useDispatch, useSelector } from 'react-redux';
import educationSlice, { getEducation } from '../../../store/slices/education.slice';
import ToggleRadio from '../../ui/ToggleRadio';
import EducationStep from './EducationSteps';
import Arrow from '../../ui/Arrow';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';

interface Props {

}

const EducationContent : React.FC<Props>  = () =>  {
    // VARIABLES
    const { isItemEnabled, isLensEnabled, environment } = useSelector(getEducation);
    const dispatch = useDispatch();

    const [B, E] = useBEM('education-content');
    const { isControlsOpen } = useSelector(getEducation)

    const toggleItem = (value: boolean) => {
        dispatch(educationSlice.actions.toggleItemControl());
    }

    const toggleLens = (value: boolean) => {
        dispatch(educationSlice.actions.toggleLensControl());
    }
    const { t } =  useTranslation(useSelector(getApp));

    const educationSteps = [
        {
            instruction: 'education_edu_step_1_new',
            guide: <>                
                <ToggleRadio on={isItemEnabled} onChange={toggleItem}/>
                <label className={isItemEnabled ? 'active' : ''}>{t('education_edu_activate_the_item_to_see_impact')}</label>
            </>,
        },
        {
            instruction: 'education_edu_step_2_new',
            guide: <>
                <div className="circle">
                    <Arrow type="right" color="#fff" size=".3rem"/>
                </div>
                <label className="active">{t('education_edu_move_the_item_')}</label>
            </>
        },
        {
            instruction: "education_edu_step_3_new",
            guide: <>                
                <ToggleRadio on={isLensEnabled} onChange={toggleLens}/>
                <label className={isLensEnabled ? 'active' : ''}>{t('education_edu_add_essilors_lenses_')}</label>
            </>
        },
        {
            instruction: "education_edu_step_4_new",
            guide: <>
                <div className="circle">
                    <Arrow type="right" color="#fff" size=".3rem"/>
                </div>
                <label className="active">{t('education_edu_move_the_image_on_the_retina')}</label>
            </>
        },
        {
            instruction: 'education_edu_step_5_new',
        },
    ]

    // HOOKS

    // METHODS
    const toggleControls = () => {
        dispatch(educationSlice.actions.toggleControls());
    }

    return (
        <div className={B(environment)}>

            <button 
                className={E('toggle-controls', [!isControlsOpen ? 'collapsed' : ''])} 
                onClick={toggleControls}>
                <span></span>
            </button>


            <EducationStep steps={educationSteps} environment={environment}/>

        </div>
    )
}

export default EducationContent;