import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import { useSelector } from 'react-redux';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
interface Props {
    isVisible: boolean,
    onSelect: (type: 'qrcode' | 'id' | 'waiting-list' | 'patient-list') => void
}

const StartFrom : React.FC<Props>  = ({
    isVisible = true,
    onSelect
}) =>  {
    // VARIABLES
    const [isShow, setShow] = useState(Boolean);
    const { t } = useTranslation(useSelector(getApp));
    // HOOKS
    useEffect(() => {

        const delay = isVisible ? 750 : 0;
        setTimeout(() => {
            setShow(isVisible);
        },delay);
    }, [isVisible]);

    // METHODS

    return (
        <div className={`start-from ${!isShow ? 'start-from--hidden' : ''}`}>
            <h5>{t('home_hom_start_from')}</h5>

            {/* <Button
                outlined={false}
                click={() => onSelect('waiting-list')}
                icon="qrcode"
                text={t('home_hom_waitlist')}
                type="secondary"/>  */}

            <Button
                textSize="small"
                outlined={false}
                click={() => onSelect('patient-list')}
                text={t('masterlicense_patientlist')}
                type="secondary"
            />

            <Button
                textSize="small"
                outlined={false}
                click={() => onSelect('qrcode')}
                icon="qrcode"
                text={t('home_hm_qr_code')}
                type="secondary"
                soon
            />
            <Button
                textSize="small"
                outlined={false}
                click={() => onSelect('id')}
                icon="stacked-group"
                text={t('home_hom_id')}
                type="secondary"/>
        </div>
    )
}

export default StartFrom;
