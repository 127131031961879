import React from 'react';
import useBEM from '../../utils/hooks/useBEM';
import Arrow from './Arrow';

interface Props {
    diameter?: number,
    background?: string,

    // ARROW PROPS
    type: 'right' | 'left' | 'up' | 'down',
    size?: string,
    color?: string,
    thickness?: string,
    className?: string,
    disabled?: boolean,
}

const CircleArrow : React.FC<Props>  = (props) =>  {
    // VARIABLES
    const [B] = useBEM('circle-arrow');

    // HOOKS

    // METHODS
    const customStyle = () => {
        const diameter = props.diameter ? `${props.diameter}rem` : '2rem';
        const background = props.background || '#fff';
        return {
            width: diameter,
            height: diameter,
            backgroundColor: background
        }
    }

    return (
        <div className={B()} style={customStyle()}>
            <Arrow {...props}/>
        </div>
    )
}

export default CircleArrow;