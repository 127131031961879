import iLensDemo from "../../interface/lens-demo";
import ProductDemoType from "../../types/product-demo.type";
import Utils from "../../utils/Utils";
const { lensDemoURL } = Utils();

const sellout = [
    'demo_new_demo_xmirr_l1',
    'demo_new_demo_xmirr_l2',
    'demo_new_demo_xmirr_l3',
    'demo_new_demo_xmirr_l4',
    'demo_new_demo_xmirr_l5',
    'demo_new_demo_xmirr_l6',
    'demo_new_demo_xmirr_l7',
    'demo_new_demo_xmirr_l8',
]



const packshotMirrors: iLensDemo = {
    demo: 'packshot-mirrors',
    demoType: ProductDemoType.SLIDER_IMAGE,
    products: [
        {
            id: 'transitions_style_mirrors',
            label: 'lens_propo_colors_bronze_shadow',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_bronze shadow.jpg'),
            colorID: "lensMirrorBronzeShadow",
            description: sellout
        },
        {
            id: 'standard',
            label: 'lens_propo_colors_bronze',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_bronze.jpg'),
            colorID: "lensMirrorBronze",
            description: sellout
        },
        {
            id: 'standard1',
            label: 'lens_propo_colors_candy',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_candy.jpg'),
            colorID: "lensMirrorCandy",
            description: sellout
        },
        {
            id: 'standard2',
            label: 'lens_propo_colors_forest_green',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_forest green.jpg'),
            colorID: "lensMirrorForestGreen",
            description: sellout
        },
        {
            id: 'standard3',
            label: 'lens_propo_colors_gold_pink',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_gold pink.jpg'),
            colorID: "lensMirrorGoldPink",
            description: sellout
        },
        {
            id: 'standard4',
            label: 'lens_propo_colors_green_shadow',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_green shadow.jpg'),
            colorID: "lensMirrorGreenShadow",
            description: sellout
        },
        {
            id: 'standard5',
            label: 'lens_propo_colors_kaki',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_kaki.jpg'),
            colorID: "lensMirrorKaki",
            description: sellout
        },
        {
            id: 'standard6',
            label: 'lens_propo_colors_mauve_silver',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_mauve silver.jpg'),
            colorID: "lensMirrorMauveSilver",
            description: sellout
        },
        {
            id: 'standard7',
            label: 'Blue',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_blue.jpg'),
            colorID: "lensMirrorNewBlue",
            description: sellout
        },
        {
            id: 'standard8',
            label: 'lens_propo_colors_new_silver',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_new silver.jpg'),
            colorID: "lensMirrorNewSilver",
            description: sellout
        },
        {
            id: 'standard9',
            label: 'lens_propo_colors_ocean_blue',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_ocean blue.jpg'),
            colorID: "lensMirrorOceanBlue",
            description: sellout
        },
        {
            id: 'standard10',
            label: 'lens_propo_colors_orange',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_orange.jpg'),
            colorID: "lensMirrorOrange",
            description: sellout
        },
        {
            id: 'standard11',
            label: 'lens_propo_colors_parma',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_parma.jpg'),
            colorID: "lensMirrorParma",
            description: sellout
        },
        {
            id: 'standard12',
            label: 'lens_propo_colors_pink_silver',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_pink silver.jpg'),
            colorID: "lensMirrorPinkSilver",
            description: sellout
        },
        {
            id: 'standard13',
            label: 'lens_propo_colors_silver_shadow',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_silver shadow.jpg'),
            colorID: "lensMirrorSilverShadow",
            description: sellout
        },
        {
            id: 'standard14',
            label: 'lens_propo_colors_silver',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_silver.jpg'),
            colorID: "lensMirrorSilver",
            description: sellout
        },
        {
            id: 'standard15',
            label: 'lens_propo_colors_sky_blue_silver',
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_skyblue silver.jpg'),
            colorID: "lensMirrorSkyBlueSilver",
            description: sellout
        },
        {
            id: 'standard16',
            label: "lens_propo_colors_violet",
            asset: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_violet.jpg'),
            colorID: "lensMirrorViolet",
            description: sellout
        }
    ]
}

export const transitionsStyleMirrorsDemo: iLensDemo[] = [
    packshotMirrors
];