import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import InputText from '../../components/ui/InputText';
import routes from '../../constants/routes.constant';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import useTranslation from '../../utils/hooks/useTranslation';
import appSlice, { getApp } from '../../store/slices/app.slice';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import useENV from '../../utils/hooks/useEnv';
import ENDPOINTS from '../../constants/endpoints';
import productsSlice, { getProducts } from '../../store/slices/products.slice';
import useProducts from '../../utils/hooks/useProducts';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import { TRANSLATIONS_DEFAULT } from '../../constants/translations.contants';
import eyePNG from '../../assets/svg/eyeIcon.svg'
import useEprom from '../../utils/hooks/useEprom';
import { IProductEprom } from '../../constants/eprom.constants';
interface Props {

}

const LicenseActivation : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('license-activation');
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const goto = useGoto();
    const { t, getTranslations2 } = useTranslation(useSelector(getApp));
    const { getProductData2, getPriceCombination2, mapProducts } = useProducts(useSelector(getProducts));
    const [deviceId, setDeviceId] = useState<string>();
    const [errorMessage, setErrorMessage] = useState('');
    const { accountDetails } = useSelector(getApp);
    const {get, insert, update} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const { lensDemo } = useSelector(getProducts);
    const env = useENV()
    const {
        getEPROMData,
        formatData
    } = useEprom();

    // HOOKS
    useEffect(() => {
        // connect();
        setDeviceId(getDeviceID());
    }, [])

    // METHODS


    const activate = async () => {

        const machineID = await get('applicationStore', 'machineId');
        setErrorMessage('');
        axios.post(ENDPOINTS.activateLicense, {
            username: id,
            password: password,
            machine_id: machineID ? machineID.value : deviceId //'a168bdb7-3ad3-42a6-adb7-2275ba3b1b67'
        }).then((res) => {
            const token = res.data['bearer-token'];
            dispatch(appSlice.actions.setToken(token));
            dispatch(appSlice.actions.saveLicense(password));

            if(!machineID)insert('applicationStore', {
                id: 'machineId',
                value: deviceId
            });
            getAccountDetails();
        })
        .catch(onError);
    }

    const getAccountDetails = () => {
        dispatch(appSlice.actions.loading(true));

        axios.get(ENDPOINTS.account, { headers: {Authorization : true, background : true} }).then(async (res) => {
            
            const { shop } = res.data
            let currentCountry = shop.country
            localStorage.setItem("country", currentCountry)

            dispatch(appSlice.actions.saveAccountDetails(res.data));

            try {
                const {responseData: {data: translationsData}} = await getTranslations2(res.data.shop.region);
                if(!translationsData){
                    onError('No translation data found.');
                    return
                }
                const currentLanguageData = await get('languageStore', 'translations');
                const todo = !currentLanguageData ? insert : update;

                await todo('languageStore', {
                    id: 'translations',
                    value: translationsData.country
                });
                dispatch(appSlice.actions.saveRegions(translationsData.region));
                dispatch(appSlice.actions.setTranslation(TRANSLATIONS_DEFAULT.enUS));
                const products = await getProductData2();
                const mappedProductData = mapProducts(products.responseData.data.products, false);
                dispatch(appSlice.actions.setProducts(mappedProductData));
                dispatch(productsSlice.actions.updateProductList(mappedProductData));
                dispatch(productsSlice.actions.attachedProductDemo({
                    demos: lensDemo,
                    productList: mappedProductData
                }))

                const prices = await getPriceCombination2();
                dispatch(appSlice.actions.setProductCombi({data: prices.responseData.data}));

                const {region, country, eprom} = res.data.shop;

                if(eprom) {
                    const result: any = await getEPROMData(region, country);
                    const formatted = formatData(result.data, country); 
                    dispatch(appSlice.actions.saveEPROMData(formatted));
                } 
                pushGADataLayers(res.data.currency.code, password);
                dispatch(appSlice.actions.loading(false));
                goto.route(routes.home, 100);
            } catch (error: any) {
                onError(error.responseData);
            }

        })
        .catch(onError);
    }

    const pushGADataLayers = (code: string, license: string) => {
        const win: any = window;
        const gaCode = accountDetails?.ga_code;
        win.dataLayer.push({
            //for custom GA Code
            // trackingId: gaCode !== undefined || gaCode !== "" ? gaCode : env.GA,
            trackingId: env.GA,
            // license,
        });
    }
    const onError = (error: any) => {
        dispatch(appSlice.actions.loading(false));

        try {
            setErrorMessage(error.response.data.message);
        } catch (error) {
            console.log(error);
            setErrorMessage('Unknown Error: Please try again');
        }
    }

    const getDeviceID = () => {
        return accountDetails?.machine_id || uuidv4();
    }

    return (
        <div className={B()}>

            <div className={E('form')}>
                <input type="hidden" name="deviceid" value={deviceId}/>


                <Icon name="logo" type="image" className={E('logo')}/>

                <h2 className={E('title')}> {t('license_activation_welc')}</h2>
                <small className={E('note')}>{t('license_activation_note')}</small>

                <div className={E('fields')}>
                    <InputText placeholder={t('license_activation_la_id')} type="light-gray" value={id} onChange={ v => setId(v.target.value.toUpperCase())}/>
                    <InputText
                        placeholder={t('license_activation_la_pass')}
                        type="light-gray"
                        value={password}
                        onChange={ v => setPassword(v.target.value.toUpperCase())}
                    />
                </div>

                <div className={E('bottom')}>
                    <label>
                        { errorMessage }
                    </label>
                    <Button
                        click={() => activate()}
                        text={t('license_activation_la_conct')}
                        icon="right-arrow-circle-white"
                        className={E('connect')}
                        disabled={ (!id || !password) ? true : false }/>
                </div>
            </div>
        </div>
    )
}

export default LicenseActivation;
