import React, { useEffect, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import { useSelector } from 'react-redux';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import Slider from "react-input-slider";
import Icon from '../../../components/ui/Icon';

import lensAll from './../../../assets/images/lens-consulatation/Lens_All.png';
import lensNone from './../../../assets/images/lens-consulatation/Lens_None.png';
import lensCorrectLens from './../../../assets/images/lens-consulatation/Lens_Correct_Active.png';
import lensEnhanceLens from './../../../assets/images/lens-consulatation/Lens_Enhance_Active.png';
import lensProtectLens from './../../../assets/images/lens-consulatation/Lens_Protect_Active.png';
import lensCorrectProtect from './../../../assets/images/lens-consulatation/Lens_Correct_Protect_Active.png';
import lensCorrectEnhance from './../../../assets/images/lens-consulatation/Lens_Correct_Enhance_Active.png';
import lensProtectEnhance from './../../../assets/images/lens-consulatation/Lens_Protect_Enhance_Active.png';

// TINTED LENS

// Transitions
import lensTransitionGreen from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_green.png';
import lensTransitionAmber from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_amber.png';
import lensTransitionAmethyst from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_amethyst.png';
import lensTransitionBrown from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_brown.png';
import lensTransitionEmerald from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_emerald.png';
import lensTransitionGrey from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_grey.png';
import lensTransitionSapphire from './../../../assets/images/lens-consulatation/tinted-lens/transition/transition_saphirre.png';

// Experio polarized
import lensPolarizedAmber from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_amber.png';
import lensPolarizedBlueInk from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_blue_ink.png';
import lensPolarizedBluePurple from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_blue_purple.png';
import lensPolarizedBlueSky from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_blue_sky.png';
import lensPolarizedBrown from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_brown.png';
import lensPolarizedGrey from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_grey.png';
import lensPolarizedGreyGreen from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_grey_green.png';
import lensPolarizedHerbal from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_herbal.png';
import lensPolarizedViolet from './../../../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_violet.png';

import lensTintedBlack from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_black.png';
import lensTintedBlueInk from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_blue_ink.png';
import lensTintedBrown from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_brown.png';
import lensTintedBurgundy from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_burgundy.png';
import lensTintedGreyGreen from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_grey_green.png';
import lensTintedGrey from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_grey.png';
import lensTintedHerbal from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_herbal.png';
import lensTintedNewBrown from './../../../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_newbrown.png';

import lensMirrorBronzeShadow from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_bronze_shadow.png';
import lensMirrorBronze from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_bronze.png';
import lensMirrorCandy from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_candy.png';
import lensMirrorForestGreen from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_forestgreen.png';
import lensMirrorGoldPink from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_goldpink.png';
import lensMirrorGreenShadow from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_green_shadow.png';
import lensMirrorKaki from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_kaki.png';
import lensMirrorMauveSilver from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_mauve_silver.png';
import lensMirrorNewBlue from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_new_blue.png';
import lensMirrorNewSilver from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_new_silver.png';
import lensMirrorOceanBlue from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_ocean_blue.png';
import lensMirrorOrange from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_orange.png';
import lensMirrorParma from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_parma.png';
import lensMirrorPinkSilver from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_pink_silver.png';
import lensMirrorSilverShadow from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_silver_shadow.png';
import lensMirrorSilver from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_silver.png';
import lensMirrorSkyBlueSilver from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_skyblue_silver.png';
import lensMirrorViolet from './../../../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_violet.png';


//Lens Glow
import correctGlow from '../../../assets/videos/lens_glow/Lens_Correct_Glow.mp4'
import protectGlow from '../../../assets/videos/lens_glow/Lens_Protect_Glow.mp4'
import enhanceGlow from '../../../assets/videos/lens_glow/Lens_Enhance_Glow.mp4'

interface Props {

}

type imageType = 'all' | 'correct' | 'protect' | 'enhance' | 'lensTransitionGreen' | 'correctProtect' | 'correctEnhance' | 'protectEnhance' | 'none';

const LensAnimation : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-animation');
    const [activeImage, setActiveImage] = useState<imageType>();
    const [images, setImages] = useState<{
        all: string,
        none:string,
        correct: string,
        protect: string,
        enhance: string,
        correctProtect:string,
        correctEnhance:string,
        protectEnhance:string,
        lensTransitionGreen: string,
        lensTransitionAmber: string,
        lensTransitionAmethyst: string,
        lensTransitionBrown: string,
        lensTransitionEmerald: string,
        lensTransitionGrey: string,
        lensTransitionSapphire: string,

        lensPolarizedAmber: string,
        lensPolarizedBlueInk: string,
        lensPolarizedBluePurple: string,
        lensPolarizedBlueSky: string,
        lensPolarizedBrown: string,
        lensPolarizedGrey: string,
        lensPolarizedGreyGreen: string,
        lensPolarizedHerbal: string,
        lensPolarizedViolet: string,

        lensTintedBlack: string,
        lensTintedBlueInk: string,
        lensTintedBrown: string,
        lensTintedBurgundy: string,
        lensTintedGreyGreen: string,
        lensTintedGrey: string,
        lensTintedHerbal: string,
        lensTintedNewBrown: string,

        lensMirrorBronzeShadow: string,
        lensMirrorBronze: string,
        lensMirrorCandy: string,
        lensMirrorForestGreen: string,
        lensMirrorGoldPink: string,
        lensMirrorGreenShadow: string,
        lensMirrorKaki: string,
        lensMirrorMauveSilver: string,
        lensMirrorNewBlue: string,
        lensMirrorNewSilver: string,
        lensMirrorOceanBlue: string,
        lensMirrorOrange: string,
        lensMirrorParma: string,
        lensMirrorPinkSilver: string,
        lensMirrorSilverShadow: string,
        lensMirrorSilver: string,
        lensMirrorSkyBlueSilver: string,
        lensMirrorViolet: string
    }>();

    const { productFamily, sliderPage } = useSelector(getLensConsultation);
    const { lensRecommendation, activePair, selectedProtect } = useSelector(getLensConsultation);

    const [sliderValue, setSliderValue] = useState(0);
    const [show, setShow] = useState(false);
    const [isProtectXperio, setIsProtectXperio] = useState(false);
    const [withLensSlider, setWithLensSlider] = useState(false);

    const sliderStyle = {
        active: {
            backgroundColor: "CCE7F7",
        },
        thumb: {
            width: 24,
            height: 24,
            opacity: 1,
        }
    }
    
    useEffect(() => {
        if(productFamily){
            setActiveImage(productFamily?.id);
            setWithLensSlider(false);
        } else {
            const protect = getProtectLensImage();
            const imageId = protect ? protect.color?.id as imageType : null;
            setActiveImage(imageId ? imageId : 'all');
            setWithLensSlider(imageId ? true : false);
        }
    }, [productFamily]);

    useEffect(() => { 
        if(!lensRecommendation)return setActiveImage('none')
        if(lensRecommendation &&  sliderPage === 0 && !withLensSlider){
            const correct = lensRecommendation[activePair]?.correct
            const protect = lensRecommendation[activePair]?.protect
            const enhance = lensRecommendation[activePair]?.enhance

            if(correct && validateProtect(protect) && enhance){
                setActiveImage('all')
                return
            }
            if(!correct && validateProtect(protect, false) && !enhance ){
                setActiveImage('none')
                return
            }
            if(correct && validateProtect(protect) && !enhance){
                setActiveImage('correctProtect')
                return
            }
            if(correct && validateProtect(protect, false) && enhance){
                setActiveImage('correctEnhance')
                return
            }
            if(!correct && validateProtect(protect) && enhance){
                setActiveImage('protectEnhance')
                return
            }
            if(!correct && validateProtect(protect, false)&& enhance){
                setActiveImage('enhance')
                return
            }
            if(!correct && validateProtect(protect) && !enhance){
                setActiveImage('protect')
                return
            }
            if(correct && validateProtect(protect, false) && !enhance){
                setActiveImage('correct')
                return
            }
        }
    }, [lensRecommendation, activePair, productFamily, sliderPage, withLensSlider])
    
    // HOOKS
    useEffect(() => {
        setImagesAssets();
        setTimeout(() => {
            setShow(true);
        }, 500);
    },[])

    useEffect(() => {
        const isExperio = isXperio();
        setIsProtectXperio(isExperio);
        setSliderValue(isExperio ? 4 : 100);
    }, [withLensSlider, activePair])

    useEffect(() => {
        const protect = getProtectLensImage();
        const imageId = protect ? protect.color?.id as imageType : null;
        if(!imageId){
            setWithLensSlider(false);
            return;
        }
        setActiveImage(imageId);
        setWithLensSlider(true);
    }, [lensRecommendation, activePair])


    // METHODS

    const validateProtect = (protect:any, exists = true) =>{
        if(exists) return protect && protect?.length > 0
        else return protect &&  protect?.length < 1 || !protect

    }

    const isXperio = () => {
        const protect:any = getProtectLensImage();
        if(!protect)return false;
        return String(protect?.product.label).toLocaleLowerCase().indexOf('xperio') >= 0;
    }

    const setImagesAssets = async () => { 
        setImages({
            lensTransitionAmber,
            lensTransitionAmethyst,
            lensTransitionBrown,
            lensTransitionEmerald,
            lensTransitionGrey,
            lensTransitionSapphire,
            lensTransitionGreen,

            lensPolarizedAmber,
            lensPolarizedBlueInk,
            lensPolarizedBluePurple,
            lensPolarizedBlueSky,
            lensPolarizedBrown,
            lensPolarizedGrey,
            lensPolarizedGreyGreen,
            lensPolarizedHerbal,
            lensPolarizedViolet,

            lensTintedBlack,
            lensTintedBlueInk,
            lensTintedBrown,
            lensTintedBurgundy,
            lensTintedGreyGreen,
            lensTintedGrey,
            lensTintedHerbal,
            lensTintedNewBrown,

            lensMirrorBronzeShadow,
            lensMirrorBronze,
            lensMirrorCandy,
            lensMirrorForestGreen,
            lensMirrorGoldPink,
            lensMirrorGreenShadow,
            lensMirrorKaki,
            lensMirrorMauveSilver,
            lensMirrorNewBlue,
            lensMirrorNewSilver,
            lensMirrorOceanBlue,
            lensMirrorOrange,
            lensMirrorParma,
            lensMirrorPinkSilver,
            lensMirrorSilverShadow,
            lensMirrorSilver,
            lensMirrorSkyBlueSilver,
            lensMirrorViolet,

            correct: lensCorrectLens,
            protect: lensProtectLens, 
            enhance: lensEnhanceLens,
            all: lensAll,
            none:lensNone,
            correctProtect: lensCorrectProtect,
            correctEnhance: lensCorrectEnhance,
            protectEnhance: lensProtectEnhance
        });
    }

    const getProtectLensImage = () => {
        if(!lensRecommendation)return '';
        const protect = lensRecommendation[activePair]?.protect;
        if(!protect || protect.length === 0)return '';
        // return protect[0]?.color?.id as imageType;        
        return protect[protect.findIndex(x=> selectedProtect && x.product?.id === selectedProtect[activePair])];
    }

    const setLensOpacity = (value: number) => setSliderValue(value);

    const generateTransitionLensOpacity = (imageKey:imageType) => {
        if(!withLensSlider || activeImage !== (imageKey as imageType))return {};
        return {
            opacity: sliderValue / (isProtectXperio ? 4 : 100)
        }
    }

    const videoSource = () => {
        if(productFamily?.id === 'correct')return correctGlow
        if(productFamily?.id === 'protect')return protectGlow
        if(productFamily?.id === 'enhance')return enhanceGlow
    }

    if(!images || !show)return null;
    
    return (
        <>
            <div className={B()}>    

                <img src={images.all} alt="" className={E('img')}  style={{
                    opacity: withLensSlider ? 1 : 0,
                }}/>        

                {Object.keys(images).map( (imgKey: string, index: number) => (
                    <img   
                        style={generateTransitionLensOpacity(imgKey as imageType)}
                        src={images[imgKey as imageType]} 
                        alt="" 
                        key={index} 
                        className={E('img', activeImage === (imgKey as imageType) ? 'active' : '')}
                    />                    
                ))}    
            </div> 

            {
                withLensSlider && sliderPage < 3 &&
                <div className={E('slider', isProtectXperio ? 'xperio' : '')}>
                    <span><Icon name="sun-high"/></span>
                    <Slider
                        styles={sliderStyle}
                        axis="y"
                        ystep={1}
                        ymin={isProtectXperio ? 4 : 100}
                        ymax={isProtectXperio ? 1 : 10}
                        y={sliderValue}
                        onChange={({ y }) => {setLensOpacity(y)}}
                    />
                    <span><Icon name="sun-low"/></span>
                </div>
            }

            {productFamily && sliderPage > 0 &&  sliderPage < 3 &&
                <video 
                    className={E('video')}
                    autoPlay={true} loop={true}  muted>
                        <source src={videoSource()} type="video/mp4"/> 
                        Your browser does not support the video tag.
                </video>
            }
            
        </>
        
    )
}

export default LensAnimation;