import React, { useEffect, useRef, useState } from 'react';
import useBEM from '../utils/hooks/useBEM';
import Utils from '../utils/Utils';

//BRANDS
import variluxCard from './../assets/images/varilux-card.png';
import experioCard from './../assets/images/experio-card.jpg';
import blueUVCard from './../assets/images/blue-uv-card.jpg';
import eyezenCard from './../assets/images/eyezen-card.png';
import crizalCard from './../assets/images/crizal-card.png';
import optiFogCard from './../assets/images/optifog-card.png';
import transitionsCard from './../assets/images/transitions-card.png';
import stellestCard2 from './../assets/images/stellest-card2.png';

//icons
import refresh from './../assets/svg/refresh.svg';
import lensMenuBlack from './../assets/svg/lensMenuBlack.svg';
import flip from './../assets/svg/flip.svg';
import hamburger from './../assets/svg/hamburger.svg';

// Prescription
import clearLens from './../assets/images/prescription/Clear_Lens.png';
// import avaOverlayClearLens0 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00000.png';
// import avaOverlayClearLens1 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00001.png';
// import avaOverlayClearLens2 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00002.png';
// import avaOverlayClearLens3 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00003.png';
// import avaOverlayClearLens4 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00004.png';
// import avaOverlayClearLens5 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00005.png';
// import avaOverlayClearLens6 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00006.png';
// import avaOverlayClearLens7 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00007.png';
// import avaOverlayClearLens8 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00008.png';
// import avaOverlayClearLens9 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00009.png';
// import avaOverlayClearLens10 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00010.png';
// import avaOverlayClearLens11 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00011.png';
// import avaOverlayClearLens12 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00012.png';
// import avaOverlayClearLens13 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00013.png';
// import avaOverlayClearLens14 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00014.png';
// import avaOverlayClearLens15 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00015.png';
// import avaOverlayClearLens16 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00016.png';
// import avaOverlayClearLens17 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00017.png';
// import avaOverlayClearLens18 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00018.png';
// import avaOverlayClearLens19 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00019.png';
// import avaOverlayClearLens20 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00020.png';
// import avaOverlayClearLens21 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00021.png';
// import avaOverlayClearLens22 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00022.png';
// import avaOverlayClearLens23 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00023.png';
// import avaOverlayClearLens24 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00024.png';
// import avaOverlayClearLens25 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00025.png';
// import avaOverlayClearLens26 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00026.png';
// import avaOverlayClearLens27 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00027.png';
// import avaOverlayClearLens28 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00028.png';
// import avaOverlayClearLens29 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00029.png';
// import avaOverlayClearLens30 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00030.png';
// import avaOverlayClearLens31 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00031.png';
// import avaOverlayClearLens32 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00032.png';
// import avaOverlayClearLens33 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00033.png';
// import avaOverlayClearLens34 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00034.png';
// import avaOverlayClearLens35 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00035.png';
// import avaOverlayClearLens36 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00036.png';
// import avaOverlayClearLens37 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00037.png';
// import avaOverlayClearLens38 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00038.png';
// import avaOverlayClearLens39 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00039.png';
// import avaOverlayClearLens40 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00040.png';
// import avaOverlayClearLens41 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00041.png';
// import avaOverlayClearLens42 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00042.png';
// import avaOverlayClearLens43 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00043.png';
// import avaOverlayClearLens44 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00044.png';
// import avaOverlayClearLens45 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00045.png';
// import avaOverlayClearLens46 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00046.png';
// import avaOverlayClearLens47 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00047.png';
// import avaOverlayClearLens48 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00048.png';
// import avaOverlayClearLens49 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00049.png';
// import avaOverlayClearLens50 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00050.png';
// import avaOverlayClearLens51 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00051.png';
// import avaOverlayClearLens52 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00052.png';
// import avaOverlayClearLens53 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00053.png';
// import avaOverlayClearLens54 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00054.png';
// import avaOverlayClearLens55 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00055.png';
// import avaOverlayClearLens56 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00056.png';
// import avaOverlayClearLens57 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00057.png';
// import avaOverlayClearLens58 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00058.png';
// import avaOverlayClearLens59 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00059.png';
// import avaOverlayClearLens60 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00060.png';
// import avaOverlayClearLens61 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00061.png';
// import avaOverlayClearLens62 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00062.png';
// import avaOverlayClearLens63 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00063.png';
// import avaOverlayClearLens64 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00064.png';
// import avaOverlayClearLens65 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00065.png';
// import avaOverlayClearLens66 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00066.png';
// import avaOverlayClearLens67 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00067.png';
// import avaOverlayClearLens68 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00068.png';
// import avaOverlayClearLens69 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00069.png';
// import avaOverlayClearLens70 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00070.png';
// import avaOverlayClearLens71 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00071.png';
// import avaOverlayClearLens72 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00072.png';
// import avaOverlayClearLens73 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00073.png';
// import avaOverlayClearLens74 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00074.png';
// import avaOverlayClearLens75 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00075.png';
// import avaOverlayClearLens76 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00076.png';
// import avaOverlayClearLens77 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00077.png';
// import avaOverlayClearLens78 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00078.png';
// import avaOverlayClearLens79 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00079.png';
// import avaOverlayClearLens80 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00080.png';
// import avaOverlayClearLens81 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00081.png';
// import avaOverlayClearLens82 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00082.png';
// import avaOverlayClearLens83 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00083.png';
// import avaOverlayClearLens84 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00084.png';
// import avaOverlayClearLens85 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00085.png';
// import avaOverlayClearLens86 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00086.png';
// import avaOverlayClearLens87 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00087.png';
// import avaOverlayClearLens88 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00088.png';
// import avaOverlayClearLens89 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00089.png';
// import avaOverlayClearLens90 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00090.png';
// import avaOverlayClearLens91 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00091.png';
// import avaOverlayClearLens92 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00092.png';
// import avaOverlayClearLens93 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00093.png';
// import avaOverlayClearLens94 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00094.png';
// import avaOverlayClearLens95 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00095.png';
// import avaOverlayClearLens96 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00096.png';
// import avaOverlayClearLens97 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00097.png';
// import avaOverlayClearLens98 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00098.png';
// import avaOverlayClearLens99 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00099.png';
// import avaOverlayClearLens100 from './../assets/images/prescription/ava-overylay-clear-lens/AVA_Overlay_Clear_Lens00100.png';

// LENS CONSULTATIONS
import lensAll from './../assets/images/lens-consulatation/Lens_All.png';
import lensCorrectLens from './../assets/images/lens-consulatation/Lens_Correct_Active.png';
import lensEnhanceLens from './../assets/images/lens-consulatation/Lens_Enhance_Active.png';
import lensProtectLens from './../assets/images/lens-consulatation/Lens_Protect_Active.png';

// LENS THICKNESS
import concaveLens50 from './../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_50.png';
import concaveLens56 from './../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_56.png';
import concaveLens60 from './../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_60.png';
import concaveLens67 from './../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_67.png';
import concaveLens74 from './../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_74.png';
import convexeLens50 from './../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_50.png';
import convexeLens56 from './../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_56.png';
import convexeLens60 from './../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_60.png';
import convexeLens67 from './../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_67.png';
import convexeLens74 from './../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_74.png';

// TINTED LENS

// Transitions
import lensTransitionGreen from './../assets/images/lens-consulatation/tinted-lens/transition/transition_green.png';
import lensTransitionAmber from './../assets/images/lens-consulatation/tinted-lens/transition/transition_amber.png';
import lensTransitionAmethyst from './../assets/images/lens-consulatation/tinted-lens/transition/transition_amethyst.png';
import lensTransitionBrown from './../assets/images/lens-consulatation/tinted-lens/transition/transition_brown.png';
import lensTransitionEmerald from './../assets/images/lens-consulatation/tinted-lens/transition/transition_emerald.png';
import lensTransitionGrey from './../assets/images/lens-consulatation/tinted-lens/transition/transition_grey.png';
import lensTransitionSapphire from './../assets/images/lens-consulatation/tinted-lens/transition/transition_saphirre.png';

// Experio polarized
import lensPolarizedAmber from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_amber.png';
import lensPolarizedBlueInk from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_blue_ink.png';
import lensPolarizedBluePurple from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_blue_purple.png';
import lensPolarizedBlueSky from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_blue_sky.png';
import lensPolarizedBrown from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_brown.png';
import lensPolarizedGrey from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_grey.png';
import lensPolarizedGreyGreen from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_grey_green.png';
import lensPolarizedHerbal from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_herbal.png';
import lensPolarizedViolet from './../assets/images/lens-consulatation/tinted-lens/xperio/polarized/xperio_P_violet.png';

import lensTintedBlack from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_black.png';
import lensTintedBlueInk from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_blue_ink.png';
import lensTintedBrown from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_brown.png';
import lensTintedBurgundy from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_burgundy.png';
import lensTintedGreyGreen from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_grey_green.png';
import lensTintedGrey from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_grey.png';
import lensTintedHerbal from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_herbal.png';
import lensTintedNewBrown from './../assets/images/lens-consulatation/tinted-lens/xperio/tinted/xperio_T_newbrown.png';

import lensMirrorBronzeShadow from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_bronze_shadow.png';
import lensMirrorBronze from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_bronze.png';
import lensMirrorCandy from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_candy.png';
import lensMirrorForestGreen from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_forestgreen.png';
import lensMirrorGoldPink from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_goldpink.png';
import lensMirrorGreenShadow from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_green_shadow.png';
import lensMirrorKaki from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_kaki.png';
import lensMirrorMauveSilver from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_mauve_silver.png';
import lensMirrorNewBlue from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_new_blue.png';
import lensMirrorNewSilver from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_new_silver.png';
import lensMirrorOceanBlue from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_ocean_blue.png';
import lensMirrorOrange from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_orange.png';
import lensMirrorParma from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_parma.png';
import lensMirrorPinkSilver from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_pink_silver.png';
import lensMirrorSilverShadow from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_silver_shadow.png';
import lensMirrorSilver from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_silver.png';
import lensMirrorSkyBlueSilver from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_skyblue_silver.png';
import lensMirrorViolet from './../assets/images/lens-consulatation/tinted-lens/xperio/mirror/xperio_M_violet.png';

// TRANSITION IN
import transitionIn0 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00000.png';
import transitionIn1 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00001.png';
import transitionIn2 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00002.png';
import transitionIn3 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00003.png';
import transitionIn4 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00004.png';
import transitionIn5 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00005.png';
import transitionIn6 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00006.png';
import transitionIn7 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00007.png';
import transitionIn8 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00008.png';
import transitionIn9 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00009.png';
import transitionIn10 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00010.png';
import transitionIn11 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00011.png';
import transitionIn12 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00012.png';
import transitionIn13 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00013.png';
import transitionIn14 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00014.png';
import transitionIn15 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00015.png';
import transitionIn16 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00016.png';
import transitionIn17 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00017.png';
import transitionIn18 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00018.png';
import transitionIn19 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00019.png';
import transitionIn20 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00020.png';
import transitionIn21 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00021.png';
import transitionIn22 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00022.png';
import transitionIn23 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00023.png';
import transitionIn24 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00024.png';
import transitionIn25 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00025.png';
import transitionIn26 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00026.png';
import transitionIn27 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00027.png';
import transitionIn28 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00028.png';
import transitionIn29 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00029.png';
import transitionIn30 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00030.png';
import transitionIn31 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00031.png';
import transitionIn32 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00032.png';
import transitionIn33 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00033.png';
import transitionIn34 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00034.png';
import transitionIn35 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00035.png';
import transitionIn36 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00036.png';
import transitionIn37 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00037.png';
import transitionIn38 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00038.png';
import transitionIn39 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00039.png';
import transitionIn40 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00040.png';
import transitionIn41 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00041.png';
import transitionIn42 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00042.png';
import transitionIn43 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00043.png';
import transitionIn44 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00044.png';
import transitionIn45 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00045.png';
import transitionIn46 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00046.png';
import transitionIn47 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00047.png';
import transitionIn48 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00048.png';
import transitionIn49 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00049.png';
import transitionIn50 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00050.png';
import transitionIn51 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00051.png';
import transitionIn52 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00052.png';
import transitionIn53 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00053.png';
import transitionIn54 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00054.png';
import transitionIn55 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00055.png';
import transitionIn56 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00056.png';
import transitionIn57 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00057.png';
import transitionIn58 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00058.png';
import transitionIn59 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00059.png';
import transitionIn60 from './../assets/images/lens-consulatation/transition-in/TransitionIn_00060.png';

// Discovery Textture
import texture0	from './../assets/images/discovery/texture/loop verre01_plan01_00000-min.png';
import texture1	from './../assets/images/discovery/texture/loop verre01_plan01_00001-min.png';
import texture2	from './../assets/images/discovery/texture/loop verre01_plan01_00002-min.png';
import texture3	from './../assets/images/discovery/texture/loop verre01_plan01_00003-min.png';
import texture4	from './../assets/images/discovery/texture/loop verre01_plan01_00004-min.png';
import texture5	from './../assets/images/discovery/texture/loop verre01_plan01_00005-min.png';
import texture6	from './../assets/images/discovery/texture/loop verre01_plan01_00006-min.png';
import texture7	from './../assets/images/discovery/texture/loop verre01_plan01_00007-min.png';
import texture8	from './../assets/images/discovery/texture/loop verre01_plan01_00008-min.png';
import texture9	from './../assets/images/discovery/texture/loop verre01_plan01_00009-min.png';
import texture10	from './../assets/images/discovery/texture/loop verre01_plan01_00010-min.png';
import texture11	from './../assets/images/discovery/texture/loop verre01_plan01_00011-min.png';
import texture12	from './../assets/images/discovery/texture/loop verre01_plan01_00012-min.png';
import texture13	from './../assets/images/discovery/texture/loop verre01_plan01_00013-min.png';
import texture14	from './../assets/images/discovery/texture/loop verre01_plan01_00014-min.png';
import texture15	from './../assets/images/discovery/texture/loop verre01_plan01_00015-min.png';
import texture16	from './../assets/images/discovery/texture/loop verre01_plan01_00016-min.png';
import texture17	from './../assets/images/discovery/texture/loop verre01_plan01_00017-min.png';
import texture18	from './../assets/images/discovery/texture/loop verre01_plan01_00018-min.png';
import texture19	from './../assets/images/discovery/texture/loop verre01_plan01_00019-min.png';
import texture20	from './../assets/images/discovery/texture/loop verre01_plan01_00020-min.png';
import texture21	from './../assets/images/discovery/texture/loop verre01_plan01_00021-min.png';
import texture22	from './../assets/images/discovery/texture/loop verre01_plan01_00022-min.png';
import texture23	from './../assets/images/discovery/texture/loop verre01_plan01_00023-min.png';
import texture24	from './../assets/images/discovery/texture/loop verre01_plan01_00024-min.png';
import texture25	from './../assets/images/discovery/texture/loop verre01_plan01_00025-min.png';
import texture26	from './../assets/images/discovery/texture/loop verre01_plan01_00026-min.png';
import texture27	from './../assets/images/discovery/texture/loop verre01_plan01_00027-min.png';
import texture28	from './../assets/images/discovery/texture/loop verre01_plan01_00028-min.png';
import texture29	from './../assets/images/discovery/texture/loop verre01_plan01_00029-min.png';
import texture30	from './../assets/images/discovery/texture/loop verre01_plan01_00030-min.png';
import texture31	from './../assets/images/discovery/texture/loop verre01_plan01_00031-min.png';
import texture32	from './../assets/images/discovery/texture/loop verre01_plan01_00032-min.png';
import texture33	from './../assets/images/discovery/texture/loop verre01_plan01_00033-min.png';
import texture34	from './../assets/images/discovery/texture/loop verre01_plan01_00034-min.png';
import texture35	from './../assets/images/discovery/texture/loop verre01_plan01_00035-min.png';
import texture36	from './../assets/images/discovery/texture/loop verre01_plan01_00036-min.png';
import texture37	from './../assets/images/discovery/texture/loop verre01_plan01_00037-min.png';
import texture38	from './../assets/images/discovery/texture/loop verre01_plan01_00038-min.png';
import texture39	from './../assets/images/discovery/texture/loop verre01_plan01_00039-min.png';
import texture40	from './../assets/images/discovery/texture/loop verre01_plan01_00040-min.png';
import texture41	from './../assets/images/discovery/texture/loop verre01_plan01_00041-min.png';
import texture42	from './../assets/images/discovery/texture/loop verre01_plan01_00042-min.png';
import texture43	from './../assets/images/discovery/texture/loop verre01_plan01_00043-min.png';
import texture44	from './../assets/images/discovery/texture/loop verre01_plan01_00044-min.png';
import texture45	from './../assets/images/discovery/texture/loop verre01_plan01_00045-min.png';
import texture46	from './../assets/images/discovery/texture/loop verre01_plan01_00046-min.png';
import texture47	from './../assets/images/discovery/texture/loop verre01_plan01_00047-min.png';
import texture48	from './../assets/images/discovery/texture/loop verre01_plan01_00048-min.png';
import texture49	from './../assets/images/discovery/texture/loop verre01_plan01_00049-min.png';
import texture50	from './../assets/images/discovery/texture/loop verre01_plan01_00050-min.png';
import texture51	from './../assets/images/discovery/texture/loop verre01_plan01_00051-min.png';
import texture52	from './../assets/images/discovery/texture/loop verre01_plan01_00052-min.png';
import texture53	from './../assets/images/discovery/texture/loop verre01_plan01_00053-min.png';
import texture54	from './../assets/images/discovery/texture/loop verre01_plan01_00054-min.png';
import texture55	from './../assets/images/discovery/texture/loop verre01_plan01_00055-min.png';
import texture56	from './../assets/images/discovery/texture/loop verre01_plan01_00056-min.png';
import texture57	from './../assets/images/discovery/texture/loop verre01_plan01_00057-min.png';
import texture58	from './../assets/images/discovery/texture/loop verre01_plan01_00058-min.png';
import texture59	from './../assets/images/discovery/texture/loop verre01_plan01_00059-min.png';
import texture60	from './../assets/images/discovery/texture/loop verre01_plan01_00060-min.png';
import texture61	from './../assets/images/discovery/texture/loop verre01_plan01_00061-min.png';
import texture62	from './../assets/images/discovery/texture/loop verre01_plan01_00062-min.png';
import texture63	from './../assets/images/discovery/texture/loop verre01_plan01_00063-min.png';
import texture64	from './../assets/images/discovery/texture/loop verre01_plan01_00064-min.png';
import texture65	from './../assets/images/discovery/texture/loop verre01_plan01_00065-min.png';
import texture66	from './../assets/images/discovery/texture/loop verre01_plan01_00066-min.png';
import texture67	from './../assets/images/discovery/texture/loop verre01_plan01_00067-min.png';
import texture68	from './../assets/images/discovery/texture/loop verre01_plan01_00068-min.png';
import texture69	from './../assets/images/discovery/texture/loop verre01_plan01_00069-min.png';
import texture70	from './../assets/images/discovery/texture/loop verre01_plan01_00070-min.png';
import texture71	from './../assets/images/discovery/texture/loop verre01_plan01_00071-min.png';
import texture72	from './../assets/images/discovery/texture/loop verre01_plan01_00072-min.png';
import texture73	from './../assets/images/discovery/texture/loop verre01_plan01_00073-min.png';
import texture74	from './../assets/images/discovery/texture/loop verre01_plan01_00074-min.png';
import texture75	from './../assets/images/discovery/texture/loop verre01_plan01_00075-min.png';
import texture76	from './../assets/images/discovery/texture/loop verre01_plan01_00076-min.png';
import texture77	from './../assets/images/discovery/texture/loop verre01_plan01_00077-min.png';
import texture78	from './../assets/images/discovery/texture/loop verre01_plan01_00078-min.png';
import texture79	from './../assets/images/discovery/texture/loop verre01_plan01_00079-min.png';
import texture80	from './../assets/images/discovery/texture/loop verre01_plan01_00080-min.png';
import texture81	from './../assets/images/discovery/texture/loop verre01_plan01_00081-min.png';
import texture82	from './../assets/images/discovery/texture/loop verre01_plan01_00082-min.png';
import texture83	from './../assets/images/discovery/texture/loop verre01_plan01_00083-min.png';
import texture84	from './../assets/images/discovery/texture/loop verre01_plan01_00084-min.png';
import texture85	from './../assets/images/discovery/texture/loop verre01_plan01_00085-min.png';
import texture86	from './../assets/images/discovery/texture/loop verre01_plan01_00086-min.png';
import texture87	from './../assets/images/discovery/texture/loop verre01_plan01_00087-min.png';
import texture88	from './../assets/images/discovery/texture/loop verre01_plan01_00088-min.png';
import texture89	from './../assets/images/discovery/texture/loop verre01_plan01_00089-min.png';
import texture90	from './../assets/images/discovery/texture/loop verre01_plan01_00090-min.png';
import texture91	from './../assets/images/discovery/texture/loop verre01_plan01_00091-min.png';
import texture92	from './../assets/images/discovery/texture/loop verre01_plan01_00092-min.png';
import texture93	from './../assets/images/discovery/texture/loop verre01_plan01_00093-min.png';
import texture94	from './../assets/images/discovery/texture/loop verre01_plan01_00094-min.png';
import texture95	from './../assets/images/discovery/texture/loop verre01_plan01_00095-min.png';
import texture96	from './../assets/images/discovery/texture/loop verre01_plan01_00096-min.png';
import texture97	from './../assets/images/discovery/texture/loop verre01_plan01_00097-min.png';
import texture98	from './../assets/images/discovery/texture/loop verre01_plan01_00098-min.png';
import texture99	from './../assets/images/discovery/texture/loop verre01_plan01_00099-min.png';
import texture100	from './../assets/images/discovery/texture/loop verre01_plan01_00100-min.png';
import texture101	from './../assets/images/discovery/texture/loop verre01_plan01_00101-min.png';
import texture102	from './../assets/images/discovery/texture/loop verre01_plan01_00102-min.png';
import texture103	from './../assets/images/discovery/texture/loop verre01_plan01_00103-min.png';
import texture104	from './../assets/images/discovery/texture/loop verre01_plan01_00104-min.png';
import texture105	from './../assets/images/discovery/texture/loop verre01_plan01_00105-min.png';
import texture106	from './../assets/images/discovery/texture/loop verre01_plan01_00106-min.png';
import texture107	from './../assets/images/discovery/texture/loop verre01_plan01_00107-min.png';
import texture108	from './../assets/images/discovery/texture/loop verre01_plan01_00108-min.png';
import texture109	from './../assets/images/discovery/texture/loop verre01_plan01_00109-min.png';
import texture110	from './../assets/images/discovery/texture/loop verre01_plan01_00110-min.png';
import texture111	from './../assets/images/discovery/texture/loop verre01_plan01_00111-min.png';
import texture112	from './../assets/images/discovery/texture/loop verre01_plan01_00112-min.png';
import texture113	from './../assets/images/discovery/texture/loop verre01_plan01_00113-min.png';
import texture114	from './../assets/images/discovery/texture/loop verre01_plan01_00114-min.png';
import texture115	from './../assets/images/discovery/texture/loop verre01_plan01_00115-min.png';
import texture116	from './../assets/images/discovery/texture/loop verre01_plan01_00116-min.png';
import texture117	from './../assets/images/discovery/texture/loop verre01_plan01_00117-min.png';
import texture118	from './../assets/images/discovery/texture/loop verre01_plan01_00118-min.png';
import texture119	from './../assets/images/discovery/texture/loop verre01_plan01_00119-min.png';
import texture120	from './../assets/images/discovery/texture/loop verre01_plan01_00120-min.png';
import texture121	from './../assets/images/discovery/texture/loop verre01_plan01_00121-min.png';
import texture122	from './../assets/images/discovery/texture/loop verre01_plan01_00122-min.png';
import texture123	from './../assets/images/discovery/texture/loop verre01_plan01_00123-min.png';
import texture124	from './../assets/images/discovery/texture/loop verre01_plan01_00124-min.png';
import texture125	from './../assets/images/discovery/texture/loop verre01_plan01_00125-min.png';
import texture126	from './../assets/images/discovery/texture/loop verre01_plan01_00126-min.png';
import texture127	from './../assets/images/discovery/texture/loop verre01_plan01_00127-min.png';
import texture128	from './../assets/images/discovery/texture/loop verre01_plan01_00128-min.png';
import texture129	from './../assets/images/discovery/texture/loop verre01_plan01_00129-min.png';
import texture130	from './../assets/images/discovery/texture/loop verre01_plan01_00130-min.png';
import texture131	from './../assets/images/discovery/texture/loop verre01_plan01_00131-min.png';
import texture132	from './../assets/images/discovery/texture/loop verre01_plan01_00132-min.png';
import texture133	from './../assets/images/discovery/texture/loop verre01_plan01_00133-min.png';
import texture134	from './../assets/images/discovery/texture/loop verre01_plan01_00134-min.png';
import texture135	from './../assets/images/discovery/texture/loop verre01_plan01_00135-min.png';
import texture136	from './../assets/images/discovery/texture/loop verre01_plan01_00136-min.png';
import texture137	from './../assets/images/discovery/texture/loop verre01_plan01_00137-min.png';
import texture138	from './../assets/images/discovery/texture/loop verre01_plan01_00138-min.png';
import texture139	from './../assets/images/discovery/texture/loop verre01_plan01_00139-min.png';
import texture140	from './../assets/images/discovery/texture/loop verre01_plan01_00140-min.png';
import texture141	from './../assets/images/discovery/texture/loop verre01_plan01_00141-min.png';
import texture142	from './../assets/images/discovery/texture/loop verre01_plan01_00142-min.png';
import texture143	from './../assets/images/discovery/texture/loop verre01_plan01_00143-min.png';
import texture144	from './../assets/images/discovery/texture/loop verre01_plan01_00144-min.png';

// Transition Out Convexe
import transitionOutConvexe0 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00000.png';
import transitionOutConvexe1 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00001.png';
import transitionOutConvexe2 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00002.png';
import transitionOutConvexe3 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00003.png';
import transitionOutConvexe4 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00004.png';
import transitionOutConvexe5 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00005.png';
import transitionOutConvexe6 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00006.png';
import transitionOutConvexe7 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00007.png';
import transitionOutConvexe8 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00008.png';
import transitionOutConvexe9 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00009.png';
import transitionOutConvexe10 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00010.png';
import transitionOutConvexe11 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00011.png';
import transitionOutConvexe12 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00012.png';
import transitionOutConvexe13 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00013.png';
import transitionOutConvexe14 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00014.png';
import transitionOutConvexe15 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00015.png';
import transitionOutConvexe16 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00016.png';
import transitionOutConvexe17 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00017.png';
import transitionOutConvexe18 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00018.png';
import transitionOutConvexe19 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00019.png';
import transitionOutConvexe20 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00020.png';
import transitionOutConvexe21 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00021.png';
import transitionOutConvexe22 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00022.png';
import transitionOutConvexe23 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00023.png';
import transitionOutConvexe24 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00024.png';
import transitionOutConvexe25 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00025.png';
import transitionOutConvexe26 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00026.png';
import transitionOutConvexe27 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00027.png';
import transitionOutConvexe28 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00028.png';
import transitionOutConvexe29 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00029.png';
import transitionOutConvexe30 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00030.png';
import transitionOutConvexe31 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00031.png';
import transitionOutConvexe32 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00032.png';
import transitionOutConvexe33 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00033.png';
import transitionOutConvexe34 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00034.png';
import transitionOutConvexe35 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00035.png';
import transitionOutConvexe36 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00036.png';
import transitionOutConvexe37 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00037.png';
import transitionOutConvexe38 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00038.png';
import transitionOutConvexe39 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00039.png';
import transitionOutConvexe40 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00040.png';
import transitionOutConvexe41 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00041.png';
import transitionOutConvexe42 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00042.png';
import transitionOutConvexe43 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00043.png';
import transitionOutConvexe44 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00044.png';
import transitionOutConvexe45 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00045.png';
import transitionOutConvexe46 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00046.png';
import transitionOutConvexe47 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00047.png';
import transitionOutConvexe48 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00048.png';
import transitionOutConvexe49 from './../assets/images/lens-consulatation/transition-out-convexe/TransitionOut_Convexe_00049.png';

// Transition Out Concave
import transitionOutConcave0 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00000.png';
import transitionOutConcave1 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00001.png';
import transitionOutConcave2 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00002.png';
import transitionOutConcave3 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00003.png';
import transitionOutConcave4 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00004.png';
import transitionOutConcave5 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00005.png';
import transitionOutConcave6 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00006.png';
import transitionOutConcave7 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00007.png';
import transitionOutConcave8 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00008.png';
import transitionOutConcave9 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00009.png';
import transitionOutConcave10 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00010.png';
import transitionOutConcave11 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00011.png';
import transitionOutConcave12 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00012.png';
import transitionOutConcave13 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00013.png';
import transitionOutConcave14 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00014.png';
import transitionOutConcave15 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00015.png';
import transitionOutConcave16 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00016.png';
import transitionOutConcave17 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00017.png';
import transitionOutConcave18 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00018.png';
import transitionOutConcave19 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00019.png';
import transitionOutConcave20 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00020.png';
import transitionOutConcave21 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00021.png';
import transitionOutConcave22 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00022.png';
import transitionOutConcave23 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00023.png';
import transitionOutConcave24 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00024.png';
import transitionOutConcave25 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00025.png';
import transitionOutConcave26 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00026.png';
import transitionOutConcave27 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00027.png';
import transitionOutConcave28 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00028.png';
import transitionOutConcave29 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00029.png';
import transitionOutConcave30 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00030.png';
import transitionOutConcave31 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00031.png';
import transitionOutConcave32 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00032.png';
import transitionOutConcave33 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00033.png';
import transitionOutConcave34 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00034.png';
import transitionOutConcave35 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00035.png';
import transitionOutConcave36 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00036.png';
import transitionOutConcave37 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00037.png';
import transitionOutConcave38 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00038.png';
import transitionOutConcave39 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00039.png';
import transitionOutConcave40 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00040.png';
import transitionOutConcave41 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00041.png';
import transitionOutConcave42 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00042.png';
import transitionOutConcave43 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00043.png';
import transitionOutConcave44 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00044.png';
import transitionOutConcave45 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00045.png';
import transitionOutConcave46 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00046.png';
import transitionOutConcave47 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00047.png';
import transitionOutConcave48 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00048.png';
import transitionOutConcave49 from './../assets/images/lens-consulatation/transition-out-concave/TransitionOut_Concave_00049.png';

// Need Explorer Images
import needExplorerEyes from './../assets/images/gen_eyes.png';
import needExplorerEyeGlasses from './../assets/images/gen_eyeglasses.png';
import needExplorerStop from './../assets/images/gen_stop.png';
import needExplorerNearby from './../assets/images/gen_nearby.png';
import needExplorerDistance from './../assets/images/gen_distance.png';
import needExplorerFarAway from './../assets/images/gen_far_away.png';
import needExplorerDragScreen from './../assets/images/gen_drag_screen.png';
import needExplorerDragDaily from './../assets/images/gen_drag_daily.png';
import needExplorerDragOutdoor from './../assets/images/gen_drag_outdoor.png';
import needExplorerDragIndoor from './../assets/images/gen_drag_indoor.png';
import needExplorerWomanChildren from './../assets/images/gen_woman_children.png';
import needExplorerRunning from './../assets/images/running.png';
import needExplorerExpose from './../assets/images/gen_expose.png';
import needExplorerRunningSolo from './../assets/images/running-solo.png';
import needExplorerDiscomfort from './../assets/images/gen_discomfort.png';
import needExplorerSmudge from './../assets/images/gen_smudge.png';
import needExplorerScratchGlasses from './../assets/images/gen_scratch_glasses.png';
import needExplorerRain from './../assets/images/gen_rain.png';
import needExplorerMultipleScreen from './../assets/images/gen_multiple_screen.png';
import needExplorerDicussion from './../assets/images/gen_discussion.png';
import needExplorerMeetings from './../assets/images/gen_meetings.png';
import needExplorerBike from './../assets/images/bike.png';
import needExplorerScooter from './../assets/images/scooter.png';
import needExplorerMotor from './../assets/images/motor.png';
import needExplorerCar from './../assets/images/gen_car.png';
import needExplorerBusDriver from './../assets/images/gen_bus_driver.png';
import needExplorerBrightlight from './../assets/images/gen_brightlight.png';
import needExplorerLowlight from './../assets/images/gen_lowlight.png';
import needExplorerReducedDark from './../assets/images/gen_reduced_dark.png';
import needExplorerChangingLight from './../assets/images/gen_changing_light.png';
import needExplorerGlare from './../assets/images/gen_glare.png';
import needExplorerHeadlights from './../assets/images/gen_headlights.png';
import needExplorerMultitask from './../assets/images/gen_multitask.png';
import needExplorerPrecisionTask from './../assets/images/gen_precision_task.png';
import needExplorerElectrician from './../assets/images/gen_electrician.png';
import needExplorerFarsighted from './../assets/images/gen_farsighted.png';
import needExplorerDragQuickEye from './../assets/images/gen_drag_quick_eye.png';
import needExplorerDragHighRisk from './../assets/images/gen_drag_high_risk.png';
import needExplorerFastResponse from './../assets/images/gen_fast_response.png';
import needExplorerDragKitchen from './../assets/images/gen_drag_kitchen.png';
import needExplorerSmudgeDustGrease from './../assets/images/gen_smudge_dust_grease.png';
import needExplorerLightCircumstances from './../assets/images/gen_light_circumstances.png';

import useTranslation from '../utils/hooks/useTranslation';
import LoadingAssets from './ui/loadingAssets';
import settings from './../constants/settings.constants';
import { IndexedDBContext, IndexedDBContextType } from '../utils/context/indexedDBContext';

interface Props {

}

const siteAssetURL = (url: string) => {
  return `${settings[settings.environment].siteAssets}${url}`;
}

let measurementAnimation =  siteAssetURL('videos/measurement.mp4');
let discoveryMontageAnimation =  siteAssetURL('videos/montage_background.mp4');

const DownloadAssets : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B,E] = useBEM('download-assets');
    const {downloadAssetBase64} = Utils();
    const [preloadComplete, setPreloadComplete] = useState(false);
    const {get, insert} = React.useContext(IndexedDBContext) as IndexedDBContextType;

    const [doneCount, setDoneCount] = useState(0);

    const loadedCount = useRef(0);
    const totalAssets = 365;

    // HOOKS
    useEffect(() => {
      const downloadAppVideos = async () => {

        const downloadDiscoveryMontageAnimation = await get('localAssetsStore', 'discoveryMontageAnimation');
        const downloadMeasurementbase64 = await get('localAssetsStore', 'measurementAnimation');

        if(!downloadDiscoveryMontageAnimation){
          const discoverybase64 = await downloadAssetBase64(discoveryMontageAnimation);
          await insert('localAssetsStore', {
            id: `discoveryMontageAnimation`,            
            asset: discoverybase64,
          });
        }

        if(!downloadMeasurementbase64){
          const measurementbase64 = await downloadAssetBase64(measurementAnimation);
          await insert('localAssetsStore', {
              id: `measurementAnimation`,            
              asset: measurementbase64,
          });
        }        
      }
      downloadAppVideos();
    },[]);
    
    // METHODS
    // const downloadAssets = async () => {     
    //     const tempAssets = cloneObject(assets);
    //     for (const key of Object.keys(assets)) {
    //       const blob = await downloadAsset(assets[key].path);
    //       tempAssets[key] = {
    //         ...tempAssets[key],
    //         blob
    //       }
    //       // setDoneCount(prev => prev + 1);
    //     }
    //     dispatch(appSlice.actions.setAssets(tempAssets));        
    //     setBatchPreloadComplete(prev => prev + 1);
    //     console.log(`Batch Complete`);
    // }

    const loaded = () => {
      loadedCount.current += 1;
      
      setDoneCount(prev => prev + 1);

      if(loadedCount.current >= totalAssets){
        setPreloadComplete(true);
      }
    }
    const downloadPercentage = () => {
      const percent = (doneCount / totalAssets * 100);
      // return  `${ Math.floor(percent) }%`;
      return Math.floor(percent);
    }

    return (
      <div className={B( preloadComplete ? 'complete' : '' )}>

        {/* <Spinner color="#8bcbf1" text={downloadPercentage()}/>
        <label className={E('label')}>
          {t('message_-_first_time_app_loading_mess_pre-loading_assets...')}
        </label> */}
        
        <LoadingAssets percentage={downloadPercentage()}/>

        {/* LENS CONSULTATION LENS IN */}
        <img alt="" src={transitionIn0} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn1} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn2} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn3} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn4} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn5} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn6} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn7} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn8} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn9} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn10} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn11} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn12} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn13} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn14} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn15} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn16} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn17} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn18} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn19} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn20} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn21} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn22} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn23} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn24} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn25} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn26} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn27} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn28} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn29} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn30} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn31} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn32} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn33} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn34} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn35} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn36} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn37} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn38} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn39} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn40} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn41} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn42} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn43} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn44} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn45} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn46} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn47} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn48} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn49} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn50} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn51} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn52} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn53} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn54} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn55} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn56} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn57} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn58} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn59} onLoad={()=> loaded()} />
        <img alt="" src={transitionIn60} onLoad={()=> loaded()} />

        {/* DISCOVERY TEXTURE */}
        <img alt="" src={texture0} onLoad={()=> loaded()} />
        <img alt="" src={texture1} onLoad={()=> loaded()} />
        <img alt="" src={texture2} onLoad={()=> loaded()} />
        <img alt="" src={texture3} onLoad={()=> loaded()} />
        <img alt="" src={texture4} onLoad={()=> loaded()} />
        <img alt="" src={texture5} onLoad={()=> loaded()} />
        <img alt="" src={texture6} onLoad={()=> loaded()} />
        <img alt="" src={texture7} onLoad={()=> loaded()} />
        <img alt="" src={texture8} onLoad={()=> loaded()} />
        <img alt="" src={texture9} onLoad={()=> loaded()} />
        <img alt="" src={texture10} onLoad={()=> loaded()} />
        <img alt="" src={texture11} onLoad={()=> loaded()} />
        <img alt="" src={texture12} onLoad={()=> loaded()} />
        <img alt="" src={texture13} onLoad={()=> loaded()} />
        <img alt="" src={texture14} onLoad={()=> loaded()} />
        <img alt="" src={texture15} onLoad={()=> loaded()} />
        <img alt="" src={texture16} onLoad={()=> loaded()} />
        <img alt="" src={texture17} onLoad={()=> loaded()} />
        <img alt="" src={texture18} onLoad={()=> loaded()} />
        <img alt="" src={texture19} onLoad={()=> loaded()} />
        <img alt="" src={texture20} onLoad={()=> loaded()} />
        <img alt="" src={texture21} onLoad={()=> loaded()} />
        <img alt="" src={texture22} onLoad={()=> loaded()} />
        <img alt="" src={texture23} onLoad={()=> loaded()} />
        <img alt="" src={texture24} onLoad={()=> loaded()} />
        <img alt="" src={texture25} onLoad={()=> loaded()} />
        <img alt="" src={texture26} onLoad={()=> loaded()} />
        <img alt="" src={texture27} onLoad={()=> loaded()} />
        <img alt="" src={texture28} onLoad={()=> loaded()} />
        <img alt="" src={texture29} onLoad={()=> loaded()} />
        <img alt="" src={texture30} onLoad={()=> loaded()} />
        <img alt="" src={texture31} onLoad={()=> loaded()} />
        <img alt="" src={texture32} onLoad={()=> loaded()} />
        <img alt="" src={texture33} onLoad={()=> loaded()} />
        <img alt="" src={texture34} onLoad={()=> loaded()} />
        <img alt="" src={texture35} onLoad={()=> loaded()} />
        <img alt="" src={texture36} onLoad={()=> loaded()} />
        <img alt="" src={texture37} onLoad={()=> loaded()} />
        <img alt="" src={texture38} onLoad={()=> loaded()} />
        <img alt="" src={texture39} onLoad={()=> loaded()} />
        <img alt="" src={texture40} onLoad={()=> loaded()} />
        <img alt="" src={texture41} onLoad={()=> loaded()} />
        <img alt="" src={texture42} onLoad={()=> loaded()} />
        <img alt="" src={texture43} onLoad={()=> loaded()} />
        <img alt="" src={texture44} onLoad={()=> loaded()} />
        <img alt="" src={texture45} onLoad={()=> loaded()} />
        <img alt="" src={texture46} onLoad={()=> loaded()} />
        <img alt="" src={texture47} onLoad={()=> loaded()} />
        <img alt="" src={texture48} onLoad={()=> loaded()} />
        <img alt="" src={texture49} onLoad={()=> loaded()} />
        <img alt="" src={texture50} onLoad={()=> loaded()} />
        <img alt="" src={texture51} onLoad={()=> loaded()} />
        <img alt="" src={texture52} onLoad={()=> loaded()} />
        <img alt="" src={texture53} onLoad={()=> loaded()} />
        <img alt="" src={texture54} onLoad={()=> loaded()} />
        <img alt="" src={texture55} onLoad={()=> loaded()} />
        <img alt="" src={texture56} onLoad={()=> loaded()} />
        <img alt="" src={texture57} onLoad={()=> loaded()} />
        <img alt="" src={texture58} onLoad={()=> loaded()} />
        <img alt="" src={texture59} onLoad={()=> loaded()} />
        <img alt="" src={texture60} onLoad={()=> loaded()} />
        <img alt="" src={texture61} onLoad={()=> loaded()} />
        <img alt="" src={texture62} onLoad={()=> loaded()} />
        <img alt="" src={texture63} onLoad={()=> loaded()} />
        <img alt="" src={texture64} onLoad={()=> loaded()} />
        <img alt="" src={texture65} onLoad={()=> loaded()} />
        <img alt="" src={texture66} onLoad={()=> loaded()} />
        <img alt="" src={texture67} onLoad={()=> loaded()} />
        <img alt="" src={texture68} onLoad={()=> loaded()} />
        <img alt="" src={texture69} onLoad={()=> loaded()} />
        <img alt="" src={texture70} onLoad={()=> loaded()} />
        <img alt="" src={texture71} onLoad={()=> loaded()} />
        <img alt="" src={texture72} onLoad={()=> loaded()} />
        <img alt="" src={texture73} onLoad={()=> loaded()} />
        <img alt="" src={texture74} onLoad={()=> loaded()} />
        <img alt="" src={texture75} onLoad={()=> loaded()} />
        <img alt="" src={texture76} onLoad={()=> loaded()} />
        <img alt="" src={texture77} onLoad={()=> loaded()} />
        <img alt="" src={texture78} onLoad={()=> loaded()} />
        <img alt="" src={texture79} onLoad={()=> loaded()} />
        <img alt="" src={texture80} onLoad={()=> loaded()} />
        <img alt="" src={texture81} onLoad={()=> loaded()} />
        <img alt="" src={texture82} onLoad={()=> loaded()} />
        <img alt="" src={texture83} onLoad={()=> loaded()} />
        <img alt="" src={texture84} onLoad={()=> loaded()} />
        <img alt="" src={texture85} onLoad={()=> loaded()} />
        <img alt="" src={texture86} onLoad={()=> loaded()} />
        <img alt="" src={texture87} onLoad={()=> loaded()} />
        <img alt="" src={texture88} onLoad={()=> loaded()} />
        <img alt="" src={texture89} onLoad={()=> loaded()} />
        <img alt="" src={texture90} onLoad={()=> loaded()} />
        <img alt="" src={texture91} onLoad={()=> loaded()} />
        <img alt="" src={texture92} onLoad={()=> loaded()} />
        <img alt="" src={texture93} onLoad={()=> loaded()} />
        <img alt="" src={texture94} onLoad={()=> loaded()} />
        <img alt="" src={texture95} onLoad={()=> loaded()} />
        <img alt="" src={texture96} onLoad={()=> loaded()} />
        <img alt="" src={texture97} onLoad={()=> loaded()} />
        <img alt="" src={texture98} onLoad={()=> loaded()} />
        <img alt="" src={texture99} onLoad={()=> loaded()} />
        <img alt="" src={texture100} onLoad={()=> loaded()} />
        <img alt="" src={texture101} onLoad={()=> loaded()} />
        <img alt="" src={texture102} onLoad={()=> loaded()} />
        <img alt="" src={texture103} onLoad={()=> loaded()} />
        <img alt="" src={texture104} onLoad={()=> loaded()} />
        <img alt="" src={texture105} onLoad={()=> loaded()} />
        <img alt="" src={texture106} onLoad={()=> loaded()} />
        <img alt="" src={texture107} onLoad={()=> loaded()} />
        <img alt="" src={texture108} onLoad={()=> loaded()} />
        <img alt="" src={texture109} onLoad={()=> loaded()} />
        <img alt="" src={texture110} onLoad={()=> loaded()} />
        <img alt="" src={texture111} onLoad={()=> loaded()} />
        <img alt="" src={texture112} onLoad={()=> loaded()} />
        <img alt="" src={texture113} onLoad={()=> loaded()} />
        <img alt="" src={texture114} onLoad={()=> loaded()} />
        <img alt="" src={texture115} onLoad={()=> loaded()} />
        <img alt="" src={texture116} onLoad={()=> loaded()} />
        <img alt="" src={texture117} onLoad={()=> loaded()} />
        <img alt="" src={texture118} onLoad={()=> loaded()} />
        <img alt="" src={texture119} onLoad={()=> loaded()} />
        <img alt="" src={texture120} onLoad={()=> loaded()} />
        <img alt="" src={texture121} onLoad={()=> loaded()} />
        <img alt="" src={texture122} onLoad={()=> loaded()} />
        <img alt="" src={texture123} onLoad={()=> loaded()} />
        <img alt="" src={texture124} onLoad={()=> loaded()} />
        <img alt="" src={texture125} onLoad={()=> loaded()} />
        <img alt="" src={texture126} onLoad={()=> loaded()} />
        <img alt="" src={texture127} onLoad={()=> loaded()} />
        <img alt="" src={texture128} onLoad={()=> loaded()} />
        <img alt="" src={texture129} onLoad={()=> loaded()} />
        <img alt="" src={texture130} onLoad={()=> loaded()} />
        <img alt="" src={texture131} onLoad={()=> loaded()} />
        <img alt="" src={texture132} onLoad={()=> loaded()} />
        <img alt="" src={texture133} onLoad={()=> loaded()} />
        <img alt="" src={texture134} onLoad={()=> loaded()} />
        <img alt="" src={texture135} onLoad={()=> loaded()} />
        <img alt="" src={texture136} onLoad={()=> loaded()} />
        <img alt="" src={texture137} onLoad={()=> loaded()} />
        <img alt="" src={texture138} onLoad={()=> loaded()} />
        <img alt="" src={texture139} onLoad={()=> loaded()} />
        <img alt="" src={texture140} onLoad={()=> loaded()} />
        <img alt="" src={texture141} onLoad={()=> loaded()} />
        <img alt="" src={texture142} onLoad={()=> loaded()} />
        <img alt="" src={texture143} onLoad={()=> loaded()} />
        <img alt="" src={texture144} onLoad={()=> loaded()} />

        {/* PRESCRIPTION */}
        <img alt="" src={	clearLens	} onLoad={()=> loaded()} />

        {/* LENS THICKNESS  */}
        <img alt="" src={	transitionOutConvexe0	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe1	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe2	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe3	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe4	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe5	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe6	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe7	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe8	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe9	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe10	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe11	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe12	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe13	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe14	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe15	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe16	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe17	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe18	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe19	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe20	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe21	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe22	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe23	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe24	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe25	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe26	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe27	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe28	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe29	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe30	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe31	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe32	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe33	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe34	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe35	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe36	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe37	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe38	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe39	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe40	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe41	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe42	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe43	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe44	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe45	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe46	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe47	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe48	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConvexe49	} onLoad={()=> loaded()} />

        <img alt="" src={	transitionOutConcave0	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave1	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave2	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave3	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave4	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave5	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave6	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave7	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave8	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave9	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave10	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave11	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave12	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave13	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave14	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave15	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave16	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave17	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave18	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave19	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave20	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave21	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave22	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave23	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave24	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave25	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave26	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave27	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave28	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave29	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave30	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave31	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave32	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave33	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave34	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave35	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave36	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave37	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave38	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave39	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave40	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave41	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave42	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave43	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave44	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave45	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave46	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave47	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave48	} onLoad={()=> loaded()} />
        <img alt="" src={	transitionOutConcave49	} onLoad={()=> loaded()} />

        {/* BRANDS */}
        <img alt="" src={	variluxCard	} onLoad={()=> loaded()} />
        <img alt="" src={	experioCard	} onLoad={()=> loaded()} />
        <img alt="" src={	blueUVCard	} onLoad={()=> loaded()} />
        <img alt="" src={	eyezenCard	} onLoad={()=> loaded()} />
        <img alt="" src={	crizalCard	} onLoad={()=> loaded()} />
        <img alt="" src={	optiFogCard	} onLoad={()=> loaded()} />  
        <img alt="" src={	transitionsCard	} onLoad={()=> loaded()} />  
        <img alt="" src={	stellestCard2	} onLoad={()=> loaded()} />

        {/* LENS CONSULTATION SIDE */}
        <img alt="" src={	lensAll	} onLoad={()=> loaded()} />
        <img alt="" src={	lensCorrectLens	} onLoad={()=> loaded()} />
        <img alt="" src={	lensEnhanceLens	} onLoad={()=> loaded()} />
        <img alt="" src={	lensProtectLens	} onLoad={()=> loaded()} />

        <img alt="" src={	lensTransitionGreen	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTransitionAmber	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTransitionAmethyst	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTransitionBrown	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTransitionEmerald	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTransitionGrey	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTransitionSapphire	} onLoad={()=> loaded()} />

        <img alt="" src={	lensPolarizedAmber	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedBlueInk	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedBluePurple	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedBlueSky	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedBrown	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedGrey	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedGreyGreen	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedHerbal	} onLoad={()=> loaded()} />
        <img alt="" src={	lensPolarizedViolet	} onLoad={()=> loaded()} />

        <img alt="" src={	lensTintedBlack	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedBlueInk	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedBrown	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedBurgundy	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedGreyGreen	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedGrey	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedHerbal	} onLoad={()=> loaded()} />
        <img alt="" src={	lensTintedNewBrown	} onLoad={()=> loaded()} />

        <img alt="" src={	lensMirrorBronzeShadow	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorBronze	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorCandy	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorForestGreen	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorGoldPink	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorGreenShadow	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorKaki	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorMauveSilver	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorNewBlue	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorNewSilver	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorOceanBlue	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorOrange	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorParma	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorPinkSilver } onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorSilverShadow	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorSilver	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorSkyBlueSilver	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMirrorViolet	} onLoad={()=> loaded()} />

        <img alt="" src={	concaveLens50	} onLoad={()=> loaded()} />
        <img alt="" src={	concaveLens56	} onLoad={()=> loaded()} />
        <img alt="" src={	concaveLens60	} onLoad={()=> loaded()} />
        <img alt="" src={	concaveLens67	} onLoad={()=> loaded()} />
        <img alt="" src={	concaveLens74	} onLoad={()=> loaded()} />
        <img alt="" src={	convexeLens50 } onLoad={()=> loaded()} />
        <img alt="" src={	convexeLens56	} onLoad={()=> loaded()} />
        <img alt="" src={	convexeLens60	} onLoad={()=> loaded()} />
        <img alt="" src={	convexeLens67	} onLoad={()=> loaded()} />
        <img alt="" src={	convexeLens74	} onLoad={()=> loaded()} />

        <img alt="" src={	refresh	} onLoad={()=> loaded()} />
        <img alt="" src={	lensMenuBlack	} onLoad={()=> loaded()} />
        <img alt="" src={	flip	} onLoad={()=> loaded()} />
        <img alt="" src={	hamburger	} onLoad={()=> loaded()} />

        {/* Need Explorer Images */}
        <img alt="" src={	needExplorerBike} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerBrightlight	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerBusDriver	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerCar	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerChangingLight	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDicussion	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDiscomfort } onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDistance	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragDaily	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragHighRisk	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragIndoor	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragKitchen	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragOutdoor	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragQuickEye	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerDragScreen	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerElectrician	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerExpose } onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerEyeGlasses } onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerEyes	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerFarAway	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerFarsighted	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerFastResponse	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerGlare	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerHeadlights	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerLightCircumstances	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerLowlight	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerMeetings	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerMotor	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerMultipleScreen	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerMultitask	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerNearby	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerPrecisionTask	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerRain	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerReducedDark	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerRunning	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerRunningSolo	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerScooter	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerScratchGlasses	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerSmudge	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerSmudgeDustGrease	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerStop	} onLoad={()=> loaded()} />
        <img alt="" src={	needExplorerWomanChildren	} onLoad={()=> loaded()} />
      </div>
    );
}

export default DownloadAssets;