import React from 'react';
import iQuestionChoice from '../../../../interface/question-option.interface';
import useBEM from '../../../../utils/hooks/useBEM';
import Icon from '../../../../components/ui/Icon';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import iQuestionOption from '../../../../interface/question-option.interface';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/slices/user.slice';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { getApp } from '../../../../store/slices/app.slice';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: iQuestionOption | iQuestionOption[]) => void
}

const ChoiceImageKids : React.FC<Props>  = ({
    question,
    onAnswer
}) =>  {
    // VARIABLES
    const  [B, E] = useBEM('choice-image-kids');
    const { ageCategory } = useSelector(getUser);
    const { t } =  useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const getAnswer = () => {
        if(!question.answer)return question.canSelectMultiple ? [] : '';      
        
        if(question.canSelectMultiple) {
            return question.answer as iQuestionOption[];
        }
        return (question.answer as iQuestionOption).value;
    }

    const select = (option: iQuestionChoice) => {

        if(question.canSelectMultiple) {
            const answer = getAnswer() as iQuestionOption[];
            const isExist = answer.filter(c => c.value === option.value);
            let finalAnswer;
            if( isExist.length > 0) {
                finalAnswer = answer?.filter(c => c.value !== option.value);
            } else {
                finalAnswer = [ ...answer, option ]
            }            

            // NOte: Temporary fix for scpeacial scenario
            if(question.id === '1.3') {
                finalAnswer = option.value === '1.3.3' ? 
                [option] : // one answer only
                finalAnswer.filter(x => x.value !== '1.3.3') // remove the 1.3.3
            }

            if(onAnswer) onAnswer(finalAnswer); 
            return;   
        }

        if(onAnswer)onAnswer(option)
    }

    const selectedChoiceClassName = (option: iQuestionChoice) => {
        if( question.canSelectMultiple ) {
            const answer = getAnswer() as iQuestionOption[];
            const isSelected = answer.filter(c => c.value === option.value)
            return [isSelected.length > 0 ? 'selected' : '']
        }
        return [getAnswer() === option.value ? 'selected' : '']
    }

    // const renderIconName = (choice: iQuestionChoice) => {
    //     return getAnswer() === choice.value ? `${choice.icon}-white` : `${choice.icon}`
    // }

    const blockModifierClass = () => {
        if(!question?.options)return '';
        return question?.options[0].icon ? 'choice-image-kids-icon' : '';
    }

    return (
        <div className={'choice-image-kids-wrapper'}>
            <ul className={`${B(ageCategory?.value)} ${blockModifierClass()}`}>
                {
                    question.options && question.options.map(option => (
                        <li 
                            key={option.value}
                            className={E('item', selectedChoiceClassName(option))}                     
                            onClick={() => select(option)}>
                            
                            {
                                option.icon && 
                                <Icon type='png' 
                                name={option.icon}
                                className={E('choice')}/>
                            }

                            <label>
                                <span></span>
                                <div>{t(option.label)}</div>
                            </label>

                        </li>
                    ))
                }            
            </ul>
        </div>

    )
}

export default ChoiceImageKids;