import React from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import MeasureDataCard from './MeasureDataCard';

interface Props {

}

const MeasuresData : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('measures-data');
    const { measurementData } = useSelector(getMeasure);
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS
    
    // METHODS

    return (
        <div className={B()}>
            
            <div className={E('content')}>
                
                <div className={E('top')}>
                    <h1>{t('measures_mes__3_measures_new')}</h1>

                    {/* <Button icon="import" type="secondary" /> */}
                </div>    


                <div className={E('cards')}>

                    {
                        measurementData.one.map( (o,i) => (
                            <MeasureDataCard
                                key={i}
                                index={i}
                                one={o}
                                two={measurementData.two[i]}
                                three={measurementData.three[i]}
                            />
                        ))
                    }
                    
                    {
                        measurementData.one.length <= 1 && 
                        <MeasureDataCard empty={true} index={0}/>
                    }                    
                    
                </div>

            </div>

        </div>
    )
}

export default MeasuresData;