import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { getProducts } from '../../../store/slices/products.slice';
import iProductBrand from '../../../interface/product-brand.interface';
import iProductFamily from '../../../interface/product-family.interface';
import ProductCard from '../../../components/products/components/ProductCard';
import useBEM from '../../../utils/hooks/useBEM';
import Icon from '../../../components/ui/Icon';
import { useDrag } from 'react-use-gesture';
import useCarouselStyle from '../../../utils/hooks/useCarouselStyle';



interface Props {
    use?: 'homepage' | 'default'
}


const ProductSelectionHome : React.FC<Props>  = ({
    use = 'default'
}) => {

    // variables
    const [B, E] = useBEM('product-selectionHome');

    //hooks
    const [brands, setBrands] = useState<iProductBrand[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [showNav, setShowNav] = useState<Boolean>(false)
    const { temporaryProductList, demoDownloadStatus, downloadStatus } = useSelector(getProducts);
    const brandLength = brands.length - 1
    const [distance, setDistance] = useState<number>(0)
    const [direction, setDirection] = useState<number>(0)
    const [dragging, setDragging] = useState<boolean>(false)
    const [isTouchEnds, setIsTouchEnds] = useState<boolean>(false)

    const {styleSettings} = useCarouselStyle(distance, direction)
    const bindXPosition = useDrag((params) => {
        setDirection(params.direction[0])
        setDistance(params.distance)
        setDragging(params.dragging)
        const isTouchEnd =  params._lastEventType === 'pointerup'
        const isClickOnly = params._dragIsTap
        if(isTouchEnd && !isClickOnly){
            direction > 0 ? navigate('prev') : navigate('next')
        }
    })



    useEffect(() => {
        let tempBrands:iProductBrand[] = [];
        temporaryProductList.forEach((family: iProductFamily) => {
            tempBrands = [
                ...tempBrands,
                ...family.brands.filter( b => isDemoAvailable(b))
            ]
            setBrands(tempBrands);
        });

    }, [temporaryProductList, activeIndex])
    //functions
    const isDemoAvailable = (brand: iProductBrand) => {
        if(!brand.background)return false;
        const productsWithDemo = brand.products.filter( p => (p.demo || []).length > 0 && p.enabled);        
        return productsWithDemo.length > 0;
    }

    const indexSettings = (i:number) => {
        if(activeIndex === i){
        return 'active'
        }
        if((activeIndex - 1  === i) || (activeIndex === 0 && i === brandLength)){
            return 'left'
        }
        if((activeIndex + 1 === i && activeIndex !== brandLength) || (activeIndex === brandLength && i === 0)){
            return 'right'
        }

        return 'none'
    }

    const navigate = (action:'next' | 'prev') => {
        switch (action) {
            case 'next':
                return setActiveIndex(activeIndex === brandLength ?  0 : activeIndex + 1)
            case 'prev':
                return setActiveIndex(activeIndex === 0 ?  brandLength : activeIndex - 1)
            default:
                return
        }
    }


    return(
        <div className={B()} {...bindXPosition()}  style={{ touchAction: 'none'}}>
            {
                brands.map( (brand, i) => {
                    return (
                        <div className={E(`carousel-item`, indexSettings(i))} key={i} onClick={() => setActiveIndex(i)} style={styleSettings(indexSettings(i),dragging)}>
                            <ProductCard 
                                key={brand.id}
                                id={brand.id}
                                label={brand.label}
                                background={brand.background}
                                logo={brand.logo}
                                products={brand.products}
                                use={use}
                                />
                        </div>
                    )
                })     
            }


                <>
                    <div className={E('nav','next')} onClick={() => navigate('next')}>
                        <Icon name='next-arrow'/>
                    </div>

                    <div className={E('nav','prev')} onClick={() => navigate('prev')}>
                        <Icon name='next-arrow'/>
                    </div>
                </>

        </div>
    )
}

export default ProductSelectionHome;