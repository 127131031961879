import React from 'react';
import iMeasureStep from '../../../interface/measure-step.interface';
import useBEM from '../../../utils/hooks/useBEM';
import { useSelector } from 'react-redux';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';

interface Props {
    step: number,
    steps: iMeasureStep[],
    onChange: (step: number) => void,
}

const MeasureStepNavigation : React.FC<Props>  = ({
    step,
    steps,
    onChange
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('measure-step-navigation');
    const { t } = useTranslation(useSelector(getApp));
    const measuresTranslationKeys = ['pair_1_tab_del_p1__measure_1', 'pair_1_tab_del_p1_measure_2', 'pair_1_tab_del_p1_measure_3']    
    // HOOKS

    // METHODS
    const activeStepClass = (s: number) => {
        return s === step ? 'active' : ''
    }

    return (
        <div className={B()}>
            {
                steps.map( (s, index ) => (
                    <div 
                        key={index}
                        className={E('step', [activeStepClass( index )])} 
                        onClick={() => onChange( index)}>
                        
                        <label className={E('step-label')}>
                            {t(measuresTranslationKeys[index])}
                        </label>
                        <h3>
                            {t(s.navigationTitle)}
                        </h3>

                    </div>
                ))
            }
        </div>
    )
}

export default MeasureStepNavigation;