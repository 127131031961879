import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iLensThicknessCalculation from '../../interface/lens-thickness-calculation';
import LensPairType from '../../types/lens-pair.type';
import iLensThicknessStore from '../interface/lens-thickness-store.interface';
import iRootState from '../interface/root-state.interface';

const initialState: iLensThicknessStore  = {
    view: 'controls',
    tempBaseLensThicknessCalculation: {
        pair1: undefined,
        pair2: undefined
    },
    tempLensThicknessCalculation: {
        pair1: undefined,
        pair2: undefined
    },
    error:'',
    sliderValue: 0,
    sliderCount: 0,
}

const lensThicknessSlice = createSlice({
    name: 'lensThickness',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<'controls' | 'prescription'>) => ( {...state, view: action.payload} ),
        changePair: (state, action: PayloadAction<LensPairType>) => ( {...state, activePair: action.payload} ),
        setLensThickness: (state, action: PayloadAction<{
            pair1: number,
            pair2: number
        }>) => {
            return {
                ...state,
                tempLensThickness: action.payload
            }
        },

        saveBaseLensThickness: (state, action: PayloadAction<{
            pair: LensPairType,
            data: iLensThicknessCalculation | undefined
        }>) => ({
                ...state,
                tempBaseLensThicknessCalculation: {
                    ...state.tempBaseLensThicknessCalculation,
                    [action.payload.pair]: action.payload.data
                }
        }),
        saveLensThicknessDifference: (state, action: PayloadAction<{
            pair: LensPairType,
            data: iLensThicknessCalculation | undefined
        }>) => ({
                ...state,
                tempLensThicknessCalculation: {
                    ...state.tempLensThicknessCalculation,
                    [action.payload.pair]: action.payload.data
                }
        }),
        clearLensThicknessCalculationData: (state) => ({
                ...state,
                tempBaseLensThicknessCalculation: { pair1: undefined, pair2: undefined },
                tempLensThicknessCalculation: { pair1: undefined, pair2: undefined }
        }),
        setLensThicknessOnePair: (state, action: PayloadAction<{
            value: number | null,
            pair: LensPairType
        }>) => {
            const { pair, value } = action.payload;
            return {
                ...state,
                tempLensThickness: {
                    ...state.tempLensThickness,
                    [pair]: value
                }
            }
        },         
        resetData: (state) => ({...state, ...initialState, tempLensThickness: undefined}),
        changeError: (state, action: PayloadAction<'lensThickness' | 'prescription' | ''>) => ({...state, error: action.payload}),
        setSliderValue: (state, action: PayloadAction<number>) => ({...state, sliderValue: action.payload}),
        setSliderCount: (state, action: PayloadAction<number>) => void({...state, sliderCount: state.sliderCount+=action.payload})
        
    }
});

export const getlensThickness = (state:iRootState) => state.lensThickness;
export default lensThicknessSlice;