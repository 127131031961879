import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import EPROM_MOCK_DATA from '../../../constants/eprom.constants';
import iProductBrand from '../../../interface/product-brand.interface';
import iProduct from '../../../interface/product.inteface';
import { getApp } from '../../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import { getUser } from '../../../store/slices/user.slice';
import AgeCategoryType from '../../../types/age-category.type';
import LensFamilyType from '../../../types/lens-family.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {

}

const LensConsultationBrand : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-consultation-brand');
    const { productFamily, lensRecommendation, activePair, family } = useSelector(getLensConsultation);
    const dispatch = useDispatch();
    const { ageCategory } = useSelector(getUser);
    const appStore = useSelector(getApp);
    const {
        accountDetails,
        epromData
    } = appStore;
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS
    // METHODS

    const selectBrand = (brand: iProductBrand) => {
        dispatch(lensConsultationSlice.actions.selectProductBrand(brand));
        dispatch(lensConsultationSlice.actions.setSliderPage(2));
    }

    const isBrandAlreadySelected = (brand: iProductBrand) => {
        // Note: hide brand if already selected on protect
        if(productFamily?.id !== LensFamilyType.PROTECT) return false;
        if(!lensRecommendation)return false;
        const protect = lensRecommendation[activePair]?.protect;

        //products for belgium new business rule
        const productsFamilyBelgium = ['transitions', 'xperio']
        const productBelgiumXperioMirrors = ['transitions_xtractive', 'xperio_polarised','xperio_tinted', 'transitions_xtractive_polarised']

        //products italy bypass
        const italyProductsByPass = ['xperio_mirrors', 'xperio_tinted']
        //italy business rule 
        if(accountDetails?.shop?.country === 'it' && protect && protect[0]?.product){
            if(!italyProductsByPass.includes(protect[0]?.product?.id)){
                return brand.id !== 'xperio'
            }
            return !italyProductsByPass.includes(protect[0]?.product?.id)
        }
        

        if(accountDetails?.shop.country === 'be' && family === "protect" && protect && protect[0]?.product?.id){
            if(protect[0]?.product?.id === 'xperio_mirrors') return !productsFamilyBelgium.includes(brand.id)
            if(productBelgiumXperioMirrors.includes(protect[0]?.product?.id))return brand.id !== 'xperio'

        }

        if(!protect || protect.length === 0) return false;
        if (protect[0].brand !== undefined)
        return (protect[0] as any).brand.id === brand.id;
        else return false
    }

    const withAvailableProductsOnEprom = (brand: iProductBrand) => {
        if(
            !lensRecommendation ||
            !lensRecommendation[activePair]?.correct ||
            productFamily?.id === LensFamilyType.CORRECT ||
            !accountDetails?.shop.eprom
        )return true;
        const correctProductId = lensRecommendation[activePair]?.
            correct.subProduct?.code;

        if(!epromData || !correctProductId)return false;
        const epromProducts = epromData[correctProductId];
        const targetProducts = productFamily?.id === LensFamilyType.PROTECT ? 'masstint': 'treatment';

        if(!epromProducts || !epromProducts[targetProducts])return false;

        const epromProductIds = Object.keys(epromProducts[targetProducts] as any);
        const branchProductIds = brand.products.map( x => x.id);
        const isWithAvailableProducts = branchProductIds.filter( x => epromProductIds.includes(x));
        return !!isWithAvailableProducts.length;
    }

    const getClassName = (brand: iProductBrand) => {
        let show = false
        let productCtr = 0

        brand.products.map((product: iProduct) => {
            if (product.enabled) {
                if (
                    product.ageCategoryId === ageCategory?.value ||
                    product.ageCategoryId === AgeCategoryType.BOTH
                ) {
                    productCtr++
                    show = true
                    return
                }
                if (
                    product.ageCategoryId === AgeCategoryType.PRODUCT_YOUNG_ADULT &&
                    product.ageCategoryId.includes(String(ageCategory?.value))
                ) {
                    productCtr++
                    show = true
                    return
                }
            }
        })

        if (productCtr > 0) show = true
        return show ? '' : 'disabled'
    }


    return (
        <div className={B()}>

            {/* <Button
                click={back}
                text={lensType?.label}
                icon="left-arrow-circle-gray" type="secondary"
                iconPosition="left"/> */}


            <ul className={E('list')}>
                {
                    productFamily?.brands
                    .filter(withAvailableProductsOnEprom)
                    .map( (brand) => (
                        !isBrandAlreadySelected(brand) && 
                        <li key={brand.id} onClick={() => selectBrand(brand)} className={getClassName(brand)}>
                            {t(brand.label)}
                            <Icon name="right-arrow-circle-blue"/>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default LensConsultationBrand;
