import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import routes from '../constants/routes.constant';
import { getApp } from '../store/slices/app.slice';
import useGoogleAnalytics from '../utils/hooks/useGoogleAnalytics';
import useGoto from '../utils/hooks/useGoto';
import ReactGA from 'react-ga';
const RouteChangeTracker = () => {

    // const ga = useGoogleAnalytics();
    const goto = useGoto();
    const { accountDetails } = useSelector(getApp);
    const country = accountDetails?.shop?.country
    const isUSCountry = country && ['us'].includes(country)
    const enableVarilux = country && ['ro','bg','de','pt','gb','be','es'].includes(country)
    const location = useLocation();

    useEffect(() => {
        routeGuard();
        ReactGA.pageview(location.pathname)
    }, [location]);

    const routeGuard = () => {
        if(
            (!accountDetails || accountDetails.state !== "Active") && 
            location.pathname !== '/license-activation' && 
            location.pathname !== '/legal-notices' &&
            location.pathname !== '/variluxG9'
        ) {
            setTimeout(() => {
                goto.route(routes.licenseActivation);
            },100);
            
        }

        if(accountDetails && !enableVarilux && location.pathname === '/variluxG9'){
            setTimeout(() => {
                goto.route(routes.home);
            },100);
        }
    }

    return <div></div>;
};

export default withRouter(RouteChangeTracker);
