import React, { useEffect } from 'react';
import useBEM from '../../utils/hooks/useBEM';
import PrescriptionLensProposal from './components/PrescriptionLensProposal';
import PrescriptionForm from './components/PrescriptionForm';
import PrescriptionSide from './components/PrescriptionSide';
import { useDispatch, useSelector } from 'react-redux';
import prescriptionSlice, { getPrescription } from '../../store/slices/prescription.slice';
import PrescriptionViewType from '../../types/prescription-view.type';

interface Props {

}

const Prescription : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription');
    const { view } = useSelector(getPrescription);
    const dispatch = useDispatch();

    // HOOKS
    useEffect(() => {
        return () => {
            dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.PRESCRIPTION))
            dispatch(prescriptionSlice.actions.resetData());
        }
    },[])

    // METHODS

    return (
        <div className={B() + ' page'}>
            
            <PrescriptionSide />
            
            <div className={E('main')}>

                { view === 'prescription' && <PrescriptionForm/> }        
                { view === 'lens-proposal' && <PrescriptionLensProposal/> }        
                
            </div>
        </div>
    )
}

export default Prescription;