import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iLicenseStore from '../interface/license-store.interface';
import iRootState from '../interface/root-state.interface';

const initialState: iLicenseStore  = {
    status: 'inactive'
}

const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {
        updateStatus: (state, action: PayloadAction<'inactive' | 'active'> ) => ({...state, status: action.payload }),
    }
});

export const getLicense = (state:iRootState) => state.license;
export default licenseSlice;