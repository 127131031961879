import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iProductBrand from '../../../interface/product-brand.interface';
import iProductFamily from '../../../interface/product-family.interface';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import useBEM from '../../../utils/hooks/useBEM';
import Button from '../../ui/Button';
import ProductCard from './ProductCard';

import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import iProduct from '../../../interface/product.inteface';
import iProductDemo from '../../../interface/product-demo';
import iLensDemo from '../../../interface/lens-demo';
import ProductDemoType from '../../../types/product-demo.type';
import DemoDownloadType from '../../../types/product-download.type';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import Utils from '../../../utils/Utils';
import Alert from '../../ui/Alert';
import Loading from '../../Loading';
import useDragScroll from './../../../utils/hooks/useDragScroll';

interface Props {

}

const ProductSelection : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('product-selection');
    const { temporaryProductList, demoDownloadStatus, downloadStatus } = useSelector(getProducts);
    const [fadeIn, setFadeIn] = useState(false);
    const [brands, setBrands] = useState<iProductBrand[]>([]);
    const [dynamicWidth, setDynamicWidth] = useState<string>('');
    const { t } = useTranslation(useSelector(getApp));
    const {get, insert} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const { downloadAssetBase64 } = Utils();
    const [ preloadingComplete, setPreloadingComplete ] = useState(true);
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const ref = useRef<any>();
    const { listen, stop } = useDragScroll(ref.current);

    // HOOKS
    useEffect(() => {
        showDownloadAlertOnFirsttime();
        setTimeout(() => {
            setFadeIn(true);
        }, 500);
        return () => {
            dispatch(productsSlice.actions.resetDownloadPendingStatus());
        }
    }, []);

    useEffect(() => {
        document.querySelector('.product-card--open')?.scrollIntoView();

        listen();
        return () => {
            stop();
        }
    }, [brands])

    useEffect(() => {
        let tempBrands:iProductBrand[] = [];
        temporaryProductList.forEach((family: iProductFamily) => {
            tempBrands = [
                ...tempBrands,
                ...family.brands.filter( b => isDemoAvailable(b))
            ]
        });
        const newBrands = tempBrands.map( b => {
            return { 
                ...b,
                download: downloadStatusIntinialData(b.id)
            }
        })
        setBrands(newBrands);
        setDynamicWidth(`${(tempBrands.length * 30)}rem`)

    }, [temporaryProductList])

    // METHODS

    const showDownloadAlertOnFirsttime = () => {
        const isFirstTime = localStorage.getItem('product-demo-accessed');
        if(!isFirstTime)setShowAlert(true);
        localStorage.setItem('product-demo-accessed', 'true');
    }

    const downloadStatusIntinialData = (brandId: string) => {
        const initial = (demoDownloadStatus || {})[brandId];
        return initial ? initial : {
            count: 0,
            total: 0,
            status: DemoDownloadType.WAITING
        }
    }

    const isDemoAvailable = (brand: iProductBrand) => {
        if(!brand.background)return false;
        const productsWithDemo = brand.products.filter( p => (p.demo || []).length > 0 && p.enabled);        
        return productsWithDemo.length > 0;
    } 

    const downloadAllDemo = async () => {
        brands.map((brand, brandIndex) => {
            
            const downloadInfo = demoDownloadStatus[brand.id];
            if(downloadInfo && downloadInfo.status === DemoDownloadType.COMPLETED)return;

            brand.products.map(async (product) => {
                await initBrandCount(brandIndex);
                await getAssetLength(product, brandIndex);
            })
        })
    }

    const isAlreadyPreloaded = async (demo: string, productId: string) => {
        const id = `${demo}_${productId}`;
        const asset = await get('lensDemoStore',id);
        return asset ? true : false; 
    }

    const isNested = (type: ProductDemoType) => {
        return [
            ProductDemoType.NESTED_DEMO, 
            ProductDemoType.NESTED_DEMO_TRANSVERSAL
        ].includes(type)
    }

    const getAssetLength = async (product: iProduct, brandIndex: number) => {
        const toPreloadAssets: any[] = [];
        product?.demo?.forEach( d => {

            if(d.is360){
                (d.products as iProductDemo[]).forEach( (p) => {

                    if(!p.drawline)return;

                    const leftright = Object.keys(p.drawline);
                    for(const posKey of leftright){
                        const pos = (p.drawline as any)[posKey];
                        const blurline = Object.keys(pos);
                        for(const imgType of blurline){
                            const id = `${p.id}_${posKey}-${imgType}`;
                            toPreloadAssets.push({
                                demo: d.demo,
                                productId: id,
                                asset: pos[imgType]
                            });

                        }
                    }
                });
                return;
            }

            if(isNested(d.demoType)) {
                (d.products as iLensDemo[]).forEach( d => {
                    (d.products as iProductDemo[]).forEach( p => {
                        if(!p.asset)return;
                        toPreloadAssets.push({
                            demo: d.demo,
                            productId: p.id,
                            asset: p.asset
                        });
                    }) 
                });
                return;
            }

            // Note: stellest start
            let additionalAssets: any[] = [];
            if(d.control) {
                d.control.products.forEach( ( chp: any )=> {
                    if(!chp.asset)return;
                    toPreloadAssets.push({
                        demo: d.control?.demo,
                        productId: chp.id,
                        asset: chp.asset
                    });
                });

                d.control.children?.forEach( (ctrlChild) => {                    
                    ctrlChild.products.forEach( ( ctrlChildProduct: any )=> {
                        if(!ctrlChildProduct.asset)return;
                        toPreloadAssets.push({
                            demo: ctrlChild?.demo,
                            productId: ctrlChildProduct.id,
                            asset: ctrlChildProduct.asset
                        });
                    });
                })
            }

            const children = d.children;
            if(children && children.length > 0 ) {
                children.forEach( ch => {
                    ch.products.forEach( ( chp: any )=> {
                        if(!chp.asset)return;
                        toPreloadAssets.push({
                            demo: ch.demo,
                            productId: chp.id,
                            asset: chp.asset
                        });
                    });
                });
            }

            (d.products as iProductDemo[]).forEach( (p) => {
                if(!p.asset)return;
                toPreloadAssets.push({
                    demo: d.demo,
                    productId: p.id,
                    asset: p.asset
                });
            });
        });
        let newBrands = [ ...brands ];
        newBrands.forEach((brand, newBrandIndex) => {
            if (newBrandIndex === brandIndex) {
                brand.download = {
                    count: 0,
                    total: toPreloadAssets.length + (brand.download?.total || 0)
                }
            }
        })
        setBrands(newBrands);
        setPreloadingComplete(false);
        dispatch(productsSlice.actions.changeDownloadStatus(DemoDownloadType.DOWNLOADING));
        
        for (const asset of toPreloadAssets) {
            const isAlreadyDownloaded = await isAlreadyPreloaded(asset.demo, asset.productId)
            
            if(!isAlreadyDownloaded){
                const base64 = await downloadAssetBase64(asset.asset);
                await insert('lensDemoStore', {
                    ...asset, 
                    id: `${asset.demo}_${asset.productId}`,
                    asset: base64,
                });
            } 
            updateBrands(newBrands, brandIndex, updateDonwloadStatusOnStore )
            setBrands(newBrands);
        }
        const completedBrands = newBrands.filter(brand => brand.download?.status === 'completed');
        let count = completedBrands.length || 0;
        dispatch(productsSlice.actions.changeDownloadCount(`${count} of ${newBrands.length}`));
        if (completedBrands.length === newBrands.length) {
            setPreloadingComplete(true);
            setLoading(false);
            dispatch(productsSlice.actions.changeDownloadStatus(DemoDownloadType.COMPLETED))
            // To reset the count
            dispatch(productsSlice.actions.changeDownloadCount(`${0} of ${newBrands.length}`));
        }
        
        return toPreloadAssets.length;
    }

    const updateDonwloadStatusOnStore = (brandId: string, info: {
        count: number,
        total?: number,
        status?: DemoDownloadType
    }) => {
        dispatch(productsSlice.actions.updateDownloadStatus({
            brandId,
            info
        }))
    }

    const updateBrands = ( 
        newBrands: iProductBrand[], 
        brandIndex: number, 
        onDoneUpdate: (brandId: string, downloadInfo: {
            count: number,
            total?: number,
            status?: DemoDownloadType
        }) => void 
    ) => {
        newBrands.forEach((brand, newBrandIndex) => {
            if (newBrandIndex === brandIndex) {
                let status = DemoDownloadType.DOWNLOADING;
                let count = brand.download?.count || 0;
                let total = brand.download?.total || 0;
                if (count >= (total-1)) {
                    status = DemoDownloadType.COMPLETED;
                }
                brand.download = {
                    count: (brand.download?.count || 0) + 1,
                    total: (brand.download?.total || 0),
                    status: status
                }

                onDoneUpdate(brand.id, brand.download)
            }
        })
    }

    const initBrandCount = (brandIndex: number) => {
        brands.forEach((brand, index) => {
            if (index === brandIndex) {
                brand.download = {
                    count: 0,
                    total: 0
                }
            }
        })
        setBrands(brands);
    }

    const onAlertConfirm = (id: string) => {
        setShowAlert(false);        
        if(id !== 'yes')return;
        setLoading(downloadStatus !==  DemoDownloadType.COMPLETED);
        downloadAllDemo();
    }

    const XRaddition = () => {
        let variluxProducts = brands[0]?.products
        
        let XRProduct = {
            id: "varilux_xr_series",
            label: "Varilux® XR series™",
            lensDesign: "progressive"
        }

    }

    XRaddition()

    return (
        <div className={B( fadeIn ? 'fade-in' : '')}>
            <div className={E('scroll')} ref={ref}>
                <ul className={E('list')} style={{width: dynamicWidth}}>
                    {
                        brands.map( brand => {
                            return (
                                <ProductCard 
                                    key={brand.id}
                                    id={brand.id}
                                    label={brand.label}
                                    background={brand.background}
                                    logo={brand.logo}
                                    products={brand.products}
                                    download={brand.download}
                                />
                            )
                        })     
                    }
                </ul>

            </div>
            {
                downloadStatus !== 'completed' &&
                    <Button
                    text={t('demo_download_all')}
                    type="secondary" weight="normal"
                    iconPosition="right"
                    outlineType="colored"
                    className={E('download')}
                    click={() => setShowAlert(true)}
                    disabled={!preloadingComplete}
                    icon="download"
                    />
            }

            <Alert 
                show={showAlert}
                message={t('demo_downloading_confirmation')} 
                buttons={[
                    { label: t('demo_downloading_conf_yes'), id: 'yes' },
                    { label: t('demo_downloading_conf_no'), id: 'no' }
                ]}
                onButtonSelect={(id) => onAlertConfirm(id)}
            />
            <Loading show={isLoading}/>
        </div>
    )
}

export default ProductSelection;