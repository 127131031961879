import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import iProductCombi from '../../../store/interface/product-combi.interface';
import { getApp } from '../../../store/slices/app.slice';
import { getProducts } from '../../../store/slices/products.slice';
import LensFamilyType from '../../../types/lens-family.type';
import useBEM from '../../../utils/hooks/useBEM';
import useProducts from '../../../utils/hooks/useProducts';
import useTranslation from '../../../utils/hooks/useTranslation';
import AdvancePriceModifierInput from './AdvancePriceModifierInput';

interface Props {
    list: iProductCombi[],
    currency: string,
    onUpdateItem: (
        id: string, 
        field: 'correctEnhanceModifier' | 'protectModifier' | 'flatFee',
        value: string, 
        type?: 'actual' | 'percent',
    ) => void
}

const AdvanceSettingFilterResults : React.FC<Props>  = ({
    list = [],
    onUpdateItem,
    currency
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('advance-setting-filter-results');
    const { getProductLabelById }  = useProducts(useSelector(getProducts));
    const { t } = useTranslation(useSelector(getApp));

    // METHODS
    const getLabel = (productId: string, familyType: string, subProductId?: string) => {
        if(!productId)return '';

        return getProductLabelById({ 
            productId, 
            familyType: familyType as LensFamilyType,
            subProductId,
        });
    }

    return (
        <div className={B()}>
            <table>
                <thead>
                    <tr>
                        <th> {t('advancedpricing_correct')} </th>
                        <th> {t('advancedpricing_protect_-_1')} </th>
                        <th> {t('advancedpricing_protect_-_2')} </th>
                        <th> {t('advancedpricing_enhance')} </th>
                        <th> {t('advancedpricing_index')} </th>
                        <th> {t('advancedpricing_price')} </th>
                        <th> 
                            {t('advancedpricing_price_modifier_for_correct_+_enhance')}
                        </th>
                        <th> 
                            {t('advancedpricing_price_modifier_for_protect_1_&_2')}
                        </th>
                        <th> 
                            {t('advancedpricing_flat_fee')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length === 0 ?
                        <tr>
                            <td colSpan={9}>
                                No Results found!
                            </td>
                        </tr> : 
                        list.map( (p, indx) => (
                            <tr key={indx}>
                                <td>
                                    { getLabel(p.correct, 'correct', p.correct_sub) }
                                </td>
                                <td>
                                    { getLabel(p.protect1, 'protect') }
                                </td>
                                <td>
                                    { getLabel(p.protect2, 'protect') }
                                </td>
                                <td>
                                    { getLabel(p.enhance, 'enhance') }
                                </td>
                                <td>
                                    {p.index}
                                </td>
                                <td>
                                    {p.price} {currency}
                                </td>
                                <td className="center">
                                    <AdvancePriceModifierInput 
                                        currency={currency}
                                        type={p.correctEnhanceModifier?.type} 
                                        value={p.correctEnhanceModifier?.value || ''}                                  
                                        onChange={(val,type) => onUpdateItem(p.id as string, 'correctEnhanceModifier', val, type)}
                                    />

                                </td>
                                <td className="center">
                                    <AdvancePriceModifierInput 
                                        currency={currency}
                                        type={p.protectModifier?.type} 
                                        value={p.protectModifier?.value || ''}                                  
                                        onChange={(val,type) => onUpdateItem(p.id as string, 'protectModifier', val, type)}                                    
                                    />
                                </td>
                                <td className="center">
                                    <AdvancePriceModifierInput 
                                        currency={currency}
                                        value={p.flatFee?.value || ''}
                                        onChange={(val) => onUpdateItem(p.id as string, 'flatFee', val)}    
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default AdvanceSettingFilterResults;