import iLensProposalField from "../interface/lens-proposal-field.interface";
import AgeCategoryType from "../types/age-category.type";
import ComplementaryEquipmentType from "../types/complementary-equipment.type";
import LensCategoryType from "../types/lens-category.type";
import LensEnhanceType from "../types/lens-enhance.type";
import LensFamilyType from "../types/lens-family.type";
import LensProtectType from "../types/lens-protect.type";

const GENERAL_EQUIPMENTS: iLensProposalField[] = [
    {
        field: LensFamilyType.CORRECT,
        label: 'prescription_rx_correct',
        required: true,
        options: [
            {
                label: 'prescription_rx_progressive',
                value: LensCategoryType.PROGRESSIVE,
                ageCategories: [
                    AgeCategoryType.ADULT,
                    AgeCategoryType.YOUNG_ADULT
                ]
            },
            {
                label: 'prescription_rx_single_vision',
                value: LensCategoryType.SINGLE_VISION
            },
            {
                label: 'prescription_rx_personalized',
                value: LensCategoryType.PERSONALIZED,
                ageCategories: [
                    AgeCategoryType.ADULT,
                    AgeCategoryType.YOUNG_ADULT
                ]
            },
            {
                label: 'prescription_rx_myopia_control_',
                value: LensCategoryType.MYOPIA,
                ageCategories: [
                    AgeCategoryType.KIDS
                ]
            }
        ]
    },
    {
        field: LensFamilyType.PROTECT,
        label: 'prescription_rx_protect',
        options: [
            {
                label: 'prescription_rx_blue_cut',
                value: LensProtectType.BLUE_CUT
            },
            {
                label: 'prescription_rx_photochromic',
                value: LensProtectType.PHOTOCROMIC
            },
            {
                label: 'prescription_rx_tinted_lens',
                value: LensProtectType.TINTED,
            }
        ]
    },
    {
        field: LensFamilyType.ENHANCE,
        label: 'prescription_rx_enhance',
        options: [
            {
                label: 'prescription_rx_ar_lens',
                value: LensEnhanceType.AR
            }
        ]
    }
];

const COMPLEMENTARY_EQUIPMENTS:iLensProposalField[] = [
    {
        field: 'complementaryEquipment',
        options: [
            {
                label: 'prescription_rx_driving',
                value: ComplementaryEquipmentType.DRIVING,
                ageCategories: [
                    AgeCategoryType.ADULT,
                    AgeCategoryType.YOUNG_ADULT
                ]
            },
            {
                label: 'prescription_rx_computer',
                value: ComplementaryEquipmentType.COMPUTER
            },
            {
                label: 'prescription_rx_sun',
                value: ComplementaryEquipmentType.SUN
            }
        ]
    }
]

let currentCountry: string = localStorage.getItem("country") !== null ? (localStorage.getItem("country") as string) : (localStorage.getItem("country") as string)
const countryWithXR: string[] = ["ro", "de", "pt", "bg", "gb", "es", "be", 'sl', 'hr', 'gr', "ph"]
let isXrEnable: boolean = countryWithXR.includes(currentCountry) 
const RX_LENS_RECOMMENDATIONS: any = {
    adult: {
        pair1: {
            correct: [
                {
                    category: LensCategoryType.PROGRESSIVE,
                    product: isXrEnable ? "varilux_xr_series" : "varilux_x_series"
                    // product: "varilux_x_series"
                },
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eyezen_start'
                }
            ],
            protect: [
                {
                    category: LensProtectType.BLUE_CUT,
                    product: 'eye_protect_system'
                },
                {
                    category: LensProtectType.PHOTOCROMIC,
                    product: 'transitions_signature_gen_8'
                },
                {
                    category: LensProtectType.TINTED,
                    product: 'xperio_tinted'
                }
            ],
            enhance: [
                {
                    category: LensEnhanceType.AR,
                    product: 'crizal_sapphire'
                },
                {
                    category: LensProtectType.TINTED,
                    product: 'crizal_sun_xprotect'
                },
            ]
        },

        pair2: {
            correct: [
                {
                    category: LensCategoryType.SINGLE_VISION,
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eyezen_start'
                },
                {
                    category: LensCategoryType.PROGRESSIVE,
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'varilux_digitime'
                }
            ],
            protect: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eye_protect_system'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'xperio_polarised'
                },
                {
                    equipment: ComplementaryEquipmentType.DRIVING,
                    product: 'xperio_polarised'
                }
            ],
            enhance: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'crizal_prevencia'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: ['crizal_sun_xprotect', 'crizal_forte']
                },
                {
                    equipment: ComplementaryEquipmentType.DRIVING,
                    product: ['crizal_sun_xprotect', 'crizal_drive']
                }
            ]
        }
    },
    kids: {
        pair1: {
            correct:[
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eyezen_kids'
                },
                {
                    category: LensCategoryType.MYOPIA,
                    product: 'stellest'
                },
            ],
            protect:[
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eye_protect_system'
                },
                {
                    category: LensProtectType.PHOTOCROMIC,
                    product: 'transitions_signature_gen_8'
                },
            ],
            enhance:[
                {
                    category: LensCategoryType.MYOPIA,
                    product: 'crizal_aliz'
                },
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'crizal_easy'
                },
            ]
        },
        pair2: {
            correct: [
                {
                    product: 'eyezen_kids'
                },
            ],
            protect: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eye_protect_system'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'transitions_signature_gen_8'
                },
            ],
            enhance: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'crizal_prevencia'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'crizal_sapphire'
                },
            ]
        }
    }
}
const RX_LENS_RECOMMENDATIONS_WITH_XR: any = {
    adult: {
        pair1: {
            correct: [
                {
                    category: LensCategoryType.PROGRESSIVE,
                    product: "varilux_xr_series",
                },
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eyezen_start'
                }
            ],
            protect: [
                {
                    category: LensProtectType.BLUE_CUT,
                    product: 'eye_protect_system'
                },
                {
                    category: LensProtectType.PHOTOCROMIC,
                    product: 'transitions_signature_gen_8'
                },
                {
                    category: LensProtectType.TINTED,
                    product: 'xperio_tinted'
                }
            ],
            enhance: [
                {
                    category: LensEnhanceType.AR,
                    product: 'crizal_sapphire'
                },
                {
                    category: LensProtectType.TINTED,
                    product: 'crizal_sun_xprotect'
                },
            ]
        },

        pair2: {
            correct: [
                {
                    category: LensCategoryType.SINGLE_VISION,
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eyezen_start'
                },
                {
                    category: LensCategoryType.PROGRESSIVE,
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'varilux_digitime'
                }
            ],
            protect: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eye_protect_system'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'xperio_polarised'
                },
                {
                    equipment: ComplementaryEquipmentType.DRIVING,
                    product: 'xperio_polarised'
                }
            ],
            enhance: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'crizal_prevencia'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: ['crizal_sun_xprotect', 'crizal_forte']
                },
                {
                    equipment: ComplementaryEquipmentType.DRIVING,
                    product: ['crizal_sun_xprotect', 'crizal_drive']
                }
            ]
        }
    },
    kids: {
        pair1: {
            correct:[
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eyezen_kids'
                },
                {
                    category: LensCategoryType.MYOPIA,
                    product: 'stellest'
                },
            ],
            protect:[
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eye_protect_system'
                },
                {
                    category: LensProtectType.PHOTOCROMIC,
                    product: 'transitions_signature_gen_8'
                },
            ],
            enhance:[
                {
                    category: LensCategoryType.MYOPIA,
                    product: 'crizal_aliz'
                },
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'crizal_easy'
                },
            ]
        },
        pair2: {
            correct: [
                {
                    product: 'eyezen_kids'
                },
            ],
            protect: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eye_protect_system'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'transitions_signature_gen_8'
                },
            ],
            enhance: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'crizal_prevencia'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'crizal_sapphire'
                },
            ]
        }
    }
}

const RX_LENS_RECOMMENDATIONS_RUSSIA: any = {
    adult: {
        pair1: {
            correct: [
                {
                    category: LensCategoryType.PROGRESSIVE,
                    product: 'varilux_comfort_max'
                },
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eyezen_start'
                }
            ],
            protect: [
                {
                    category: LensProtectType.BLUE_CUT,
                    product: 'eye_protect_system'
                },
                {
                    category: LensProtectType.PHOTOCROMIC,
                    product: 'transitions_signature_gen_8'
                },
                {
                    category: LensProtectType.TINTED,
                    product: 'xperio_tinted'
                }
            ],
            enhance: [
                {
                    category: LensEnhanceType.AR,
                    product: 'crizal_sapphire'
                },
                {
                    category: LensProtectType.TINTED,
                    product: 'crizal_sun_xprotect'
                },
            ]
        },

        pair2: {
            correct: [
                {
                    category: LensCategoryType.SINGLE_VISION,
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eyezen_start'
                },
                {
                    category: LensCategoryType.PROGRESSIVE,
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'varilux_comfort_max'
                }
            ],
            protect: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eye_protect_system'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'xperio_polarised'
                },
                {
                    equipment: ComplementaryEquipmentType.DRIVING,
                    product: 'xperio_polarised'
                }
            ],
            enhance: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'crizal_prevencia'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: ['crizal_sun_xprotect', 'crizal_forte']
                },
                {
                    equipment: ComplementaryEquipmentType.DRIVING,
                    product: ['crizal_sun_xprotect', 'crizal_drive']
                }
            ]
        }
    },
    kids: {
        pair1: {
            correct:[
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eyezen_kids'
                },
                {
                    category: LensCategoryType.MYOPIA,
                    product: 'stellest'
                },
            ],
            protect:[
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'eye_protect_system'
                },
                {
                    category: LensProtectType.PHOTOCROMIC,
                    product: 'transitions_signature_gen_8'
                },
            ],
            enhance:[
                {
                    category: LensCategoryType.MYOPIA,
                    product: 'crizal_aliz'
                },
                {
                    category: LensCategoryType.SINGLE_VISION,
                    product: 'crizal_easy'
                },
            ]
        },
        pair2: {
            correct: [
                {
                    product: 'eyezen_kids'
                },
            ],
            protect: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'eye_protect_system'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'transitions_signature_gen_8'
                },
            ],
            enhance: [
                {
                    equipment: ComplementaryEquipmentType.COMPUTER,
                    product: 'crizal_prevencia'
                },
                {
                    equipment: ComplementaryEquipmentType.SUN,
                    product: 'crizal_sapphire'
                },
            ]
        }
    }
}


export {
    GENERAL_EQUIPMENTS,
    COMPLEMENTARY_EQUIPMENTS,
    RX_LENS_RECOMMENDATIONS,
    RX_LENS_RECOMMENDATIONS_RUSSIA,
    RX_LENS_RECOMMENDATIONS_WITH_XR
}
