import React from 'react'
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

type Props = {
    finalDemoType?:string
    isCompareEnabled?:boolean
}
const DemoTextOverlay: React.FC<Props> = ({finalDemoType, isCompareEnabled}) => {
  
  // functions 
  const classExtender = (demoType?:string, isCompareEnabled?:boolean) => {
    if(demoType === 'competitor' && isCompareEnabled)return '-compare'
    if(demoType === 'competitor' && !isCompareEnabled)return ''
    if(demoType === 'competitor2')return '2'
    else return
  }

  //variables
  const classExtension = classExtender(finalDemoType, isCompareEnabled)
  const [B] = useBEM(`text-overlay${classExtension}`);
  const { t } = useTranslation(useSelector(getApp));
  const text = finalDemoType === 'competitor' ? 'product_demo_text_overlay' : 'product_demo_text_overlay2'

  return (
    <div className={B()}>
        <span>
            {t(text)}
        </span>
    </div>
  )
}

export default DemoTextOverlay