import { is } from 'immer/dist/internal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import iLensDemo from '../../../interface/lens-demo';
import iLensColor from '../../../interface/lens-color.inteface'
import iProductDemo from '../../../interface/product-demo';
import { getApp } from '../../../store/slices/app.slice';
import productDemoSlice, { getProductDemo } from '../../../store/slices/products-demo.slice';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import ExperienceType from '../../../types/experience.type';
import ProductDemoType from '../../../types/product-demo.type';
import useBEM from '../../../utils/hooks/useBEM';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../../utils/hooks/useTranslation';
import { DEMO_COLORS_MODIFICATIONS } from '../../../constants/lens-consultation-modified-colors';

interface Props {
    hideDemo?: iProductDemo,
    selectedDemo: iProductDemo,
    forceDisableSelect?: boolean,
    onSelectProduct?: (productDemo: iProductDemo | iLensDemo) => void
}

const ProductDemoSelection: React.FC<Props> = ({
    hideDemo,
    selectedDemo,
    forceDisableSelect,
    onSelectProduct
}) => {
    // VARIABLES
    const [B, E] = useBEM('product-demo-selection');
    const {
        productDescription,
        isCompareEnabled,
        demoType,
        subDemoType,
        seeThroughActivated,
        demoFrom
    } = useSelector(getProductDemo);
    const [isOpenSubProducts, setIsOpenSubProducts] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));
    const [finalDemoType, setFinalDemoType] = useState<iLensDemo>();
    const { isOpen, enabledProducts, productToDemo, isQrCodeOpen, brandToDemo } = useSelector(getProducts);
    const ga = useGoogleAnalytics();
    const appStore = useSelector(getApp);
    const { experience, accountDetails} = appStore;
    const availableColors:iLensColor[] | undefined = productToDemo?.colors
    const isSlovakia = accountDetails?.shop.country === 'sk'
    const countryLicense = accountDetails?.shop.country


    // HOOKS
    useEffect(() => {
        if (demoType && [
            ProductDemoType.NESTED_DEMO_TRANSVERSAL,
            ProductDemoType.NESTED_DEMO
        ].includes(demoType?.demoType)) {
            setFinalDemoType(subDemoType || demoType);
            return;
        }
        setFinalDemoType(demoType);
    }, [subDemoType, demoType])

    // METHODS
    const setDescription = (desc: string[] | null) => {
        dispatch(productDemoSlice.actions.setProductDescription(desc))
    }

    const showSubProducts = (isShow: boolean) => {
        if (disableProductSelection()) return;
        setDescription(null);
        setIsOpenSubProducts(isShow);
        dispatch(productDemoSlice.actions.disableControls(isShow));
    }
    const subProductModifierClass = () => {
        const classes = [demoType?.demoType as string];
        if (isOpenSubProducts) classes.push('open');
        if (isCompareEnabled) classes.push('compare');
        return classes;
    }

    const selectProduct = (product: iProductDemo | iLensDemo) => {
        if (selectedDemo.id === (product as iProductDemo).id) return;

        const eventAction = isOpen ? 'Product' : 'LC';
        // alert('b');

        if (demoType?.demoType === ProductDemoType.NESTED_DEMO) {
            const p = product as iLensDemo;
            ga.event({
                category: `Demo - ${t((p.products[0] as iProductDemo).label, 'enUS')}`,
                action: `Demonstration - ${eventAction}`,
                label: `See Demo - ${p.demo}`
            })
        } else {
            const p = product as iProductDemo;
            ga.event({
                category: `Demo - ${t(p.label, 'enUS')}`,
                action: `Demonstration - ${eventAction}`,
                label: `See Demo - ${demoType?.demo}`
            })
        }

        if (onSelectProduct) onSelectProduct(product);
        showSubProducts(false);
    }

    const disableProductSelection = () => {
        if (
            forceDisableSelect ||
            demoType?.demoType === ProductDemoType.SEE_THROUGH_IMAGE ||
            demoType?.demoType === ProductDemoType.SEE_THROUGH_VIDEO
        ) return true;

        if (demoType?.demoType === ProductDemoType.NESTED_DEMO) {
            return false;
        }

        return finalDemoType && finalDemoType?.products.length < (isCompareEnabled ? 3 : 2);
    }

    const showProduct = (list: string[] | undefined, id: string) => {
        if (
            id.toLocaleLowerCase().includes('standard') ||
            id.includes(productToDemo?.id || '')
        ) return true;
        return (list || []).includes(id)
    }

    const checkedColorFromBO = (label:string, country?: string) =>{

        let hasColorModications = country && ['co', 'gr'].includes(country) 

        if (hasColorModications) {
            if (!country || !productToDemo) return

            let newColors = DEMO_COLORS_MODIFICATIONS?.[country]?.[productToDemo.id]
          
            if (newColors !== undefined) {
                let filteredColors = newColors.filter(color => color.label === label)
                return filteredColors && filteredColors.length > 0
            } else {
                const colors = availableColors?.filter(color => color.label === label)
                return colors && colors?.length > 0 || true
            }
        } else {
            const colors = availableColors?.filter(color => color.label === label)
            return colors && colors?.length > 0 || true
        }
    }

    const renderSubProducts = () => {
        if (!finalDemoType) return;
        if (demoType?.demoType === ProductDemoType.NESTED_DEMO) {
            return (demoType?.products as iLensDemo[]).map((pp, index) => {
                const product = pp.products[0] as iProductDemo;
                if (!showProduct(enabledProducts, product.id)) return null;

                return (
                    <button
                        key={index} onClick={() => selectProduct(pp)}
                        className={E('product', [subDemoType?.demo === pp.demo ? 'active' : ''])}
                    >
                        {t(product.label)}
                    </button>
                )
            })
        }
        
        return (finalDemoType?.products as iProductDemo[]).map((product, index) => {
            if (
                (hideDemo && hideDemo.id === product.id) ||
                !showProduct(enabledProducts, product.id)
            ) return null;

            if(finalDemoType?.demo === 'packshot-mirrors')
                return (
                    checkedColorFromBO(product.label, countryLicense) &&
                    <button
                    key={index} onClick={() => selectProduct(product)}
                    className={E('product', [selectedDemo.id === product.id ? 'active' : ''])}
                >
                    {t(product.label)}
                </button>
                )

                return (
                    checkedColorFromBO(product.label, countryLicense) && 
                    <button
                    key={index} onClick={() => selectProduct(product)}
                    className={E('product', [selectedDemo.id === product.id ? 'active' : ''])}
                    >
                        {t(product.label)}
                </button>
            )
        })
    }

    const isSeeThrougDemo = () => {
        if (!demoType) return false;
        return [
            ProductDemoType.SEE_THROUGH_IMAGE,
            ProductDemoType.SEE_THROUGH_VIDEO
        ].includes(demoType.demoType);
    }


    const getDynamicLabel = () => {
        if (!isSeeThrougDemo()) return t(selectedDemo.label);

        if (seeThroughActivated) return t(selectedDemo.label);
        const stardardDemo = (demoType?.products as any[]).filter(x => x.id === 'standard');
        const standardLabel = stardardDemo.length > 0 ? stardardDemo[0].label : '';
        return t(standardLabel);
    }

    const withSellout = () => {
        const withDescription = selectedDemo.description && selectedDemo.description.length > 0;
        if (demoType?.withSellout) return withDescription;
        return withDescription;
    }
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    // const isTransitions = () =>{
    //     if(brandToDemo && demoFrom?.id === 'lensConsultation' && !isSlovakia)
    //     return brandToDemo.id === 'transitions'
    // }

    
    return (
        <div className={B()}>
            {/* {!isOpenSubProducts && !isQrCodeOpen && !isTransitions() && */}
            {!isOpenSubProducts && !isQrCodeOpen &&
                <div className={E('selected')}>
                    <Button
                        click={() => showSubProducts(true)}
                        text={getDynamicLabel()}
                        icon={disableProductSelection() ? null : `pencil-circle-gray${selectIcon()}`}
                        type="secondary"
                        outlined={false} />

                    {withSellout() &&
                        <Button
                            type={productDescription ? 'primary' : 'secondary'}
                            icon={`information-${productDescription ? 'white' : 'blue'}`}
                            outlined={false}
                            click={() => setDescription(selectedDemo.description as string[])} />
                    }
                </div>
            }
            <div className={E('sub-products', subProductModifierClass())}>
                {renderSubProducts()}
                <span onClick={() => showSubProducts(false)}></span>
            </div>
        </div>
    )
}

export default ProductDemoSelection;
