import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import routes from '../../../constants/routes.constant';
import myCompanionSlice, { getMyCompanion } from '../../../store/slices/mycompanion.slice';
import useGoto from '../../../utils/hooks/useGoto';
import productsSlice from '../../../store/slices/products.slice';
import appSlice, { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import axios from 'axios';
import ENDPOINTS from '../../../constants/endpoints';
import useDisableCountry from '../../../utils/hooks/useDisableCountry';

interface Props {

}

const MyCompanionFooter : React.FC<Props>  = () =>  {
    // VARIABLES
    const {
        globalInformation,
        myCompannion,
        accountDetails,
        token
    }= useSelector(getApp);
    const [priceOff, setPriceOff] = useState(myCompannion.isPriceOff);
    const [isEssilorRecommend, setEssilorRecommend] = useState(myCompannion.essilorRecommend);

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [configCreationDate, setConfigCreationDate] = useState<string>("")
    const dispath = useDispatch();
    const goto = useGoto();
    const { productList, tempLensThicknessSettings, tempDiscountSettings } = useSelector(getMyCompanion);
    const { t } = useTranslation(useSelector(getApp));
    const { firstLogin } = useSelector(getApp);
    const dispatch = useDispatch();
    const companion = useSelector(getMyCompanion)
    const headers = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'content-type': "application/json"
        }
    }
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const [isBelgium , setIsBelgium] =useState(false);
    const country = accountDetails?.shop.country;
    const hideEssilorRecommend = ['be', 'dk', 'se', 'fi', 'no', 'in', 'in2'].includes(country || '')
    const [ hidePrice ] = useDisableCountry(["gr"])

    // HOOKS
    useEffect( () => {
        if(myCompannion.isPriceOff !== undefined){
            setPriceOff(myCompannion.isPriceOff);
        }
        country ==='be' ? setIsBelgium(true) : setIsBelgium(false)
    }, [myCompannion.isPriceOff])

    useEffect(() => {
        if (companion.configUpdatedAt)
            setConfigCreationDate(new Date(companion.configUpdatedAt).toDateString())

    }, [companion.configUpdatedAt])

    // METHODS
    const priceOffOnclickHandler = () => {
        dispath(myCompanionSlice.actions.offPrice(!priceOff));
        setPriceOff(!priceOff);

        if(!priceOff){
            dispath(myCompanionSlice.actions.offEssilorRecommended(false));
            setEssilorRecommend(false);
        }
    }

    const essilorRecommendOnclickHandler = () => {
        dispath(myCompanionSlice.actions.offEssilorRecommended(!isEssilorRecommend));
        setEssilorRecommend(!isEssilorRecommend);
    }

    const getMasterLicenseConfiguration = () => {
        setShowConfirmModal(false)

        axios.get(
            ENDPOINTS.masterLicenseData,
            headers
        ).then(result => {
            if (result.data.code === '001') {
                setShowSuccessModal(true)
                setConfigCreationDate(new Date(result.data.data.updated_at).toDateString())
                dispath(myCompanionSlice.actions.saveConfigurationData(result.data.data))
                setTimeout(() => setShowSuccessModal(false), 500)
            } else if (result.data.code === '003') {
                setShowErrorModal(true)
                setErrorMessage(result.data.message)
            }
        })
    }

    const saveConfiguration = () => {

        const data = {
            created_by: globalInformation.license,
            shop_id: accountDetails?.shop.shop_account_no,
            region_id: accountDetails?.shop.country,
            created_at: new Date().toLocaleDateString(),
            product_selected: companion.productList,
            pricing_discount: companion.tempDiscountSettings,
            patient_list: {},
            lens_thickness: companion.tempLensThicknessSettings
        }

        axios.post(
            ENDPOINTS.masterLicenseData,
            data,
            headers
        ).then(result => {
            if (result.data.code === '001') {
                dispath(productsSlice.actions.updateProductList(productList));
                dispath(appSlice.actions.saveLensThicknessSettings(tempLensThicknessSettings));
                dispath(appSlice.actions.saveDiscountSettings(tempDiscountSettings));
                dispath(appSlice.actions.saveMyCompannionData({
                    isPriceOff: priceOff,
                    essilorRecommend: isEssilorRecommend,
                    category: 'correct',
                    tempLensThicknessSettings: tempLensThicknessSettings,
                    tempDiscountSettings: tempDiscountSettings
                }));
                setShowSaveModal(true);
            } else if (result.data.code === '003') {
                setShowErrorModal(true)
                setErrorMessage(result.data.message)
            }
        })
    }

    const save = () => {
        dispath(productsSlice.actions.updateProductList(productList));

        dispath(appSlice.actions.saveLensThicknessSettings(tempLensThicknessSettings));
        dispath(appSlice.actions.saveDiscountSettings(tempDiscountSettings));

        dispath(appSlice.actions.saveMyCompannionData({
            isPriceOff: priceOff,
            essilorRecommend: isEssilorRecommend,
            category: 'correct',
            tempLensThicknessSettings: tempLensThicknessSettings,
            tempDiscountSettings: tempDiscountSettings
        }));

        setShowSaveModal(true);
        if(firstLogin?.view === 'companion' && firstLogin?.isOpen){
            setTimeout(() => {
            if(firstLogin.cookieEnabled){
                dispatch(appSlice.actions.setViewFirstLogin('terms'));
            }
            else{
                dispatch(appSlice.actions.setViewFirstLogin('language'));
                dispatch(appSlice.actions.setOpenFirstLogin(false));
                dispatch(appSlice.actions.setFirstLoginStatus(true));
            }
            }, 2000);
        }
        else{
            goto.route(routes.home, 2000)
        };
    }

    return (
        <div className="my-companion-footer">

            {
                (hidePrice) ? <div></div> :
                <Button
                text={t(!priceOff ? 'summary_price_on' : 'summary_price_off')}
                type={!priceOff ? 'primary' : 'secondary'}

                click={priceOffOnclickHandler}/>
            }
            {
                !priceOff && !hideEssilorRecommend &&
                <Button
                    text={t('my_practice_my_essilor_recom_prices')}
                    type={!isEssilorRecommend ? "secondary" : "primary"}
                    icon={!isEssilorRecommend ? "adjust" : "adjust-white"}
                    click={essilorRecommendOnclickHandler}
                />
            }

            <div className='align-right'>
                {
                    !accountDetails?.is_master && <span className='date-sync'>
                        {t('masterlicense_timestamp')} {configCreationDate}
                    </span>

                }
                <div className='buttons'>
                    {
                        accountDetails?.is_master
                            ? <Button
                                text={t('masterlicense_saveconfig')}
                                disabled={!navigator.onLine}
                                click={saveConfiguration}
                              />
                            : <Button
                                text={t('masterlicense_getmastersetting')}
                                disabled={!navigator.onLine}
                                click={() => setShowConfirmModal(true)}
                              />
                    }
                    <Button text={t('my_practice_my_save')} icon="check-circle" click={save}/>
                </div>

            </div>

            <Modal
                onClose={() => setShowErrorModal(false)}
                height="23rem" width="26rem"
                show={showErrorModal} hideCloseButton={true}>

                <div className="my-companion-footer__saved">
                    <h2>{errorMessage}</h2>
                </div>
            </Modal>

            <Modal
                onClose={() => setShowSaveModal(false)}
                height="23rem" width="26rem"
                show={showSaveModal} hideCloseButton={true}>

                <div className="my-companion-footer__saved">
                    <Icon name="saved"></Icon>
                    <h2>{t('my_practice_my_saved')}</h2>
                </div>
            </Modal>

            <Modal
                onClose={() => setShowSuccessModal(false)}
                height="23rem" width="26rem"
                show={showSuccessModal} hideCloseButton={true}>

                <div className="my-companion-footer__saved">
                    <Icon name="saved"></Icon>
                    <h2>Success</h2>
                </div>

            </Modal>

            <Modal
                onClose={() => {setShowConfirmModal(false)}}
                height="18rem" width="24rem"
                show={showConfirmModal} hideCloseButton={true}>

                <div className="my-companion-footer__confirm">
                    <p>{t('masterlicense_messageconfig')}</p>
                    <div>
                        <Button
                            text="Yes"
                            type="primary"
                            click={getMasterLicenseConfiguration}
                        />
                        <Button
                            text="Cancel"
                            type="secondary"
                            click={() => setShowConfirmModal(false)}
                        />
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default MyCompanionFooter;
