const neddExplorerIntro = [
    {
        title: 'personalize_your_lenses_pyl_pers_your_lenses',
        description: 'personalize_your_lenses_pyl_ess_brings_best_tech'
    },
    {
        title: 'legal_notices_ln_legal_notices',
        description: 'legal_notices_ln_purpose_of_the_questionaire'
    }
];

export default neddExplorerIntro;