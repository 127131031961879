import React, { useEffect } from 'react';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import iProductFamily from '../../../interface/product-family.interface';
import iProduct from '../../../interface/product.inteface';
import iSubProduct from '../../../interface/sub-product.inteface';
import LensFamilyType from '../../../types/lens-family.type';
import Lenstype from '../../../types/lens-family.type';
import useBEM from '../../../utils/hooks/useBEM';
import { useSelector, useDispatch } from 'react-redux';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';


type eventType = 'select' | 'delete';

interface Props {
    family: iProductFamily,
    value?: iProduct | iSubProduct | any[],
    extraComponent?: React.ReactNode,
    noLabel?:boolean,
    allowMultiple?:boolean,
    disabled?: boolean,

    onEvent?: (event: eventType, family: iProductFamily) => void,
}

const LensFamily : React.FC<Props>  = ({
    family,
    value,
    extraComponent,
    noLabel,
    allowMultiple,
    disabled = false,
    onEvent
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-family');
    const { t } = useTranslation(useSelector(getApp));
    const { 
        productFamily, 
        productBrand, 
        lensRecommendation, 
        activePair,
    } = useSelector(getLensConsultation);
    const { accountDetails, epromData } = useSelector(getApp);
    const dispatch = useDispatch()
    // HOOKS

    // METHODS


    const countryBusinessRule = () =>{
        if(accountDetails?.shop.country !== 'be')return true

        const productBelgiumExemption = ['transitions_signature_gen_8', 'eye_protect_system','clear']
        //products that can be paired with xperio mirrors
        const productBelgiumXperioMirrors = ['transitions_xtractive', 'xperio_polarised','xperio_tinted', 'transitions_xtractive_polarised']
        //add country with additional business rule
        let protect;
        let correct;
        if(lensRecommendation){
            protect = lensRecommendation[activePair]?.protect
            correct = lensRecommendation[activePair]?.correct
        }
        if (accountDetails?.shop.country === "be" && protect && protect[0]?.product?.id) {
            if ( protect[0]?.product?.id && productBelgiumXperioMirrors.includes(protect[0]?.product?.id) && epromData &&
                correct?.subProduct?.code && epromData[correct?.subProduct?.code]?.masstint)
                return epromData[correct?.subProduct?.code]?.masstint?.xperio_mirrors;
            if (productBelgiumExemption.includes(protect[0]?.product?.id)) return false;
            if (productBelgiumXperioMirrors.includes(protect[0]?.product?.id)) return true;
            return protect[0]?.product?.id === "xperio_mirrors";
        }
    }

    const onClickHandler = (event: eventType, force = false) => {
        if(!onEvent)return;
        if(event === 'select' && value && !force)return;
        onEvent(event, family);
        dispatch(lensConsultationSlice.actions.setFamily(family.id))
    }

    const classModifiers = () => {
        const modifiers: string[] = [ family.id ];
        if(value)modifiers.push('with-value');
        if(disabled)modifiers.push('disabled');
        return modifiers;
    }

    const getValueDisplay = () => {
        // if(family.id === LensFamilyType.PROTECT) {
        //     return t((value as any[])[0].product.label);
        // }
        return t((value as iSubProduct | iProduct).label)
    }

    return (
        <div className={B(classModifiers())} onClick={() => onClickHandler('select')}>
            <Icon name={family.id} type="image" className={E('icon')}/>                
            
            {
                !noLabel &&
                <h2 className={E('label')}>{t(family.label)}</h2>
            }
            

            <Button icon="plus" type="secondary" circle={true} className={E('add')}/>

            {
                value &&
                <div className={E('value', extraComponent ? 'with-extra' : '')}>
                    {getValueDisplay()}

                    <div className={E('value-actions')}>
                        <span onClick={() => onClickHandler('delete')}>
                            <Icon name="trash"/>
                        </span>
                        
                        {
                            allowMultiple && countryBusinessRule() &&
                            <Icon name="plus-white" click={() => onClickHandler('select', true)}/>
                        }
                    </div>
                </div>
            }

            {
                extraComponent && 
                extraComponent
            }

        </div>
    )
}

export default LensFamily;