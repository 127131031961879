import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import { KIDS_THEMES } from '../../../constants/theme.constants';
import { getApp } from '../../../store/slices/app.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Utils from '../../../utils/Utils';
import FrametraceListModal from './FrametraceListModal';

interface Props {
    label: string,
    description: string,
    step: number,
    children: React.ReactNode,
    onImport: () => void
}

const MeasureModal : React.FC<Props>  = ({
    label,
    description,
    step = 1,
    children,
    onImport
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('measure-modal');
    const { activeDataIndex } = useSelector(getMeasure);
    const { ageCategory } = useSelector(getUser);
    const { generateRandomNumbers, getThemeVariables } = Utils();
    const { theme } = useSelector(getApp);
    const themes = KIDS_THEMES;
    const { t } = useTranslation(useSelector(getApp));
    const pairTranslationKeys = ['measures_mes__3_pair_1_new', 'delivery_del_3_pair_2']
    const [showFrametradeModal, setShowFrametraceModal] = useState(false)

    // HOOKS

    // METHODS
    const displayModal = () => {
        setShowFrametraceModal(false)
    }
    const getAge = () => {
        const age = ageCategory?.value;
        return age ? age : ''
    }

    const themeStyle = () => {
        if(!theme || theme.id !== 'multi')return {};
        const randomNumbers = generateRandomNumbers(themes.length - 1);
        const randomTheme = themes[randomNumbers[0]];
        return getThemeVariables(randomTheme)
    }

    const closeFrametraceModal = () => {
        setShowFrametraceModal(false)
    }

    return (

        <div className={E('card',getAge())} style={themeStyle()}>
            <div className={E('head')}>
                <span>
                    {t(pairTranslationKeys[activeDataIndex])}
                </span>

                <div className='ivs'>
                    <span className='info'>
                        {/* {"Data can only generate from Eyeruler2. For other IVS Device such as Visioffice X & Visioffice 3 will be coming soon."} */}
                    </span>

                    { activeDataIndex === 0 &&
                    <Button
                    outlined
                    icon="import"
                    type='secondary'
                    click={() => setShowFrametraceModal(true)}
                    />
                }
                </div>

            </div>

            <label className={E('label')}>
                {label}
            </label>

            <p className={E('description',getAge())}>
                {description}
            </p>

            {
                showFrametradeModal && <FrametraceListModal
                    showModal={true}
                    onHideModal={() => closeFrametraceModal()}
                    displayModal={() => displayModal()}
                />
            }


            {children}
        </div>

    )
}

export default MeasureModal;
