import React from 'react';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    name: string
}

const DeliverySummarySection : React.FC<Props>  = ({
    name,
    children
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-summary-section');

    // HOOKS

    // METHODS

    return (
        <div className={B()} id={name}>
            <label className={name}>{name}</label>

            {children}

            <div className={E('min-content')}>
                <span className='target' data-view={name}>{name}</span>
            </div>
        </div>
    )
}

export default DeliverySummarySection;