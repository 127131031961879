import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import Accordion from "../../../components/ui/Accordion";
import Icon from "../../../components/ui/Icon";
import { TUTORIAL_CONTENT } from "../../../constants/tutorial-content.constants";
import { getApp } from "../../../store/slices/app.slice";
import sidebarSlice, { getSidebar } from "../../../store/slices/sidebar.slice";
import tutorialSlice, { getTutorial } from "../../../store/slices/tutorial.slice";
import useBEM from "../../../utils/hooks/useBEM";
import useTranslation from "../../../utils/hooks/useTranslation";
import TutorialCard from "./TutorialCard";

interface Props {

}
const TutorialMain : React.FC<Props>  = () =>  {
    
    // VARIABLES
    const dispatch = useDispatch();
    const [B, E] = useBEM('tutorial-main');
    const { selected, items } = useSelector(getTutorial);
    const { token } = useSelector(getApp);
    const { t } = useTranslation(useSelector(getApp));
    // HOOKS
    useEffect(() => {
        if(items !== undefined)
            dispatch(tutorialSlice.actions.saveTutorialItems(items));
            

    }, [])
    // METHODS
    const onError = (error: any) => {
    }
    
    const onClickContent = (type:string,key:string) =>{
        dispatch(tutorialSlice.actions.selectedCard({key:type,selectedKey:key}));
        dispatch(tutorialSlice.actions.saveViewMode('tutorialSection'));
    }

    const returnTotal = (file:any[] = []) =>{
        return file.length;
    }
    const returnRemaining = (file:any[] = []) =>{
        let count = 0;
        file.map(cond =>{
            if(cond.viewed) count = count + 1;
        })
        return count;
    }
    return (<div className={B('')}>
                    <div className={E('intro')}>
                        <div className={E('background')}>

                        </div>
                        <div className={E('info')}>
                            <p>
                            {t('my_practice_my_otutorials')} 
                            </p>
                            <h5>
                                <Icon name="video-white" type="png"></Icon> {t('my_practice_my_expla_vid')} 
                            </h5>
                            <hr></hr>
                            <h5>
                                <Icon name="interactive-white" type="png"></Icon>{t('my_practice_my_interac_tuto')} 
                            </h5>
                        </div>
                    </div>
                    <div className={E('list')}>
                        <Accordion title={t('my_practice_my_new_feat')}  key={1} className="tutorial-content"
                        >
                            {
                                items?.newFeatures?.length !== 0 && items?.newFeatures?.map((value:any, i)=>{
                                    return <TutorialCard title={value.label} key={i}
                                            click={()=>onClickContent('newFeatures',value.key)} 
                                            className={returnTotal(value.content) === returnRemaining(value.content) ? 'selected' : ''}
                                            totalCount={returnTotal(value.content)} count={returnRemaining(value.content)}></TutorialCard>
                                })
                                
                            }                            
                        </Accordion>
                        <Accordion title={t('my_practice_my_how__user_journey')}  key={2} className="tutorial-content"
                        >
                             {
                                items?.useJourney?.length !== 0 && items?.useJourney?.map((value:any, i)=>{
                                    return <TutorialCard title={value.label} key={i}
                                            click={()=>onClickContent('useJourney',value.key)} 
                                            totalCount={returnTotal(value.content)} count={returnRemaining(value.content)}
                                            className={returnTotal(value.content) === returnRemaining(value.content) ? 'selected' : ''}
                                            ></TutorialCard>
                                })
                                
                            }
                            
                        </Accordion>
                        <Accordion title={t('my_practice_my_person_companion')} key={3} className="tutorial-content"
                        >
                            {
                                items?.personalizedCompanion?.length !== 0 && items?.personalizedCompanion?.map((value:any, i)=>{
                                    return <TutorialCard title={value.label} key={i}
                                            click={()=>onClickContent('personalizedCompanion',value.key)} 
                                            totalCount={returnTotal(value.content)} count={returnRemaining(value.content)}
                                            className={returnTotal(value.content) === returnRemaining(value.content) ? 'selected' : ''}
                                            ></TutorialCard>
                                })
                                
                            }
                        </Accordion>
                        <Accordion title={t('my_practice_my_how_update_app')} key={4} className="tutorial-content"
                        >
                            {
                                items?.updateApp?.length !== 0 && items?.updateApp?.map((value:any, i)=>{
                                    return <TutorialCard title={value.label} key={i}
                                            click={()=>onClickContent('updateApp',value.key)} 
                                            totalCount={returnTotal(value.content)} count={returnRemaining(value.content)}
                                            className={returnTotal(value.content) === returnRemaining(value.content) ? 'selected' : ''}
                                            ></TutorialCard>
                                })
                                
                            }
                        </Accordion>
                    </div>
                    </div>
    )

}
export default TutorialMain;