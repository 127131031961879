import React from 'react';
import { backgroundColorHeader, backgroundPerRow, containerWhite, h2, h3, headerImage1, headerImage2, icon, imageLogo, paragraph, summaryBenefitContainer, summaryBenefitContainerLI } from '../../../constants/email-css.constants';
import iLensProposalField from '../../../interface/lens-proposal-field.interface';
import iAppStore from '../../../store/interface/app-store.interface';
import useTranslation from '../../../utils/hooks/useTranslation';
import Icon from '../../ui/Icon';

interface Props {
    data: any,
    pair: number,
    appStore: iAppStore,
    breakdown: any,
    complementaryEquipment?:iLensProposalField[]
}
const HeaderSummary : React.FC<Props>  = ({
    data, pair, appStore, breakdown, complementaryEquipment = []
}) =>  {
    //VARIABLES
    const headerVal = data[0]
    const { t } = useTranslation(appStore);
    //METHOD
    const groupBy = (data: any[], type: number) =>{
        const newData = data.filter((
            filter) => 
            parseInt(filter.type) === type )
        return newData;
    }
    const returnPrice = (pair:number) =>{
        return pair === 1 ? `${breakdown.pair1.deliveryPrice} ${breakdown.currency}` : `${breakdown.pair2.deliveryPrice} ${breakdown.currency}`
    }
    const returnEquipment = (eq: string) =>{
        if(complementaryEquipment.length === 0 || pair === 1) return t(eq);
        const translationKey: {
            [key:string]: string        
        } = {
            prescription_rx_driving: 'delivery_design_lens6',
            prescription_rx_computer: 'delivery_design_lens4',
            prescription_rx_sun: 'delivery_design_lens5'
        }
        const label = (complementaryEquipment as iLensProposalField[])[0].value?.label;
        return t(translationKey[label as string]) || t(eq);

    }
    return (
        <div style={backgroundPerRow}>
            <div style={{display: "flex"}}>
                <div style={ pair === 1 ? headerImage1 : headerImage2}></div>
                <div style={backgroundColorHeader}>
                    <div style={{display: "flex"}}>
                        <h2 style={h2}>{pair === 1 ? t('delivery_del_pair_1') : t('delivery_del_3_pair_2')} <span 
                        style={{backgroundColor:"white", color: "black", padding: "0 1rem"}}>{returnPrice(pair)}</span></h2>
                    </div>     
                    
                    <span style={containerWhite}>{returnEquipment(headerVal.deliveryDescription.type)}</span>               
                    <p>{t(headerVal.deliveryDescription.desc)}</p>
                </div>
            </div>            
            <div>
                    {
                                        headerVal.products[0].descriptions && (
                                            <ul style={{display: "flex", alignItems: "center", padding: "0"}}>
                                            {
                                                groupBy(headerVal.products[0].descriptions, 1).map((desc: any, iii: number) => (
                                                    <li style={{display: "flex", margin: "0 10%"}}>
                                                        {/* <img src={"https://i.postimg.cc/DfX9p8J2/"+desc.icon+".png"} alt={desc.icon} style={icon}></img> */}
                                                        <Icon type="png" name={desc.icon} customStyle={icon}/>
                                                        <label style={{"width": "6rem", fontSize: ".8rem", textAlign:"center"}} >{t(desc.label)}</label>
                                                    </li>   
                                                ))
                                            }                                                                                                 
                                            </ul>  
                                        )
                                    }  
                                    {
                                        headerVal.products[0].descriptions && headerVal.products[0].descriptions.length > 3 && (
                                            <ul style={{display: "flex", alignItems: "center", padding: "0"}}>
                                                {
                                                    groupBy(headerVal.products[0].descriptions, 2).map((desc: any, iii: number) => (
                                                        <li style={{display: "flex", margin: "0 10%"}}>
                                                            {/* <img src={"https://i.postimg.cc/DfX9p8J2/"+desc.icon+".png"} alt={desc.icon} style={icon}></img> */}
                                                            <Icon type="png" name={desc.icon} customStyle={icon}/>
                                                            <label style={{"width": "6rem", fontSize: ".8rem", textAlign:"center"}} >{t(desc.label)}</label>
                                                        </li>  
                                                    ))
                                                }                                              
                                            </ul>  
                                        )
                                    } 
            </div>
        </div>
        )
}
export default HeaderSummary;