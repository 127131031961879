import React, { useEffect, useState } from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    position?: 'top' | 'bottom' | 'left' | 'right' | 'bottom-left',
    show?: boolean,
    backgroundColor?: string[],
    onClick?: () => void
}

const GenericToolTip : React.FC<Props>  = ({
    children,
    position = 'right',
    show = true,
    backgroundColor = ['#000'],
    onClick
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('generic-tool-tip');
    const [animate, setAnimate] = useState(false);
    const [ localShow, setLocalShow ] = useState(false);

    // HOOKS
    useEffect(() => {
        const delay1 = show ? 0 : 100;
        const delay2 = !show ? 0 : 100;

        setTimeout(() => setLocalShow(show), delay1);
        setTimeout(() => setAnimate(show), delay2);

    }, [show])

    // METHODS
    const mainModifier = () => {
        const mods = [
            position,
            localShow ? 'show' : '',
            animate ? 'animate' : ''
        ]
        if(backgroundColor.length > 1) {
            mods.push('gradient');
        }
        return mods;
    }

    const onClickHandler = () => {
        if (onClick) onClick()
    }

    return (
        <div
            className={B(mainModifier())}
            onClick={() => onClickHandler()}
            style={{
                "--app-tooltip-bg": backgroundColor[0],
                "--app-tooltip-bg-gradient": backgroundColor[1] || backgroundColor[0],
            } as React.CSSProperties}
        >
            <div className={E('wrapper', position)}>
                {children}
            </div>
        </div>
    )
}

export default GenericToolTip;
