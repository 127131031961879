const anamnesisDetails = {
    side: {
        tag: 'Varilux X series',
        title: 'Vivamus augue quam, maximus sed fringilla sed,',
        description: 'Curabitur ac neque libero. Ut leo eros, accumsan a tempor nec, varius eget arcu. Phasellus ullamcorper, mi nec dapibus vestibulum, turpis enim egestas urna, et pharetra elit turpis vel nunc. Fusce ut dui congue, maximus arcu vel, hendrerit tortor. Praesent sit amet turpis auctor, consequat leo ac, luctus risus. Nulla eu erat dictum, tempor nibh sed, tincidunt orci. Proin a nulla ante. '
    },
    summary: [
        {
            categoryIndex: 0,
            questionIndex:0,
            question: 'What kind of vision challenge are you trying to solve?',
            answers: [
                'Trouble seeing near and far'
            ]
        },
        {
            categoryIndex: 0,
            questionIndex:0,
            question: 'Your vision changes as you get older, what’s your age range?',
            answers: [
                '18-30 years old'
            ]
        },
        {
            categoryIndex: 0,
            questionIndex:0,
            question: 'Where do you spend most of your day?',
            answers: [
                'Some indoors',
                'Some outdoors'
            ]
        },
        {
            categoryIndex: 0,
            questionIndex:0,
            question: 'How many hours a day do you spend in front of a screen-computer, phone, tv, etc?',
            answers: [
                '12 + Hours'
            ]
        },
        {
            categoryIndex: 0,
            questionIndex:0,
            question: 'Do you experience any of the following?',
            answers: [
                'Discomfort from glare at night or from digital devices',
                'Eyeglass lenses that scratch easily',
                'Eyeglass lenses that scratch easily',
                'Exposure to dirt, rain, and wind'
            ]
        },
        {
            categoryIndex: 0,
            questionIndex:0,
            questionRef: undefined,
            answerRef: undefined,
            question: 'How many hours a day do you spend in front of a screen-computer, phone, tv, etc?',
            answers: [
                '12 + Hours'
            ]
        }
    ],
    lens: "correct",
    icon: "correct" 
};
export default anamnesisDetails;