
import kidsProductLogic from '../constants/product-logic-kids.constants';
import iProductFamily from '../interface/product-family.interface';
import { AddMatrixValues, fetchBrandDetailsViaId, fetchProductDetailsViaId, fetchSubProductDetailsViaProductId, getAnswerIds, getAnswerIDwithSignificantAnswers } from './misc';

const product_ids = [`stellest`,`eyezen_kids`,`crizal_easy`,`crizal_prevencia`]
const brand_ids = [`stellest`,`eyezen`,`crizal`,`crizal`]

export const evaluateKidsDecisionTree = (
    currentNeedExplorerData: any,
    pl: iProductFamily
) => {
    console.log(currentNeedExplorerData)

    //(1) get all answers from children to parents ANSWER_IDS
    const allAnswers = [
        ...currentNeedExplorerData.questions[0].questions,
        ...currentNeedExplorerData.questions[1].questions
    ]

    var ANSWER_IDS = getAnswerIds(allAnswers)
    console.log('ANSWER IDS: ', ANSWER_IDS)

        //(1.1) Special Scenario: If the user choose anything between 6.2.1 to 6.2.4, remove 6.2.5
        ANSWER_IDS = selectedNone(ANSWER_IDS)
        console.log('ANSWER IDS (clean): ', ANSWER_IDS)

    //(2) get ALL_PRODUCT_LOGIC_MATRIX for general and extra
    const ALL_PRODUCT_LOGIC_MATRIX = kidsProductLogic
    console.log('ALL PRODUCT: ', ALL_PRODUCT_LOGIC_MATRIX)

    //(3) filter the ALL_PRODUCT_LOGIC_MATRIX with ANSWER_IDS
    let ANSWER_MATRIX = []
    let REFERENCE = []
    for (var i = 0; i < ANSWER_IDS.length; i++) {
        var item  = ALL_PRODUCT_LOGIC_MATRIX.find(m => m.answerId === ANSWER_IDS[i]); 
        ANSWER_MATRIX.push(item?.scoreMatrix)
        REFERENCE.push(item)
    }
    console.log('ANSWER MATRIX: ', ANSWER_MATRIX)

    //(4) Add ANSWER_MATRIX
    const MATRIX_SUM = AddMatrixValues(ANSWER_MATRIX)
    console.log('SUM: ',MATRIX_SUM)

    //(5) Apply result to TEMP
    let index_correct = {
        pair1: MATRIX_SUM[1] > MATRIX_SUM[0] ? 1 : 0,
        pair2: MATRIX_SUM[1] > MATRIX_SUM[0] ? 0 : 1
    }

    let index_enhance = {
        pair1: MATRIX_SUM[3] > MATRIX_SUM[2] ? 3 : 2,
        pair2: MATRIX_SUM[3] > MATRIX_SUM[2] ? 2 : 3
    }

    const isEyezenKids = index_correct.pair1 === 1 ? true : false
    const isStellest_pair1 = index_correct.pair1 === 0 ? true : false

    const TEMP = {
        pair1: {
            correct: {
                brand: fetchBrandDetailsViaId(brand_ids[index_correct.pair1], pl),
                product: fetchProductDetailsViaId(product_ids[index_correct.pair1], pl),
                subProduct: fetchSubProductDetailsViaProductId(product_ids[index_correct.pair1], pl),
                significantAnswers: getAnswerIDwithSignificantAnswers(index_correct.pair1, REFERENCE)
            },
            // If correct is eyezen kids; index = 1
            // then EPS as 1st pair	
            ...isEyezenKids ? { protect: [{
                brand: isEyezenKids ? fetchBrandDetailsViaId('blueuv_filter_system', pl) : {},
                product: isEyezenKids ? fetchProductDetailsViaId('eye_protect_system', pl) : {},
                significantAnswers: null
            }] } : { protect: null },
            enhance: {
                brand: isStellest_pair1 ? fetchBrandDetailsViaId('crizal', pl) : fetchBrandDetailsViaId(brand_ids[index_enhance.pair1], pl),
                product: isStellest_pair1 ? fetchProductDetailsViaId('crizal_aliz', pl) : fetchProductDetailsViaId(product_ids[index_enhance.pair1], pl),
                significantAnswers: isStellest_pair1 ? null : getAnswerIDwithSignificantAnswers(index_enhance.pair1, REFERENCE)
            }
        },
        pair2: {
            correct: null,
            protect: null,
            enhance: null,
        },
        answers: ANSWER_IDS
    }

    return TEMP
}

const selectedNone = (ANSWER_IDS:any) => {
    let has = false

    ANSWER_IDS.map((a:any) => {
        if(a === '6.2.1' || a === '6.2.2' || a === '6.2.3' || a === '6.2.4'){
            has = true
        }
    })
    
    return has ? ANSWER_IDS.filter((a:any) => a!== '6.2.5') : ANSWER_IDS
}