import React, { useEffect, useState } from 'react';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';
import { useDispatch, useSelector } from 'react-redux';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import LensPairType from '../../../types/lens-pair.type';
import { getSummary } from '../../../store/slices/summary.slice';
import { HashLink } from 'react-router-hash-link';
import clearLens from '../../../assets/images/prescription/Clear_Lens.png';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import Modal from '../../../components/Modal';
import iPairBreakdown from '../../../interface/pair-breakdown.interface';
import useAdvancePricing from '../../advance-price-setting/useAdvancePricing';
import ExperienceType from '../../../types/experience.type';

interface Props {
    isShowPriceBreakDown: boolean,
    priceOn: boolean,
    showPriceBreakDown: (show: boolean) => void
}

const SummarySide: React.FC<Props> = ({
    isShowPriceBreakDown,
    priceOn,
    showPriceBreakDown
}) => {
    // VARIABLES
    const [B, E] = useBEM('summary-side');
    const dispatch = useDispatch();
    const appStore = useSelector(getApp);
    const { activePair, breakdown } = useSelector(getLensConsultation);
    const { summaryList1, summaryList2 } = useSelector(getSummary);
    const { lensRecommendation } = useSelector(getLensConsultation);
    const { t } = useTranslation(appStore);
    const { myCompannion, experience } = appStore;
    const { summaryTotal, currency, pair1, pair2 } = breakdown
    const { tempDiscountSettings } = myCompannion
    const { amountEnabled } = tempDiscountSettings[0];
    const [advancePrice, setAdvancePrice] = useState<any>();
    const {
        isAdvancePricing,
        getAdvancePricingTotal
    } = useAdvancePricing(appStore);


    useEffect(() => {
        if (!lensRecommendation || !isAdvancePricing) return;
        setAdvancePrice(getAdvancePricingTotal(lensRecommendation));
    }, [])
    // METHODS

    const changePair = (pair: LensPairType) => {
        dispatch(lensConsultationSlice.actions.changePair(pair));
    }

    const showPricingModal = () => {
        showPriceBreakDown(true)
    }

    const renderPackageBreakdown = (show: boolean) => {
        return (
            <Modal show={show} height="45rem" width="70rem" onClose={() => showPriceBreakDown(false)}>
                <div className={E('modal-package')}>
                    <h2>{t('delivery_del_total')}</h2>
                    {renderPackage()}
                </div>
            </Modal>
        )
    }

    const renderPackage = () => {
        const tableData = [
            {
                class: 'pp-pair1',
                icon: 'lens-pair-1',
                label: t('delivery_del_pair_1'),
                design: pair1.categoryLens,
                total: !advancePrice ? pair1.price : advancePrice.pair1.originalPrice,
                enabled: pair1.enabled,
                hasNocombi: pair1.hasNocombi
            },
            {
                class: 'pp-pair2',
                icon: 'lens-pair-1',
                label: t('delivery_del_3_pair_2'),
                design: pair2.categoryLens,
                total: !advancePrice ? pair2.price : advancePrice.pair2.originalPrice,
                enabled: pair2.enabled,
                hasNocombi: pair2.hasNocombi
            }
        ]

        const misc = () => {
            return (
                <React.Fragment>
                    <tr className="pp-pair1-discount">
                        <td colSpan={3}>
                            {t('price_bdown_pair_1_disc')}
                        </td>
                        <td >

                            {
                                !advancePrice ?
                                    pair1.discount.toFixed(2).replace('.00','') :
                                    advancePrice.pair1.discount.toFixed(1).replace('.0','')
                            }
                        </td>
                    </tr>
                    {pair2.enabled && <tr className="pp-pair2-discount">
                        <td colSpan={3}>
                            {t('price_bdown_pair_2_disc')}
                        </td>
                        <td >
                            {
                                !advancePrice ?
                                    pair2.discount.toFixed(2).replace('.00','') :
                                    advancePrice.pair2.discount.toFixed(1).replace('.0','')
                            }
                        </td>
                    </tr>}
                    <tr>
                        <td colSpan={3}>
                            {t('delivery_del_total')}
                        </td>
                        <td >
                            {
                                !advancePrice ?
                                    summaryTotal.toFixed(2).replace('.00','') :
                                    (advancePrice.pair1.total + advancePrice.pair2.total).toFixed(2).replace('.00','')
                            }
                        </td>
                    </tr>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <table className="tg">
                    <tbody>
                        {
                            tableData.map((td, i) => {
                                return td.enabled && <tr key={i} className={td.class}>
                                    <td><Icon name={td.icon} /></td>
                                    <td>{td.label}</td>
                                    <td>{td.design}</td>
                                    <td>{td.hasNocombi ? '-' : td.total}</td>
                                </tr>
                            })
                        }
                        {misc()}
                    </tbody>
                </table>
            </React.Fragment>
        )

    }

    const renderManualBreakdown = (show: boolean) => {
        const hasPair2 = pair2.enabled

        return (
            <Modal show={show} height="60rem" width={hasPair2 ? "70rem" : "37rem"} onClose={() => showPriceBreakDown(false)}>
                <div className={E('modal')}>
                    <div className="pair1bd" style={{
                        width: hasPair2 ? "31rem" : "-webkit-fill-available",
                        flex: hasPair2 ? "1" : "none"
                    }}>
                        <Icon name={`lens-pair-1${isSunExp() ? 'sun' : ''}`} />
                        <h2>{t('delivery_del__pair_1')}</h2>
                        <p>{pair1.categoryLens}</p>
                        {renderBreakdown(pair1)}
                    </div>
                    {
                        hasPair2 &&
                        <div className="pair2bd">
                            <Icon name={'lens-pair-2'} />
                            <h2>{t('delivery_del__pair_2')}</h2>
                            <p>{pair2.categoryLens}</p>
                            {renderBreakdown(pair2)}
                        </div>
                    }
                </div>
            </Modal>
        )
    }

    const renderBreakdown = (pair: iPairBreakdown) => {
        const tableData = [
            {
                icon: 'correct',
                label: 'my_practice_my_correct',
                value: `${pair.correctPrice} ${currency}`
            },
            ...(
                !isSunExp() ?
                    [{
                        icon: 'protect',
                        label: 'my_practice_my_protect',
                        value: `${pair.protectPrice} ${currency}`
                    },
                    {
                        icon: 'protect',
                        label: 'my_practice_my_protect',
                        cond: true,
                        value: `${pair.protect2Price} ${currency}`
                    },
                    {
                        icon: 'enhance',
                        label: 'my_practice_my_enhance',
                        value: `${pair.enhancePrice} ${currency}`
                    }] : []
            ),
            {
                icon: 'lens-thickness',
                label: 'price_bdown_thick',
                value: `${pair.lensThickness} ${currency}`
            },
            {
                icon: 'percentage',
                label: 'price_bdown_disc',
                value: `${amountEnabled ? pair.discount : pair.discountPercentage} ${amountEnabled ? currency : '%'}`
            }
        ]

        return <React.Fragment>
            <table>
                <tbody>
                    {
                        tableData.map((td) => {
                            return <tr>
                                <td>{td.icon === 'percentage' ?
                                    <span className="percent">{!amountEnabled && '%'}</span>
                                    : <Icon name={td.icon} />}</td>
                                {
                                    td.cond && <td>{t(td.label).toUpperCase()} 2</td>
                                }
                                {
                                    !td.cond && <td>{t(td.label).toUpperCase()}</td>
                                }
                                <td>{td.value}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            <div className="total">
                <div>
                    <h3>{t('delivery_del_total')}</h3>
                    <h3>{`${pair.summaryPrice.toFixed(2).replace('.00','')} ${currency}`}</h3>
                </div>
            </div>
        </React.Fragment>
    }

    const isSunExp = () => {
        return experience === ExperienceType.SUN;
    }

    return (
        <div className={E('container')}>
            <div className={B(experience)}>

                {
                    !isSunExp() &&
                    <div className={E('lens-pairs', activePair)}>
                        <HashLink to={'#pair1'}>
                            <Icon name="lens-pair-1" className={summaryList1 && summaryList1.length !== 0 ? '' : 'disabled'} click={() => changePair(LensPairType.PAIR1)} />
                        </HashLink>
                        <span></span>
                        <HashLink to={'#pair2'}>
                            <Icon name="lens-pair-2" className={summaryList2 && summaryList2.length !== 0 ? '' : 'disabled'} click={() => changePair(LensPairType.PAIR2)} />
                        </HashLink>
                    </div>
                }
                <img src={clearLens} alt="" className={E('lens')} />
            </div>
            {priceOn &&
                (!isAdvancePricing ?
                    <div className={`${E('container')}-price`} onClick={showPricingModal}>
                        {`${summaryTotal.toFixed(2).replace('.00','')} ${currency}`}
                    </div> :

                    // ADVANCE PRICING
                    <div className={`${E('container')}-price`} onClick={showPricingModal}>
                        {`${(advancePrice.pair1.total + advancePrice.pair2.total).toFixed(2).replace('.00','')} ${currency}`}
                    </div>
                )
            }
            {myCompannion.essilorRecommend ? renderPackageBreakdown(isShowPriceBreakDown) : renderManualBreakdown(isShowPriceBreakDown)}
        </div>
    )
}

export default SummarySide;
