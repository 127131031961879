
const deliveryPairDesc = {
    progressive: 
    {   type: "pair_1_tab_del_p1_progressive_vision_title",
        desc: "pair_1_tab_del_p1_progressive_vision_text"},
    single: {
        type: "pair_1_tab_del_p1__single_vision",
        desc: "pair_1_tab_del_p1_single_vision"
    },
    both: {
        type: "delivery_design_lens3",
        desc: "pair_1_tab_del_p1_single_vision"
    }

}

export default deliveryPairDesc;