import iNeedExplorerCategory from "../interface/need-explorer-category.interface";


const ChildrenCategory:iNeedExplorerCategory = {
    id: '6',
    category: 'need_explorer_qest_kids_chil',
    questions: [
        {
            id: '6.1',
            question: 'for_kids_q_kids_age',
            kind: 'need_explorer_single_choice_question',
            type:'CHOICE_BOX',
            options: [
                { value: '6.1.1', label: 'for_kids_q_kids_age_4-6' },
                { value: '6.1.2', label: 'for_kids_q_kids_age_6-8' },
                { value: '6.1.3', label: 'for_kids_q_kids_age_8-10' },
                { value: '6.1.4', label: 'for_kids_q_kids_age_10-12' },
                { value: '6.1.5', label: 'for_kids_q_kids_age_12-14' },
                { value: '6.1.6', label: 'for_kids_q_kids_age_14-16' },
                { value: '6.1.7', label: 'for_kids_q_kids_age+16' }
            ],
            isAnswered: false
        },
        {
            id: '6.2',
            question: 'for_kids_q_kids_clear_vision',
            kind: 'need_explorer_multiple_choice_question',
            subQuestion: 'need_explorer__traff_participation_6',
            type: 'CHOICE_BOX_IMAGE',
            options: [
                {
                    value: '6.2.1',
                    label:'for_kids_q_kids_clear_vision_blackboard',
                    image:'board0'
                },
                {
                    value: '6.2.2',
                    label:'for_kids_q_kids_clear_vision_reading_',
                    image:'reading-book',
                },
                {
                    value: '6.2.3',
                    label:'for_kids_q_kids_clear_vision_tablet',
                    image:'tablet-use'
                },
                {
                    value: '6.2.4',
                    label:'for_kids_q_kids_outdoor_activities',
                    image:'outdoor-activities-image'
                }
            ],
            answer: [{
                value: '6.2.5',
                label:'None',
                image:'white-bg'
            }],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '6.3',
            question: 'for_kids_q_kids_board_at_school',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_BOX_IMAGE',
            options: [
                { value: '6.3.1', label: 'for_kids_q_kids_board_at_school_sharp', image:'board0'},
                { value: '6.3.2', label: 'for_kids_q_kids_board_at_school_blurred_vision-low', image:'board1' },
                { value: '6.3.3', label: 'for_kids_q_kids_board_at_school_blurred_vision-medium', image:'board2' },
                { value: '6.3.4', label: 'for_kids_q_kids_board_at_school_blurred_vision-high', image:'board3' }
            ],
            canSelectMultiple: false,
            isAnswered: false
        },
        {
            id: '6.4',
            question: 'for_kids_q_kids_score_you_vision_',
            kind: 'need_explorer_single_choice_question',
            // type: 'SLIDER_GROUP',
            // sliders: [
            //     { key: '6.4.1', label: '', min: 0, max: 6, minValue: 1},
            // ],
            // answer: [1]
            type: 'CHOICE_BOX_KIDS',
            options: [
                { value: '6.4.1', label: 'for_kids_q_kids_score_you_vision__1'},
                { value: '6.4.2', label: 'for_kids_q_kids_score_you_vision__2'},
                { value: '6.4.3', label: 'for_kids_q_kids_score_you_vision__3'},
                { value: '6.4.4', label: 'for_kids_q_kids_score_you_vision__4'},
                { value: '6.4.5', label: 'for_kids_q_kids_score_you_vision__5'},
                { value: '6.4.6', label: 'for_kids_q_kids_score_you_vision__6'}
            ],
            isAnswered: false
        },
        {
            id: '6.5',
            question: 'for_kids_q_kids_mcq-complaints',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_IMAGE_KIDS',
            options: [
                { value: '6.5.1', label: 'for_kids_q_kids_mcq-complaints_headaches', icon: 'headaches'},
                { value: '6.5.2', label: 'for_kids_q_kids_mcq-complaints_tired_eyes', icon: 'tiredEyes'},
                { value: '6.5.3', label: 'for_kids_q_kids_mcq-complaints_blinking_excessively', icon: 'blinkingExcessively'},
                { value: '6.5.4', label: 'for_kids_q_kids_mcq-complaints_squinting', icon: 'squinting'},
                { value: '6.5.5', label: 'for_kids_q_kids_mcq-complaints_rubbing_eyes', icon: 'rubbingEyes'}
            ],
            isAnswered: false,
            canSelectMultiple: true
        },
    ]
}

const ParentsCategory:iNeedExplorerCategory = {
    id: '7',
    category: 'need_explorer_qest_kids_par',
    questions: [
        {
            id: '7.1',
            question: 'for_parent_q_kids_myopic',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_BOX',
            options: [
                { value: '7.1.1', label: 'for_parent_q_kids_myopic_mother' },
                { value: '7.1.2', label: 'for_parent_q_kids_myopic_father' },
                { value: '7.1.3', label: 'for_parent_q_kids_myopic_brother(s)' },
                { value: '7.1.4', label: 'for_parent_q_kids_myopic_sister(s)' },
                { value: '7.1.5', label: 'need_explorer_no_one' }
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '7.2',
            question: 'for_parent_q_kids_squint_eyes',
            kind: 'need_explorer_single_choice_question',
            type:'CHOICE_BOX',
            options: [
                { value: '7.2.1', label: 'for_parent_q_kids_squint_eyes_yes' },
                { value: '7.2.2', label: 'for_parent_q_kids_squint_eyes_no' }
            ],
            isAnswered: false
        },
        {
            id: '7.3',
            question: 'for_parent_q_kids_outdoors_per_day',
            kind: 'need_explorer_duration_question',
            type: 'DIAL',
            dial: {
                label: 'need_explorer_hours',
                min: 0,
                max: 6,
            },
            answer: 0,
            isAnswered: false
        },
        {
            id: '7.4',
            question: 'for_parent_q_kids_near_work',
            kind: 'need_explorer_duration_question',
            type: 'DIAL',
            dial: {
                label: 'need_explorer_hours',
                min: 0,
                max: 6,
            },
            answer: 0,
            isAnswered: false
        },
        {
            id: '7.5',
            question: 'for_parent_q_kids_mcq-childs_complaints',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_IMAGE_KIDS',
            options: [
                { value: '7.5.1', label: 'for_parent_q_kids_mcq-childs_complaints_headaches', icon: 'headaches'},
                { value: '7.5.2', label: 'for_parent_q_kids_mcq-childs_complaints_tired_eyes', icon: 'tiredEyes'},
                { value: '7.5.3', label: 'for_parent_q_kids_mcq-childs_complaints_blinking_excessively', icon: 'blinkingExcessively'},
                { value: '7.5.4', label: 'for_parent_q_kids_mcq-childs_complaints_squinting', icon: 'squinting'},
                { value: '7.5.5', label: 'for_parent_q_kids_mcq-childs_complaints_rubbing_eyes', icon: 'rubbingEyes'}
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
    ]
}

const needExplorerKidsQuestionaires: iNeedExplorerCategory[] = [
    ChildrenCategory,
    ParentsCategory
];

export default needExplorerKidsQuestionaires;
