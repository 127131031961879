
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { renderToString } from 'react-dom/server';
import needExplorerKidsQuestionaires from '../constants/need-explorer-kids-questionaires.contants';
import neddExplorerQuestionaires from '../constants/need-explorer-questionaires.contants';
import productLogic from '../constants/product-logic.constants';
import iProductFamily from '../interface/product-family.interface';
import iSubProduct from '../interface/sub-product.inteface';
import iProductCombi from '../store/interface/product-combi.interface';

const high_questions_indexes = [2,3,7,8,11,12,14,14,20,21,22,24,26,28,29]
const low_questions_indexes = [0,1,4,5,6,9,10,13,15,17,18,19,23,25,27]
const general_question_indeces = [0,1,2,3,9,10,11,12,17,18,19,20,21,22]

const low_answer_ids = ['1.2.1','1.2.2']

const correct_indexes = { start: 0, end: 8 };
const protect_indexes = { start: 9, end: 16 };
const enhance_indexes = { start: 17, end: 29 };

const product_ids1 = // for all country
[
`varilux_x_series`,
`varilux_digitime`,
`eyezen_start`,
`eyezen_boost`,
`varilux_x_series`,
`varilux_digitime`,
`varilux_road_pilot_ii`,
`eyezen_start`,
`spherical_essilor`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`transitions_signature_gen_8`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`xperio_polarised`,
`crizal_sapphire`,
`crizal_prevencia`,
`crizal_sun_xprotect`,
`crizal_sapphire`,
`crizal_prevencia`,
`crizal_sun_xprotect`,
`crizal_prevencia`,
`crizal_prevencia`,
`crizal_drive`,
`crizal_drive`,
`crizal_sapphire`,
`crizal_sapphire`,
`one_five_nine`]

const product_ids2 = // for russia ONLY
[`varilux_comfort_max`,
`varilux_comfort_max`,
`eyezen_start`,
`eyezen_boost`,
`varilux_comfort_max`,
`varilux_comfort_max`,
`varilux_comfort_max`,
`eyezen_start`,
`spherical_essilor`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`transitions_signature_gen_8`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`xperio_polarised`,
`crizal_sapphire`,
`crizal_prevencia`,
`crizal_sun_xprotect`,
`crizal_sapphire`,
`crizal_prevencia`,
`crizal_sun_xprotect`,
`crizal_prevencia`,
`crizal_prevencia`,
`crizal_drive`,
`crizal_drive`,
`crizal_sapphire`,
`crizal_sapphire`,
`one_five_nine`]

const product_ids3 = // for countries with varilux XR
[
`varilux_xr_series`,
`varilux_x_series`,
`varilux_digitime`,
`eyezen_start`,
`eyezen_boost`,
`varilux_x_series`,
`varilux_digitime`,
`varilux_road_pilot_ii`,
`eyezen_start`,
// `spherical_essilor`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`transitions_signature_gen_8`,
`transitions_signature_gen_8`,
`xperio_polarised`,
`xperio_polarised`,
`crizal_sapphire`,
`crizal_prevencia`,
`crizal_sun_xprotect`,
`crizal_sapphire`,
`crizal_prevencia`,
`crizal_sun_xprotect`,
`crizal_prevencia`,
`crizal_prevencia`,
`crizal_drive`,
`crizal_drive`,
`crizal_sapphire`,
`crizal_sapphire`,
`one_five_nine`]

const brand_ids =
[`varilux`,
`varilux`,
`eyezen`,
`eyezen`,
`varilux`,
`varilux`,
`varilux`,
`eyezen`,
`standard_design`,
`transitions`,
`xperio`,
`transitions`,
`xperio`,
`transitions`,
`transitions`,
`xperio`,
`xperio`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`crizal`,
`none`]

// select array with xr when xr products is available to the given country
const setProductId = (country?: string) => {
    const countriesWithXr = ["ro", "de", "pt", "bg", "gb", "es", "be", 'sl', 'hr', "gr", "ph"]  // countries codes with xr (refer to backoffice)
    
    if (country && country === "ru") {
      return product_ids2
    } else if (country && countriesWithXr.includes(country)) {
      return product_ids3
    } else {
      return product_ids1
    }
}

// DECISION TREE
export const evaluateDecisionTree = (
    currentNeedExplorerData: any,
    isGeneral: boolean,
    pl: iProductFamily,
    country?:string
    ) => {
    // const isRussia = country && country === 'ru'
    // const isXseriesAvailable = getProductInfo(pl).products.find((prd:any) => prd.id === product_ids1[0]) !== undefined
    const product_ids = setProductId(country)
	console.log(country)

    if(isGeneral){
        //(1) get general question ANSWER_IDS
        const ANSWER_IDS = getAnswerIds(currentNeedExplorerData.questions[0].questions)
        console.log('ANSWER IDS: ', ANSWER_IDS)

            //(1.1) check if age is LOW
            let isAgeLow = ANSWER_IDS.includes(low_answer_ids[0]) || ANSWER_IDS.includes(low_answer_ids[1])
            console.log('AGE: ', isAgeLow ? 'LOW' : 'HIGH')

        //(2) get GENERAL_PRODUCT_LOGIC_MATRIX for general
        const GENERAL_PRODUCT_LOGIC_MATRIX = productLogic.filter((q:any) => q.answerId[0] === '1')
        console.log('GENERAL PRODUCT: ', GENERAL_PRODUCT_LOGIC_MATRIX)

        //(3) filter the GENERAL_PRODUCT_LOGIC_MATRIX with ANSWER_IDS
        let ANSWER_MATRIX = []
        for (var i = 0; i < ANSWER_IDS.length; i++) {
            var item  = GENERAL_PRODUCT_LOGIC_MATRIX.find(m => m.answerId === ANSWER_IDS[i]);
            ANSWER_MATRIX.push(item)
        }
        const REFERENCE = ANSWER_MATRIX
        console.log('ANSWER MATRIX: ', ANSWER_MATRIX)

            //(3.1) Apply Special Questions Multiplier in DRAG_AND_DROP_SORTER
            ANSWER_MATRIX = ApplyDragDropSorterMultiplier(ANSWER_MATRIX)
            console.log('APPLY MULTIPLIER: ', ANSWER_MATRIX)

        //(4) Add ANSWER_MATRIX
        const MATRIX_SUM = AddMatrixValues(ANSWER_MATRIX)
        console.log('SUM: ',MATRIX_SUM)

        //(5) change values of high_indexes to 0 if AGE is LOW (>40)
        const AGE_INDEXES = isAgeLow ? low_questions_indexes : high_questions_indexes
        AGE_INDEXES.map((i: any, j:any) => { MATRIX_SUM[i] = 0 })
        console.log('REPLENISH AGE: ',MATRIX_SUM)

        //(6) evaluate highest index per category of current MATRIX_SUM and cut 1.59
        MATRIX_SUM.pop()
        const HIGHEST_SCORE_INDECES = getHighestScorePerCategory(MATRIX_SUM)

        //(7) match indexes to product_ids
        const { pair1, pair2 } = HIGHEST_SCORE_INDECES

            // (7.1) SPECIAL SCENARIO: When  Xperio is recommended in PROTECT, Crizal Sun Xprotect must be chosen in ENHANCE
            let isXperio = brand_ids[pair1.protect+protect_indexes.start] === 'xperio'

        //(8) Apply product data stucture find product via id

        const TEMP = {
            pair1: {
                correct: {
                    brand: fetchBrandDetailsViaId(brand_ids[pair1.correct], pl),
                    product: fetchProductDetailsViaId(product_ids[pair1.correct], pl),
                    subProduct: fetchSubProductDetailsViaProductId(product_ids[pair1.correct], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.correct, REFERENCE)
                },
                protect: [{
                    brand: fetchBrandDetailsViaId(brand_ids[pair1.protect+protect_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair1.protect+protect_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.protect+protect_indexes.start, REFERENCE)
                }],
                enhance: isXperio ? {
                    brand: fetchBrandDetailsViaId('crizal', pl),
                    product: fetchProductDetailsViaId('crizal_sun_xprotect', pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.enhance+enhance_indexes.start, REFERENCE)
                } : {
                    brand: fetchBrandDetailsViaId(brand_ids[pair1.enhance+enhance_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair1.enhance+enhance_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.enhance+enhance_indexes.start, REFERENCE)
                },
            },
            pair2: {
                correct: {
                    brand: fetchBrandDetailsViaId(brand_ids[pair2.correct], pl),
                    product: fetchProductDetailsViaId(product_ids[pair2.correct], pl),
                    subProduct: fetchSubProductDetailsViaProductId(product_ids[pair2.correct], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair2.correct, REFERENCE)
                },
                protect: [{
                    brand: fetchBrandDetailsViaId(brand_ids[pair2.protect+protect_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair2.protect+protect_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair2.protect+protect_indexes.start, REFERENCE)
                }],
                enhance: isXperio ? {
                    brand: fetchBrandDetailsViaId('crizal', pl),
                    product: fetchProductDetailsViaId('crizal_sun_xprotect', pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.enhance+enhance_indexes.start, REFERENCE)
                } : {
                    brand: fetchBrandDetailsViaId(brand_ids[pair2.enhance+enhance_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair2.enhance+enhance_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair2.enhance+enhance_indexes.start, REFERENCE)
                }
            },
            answers: ANSWER_IDS
        }

        return TEMP
    }else{
        //(1) get all answers from general and category question ANSWER_IDS
        const allAnswers = [
            ...currentNeedExplorerData.questions[0].questions,
            ...currentNeedExplorerData.questions[1].questions,
            ...currentNeedExplorerData.questions[2].questions,
            ...currentNeedExplorerData.questions[3].questions,
            ...currentNeedExplorerData.questions[4].questions
        ]

        console.log('ALL ANSWERS: ', allAnswers)

        let ANSWER_IDS = getAnswerIds(allAnswers)
        console.log('ANSWER IDS: ', ANSWER_IDS)

            //(1.0) Special Scenario: If the user choose anything between 3.1.1 to 3.1.5, remove 3.1.6
            ANSWER_IDS = selectedNone(ANSWER_IDS)
            console.log('ANSWER IDS (clean): ', ANSWER_IDS)

            //(1.1) check if age is LOW
            let isAgeLow = ANSWER_IDS.includes(low_answer_ids[0]) || ANSWER_IDS.includes(low_answer_ids[1])
            console.log('AGE: ', isAgeLow ? 'LOW' : 'HIGH')

        //(2) get ALL_PRODUCT_LOGIC_MATRIX for general and extra
        const ALL_PRODUCT_LOGIC_MATRIX = productLogic
        console.log('ALL PRODUCT: ', ALL_PRODUCT_LOGIC_MATRIX)

        //(3) filter the ALL_PRODUCT_LOGIC_MATRIX with ANSWER_IDS
        let ANSWER_MATRIX = []
        for (var i = 0; i < ANSWER_IDS.length; i++) {
            var item  = ALL_PRODUCT_LOGIC_MATRIX.find(m => m.answerId === ANSWER_IDS[i]);
            ANSWER_MATRIX.push(item)
        }
        const REFERENCE = ANSWER_MATRIX
        console.log('ANSWER MATRIX: ', ANSWER_MATRIX)

            //(3.1) Apply Special Questions Multiplier in DRAG_AND_DROP_SORTER
            ANSWER_MATRIX = ApplyDragDropSorterMultiplier(ANSWER_MATRIX)
            console.log('APPLY MULTIPLIER: ', ANSWER_MATRIX)

        //(4) Add ANSWER_MATRIX
        const MATRIX_SUM = AddMatrixValues(ANSWER_MATRIX)
        console.log('SUM: ',MATRIX_SUM)

        //(5) change values of high_indexes to 0 if AGE is LOW (>40)
        const AGE_INDEXES = isAgeLow ? low_questions_indexes : high_questions_indexes
        AGE_INDEXES.map((i: any, j:any) => { MATRIX_SUM[i] = 0 })
        console.log('REPLENISH AGE: ',MATRIX_SUM)

        //(6) change values of general questions to 0 if the user finished the extra questions
        general_question_indeces.map((i: any, j:any) => { MATRIX_SUM[i] = 0 })
        console.log('REPLENISH GEN QUESTIONS: ', MATRIX_SUM)

        //(7) evaluate highest index per category of current MATRIX_SUM and cut 1.59
        MATRIX_SUM.pop()
        const HIGHEST_SCORE_INDECES = getHighestScorePerCategory(MATRIX_SUM)

        //(8) match indexes to product_ids
        const { pair1, pair2 } = HIGHEST_SCORE_INDECES

            // (8.1) SPECIAL SCENARIO: When  Xperio is recommended in PROTECT, Crizal Sun Xprotect must be chosen in ENHANCE
            let isXperio = brand_ids[pair1.protect+protect_indexes.start] === 'xperio'

        //(9) Apply product data stucture find product via id

        const TEMP = {
            pair1: {
                correct: {
                    brand: fetchBrandDetailsViaId(brand_ids[pair1.correct], pl),
                    product: fetchProductDetailsViaId(product_ids[pair1.correct], pl),
                    subProduct: fetchSubProductDetailsViaProductId(product_ids[pair1.correct], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.correct, REFERENCE)
                },
                protect: [{
                    brand: fetchBrandDetailsViaId(brand_ids[pair1.protect+protect_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair1.protect+protect_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.protect+protect_indexes.start, REFERENCE)
                }],
                enhance: isXperio ? {
                    brand: fetchBrandDetailsViaId('crizal', pl),
                    product: fetchProductDetailsViaId('crizal_sun_xprotect', pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.enhance+enhance_indexes.start, REFERENCE)
                } : {
                    brand: fetchBrandDetailsViaId(brand_ids[pair1.enhance+enhance_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair1.enhance+enhance_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.enhance+enhance_indexes.start, REFERENCE)
                },
            },
            pair2: {
                correct: {
                    brand: fetchBrandDetailsViaId(brand_ids[pair2.correct], pl),
                    product: fetchProductDetailsViaId(product_ids[pair2.correct], pl),
                    subProduct: fetchSubProductDetailsViaProductId(product_ids[pair2.correct], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair2.correct, REFERENCE)
                },
                protect: [{
                    brand: fetchBrandDetailsViaId(brand_ids[pair2.protect+protect_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair2.protect+protect_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair2.protect+protect_indexes.start, REFERENCE)
                }],
                enhance: isXperio ? {
                    brand: fetchBrandDetailsViaId('crizal', pl),
                    product: fetchProductDetailsViaId('crizal_sun_xprotect', pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair1.enhance+enhance_indexes.start, REFERENCE)
                } : {
                    brand: fetchBrandDetailsViaId(brand_ids[pair2.enhance+enhance_indexes.start], pl),
                    product: fetchProductDetailsViaId(product_ids[pair2.enhance+enhance_indexes.start], pl),
                    significantAnswers: getAnswerIDwithSignificantAnswers(pair2.enhance+enhance_indexes.start, REFERENCE)
                }
            },
            answers: ANSWER_IDS
        }

        return TEMP
    }
}

export const getAnswerIDwithSignificantAnswers = (index: number, answerMatrix: any) => {
    console.log('answerMatrix', answerMatrix)
    console.log('index', index)

    const filtered = answerMatrix.filter((am: any) => {
        return am && am.scoreMatrix[index] !== 0
    })

    console.log('filtered', filtered)

    return filtered
}

export const fetchSubProductDetailsViaProductId = (id: string | undefined, pl: iProductFamily) => {
    const allProducts = getProductInfo(pl).products
    const subProducts = allProducts
    .filter((a:any) => a.id === id && a.enabled)
    .map((a:any) => a.subProducts)
    if(subProducts && subProducts[0] && subProducts[0].length > 0){
        let uniqueSubProduct = getUniqueSubProducts(subProducts[0], true);

        if(uniqueSubProduct.length === 1){
            return uniqueSubProduct[0]
        }
    }

    return null
}

export const fetchSubProductDetailsViaSubProductId = (id: string | undefined, pl: iProductFamily) => {
    const allSubProducts = getProductInfo(pl).subProducts

    const getSubProductFromId = allSubProducts.filter((a:any) => a.id === id)

    return getSubProductFromId ? { price: 0, ...getSubProductFromId[0] } : { price: 0 }
}

export const fetchProductDetailsViaId = (id: string | undefined, pl: iProductFamily) => {
    const allProducts = getProductInfo(pl).products
    const getProductFromId = allProducts.filter((a:any) => a.id === id && a.enabled)

    return getProductFromId ? { subProducts: [{ id: '', label :'', price: 0, code: '', index: 0}], price: 0, ...getProductFromId[0] } : { subProducts: [{ id: '', label :'', price: 0, code: '', index: 0}], price: 0 }
}

export const fetchBrandDetailsViaProductId = (id: string | undefined, pl: iProductFamily) => {
    const allBrands = getProductInfo(pl).brands
    const brand = allBrands.filter((a:any) => {
        let hasId = false

        a.products.map((p:any)=>{
            if(p.id === id){
                hasId = true
                return
            }
        })

        return hasId ? a : false
    })

    return brand.length > 0 ? brand[0] : {}
}

export const fetchBrandDetailsViaId = (id: string, pl: iProductFamily) => {
    const allBrands = getProductInfo(pl).brands

    const getBrandFromId = allBrands.filter((a:any) => a.id === id && a.enabled)

    return getBrandFromId[0]
}

export const getProductInfo = (productList: iProductFamily) => {
    let products = <Object[]>[];
    let subProducts = <Object[]>[];
    let brands = <Object[]>[];

    //correct, protect, enhance products in one layer
    if(Array.isArray(productList)){
        productList.map((l:any) => {
            l.brands.map((brand:any) => {
                brands.push(brand)
                brand.products.map((b:any) => {
                    products.push(b)
                    if(Array.isArray(b.subProducts)){
                        b.subProducts.map((s:any) => {
                            subProducts.push(s)
                        })
                    }
                })
            })
        })
    }

    return {
        products: products,
        brands: brands,
        subProducts: subProducts
    }
}

export const getAnswerIds = (data: any) => {
    var answerIDs = <String[]>[];

    // DRAG_AND_DROP_SORTER Selections
    data
        .filter((q:any) => q.type === 'DRAG_AND_DROP_SORTER')
        .map((a:any) => {
            a.answer.map((v: any) => { answerIDs.push(v.value) } )
        })

    // DIAL Selections
    var dialAnswers: { answer: number, qid: string }[] = []

    data
        .filter((q:any) => q.type === 'DIAL')
        .map((a:any) => { dialAnswers.push({answer: a.answer, qid: a.id}) })

    answerIDs = dialLogic(dialAnswers, answerIDs)

    // Single Selections
    data
        .filter((q:any) => { return !q.canSelectMultiple })
        .filter((q:any) => q.type !== 'SLIDER_GROUP')
        .filter((q:any) => q.type !== 'DRAG_AND_DROP_SORTER')
        .filter((q:any) => q.type !== 'DIAL')
        .filter((q:any) => q.answer !== undefined)
        .map((q:any) => { answerIDs.push(q.answer.value) })

    // Multiple Selections
    data
        .filter((q:any) => { return q.canSelectMultiple })
        .filter((q:any) => q.type !== 'SLIDER_GROUP')
        .filter((q:any) => q.type !== 'DRAG_AND_DROP_SORTER')
        .filter((q:any) => q.type !== 'DIAL')
        .filter((q:any) => q.answer !== undefined)
        .map((a:any) => {
            a.answer.map((v: any) => { answerIDs.push(v.value) } )
        })

    return answerIDs
}

const selectedNone = (ANSWER_IDS:any) => {
    let has = false

    ANSWER_IDS.map((a:any) => {
        if(a === '3.1.1' || a === '3.1.2' || a === '3.1.3' || a === '3.1.4' || a === '3.1.5'){
            has = true
        }
    })

    return has ? ANSWER_IDS.filter((a:any) => a!== '3.1.6') : ANSWER_IDS
}

const dialLogic = (dialAnswers:any, answerIDs:any) => {
    dialAnswers.map((a:any) => {
        const { answer, qid } = a
        let ans = ''

        switch(qid){
            case '7.3' : { ans = answer === 0 || answer === 1 ? '7.3.1' :
                                 answer === 2 || answer === 3 ? '7.3.2' :
                                 answer === 4 || answer === 5 ? '7.3.3' : '7.3.4' } break;
            case '7.4' : { ans = answer === 0 || answer === 1 ? '7.4.1' :
                                 answer === 2 || answer === 3 ? '7.4.2' :
                                 answer === 4 || answer === 5 ? '7.4.3' : '7.4.4' } break;
            case '2.2' : { ans = answer === 0 || answer === 1 ? '0.0.0' : '2.2.1' } break;
            case '4.3' : { ans = answer === 0 || answer === 1 ? '0.0.0' : '4.3.1' } break;
            case '3.2' : { ans = answer === 0 || answer === 1 ? '0.0.0' : '3.2.1' } break;
            default: return ''
        }

        answerIDs.push(ans)
    })

    return answerIDs
}

export const getHighestScorePerCategory = (
    sumMatrix: any
) => {
    // Get the highest score for Correct Category
    const correctScores = sumMatrix.filter((x:any, i: number) => { return i < correct_indexes.end+1 })
    const indeces_correct = GetFirstSecondHighestIndex(correctScores)
    console.log('CORRECT: ', correctScores)

    // // Get the highest score for Protect Category
    const protectScores = sumMatrix.filter((x:any, i: number) => { return i >= protect_indexes.start && i <= protect_indexes.end })
    const indeces_protect = GetFirstSecondHighestIndex(protectScores)
    console.log('PROTECT: ', protectScores)

    // // Get the highest score for Enhance Category
    const enhanceScores = sumMatrix.filter((x:any, i: number) => { return i >= enhance_indexes.start && i <= enhance_indexes.end })
    const indeces_enhance = GetFirstSecondHighestIndex(enhanceScores)
    console.log('ENHANCE: ', enhanceScores)

    return {
        pair1:{
            correct: indeces_correct.first,
            protect: indeces_protect.first,
            enhance: indeces_enhance.first
        },
        pair2: {
            correct: indeces_correct.second,
            protect: indeces_protect.second,
            enhance: indeces_enhance.second
        }
    }
}

export const AddMatrixValues = (matrix: any) => {
    console.log(matrix)
    return matrix.reduce((r:any, a:any) => a.map((b:any, i:any) => (r[i] || 0) + b), []);
}

export const GetFirstSecondHighestIndex = (inp: any) => {
    var outp = new Array();
    for (var i = 0; i < inp.length; i++) {
        outp.push(i);
        if (outp.length > 2) {
            outp.sort(function(a, b) { return inp[b] - inp[a]; });
            outp.pop();
        }
    }
    return {
        first:outp[0],
        second:outp[1]
    }
}

export const ApplyDragDropSorterMultiplier = (am: any) => {
    // get all 1.6, 4.4
    let rank_answers_1_6 = am.filter((q:any) => { return q &&(`${q.answerId[0]}.${q.answerId[2]}` === '1.6')})
    let rank_answers_4_4 = am.filter((q:any) => { return q && (`${q.answerId[0]}.${q.answerId[2]}` === '4.4')})

    console.log('--rank_answers_1_6', rank_answers_1_6)
    console.log('--rank_answers_4_4', rank_answers_4_4)

    // cut 1.6, 4.4 answers
    let cut_ranking_answers = am
    .filter((q:any) => { return q && (`${q.answerId[0]}.${q.answerId[2]}` !== '1.6')})
    .filter((q:any) => { return q && (`${q.answerId[0]}.${q.answerId[2]}` !== '4.4')})

    console.log('--cut_ranking_answers', cut_ranking_answers)

    // reverse to get the highest multiplier
    rank_answers_1_6.reverse()
    rank_answers_4_4.reverse()

    // multiply the index to each of the matrix
    const answers_multiplied_1_6 = rank_answers_1_6.map((i: any, j:any) => {
        const multiplied = i.scoreMatrix.map((k: any) => {
            return k*j
        })

        return {
            answerId: i.answerId,
            scoreMatrix: multiplied
        }
    })

    // multiply the index to each of the matrix
    const answers_multiplied_4_4 = rank_answers_4_4.map((i: any, j:any) => {
        const multiplied = i.scoreMatrix.map((k: any) => {
            return k*j
        })

        return {
            answerId: i.answerId,
            scoreMatrix: multiplied
        }
    })

    console.log('--answers_multiplied_1_6', answers_multiplied_1_6)
    console.log('--answers_multiplied_4_4', answers_multiplied_4_4)

    const newAnswerMatrix = [...cut_ranking_answers, ...answers_multiplied_1_6, ...answers_multiplied_4_4]

    return newAnswerMatrix.map((i: any) => {
        return i.scoreMatrix
    })
}

const getUniqueSubProducts = (subProducts: iSubProduct[], isLowestIndex = false): iSubProduct[] => {
    const unique: string[] = []
    const uniqueSubProducts =  subProducts.filter((p: iSubProduct) => {
        if(unique.includes(p.label))return false;
        unique.push(p.label);
        return true;
    });
    if(!isLowestIndex) return uniqueSubProducts;
    // get the subproducts with lowest index
    const lowestIndex:iSubProduct[] = []
    uniqueSubProducts.forEach( x => {
        const sortSubProducts = subProducts.filter( p => p.label === x.label).sort(function(a,b){
            return a.index - b.index;
        });
        lowestIndex.push(sortSubProducts[0]);
    });
    return lowestIndex;
}

export const getAnswerDetails = (isKids: boolean, answers: string[]) => {
    let age = ''

    if(isKids){
        //AGE
        const allAnswers = getAllAnswersInOneLayer(needExplorerKidsQuestionaires)
        const kidsAge_allAnswer = allAnswers.filter((a:any)=>{
            return a.value === '6.1.1' || a.value === '6.1.2' || a.value === '6.1.3' || a.value === '6.1.4' || a.value === '6.1.5' || a.value === '6.1.6' || a.value === '6.1.7'
        })
        const kidsAge_answer = answers.filter((a:any)=>{ return a[0] === '6' && a[2] === '1' })
        const selectedAge = kidsAge_allAnswer.filter((a:any)=>{ return a.value === kidsAge_answer[0] })

        age = selectedAge.length > 0 ? selectedAge[0].label.replace("<br/>","") : ''
    }else{
        //AGE
        const allAnswers = getAllAnswersInOneLayer(neddExplorerQuestionaires)
        const adultAge_allAnswer = allAnswers.filter((a:any)=>{
            return a.value === '1.2.1' || a.value === '1.2.2' || a.value === '1.2.3' || a.value === '1.2.4' || a.value === '1.2.5'
        })
        const adultAge_answer = answers.filter((a:any)=>{ return a && (a[0] === '1' && a[2] === '2') })
        const selectedAge = adultAge_allAnswer.filter((a:any)=>{ return a.value === adultAge_answer[0] })

        age = selectedAge.length > 0 ? selectedAge[0].label.replace("<br/>","") : ''
    }

    return {
        age: age
    }
}

const getAllAnswersInOneLayer = (arr: any) => {
    let allAnswers = new Array()

    arr.map((q:any)=>{
        q.questions.map((qq:any)=>{
            if(qq.options && Array.isArray(qq.options)){
                qq.options.map((o:any) => {
                    allAnswers.push(o)
                })
            }
        })
    })

    return allAnswers
}

// OTHERS

export enum SummaryExportType {
    QRCODE = 'qrcode',
    PRINT = 'print',
    EMAIL = 'email',
}

export function saveToPdf(params: {
    inputDom: HTMLDivElement | undefined | null,
    pdfName?: string | undefined | null,
    pageOptions?: any,
    scale?: number,
    onComplete?: () => void
}) {

    const {
        inputDom,
        pdfName,
        pageOptions,
        scale = 2,
        onComplete
    } = params;

    if( !inputDom ) return;
    const { topOffset = {}, removeElements = [], containerWidth } = pageOptions ?? {};
    for(const element of removeElements) {
        const elementToRemove = document.getElementById(element) as HTMLElement
        elementToRemove.setAttribute('style','display: none')
    }
    inputDom.setAttribute("style", `overflow: visible; ${containerWidth ? `width: ${containerWidth}` : ''}`)
    html2canvas(inputDom,{scale})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'letter', true);
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        const imgHeight = (pdfName?.includes("_lens_consultation_summary_")) ? height : canvas.height * width / canvas.width
        let pageTopOffset = 0;
        let currentPage = 1;
        const isMultiplePage = (imgHeight - height > height * .20)
        const firstPageImgHeight = isMultiplePage ? imgHeight : height;
        const additionalOffset = ( topOffset: any, currentPage: number ) =>
            topOffset.hasOwnProperty(currentPage) ? topOffset[currentPage] :  0

        pdf.addImage(imgData, 'PNG', 0, pageTopOffset + additionalOffset(topOffset, currentPage), width, firstPageImgHeight);
        if( isMultiplePage ) {
            let remainingHeight = imgHeight;
            remainingHeight -= height;
            while(remainingHeight >= 0){
                pageTopOffset -= height;
                currentPage++;
                pdf.addPage('letter');
                pageTopOffset = Math.abs(pageTopOffset) > imgHeight ? -Math.abs(imgHeight) : pageTopOffset;
                pdf.addImage(imgData, 'PNG', 0, pageTopOffset + additionalOffset(topOffset, currentPage), width, imgHeight);
                remainingHeight -= height;
            }
        }
        pdf.save(pdfName ?? `document.pdf`);
        if(onComplete)onComplete();
      });
    inputDom.removeAttribute("style")
    for(const element of removeElements) {
        const elementToRemove = document.getElementById(element) as HTMLElement
        elementToRemove.removeAttribute('style')
    }
}

export const sendMail = (receiver: string, subject: string, token: string, emailComponentTemplate: any, ) => {
    const currentDateTime = new Date().toLocaleString([], {day: '2-digit' ,month: '2-digit', year: 'numeric', hour: '2-digit', minute:'2-digit'})
    const requestBody = {
        "from": "noreply.pwa@essilor.com",
        "to": receiver,
        "subject": `${subject} ${currentDateTime}`,
        "body": `<div style="width:fit-content; max-width: 100%">
                   ${renderToString(emailComponentTemplate)}
                <div/>`
    }
    const axiosConfig =  { headers: {"Authorization" : `Bearer ${token}`} }
    // const testurl = 'https://cpbx-dev-app-frc-03-companion.azurewebsites.net/api/v1/email'
    const testurl = 'https://essilor-prd.apigee.net/gse/essilorcompanion/email';
    axios.post(testurl, requestBody, axiosConfig).then((res)=>console.info(res)).catch((error)=>console.error(error))
}

export const fetchProductCombi = (
    productCombi: iProductCombi[],
    cs:string|boolean,
    p1:string|boolean,
    p2:string|boolean,
    e:string|boolean,
    index:string
) => {

    console.log('correct', cs)
    console.log('protect', p1)
    console.log('protect2', p2)
    console.log('enhance', e)
    console.log('correctIndex', index)


    console.log('xxxx')
    console.log('pc', productCombi)

    const res = productCombi.filter((pc:any)=>{
        return pc.correct_sub === cs &&
        pc.protect1 === p1 &&
        pc.protect2 === p2 &&
        pc.enhance === e &&
        pc.index === index
    })

    console.log('yyyy')

    console.log('res',res)

    return res.length > 0 && Array.isArray(res) ? res[0].price : '0'
}
