import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TRANSLATIONS_MULTIPLE_LANG } from '../../constants/translations.contants';
import iRegion from '../../interface/region.interface';
import appSlice, { getApp } from '../../store/slices/app.slice';
import lensConsultationSlice from '../../store/slices/lens-consultation.slice';
import { getProducts } from '../../store/slices/products.slice';
import sidebarSlice from '../../store/slices/sidebar.slice';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import useBEM from '../../utils/hooks/useBEM';
import useProducts from '../../utils/hooks/useProducts';
import useTranslation from '../../utils/hooks/useTranslation';
import Modal from '../Modal';
import RegionSelection from '../RegionSelection';
import Button from '../ui/Button';
import Language from './components/Language';
import MyComapanion from './components/MyComapanion';
import TermsCondition from './components/TermsCondition';
interface Props {
    // show: boolean
}

const FirstLogin : React.FC<Props>  = ({
    // show
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('first-login');
    const modalStyle = {
        width: "95.4rem",
        height: "69.8rem",
        borderRadius: "15px"
    }
    const { 
        firstLogin, 
        regions, 
        accountDetails, 
        translationCountry,
        translationCountryLanguage
    } = useSelector(getApp);
    const dispatch = useDispatch();
    const { t, getTranslations2 } = useTranslation(useSelector(getApp));
    const {getCurrency } = useProducts(useSelector(getProducts));
    const [currentRegion, setCurrentRegion] = useState<iRegion>();
    const { update } = React.useContext(IndexedDBContext) as IndexedDBContextType;

    // HOOKS
    useEffect(() => {
        dispatch(appSlice.actions.setViewFirstLogin('region'));
        dispatch(appSlice.actions.setCookieEnabled(getCookies())); 
    }, [])
    // METHODS
    const modalOnClose = () => {
        dispatch(appSlice.actions.setOpenFirstLogin(false));
        dispatch(appSlice.actions.setFirstLoginStatus(true));
    }

    const loadCurrency = async() =>{
        const currency = await getCurrency();
        dispatch(lensConsultationSlice.actions.setCurrency(currency.responseData));
    }   

    const getCookies = () =>{
        const pairs = document.cookie.split(";");
        let cookies:any = {};
        for (var i=0; i<pairs.length; i++){
            var pair = pairs[i].split("=");
            cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
        }
        if(cookies[""] === "" || cookies._ga !== undefined) return true;
        return false;
    }

    const onSave = async () =>{
        if(firstLogin?.view === 'region' && currentRegion){
            const {responseData: {data: translationsData}} = await getTranslations2(currentRegion?.id, true);
            if(!translationsData)return;
            await update('languageStore', {
                id: 'translations',
                value: translationsData.country
            });   
            dispatch(appSlice.actions.setViewFirstLogin('language'));  
            dispatch(appSlice.actions.setTranslationRegion(currentRegion));                    
            return;
        }
        if(firstLogin?.view === 'language'){
            loadCurrency();
            dispatch(appSlice.actions.setLanguage(firstLogin.selectedLanguage || 'enUS'));
            dispatch(appSlice.actions.setSubLanguage(firstLogin.subLanguage?.code || 'enUS'));
            dispatch(appSlice.actions.setViewFirstLogin('companion'));
        }
    }

    const onSelectRegion = (r: iRegion) => {
        setCurrentRegion(r);
    }

    const onNextPage = () =>{
        switch(firstLogin?.view){
            case 'region': 
                return <RegionSelection 
                            onSelect={onSelectRegion} 
                            items={regions} 
                            currentRegion={currentRegion}                            
                        />
            case 'language':
                return <Language/>
            case 'companion':
                return  <MyComapanion/>
            default: {
                if(firstLogin?.cookieEnabled){                    
                    return <TermsCondition/>
                }
            }
        }
    }

    const onTitleChange = () =>{
        switch(firstLogin?.view){
            case 'region':
                return t('region_filter')

            case 'language':
                return t('language_lang_select_your_language')
            case 'companion':
                return   t('catalog_first_time_login_catalog');
            default: {
                if(firstLogin?.cookieEnabled){                    
                    return "Terms and conditions of usage";
                }
            }
        }
    }

    const isMultiLanguage = (code: string) => {
        const keys = Object.keys(TRANSLATIONS_MULTIPLE_LANG);
        const isMulti = keys.includes(code);

        return {
            condition: isMulti,
            data: TRANSLATIONS_MULTIPLE_LANG[code]
        };
    }

    const disableLanguage = () =>{
        if( 
            !firstLogin?.selectedLanguage ||
            firstLogin?.view === 'region' && !currentRegion ||
            firstLogin?.view === 'language' && !translationCountryLanguage
        )return true;

        return false;
    }

    return (
        <Modal show={firstLogin?.isOpen} style={modalStyle} className={B()} onClose={() => modalOnClose()}
        closeModalOnOutsideClick = {false}
        hideCloseButton={true}>
                <div className={E('modal')}>
                    <div className={E('header')}>
                        {
                            firstLogin?.view === 'companion' && (
                                <Button                             
                                    text={t('language_lang__back_')}
                                    type="secondary" weight="normal"
                                    icon="left-arrow-circle-gray" 
                                    iconPosition="left" 
                                    click={()=>dispatch(appSlice.actions.setViewFirstLogin('language'))} 
                                /> 
                            )
                        }
                        
                        <h3>
                            {
                                onTitleChange()
                            }
                        </h3>
                    </div>
                    <div className={E('body '+ firstLogin?.view)}>
                        {
                            onNextPage()
                        }
                        
                    </div>
                    {
                        ( firstLogin?.view === 'language' ||  firstLogin?.view === 'region' ) && 
                        <div className={E('footer')}>
                            <Button 
                                text={t('language_lang_next')} 
                                icon="right-arrow-circle-white" 
                                click={()=>onSave()}
                                disabled={disableLanguage()}
                            />
                        </div>
                    }
                    
                </div>                           
        </Modal>
    )
}

export default FirstLogin;