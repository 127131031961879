import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iNeedExplorerQuestion from '../../../interface/need-explorer-question.interface';
import { getApp } from '../../../store/slices/app.slice';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import userSlice from '../../../store/slices/user.slice';
import ExperienceType from '../../../types/experience.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import ChoiceBox from './question-types/ChoiceBox';
import ChoiceBoxImage from './question-types/ChoiceBoxImage';
import ChoiceBoxImageDuration from './question-types/ChoiceBoxImageDuration';
import ChoiceBoxKids from './question-types/ChoiceBoxKids';
import ChoiceImageKids from './question-types/ChoiceImageKids';
import ChoiceList from './question-types/ChoiceList';
import Dial from './question-types/Dial';
import DragAndDropSorter from './question-types/DragAndDropSorter';
import Note from './question-types/Note';
import SliderGroup from './question-types/SliderGroup';


interface Props {
    question: iNeedExplorerQuestion
}

const NeedExplorerQuestion : React.FC<Props>  = ({
    question
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-question');
    const dispatch = useDispatch();
    const { t } =  useTranslation(useSelector(getApp));
    const currentNeedExplorerData = useSelector(getNeedExplorer);
    const {
        categoryStep,
        questionStep,
    } = useSelector(getNeedExplorer);
    const appStore = useSelector(getApp);
    const { experience } = appStore;

    // HOOKS
    useEffect(() => {
        dispatch(userSlice.actions.saveNeedExplorerData(currentNeedExplorerData));
    }, [currentNeedExplorerData])

    // METHODS
    const answerQuestion = (answer: any) => {
        const category = categoryStep;
        const question = questionStep;
        dispatch(needExplorerSlice.actions.answerQuestion({answer, category, question}));
        dispatch(needExplorerSlice.actions.setShowSaved(true))
    }

    const answerPanel = (q: iNeedExplorerQuestion) => {
        switch (q.type) {
            case 'CHOICE_LIST':
                return <ChoiceList question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_BOX':
                return <ChoiceBox question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_BOX_KIDS':
                return <ChoiceBoxKids question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_IMAGE_KIDS':
                return <ChoiceImageKids question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'SLIDER_GROUP':
                return <SliderGroup question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'DRAG_AND_DROP_SORTER':
                return <DragAndDropSorter question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_BOX_IMAGE':
                return <ChoiceBoxImage question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'NOTE':
                return <Note question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'DIAL':
                return <Dial question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'DURATION_GROUP':
                return <ChoiceBoxImageDuration question={q} onAnswer={(ans) => answerQuestion(ans)} />
            default:
                return null;
        }
    }

    return (
        <div className={B()}>
            <h4 className={E( experience === ExperienceType.SUN ? 'question-sun': 'question' , [question?.subQuestion ? 'with-subquestion' : ''])}>
                {t(question?.question)}
                {/* <span className={E('question-subtext')}>
                    {question?.subQuestion}
                </span> */}
            </h4>
            
            <label className={E(experience === ExperienceType.SUN ? 'question-type-sun': 'question-type')}>{t(question?.kind)}</label>
            {/* {
                question.subQuestion &&
                <label className={E('sub-question')}> {t(question.subQuestion)} </label>
            } */}

            { answerPanel(question) }
        </div>
    )
}

export default NeedExplorerQuestion;
