import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {

}

const LensProposaBuilderlExperience : React.FC<Props>  = () =>  {
    // VARIABLES
    const [experience, setExperience] = useState(1);
    const { t } = useTranslation(useSelector(getApp));
    const buttons = [
        { id: 1, text: 'lens_proposal_builder_lpb__standard_experience' },
        { id: 2, text: 'lens_proposal_builder_lpb__ava_tm_experience', soon: true },
        { id: 3, text: 'lens_proposal_builder_lpb__sun_experience', soon: true}
    ];

    // HOOKS

    // METHODS
    const buttonIcon = (status?: string) => {
        return <span className={`icon ${status ? 'icon--active' : ''}`}></span>;
    }

    return (
        <div className="lens-proposal-experience">
            {
                buttons.map( b => (
                    <Button 
                        soon={b.soon}
                        click={() => setExperience(b.id)}
                        active={b.id === experience}
                        text={t(b.text)} 
                        type="simple" 
                        icon={buttonIcon( b.id === experience ? 'active' : '' )} 
                        key={b.id}
                    />
                ))
            } 
        </div>
    )
}

export default LensProposaBuilderlExperience;