import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import Text from '../../../components/ui/Text';
import { DISCOVER_STEPS } from '../../../constants/discovery.constants';
import { getApp } from '../../../store/slices/app.slice';
import DiscoveryView from '../../../types/discovery-view.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    onNextView: (view: DiscoveryView) => void
}

const DiscoverySteps : React.FC<Props>  = ({
    onNextView
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('discovery-steps');
    const [step, setStep] = useState(0);
    const [animateIn, setAnimateIn] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);
    const [fadeInSprite, setFadeInSprite] = useState(false);
    const  {t} = useTranslation(useSelector(getApp));

    const steps = DISCOVER_STEPS

    // HOOKS
    useEffect(() => {
        setAnimateIn(true);

        setTimeout(() => {
            setFadeInSprite(true);
        }, 1000);
    }, [])

    // METHODS
    const next = () => {
        if(step === (steps.length - 1)) {            
            onNextView(DiscoveryView.LENS);
            setFadeInSprite(false);
            setTimeout(() => setAnimateOut(true),1000)
            return;
        }
        setStep(step + 1);
    }

    const generateStepDots = (count: number) => {
        return new Array(count).fill('').map( (item, index) => <div key={index}>
            <span>{index+1}/{steps.length}</span>
        </div> );
    }

    const animationClass = () => {
        const clas: string[] = [];
        if(animateIn)clas.push('animate-in');
        if(animateOut)clas.push('animate-out');        
        return clas;
    }


    return (
        <div className={B(animationClass())}>            
            <div className={E('padding')}></div>
            <div className={E('content')}>
                <div className={E('active-dots')}>
                    {generateStepDots(step + 1)}
                </div>

                <div className={E('content-inner')}>
                    <h2>
                        {t(steps[step].title)}
                    </h2>
                    <Text type="white">{t(steps[step].description)}</Text>

                    <Button 
                        click={next}
                        text={t('discovery_essilor_de_next')} 
                        icon={<CircleArrow type="right" background="#f1f1f1" size=".4rem"/>}
                        type="simple"/>
                </div>

                <div className={E('inactive-dots')}>
                    {generateStepDots(steps.length - (step + 1))}
                </div>                
            </div>
        </div>
    )
}

export default DiscoverySteps;