import React, { useEffect, useState } from 'react';
import { controlOptions } from '../../../constants/education.constants';
import useBEM from '../../../utils/hooks/useBEM';
import Dropdown from '../../ui/Dropdown';
import ToggleRadio from '../../ui/ToggleRadio';
import Slider from 'react-input-slider';
import ToggleButton from '../../ui/ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import educationSlice, { getEducation } from '../../../store/slices/education.slice';
import EnvironmentType from '../../../types/environment.type';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';


interface Props {
    isOpen: boolean
}

const EducationControls : React.FC<Props>  = ({
    isOpen = true
}) =>  {
    // VARIABLES
    const { 
        isItemEnabled, 
        isLensEnabled,
        environment
    } = useSelector(getEducation);
    const dispatch = useDispatch();

    const [B, E] = useBEM('education-controls');
    const [visualDefect1, setVisualDefect1] = useState<string>();
    const [visualDefect2, setVisualDefect2] = useState<string>();
    // const [toggleItem, setToggleItem] = useState<'on' | 'off'>('off');
    // const [toggleLens, setToggleLens] = useState<'on' | 'off'>('off');

    const [item, setItem] = useState<string>();
    const [lensSlider, setLensSlider] = useState(5);
    const [screenMode, setScreenMode] = useState('');
    const { t } = useTranslation(useSelector(getApp));
    const sliderStyle = {
        track: {
            backgroundColor: "#CFD3D7"
        },
        active: {
            backgroundColor: "transparent",
            borderRadius: "1em"
        },
        thumb: {
            width: 20,
            height: 20,
            opacity: 1
        }
    }

    // HOOKS
    useEffect(() => {
        setVisualDefect2(undefined);
    }, [visualDefect1])

    useEffect(() => {
        setLensSlider(5);
    }, [isLensEnabled])

    useEffect(() => {
        setItem(undefined);
    }, [isItemEnabled])

    // METHODS
    const getVisualDefect2Options = () => {
        const key = visualDefect1 ? visualDefect1 : t('education_edu_none_new');
        const options = controlOptions.visualDefect2[key as 'myopia'];
        return options;
    }

    const toggleItem = (value: boolean) => {
        dispatch(educationSlice.actions.toggleItemControl());
    }

    const toggleLens = (value: boolean) => {
        dispatch(educationSlice.actions.toggleLensControl());
    }

    const setEnvironment = (env: string) => {
        dispatch(educationSlice.actions.setEnvironment(env as EnvironmentType))
    }

    return (
        <div className={B(!isOpen ? 'collapsed' : '')}>
            
            <div className={E('control')}>
                <label>{t('education_edu_visual_defect_1')}</label>

                <Dropdown 
                    onSelect={(value) => setVisualDefect1(value)}
                    value={visualDefect1}
                    options={controlOptions.visualDefect1}
                />

            </div>
            <div className={E('control')}>
                <label>{t('education_edu_visual_defect_2')}</label>

                <Dropdown 
                    onSelect={ v => setVisualDefect2(v)}
                    value={visualDefect2}
                    options={getVisualDefect2Options()}/>
            </div>
            <div className={E('control')}>
                <label className={!isItemEnabled ? 'temp-no-padding-bottom' : ''}>
                    {t('education_edu_item')} 
                    <ToggleRadio on={isItemEnabled} onChange={ v => toggleItem(v)}/>

                </label>
                
                {
                    isItemEnabled  &&
                    <Dropdown 
                        value={item} 
                        onSelect={ v => setItem(v)}
                        options={controlOptions.item}/>
                }

            </div>
            <div className={E('control')}>
                <label className={!isLensEnabled ? 'temp-no-padding-bottom' : ''}>
                    {t('education_edu_lens')} 
                    <ToggleRadio on={isLensEnabled} onChange={ v => toggleLens(v)}/>
                </label>
                {
                    isLensEnabled && 
                    <div className={E('control-lens-slider')}>
                        <label>Puissance correction</label>
                        <div>
                            <span>-10</span>
                                <Slider
                                    axis="x"
                                    xstep={1}
                                    xmin={0}
                                    xmax={10}
                                    x={lensSlider}
                                    styles={sliderStyle}
                                    onChange={({x}) => setLensSlider(x)}
                                />
                            <span>+10</span>
                        </div>
                    </div>    
                }

            </div>
            <div className={E('control')}>
                <label>{t('education_edu_environment_new')} </label>

                <ToggleButton 
                    value={environment}
                    onChange={ v => setEnvironment(v) }
                    options={controlOptions.environment}/>
            </div>
            <div className={E('control')}>
                <label>{t('education_edu_environment_new')}</label>

                <ToggleButton 
                    value={screenMode}
                    onChange={ v => setScreenMode(v)}
                    options={controlOptions.screenMode}/>
            </div>

        </div>
    )
}

export default EducationControls;