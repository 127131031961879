import measuresValidations from "../constants/measures-validation.constants"

export const validateMeasures = (
    number: number,
    value: any, 
    key: string,
) => {
    const validations = measuresValidations.filter((i:any) => { return i.key === key } )
    const validation = validations[0]

    if(validation){
        if(
            (value >= validation.min && value <= validation.max && value !== '') || 
            (value === '0' && validation.allowZero)
        ){
            
            return {
                isError: false,
                // errorMsg: `Value should be ${validation.min} - ${validation.max}`
                errorMsg: `message_-_measures_${number}_mess_value_should_be_${validation.min}_-_${validation.max}`
            }
        }else{
            return {
                isError: true,
                // errorMsg: `Value should be ${validation.min} - ${validation.max}`
                errorMsg: `message_-_measures_${number}_mess_value_should_be_${validation.min}_-_${validation.max}`
            }
        }
    }
}