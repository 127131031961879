import React from 'react';
import REGIONS from '../constants/regions.constants';
import iRegion from '../interface/region.interface';
import useBEM from '../utils/hooks/useBEM';


interface Props {
    currentRegion?: iRegion,
    items: iRegion[],
    onSelect: (region: iRegion) => void
}

const RegionSelection : React.FC<Props>  = ({
    currentRegion,
    items,
    onSelect
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('region-selection');
    const images = REGIONS.images;

    // METHODS
    const isSelected = (id: string) => {
        return id === currentRegion?.id ? ['selected'] : [];
    }

    const onSelectHandler = (region: iRegion) => {
        if(region.id === currentRegion?.id)return;
        onSelect(region)
    }

    return (
        <div className={B()}>
            
            {
                items.map( i => (
                    <div key={i.id} className={E('region', isSelected(i.id))} onClick={() => onSelectHandler(i)}>
                        <label className={E('region-label')}>
                            {i.label}
                        </label>

                        <span className={E('region-radio', isSelected(i.id))}></span>

                        <img className={E('image')} src={images[i.id.toLocaleLowerCase()]} alt="" />
                    </div>
                ))
            }
            
            

        </div>
    )
}

export default RegionSelection;