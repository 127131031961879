import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iMenu from '../../interface/menu.interface';
import iRootState from '../interface/root-state.interface';
import iSidebarStore from '../interface/sidebar-store.interface';


const initialState: iSidebarStore  = {
    open: false,
}

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        open: (state) => ({...state, open: true, selectedMenu: undefined}) ,
        close: (state) => ({...state, open: false }),
        selectMenu: (state, action: PayloadAction<{
            menu: iMenu | undefined,
            callerId?: string
        }>) => ({
            ...state,
            selectedMenu: action.payload.menu,
            callerId: action.payload.callerId
        }),
    }
});

export const getSidebar = (state:iRootState) => state.sidebar;
export default sidebarSlice;