import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Accordion from '../../../components/ui/Accordion';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';


interface SelectionType {
    label: string,
    value?: string,
    subSelection?: SelectionType[],
}

interface Props {
    show: boolean,
    onClose?: () => void,
    options?: SelectionType[],
    onSelect?: (value: {
        label: string,
        value: string
    }) => void

}

const AdvanceSettingFilterFieldSelection : React.FC<Props>  = ({
    show,
    options = [],
    onClose,
    onSelect
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('advance-setting-filter-field-selection');
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const onCloseModal = () => {
        if(onClose)onClose()
    }

    const onSelectHandler = (opt: SelectionType) => {
        if(opt.subSelection && opt.subSelection.length > 0 || !onSelect)return;
        onSelect({
            label: opt.label,
            value: opt.value as string
        })
    }

    const renderOptions = (ops: SelectionType[]) => {
        return ops.map( x => (
            <Accordion 
                key={x.value}
                title={x.label} clicked={() => onSelectHandler(x)}>
                {
                    x.subSelection && 
                    x.subSelection.length > 0 && 
                    renderOptions(x.subSelection)
                }
            </Accordion>
        ))
    }

    return (
        <div className={B()}>
            <Modal                 
                show={show} 
                onClose={() => {onCloseModal()}} 
                height="51rem">

                <h3 className={E('title')}>
                    {t('advancedpricing_choose_product')}
                </h3>

                <div className={E('list')}>
                    {
                        renderOptions(options)
                    }
                </div>
            </Modal>
        </div>
    )
}

export default AdvanceSettingFilterFieldSelection;