import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListMenu from '../../../components/ui/ListMenu';
import { categories } from '../../../constants/my-companion.constants';
import iListMenuItem from '../../../interface/list-menu-item.interface';
import { getApp } from '../../../store/slices/app.slice';
import myCompanionSlice, { getMyCompanion } from '../../../store/slices/mycompanion.slice';

interface Props {

}

const MyCompanionLeft : React.FC<Props>  = () =>  {
    // VARIABLES
    const [menuItem, selectMenuItem] = useState<iListMenuItem>();
    const dispatch = useDispatch();
    const { firstLogin, accountDetails } = useSelector(getApp);
    
    const { 
        isPriceOff,  
        essilorRecommend,
    } = useSelector(getMyCompanion);

    // HOOKS
    useEffect(() => {
        // Note: temp
        onSelectMenu({
            value: 'correct',
            label: 'Correct',
            icon: 'correct-lens'
        });
    },[])


    // METHODS
    const onSelectMenu = (menu: iListMenuItem) => {
        dispatch(myCompanionSlice.actions.selectCategory(menu.value))
        selectMenuItem(menu);        
    }

    const isLastCategory = (index: number) => {
        return categories.length === index + 1;
    }
    const groupCategory = () =>{
        if(firstLogin?.view === 'companion' && firstLogin?.isOpen) return 0
        
        return categories.length-1
    }  

    const displayAdvance = (list:any) =>{
        if(!isPriceOff && essilorRecommend && accountDetails?.advance_pricing) return list
        const temp = [list[0], list[1].slice(0,-1)];
        return temp
    }

    return (
        <div className="my-companion-left">
            
            {
                displayAdvance(categories).map((group:any, index:number) => (
                    groupCategory() >= index && (
                        <Fragment key={index}>
                            <ListMenu 
                                value={menuItem}
                                onSelect={onSelectMenu}
                                items={group}/>

                            {
                                !isLastCategory(index) && groupCategory() !== 0 &&
                                <div className="devider"></div>
                            }

                        </Fragment>  
                    )
                    
                ))
            }             

        </div>
    )
}

export default MyCompanionLeft;