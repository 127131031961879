import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import { LENS_FAMILY_DESCRIPTION } from '../../../constants/discovery.constants';
import { getApp } from '../../../store/slices/app.slice';
import { getDiscovery } from '../../../store/slices/discovery.slice';
import LensFamilyType from '../../../types/lens-family.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Utils from '../../../utils/Utils';

interface Props {
    onClose: () => void
}

const DiscoveryLensInfo : React.FC<Props>  = ({
    onClose
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('discovery-lens-info');
    const { selectedLens } = useSelector(getDiscovery);
    const {cloneObject} = Utils();
    const lensFamilyDescription = (LENS_FAMILY_DESCRIPTION);
    const [ lens, setLens ] = useState<{
        title: string,
        description: string
    }>();
    const { t } = useTranslation(useSelector(getApp));


    // HOOKS
    useEffect(() => {
        if(selectedLens){
            const lensFamily = cloneObject(lensFamilyDescription);
            setLens(lensFamily[selectedLens as LensFamilyType]);
        }
    }, [selectedLens])

    // METHODS
    const close = () => {
        onClose();
    }

    return (
        <div className={B( selectedLens && selectedLens.length > 0 ? 'show' : '')}>
            
            <Button text={t('discovery_essilor_de_close')} type="simple" click={close}/>

            <div className={E('description')}>
                {/* TODO: put this data on constants */}
                <strong>{t(lens?.title || '')}</strong>                
                <p>{t(lens?.description || '')}</p>
            </div>

        </div>
    )
}

export default DiscoveryLensInfo;