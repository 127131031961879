import React from 'react'
import { useSelector } from 'react-redux'
import NumberSlider from '../../../../components/ui/NumberSlider'
import Icon from '../../../../components/ui/Icon'
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface'
import { getApp } from '../../../../store/slices/app.slice'
import useBEM from '../../../../utils/hooks/useBEM'
import useTranslation from '../../../../utils/hooks/useTranslation'
import Utils from '../../../../utils/Utils'
import Islayder from '../../../../components/ui/Islayder'

interface Props {
  question: iNeedExplorerQuestion,
  onAnswer?: (answers: number[]) => void
}

const ChoiceBoxImageDuration : React.FC<Props> = ({
  question,
  onAnswer
}) => {
  const [B, E] = useBEM('choice-box-image-duration')
  const { t } =  useTranslation(useSelector(getApp));
  const { cloneObject } = Utils()

  const onChange = (data: any, index: number) => {
    const newAnswers = cloneObject(question.answer) as number[]
    newAnswers[index] = data;
    if(onAnswer) onAnswer(newAnswers);
  }

  const durationValue = (index: number) => (question.answer as number[])[index]

  return (
    <div className={B()}>
      {
        question.durations?.map((duration, key) => (
          <div className={E('box')}>
            <Icon
               type='image'
               name={duration.image || ''}
               className='box-bg'
               background={true}
            />
            <div className='label-group'>
              <label>
                {t(duration.label)}
              </label>
              <Islayder
                minValue={duration.minValue}
                min={duration.min}
                max={duration.max}
                value={durationValue(key)}
                onChange={(data) => onChange(data, key)}
                withIndex={true}
              />
              <div className={E('minmax')}>
                  <span>{duration.min}h</span>
                  <span>{duration.max}h</span>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default ChoiceBoxImageDuration
