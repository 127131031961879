import React, { useEffect, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import StartFrom from './StartFrom';
import AgeCategory from './AgeCategory';
import Icon from '../../../components/ui/Icon';

interface Props {
    isSelectAge: boolean,
    onClose: () => void,
    onSelect: (id: string) => void
}

const HomeStart : React.FC<Props>  = ({
    isSelectAge = false,
    onClose,
    onSelect
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('home-start');
    const [isShowAge, setIsShowAge] = useState(isSelectAge);

    // HOOKS
    useEffect(() => {
        setIsShowAge(isSelectAge);
    }, [isSelectAge]);

    // METHODS
    const closeAge = () => {
        setIsShowAge(false);
        onClose();
    }

    return (
        <div className={B(isShowAge ? 'showage' : '')}>
            
            <Icon click={closeAge} name="close-white" className={E('close')}/>
            <StartFrom onSelect={id => onSelect(id)} isVisible={!isShowAge}/>
            <AgeCategory isVisible={isShowAge}/>

        </div>
    )
}

export default HomeStart;