import React, { useEffect, useState } from 'react';
import Icon from '../../components/ui/Icon';
import useBEM from '../../utils/hooks/useBEM';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../utils/hooks/useTranslation';
import appSlice, { getApp } from '../../store/slices/app.slice';
import EmatSlider from './components/EmatSlider';
import Button from '../../components/ui/Button';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import Modal from '../../components/Modal';
import ENDPOINTS from '../../constants/endpoints';
import moment from 'moment';
import ematDetails from '../../interface/emat-details';

interface Props {

}

const Emat : React.FC<Props>  = () =>  {
    // VARIABLES

    const fileSort = [
        { label: 'home_hom_all', sortKey: 'all', selected: true},
        { label: 'home_hom_photos', sortKey: 'image', selected: false},
        { label: 'home_hom_video', sortKey: 'video', selected: false},
        { label: 'home_hom_pdf' , sortKey: 'pdf', selected: false},
    ];

    const modalStyle = {
        width: "95.4rem",
        height: "69.8rem",
        borderRadius: "15px"
    }
    const [Bfs, Efs] = useBEM('file-sort');
    const [Bfc, Efc] = useBEM('file-container');
    const [B, E] = useBEM('emat-model');
    const { t } = useTranslation(useSelector(getApp));
    const [sortKey, setSortKey] = useState('all');
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([] as any);
    const dispatch = useDispatch();
    const { ematModal } = useSelector(getApp);
    const { token } = useSelector(getApp);    
    const currUrl = window.location.pathname.split("/").pop();
    // HOOKS
    // METHODS
    
    const onClickSort = (value:string) =>{
        setSortKey(value)
    }
    const modalOnClose = () => {
        dispatch(appSlice.actions.setEmatModal({open: false}));
    }
    useEffect(() => {
        fetchMore();
      }, []);


    const fetchMore = () =>{
        const axiosConfig =  { headers: {"Authorization" : `Bearer ${token}`} }
        // const axiosConfig =  { headers: {"Authorization" : `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFc3NpbG9yIENvbXBhbmlvbiIsImF1ZCI6IiIsImF2YWlsYWJsZV9kYXRlIjoiMjAyMS0wNi0wNCIsImlzcyI6InVybjpcL1wvYXBpZ2VlLWVkZ2UtZXNzaWxvci1KV1QiLCJtYWNoaW5lX2lkIjoiZDUyMGM3YTgtNDIxYi00NTYzLWI5NTUtZjVhYmM1NmI5N2VjIiwic3RhdGUiOiI4IiwiZXhwaXJhdGlvbl9kYXRlIjoiMjAyMS0wNy0wNCIsImlhdCI6MTYyMzI5NTk1MywianRpIjoiZmVjYTAwYmYtMjEyMy00MWU5LWE1MDctMTVkYTgxZmE3YTAyIiwibGljZW5zZV9udW1iZXIiOiJURVNULVczTUEtSk1BTS0xNU1MTSJ9.DmF_8MEBvCUeFmJCaA2pageG-NHrd_wt7sOf7XtHAN60Hbp2FK-09H3amNcyh3nxLAu7HgZzRDchXi-IBnzTvkE5hbDck72l3up1F__dY828hEFeYBdrQuhvu1xL9S48AJym_z_xxShEja32ZxoVZekLTSyOdfxBGy6U4yQjQmOsN-xk8R9rPI2u5eXUdgie59Vsni3AQFHdKkQDeVWict8yTwdZNVpzkzxn2uu6rZAiHMriBKStTpRRDIkIP209Z9POIdHf-kzh28JEgFLbuXcLwhIdA1hMzvVErISrysCDtqyxi9Gog4o9Z-KwKOkI6G7ZArk81H5ozGxWy4ETww`} }
        const url = currUrl === 'emat' ?
        `${ENDPOINTS.emats}?page=${page}` : 
        `${ENDPOINTS.whatsnew}?page=${page}`
        setPage(page+1)
        axios
      .get(
        url, axiosConfig)
      .then(res => {
        res.data.sort(function(a:any,b:any){
            return moment(b.created_date,"YYYY-MM-DD").date() - moment(a.created_date,"YYYY-MM-DD").date()
        })
        setItems([...items, ...res.data]);
        const today = new Date();
        
        if(page===1 && currUrl === 'emat')
            dispatch(appSlice.actions.seteMatList({today: today, load: res.data}));
        if(page===1 && currUrl !== 'emat')
            dispatch(appSlice.actions.setNewList({today:today, load: res.data}));
      }).catch(onError);
    }
    const onError = (error: any) => {
    }
    const groupBy = (data: any[]) =>{
        
        if(sortKey === 'all')  return data;
        if (sortKey === 'pdf') {
            return data.filter((
                filter) => 
                filter.label.toLowerCase() === sortKey ||  filter.label.toLowerCase() === 'document')
        }
        if(sortKey === 'image'){
            return data.filter((
                filter) => 
                filter.label.toLowerCase() === sortKey ||  filter.type.toLowerCase() === 'jpg'
                ||  filter.type.toLowerCase() === 'png')
        }
        const newData = data.filter((
            filter) => 
            filter.label.toLowerCase() === sortKey )
        return newData;
    }

    const onTranslate = (word:string, file:string) =>{
        switch(word.toLowerCase()){
            case 'pdf':
            case 'document':
                return t('home_hom_pdf');
            case 'video':
                return t('home_hom_video');
            case 'photos':
            case 'image':
                return t('home_hom_photos');
            default:{
                if(file.toLowerCase() === 'jpg' || file.toLowerCase() === 'png') return t('home_hom_photos');
                else return word;
            }
                
        }
    }
    const renderFileSort = () => {
        let sort = fileSort.map((sort,key) => {
            return (
                <Button key={key} text={t(sort.label)} iconPosition="left"
                click={()=>onClickSort(sort.sortKey)} 
                icon={returnImage(sort.sortKey, sortKey===sort.sortKey)}
                className={'sort-btn '+(sortKey===sort.sortKey ? 'selected' : '')}
                icontype="png"
                type="secondary"></Button>
            )
        })

        return sort
    }
    const openModal = (type: string, file: ematDetails) =>{
        dispatch(appSlice.actions.setEmatModal({open: true, detail: file}));    
    }


    const returnImage = (value: string, condition: boolean = false, file:string="") =>{
        switch(value.toLowerCase()){
            case 'pdf':
            case 'document':
                if(condition) return 'document-white'
                return 'document';
            case 'video':
                if(condition) return 'play-white'
                return 'play';
            case 'image':
                if(condition) return 'camera-white'
                return 'camera';
            default:
                if(file.toLowerCase() === 'jpg' || file.toLowerCase() === 'png') return 'camera';
                return value
        }
    }
    const renderFiles = () => {
        return (
            
                groupBy(items).map((file,key) => {
                        return <li key={key} className={Efc('item')}>
                            <Icon name={returnImage(file.label,false,file.type)} type="png" className="icon-file"/>
                            <label className="file-name">{onTranslate(file.label, file.type)} </label>
                            <label className="description">{file.description}</label>
                            <label className="date-created">{moment(file.created_date, "YYYY-MM-DD").format("MM/DD/YYYY").toString()}</label>
                            <Button key={key} text={t('home_hom_smore')} iconPosition="left" icon="see-more"
                            click={()=>openModal(file.type, file)}
                            type="secondary"></Button>
                            </li>
                })
        )

    }

    const onType = () =>{
        let type:string = '';
        if(ematModal.selected?.label){
            switch(ematModal.selected?.label.toLowerCase()){
                case 'pdf':
                case 'document':
                    type = 'document';
                    break;
                case 'video':
                    type = 'video';
                    break;
                case 'image':
                    type = 'image';
                    break;
            
            }
            if (ematModal.selected?.type.toLowerCase() === "jpg" || ematModal.selected?.type.toLowerCase() === "png")
                type = 'image';
                return type;
        }
        return ''

        
    }

    return <div className="emat"  id="scrollableDiv">                  
                <EmatSlider></EmatSlider>
                <div className="emat-container">   
                    <div className={Bfs()}>
                        <ul className={Efs('items')}>
                            {renderFileSort()}
                        </ul>
                    </div>
                    <div className={Bfc()} >
                    <InfiniteScroll  dataLength={items.length} loader={<h4></h4>}        
                    next={fetchMore} hasMore={true} endMessage={<h4>Reached</h4>}
                    style={{width: '85vw'}}
                    scrollableTarget="scrollableDiv" >
                        <ul className={Efc('items')}>
                            {renderFiles()}
                        </ul>
                    </InfiniteScroll>
                    
                    </div>
                </div>
                <Modal show={ematModal.isOpen} style={modalStyle} className={B()} onClose={() => modalOnClose()}>
                    <div className={E('header')}>
                        <Button                             
                            text={t('my_practice_my_back')} 
                            type="secondary" weight="normal"
                            icon="left-arrow-circle-gray" 
                            iconPosition="left" 
                            click={()=>modalOnClose()} 
                        /> 
                    </div>
                    <div className={E(`body ${ematModal.selected?.label}`)}>
                        
                        {
                            onType() === 'document' && (
                                <iframe
                                src={ematModal.selected?.file}>
                                </iframe>
                                
                            )
                            
                        }
                        {
                            onType() === 'video' && (
                                <video controls autoPlay >
                                    <source src={ematModal.selected?.file} type="video/mp4"/>
                                </video>
                                    
                            )
                            
                        }
                        {
                            onType() === 'image' && (
                                <img src={ematModal.selected?.file}></img>
                                
                            )
                            
                        }
                        
                    </div>
                </Modal>
        </div>
}

export default Emat;
