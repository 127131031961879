import isummaryDetails from "../interface/summary.interface";

const summaryData: isummaryDetails[] = [
    {
      id: "correct",
      title: "summary_sum_correct",
      icon: "correct",
      sub: "summary_sum_your_vision",
      products: [
          {
            title: "Varilux X Series",
            descriptions: [
              {
                label: "pair_1_tab_del_2_p1_sharp_vision",
                icon: "sharp-vision",
              },
              {
                label: "Smooth transition near to far",
                icon: "smooth",
              },
              {
                label: "pair_1_tab_del_2_p1_eliminates_off-balance",
                icon: "eliminates",
              },
            ],
          }
      ]
    },
    {
      id: "protect",
      title: "summary_sum_protect",
      icon: "protect",
      sub: "summary_sum_your_eyes",
      products: [
        {
          title: "EyeProtect System",
          descriptions: [
            {
              label: "pair_1_tab_del_3_p1_protection_against_harmful_uv_light",
              icon: "UV",
            },
            {
              label: "Helps protect from harmful blue light",
              icon: "harmful-light",
            },
            {
              label: "Azsthetically clear lenses",
              icon: "clear-lense",
            },
          ],
        },
        {
          title: "EyeProtect System",
          descriptions: [
            {
              label: "pair_1_tab_del_3_p1_protection_against_harmful_uv_light",
              icon: "UV",
            },
            {
              label: "Helps protect from harmful blue light",
              icon: "harmful-light",
            },
            {
              label: "Azsthetically clear lenses",
              icon: "clear-lense",
            },
          ],
        },
      ],
    },
    {
      id: "enhance",
      title: "summary_sum_enhance",
      icon: "enhance",
      sub: "summary_sum_clarity",
      products: [
          {
            title: "Crizal Sapphire 360",
            descriptions: [
              {
                label: "Reduces glare",
                icon: "reduce-glare",
              },
              {
                label: "Durable",
                icon: "durable",
              },
              {
                label: "pair_1_tab_del_2_p1_easy_to_clean",
                icon: "easy-to-clean",
              },
            ],
          }
      ]
    },
  ];

  export default summaryData;