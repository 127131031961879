import React, { useState } from 'react'
// import { useSelector } from 'react-redux';
import { iView } from '../../../store/interface/variluxG9.interface';
// import { getApp } from '../../../store/slices/app.slice';
// import useTranslation from '../../../utils/hooks/useTranslation';
import ConclusionVarilux from './ConclusionVarilux';
import DemonstrationsVarilux from './DemonstrationsVarilux';
import IntroVarilux from './IntroVarilux';
import NeedExplorerVarilux from './NeedExplorerVarilux';
import PrescriptionVarilux2 from './PrescriptionVarilux2';

interface Props {
    step?:iView
}

const VariluxContainer:React.FC<Props> = ({step}) => {
//   const { t } = useTranslation(useSelector(getApp));
      // for gtm id

  const viewPage = (step:iView) => {
    switch (step) {
      case 'intro':
        return <IntroVarilux/>
      case 'needExplorer':
        return <NeedExplorerVarilux />
      case 'prescription':
        return <PrescriptionVarilux2 /> 
      case 'demonstrations':
        return <DemonstrationsVarilux/>
      case 'conclusion':
        return <ConclusionVarilux/>
    
      default:
        break;
    }
  }

  return (
    <>
      {
        step && viewPage(step)
      }
    </>
  )
}

export default VariluxContainer