import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iMeasurementDataOne from '../../interface/measurement-one-data.inteface';
import iMeasurementDataThree from '../../interface/measurement-three-data.inteface';
import iMeasurementDataTwo from '../../interface/measurement-two-data.inteface';
import iMeasureStore from '../interface/measure-store.interface';
import iRootState from '../interface/root-state.interface';


const initialState: iMeasureStore  = {
    view: 'measure',
    step: 0,
    activeDataIndex: 0,
    measurementData: {
        one: [],
        two: [],
        three: []
    },
    isSavePairData: 0,
    isIvsGenerated: false
}

const measureSlice = createSlice({
    name: 'measure',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<'measure' | 'data'>) => ( {...state, view: action.payload} ),
        changeStep: (state, action: PayloadAction<number>) => ( {...state, step: action.payload} ),
        saveMeasureData: (state, action: PayloadAction<{
            data: iMeasurementDataOne | iMeasurementDataTwo | iMeasurementDataThree,
            key: 'one' | 'two' | 'three'
        }>) => {
            const {key, data} = action.payload;

            if(!state.measurementData[key][state.activeDataIndex]) {
                return {
                    ...state,
                    measurementData: {
                        ...state.measurementData,
                        [key]: [
                            ...state.measurementData[key],
                            data as any
                        ]
                    }
                };
            }

            return {
                ...state,
                measurementData: {
                    ...state.measurementData,
                    [key]: [
                        ...state.measurementData[key].map( (x: any,i: number) => {
                            if(i === state.activeDataIndex) {
                                return data;
                            }
                            return x;
                        })
                    ]
                }
            };
        },
        saveFrametraceMeasure: (state, action: PayloadAction<any>) => ({
            ...state, measurementData: action.payload
        }),
        addPair: (state) => ( {...state, activeDataIndex: state.activeDataIndex + 1} ),
        editPair: (state, action: PayloadAction<number>) => ( {...state, activeDataIndex: action.payload} ),
        resetData: (state) => ( {...state, ...initialState} ),

        saveMeasureFromStore: (state, action: PayloadAction<iMeasureStore["measurementData"]>) => ( {
            ...state,
            measurementData: action.payload
        }),
        savePairData: (state, action: PayloadAction<number>) => ({...state, isSavePairData: action.payload}),
        sessionStart: (state, action: PayloadAction<string | undefined>) => ({
            ...state,
            sessionStart: action.payload
        }),
        generateIvs: (state, action:PayloadAction<boolean>) => ({...state, isIvsGenerated:action.payload
        })
    }
});

export const getMeasure = (state:iRootState) => state.measure;
export default measureSlice;
