import React from 'react';
import Arrow from '../../../components/ui/Arrow';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    className: string,
    direction:"up" | "down" | "left" | "right",
    click: () => void
}

const DiscoveryArrows : React.FC<Props>  = ({
    className,
    direction,
    click
}) =>  {
    // VARIABLES
    const [B] = useBEM('discovery-arrows');

    // HOOKS

    // METHODS

    return (
        <div className={B(direction) + ' ' + className} onClick={click}>
            <Arrow type={direction} size=".60rem" color="#fff"/>
            <Arrow type={direction} size=".60rem" color="#fff"/>
            <Arrow type={direction} size=".60rem" color="#fff"/>
        </div>
    )
}

export default DiscoveryArrows;