import React from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import NeedExplorerCategory from './NeedExplorerCategory';
import NeedExplorerQuestionPanel from './NeedExplorerQuestionPanel';

interface Props {

}

const NeedExplorerQuestionaires : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B] = useBEM('need-explorer-questionaires');
    // HOOKS

    // METHODS

    return (
        <div className={B()}>

            <NeedExplorerCategory/>
            <NeedExplorerQuestionPanel/>

        </div>
    )
}

export default NeedExplorerQuestionaires;
