import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import neddExplorerIntro from '../../../constants/need-explorer-intro.contants';
import NeedExplorerViewType from '../../../types/need-explorer-view.type';
import needExplorerSlice from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';

interface Props {

}

const NeedExplorerIntro : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-intro');
    const [ step, setStep ] = useState(0);
    const intro = neddExplorerIntro;
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation(useSelector(getApp));
    // HOOKS
    useEffect(() => {
        dispatch(needExplorerSlice.actions.generalQuestionSessionStart(new Date().toISOString()));
    }, [])

    // METHODS
    const isLastStep = () => {
        return intro.length === (step + 1)
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    const backStep = () => {
        setStep(step - 1);
    }

    const accept = () => {
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }

    const decline = () => {
        history.push('/lens-proposal-builder')
    }

    return (
        <div className={B()}>

            <div className={E('modal')}>

                <div className={E('step')}>

                    <label className={E('step-progress')}>

                        { step > 0 && <Icon name="left-arrow-circle-gray" click={backStep}/> }

                        { step + 1} / {intro.length}
                    </label>

                    <h2>
                        {t(intro[step].title)}
                    </h2>
                    <p className={E('step-description')}>
                        {t(intro[step].description)}
                    </p>

                    <div className={`${E('step-buttons')} ${!isLastStep() ? '-row' : ''}`}>
                        {
                            !isLastStep() ?
                                <Button
                                    text={t('personalize_your_lenses_pyl_next')}
                                    icon="right-arrow-circle-white"
                                    click={nextStep}
                                /> : <>
                                    <Button
                                        text={t('legal_notices_ln_accept')}
                                        icon="saved-white"
                                        click={accept}
                                        icontype='svg'
                                    />
                                    <Button
                                        text={t('legal_notices_ln_decline')}
                                        type="secondary"
                                        icon="decline"
                                        icontype="png"
                                        click={decline}
                                    />
                                </>
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default NeedExplorerIntro;
