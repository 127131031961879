import React, { useEffect, useState } from 'react';
import sidebarSlice, { getSidebar } from '../../store/slices/sidebar.slice';
import { useSelector, useDispatch } from 'react-redux';
import useBEM from '../../utils/hooks/useBEM';
import Modal from '../Modal';
import EducationLanding from './components/EducationLanding';
import EducationControls from './components/EducationControls';
import educationSlice, { getEducation } from '../../store/slices/education.slice';
import Button from '../ui/Button';
import iOption from '../../interface/option.interface';
import EducationContent from './components/EducationContent';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import Utils from '../../utils/Utils';
import useTranslation from '../../utils/hooks/useTranslation';
import { getApp } from '../../store/slices/app.slice';

interface Props {

}

const Education : React.FC<Props>  = () =>  {
    // VARIABLES
    const { selectedMenu } = useSelector(getSidebar);
    const { view, sessionStart } = useSelector(getEducation);
    const { isControlsOpen } = useSelector(getEducation);
    const dispatch = useDispatch();
    const ga = useGoogleAnalytics();
    const { getDateTimeDifference } = Utils();
    const { t } = useTranslation(useSelector(getApp));

    const [show, setShow] = useState(false);
    const [B, E] = useBEM('education');
    const modalStyle = {
        width: "95.4rem",
        height: "69.8rem",
        borderRadius: "15px"
    }

    // HOOKS
    useEffect(() => {
        if(selectedMenu?.id !== 'education')return;
        setShow(true);
    }, [selectedMenu])

    // METHODS
    const onSelect = (view: iOption) => {
        dispatch(educationSlice.actions.changeView(view))
    }
    
    const backOnLanding = () => {
        saveEducationSessionDuration();
        dispatch(educationSlice.actions.resetState())
    }

    const saveEducationSessionDuration = () => {
        if(sessionStart){
            ga.event({
                category: 'Education',
                action: getDateTimeDifference(new Date(sessionStart), new Date()),
                label: t(view.label, 'enUS')
            });
        }
    }

    const modalOnClose = () => {
        if(view.value !== "landing"){
            saveEducationSessionDuration();
        }

        setShow(false);
        dispatch(educationSlice.actions.resetState());
        dispatch(sidebarSlice.actions.selectMenu({menu: undefined}))
    }

    return (
        <Modal 
            noPadding={true}
            show={show} style={modalStyle} onClose={modalOnClose}>
            
            <div className={B()} >
                <div className={E('header')}>
                    
                    {
                        view.value !== 'landing' && 
                        <Button 
                            outlineType="light"
                            text={t('education_edu_back')} 
                            icon="left-arrow-circle-gray" 
                            iconPosition="left" type="secondary" 
                            click={backOnLanding}
                        />
                    }
                    

                    <h4 className={`${view.value}`}>{t(view.label)}</h4>
                </div>

                <div className={E('main')}>
                    { view.value === 'landing' &&  <EducationLanding onSelect={v => onSelect(v)}/> }
                    { view.value === 'eye' &&  
                        <>
                            <EducationControls isOpen={isControlsOpen}/> 
                            <EducationContent/>  
                        </>  
                    }                    
                </div>

            </div>
        </Modal>
    )
}

export default Education;