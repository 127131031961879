import { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import Button from "../../../components/ui/Button";
import Champ from "../../../components/ui/Champ"
import Dialogue from "../../../components/ui/Dialogue";
import Icon from "../../../components/ui/Icon";
import { getApp } from "../../../store/slices/app.slice";
import variluxgG9Slice, { getVariluxG9 } from "../../../store/slices/variluxG9.slice";
import useBEM from "../../../utils/hooks/useBEM"
import useTranslation from "../../../utils/hooks/useTranslation";
import useVariluxDisableButton from "../../../utils/hooks/useVariluxDisableButton";

interface Props {
    
}

const NeedExplorerVarilux: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { tg9 } = useTranslation(useSelector(getApp));
  const [B,E] = useBEM('varilux-needExplorer')
  const { needExplorerChoices, view } = useSelector(getVariluxG9); 

  //hooks
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const {disableNext} = useVariluxDisableButton()

  //functions
  const selectChoice = (id:number) => {
    dispatch(variluxgG9Slice.actions.selectChoice(id))
  }

  const nextStep = () => {
    dispatch(variluxgG9Slice.actions.changeView('prescription'))
  }

  return (

    <div className={B()}>
      <Dialogue className={E('quiz-dialogue')}>
        <div className={E('quiz-header')}>
          <span className={E('quiz-question')}>
            {tg9('select_visual__prog_lens','Select the visual discomforts experienced by your patient with their current progressive lenses')}
          </span>
          <span className={E('quiz-question-type')}>
            {tg9('need_explorer_multiple_choice_question','Multiple choice question')}
          </span>
        </div>
        {/* choices */}
        <div className={E('choices')}>
          {
            needExplorerChoices.map((choice) => (
              <Champ 
                gtmId={choice.gtmId}
                text={tg9(choice.label[0], choice.label[1])} 
                key={choice.id} 
                selected={choice.selected}
                click={() => selectChoice(choice.id)}
                />
            ))
          }
        </div>

        <Button 
          text={tg9('personalize_your_lenses_pyl_next','Next')}
          icon="right-arrow-circle-purple"
          className={E('next-button')}
          outlined={false}
          type='simple'
          // click={() => setShowWarning(true)}
          click={nextStep}
          disabled={disableNext(view)}
          id='varilux_need_explorer_next_button'
        />
      </Dialogue>

      {/*warning modal */}
          {/* <Modal show={showWarning} 
            onClose={()=> setShowWarning(false)} 
            className={E('warning-container')}
            closeButtonColor='#6E51EB'>

            <div className={E('warning')}>
              <div className={E('warning-icon')}>
                <Icon name='warning'/>
              </div>
              <div className={E('warning-text')}>
                <span>
                  {tg9('need_explorer_warning','Warning')}
                </span>
                <p>
                  {tg9('need_explorer_warning_modification', 'Any modification will influence the next steps')}
                </p>
              </div>
            </div>

            <div className={E('warning-buttons')}>
              <Button 
                text={tg9('need_explorer_continue', 'Continue')}
                className={E('next-button','warning')}
                outlined={false}
                type='simple'
                click={nextStep}
                id='varilux_need_explorer_continue_button'
              />
              <span 
                id="varilux_need_explorer_back_button" 
                className={E('warning-buttons-gradient')} 
                onClick={()=>setShowWarning(false)}
               >
                {tg9('need_explorer_back', 'Back to Need Explorer')}
              </span>
            </div>
          </Modal> */}
    </div>
  )
}

export default NeedExplorerVarilux