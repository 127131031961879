import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iOption from '../../interface/option.interface';
import EnvironmentType from '../../types/environment.type';
import iEducationStore from '../interface/education-store.interface';
import iRootState from '../interface/root-state.interface';


const initialState: iEducationStore  = {
    view: {
        value: 'landing',
        label: 'my_practice_my_educ'
    },
    isControlsOpen: true,
    isItemEnabled: false,
    isLensEnabled: false,
    environment: undefined
}

const educationSlice = createSlice({
    name: 'education',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<iOption>) => ({
            ...state, 
            view: action.payload,
            isControlsOpen: true
        }),
        toggleControls: (state) => ({...state, isControlsOpen: !state.isControlsOpen}),
        toggleItemControl: (state) => ({...state, isItemEnabled: !state.isItemEnabled}),
        toggleLensControl: (state) => ({...state, isLensEnabled: !state.isLensEnabled}),
        resetState: (state) => ({...state, ...initialState}),
        setEnvironment: (state, action: PayloadAction<EnvironmentType>) => ({
            ...state, 
            environment: action.payload
        }),
        sessionStart: (state, action: PayloadAction<string | undefined>) => ({
            ...state, 
            sessionStart: action.payload
        }),
    }
});

export const getEducation = (state:iRootState) => state.education;
export default educationSlice;