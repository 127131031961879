import React, { createRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import EmatCard from './EmatCard';
interface Props {
    click?: () => void,
}

const EmatSlider : React.FC<Props>  = (
    click,
) =>  {
    // VARIABLES
    const [positionSlider, setPositionSlider] = useState('');
    const [itemsLength, setItemsLength] = useState(0);
    const {recentNew, recentEmat} = useSelector(getApp);
    const [step, setStep] = useState(0);
    const [maxHeight, setMaxHeight] = useState(false);
    const [minHeight, setMinHeight] = useState(false);
    const sliderStep = 50;
    const myRef = React.createRef<any>();
    
    const [B, E] = useBEM('emat-slider');
    const { t } = useTranslation(useSelector(getApp));
    const currUrl = window.location.pathname.split("/").pop();
    
    // HOOKS
    // METHODS

    useEffect(() => {
        if(currUrl === 'emat') setItemsLength(getLength(recentEmat));
        else setItemsLength(getLength(recentNew));
      }, [recentNew, recentEmat]);
    const onClickNext = (position: string) => {
        let tempCount = step;
        let slider = sliderStep;
        if(step<=1) {
            tempCount = step;
        }
        if(step>(itemsLength)) {
            tempCount =itemsLength;
        }
        if(position === 'prev'){
            slider = slider-(sliderStep*tempCount)            
            tempCount--;
        }else{
            
            tempCount++;
            slider = -(sliderStep*tempCount)
            
        }
        setStep(tempCount)
        setPositionSlider(`translateX(${slider}rem)`);
    }
    const getLength = (list:any = []) =>{
        let newList:any = [];
        list.map((ls:any)=>{
          if(ls !== 0) newList.push(ls)
        })
        return newList.length-2;
    }
    const onUndefined = () =>{
        let arr: any [] = [];
        if(currUrl === 'emat'){
            recentEmat.map((ls:any)=>{
                if(ls === 0) arr.push(false);
                else arr.push(true);
            })
        }else{
            recentNew.map((ls:any)=>{
                if(ls === 0) arr.push(false);
                else arr.push(true);
            })
        }
        const cond = arr.includes(true) ? true : false;
        return cond ;
    }

    const returnList = () =>{
        if(currUrl === 'emat') return recentEmat;
        else return recentNew;
    }

    const maxHeightScroll = () =>{
        const { scrollLeft, scrollWidth, clientWidth } = myRef.current;
        if (Math.round(scrollLeft + clientWidth) === scrollWidth) {
            // TO SOMETHING HERE
            setMaxHeight(true);
            setMinHeight(false);
        }
        // else if(Math.round(scrollLeft + clientWidth) !== scrollWidth){
        //     setMaxHeight(false);
        //     setMaxHeight(false);
        // }
        if(Math.round(scrollLeft + clientWidth) === 919){
            setMinHeight(true);
            setMaxHeight(false);
        }
        // else if (Math.round(scrollLeft + clientWidth) > 919){
        //     setMaxHeight(false);
        //     setMaxHeight(false);
        // }
    }
    
    const onNextScroll = (position:string) =>{
        let tempCount = step;
        let slider = sliderStep;
        
        if(step<=1) {
            tempCount = step;
        }
        if(step>(itemsLength)) {
            tempCount =itemsLength;
        }
        if(position === 'prev'){                    
            myRef.current.scrollLeft -= slider*15;  
            slider = (sliderStep*tempCount)                          
            tempCount--;
        }else{
            
            tempCount++;
            slider = (sliderStep*tempCount);
            myRef.current.scrollLeft += slider*15;
            
        }
        
        setStep(tempCount)
        
    }

    return <>
    {
         onUndefined() && (
            <div className={B()}>
                <div className={E('header')}>
                    <h2>{t('home_hom_rfiles')}</h2>
                    <div className="arrows">
                        <Icon 
                            className={step === 0  ? 'disabled' : ''}
                            // name="right-arrow-circle" click={()=>onClickNext('prev')}/>
                            name="right-arrow-circle" click={()=>onNextScroll('prev')}/>
                        <Icon 
                            className={step >= (itemsLength-2) ? 'disabled': ''}
                            // name="right-arrow-circle" click={()=>onClickNext('next')}/>
                            name="right-arrow-circle" click={()=>onNextScroll('next')}/>

                    </div>
                </div>
                <div className={E('slider-body')} 
                ref={myRef}
                // style={{transform: positionSlider}} 
                onScroll={maxHeightScroll}
                >
                    {
                        returnList()?.map((list:any, key:number) =>{
                                return list !== 0 && (
                                    <EmatCard data={list} key={key}></EmatCard>
                                )               
                        })
                    }
                </div>
                
            </div>
        )
    }
    
    </>
}

export default EmatSlider;