import React, { useLayoutEffect, useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import appSlice, { getApp } from '../../store/slices/app.slice'
import useBEM from '../../utils/hooks/useBEM'
import VarNavbar from './components/VarNavbar'
import background1 from '../.././assets/png/variluxG9-bg-4.png'
import background2 from '../.././assets/png/variluxG9-bg-2.png'
import background3 from '../.././assets/png/variluxG9-bg-3.png'
import VariluxContainer from './components/VariluxContainer'
import variluxgG9Slice, { getVariluxG9 } from '../../store/slices/variluxG9.slice'
import useVariluxBackground from '../../utils/hooks/useVariluxBackground'
import DownloadVariluxAssets from './components/DownloadVariluxAssets'
import Modal from '../../components/Modal'
import Icon from '../../components/ui/Icon'
import useTranslation from '../../utils/hooks/useTranslation'
import Button from '../../components/ui/Button'
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext'
import iG9CountryLanguages from '../../store/interface/g9-country-languages.interface'

interface Props {

}

const Varilux: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const [B, E] = useBEM('varilux')
  const { tg9 } = useTranslation(useSelector(getApp));
  const { view, showDownloadPopup } = useSelector(getVariluxG9);
  const [installPWA, setInstallPWA] = useState<any>(null) 
  const { backgroundStyle } = useVariluxBackground([background1, background2, background3], view)
  const {get, update, insert} = useContext(IndexedDBContext) as IndexedDBContextType
  const { accountDetails } = useSelector(getApp)

  useLayoutEffect(() => {
    dispatch(variluxgG9Slice.actions.changeView('intro'))

    return () => {
      closeDownloadPopup()
    }
  }, [])

  useEffect(() => {
    let country = accountDetails?.shop.country
    if (country === "de") { // START AT 'CURRENT LENSES' PAGE WHEN COUNTRY IS GERMANY
      dispatch(variluxgG9Slice.actions.changeView('prescription'))
    } else {
      dispatch(variluxgG9Slice.actions.changeView('intro'))
    }

    fetchG9Translation()

    const handler = (e: any) => {
      e.preventDefault()
      setInstallPWA(e)
    }
    window.addEventListener('beforeinstallprompt', handler)
    return () => window.removeEventListener("transitionend", handler)
  }, [])
  
  const closeDownloadPopup = () => {
    dispatch(variluxgG9Slice.actions.setShowDownloadPopup(false))
  }

  const handlInstallation = (e: any) => {
    e.preventDefault()
    installPWA?.prompt()
    dispatch(variluxgG9Slice.actions.setShowDownloadPopup(false))
  }

  const fetchG9Translation = async () => {
    try {
      const res = await fetch('https://qua-bo.essilorcompanion.com/api/v1/translationg9')
      const data = await res.json()
      const translationData = await get('languageStore', 'g9translations')
      const todo = translationData ? update : insert
      todo('languageStore', {
        id: 'g9translations',
        value: data.translations
      })

      let sortedCountryLanguages = data.continents.map((continent: iG9CountryLanguages) => {
        return {
          ...continent,
          countries: continent.countries.sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
        }
      });
  
      dispatch(appSlice.actions.setG9CountryLanguages(sortedCountryLanguages))
      dispatch(appSlice.actions.setG9Languages(data.languages))
    } catch (error) {
      return error
    }
  }

  const varilux_version = 'Version: 0.0.14'

  return (
    <>
      <DownloadVariluxAssets />
      <div id={B() + '-' + view} className={B()} style={backgroundStyle}>
        <VarNavbar />
        <VariluxContainer step={view} />
        <span className={E('version')}> {varilux_version} </span>
      </div>

      {/* download modal */}
      {
        <Modal
          show={showDownloadPopup}
          className={E('download-modal')}
          onClose={() => closeDownloadPopup()}
          closeButtonColor='#6E51EB'
        >
          <div className={E('download-container')}>
            <div className={E('download-container-icon')}>
              <Icon name='download-varilux' />
            </div>
            <div className={E('download-container-text')}>
              <span>
                {tg9('varilux_web_app', 'Installation web app')}
              </span>
              <p>
                {tg9('varilux_install_web_app', 'Install the application for a better experience')}
              </p>
            </div>
            <div className={E('install-button')} onClick={handlInstallation}>
              <Button
                text={tg9('varilux_install', 'Install')}
                className={E('next-button', 'install')}
                outlined={false}
                type='simple'                
              />
            </div>
          </div>
        </Modal>
      }

      {/* NOTE: varilux version, Increment the value varilux 
        version everytime you deploy something that is 
        related to varilux G9*/}
    </>
  )
}

export default Varilux