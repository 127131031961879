import React, { useEffect, useRef, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import { useDispatch, useSelector } from 'react-redux';
import productDemoSlice, { getProductDemo } from '../../../store/slices/products-demo.slice';
import ProductDemoType from '../../../types/product-demo.type';
import SeeThrougDemo from './SeeThrougDemo';
import iLensDemo from '../../../interface/lens-demo';
import ReactPannellum from "react-pannellum";
// import fieldOfVision from '../../../assets/images/field-of-vision.jpg';
import fieldOfVision from '../../../assets/images/field-of-vision-min.jpg';
import DrawLine from './DrawLine';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import  { getProducts } from '../../../store/slices/products.slice';
import Icon from '../../../components/ui/Icon'
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import DemoTextOverlay from './DemoTextOverlay';







interface Props {
    isChangeDemoTypeLoading?:boolean
}

const ProductDemo : React.FC<Props>  = ({isChangeDemoTypeLoading}) =>  {
    // VARIABLES
    const  { 
        demoType,  
        subDemoType,
        activeProductDemo,
        activeCompareProductDemo,
        syncVideoPlayFlag,
        isCompareEnabled,
        demoFrom,
        qrPopup
    } = useSelector(getProductDemo);
    const { accountDetails } = useSelector(getApp);
    const dispatch = useDispatch();
    const [B, E] = useBEM('product-demo');
    const videoRef = useRef<any>();
    const [assetSource, setAssetSource] = useState<string | null>(null);
    const [finalDemoType, setFinalDemoType] = useState<iLensDemo>();
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const previousLensDemo = useRef<string>();
    const {isQrCodeOpen, brandToDemo} = useSelector(getProducts)
    const { t } = useTranslation(useSelector(getApp));
    const isSlovakia = accountDetails?.shop.country === 'sk'
    const showTextOverLay = finalDemoType?.demo === 'competitor' && activeProductDemo?.id === 'varilux_physio_30' && !isCompareEnabled

    // HOOKS
    // useEffect(()=>{
    //     if(isQrCodeOpen || isTransitions()) 
    //     dispatch(productDemoSlice.actions.enableCompare(false))
    // }, [])
    useEffect(() => {
        if(demoType && [
            ProductDemoType.NESTED_DEMO_TRANSVERSAL,
            ProductDemoType.NESTED_DEMO
        ].includes(demoType?.demoType)){
            setFinalDemoType(subDemoType || demoType);
            return;     
        }

        setFinalDemoType(demoType)
    }, [subDemoType, demoType])

    useEffect(() => {
        if(isImageDemo() || !videoRef?.current)return;
        if(needVideoPlaySync() && syncVideoPlayFlag >= 2) {
            setTimeout(() => {
                if(videoRef.current === null)return;
                if(videoRef.current.paused)videoRef.current.play();
            }, 1000);
        }
    }, [syncVideoPlayFlag])

    useEffect(() => {
        if(!videoRef.current || isImageDemo())return;
        videoRef.current.pause();
        videoRef.current.currentTime = 0;    
        setTimeout(() => {
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(null));
   
            if(!needVideoPlaySync()) {
                videoRef?.current?.play();    
            }
        });
    }, [assetSource])

    useEffect(() => {        
        if(!finalDemoType || !activeProductDemo)return;
        getAssetSource();        
    }, [activeProductDemo, finalDemoType])

    useEffect(() => {
        if(isCompareEnabled && videoRef.current){
            videoRef.current.pause();
            videoRef.current.currentTime = 0;        
        }
    }, [activeCompareProductDemo]);

    // METHODS

    const needVideoPlaySync = () => {
           if(
            isCompareEnabled ||
            [ ProductDemoType.SEE_THROUGH_IMAGE,
                ProductDemoType.SEE_THROUGH_VIDEO ]
            .includes(finalDemoType?.demoType as ProductDemoType)

        )return true;
        return false;
    }
    // const isTransitions = () =>{
    //     if(brandToDemo && demoFrom?.id === 'lensConsultation' && !isSlovakia)
    //     return brandToDemo.id === 'transitions'
    // }

    const timer = useRef<any>();
    const getAssetSource = () => {
        setAssetSource(null);

        // if(isQrCodeOpen || isTransitions()){
        //     if(timer.current)clearTimeout(timer.current);
        //     timer.current = setTimeout(async () => {
        //         if(!finalDemoType?.demo || !activeProductDemo?.id)return;
        //         const id = `video-advertisement-gen-8_standard1`;
        //         if(previousLensDemo.current === id)return;      
        //         let asset;
        //         let tryLimit = 3;
        //         let tryAgain;
        //         do {
        //             try {
        //                 asset = await get('lensDemoStore', id);
        //                 tryAgain = false;
        //                 console.log(`Success A`);
        //             } catch (error) {
        //                 tryAgain = true;
        //                 tryLimit -= 1;
        //                 console.log(`Try: ${tryLimit}`);
    
        //             }
        //         } while (tryAgain && tryLimit > 0);
        //         console.log(`Success B`);
        //         previousLensDemo.current = id;
        //         console.log(asset)
        //         if(asset)setAssetSource(asset.asset);            
        //     },500)
        //     return
        // }

        if(timer.current)clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
            if(!finalDemoType?.demo || !activeProductDemo?.id)return;
            const id = `${finalDemoType?.demo}_${activeProductDemo?.id}`;
            if(previousLensDemo.current === id)return;      
            const asset = await get('lensDemoStore', id);
            previousLensDemo.current = id;
            if(asset)setAssetSource(asset.asset);            
        },500)
    }

    const isVideoDemo = () => {
        if(!finalDemoType?.demoType || finalDemoType.is360)return false;
        return [
            ProductDemoType.VIDEO,
            ProductDemoType.SLIDER_VIDEO,
            ProductDemoType.VERTICAL_SLIDER_VIDEO,
            ProductDemoType.DIVIDER_VIDEO,
            ProductDemoType.SEE_THROUGH_VIDEO,
            ProductDemoType.SIDE_BY_SIDE_VIDEO,
        ].includes(finalDemoType.demoType)
    }

    const isImageDemo = () => {
        if(!finalDemoType?.demoType || finalDemoType.is360)return false;
        // if(isTransitions())return false
        return [
            ProductDemoType.SLIDER_IMAGE
        ].includes(finalDemoType.demoType)
    }

    const style = {
        width: "100%",
        height: "100%",
        background: 'rgba(255,255,255,0.5)',
    }

    const config = {
        // autoRotate: -2,
        autoLoad: true,
        showZoomCtrl: false,
        showFullscreenCtrl: false,
        doubleClickZoom: false,
        keyboardZoom: false,
        mouseZoom: false,
    };

    const openQrCode = () =>{
            dispatch(productDemoSlice.actions.openQrPopup(!qrPopup));
    }
    return (
        //temporary hide
        // <div className={B( isTransitions() ? 'side-by-side-video' : finalDemoType?.demoType )}>
        <div className={B(finalDemoType?.demoType )}>
            { demoType && demoType.is360 && 
                <ReactPannellum
                    id={"image360"}
                    sceneId={"sceneId"}
                    imageSource={fieldOfVision}
                    config={config}
                    style={style}/>
            }
            
            {/* {ProductDemoType.SPHERE_IMAGE === demoType && 
                <ReactPannellum
                    id={"id"}
                    sceneId={"sceneId"}
                    imageSource={sphereImage}
                    config={config}
                    style={style}/>
            }
            

            { ProductDemoType.DRIVE === demoType && 
                <Icon name="demonstration-car" type="image" background={true} className={E('drive')}/>               
            }

            { ProductDemoType.VIDEO === demoType && 
                <video 
                    className={E('video')}
                    playsInline
                    autoPlay={true} loop={true}  muted>
                    <source src={videoDemo} type="video/mp4"/> 
                    Your browser does not support the video tag.
                </video>
            } */}

            { assetSource && isVideoDemo() && !isQrCodeOpen &&
                <video 
                    ref={videoRef}
                    className={E('video')}
                    playsInline
                    autoPlay={false} loop={true}  muted>
                    <source src={assetSource} type="video/mp4"/> 
                    Your browser does not support the video tag.
                </video>
            }

            {/* temporary hide */}
            {/* { assetSource && isTransitions() && !isQrCodeOpen &&
            <div className={E('video-lens')}>
                <video 
                    ref={videoRef}
                    className={E('video')}
                    playsInline
                    autoPlay={false} loop={true}  muted>
                    <source src={assetSource} type="video/mp4"/> 
                    Your browser does not support the video tag.
                </video>
                { !qrPopup &&
                <button className={E('video-button')} onClick={openQrCode} data-vto-button-content={t('vto_new')}>
                    <div className={E('video-button-icon')}>
                        <Icon name='left-arrow-white'/>
                    </div>
                        {t('vto_transitions_header')}
                        <div className={E('video-button-icon')}>
                            <Icon name={'vto_qr'} type='png'/>
                        </div>
                </button>}
            </div>
            } */}
            { assetSource && isVideoDemo() && isQrCodeOpen &&
            <div className={E('qr-video-container')}>
                <video 
                ref={videoRef}
                className={E('qr-video')}
                playsInline
                autoPlay={false} loop={true}  muted>
                    <source src={assetSource} type="video/mp4"/> 
                    Your browser does not support the video tag.
                </video>
                <div className={E('qr-head-vto')}>
                    <div className={E('qr-head-vto-icon')}>
                        <Icon name={'vto_qr'} type='png'/>
                    </div>
                </div>
            </div>
            }

            {/* NOTE: See through standard asset */}
            {   assetSource && finalDemoType &&
                [
                    ProductDemoType.SEE_THROUGH_IMAGE, 
                    ProductDemoType.SEE_THROUGH_VIDEO
                ].includes(finalDemoType?.demoType as ProductDemoType) &&
                
                <SeeThrougDemo type={finalDemoType?.demoType as ProductDemoType} demo={finalDemoType}/>
            }

            {
                // !isTransitions() && isImageDemo() && assetSource &&
                isImageDemo() && assetSource &&
                <img src={assetSource} alt="" />
            }

            {
               demoType?.is360 && !isCompareEnabled &&
                <DrawLine mode='active'/>
            }

            {!isChangeDemoTypeLoading && showTextOverLay  &&
                <DemoTextOverlay finalDemoType={finalDemoType?.demo} isCompareEnabled={isCompareEnabled}/>
            }
        </div>
    )
}

export default ProductDemo;