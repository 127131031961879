import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TUTORIAL_CONTENT } from "../../constants/tutorial-content.constants";
import tutorialDetails from "../../interface/tutorial-content-details";
import iRootState from "../interface/root-state.interface";
import tutorialStore from "../interface/tutorial.interface";

const initialState: tutorialStore  = {
   items: {
    newFeatures: [],
    useJourney: [],
    personalizedCompanion: [],
    updateApp: [],
   },
    selected: '',
    description: {
        label: '',
        key: '',
        description: '',
        content: [
            {
                title: '',
                file: '',
                viewed: false
            }
        ]
    },
    view: 'tutorialMain'
}

const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState,
    reducers:{
        selectedCard: (state, action: PayloadAction<{
            key: string,
            selectedKey: string
            }>) => 
            {   
                let desc;
                switch(action.payload.key){
                    case 'newFeatures':
                            desc = state.items?.newFeatures?.find(val =>
                                val.key === action.payload.selectedKey  
                            );
                        break;
                    case 'useJourney':
                            desc = state.items?.useJourney?.find(val =>
                                val.key === action.payload.selectedKey  
                            );
                        break;
                    case 'personalizedCompanion':
                            desc = state.items?.personalizedCompanion?.find(val =>
                                val.key === action.payload.selectedKey  
                            );
                        break;
                    case 'updateApp':
                            desc = state.items?.updateApp?.find(val =>
                                val.key === action.payload.selectedKey  
                            );
                        break;
                    default: 
                            break;
                }
                return{...state, selected: action.payload.key, description: desc}
            },
            saveViewMode: (state, action: PayloadAction<string>) => ( {
                ...state, 
                view: action.payload
            }),
            saveTutorialItems: (state, action: PayloadAction<
                {}>) => ( 
                    {
                ...state, 
                items: action.payload
            }),
    }
});

export const getTutorial = (state:iRootState) => state.tutorial;
export default tutorialSlice;