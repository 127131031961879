import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import SideBoxBordered from '../../../components/ui/SideBoxBordered';
import { LENS_THICKNESS_RULES } from '../../../constants/lens-thickness.constants';
import routes from '../../../constants/routes.constant';
import { getApp } from '../../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import lensThicknessSlice, { getlensThickness } from '../../../store/slices/lens-thickness.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import LensPairType from '../../../types/lens-pair.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import useLensThickness from '../useLensThickness';

interface Props {

}

const LensThicknessSide : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-thickness-side');
    const history = useHistory();
    const { activePair, lensRecommendation } = useSelector(getLensConsultation);

    const { view } = useSelector(getlensThickness);
    const { tempPrescriptionData, isValidPrescription } = useSelector(getPrescription);
    const { prescription } = useSelector(getUser);
    const dispatch = useDispatch();
    const lensThicknessRules = LENS_THICKNESS_RULES;
    const { getRecommendedLensThickness } = useLensThickness();
    const { tempLensThickness } = useSelector(getlensThickness)
    const { t } =  useTranslation(useSelector(getApp));


    // HOOKS

    // METHODS
    const gotoSummary = () => {
        if(view === 'prescription') {
            dispatch(lensThicknessSlice.actions.changeView('controls'));
            dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));  
            recommendLensThickness(tempPrescriptionData);           
            dispatch(prescriptionSlice.actions.resetData());
            return;
        }
    
        if(lensRecommendation)dispatch(userSlice.actions.saveLensRecommendationData(lensRecommendation));
        if(tempLensThickness)dispatch(userSlice.actions.saveLensThickness(tempLensThickness));
        
        history.push(routes.summary.path);
    }

    const changePair = (pair: LensPairType) => {
        if(!lensRecommendation || !lensRecommendation[pair]?.correct)return;
        dispatch(lensConsultationSlice.actions.changePair(pair))
    }

    const recommendLensThickness = (prescription: any) => {
        const sphere = prescription.values.sphere;
        const thickness = getRecommendedLensThickness({
            od: sphere.od,
            os: sphere.os
        }, lensThicknessRules)

        dispatch(lensThicknessSlice.actions.setLensThickness({
            pair1: thickness,
            pair2: thickness
        }));
        dispatch(userSlice.actions.saveLensThickness({
            pair1: thickness,
            pair2: thickness
        }));
    }

    const isPrescriptionComplete = () => {
        return prescription.values.sphere.od ? true : false
    }

    const enableSaveNextButton = () => {
        if(view === 'prescription') return isValidPrescription;
        return isPrescriptionComplete();
    }

    return (
        <div className={B()}>
            <SideBoxBordered>
                <div className={E('top')}>                    
                    <label>
                        <Icon name="progress-circle"/> 
                        Prescription
                    </label>

                    <div className={E('lens-pairs', activePair)}>
                        <Icon name="lens-pair-1" click={() => changePair(LensPairType.PAIR1)}/>
                        <span></span>
                        <Icon name="lens-pair-2" click={() => changePair(LensPairType.PAIR2)}/>
                    </div>
                </div>

                <Icon name="tempo-thickness" type="image"/>

                <div className={E('buttons')}>
                    <Button text={t('questionnaires_q_save_your_anamnesis')} 
                        icon="right-arrow-circle-white" 
                        click={gotoSummary} 
                        disabled={!enableSaveNextButton()}/>
                </div>
            </SideBoxBordered>
        </div>
    )
}

export default LensThicknessSide;