import iListMenuItem from '../interface/list-menu-item.interface';

const deliverySummary = {
    pair1:{
        details: {
            pair:1,
            type:"Progressive vision",
            description:"Ut non eleifend turpis, a egestas diam. Cras mi leo, posuere sit amet tincidunt quis, aliquet ac nisl. Phasellus tellus orci, pharetra eu sodales in, viverra a urna. Fusce viverra congue tristique. Ut id mauris ac mauris convallis vehicula id eu ligula.",
            price:"450"
        } 
    },
    pair2:{
        details: {
            pair:2,
            type:"Sun Vision",
            description:"Ut non eleifend turpis, a egestas diam. Cras mi leo, posuere sit amet tincidunt quis, aliquet ac nisl. Phasellus tellus orci, pharetra eu sodales in, viverra a urna. Fusce viverra congue tristique. Ut id mauris ac mauris convallis vehicula id eu ligula.",
            price:"250"
        } 
    }
}

export const NearVisionMenuItems: iListMenuItem[]  = 
[
    // {
    //     value: 'smartwatch',
    //     label: 'vision_test_vis_test_smartwatch',
    //     icon: 'watch'
    // },
    {
        value: 'smartphone', 
        label: 'vision_test_vis_test_smartphone',
        icon: 'mobile'
    },
    {
        value: 'laptop', 
        label: 'vision_test_vis_test_laptop',
        icon: 'laptop'
    },
    {
        value: 'desktop', 
        label: 'vision_test_vis_test_desktop',
        icon: 'computer2'
    }
] 

export const visionTestValues = 
[
    { 
        m: '1', 
        p: '4', 
        va: '0,4', 
        text: 'vision_test_vis_test_va=0,4_content'
    },
    { 
        m: '0,8', 
        p: '3,2', 
        va: '0,5', 
        text: 'vision_test_vis_test_va=0,5_content'
    },
    { 
        m: '0,63', 
        p: '2,5', 
        va: '0,63', 
        text: 'vision_test_vis_test_va=0,63_content'
    },
    { 
        m: '0,5', 
        p: '2', 
        va: '0,8', 
        text: 'vision_test_va=0,8_content'
    },
    { 
        m: '0,4', 
        p: '1,5', 
        va: '1,0', 
        text: 'vision_test_vis_test_va=1,0_content'
    }
]

export default deliverySummary;