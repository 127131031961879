import { useSelector } from "react-redux";
import pricingBreakdown from "../../constants/pricing-breakdown";
import iLensRecommendationPair from "../../interface/lens-recommendaiton-pair.interface";
import iPriceBreakdown from "../../interface/price-breakdown.interface";
import iProductDetails from "../../interface/product-details.interface";
import iProductCombi from "../../store/interface/product-combi.interface";
import { getApp } from "../../store/slices/app.slice";
import { getLensConsultation } from "../../store/slices/lens-consultation.slice";
import { getMyCompanion } from "../../store/slices/mycompanion.slice";
import { getProducts } from "../../store/slices/products.slice";
import { getUser } from "../../store/slices/user.slice";
import LensCategoryType from "../../types/lens-category.type";
import { fetchProductDetailsViaId, fetchSubProductDetailsViaSubProductId } from "../misc";
import useTranslation from "./useTranslation";

type hookType = {
    getBreakdown: () => iPriceBreakdown,
    getDetails: (lrpair: iLensRecommendationPair) => iProductDetails,
    getDiscount: (isPair1: boolean, total: number) => number,
    getCurrency: () => string,
    getPackageOfferPrice: (
        correct_id: string | undefined,
        correct_code: string | undefined,
        correct_index: number | undefined,
        protect_id: string | undefined,
        protect2_id: string | undefined,
        enhance_id: string | undefined
    ) => number
}

const usePricing  = (): hookType =>  {
    const app = useSelector(getApp)
    const lensRecom = useSelector(getLensConsultation)
    const { myCompannion } = useSelector(getApp)
    const { measures } = useSelector(getUser)
    const { temporaryProductList } = useSelector(getProducts);
    const { productCombi } = app; 
    const { t } = useTranslation(app);
    const { breakdown } = useSelector(getLensConsultation)

    const defaultVal:iPriceBreakdown = breakdown
    const orig:iPriceBreakdown = pricingBreakdown

    const get = (lensDesign: string | undefined) => {
        switch(lensDesign){
            case LensCategoryType.PROGRESSIVE: return t('delivery_design_lens1')
            case LensCategoryType.SINGLE_VISION: return t('delivery_design_lens2')
            case LensCategoryType.PROGRESSIVE_SV_LENS: return t('delivery_design_lens3')
            case 'both': return t('delivery_design_lens3')
            default: return ''
        }
    }

    const pair = (isPair1: boolean) => {
        const { lensRecommendation } = lensRecom
        const lrpair = isPair1 ? lensRecommendation?.pair1 : lensRecommendation?.pair2
        const { tempDiscountSettings } = myCompannion
        const p1Percentage = tempDiscountSettings[1].discountPercentage
        const p2Percentage = tempDiscountSettings[2].discountPercentage
        const disc = isPair1 ? p1Percentage : p2Percentage

        if(lrpair){
            const index = getDetails(lrpair).index
            const discount = getDiscount(isPair1, getPrice(lrpair) + getLensThickness(index))
            const hasNocombi = myCompannion.essilorRecommend && getPrice(lrpair) === 0 ? true : false
            const enabled = lrpair && lrpair.correct === null ? false : true

            return {
                enabled: enabled,
                categoryLens: get(getDetails(lrpair).lensDesign),

                summaryPrice: getPrice(lrpair) + getLensThickness(index) - discount,
                deliveryPrice: getPrice(lrpair) + getLensThickness(index) + getMeasureData(isPair1) - discount,

                price: getPrice(lrpair),
                discount: discount,
                discountPercentage: disc,
                lensThickness: getLensThickness(index),
                measures: getMeasureData(isPair1),
                
                correctPrice: getManualSubProductPrice(getDetails(lrpair).correct),
                protectPrice: getManualPrice(getDetails(lrpair).protect),
                protect2Price: getManualPrice(getDetails(lrpair).protect2),
                enhancePrice: getManualPrice(getDetails(lrpair).enhance),

                hasNocombi: hasNocombi
            }
        }else{
            return isPair1 ? {
                ...orig.pair1,
                enabled: false
            } : {
                ...orig.pair2,
                enabled: false
            }
        }
    }

    const getLensThickness = (thickVal: number | undefined) => {
        if(thickVal){
            const { tempLensThicknessSettings, essilorRecommend } = myCompannion
            const val = tempLensThicknessSettings.filter((thick) => { return thick.value === thickVal && thick.enabled } )
            
            return essilorRecommend ? 0 : (Array.isArray(val) && val.length > 0 ? val[0].price : 0)
        }else{
            return 0
        }

    }

    const getDiscount = (isPair1: boolean, total: number) => {
        const { tempDiscountSettings } = myCompannion
        const isAmountEnabled = tempDiscountSettings[0].amountEnabled
        const p1Amount = tempDiscountSettings[1].discountAmount
        const p2Amount = tempDiscountSettings[2].discountAmount
        const p1Percentage = tempDiscountSettings[1].discountPercentage
        const p2Percentage = tempDiscountSettings[2].discountPercentage

        return isPair1 ? (isAmountEnabled ? p1Amount : (total*(p1Percentage/100)) ) : ( isAmountEnabled ? p2Amount : (total*(p2Percentage/100)) )
    }

    const getPrice = (lrpair: iLensRecommendationPair) => {
        const correct = getManualSubProductPrice(getDetails(lrpair).correct)
        const protect = getManualPrice(getDetails(lrpair).protect)
        const protect2 = getManualPrice(getDetails(lrpair).protect2)
        const enhance = getManualPrice(getDetails(lrpair).enhance)

        return myCompannion.essilorRecommend ? getPackagePrice(lrpair) :
        correct + protect + protect2 + enhance
    }

    const getDetails = (lrpair: iLensRecommendationPair):iProductDetails => {
        const correct = lrpair.hasOwnProperty('correct') && lrpair.correct !== null && lrpair.correct !== undefined ? lrpair.correct.product : false
        const correctSub = lrpair.hasOwnProperty('correct') && lrpair.correct !== null && lrpair.correct !== undefined ? lrpair.correct.subProduct : false
        const protect = lrpair.hasOwnProperty('protect') && lrpair.protect !== undefined && (lrpair.protect || []).length > 0 ? lrpair.protect[0].product : false
        const protect2 = lrpair.hasOwnProperty('protect') && lrpair.protect !== undefined && (lrpair.protect || []).length > 0 && lrpair.protect[1] ? lrpair.protect[1].product : false
        const enhance = lrpair.hasOwnProperty('enhance') && lrpair.enhance !== null && lrpair.enhance !== undefined ? lrpair.enhance.product : false

        return {
            correct: correctSub ? correctSub?.id : '',
            correctCode: correctSub ? correctSub?.code : '',
            protect: protect ? protect?.id : '',
            protect2: protect2 ? protect2.id : '',
            enhance: enhance ? enhance.id : '',
            index: correctSub ? correctSub?.index : 0,
            lensDesign: correct ? correct.lensDesign : ''
        }
    }

    const getMeasureData = (isPair1: boolean) => {
        const { one } = measures
        
        const mp1 = one.length > 0 ? one[0].price.value : 0
        const mp2 = one.length > 1 ? one[1].price.value : 0

        return isPair1 ? mp1 : mp2
    }

    const getManualPrice = (id: string | undefined) => {
        if(id){
            return fetchProductDetailsViaId(id, temporaryProductList).price
        }

        return 0
    }

    const getManualSubProductPrice = (id: string | undefined) => {
        if(id){
            return fetchSubProductDetailsViaSubProductId(id, temporaryProductList).price
        }

        return 0
    }

    const getPackagePrice = (lrpair: iLensRecommendationPair) => {

        const price = fetchProductCombi(
            productCombi,
            getDetails(lrpair).correctCode,
            getDetails(lrpair).protect,
            getDetails(lrpair).protect2,
            getDetails(lrpair).enhance,
            getDetails(lrpair).index
        )

        return Number(price)
    }

    const getPackagePriceViaId = (
        correct_id: string | undefined,
        protect_id: string | undefined,
        protect2_id: string | undefined,
        enhance_id: string | undefined,
        index: number | undefined
    ) => {

        // console.log('getPackagePriceViaId---------------')
        // console.log('correct', correct_id)
        // console.log('protect', protect_id)
        // console.log('protect2', protect2_id)
        // console.log('enhance', enhance_id)
        // console.log('correctIndex', index)

        const price = fetchProductCombi(
            productCombi,
            correct_id ? correct_id : '',
            protect_id ? protect_id : '',
            protect2_id ? protect2_id : '',
            enhance_id ? enhance_id : '',
            index ? index: 0
        )

        return Number(price)
    }

    const getPackageOfferPrice = (
        correct_id: string | undefined,
        correct_code: string | undefined,
        correct_index: number | undefined,
        protect_id: string | undefined,
        protect2_id: string | undefined,
        enhance_id: string | undefined
    ) => {
        // console.log('correct_id',correct_id)
        // console.log('correct_code',correct_code)
        // console.log('correct_index',correct_index)
        // console.log('protect_id',protect_id)
        // console.log('protect2_id',protect2_id)
        // console.log('enhance_id',enhance_id)

        const correct = getManualSubProductPrice(correct_id)
        const protect = getManualPrice(protect_id)
        const protect2 = getManualPrice(protect2_id)
        const enhance = getManualPrice(enhance_id)

        return myCompannion.essilorRecommend ? getPackagePriceViaId(
            correct_code,
            protect_id,
            protect2_id,
            enhance_id,
            correct_index
        ) :
        correct + protect + protect2 + enhance + getLensThickness(correct_index)
    }

    const fetchProductCombi = (
        productCombi: iProductCombi[],
        cs:string | undefined,
        p1:string,
        p2:string,
        e:string,
        index:number
    ) => {
        // console.log('---------------')
        // console.log('correct', cs)
        // console.log('protect', p1)
        // console.log('protect2', p2)
        // console.log('enhance', e)
        // console.log('correctIndex', index)

        const res = productCombi.filter((pc:any)=>{
            return pc.correct_sub === cs && 
            pc.protect1 === p1 &&
            pc.protect2 === p2 && 
            pc.enhance === e && 
            pc.index === index
        })

        // console.log('res---', res)
        // console.log('---------------')
    
        return res.length > 0 && Array.isArray(res) ? res[0].price : 0
    }

    const getCurrency = () => {
        return defaultVal.currency
    }

    const getBreakdown = (): iPriceBreakdown => {
        // console.log('--lensRecom', lensRecom)
        // console.log('--myCompannion', myCompannion)
        // console.log('--measure', measure)

        const breakdown = {
            ...defaultVal,
            currency: defaultVal.currency,
            summaryTotal: pair(true).summaryPrice + pair(false).summaryPrice,
            deliveryTotal: pair(true).deliveryPrice + pair(false).deliveryPrice,
            pair1: pair(true),
            pair2: pair(false)
        } 
        
        return breakdown
    }

    return {
        getBreakdown,
        getDetails,
        getPackageOfferPrice,
        getDiscount,
        getCurrency
    }
}

export default usePricing;