import React, { Fragment } from 'react';
import Icon from '../../../components/ui/Icon';
import Text from '../../../components/ui/Text';
import useBEM from '../../../utils/hooks/useBEM';
import LensTransitionInAnimation from '../../lens-consultation-v2/components/LensTransitionInAnimation';
// import lensAll from './../../../assets/images/lens-consulatation/Lens_All.png';
import useTranslation from '../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';

interface Props {
    // TEMPORARY
    pair: number

    data: any
}

const DeliveryPairConsultation : React.FC<Props>  = ({
    pair,
    data
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-pair-consultation');
    const { t } = useTranslation(useSelector(getApp));


    // HOOKS

    // METHODS
    const groupBy = (data: any[], type: number) =>{
        const newData = data.filter((
            filter) => 
            parseInt(filter.type) === type )
        return newData;
    }
    const renderLensData = (product: any) => {
        return (
            <>
                <label>{product.title}</label>
                { product.descriptions && (
                    <ul>
                        {
                            groupBy(product.descriptions, 1).map( (item: any, index: number) => (
                                <Fragment key={index}>
                                    <li>
                                        <Icon name={item.icon +"-blue"} type="png"/>
                                        <p>
                                        {
                                            Array.isArray(item.label) ? t(item.label[0], item.label[1]) : t(item.label)
                                        }
                                        </p>
                                    </li>
                                    {
                                        groupBy(product.descriptions, 1).length !== (index + 1) && 
                                        <li className="separator"></li>
                                    }                                
                                </Fragment>
                            ))
                        }
                    </ul>
                    
                )}
                { product.descriptions && product.descriptions.length > 3 &&(
                    <ul>
                        {
                            groupBy(product.descriptions, 2).map( (item: any, index: number) => (
                                <Fragment key={index}>
                                    <li>
                                        <Icon name={item.icon +"-blue"} type="png"/>
                                        <p>
                                        {
                                            Array.isArray(item.label) ? t(item.label[0], item.label[1]) : t(item.label)
                                        }
                                        </p>
                                    </li>
                                    {
                                        groupBy(product.descriptions, 2).length !== (index + 1) && 
                                        <li className="separator"></li>
                                    }                                
                                </Fragment>
                            ))
                        }
                    </ul>                    
                )}
            </>
        )
    }

    return (
        <div className={B()}>
            <div className={E('lens')}>
                <LensTransitionInAnimation trigger="class"/>
            </div>

            <div className={E('details')}>
                <h2>{t('pair_2_tab__del_p2__lens_consultation_title')}</h2>

                <Text type="light">
                {t('pair_2_tab__del_p2__lens_consultation')}
                </Text>

                
                    <label>{t('pair_1_tab_del_2_p1_correct')}</label>
                    <div className={E('lens-type')}>
                        {renderLensData(data[0].products[0])}
                    </div>                    
                

                {
                    data[1] !== null && data[1] !== undefined && <>
                            <label>{t('pair_1_tab_del_2_p1_protect')}</label>
                            <div className={E('lens-type')}>
                                {
                                    data[1].products.map((val:any)=>{                                        
                                       return renderLensData(val)
                                    })
                                }
                            </div>
                        </>
                    
                }
                
                {
                    data[2] !== null && data[2] !== undefined && <>
                            <label>{t('pair_1_tab_del_3_p1_enhance')}</label>
                            <div className={E('lens-type')}>
                                {renderLensData(data[2].products[0])}
                            </div>
                        </>
                    
                } 


            </div>
        </div>
    )
}

export default DeliveryPairConsultation;