import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { backgroundPerRow, flexSpaceBetween, icon, mainIcon, pairContainer, pairHeader, pairSpan, summaryBenefitContainer, summaryBenefitContainerLI, summaryProductTitle, summaryRow, summaryRowBlock, summaryRowPadding, summaryRowTitle, summaryRowTitleII, summaryRowTitleLabel } from '../../../constants/email-css.constants';
import isummaryDetails from '../../../interface/summary.interface';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import Icon from '../../ui/Icon';
interface Props {
    data: isummaryDetails[] | any,
    pair: number
}

const SummaryDetails : React.FC<Props>  = ({
    data,
    pair
}) =>  {
    //VARIABLES
    const { t } = useTranslation(useSelector(getApp));
    //HOOKS

    //METHOD
    const groupBy = (data: any[], type: number) =>{
        const newData = data.filter((
            filter) => 
            parseInt(filter.type) === type )
        return newData;
    }
    return (
    <div style={backgroundPerRow}>
        {
            data && (
                <div style={pairHeader}>
                    <a style={pairContainer}>
                        <span style={pairSpan}><strong>Pair #{pair}</strong></span>
                    </a>
                </div>
            )
        }
        
        {/* Products and Lenses */}
        {
            data.map( (data: any,i: number) =>(
                data && data !=undefined && (
                    <div style={{display: "flex", padding: "0 3px"}}> 
                        {/* Category title */}
                        <div style={summaryRowPadding}>                 
                                <Icon type="png" name={data.icon} customStyle={mainIcon}/>  
                                <p style={summaryRowTitleLabel}>
                                {t(data.title)}&nbsp;<span style={{textTransform: "lowercase"}}>{t(data.sub)}</span>
                                </p>
                        </div>
                        {/* Selected products and benefits */}
                        <div style={summaryRowBlock}>
                        {
                            data.products.map( (product: any, ii: number) => (
                                <div style={summaryRowBlock}>
                                    <div style={summaryProductTitle}>
                                        <p style={summaryRowTitleII}>
                                            {t(product.title)}
                                        </p>
                                    </div> 
                                    {
                                        product.descriptions && (
                                            <ul style={{display: "flex", alignItems: "center", padding: "0"}}>
                                            {
                                                groupBy(product.descriptions, 1).map((desc: any, iii: number) => (
                                                    <li style={summaryBenefitContainerLI}>
                                                        <Icon type="png" name={desc.icon} customStyle={icon}/>
                                                        {/* <img src={"https://i.postimg.cc/DfX9p8J2/"+desc.icon+".png"} alt={desc.icon} style={icon}></img> */}
                                                        <label style={{fontSize: ".8rem", textAlign:"center"}} >{t(desc.label)}</label>

                                                    </li>   
                                                ))
                                            }                                                                                                 
                                            </ul>  
                                        )
                                    }  
                                    {
                                        product.descriptions && product.descriptions.length > 3 && (
                                            <ul style={{display: "flex", alignItems: "center", padding: "0"}}>
                                                {
                                                    groupBy(product.descriptions, 2).map((desc: any, iii: number) => (
                                                        <li style={summaryBenefitContainerLI}>
                                                            {/* <img src={"https://i.postimg.cc/DfX9p8J2/"+desc.icon+".png"} alt={desc.icon} style={icon}></img> */}
                                                            <Icon type="png" name={desc.icon} customStyle={icon}/>
                                                            <label style={{fontSize: ".8rem", textAlign:"center"}} >{t(desc.label)}</label>

                                                        </li>  
                                                    ))
                                                }                                              
                                            </ul>  
                                        )
                                    }                 
                                                   
                                </div>
                            ))
                        }
                        </div>        
                    </div>
                )
            ))
        }
        {                            
            data[0] && (                                
                <div style={{display: "flex", alignItems: "center", padding: "3rem 0"}}>
                    <Icon name="lens-thickness"/>
                        <label style={{fontSize: "12px", padding: "10px"}}>{t('summary_sum_lens_thickness')}</label>
                        <span style={{backgroundColor:"rgba(0, 134, 214, 0.15)", height: "17px", width: "33px", marginLeft: "1rem", padding: "12px", fontSize:"12px"}}>{ data[0].thickness}</span>
                    </div>
                )
        }
        
    </div>
    )
}
export default SummaryDetails;