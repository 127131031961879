import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Icon from '../../../components/ui/Icon';
import anamnesisDetailsSummary from '../../../interface/anamnesis-summary.interface';
import anamnesisDetails from '../../../constants/anamnesis-summery.constants';
import lensConsultationData from '../../../constants/lens-consultation-data.constants';
import { getApp } from '../../../store/slices/app.slice';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

import NeedExplorerViewType from '../../../types/need-explorer-view.type';
interface Props {
    onClose: () => void;
}

const NeedExplorerAnamnesisDetails: React.FC<Props> = ({
    onClose,
}) => {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-anamnesis-details');
    const [details, setDetails] = useState<anamnesisDetailsSummary>(anamnesisDetails);
    const { selectedId, questions, significance, selectedLens, selectedLensIcon } = useSelector(getNeedExplorer)
    const { t } = useTranslation(useSelector(getApp));
    const dispatch = useDispatch();

    // HOOKS
    useEffect(() => {
        setDetails({
            ...details,
            side: {
                tag: selectedId?.label,
                title: selectedId?.smallTitle,
                description: selectedId?.description,
            },
            summary: getSummaryDetails()

        })
    }, [])

    const getSummaryDetails = () => {
        let questions_answer = new Array()
        const questionTypes = ['CHOICE_LIST', 'CHOICE_BOX', 'CHOICE_BOX_IMAGE', 'CHOICE_BOX_KIDS', 'CHOICE_IMAGE_KIDS']
        questions.forEach((q: any) => {
            q.questions.forEach((qq: any) => {
                if (questionTypes.includes(qq.type) || qq.id === '1.6') {
                    if (Array.isArray(qq.answer)) {
                        let answers = new Array()
                        let answersId = new Array()

                        qq.answer.forEach((a: any) => {
                            //VALIDATE IF SIGNIFICANT
                            if (validateSignificance(a.value)) {
                                answers.push(a.label)
                                answersId.push(a.value)
                            }
                        })

                        if (answers.length > 0) {
                            questions_answer.push({
                                questionIndex: qq.questionIndex,
                                categoryIndex: qq.categoryIndex,
                                question: qq.question,
                                answers: answers,
                                answersId: answersId
                            })
                        }
                    }

                    if (qq.hasOwnProperty('answer') && !Array.isArray(qq.answer)) {
                        //VALIDATE IF SIGNIFICANT
                        if (qq.answer !== undefined && validateSignificance(qq.answer.value)) {
                            questions_answer.push({
                                questionIndex: qq.questionIndex,
                                categoryIndex: qq.categoryIndex,
                                question: qq.question,
                                answers: [qq.answer.label.replace("<br/>", "")],
                                answersId: qq.answer.value
                            })
                        }
                    }
                }
            })
        })

        return questions_answer
    }
    const modify = (category: number, question: number) => {

        dispatch(needExplorerSlice.actions.gotoQuestion({category, question}));
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }


    const validateSignificance = (val: any) => {
        let has = false;

        if (significance !== null) {
            significance.forEach((el: any) => {
                if (el.answerId === val) {
                    has = true
                    return
                }
            })
        }

        return has
    }

    return (
        <Modal show={true} radius="8px" width="85rem" height="55rem" transparent={true} hideCloseButton={true}>
            <div className={B()}>
                <div className={E('header')}>

                    <div className={E('lens')}>
                        {/* <Icon name={selectedLensIcon} type="image" />  */}
                        <Icon name={`${(selectedLensIcon)}-blue`} className={E('lens-icon')} type="image" />
                        <span>{t(selectedLens)}</span>
                    </div>
                    <span>
                        {t(details.side.tag)}
                    </span>
                    <Icon name="close-gray" className={E('close')} click={onClose} />
                </div>

                <div className={E('details')}>
                    <div className={E('scroll')}>
                        {
                            details.summary.map((summary, index) => (
                          
                                <div className={E('summary')} key={index}>
                                   <div className={E('summary-question')}> <h5>
                                        {t(summary.question)}
                                     
                                    </h5>
                                    <div className={E('summary-edit')}  onClick={() => modify(summary.categoryIndex, summary.questionIndex)}>
                                        <span>{t('measures_mes__3_edit')}</span>
                                        <Icon name="pencil-circle-gray-gray" />
                                    </div>
                               </div>


                                    {
                                        summary.answers.map((answer, i) => (
                                            <span className={E('summary-answer')} key={i}>
                                                {t(answer)}
                                            </span>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

           
        </Modal>
    )
}

export default NeedExplorerAnamnesisDetails;