import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import Text from '../../../components/ui/Text';
import { getApp } from '../../../store/slices/app.slice';
import DiscoveryView from '../../../types/discovery-view.type';
import useBEM from '../../../utils/hooks/useBEM';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    onNextView: (view: DiscoveryView) => void
}

const DiscoveryIntro : React.FC<Props>  = ({
    onNextView
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('discovery-intro');
    const [animate, setAnimate] = useState(false);
    const {t} = useTranslation(useSelector(getApp));
    const ga = useGoogleAnalytics();

    // HOOKS

    // METHODS
    const onNextViewHandler = () => {
        setAnimate(true);
        setTimeout(() => {
            onNextView(DiscoveryView.STEPS)
        }, 500);

        ga.event({
            category: 'Home page',
            action: 'Discover Essilor',
            label: 'Start Experience'
        })
    }

    return (
        <div className={B( animate ? 'animate' : '')}>
            
            <Text type="white" bold>
                {t('discovery_essilor_de_essilor_designs_')}
            </Text>

            <Button 
                click={onNextViewHandler}
                icon={<CircleArrow type="right" background="#f1f1f1" size=".4rem"/>}
                text={t('discovery_essilor_de_start_experience')} 
                type="secondary" 
                outlined={false}/>

            <div className={E('padding')}></div>
        </div>
    )
}

export default DiscoveryIntro;