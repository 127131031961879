import React from 'react';
import Spinner from '../../../components/ui/Spinner';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    progress: string
}

const DiscoverySplashScreen : React.FC<Props>  = ({
    progress
}) =>  {
    // VARIABLES
    const [B] = useBEM('discovery-splashscreen');

    // HOOKS

    // METHODS

    return (
        <div className={B()}>
            <Spinner text={progress}/>
        </div>
    )
}

export default DiscoverySplashScreen;