import summarySunfit from "../interface/sunfit-summary";


const XPERIO_SUNFIT_SUMMARY: summarySunfit = {

    title: "Xperio®Sunfit",
    descriptions:[
        {
            icon: "uv-protection",
            label: "sun_exp_lensbenefitxperiosun1",
            type: 1,
        },
        {
            icon: "vision-stability",
            label: "sun_exp_lensbenefitxperiosun2",
            type: 1
        },
        {
            icon: "vision-comfort",
            label: "sun_exp_lensbenefitxperiosun3",
            type: 1
        },
        {
            icon: "glare-cut",
            label: "sun_exp_lensbenefitxperiosun4",
            type: 2
        },
        {
            icon: "picto-style",
            label: "sun_exp_lensbenefitxperiosun5",
            type: 2
    
        }]
}

  export default XPERIO_SUNFIT_SUMMARY;