import iPackages from "../interface/packages.interface";

const packages : iPackages[] = [
    {
        label: 'packaged_offer_popup_po_popup_persnonalized',
        subLabel: 'packaged_offer_popup_po_popup_persnonalized_new',
        id: 'personalized',
        product: {
            price: '',
            name: ''
        },
        isDisabled: false,
        categories: [
            {
                value: '',
                label: ''
            },
            {
                value: '',
                label: ''
            },
            {
                value: '',
                label: ''
            }
        ]
    },
    {
        label: 'packaged_offer_popup_po_popup_optimized',
        subLabel: 'packaged_offer_popup_po_popup_optimized_new',
        id: 'optimized',
        product: {
            price: '',
            name: '-'
        },
        isDisabled: false,
        categories: [
            {
                value: '',
                label: ''
            },
            {
                value: '',
                label: ''
            },
            {
                value: '',
                label: ''
            }
        ]
    },
    {
        label: 'packaged_offer_popup_po_popup_standard',
        subLabel: 'packaged_offer_popup_po_popup_standard_new',
        id: 'standard',
        product: {
            price: '',
            name: ''
        },
        isDisabled: false,
        categories: [
            {
                value: '',
                label: ''
            },
            {
                value: '',
                label: ''
            },
            {
                value: '',
                label: ''
            }
        ]
    }
]

export default packages;