import iNeedExplorerSummaryDetails from "../interface/need-explorer-summary-details";

const iNeedExplorerKidsSummary: iNeedExplorerSummaryDetails[] = [
    {
        label: "questionnaires_q_screen_usage_summ_age",
        icon: "Age",
        type: "1",
        answer: undefined,
        question: '6.1'
    },
    {
        label: "questionnaires_q_screen_usage_summ_vision_rating",
        icon: "vision",
        type: "1",
        answer: undefined,
        question: '6.4'
        
    },
    {
        label: "questionnaires_q_screen_usage_spend_main_points",
        icon: "main pain",
        type: "2",
        question: '6.5'
    }
]


export default iNeedExplorerKidsSummary;