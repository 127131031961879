import React from 'react';
import iOption from '../../interface/option.interface';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    value?: string,
    options: iOption[],
    onChange?: (value: string) => void
}

const ToggleButton : React.FC<Props>  = ({
    value,
    options = [],
    onChange
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('toggle-button');


    // HOOKS
    

    // METHODS
    const activeClass = (option: iOption) => {
        if(!value) return '';
        return (value === option.value) ? 'selected' : '';
    }

    const setValue = (option: iOption) => {
        if(onChange)onChange(option.value);
    }

    return (
        <div className={B()}>
            {
                options.map((option, index) => (
                    <div className={E('option', [activeClass(option)])} key={index} 
                        onClick={() => setValue(option)}>
                        {option.label}
                    </div>
                ))
            }
        </div>
    )
}

export default ToggleButton;