import settings from "../../constants/settings.constants"

type hookType = {
    version: string,
    apiUrl: string,
    GA: string,
    demoAssetsUrl: string,
    dataStructureVersion: number,
    signalRUrl: string
}

const useENV  = (): hookType =>  {
        // const vars = settings[settings.environment]

        // return {
        //     version: vars.version,
        //     apiUrl: vars.apiUrl,
        //     GA: vars.GA,
        //     demoAssetsUrl: vars.demoAssetsUrl,
        //     dataStructureVersion: vars.dataStructureVersion
        // }
        return settings[settings.environment];
}

export default useENV;