import iProductBrand from "../../interface/product-brand.interface";
import iProduct from "../../interface/product.inteface";
import iSubProduct from "../../interface/sub-product.inteface";
import ComplementaryEquipmentType from "../../types/complementary-equipment.type";
import LensCategoryType from "../../types/lens-category.type";
import LensEnhanceType from "../../types/lens-enhance.type";
import LensFamilyType from "../../types/lens-family.type";
import LensProtectType from "../../types/lens-protect.type";
import {LENS_THICKNESS_RULES} from "../../constants/lens-thickness.constants";
import iLensRecommendationPair from "../../interface/lens-recommendaiton-pair.interface";
import iProductFamily from "../../interface/product-family.interface";
import LensPairType from "../../types/lens-pair.type";
import { RX_LENS_RECOMMENDATIONS, RX_LENS_RECOMMENDATIONS_RUSSIA, RX_LENS_RECOMMENDATIONS_WITH_XR } from "../../constants/prescription-lens-proposal.constants";
import AgeCategoryType from "../../types/age-category.type";
import SKIP_EPROM_VALIDATION_DATA from "../../constants/skip-eprom-validation.constants";

type recommendedProductType = {
    product: iProduct | undefined,
    brand?: iProductBrand | undefined,
    subProduct?: iSubProduct | undefined
}


type hookType = {
    rxLensRecommendation: (params : {
        products: iProductFamily[],
        ageCategory: AgeCategoryType,
        pair: LensPairType,
        family: LensFamilyType,
        category?: LensCategoryType | LensProtectType | LensEnhanceType,
        equipment?: ComplementaryEquipmentType,
        index?: number
        country?:string
    }) => recommendedProductType,
    getProductsByFamily: (family: LensFamilyType, products: any) => any;
    getProductById: (params: {
        id: string, 
        list:iProductFamily[],
        family: LensFamilyType,
        brandId?: string
    }) => iProduct | undefined;
    getSubProductsByLabel: (label: string, subProducts: iSubProduct[], isSortByIndex?: boolean) => iSubProduct[];
    getUniqueSubProducts: (subProducts: iSubProduct[], options?: {
        isLowestIndex?: boolean,
        targetIndex?: number
    }) => iSubProduct[];
    isValidRecommendation: (recommendations: {
        pair1?: iLensRecommendationPair,
        pair2?: iLensRecommendationPair
    }) => boolean,
    getMeasuresHighestValues: (data: any, pair: number) => {
        a: number,
        b: number,
        pd: number,
        height: number
    },
    getPrescriptionByPosition : (pos: 'od' | 'os', rx: any) => any,
    getLeftAndRightPrescription : (rx: any) => any,
    isValidOnEPROM: (protect: any[], eprom: string[], result: (result: boolean) => void) => void,
    getRequestType: (withRX: boolean, validateEPROM: boolean) =>  number,
    correctPrescriptionData : (data: any, defaultValue: any) => any,
    skipEPROMValidation: (country: string, productId: string, subProductId: string) => boolean
}


const useLensConsultation  = (): hookType =>  {

    const getProductsByFamily = (type: LensFamilyType, products: any) => {
        const family = products.filter( (o: any) => (o.id as LensFamilyType) === type)
        if(family.length < 1)return null;
        return family[0];
    }


    const filterSubProductsByIndex = (product: iProduct, index: number): iSubProduct[] => {
        const subProducts = product.subProducts;
        if(!subProducts || subProducts.length === 0)return [];        
        const filtered = subProducts.filter( subProduct => subProduct.index === index);

        if(filtered.length === 0){
            let ruleIndex = 0;
            LENS_THICKNESS_RULES.forEach((rule: any, i: number) => {
                if(rule.value === index) ruleIndex = (i - 1)
            });
            if(ruleIndex < 0)return [];
            return filterSubProductsByIndex(product, LENS_THICKNESS_RULES[ruleIndex].value)
        }
        return filtered;
    }

    const loopProducts = (brands: iProductBrand[], callback: (product: iProduct, brand: iProductBrand) => boolean) => {
        let withResult = false;

        let brandLoop = 0;
        while(brandLoop < brands.length && !withResult){
            const products = brands[brandLoop].products;
            let productLoop = 0;
            while(productLoop < products.length && !withResult){
                const product:iProduct = products[productLoop];
                withResult = callback(product, brands[brandLoop]);
                productLoop++;
            }
            brandLoop++;
        } 
    }

    const getSubProductsByLabel = (label: string, subProducts: iSubProduct[], isSortByIndex = false): iSubProduct[] =>  {
        const filtered = subProducts.filter( (p:iSubProduct) => p.label === label) as iSubProduct[];
        
        if(!isSortByIndex)return filtered;
        return filtered.sort(function(a,b){
            return a.index - b.index;
        });
    }

    const getUniqueSubProducts = (subProducts: iSubProduct[], options?: {
        isLowestIndex?: boolean,
        targetIndex?: number
    }): iSubProduct[] => {

        if(options?.targetIndex){
            const target = subProducts.filter( x => x.index === options.targetIndex);
            if(target.length > 0)return target;            
        }
        
        const unique: string[] = []
        const uniqueSubProducts =  subProducts.filter((p: iSubProduct) => {
            if(unique.includes(p.label))return false;
            unique.push(p.label);
            return true;
        });   

        if(!options?.isLowestIndex) return uniqueSubProducts;
        // get the subproducts with lowest index
        const lowestIndex:iSubProduct[] = []
        uniqueSubProducts.forEach( x => {
            const sortSubProducts = subProducts.filter( p => p.label === x.label).sort(function(a,b){
                return a.index - b.index;
            });
            lowestIndex.push(sortSubProducts[0]);
        });
        return lowestIndex;
    }

    const isWithRecommendedProduct = (recommendations?: {
        pair1?: iLensRecommendationPair,
        pair2?: iLensRecommendationPair
    }) => {
        if(!recommendations)return false;

        let withProducts = false;
        const cloned = JSON.parse(JSON.stringify(recommendations));
        ['pair1', 'pair2'].forEach((pairKey: string) => {
            ['correct','protect','enhance'].forEach( (familyKey: any) => {
                const pair = cloned[pairKey];
                const family = pair ? pair[familyKey] : null;
                if(!family)return;
                
                if(familyKey === "correct" && family.subProduct){
                    withProducts = true;                    
                }else if(familyKey === "protect" && family.length > 0){
                    withProducts = true;
                } else if(family.product){
                    withProducts = true;
                }
            });
        });
        return withProducts;
    }

    const isValidRecommendation = (recommendations?: {
        pair1?: iLensRecommendationPair,
        pair2?: iLensRecommendationPair
    }): boolean => {
        if(!recommendations) return false;
        if(!isWithRecommendedProduct(recommendations))return false;
        if(recommendations?.pair1?.correct?.product && !recommendations?.pair1?.correct.subProduct) return false;
        if(recommendations?.pair2?.correct?.product && !recommendations?.pair2?.correct.subProduct) return false;
        return true;
    }

    const getProductById = (params: {
        id: string, 
        list:iProductFamily[],
        family: LensFamilyType,
        brandId?: string,
    }): iProduct | undefined => {
        const { id, list, family, brandId } = params;
        const fam = list.filter( f => f.id === family)[0];
        
        let brand = brandId ?
            fam.brands.filter( b => b.id === brandId)[0] :
            null;

        if(brand){
            const found = brand.products.filter( p => p.id === id )[0];
            return found;
        }

        let product: any;
        for(const b in fam.brands){
            const found = fam.brands[b].products.filter( p => p.id === id )[0];
            if(found){
                product = found;
                break;
            }
        }
        return product;
    }; 


    const rxLensRecommendation = (params : {
        products: iProductFamily[],
        ageCategory: AgeCategoryType,
        pair: LensPairType,
        family: LensFamilyType,
        category?: LensCategoryType | LensProtectType | LensEnhanceType,
        equipment?: ComplementaryEquipmentType,
        index?: number,
        country?:string
    }) => {
		// JUMP - IMPORTANT - RECOMMENDATION SYSTEM FOR LENS CONSULATION
        let currentCountry = params.country && params.country
        // let currentCountry = localStorage.getItem("country")
        const countryWithXR: string[] = ["ro", "de", "pt", "bg", "gb", "es", "be", 'sl', 'hr']
        let isXrEnable: boolean = countryWithXR.includes(currentCountry as string) 

        // const decisionTree = params.country &&  params.country === 'ru' ? RX_LENS_RECOMMENDATIONS_RUSSIA : RX_LENS_RECOMMENDATIONS;
        const decisionTree = params.country &&  params.country === 'ru' ? RX_LENS_RECOMMENDATIONS_RUSSIA : isXrEnable ? RX_LENS_RECOMMENDATIONS_WITH_XR : RX_LENS_RECOMMENDATIONS;
		const age = decisionTree[params.ageCategory];
        const pair = age[params.pair];
        const family = pair[params.family];

        const recommendation = family.filter( (x:any) => {            
            if(params.pair === LensPairType.PAIR2){
                if(!params.category && !params.equipment)return true;
                if(params.category)return x.category === params.category && x.equipment === params.equipment;
                return x.equipment === params.equipment;
            }
            return x.category === params.category;
        })[0];

        const recommended: recommendedProductType = {
            product: undefined,
            brand: undefined,
            subProduct: undefined
        }

        if(!recommendation)return recommended;

        const brands = getProductsByFamily(params.family, params.products).brands;
        loopProducts(brands, (product: iProduct, brand: iProductBrand) => {

            if(Array.isArray(recommendation.product)){            
                if(product.id === recommendation.product[params.index || 0]){
                    recommended.product = product.enabled ? product : undefined;
                    recommended.brand = brand;                
                    return true;
                }
            }

            if(product.id === recommendation.product){
                recommended.product = product.enabled ? product : undefined;
                recommended.brand = brand;                
                return true;
            }
            return false;
        });        
        return recommended;
    }

    const N = (data: any) => {
        if(!data)return 0;
        return Number(String(data).split(' ')[0]);
    }

    const getMeasuresHighestValues = (data: any, pair: number) => {
        const measures1 = data.one[pair];
        if(!measures1) {
            return {
                a: 50,
                b: 28,
                pd: 30,
                height: 23
            }
        }
        const {left, right} = data.two[pair];
        const pd = N(left.pd.value) > N(right.pd.value) ? left.pd.value : right.pd.value;
        const height = N(left.height.value) > N(right.height.value) ? left.height.value : right.height.value;

        return {
            a: measures1.a.value,
            b: measures1.b.value,
            pd: N(pd),
            height: N(height)
        }
    }

    const getPrescriptionByPosition = (pos: 'od' | 'os', rx: any) => {
        const parse = (rxProp: string) => rx[rxProp] ? parseFloat(rx[rxProp][pos]) : null;

        return {
            sph: parse('sphere'),
            cyl: parse('cylinder'),
            axl: parse('axis'),
            add: parse('add')
        }
    }

    const getLeftAndRightPrescription = (rx: any) => {
        return {
            left: getPrescriptionByPosition('od', rx),
            right : getPrescriptionByPosition('os', rx)
        }
    }

    const isValidOnEPROM = (protect: any[], eprom: string[], result: (result: boolean) => void) => {
        let validOnEPROM = eprom.length > 0;
        const selectedProtects =  protect ? protect.map( p => p.product?.id) : ['clear'];
        selectedProtects.forEach( p => {
            const isFound = (eprom || []).includes(p);   
            if(!isFound) validOnEPROM = false;
        });
        result(validOnEPROM);
    }

    const getRequestType = (withRX: boolean, validateEPROM: boolean): number => {
        let flag = -1;
        if(withRX) flag += 1; // 0
        if(validateEPROM) flag += 2;
        return flag;
    }

    const transformRxFieldData = (data: any, rxField: string, defaultValue: any) => {
        const rxValues = data.prescription.values;
        if(
            !rxValues[rxField] ||
            !rxValues[rxField].od ||
            !rxValues[rxField].os
        ){
            return {
                ...data,
                prescription: {
                    ...data.prescription,
                    values: {
                        ...data.prescription.values,
                        [rxField]: {od: defaultValue, os: defaultValue},
                    }
                }
            }
        }
        
        return data;
    }

    const correctPrescriptionData = (data: any, defaultValue: any) => {
        const rxValues = data.prescription.values;
        
        let finalData = {...data};
        if(!rxValues){
            const odos = {od: defaultValue, os: defaultValue};

            return {
                ...data,
                prescription: {
                    ...data.prescription,
                    values: {
                        addition: defaultValue,
                        axis: odos,
                        cylinder: odos,
                        sphere: odos,
                    }
                }
            }
        }
        finalData = transformRxFieldData(finalData, 'sphere', defaultValue);
        finalData = transformRxFieldData(finalData, 'axis', defaultValue);
        finalData = transformRxFieldData(finalData, 'cylinder', defaultValue);

        return finalData;
    }

    const skipEPROMValidation = (country: string, productId: string, subProductId?: string) => {
        // if(experience && experience === ExperienceType.SUN)return true
        const skipCountry = SKIP_EPROM_VALIDATION_DATA[country];
        const skipProduct = skipCountry && skipCountry.includes(productId)
        //Todo: refactor code if we need to include subproduct
        //const skipSubProduct =  skipProduct ? skipProduct.includes(subProductId) : false;
        return !!(skipCountry && skipProduct)
    }

    return {
        getProductsByFamily,
        getSubProductsByLabel,
        getUniqueSubProducts,
        isValidRecommendation,
        getProductById,
        rxLensRecommendation,
        getMeasuresHighestValues,
        getPrescriptionByPosition,
        getLeftAndRightPrescription,
        isValidOnEPROM,
        getRequestType,
        correctPrescriptionData,
        skipEPROMValidation
    }
}

export default useLensConsultation;