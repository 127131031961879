const LENS_FAMILY_DESCRIPTION = {
    correct: {
        title: 'discovery_essilor_de_correct_protect_title',
        description: 'discovery_essilor_de_correct_your_vision'
    },
    protect: {
        title: 'discovery_essilor_de_protect_your_eyes_title',
        description: 'discovery_essilor_de_protect_your_eyes'
    },
    enhance: {
        title: 'discovery_essilor_de_enhance_clarity_title',
        description: 'discovery_essilor_de_enhance_clarity_'
    }
}

const DISCOVER_STEPS = [
    {
        title: 'discovery_essilor_de_leader_in_innovation_title',
        description: 'discovery_essilor_de_leader_in_innovation_'
    },
    {
        title: 'discovery_essilor_de_human_centric_title',
        description: 'discovery_essilor_de_human_centric'
    },
    {
        title: 'discovery_essilor_de_trustable_partner_title',
        description: 'discovery_essilor_de_trustable_partner_'
    },
    {
        title: 'discovery_essilor_de_expert_craftsmanship_title',
        description: 'discovery_essilor_de_expert_craftsmanship'
    }
]

export {
    LENS_FAMILY_DESCRIPTION,
    DISCOVER_STEPS
}