import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import iProductBrand from '../../../interface/product-brand.interface';
import iProductFamily from '../../../interface/product-family.interface';
import { getApp } from '../../../store/slices/app.slice';
import { getMyCompanion } from '../../../store/slices/mycompanion.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import AdvanceSettingFilterField from './AdvanceSettingFilterField';
import AdvanceSettingFilterFieldSelection from './AdvanceSettingFilterFieldSelection';


interface iFilterValue {
    id: string,
    label: string,
    value?: {
        label: string,
        id: string
    }
}

interface Props {
    onSubmit: (values: iFilterValue[]) => void
}

const AdvanceSettingFilter : React.FC<Props>  = ({
    onSubmit
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('advance-setting-filter');
    const { productList, tempLensThicknessSettings} = useSelector(getMyCompanion);
    
    const [showFilterSelection, setShowFilterSelection] = useState(false);
    const [activeFieldId, setActiveFieldId] = useState<string>('');
    const [selectOptions, setSelectOptions] = useState<any[]>([]);
    const { getUniqueSubProducts } = useLensConsultation();
    const { t } = useTranslation(useSelector(getApp));


    const [fields, setFields] = useState<iFilterValue[]>([
        { id: "correct_sub", label: "advancedpricing_correct"},
        { id: "protect2", label: "advancedpricing_protect_-_2"},
        { id: "index", label: "advancedpricing_index"},
        { id: "protect1", label: "advancedpricing_protect_-_1"},
        { id: "enhance", label: "advancedpricing_enhance"}
    ])


    // HOOKS
    useEffect(() => {
        // console.log(productList);
    }, [])

    // METHODS
    const onClickHandler = useCallback((id: string) => {
        const options = getDynamicOptions(id);
        setSelectOptions(options);
        setActiveFieldId(id);
        setShowFilterSelection(true);        
    }, []);


    const onClearHandler = useCallback((id: string) => {
        setFields(prevStat => (prevStat.map( x => (
            x.id === id ?
            { ...x, value:  undefined} :
            x
        ))))  
    }, []);



    const getDynamicOptions = (id: string) => {
        const key = id.replace('1', '').replace('2', '').replace('_sub', '');

        if(key === 'index'){
            const indxOptions = tempLensThicknessSettings.filter( x => x.enabled).map( x => ({
                label: x.value,
                value: x.value
            }));
            return indxOptions;
        }
        
        const family = productList.filter( (p: iProductFamily) => p.id === key)[0];
        const options = family.brands.map((b: iProductBrand) => ({
            label: b.label,
            value: b.id,
            subSelection: b.products.map( p => ({
                label: p.label,
                value: p.id,
                subSelection: getUniqueSubProducts(p.subProducts || []).map( sp => ({
                    label: sp.label,
                    value: sp.code,
                }))
            }))
        }));
        return options;
    }
    
    const selectionOnCloseHandler = useCallback(() => {
        setShowFilterSelection(false);
    }, [])

    const renderFields = (flds: any[]) => (
        flds.map( x => (
            <AdvanceSettingFilterField 
                key={x.id}
                id={x.id} 
                label={t(x.label)} 
                value={x.value?.label || ''}
                onClick={() => onClickHandler(x.id)}
                onClear={() => onClearHandler(x.id)}
            />
        ))
    )


    const onSelectOptionHandler = useCallback((option) => {
        setFields(prevStat => (prevStat.map( x => (
            x.id === activeFieldId ?
            { ...x, value: option } :
            x
        ))))
        setShowFilterSelection(false);
    },[activeFieldId])

    return (
        <>
            <div className={B()}>
                <div className={E('fields')}>
                    { renderFields(fields) }                    
                </div>
                <Button text={t('advancedpricing_filter')} className={E('button')} click={() => onSubmit(fields)}/>    
            </div>
            
            <AdvanceSettingFilterFieldSelection 
                show={showFilterSelection} 
                onClose={selectionOnCloseHandler}
                onSelect={onSelectOptionHandler}
                options={selectOptions}
            />        
        </>
    )
}

export default AdvanceSettingFilter;