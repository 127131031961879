import React from 'react';
import { useSelector } from 'react-redux';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import { getApp } from '../../../../store/slices/app.slice';
import useTranslation from '../../../../utils/hooks/useTranslation';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: string) => void
}

const Note : React.FC<Props>  = ({
    question,
    onAnswer
}) =>  {
    // VARIABLES
    const { t } =  useTranslation(useSelector(getApp));
    // HOOKS

    // METHODS
    const onChangeHandler = (event: any) => {
        if(onAnswer) onAnswer(event.target.value);
    }

    return (
        <textarea 
            value={question.answer as string}
            className="note" placeholder={t(question.placeholder ? question.placeholder : '')} onChange={onChangeHandler}>
        </textarea>
    )
}

export default Note;