import { useSelector } from "react-redux";
import Icon from "../../../components/ui/Icon";
import { getApp } from "../../../store/slices/app.slice";
import useBEM from "../../../utils/hooks/useBEM";
import useTranslation from "../../../utils/hooks/useTranslation";


interface Props {
    title:string,
    count?: number,
    totalCount?: number,
    className?: string,
    click?: () => void,
}

const TutorialCard : React.FC<Props>  = (
    {   title,
        count = 0, 
        totalCount = 0,
        className,
        click
    }
    ) =>  {
    //VARIABLE
    const [B, E] = useBEM('tutorial-card');
    const { t } = useTranslation(useSelector(getApp));

    //METHOD
    const onClickHandler = () => {
        if(!click)return;
        click();
    }
    const computeProgress = () => {
        const percent = 100 / totalCount * count;
        return Math.ceil(percent);
    }
    const getWord = (value:string) =>{
        return  value.substr(1, 9);
    }
    return(
        <div className={B(`${className}`)} onClick={onClickHandler}>
            <div className={E('header')}>
                <h4>
                    {title}
                </h4>
                    <Icon name="see-more" className={className}></Icon>
            </div>
            <div className={E('body')}>
                <label>{totalCount} {getWord(t('my_practice_my_1_content'))}</label>
                <div className={E('body progress-bar')}>
                    <span style={{width: `${computeProgress()}%`}}></span>
                    {/* <span style={{width: `78%`}}></span> */}
                </div>
            </div>
        </div>
    )
}

export default TutorialCard;