import React, { useEffect, useState } from 'react';
import useBEM from '../utils/hooks/useBEM';
import Utils from '../utils/Utils';
import eyeGif from './../assets/gif/eye.gif';
import eyeSVG from './../assets/svg/eye.svg';


interface Props {

}

const BlinkEye : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('blink-eye');
    const [onHoverEye, setOnHoverEye] = useState(false);
    const {downloadAsset} = Utils();
    const [gifSource, setGifSource] = useState<string>();
    const [svgSource, setSvgSource] = useState<string>();


    // HOOKS
    useEffect(() => {
        downloadAsset(eyeGif).then( b => setGifSource(b))
        downloadAsset(eyeSVG).then( b => setSvgSource(b))
    }, [])

    // METHODS
    const onHoverHandler = (isHover: boolean) => {
        setTimeout(() => {
            setOnHoverEye(isHover);
        }, isHover ? 0 : 250);
    }

    return (
        <div className={B()} 
            onMouseEnter={() => onHoverHandler(true)}
            onMouseLeave={() => onHoverHandler(false)}
        >
            {/* { svgSource && <img src={svgSource} alt=""/> } */}

            {
                gifSource &&
                <img className={onHoverEye ? 'gif' : 'svg'} src={onHoverEye ? gifSource : svgSource} alt=""/>
            }
        </div>
    )
}

export default BlinkEye;