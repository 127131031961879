import iProductLogic from "../interface/product-logic.interface";

const productLogic: iProductLogic[] = [
{ answerId: '1.1.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.1.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.1.3', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.2.1', scoreMatrix: [0,0,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.2.2', scoreMatrix: [0,0,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.2.3', scoreMatrix: [5,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.2.4', scoreMatrix: [5,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.2.5', scoreMatrix: [5,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.3.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.3.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.3.3', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.4.1', scoreMatrix: [5,3,3,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.4.2', scoreMatrix: [3,4,4,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.4.3', scoreMatrix: [3,3,5,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.3', scoreMatrix: [5,3,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.4', scoreMatrix: [3,5,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.5', scoreMatrix: [5,4,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.6', scoreMatrix: [5,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.7', scoreMatrix: [0,0,3,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.5.8', scoreMatrix: [0,0,0,0,0,0,0,0,0,4,5,4,5,0,0,0,0,0,0,2,0,0,2,0,0,0,0,0,0,0] },
{ answerId: '1.5.9', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.6.1', scoreMatrix: [2,1,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,1,0,0,0,0,0,0,0,0] },
{ answerId: '1.6.2', scoreMatrix: [1,1,1,1,0,0,0,0,0,1,2,1,2,0,0,0,0,1,0,2,1,0,2,0,0,0,0,0,0,0] },
{ answerId: '1.6.3', scoreMatrix: [1,1,1,1,0,0,0,0,0,2,1,2,1,0,0,0,0,2,0,1,2,0,1,0,0,0,0,0,0,0] },
{ answerId: '1.6.4', scoreMatrix: [1,1,1,1,0,0,0,0,0,1,0,1,0,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0] },
{ answerId: '1.7.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.7.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.7.3', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.7.4', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.8.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,0,0,2,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.8.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,1,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.8.3', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,1,1,0,1,0,0,0,0,0,0,0] },
{ answerId: '1.8.4', scoreMatrix: [0,0,0,0,0,0,0,0,0,2,1,2,1,0,0,0,0,1,0,2,1,0,2,0,0,0,0,0,0,0] },
{ answerId: '1.9.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,1,0,0,0,0,0,0,0,0,0] },
{ answerId: '1.9.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,1,0,2,1,0,0,0,0,0,0,0,0] },
{ answerId: '1.9.3', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,1,0,2,1,0,0,0,0,0,0,0,0] },
{ answerId: '1.9.4', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,1,0,2,1,0,0,0,0,0,0,0,0] },
{ answerId: '2.1.1', scoreMatrix: [0,0,0,0,3,2,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.1.2', scoreMatrix: [0,0,0,0,3,2,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.1.3', scoreMatrix: [0,0,0,0,3,2,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.1.4', scoreMatrix: [0,0,0,0,3,2,0,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.1.5', scoreMatrix: [0,0,0,0,3,2,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.1.6', scoreMatrix: [0,0,0,0,3,4,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0,0,0] },
{ answerId: '2.1.7', scoreMatrix: [0,0,0,0,3,5,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0,0,0] },
{ answerId: '2.1.8', scoreMatrix: [0,0,0,0,2,6,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3,3,0,0,0,0,0] },
{ answerId: '2.1.9', scoreMatrix: [0,0,0,0,3,1,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.1.10', scoreMatrix: [0,0,0,0,3,1,0,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.2.1', scoreMatrix: [0,0,0,0,3,2,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.3.1', scoreMatrix: [0,0,0,0,2,4,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,1,1,0] },
{ answerId: '2.3.2', scoreMatrix: [0,0,0,0,2,4,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.3.3', scoreMatrix: [0,0,0,0,2,4,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.4.1', scoreMatrix: [0,0,0,0,1,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.4.2', scoreMatrix: [0,0,0,0,1,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.4.3', scoreMatrix: [0,0,0,0,1,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.4.4', scoreMatrix: [0,0,0,0,1,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.4.5', scoreMatrix: [0,0,0,0,1,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0] },
{ answerId: '2.4.6', scoreMatrix: [0,0,0,0,1,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.4.7', scoreMatrix: [0,0,0,0,2,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.4.8', scoreMatrix: [0,0,0,0,2,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.4.9', scoreMatrix: [0,0,0,0,2,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '2.4.10', scoreMatrix: [0,0,0,0,2,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '3.1.1', scoreMatrix: [0,0,0,0,2,0,1,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.1.2', scoreMatrix: [0,0,0,0,2,0,1,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.1.3', scoreMatrix: [0,0,0,0,2,0,2,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.1.4', scoreMatrix: [0,0,0,0,2,0,3,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.1.5', scoreMatrix: [0,0,0,0,2,0,3,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.1.6', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '3.2.1', scoreMatrix: [0,0,0,0,3,0,1,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0] },
{ answerId: '3.3.1', scoreMatrix: [0,0,0,0,2,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0] },
{ answerId: '3.3.2', scoreMatrix: [0,0,0,0,2,0,3,1,2,0,0,0,0,1,1,1,1,0,0,0,0,0,0,0,0,4,4,0,0,0] },
{ answerId: '3.3.3', scoreMatrix: [0,0,0,0,2,0,4,1,2,0,0,0,0,2,2,2,2,0,0,0,0,0,0,0,0,4,4,0,0,0] },
{ answerId: '3.4.1', scoreMatrix: [0,0,0,0,2,0,4,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0] },
{ answerId: '3.4.2', scoreMatrix: [0,0,0,0,3,0,1,1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.5.1', scoreMatrix: [0,0,0,0,2,0,1,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0] },
{ answerId: '3.5.2', scoreMatrix: [0,0,0,0,1,0,2,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0] },
{ answerId: '3.6.1', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,2,2,6,6,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '3.6.2', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,2,2,7,7,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '3.6.3', scoreMatrix: [0,0,0,0,1,0,5,1,1,0,0,0,0,2,2,0,0,0,0,0,0,0,0,0,0,5,5,0,0,0] },
{ answerId: '3.6.4', scoreMatrix: [0,0,0,0,1,0,2,1,1,0,0,0,0,3,3,4,4,0,0,0,0,0,0,0,0,2,2,0,0,0] },
{ answerId: '3.6.5', scoreMatrix: [0,0,0,0,1,0,3,1,1,0,0,0,0,2,2,5,5,0,0,0,0,0,0,0,0,3,3,0,0,0] },
{ answerId: '3.6.6', scoreMatrix: [0,0,0,0,1,0,5,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,5,5,0,0,0] },
{ answerId: '4.1.1', scoreMatrix: [0,0,0,0,4,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '4.1.2', scoreMatrix: [0,0,0,0,4,0,0,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '4.1.3', scoreMatrix: [0,0,0,0,4,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '4.1.4', scoreMatrix: [0,0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '4.2.1', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,3,3,3,3,0,0,0,0,0,0,0,0,0,0,1,1,0] },
{ answerId: '4.2.2', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,3,3,3,3,0,0,0,0,0,0,0,0,0,0,3,3,4] },
{ answerId: '4.2.3', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,3,3,3,3,0,0,0,0,0,0,0,0,0,0,1,1,2] },
{ answerId: '4.2.4', scoreMatrix: [0,0,0,0,2,0,0,2,1,0,0,0,0,3,3,3,3,0,0,0,0,0,0,0,0,0,0,3,3,2] },
{ answerId: '4.2.5', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,3,3,3,3,0,0,0,0,0,0,0,0,0,0,3,3,0] },
{ answerId: '4.2.6', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,3,3,4,4,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '4.2.7', scoreMatrix: [0,0,0,0,2,0,0,2,1,0,0,0,0,3,3,4,4,0,0,0,0,0,0,0,0,0,0,1,1,0] },
{ answerId: '4.3.1', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,3,3,3,3,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '4.4.1', scoreMatrix: [0,0,0,0,4,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3] },
{ answerId: '4.4.2', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,4,4,4] },
{ answerId: '4.4.3', scoreMatrix: [0,0,0,0,4,0,0,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3] },
{ answerId: '4.4.4', scoreMatrix: [0,0,0,0,4,0,0,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '4.5.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '4.5.2', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,2,2,4,4,0,0,0,0,0,0,0,0,0,0,4,4,1] },
{ answerId: '4.5.3', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,1,1,4,4,0,0,0,0,0,0,0,0,0,0,3,3,2] },
{ answerId: '4.5.4', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,1,1,4,4,0,0,0,0,0,0,0,0,0,0,3,3,2] },
{ answerId: '4.5.5', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,2,2,4,4,0,0,0,0,0,0,0,0,0,0,3,3,1] },
{ answerId: '4.5.6', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,1,1,4,4,0,0,0,0,0,0,0,0,0,0,3,3,2] },
{ answerId: '4.6.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '4.6.2', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,4,4,4,4,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '4.7.1', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,5,5,0] },
{ answerId: '4.7.2', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,5,5,4,4,0,0,0,0,0,0,0,0,0,0,1,1,0] },
{ answerId: '4.7.3', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,2,2,5,5,0,0,0,0,0,0,0,0,0,0,1,1,0] },
{ answerId: '4.7.4', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '4.7.5', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3,3,0] },
{ answerId: '4.7.6', scoreMatrix: [0,0,0,0,1,0,0,1,1,0,0,0,0,1,1,4,4,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '4.7.7', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3,3,0] },
{ answerId: '4.7.8', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2] },
{ answerId: '4.7.9', scoreMatrix: [0,0,0,0,0,0,0,1,1,0,0,0,0,1,1,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '5.1.1', scoreMatrix: [0,0,0,0,2,4,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0,0,0] },
{ answerId: '5.1.2', scoreMatrix: [0,0,0,0,2,1,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '5.1.3', scoreMatrix: [0,0,0,0,3,2,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0,0,0,0,0] },
{ answerId: '5.1.4', scoreMatrix: [0,0,0,0,3,1,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0] },
{ answerId: '5.1.5', scoreMatrix: [0,0,0,0,3,3,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '5.1.6', scoreMatrix: [0,0,0,0,3,3,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '5.1.7', scoreMatrix: [0,0,0,0,3,1,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '5.1.8', scoreMatrix: [0,0,0,0,2,1,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '5.1.9', scoreMatrix: [0,0,0,0,2,1,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,0] },
{ answerId: '5.1.10', scoreMatrix: [0,0,0,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,3,3,5] },
{ answerId: '5.2.1', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,5,5,0] },
{ answerId: '5.2.2', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
{ answerId: '5.2.3', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,1] },
{ answerId: '0.0.0', scoreMatrix: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
]

export default productLogic;
