import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KIDS_THEMES } from '../../../constants/theme.constants';
import iNeedExplorerCategory from '../../../interface/need-explorer-category.interface';
import { getApp } from '../../../store/slices/app.slice';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Utils from '../../../utils/Utils';
import NeedExplorerProgress from './NeedExplorerProgress';

interface Props {

}

const NeedExplorerCategory : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-category');
    const { questions, activeCategory } = useSelector(getNeedExplorer);
    const { ageCategory } = useSelector(getUser);
    const dispatch = useDispatch();

    const [categoryToAnimate, setCategoryToAnimate] = useState<any>();
    const activeAnimation = useRef<any>();
    const { theme } = useSelector(getApp);
    const { generateRandomNumbers, getThemeVariables } = Utils();
    const themes = KIDS_THEMES;
    const [ themeStyle, setThemeStyle ] = useState<any>();

    const { t } =  useTranslation(useSelector(getApp));

    // HOOKS

    useEffect(() => {
        if(!theme || theme.id !== 'multi')return;
        const randomNumbers = generateRandomNumbers(themes.length - 1);
        const randomTheme = themes[randomNumbers[0]];
        setThemeStyle(getThemeVariables(randomTheme))
    }, [])

    useEffect(() => {
        if(!categoryToAnimate || categoryToAnimate.id !== activeCategory.id){
            setCategoryToAnimate(activeCategory);
        }
    }, [activeCategory]);

    // useEffect(() => {
    //     animateActiveCategory();
    // }, [categoryToAnimate])

    // METHODS
    const modiferClass = (category: iNeedExplorerCategory) => {
        const classes = [];
        if(category.isComplete)classes.push('completed');
        if(category.id === activeCategory.id)classes.push('active');
        return classes;
    }

    const gotoCategory = (category: iNeedExplorerCategory, index: number) => {
        if(!category.isComplete)return;
        dispatch(needExplorerSlice.actions.gotoCategory({ category,index }))
    }

    // const animateActiveCategory = () => {
    //     const activeCategoryElement = document.querySelector('.need-explorer-category__item-label--active');
    //     const coords = activeCategoryElement?.getBoundingClientRect();
    //     const width = coords?.width || 0;
    //     const left  = (coords?.left || 0) + (width / 2) - 20;
    //     activeAnimation.current.style.left= `${left}px`;
    //     activeAnimation.current.style.top= `${coords?.top}px`;
    //     activeAnimation.current.style.width= `3rem`;
    //     setTimeout(() => {
    //         activeAnimation.current.style.width= `${coords?.width}px`;
    //         activeAnimation.current.style.left= `${coords?.left}px`;
    //     }, 350);
    // }

    return (
        <div className={B(ageCategory?.value)} style={themeStyle}>
            {/* <div className={E('active-animation')} ref={activeAnimation}></div> */}
            <ul className={E('items')}>

                {
                    questions.map( (category, index) => (
                        <li className={E('item', modiferClass(category))} key={category.id} onClick={() => gotoCategory(category, index)}>
                            <label className={E('item-label', modiferClass(category))}>
                                {t(category.category)}
                            </label>

                            <span className={E('item-arrow', modiferClass(category))}>&gt;</span>
                        </li>
                    ))
                }

            </ul>

            <NeedExplorerProgress />
        </div>
    )
}

export default NeedExplorerCategory;
