import iLensDemo from "../../interface/lens-demo";
import { experioMirrorsDemo } from "./experio-mirrors.constants";
import { experioPolarisedDemo } from "./experio-polarise.constants";
import { experioTintedDemo } from "./experio-tinted.constants";
import { experioTintedMirrorsDemo } from "./experio-tinted-mirrors.constants";
import { stellestDemo } from "./stellest.constants";
import { transitionsStyleMirrorsDemo } from "./transitions-style-mirrors.constants";
import { transitionsXtractiveMirrorDemo } from "./transitions-xtractive-mirrors.constants";
import {experioPolarisedMirrorsDemo} from "./experio-polarised-mirrors.constants"

const PRODUCT_LENS_DEMO: iLensDemo[] = [
    ...experioPolarisedDemo,
    ...experioTintedDemo,
    ...experioMirrorsDemo,
    ...stellestDemo,
    ...experioTintedMirrorsDemo,
    ...transitionsStyleMirrorsDemo,
    ...experioPolarisedMirrorsDemo,
    ...transitionsXtractiveMirrorDemo,
]

export default PRODUCT_LENS_DEMO;