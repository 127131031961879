import React from 'react';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    isKids: boolean,
    disableNext: boolean,
    disableBack: boolean,
    currentQuestion: number,
    numberOfQuestions: number,
    isCurrentQuestionWithAnwer: boolean,
    // Note: list of question index with answers
    fullIndexes: number[], 
    onNavigate: (action: 'back' | 'next') => void
}

const CategoryProgressBar : React.FC<Props>  = ({
    isKids,
    disableBack,
    disableNext,
    currentQuestion,
    numberOfQuestions,
    isCurrentQuestionWithAnwer,
    fullIndexes,
    onNavigate
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('category-progress-bar');

    // HOOKS

    // METHODS
    const barClassModifiers = (index: number) => {
        const modifiers = [];
        if(index === currentQuestion) modifiers.push('current');
        if( 
            index < currentQuestion || 
            ( isCurrentQuestionWithAnwer && index === currentQuestion) ||
            fullIndexes.includes(index)
        ) modifiers.push('full');
        if(isKids) modifiers.push('kids');
            
        return modifiers;
    }

    const generateBars = () => {
        return new Array(numberOfQuestions).fill('').map( (_, index) => (
            <div key={index} className={E('progress-bar', barClassModifiers(index))}>
            </div>
        ))
    }

    const navigate = (dir: 'next' | 'back') => {
        if(dir === 'next'){
            if(disableNext)return;
            onNavigate('next');
            return;
        }
        if(disableBack)return;
        onNavigate('back');
    }

    return (
        <div className={B()}>
            <div className={E('arrows')}>
                <Icon className={E('arrow',['left', disableBack ? 'disabled' : ''])} name="right-arrow-circle" click={() => navigate('back')}/>
                
                <Icon 
                    className={E('arrow',['right', disableNext ? 'disabled' : ''])} 
                    name="right-arrow-circle" 
                    click={() => navigate('next')}/>
            </div>

            <div className={E('progress')}>
                {generateBars()}
            </div>
        </div>
    )
}

export default CategoryProgressBar;