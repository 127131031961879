import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import MyCompanionFooter from '../../../pages/my-companion/components/MyCompanionFooter';
import MyCompanionLeft from '../../../pages/my-companion/components/MyCompanionLeft';
import MyCompanionList from '../../../pages/my-companion/components/MyCompanionList';
import useBEM from '../../../utils/hooks/useBEM';
interface Props {
}

const MyComapanion : React.FC<Props>  = ({
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('loading');
    const dispatch = useDispatch();

    

    // HOOKS

    // METHODS
    return (
            <div className="mycompanion">
                <MyCompanionLeft/>
                <MyCompanionList/>
                <MyCompanionFooter/>
            </div>
    )
}

export default MyComapanion;