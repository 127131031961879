import React, { MutableRefObject, useEffect } from "react"; 

type bemType = {
    listen: () => void, 
    stop: () => void, 
}


const useDragScroll  = (element: HTMLElement ): bemType =>  {
        let draggable = false;
        let startingPoint = 0;
        let timer: any;
        let touchEnabled = window.matchMedia("(pointer: coarse)").matches;

        const onMouseDown = (e:any) => {
            draggable = true;
            startingPoint = e.clientX;
        }

        const onMouseUp = (e:any) => {
            draggable = false;
            startingPoint = 0;
        }

        const onMouseMove = (e:any) => {
            if(!draggable)return;
            const a = (startingPoint - e.clientX);
            clearTimeout(timer);
            timer = setTimeout(() => {
                element.scrollLeft = element.scrollLeft + a;
                startingPoint = e.clientX;
            },50)
        }

        const listen = () => { 
            if(!element || touchEnabled)return;
            stop();
            element.addEventListener('mousedown', onMouseDown);
            element.addEventListener('mouseup', onMouseUp);
            element.addEventListener('mousemove', onMouseMove);
        }

        const stop  = () => {
            if(!element)return;
            element.removeEventListener('mousedown', onMouseDown);
            element.removeEventListener('mouseup', onMouseUp);
            element.removeEventListener('mousemove', onMouseMove);
        }

        return {
            listen,
            stop
        };
}

export default useDragScroll;