import React from 'react'

interface iTransitionAnimation {
    entrance: 'FADE_IN' | 'SLIDE'
}

const withAnimationTransition = (
    WrappedComponent: any, {
        entrance
    }: iTransitionAnimation) => {

    const blockClass = 'with-animation-transition';
    const entranceClass = () => {
        return `${blockClass}--entrance-${entrance}`
    }
    const animateClass = (flag: boolean) => {
        return flag ? `animate` : '';
    }

    class WithAnimationTransition extends React.Component {
        state = {
            animate: false
        };

        componentDidMount(){
            setTimeout(() => {
                this.setState(() => ({
                    animate: true
                }));
            })
        }

        render() {
            return (
                <div className={`${blockClass} ${entranceClass()} ${animateClass(this.state.animate)}`}>
                    <WrappedComponent/>
                </div>
            )
        }
    }

    return WithAnimationTransition;
}

export default withAnimationTransition;
