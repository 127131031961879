import React, { useEffect } from 'react';
import { h3, imageLogo, paragraph } from '../../../constants/email-css.constants';
interface Props {
    
    title: string
    description: string
}

const Header : React.FC<Props>  = ({
    title,
    description
}) =>  {
    //VARIABLES

    //METHOD

    return (
        <div style={{display: "flex"}}>
            <div style={{paddingRight: "10px", paddingLeft: "10px"}}>
                <img  src="https://i.postimg.cc/N05BvVpt/essilorlogo.png" alt="Essilor logo"
                style={imageLogo} width="59"></img>
            </div>
            <div style={{display: "block"}}>
                <h3 style={h3}>
                    <strong>{title}</strong>
                </h3>
                <p style={paragraph}>{description}</p>
            </div>
        </div>
        )
}
export default Header;