import iVariluxProductCard from "../../../../interface/varilux-product-card.interface";
import Button from '../../../../components/ui/Button';
import useBEM from "../../../../utils/hooks/useBEM";
import useTranslation from "../../../../utils/hooks/useTranslation";
import { useSelector } from "react-redux";
import { getApp } from "../../../../store/slices/app.slice";
import { useState } from "react";

const ProductCardVarilux = ({ product, setShowDemo, setPreviewItem, setPreviewItemIndex, index, setActiveIndex }: any) => {
    const item: iVariluxProductCard = product;
    const { tg9 } = useTranslation(useSelector(getApp));
    const [B, E] = useBEM('varilux-demonstrations');
    const [active, setActive] = useState<boolean>(false);
    const [playVideo, setPlayVideo] = useState(false);

    return (
        <>
            <div className={E('product-card') + ' ' + (active ? 'product-card-active' : '')}
                onMouseEnter={() => {setActive(true);setActiveIndex(index)}}
                onMouseLeave={() => {
                    setActive(false);
                    setPlayVideo(false);
                    setActiveIndex(-1)
                }}
            >
                <div className={E('play-background-video')}
                //onClick={() => setPlayVideo(true)}
                ></div>

                <video
                    className={E('background-video' + (playVideo ? ' active' : ''))}
                    autoPlay={true}
                    loop={true}
                    playsInline
                    muted
                    onClick={() => setPlayVideo(false)}
                    style={{ display: playVideo ? 'block' : 'none' }}
                >
                    <source src={item.video} type="video/mp4" />
                </video>
                <img
                    className={E('background' + (item.isExclusive ? ' hallo-effect' : '') + (playVideo ? '' : ' active'))}
                    src={item.image}
                    style={{ display: playVideo ? 'none' : 'block' }}
                    alt="Product"
                />

                {
                    item.isRecommended && (
                        <div className={E('recommended')}>
                            <span className={E('recommended-oval')}></span>
                            <span className={E('recommended-label')}>{tg9('recommended', 'Recommended')}</span>
                        </div>
                    )
                }
                {
                    item.isExclusive && (
                        <span className={E('exclusive')}>{tg9('exclusive', 'Exclusive')}</span>
                    )
                }
                <div className={E('title')}>{tg9(item.title[0], item.title[1])}</div>

                <div 
                    id={item.title[0]}
                    className={E('demo')}
                    onClick={() => {
                        setPlayVideo(false);
                        setPreviewItem(item);
                        setShowDemo(true);
                        setPreviewItemIndex(index);
                        }
                    }
                >
                    <Button
                        text={tg9('demo', 'Demo')}
                        className={E('demo-button')}
                        outlined={false}
                        type='simple'
                        id={item.title[0]}
                    />
                </div>

            </div>
        </>

    )
}

export default ProductCardVarilux;