import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/ui/Button';
import lensThicknessSlice, { getlensThickness } from '../../store/slices/lens-thickness.slice';
import prescriptionSlice from '../../store/slices/prescription.slice';
import useBEM from '../../utils/hooks/useBEM';
import PrescriptionForm from '../prescription/components/PrescriptionForm';
import LensThicknessControl from './components/LensThicknessControl';
import LensThicknessSide from './components/LensThicknessSide';

interface Props {

}

const LensThickness : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-thickness');
    const { view } = useSelector(getlensThickness);
    const dispatch = useDispatch();

    // HOOKS
    useEffect(() => {
        return () => {
            dispatch(lensThicknessSlice.actions.changeView('controls'));
        }
    }, [])

    // METHODS
    const back = () => {
        dispatch(lensThicknessSlice.actions.changeView('controls'));
        dispatch(prescriptionSlice.actions.resetData());
    }

    return (
        <div className={B() + ' page'}>
            
            <LensThicknessSide />

            { view === "controls" && <LensThicknessControl/> }
            
            { view === "prescription" && 
                <div className={E('prescription')}>
                    <Button 
                        text="Back" 
                        icon="left-arrow-circle-gray" 
                        iconPosition="left" 
                        
                        type="secondary"
                        click={back}
                    />
                    <PrescriptionForm/>
                </div>
            }
            

        </div>
    )
}

export default LensThickness;