import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iDeliveryStore from '../interface/delivery-store.interface';
import iRootState from '../interface/root-state.interface';


const initialState: iDeliveryStore  = {
    activeSection: 'prescription',
    scrollView: '',
    isShowSummaryDetails: false,

    isWithInteraction: false
}

const deliverySlice = createSlice({
    name: 'delivery',
    initialState,
    reducers: {
        setActiveSection: (state, action: PayloadAction<string>) => ({
            ...state, 
            activeSection: action.payload
        }),
        scrollToView: (state, action: PayloadAction<string>) => ({
            ...state, scrollView: action.payload
        }),
        showSummaryDetails: (state, action: PayloadAction<boolean>) => {

            const timeStamp = state.validatedTimestamp || new Date().toISOString();


            return {
                ...state, 
                isShowSummaryDetails: action.payload,
                isWithInteraction: true,
                validatedTimestamp: timeStamp
            }
        },
        validate: (state) => {
            const timeStamp = state.validatedTimestamp || new Date().toISOString();

            return {
                ...state,
                isShowSummaryDetails: true,
                activeSection: 'vision_test',
                scrollView:'vision_test',
                isWithInteraction: true,
                validatedTimestamp: timeStamp
            }
        },
        clearValidatedTimestamp: (state) => {
            return {
                ...state,
                validatedTimestamp: undefined
            }
        },
        reset: (state) => ({
            ...state,
            ...initialState
        })
    }
});

export const getDelivery = (state:iRootState) => state.delivery;
export default deliverySlice;