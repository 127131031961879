import React from 'react';
import useBEM from '../../../../utils/hooks/useBEM';
import Utils from '../../../../utils/Utils';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import Islayder from '../../../../components/ui/Islayder';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import NumberSlider from '../../../../components/ui/NumberSlider';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answers: number[]) => void
}

const SliderGroup : React.FC<Props>  = ({
    question,
    onAnswer
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('slider-group');
    const { cloneObject } = Utils();
    const { t } =  useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const handleOnChange = (data: any, index: number) => {
        const newAnswers = cloneObject(question.answer) as number[];
        newAnswers[index] = data;
        if(onAnswer) onAnswer(newAnswers);
    }

    const getSliderValue = (index: number) => {
        return (question.answer as number[])[index];
    }

    return (
        <div className={B()}>
            {
                question.sliders && question.sliders.map( (slider, index) => (
                    <div className={E('slider-box')} key={index}>
                        <label className={E('slider-label')}>
                            {t(slider.label)}
                        </label>

                        <Islayder
                            value={getSliderValue(index)}
                            min={slider.min}
                            max={slider.max}
                            minValue={slider.minValue}
                            onChange={(data) => handleOnChange(data, index)}
                            withIndex={true}
                        />

                        {/* <div className={E('slider-value')}>
                            <span>{slider.min}h</span>
                            <span>{slider.max}h</span>
                        </div> */}
                    </div>
                ))
            }

        </div>
    )
}

export default SliderGroup;
