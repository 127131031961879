import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import Arrow from '../../../components/ui/Arrow';
import anamnesis from '../../../constants/anamnesis.constants';
import NeedExplorerViewType from '../../../types/need-explorer-view.type';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import NeedExplorerAnamnesisDetails from './NeedExplorerAnamnesisDetails';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import { fetchProductDetailsViaId, getAnswerDetails } from '../../../utils/misc';
import useTranslation from '../../../utils/hooks/useTranslation';
import iNeedExplorerSummary from '../../../constants/need-explorer-summary.constant';
import iNeedExplorerSummaryDetails from '../../../interface/need-explorer-summary-details';
import { getProducts } from '../../../store/slices/products.slice';
import { getUser } from '../../../store/slices/user.slice';
import AgeCategoryType from '../../../types/age-category.type';
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes.constant';
import { getApp } from '../../../store/slices/app.slice';
import { icon } from '../../../constants/email-css.constants';
import iNeedExplorerKidsSummary from '../../../constants/need-explorer-summary-kids.constant';
import iNeedExplorerProductsSummary from '../../../constants/need-explorer-summary-products.constant';
import store from '../../../store/store';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import Utils from '../../../utils/Utils';

interface Props {

}

const NeedExplorerAnamnesis: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-anamnesis');
    const dispatch = useDispatch();
    const [showSummary, setShowSummary] = useState<boolean>(false);
    const [result, setResult] = useState<any[]>(anamnesis);
    const currentLensConsultation = useSelector(getLensConsultation);
    const { temporaryProductList } = useSelector(getProducts);
    const { isExtraDone, answers, questions } = useSelector(getNeedExplorer);
    const currentNeedExplorerData = useSelector(getNeedExplorer);
    const { ageCategory } = useSelector(getUser);
    const history = useHistory();
    const { t } = useTranslation(useSelector(getApp));
    const { language } = useSelector(getApp)
    const [summary, setSummary] = useState<iNeedExplorerSummaryDetails[]>(iNeedExplorerSummary);
    const [activePair, setPair] = useState<string>('1');
    const [lens, setLens] = useState<any>();
    const ga = useGoogleAnalytics()
    const { getDateTimeDifference } = Utils();
 
    // HOOKS
    useEffect(() => {
        getNeedExplorerSummary();
        dataPopulate()
    }, [summary])

    useEffect(() => {
        dataPopulate()
    }, [language])

    const getNeedExplorerSummary = () => {
        if (ageCategory?.value !== AgeCategoryType.KIDS) {
            const summary: iNeedExplorerSummaryDetails[] = iNeedExplorerSummary;
            setSummary(summary);
            summary.forEach((q) => {
                q.answer = questions[0].questions.find((ans) => ans.id === q.question)?.answer;
                if (q.question === '1.6' && q.answer.length > 3){
                   q.answer = q.answer.slice(0, q.answer.length - 1)
                }
                 if (q.question === '2.4')return q.answer = questions[1].questions.find((x) => x.id === q.question)?.answer;
            })
            setSummary(summary);
        }
        else {
            const summary: iNeedExplorerSummaryDetails[] = iNeedExplorerKidsSummary;
            setSummary(summary)
            summary.forEach((q) => {
                q.answer = questions[0].questions.find((ans) => ans.id === q.question)?.answer;
            })
            setSummary(summary);
        }
    }
    // METHODS
    const modify = () => {
        dispatch(needExplorerSlice.actions.questionaireFromStart());
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }

    const dataPopulate = () => {
        const { storedLensRecommendation } = currentLensConsultation
        const correctp1 = storedLensRecommendation?.pair1?.correct
        const correctp2 = storedLensRecommendation?.pair2?.correct
        const protectp1 = storedLensRecommendation?.pair1?.protect
        const protectp2 = storedLensRecommendation?.pair2?.protect
        const enhancep1 = storedLensRecommendation?.pair1?.enhance
        const enhancep2 = storedLensRecommendation?.pair2?.enhance

        const  result  = iNeedExplorerProductsSummary;
        result[0].tag = correctp1 ? correctp1.product?.label: undefined
        result[0].benefits = correctp1?.product?.benefits || undefined
        result[1].tag =  correctp2 ? correctp2.product?.label : undefined
        result[1].benefits = correctp2?.product?.benefits || undefined
        result[4].tag = enhancep1 ? t(enhancep1.product?.label) : undefined
        result[4].benefits = enhancep1?.product?.benefits
        result[5].tag = enhancep2 ? t(enhancep2.product?.label) : undefined
        result[5].benefits = enhancep2?.product?.benefits

        if (protectp1 && protectp1.length > 0) {
            result[2].benefits = protectp1[0].product?.benefits
            result[2].tag = protectp1[0].product?.label
        }
        if (protectp2 && protectp2.length > 0) {
            result[3].benefits = protectp2[0].product?.benefits
            result[3].tag = protectp2[0].product?.label
        }
        
        setResult(result)
    }

    const gotoAnamnesis = () => {
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }

    const gotoLPB = () => {
        //Move to Lens Proposal Builder Page
        history.push(routes.lensProposalBuilder.path);

        if(!currentNeedExplorerData.generalQuestionSessionStart)return;
        const timediff = getDateTimeDifference(new Date(currentNeedExplorerData.generalQuestionSessionStart), new Date());
        ga.event({
            category: 'Need Explorer',
            action: 'Need Explorer - Save & Next',
            variable_id: 'need_explorer_completion',
            label: timediff,
        });
    }

    const goBack = () => {
        isExtraDone ? dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.FINISHED)) : dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.GENERAL_CHECKPOINT));

    }

    const onSelectCard = (index: number, lensType: string, icon: string) => {
        // populate id to need explorer state
        const { id, significance } = getValues(index)

        const product = fetchProductDetailsViaId(id, temporaryProductList)

        dispatch(needExplorerSlice.actions.populateSelectedModal(product));
        dispatch(needExplorerSlice.actions.populateSelectedModalSignificance(significance));
        dispatch(needExplorerSlice.actions.populateSelectedModalLens(lensType));
        dispatch(needExplorerSlice.actions.populateSelectedModalLensIcon(icon));
        setLens(lens)
        setShowSummary(true);
    }

    const getAge = () => {
        const { age } = getAnswerDetails(ageCategory?.value === AgeCategoryType.KIDS, answers)

        return age
    }

    const getValues = (index: number) => {
        const { storedLensRecommendation } = currentLensConsultation
        
        // index 0 = correct pair 1
        // index 1 = correct pair 2
        // index 2 = protect pair 1
        // index 3 = protect pair 2
        // index 4 = enhance pair 1
        // index 5 = enhance pair 2

        switch (index) {
            case 0: return { id: storedLensRecommendation?.pair1?.correct.product?.id, significance: storedLensRecommendation?.pair1?.correct.significantAnswers }
            case 1: return { id: storedLensRecommendation?.pair2?.correct.product?.id, significance: storedLensRecommendation?.pair2?.correct.significantAnswers }
            case 2: return { id: storedLensRecommendation?.pair1?.protect[0].product?.id, significance: storedLensRecommendation?.pair1?.protect[0].significantAnswers }
            case 3: return { id: storedLensRecommendation?.pair2?.protect[0].product?.id, significance: storedLensRecommendation?.pair2?.protect[0].significantAnswers }
            case 4: return { id: storedLensRecommendation?.pair1?.enhance.product?.id, significance: storedLensRecommendation?.pair1?.enhance.significantAnswers }
            case 5: return { id: storedLensRecommendation?.pair2?.enhance.product?.id, significance: storedLensRecommendation?.pair2?.enhance.significantAnswers }
            default: return { id: '', significance: null }
        }
    }

    const togglePair = () => {

    }
    return (
        <div className={`${B()} page`}>

            <Button
                click={goBack}
                className={E('back')}
                text={t('demo_demo__back')}
                icon="left-arrow-circle-gray"
                iconPosition="left"
                type="secondary"
                outlineType='none'
            />

            <div className={E('right')}>

                <div className={E('summary')}>

                    <div className={E('summary-wrapper')}>
                        {summary.map((details: iNeedExplorerSummaryDetails, ii: number) =>
                        (
                            details.type === '1' && details.answer &&
                            <div className={E('summary-details')}>

                                <span className={E('summary-tag')}>
                                    <Icon name={details.icon} className={E('summary-icon')}/> 
                                    <label className={E('summary-label')}>{t(details.label)}</label>
                                </span>
                                <h5 className={E('summary-answer')}>
                                    {t(details.answer.label)}
                                </h5>
                            </div>
                        ))}

                        <div className={E('edit')} onClick={modify}>
                            <span>{t('measures_mes__3_edit')}</span>
                            <Icon name="pencil-circle-gray-gray" />
                            {/* <Arrow type="down" color="#2f3b4a" /> */}
                        </div>

                    </div>
                    <div className={E('summary-wrapper')}>
                        {summary.map((details: iNeedExplorerSummaryDetails, ii: number) =>
                        (
                            details.type === '2' && details.answer &&
                            <div className={E('summary-details')}>
                                <span className={E('summary-tag')}>
                                    <Icon name={details.icon} className={E('summary-icon')} />
                                    <label className={E('summary-label')}>{t(details.label)}</label>
                                </span>
                                <div className={E('summary-container')}>
                                    { details.answer && details.answer.map((answer: any, ii: number) =>
                                        <h5 className={E('summary-answer')}>
                                                {t(answer.label)}
                                        </h5>
                                    )}
                                </div>

                            </div>
                        ))}
                    </div>

                </div>

                <div className={E('toggle')}>

                    <Button
                        click={() => setPair('1')}
                        className={activePair === '1' ? E('toggle-active') : E('toggle-button')}
                        text={t('delivery_del_pair_1')}
                        outlineType='none'

                    />
                    <Button
                        click={() => setPair('2')}
                        className={activePair === '2' ? E('toggle-active') : E('toggle-button')}
                        text={t('delivery_del_3_pair_2')}
                        type="secondary"
                        outlineType='none'
                    />
                </div>
                <div className={E('cards')}>

                    {
                        result.map((d, index) => (
                            d.tag && d.pair === activePair &&
                            <div className={E('card')} key={index} >
                                <h3 className={E('card-title')}>
                                    <Icon className={E("card-icon")} name={d.icon} type="image" /> {t(d.lensType)}
                                </h3>
                                <span className={E('card-tag')}>{d.tag}</span>
                                <div className={E('card-wrapper')}>
                                    { d.benefits && d.benefits.map((b: any, ii: number) => (
                                        <div className={E('card-benefits')} key={ii}>
                                            <Icon name={`${b.icon}-blue`} type="png" customStyle={icon} /> 
                                            <label className={E('card-label')}>
                                                {
                                                    (Array.isArray(b.label)) ? t(b.label[0], b.label[1]) : t(b.label)
                                                }
                                            </label> 
                                        </div>
                                    ))}
                                </div>
                                <div className={E('card-info')} onClick={() => onSelectCard(index, d.lensType, d.icon)}>
                                    <span>{t('questionnaires_q_screen_usage_see_ans', 'See the answers')}</span>
                                    <Arrow type="down" color="#2f3b4a" />
                                </div>
                            </div>
                        ))
                    }

                </div>
                <div className={E('button')}>
                    <Button
                        text={t('prescription_rx_save_&_next')}
                        icon={
                            <Arrow
                                type='right'
                                color='#eee'
                            />
                        }
                        iconPosition='right'
                        click={() => gotoLPB()}
                    />

                </div>
                {
                    showSummary &&
                    <NeedExplorerAnamnesisDetails onClose={() => setShowSummary(false)} />
                }

            </div>
        </div>
    )
}

export default NeedExplorerAnamnesis;