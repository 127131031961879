import React from 'react';
import useBEM from '../../utils/hooks/useBEM';
// import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    type?: 'default' | 'light' | 'white',
    bold?: boolean
}

const Text : React.FC<Props>  = ({
    children,
    type = 'default',
    bold = false
}) =>  {
    // VARIABLES
    const [B] = useBEM('text');

    // HOOKS

    // METHODS
    const modifierClass = () => {
        const classes = [];
        if(bold)classes.push('bold');
        classes.push(type);
        return classes;
    }

    return (
        <p className={B(modifierClass())}>{children}</p>
    )
}

export default Text;