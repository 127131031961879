enum ProductDemoType {
    VIDEO = 'video',
    SLIDER_VIDEO = 'slider-video',
    VERTICAL_SLIDER_VIDEO = 'vertical-slider-video',
    DIVIDER_VIDEO = 'divider-video',
    SLIDER_IMAGE = 'slider-image',
    NESTED_DEMO_TRANSVERSAL = 'nested-demo-transversal',
    NESTED_DEMO = 'nested-demo',
    // DRIVE = 'drive',
    // SPHERE_IMAGE = 'sphere-image',
    SEE_THROUGH_VIDEO = 'see-through-video',
    SEE_THROUGH_IMAGE = 'see-through-image',

    SIDE_BY_SIDE_VIDEO = 'side-by-side-video',
}

export default ProductDemoType;