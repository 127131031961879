import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import RouterLink from '../../../components/ui/RouterLink';
import NeedExplorerViewType from '../../../types/need-explorer-view.type';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import { evaluateDecisionTree } from '../../../utils/misc';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import { evaluateKidsDecisionTree } from '../../../utils/KidsDecisionTree';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import Utils from '../../../utils/Utils';
import { getProducts } from '../../../store/slices/products.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import iLensRecommendationPair from '../../../interface/lens-recommendaiton-pair.interface';
interface Props {

}

const NeedExplorerFinished : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-finished');
    const dispatch = useDispatch();
    const currentNeedExplorerData = useSelector(getNeedExplorer);
    const currentLensConsultation = useSelector(getLensConsultation);
    const { temporaryProductList } = useSelector(getProducts);
    const { ageCategory } = useSelector(getUser);
    const ga = useGoogleAnalytics();
    const { getDateTimeDifference } = Utils();
    const { t } =  useTranslation(useSelector(getApp));
    const { accountDetails } =  (useSelector(getApp));
    const country = accountDetails?.shop.country

    // HOOKS
    useEffect(() => {
        return () => {
            saveAllQuestionsSessionDuration();
            saveMoreQuestionsSessionDuration();
        }
    }, [])

    // METHODS
    const modify = () => {
        dispatch(needExplorerSlice.actions.questionaireFromStart());
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.QUESTIONNAIRE));
    }

    const saveAllQuestionsSessionDuration = () => {
        if(!currentNeedExplorerData.generalQuestionSessionStart)return;
        const timediff = getDateTimeDifference(new Date(currentNeedExplorerData.generalQuestionSessionStart), new Date());
        ga.event({
            category: 'Need Explorer',
            action: 'All Q Complete',
            variable_id: 'need_explorer_all_question_complete',
            label: timediff
        });
    }

    const saveMoreQuestionsSessionDuration = () => {
        if(!currentNeedExplorerData.moreQuestionSessionStart)return;
        const timediff = getDateTimeDifference(new Date(currentNeedExplorerData.moreQuestionSessionStart), new Date());
        ga.event({
            category: 'Need Explorer',
            action: '18 Q Complete',
            variable_id: 'need_explorer_18_question_complete',
            label: timediff
        });
        dispatch(needExplorerSlice.actions.generalQuestionSessionStart(undefined))
    }

    const gotoAnamnesis = () => {
        //Save Data and Recommend
        saveDataAndPrefilled()
        //Move to Anamnesis Page
        dispatch(needExplorerSlice.actions.setIsExtraDone(true));
        dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.ANAMNESIS));
    }

    const saveDataAndPrefilled = () => {
        //Check if kids or adults
        const isKids = ageCategory?.value === 'kids'

        //Get recommendation
        const recom = isKids ?
        evaluateKidsDecisionTree(currentNeedExplorerData, temporaryProductList) :
        evaluateDecisionTree(currentNeedExplorerData, false, temporaryProductList, country)

        // Save User Data for Need Explorer
        dispatch(userSlice.actions.saveNeedExplorerData(currentNeedExplorerData));

        //Evaluate Decision Tree to prefilled data in Lens Consultation
        const temp = {
            ...currentLensConsultation,
            lensRecommendation: {
                pair1: recom.pair1,
                pair2: recom.pair2
            },
            storedLensRecommendation: {
                pair1: recom.pair1,
                pair2: recom.pair2
            }
        }

        console.log('RESULT FROM GENERAL & EXTRA', temp)

        dispatch(userSlice.actions.savePrefilledData(temp));
        dispatch(needExplorerSlice.actions.setFinalAnswers(recom.answers))
        dispatch(lensConsultationSlice.actions.setPrefilledDataFromNeedExplorer(recom))
        dispatch(lensConsultationSlice.actions.setPrefilledDataFromNeedExplorerStored(recom))

        dispatch(userSlice.actions.saveLensRecommendationData({
            pair1: recom?.pair1 as iLensRecommendationPair,
            pair2: recom?.pair2 as iLensRecommendationPair
        }));
    }

    return (
        <div className={B()}>

            <div className={E('modal')}>
                <div />

                <div>
                    <div className='icons'>
                        <Icon
                            className='icon'
                            name="check-with-circle"
                            type='svg'
                        />
                        <Icon
                            className='icon'
                            name="check-with-circle"
                            type='svg'
                        />
                    </div>

                    <h2>{t('questionnaires_q_finished')}</h2>

                    <div className='save'>
                        <Button
                            className='btn'
                            text={t('questionnaires_q_save_your_anamnesis')}
                            icon="right-arrow-circle-white"
                            click={gotoAnamnesis}
                            type='secondary'
                        />

                        <RouterLink className='modify' click={modify}>
                            {t('questionnaires_q_modify_my_answers')}
                        </RouterLink>
                    </div>

                    <div className='separator'>
                        <div />
                        <span>OR</span>
                        <div />
                    </div>

                    <div className='buttons'>
                        <Button
                            className='btn'
                            text={t('questionnaires_q_summary')}
                            type="simple"
                            icon="right-arrow"
                            click={gotoAnamnesis}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NeedExplorerFinished;
