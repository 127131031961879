import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import sidebarSlice, { getSidebar } from '../../store/slices/sidebar.slice';
import Menu from './components/menu';
import menus from '../../constants/menu.constant';
import useGoto from '../../utils/hooks/useGoto';
import useTranslation from '../../utils/hooks/useTranslation';
import { getApp } from '../../store/slices/app.slice';

interface Props {

}

const SideBar: React.FC<Props> = () => {
    // VARIABLES
    const { open, selectedMenu } = useSelector(getSidebar);
    const dispatch = useDispatch();
    const goto = useGoto();
    const firstUpdate = useRef(true);
    const { t } = useTranslation(useSelector(getApp));
    // HOOKS
    useEffect(() => {
        dispatch(sidebarSlice.actions.selectMenu({ menu: undefined }));
    }, []);
    useEffect(() => {
        // Note: dont run on intial render
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const route = selectedMenu?.route;
        if (route) {
            goto.route(route);
        }
    }, [selectedMenu]);
    // METHODS
    const closeSideBar = () => {
        dispatch(sidebarSlice.actions.close())
    }
    if (!open) return null;

    return (
        <div className="sidebar">
            <div className="sidebar__blocker" onClick={closeSideBar}></div>
            <div className="sidebar__menu-container">
                {/* MAIN MENU */}
                <Menu items={menus} title={t('my_practice_my_mpractice')} disabled={(selectedMenu && selectedMenu.submenu) ? true : false} />
                {/* SUB MENU */}
                {selectedMenu && selectedMenu.submenu && <Menu items={selectedMenu.submenu} />}
            </div>
        </div>
    )
}

export default SideBar;