import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PRESCRIPTION_RULES, { PRESCRIPTION_FORM, PRESCRIPTION_FORM_VARILUX, PRESCRIPTION_RULES_VARILUX } from '../../../constants/prescription.constants';
import iPrescriptionRule from '../../../interface/prescription-rules.interface';
import { getApp } from '../../../store/slices/app.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import { getUser } from '../../../store/slices/user.slice';
import variluxgG9Slice, { getVariluxG9 } from '../../../store/slices/variluxG9.slice';
import AgeCategoryType from '../../../types/age-category.type';
import PrecisionType from '../../../types/precision.type';
import PrescriptionFieldType from '../../../types/prescription-field.type';
import PrescriptionProductTypeVarilux from '../../../types/prescription-product-varilux.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import PrescriptionInput from './PrescriptionInput';

interface Props {
    parent?: 'lens-consultation',
    readOnly?: boolean,
    use?: 'default' | 'varilux'
}

const PrescriptionForm : React.FC<Props>  = ({
    parent,
    readOnly = false,
    use = 'default'
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription-form');

    const { prescription,ageCategory } = useSelector(getUser);
    const { tempPrescriptionData, valueSign } = useSelector(getPrescription);
    const {prescriptionVarilux} = useSelector(getVariluxG9)
    const [dirtyFields, setDirtyFields] = useState<string[]>([]);
    const dispatch = useDispatch();
    const prescriptionFormFields = use === 'default' ?  PRESCRIPTION_FORM : PRESCRIPTION_FORM_VARILUX;
    const prescriptionRule = use === 'default' ?  PRESCRIPTION_RULES : PRESCRIPTION_RULES_VARILUX;
    const prescriptionRules = useRef(prescriptionRule);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const { t } = useTranslation(appStore);
    const forVarilux = use === 'varilux'
    const tempPrescriptionDataState = forVarilux ? prescriptionVarilux.values : tempPrescriptionData.values
    const [prescriptionValues, setPrescriptionValues] = useState<any>(tempPrescriptionDataState);
    const productSelected = prescriptionVarilux.productSelected
    // HOOKS
    useEffect(() => {

        if(isFirstLoad)return;
        // Note: dont execute on first load on component
        if(!forVarilux){
            updateFormFieldValuesBaseOnPrecision();
        }

    }, [tempPrescriptionData.precision]);

    useEffect(()=>{
        if(forVarilux && productSelected === PrescriptionProductTypeVarilux.NONE){
            setPrescriptionValues({
                sphere: { od: '', os: '' },
                addition: ''
            })
            setDirtyFields([])
        }
    }, [productSelected])

    useEffect(() => {
        if(!tempPrescriptionData.values.sphere.od && !forVarilux){
            prescription.values && setPrescriptionValues(prescription.values);
            changePrecision(prescription.precision)
        }

        setTimeout(() => setIsFirstLoad(false), 250);
    }, [])

    useEffect(() => {
        updatePrescriptionRules();
        
        // Note: Check if prscirption form is valid
        let isValidPrescription = true;
        prescriptionFormFields.forEach( f => {
            const row = f.name;
            f.fields.forEach( (c: any) => {
                const res = validate(row, c.position, true);
                if(!res.valid){
                    isValidPrescription = false;
                    return;
                }
            })
        });

        
        if(!forVarilux){
            dispatch(prescriptionSlice.actions.validPrescription(isValidPrescription))
            dispatch(prescriptionSlice.actions.saveTempPrescriptionData({
                values: prescriptionValues,
                precision: tempPrescriptionData.precision
            }));
            activateLabel();
        }else{
            // varilux prescription
            dispatch(variluxgG9Slice.actions.savePrescription({
                addition:prescriptionValues.addition,
                sphere:prescriptionValues.sphere,
            }))

            const {addition, sphere} = prescriptionValues
            if( addition === '' && sphere.os === '' && sphere.od === ''){
                setDirtyFields([])
            }
        }
        
    }, [prescriptionValues])

    // METHODS
    const activateLabel = () =>{
        if(prescriptionValues && prescriptionValues.sphere.od){
            dispatch(prescriptionSlice.actions.saveValueSign({
                presicription: 'sphere',
                sign: prescriptionValues.sphere.od.charAt(0)
            }));
        }
        if(prescriptionValues && prescriptionValues.cylinder.od){
            dispatch(prescriptionSlice.actions.saveValueSign({
                presicription: 'cylinder',
                sign: prescriptionValues.cylinder.od.charAt(0)
            }));
        }
    }
    const updateFormFieldValuesBaseOnPrecision = () => {
        if(tempPrescriptionData.precision === PrecisionType.AVA)return;

        const sphereOD = incrementValue(prescriptionValues.sphere.od, tempPrescriptionData.precision, 'sphere');
        const sphereOS = incrementValue(prescriptionValues.sphere.os, tempPrescriptionData.precision, 'sphere');
        const cylinderOD = incrementValue(prescriptionValues.cylinder.od, tempPrescriptionData.precision, 'cylinder');
        const cylinderOS = incrementValue(prescriptionValues.cylinder.os, tempPrescriptionData.precision, 'cylinder');

        setPrescriptionValues((prev: any) => ({
            ...prev,
            sphere: { od: sphereOD, os: sphereOS },
            cylinder: { od: cylinderOD, os: cylinderOS },
        }));
    }

    // TODO: create hook for prescription function, include this method
    const incrementValue = (data: number, precission: PrecisionType, fieldName: string) => {
        let rules = prescriptionRules.current[precission][fieldName as PrescriptionFieldType];
        if(rules.increment === 0 || !data)return data;

        // Note: get decimal value
        const isNegative = (data || 0) < 0;
        const fieldValue = Math.abs(data || 0);
        let decimalValue = fieldValue % 1;
        const floorValue = Math.floor(fieldValue);
        const incrementBase = Math.floor(decimalValue / rules.increment) * rules.increment;

        if( decimalValue > 0) {
            const halfOfIncrement = rules.increment / 2;
            const difference = decimalValue - incrementBase;
            let finalValue:any = floorValue +
                            incrementBase +
                        ( halfOfIncrement > difference ? 0 : rules.increment );

           finalValue = rules.validation.requiredSign ? addSign(finalValue, isNegative) : finalValue;

            return finalValue;
        }
        return data;
    }
    const addSign = (data: number, isNegative: boolean) => {
        return `${isNegative ? '-' : '+'}${data}`
    }

    const precisionActiveClass = (value: PrecisionType) => {
        return tempPrescriptionData?.precision === value ? 'active' : ''
    }

    const changePrecision = (value: PrecisionType) => {
        dispatch(prescriptionSlice.actions.setPrescriptionPrecision(value))
    }

    const updatePrescription = (field: string, value: number, position?: string) => {
        const newState = {
            ...prescriptionValues,
            [field]: position ? {
                ...prescriptionValues[field],
                [position]: value
            } : value
        }
        setPrescriptionValues(newState);
        setFieldsDirty(field);
        dispatch(prescriptionSlice.actions.setTemporaryData(newState))
    }

    const setFieldsDirty = (field: string) => {
        if(!dirtyFields.includes(field)){
            let newDirtyFields = [
                ...dirtyFields,
                field
            ];

            // Note: make axis dirty when cylinder has value, vice versa
            if(newDirtyFields.includes('cylinder') && !newDirtyFields.includes('axis'))newDirtyFields.push('axis');
            if(newDirtyFields.includes('axis') && !newDirtyFields.includes('cylinder'))newDirtyFields.push('cylinder');
            setDirtyFields(newDirtyFields);
        }
    }

    const getRules = (precission: PrecisionType, fieldName: string): iPrescriptionRule => {
        let rules = prescriptionRules.current[precission][fieldName as PrescriptionFieldType];
        const partnerFields = ['cylinder', 'axis'];
        if(partnerFields.includes(fieldName)){

            const currentIndex = fieldName === partnerFields[1] ? 1 : 0;
            const currentField = partnerFields[currentIndex];
            if(prescriptionValues[currentField].od || prescriptionValues[currentField].os){
                rules = {
                    ...rules,
                    validation: {
                        ...rules.validation,
                        required: true
                    }
                }
            }

            // Note: update the required validation of cylinder whe axis has value, vice versa
            const partnerIndex = fieldName === partnerFields[1] ? 0 : 1;
            const partnerField = partnerFields[partnerIndex];
            if(prescriptionValues[partnerField].od || prescriptionValues[partnerField].os){
                rules = {
                    ...rules,
                    validation: {
                        ...rules.validation,
                        required: true
                    }
                }
            }
        }
        return rules;
    }

    const isWithSign = (value: any) => {
        const firstCharacter = String(value || '').charAt(0);
        return ['+','-'].includes(firstCharacter);
    }

    const isKids = () => {
        return ageCategory && ageCategory.value === AgeCategoryType.KIDS;
    }

    const validate = (row: string, column: string, ignoreDirtyCondition = false) => {
        const data = getValue(row, column);
        const rules = getRules(tempPrescriptionData.precision, row);
        let result = { valid: true, message: '' }
        let fieldValue = data || 0;

        // Note: remove suffix before validation
        if(rules.suffix)fieldValue = String(fieldValue).replace(rules.suffix, '');

        // Note: validate only when field is dirty
        if(!dirtyFields.includes(row) && !ignoreDirtyCondition) return result;

        if(rules.validation.required && !data && String(data) !== '0'){
            result = { valid: false, message: t('message_messrequired_rx') }
        } else if(
            ( fieldValue && (fieldValue < rules.min || fieldValue > rules.max) ) ||
            isNaN(fieldValue) ||
            // Note: Invalid if with sign (+/-)
            ( !rules.validation.requiredSign && isWithSign(fieldValue) )
        ) {
            result = {  valid: false, message: t('message_mess_invalid_value_rx') }
        }

        return result;
    }

    const getValue = (row: string, column: string) => {
        let field = prescriptionValues[row];
        if(column)field = field[column];
        return field;
    }

    const changeSign = (row:string, sign:string) =>{
        let field = prescriptionValues[row];
        if( prescriptionValues[row].os !== undefined || prescriptionValues[row].od !== undefined){

        setPrescriptionValues({
                ...prescriptionValues,
                [row]: {
                    od: sign+(field.od || '').substring(1),
                    os: sign+((field.os || '').substring(1)),
                }
            });
        }
    }

    const modifier = () => {
        return parent || ''
    }


    // Note: Make sphere required if sphere od or os has a value
    const updatePrescriptionRules = () => {
        if(parent !== 'lens-consultation') return;
        const required = (prescriptionValues.sphere.od || prescriptionValues.sphere.os) ? true : false;
        prescriptionRules.current = {
            ...prescriptionRules.current,
            standard: {
                ...prescriptionRules.current.standard,
                sphere: {
                    ...prescriptionRules.current.standard.sphere,
                    validation: {
                        required,
                        requiredSign: true
                    }
                }
            }
        }
    }


    const validateVariluxAdd = (row:string, column:string) => {
       const data = getValue(row, column)
       const osData = getValue('sphere', 'os')
       const odData = getValue('sphere', 'od')
       if(validate('sphere', 'os').message ===  t('message_messrequired_rx') && 
          !validate('sphere', 'os').valid && !data){
        return validate('sphere', 'os')
       }else if(validate('sphere', 'od').message ===  t('message_messrequired_rx') && 
                !validate('sphere', 'ood').valid && !data){
                    return validate('sphere', 'od')
        }
        if( validate('sphere', 'os').valid &&
            validate('sphere', 'od').valid && 
            osData &&
            odData &&
            !data){ 
            return { valid: false, message: t('message_messrequired_rx')}
        }
        return validate(row, column)
    }

    return (
        <div className={B(experience)}>

            {
                // !readOnly &&
                // <div className={E('precision', ageCategory ? ageCategory.value : '')}>
                //     <button className={ precisionActiveClass(PrecisionType.STANDARD) } onClick={() => changePrecision(PrecisionType.STANDARD)}>
                //         {t('prescription_rx_standard_precision')}
                //     </button>
                //     {/* <button className={ precisionActiveClass(PrecisionType.AVA) } onClick={() => changePrecision(PrecisionType.AVA)}>
                //             {t('prescription_rx_ava_tm_precision')}
                //     </button> */}
                //     {/* {
                //         !isKids() &&
                //         <button className={ precisionActiveClass(PrecisionType.AVA) } onClick={() => changePrecision(PrecisionType.AVA)}>
                //             {t('prescription_rx_ava_tm_precision')}
                //         </button>
                //     } */}

                // </div>
            }

            { prescriptionFormFields.map( (row, index:number) => {
                // Note: hide addition for kids
                if(row.name === 'addition' && isKids())return <span key="-1"></span>;

                return <div className={E('field', [row.name, modifier()])} key={row.name}>
                            <div className={E(`field-header`, modifier()) + ` ${row.name}`}>
                                <label>{t(row.label)}</label>
                                <hr/>
                                {

                                    (row.name === 'sphere' || row.name === 'cylinder') && !readOnly &&
                                    <div className={E(`field-header btn`)}>
                                        <div className={`prescription-btn ${valueSign[row.name] === '-' ? 'active' : ''}` + ` prescription-btn-${use}`}
                                        onClick={()=>changeSign(row.name, '-')}>-</div>
                                        <div className={`prescription-btn ${valueSign[row.name]  === '+' ? 'active': ''}` + ` prescription-btn-${use}`}
                                         onClick={()=>changeSign(row.name, '+')}>+</div>
                                    </div>
                                }

                            </div>

                            {
                                parent != 'lens-consultation' &&
                                <label></label>
                            }
                            

                            { row.fields.map((column: any, index: number) => (
                                <PrescriptionInput
                                    key={index}
                                    readOnly={readOnly}
                                    valid={column.valid}
                                    validation={forVarilux && row.name === 'addition' ? validateVariluxAdd(row.name, column.position) : validate(row.name, column.position)}
                                    rules={getRules(tempPrescriptionData.precision, row.name)}
                                    label={t(column.label)}
                                    value={getValue(row.name, column.position)}
                                    onChange={v => updatePrescription(row.name, v, column.position)}
                                    parent={parent}
                                />
                            )) }
                        </div>
            })}


        </div>
    )
}

export default PrescriptionForm;
