import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import InputText from '../../../components/ui/InputText';
import iMeasurementDataOne from '../../../interface/measurement-one-data.inteface';
import useBEM from '../../../utils/hooks/useBEM';
import { validateMeasures } from '../../../utils/MeasuresValidation';
import { useSelector } from 'react-redux';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import CircleArrow from '../../../components/ui/CircleArrow';
import { getMeasure } from '../../../store/slices/measure.slice';
import useDisableCountry from '../../../utils/hooks/useDisableCountry';

interface Props {
    onSave: (data: iMeasurementDataOne) => void,
    data?: iMeasurementDataOne,
    isKids: boolean
    // experience: ExperienceType
}

const MeasureStepOne : React.FC<Props>  = ({
    onSave,
    data,
    isKids,
    // experience
}) =>  {
    // VARIABLES
    const defaultData = {
        price: {
            value: 0,
            error: false,
            errorMsg: ''
        },
        a:{
            value: 0,
            error: false,
            errorMsg: ''
        },
        b:{
            value: 0,
            error: false,
            errorMsg: ''
        },
        d:{
            value: 0,
            error: false,
            errorMsg: ''
        },
    }
    const [B, E] = useBEM('measures-step');
    const { t } = useTranslation(useSelector(getApp));
    const [localData, setLocalData] = useState<iMeasurementDataOne>(defaultData)
    const { breakdown } = useSelector(getLensConsultation);
    const {activeDataIndex, isIvsGenerated} = useSelector(getMeasure)
    const { accountDetails }= useSelector(getApp);
    const country = accountDetails?.shop.country;
    // const hide = country && ['in', 'in2', 'gr'].includes(country)
    const [ hidePrice ] = useDisableCountry(['gr'])

    // HOOKS
    useEffect(() => {
        setLocalData(data || defaultData)
    }, [data])

    // METHODS
    const onSaveHandler = () => {
        onSave(localData);
    }

    const inputChangeHandler = (e: any, key: string) => {
        let value = e.target.value
        const result = validateMeasures(1,value, key)
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
        if(value === '' || floatRegExp.test(value)){
            setLocalData((prev: any) => {
                return {
                    ...prev,
                    [key]: {
                        value: value ? Number(value) : 0,
                        error: result?.isError,
                        errorMsg: result?.errorMsg
                    }
                }
            })
        }

    }

    const validateCurrentState = () => {
        return localData.price.error === true ||
        localData.a.error === true ||
        localData.b.error === true ||
        localData.d.error === true ||
        localData.a.value === 0 ||
        localData.b.value === 0 ||
        localData.d.value === 0
    }

    const getNextButtonIcon = () => {
        if(isKids)return "right-arrow-circle";
        return <CircleArrow type='right' background='#eee'/>
    }

    return (
        <div className={B()}>

            <div className={E('form', 'one')}>

                {
                    !hidePrice && (
                        <>
                            <label>{t('measures_mes_price')}</label>

                            <div className="price" data-placeholder={breakdown.currency}>
                                <InputText inputType="number" placeholder="Enter the value" type="light" value={String(localData?.price.value)} onChange={(v) => inputChangeHandler(v, 'price')}
            
                                />
                                {localData?.price.error && <span>{t(localData?.price.errorMsg)}</span>}
                            </div>
                        </>
                    )
                }

                <label>{t('measures_mes_a_▭_b')}</label>

                <div className="container-mm" data-placeholder={"mm"}>
                    <InputText inputType="number" placeholder="A" type="light" value={String(localData?.a.value)} onChange={(v) => inputChangeHandler(v, 'a')}/>
                    {localData?.a.error && <span>{t(localData?.a.errorMsg)}</span>}
                </div>

                <div className="container-mm" data-placeholder={"mm"}>
                    <InputText inputType="number" placeholder="B" type="light" value={String(localData?.b.value)} onChange={(v) => inputChangeHandler(v, 'b')}/>
                    {localData?.b.error && <span>{t(localData?.b.errorMsg)}</span>}
                </div>

                <label>{t('measures_mes_d')}</label>

                <div className="d" data-placeholder={"mm"}>
                    <InputText inputType="number" placeholder={t('measures_mes_enter_the_value')} type="light" value={String(localData?.d.value)} onChange={(v) => inputChangeHandler(v, 'd')}/>
                    {localData?.d.error && <span>{t(localData?.d.errorMsg)}</span>}
                </div>
            </div>

            <Button
                className={E('button')}
                text={t('measures_mes_next')}
                icon={getNextButtonIcon()}
                disabled={validateCurrentState()}
                // experience={experience}
                click={onSaveHandler}
            />

        </div>
    )
}

export default MeasureStepOne;
