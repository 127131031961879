import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KIDS_THEMES } from '../constants/theme.constants';
import iTheme from '../interface/theme.interface';
import appSlice, { getApp } from '../store/slices/app.slice';
import useBEM from '../utils/hooks/useBEM';
import Icon from './ui/Icon';

interface Props {

}

const ThemeSetting : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('theme-setting');
    const themes = KIDS_THEMES;
    const { theme } = useSelector(getApp);
    const dispatch = useDispatch();

    // HOOKS

    // METHODS
    const multiColor = (index: number) => {
        if(index !== themes.length)return null;

        return [1,2,3,0].map((i)=>(
            <div key={i} style={{backgroundColor:themes[i].main}}></div>
        ))
    }

    const activeTheme = (themeKey: iTheme) => {
        if(theme.id !== themeKey.id)return null;
        return <Icon name="check-square" className={E('active')}/>
    }

    const changeTheme = (theme: iTheme) => {
        dispatch(appSlice.actions.changeTheme(theme));
    }

    return (
        <ul className={B()}>
            {
                themes.map((item,index) => (
                    <li 
                        onClick={() => changeTheme(item)}
                        className={E('item', item.id)}
                        key={index} style={{
                        backgroundColor: item.main
                    }}>

                        
                        {multiColor(index + 1)}

                        {activeTheme(item)}

                    </li>
                ))
            }
        </ul>
    )
}

export default ThemeSetting;