import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import productDemoSlice, { getProductDemo } from '../../../store/slices/products-demo.slice';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import useBEM from '../../../utils/hooks/useBEM';
import ProductDemo from './ProductDemo';
import ProductDemoSelection from './ProductDemoSelection';
import iProductDemo from '../../../interface/product-demo';
import ProductDemoType from '../../../types/product-demo.type';
import iLensDemo from '../../../interface/lens-demo';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import DrawLine from './DrawLine';
import {useDrag} from 'react-use-gesture'
import {useSpring, animated} from 'react-spring'
import DemoTextOverlay from './DemoTextOverlay';


interface Props {
    type: 'page' | 'modal'
    isChangeDemoTypeLoading?:boolean
}

const ProductDemoCompare : React.FC<Props>  = ({
    type,
    isChangeDemoTypeLoading
}) =>  {
    // VARIABLES
    const dispatch = useDispatch();
    const [B, E] = useBEM('product-demo-compare');
    const { productToDemo, productToCompare, isQrCodeOpen } =  useSelector(getProducts);
    const {
        demoType,
        subDemoType,
        activeProductDemo,
        activeCompareProductDemo,
        syncVideoPlayFlag,
        triggerCenter,
        isCompareEnabled
    } = useSelector(getProductDemo);
    const [sliderValue, setSliderValue] = useState(50);
    const videoRef = useRef<any>();
    const [assetSource, setAssetSource] = useState<string | null>(null);
    const [finalDemoType, setFinalDemoType] = useState<iLensDemo>();
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const screenWidth = window.innerWidth
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const showTextOverLay = finalDemoType?.demo === 'competitor' && isCompareEnabled
    const showTextOverLay2 = finalDemoType?.demo === 'competitor2'
    // HOOKS

    const diamondPos = useSpring<any>({x:0})

    useEffect(() => {
        setSliderValue(50);
    }, [triggerCenter])

    useEffect(() => {
        if(demoType && [
            ProductDemoType.NESTED_DEMO_TRANSVERSAL,
            ProductDemoType.NESTED_DEMO
        ].includes(demoType?.demoType)){
            setFinalDemoType(subDemoType || demoType);
            return;     
        }
        setFinalDemoType(demoType)
    }, [subDemoType, demoType])

    useEffect(() => {
        if(!videoRef?.current)return;
        if(syncVideoPlayFlag >= 2) {
            setTimeout(() => {
                if(videoRef.current === null)return;
                if(videoRef.current.paused)videoRef.current.play();
            }, 1000)
        }
    }, [syncVideoPlayFlag])

    useEffect(() => {

        // Note: if asset is not available call updateVideoPlayFlag to play left video
        if(
            !activeCompareProductDemo?.asset &&
            !isImageDemo()
        )dispatch(productDemoSlice.actions.updateVideoPlayFlag(null));

        if(!videoRef.current || isImageDemo())return;

        videoRef.current.pause();
        videoRef.current.currentTime = 0;    
        setTimeout(() => {
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(null));
        });

    }, [assetSource])
    
    useEffect(() => {
        dispatch(productsSlice.actions.selectProductToCompare(productToDemo));

        return () => {
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(0));
            // back();
        }
    }, [])

    useEffect(() => {
        setSliderValue(50)
        return () => {
            dispatch(productDemoSlice.actions.enableCompare(false))
        }
    }, [demoType?.demo])
    
    useEffect(() => {
        if(isImageDemo())return;
        if(videoRef.current){
            videoRef.current.pause();
            videoRef.current.currentTime = 0;        
        }
    }, [activeProductDemo]);

    const throttle = useRef<any>();
    useEffect(() => {     
        if(throttle.current)clearTimeout(throttle.current); 
        throttle.current = setTimeout(() => {
            getAssetSource();
        }, 100)  

    }, [activeCompareProductDemo, finalDemoType])

    const onSelectProduct = (product: iProductDemo) => {
        dispatch(productDemoSlice.actions.setActiveProductDemo(product));
    }

    const onSelectProductToCompare = (product: iProductDemo | iLensDemo) => {

        if(demoType?.demoType === ProductDemoType.NESTED_DEMO){
            const products = (product as iLensDemo).products as iProductDemo[];
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(0));
            dispatch(productDemoSlice.actions.setSubDemoType((product as iLensDemo))); 
            dispatch(productDemoSlice.actions.setActiveProductDemo(products[1]));
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(products[0]));
            return;
        }

        dispatch(productDemoSlice.actions.setActiveCompareProductDemo(product as iProductDemo));
    }

    const sliderOnChange = (slider: any) => {
        const value = Number(slider.target.value);
        // if(value <= 20 || value >= 80)return;
        setSliderValue(value);
    }

    const getAssetSource = () => {
        setAssetSource(null);
        setTimeout(async () => {
            if(!finalDemoType?.demo || !activeCompareProductDemo?.id)return;
            const id = `${finalDemoType?.demo}_${activeCompareProductDemo?.id}`;
            const asset = await get('lensDemoStore', id);
            if(asset)setAssetSource(asset.asset);            
        },500)
    }
    
    const isImageDemo = () => {
        if(!finalDemoType?.demoType)return false;
        return [
            ProductDemoType.SLIDER_IMAGE
        ].includes(finalDemoType?.demoType)
    }

    const isWithCompareSlider = () => {
        if(
            !finalDemoType?.demoType ||
            (!activeCompareProductDemo?.asset && !finalDemoType?.is360) ||
            finalDemoType?.hideSlider
        )return false;

        return ![
            ProductDemoType.SIDE_BY_SIDE_VIDEO
        ].includes(finalDemoType?.demoType)
    }

    const productSelectionsWidth = (value: number) => {
        if(value > 20 && value < 80)return sliderValue;
        return sliderValue <= 20 ? 20 : 80
    }

    const bindDiamondPos = useDrag((params)=>{
        setSliderValue(sliderValueSetter(params.offset[0], checkOffsetValue(params.offset[0])))
        diamondPos.x.set(Math.abs(params.offset[0]) > ((screenWidth * 0.5) - 30) ? checkOffsetValue(params.offset[0]): params.offset[0])
    })

    const sliderValueSetter = (xValue:number, base:number) =>{
        let percent = ((xValue / base) * 100)/2
        if(percent > 50 || percent < 0){
            percent = 50
        }
        return base < 0 ? 50 - percent : 50 + percent
    }
    const checkOffsetValue = (offset: number) =>{
        return offset < 0 ?  -(((screenWidth * 0.5) - 30)) : (((screenWidth * 0.5) - 30))
    }
    
    return (
        <div className={B([type, finalDemoType?.demoType || ''])}>
            <ProductDemo />  

            
            {
                demoType?.is360 &&
                <div className={E('drawline')} style={{ width: `${sliderValue}%` }}>
                    <div>
                        <DrawLine mode='active'/>
                    </div>                
                </div>
            }

            <div className={E('splitter-view')} style={{ width: `${100 - sliderValue}%` }}>


                {
                    assetSource && 
                    !isImageDemo() && 
                    activeCompareProductDemo?.asset && 
                    
                    <video 
                        ref={videoRef}
                        className={E('to-compare')}
                        playsInline
                        autoPlay={false} loop={true}  muted>
                        <source src={assetSource} type="video/mp4"/> 
                        Your browser does not support the video tag.
                    </video>
                }

                {
                    assetSource && isImageDemo() &&
                    <div className={E('to-compare', finalDemoType?.demoType)}>
                        <img src={assetSource} alt=""/>  
                    </div>
                }

                {
                    demoType?.is360 &&
                    <DrawLine mode='compare'/>
                }

            </div>

            {
                isWithCompareSlider() &&
                <>
                    <div className={E('splitter', demoType?.demoType)} style = {{left:`${sliderValue}%`, backgroundColor: demoType?.demo === 'benefit' ? '#000' : ''}}>
                        {
                            demoType?.demoType !== ProductDemoType.DIVIDER_VIDEO &&
                            <animated.span {...bindDiamondPos()} style={{touchAction:'none',}}></animated.span>
                        }
                    </div>

                    {
                        demoType?.demoType !== ProductDemoType.DIVIDER_VIDEO &&
                        <input 
                        value={sliderValue}
                        onChange={sliderOnChange}
                        type="range"
                            min="0" 
                            max="100"  
                            className={E('slider')}
                            style={{pointerEvents:'none'}}/>
                    }
                </>
            }
                {!isChangeDemoTypeLoading && (showTextOverLay || showTextOverLay2) && 
                    <DemoTextOverlay finalDemoType={finalDemoType?.demo} isCompareEnabled={isCompareEnabled}/>
                }

            <div className={E('product-selections', finalDemoType?.demoType || '')}>
                <div style={{
                    flex: 'none',
                    width:`${ productSelectionsWidth(sliderValue) }%`   
                }}>
                    {
                        productToDemo && activeProductDemo &&
                        <ProductDemoSelection
                            forceDisableSelect={demoType?.demoType === ProductDemoType.NESTED_DEMO}
                            hideDemo={activeCompareProductDemo}
                            selectedDemo={activeProductDemo}
                            onSelectProduct={(p) => onSelectProduct(p as iProductDemo)}
                        />
                    }

                </div>

                {
                    productToCompare && activeCompareProductDemo &&
                    <ProductDemoSelection
                        hideDemo={activeProductDemo}
                        selectedDemo={activeCompareProductDemo}
                        onSelectProduct={(p) => onSelectProductToCompare(p)}
                    />
                }

            </div>
        </div>
    )
}

export default ProductDemoCompare;