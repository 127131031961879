import ReactGA from 'react-ga';
import settings from '../../constants/settings.constants';
import store from '../../store/store';

type bemType = {
    // init: (id: string) => void,
    event: (params: {
        category: string,
        action: string,
        label?: string,
        variable_id?: string,
    }) => void,
    // pageView: (path: string) => void
}


const useGoogleAnalytics  = (): bemType =>  {

        // const defaultParams = () => {
        //     const userId = store.getState().user.id;
        //     const params = {
        //         v: 1, // Version Number
        //         tid: settings[settings.environment].GA, // Tracking ID
        //         cid: userId ? userId : String(new Date().getTime())
        //     }
        //     // return {
        //     //     ...params,
        //     //     ...( userId ? {cid: userId} : {} )
        //     // }
        //     return params
        // }

        // const payloadString = (data: any) => {
        //     return Object.keys(data)
        //     .filter(analyticsKey => data[analyticsKey])
        //     .map(analyticsKey => analyticsKey + '=' + encodeURIComponent(data[analyticsKey]))
        //     .join('&')
        // }

        // const init = (id: string) => {
        //     ReactGA.initialize(id);
        // }

        const event = ({ category,action,label, variable_id }: {
            category: string,
            action: string,
            label?: string,
            variable_id?: string,
        }) => {

            // let payloadData: any = {
            //     ...defaultParams(),
            //     t: 'event', // Hit Type
            //     ec: category, // Event Category
            //     ea: action, // Event Action
            // }

            // if(label){
            //     payloadData = {
            //         ...payloadData,
            //         el: label // Event Label
            //     }
            // }
            const license = store.getState().app.globalInformation.license;

            const newDataLayer = {
                event: 'CustomEvent',
                category,
                action,
                label,
                variable_id,
                license
            }
            const win: any = window;
            win.dataLayer.push(newDataLayer);
            // if(!navigator.onLine){
            //     // save payload data;
            //     return;
            // }

            // Post to Google Analytics endpoint
            // return fetch('https://www.google-analytics.com/collect', {
            //     method: 'post',
            //     body: payloadString(payloadData)
            // }).catch((e) => {
            //     // save payload data;
            // }) 

            // ReactGA.event({
            //     category, action, label,
            // });
        }

        // const pageView = (path: string) => {
        //     let payloadData: any = {
        //         ...defaultParams(),
        //         t: 'pageview', // Hit Type
        //         dp: path
        //     }

        //     return fetch('https://www.google-analytics.com/collect', {
        //         method: 'post',
        //         body: payloadString(payloadData)
        //     }).catch((e) => {
        //         // save payload data;
        //     }) 
        // }
        
        return {
            // init,
            event,
            // pageView
        };
}

export default useGoogleAnalytics;