import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductDemo } from '../../../store/slices/products-demo.slice';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import useBEM from '../../../utils/hooks/useBEM';

interface Props {
    mode: 'active' | 'compare'
}

const DrawLine : React.FC<Props>  = ({
    mode
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('draw-line');
    const [leftBlur, setLeftBlur] = useState<string | undefined>();
    const [leftLine, setLeftLine] = useState<string | undefined>();
    const [rightBlur, setRightBlur] = useState<string | undefined>();
    const [rightLine, setRightLine] = useState<string | undefined>();
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;

    const  { 
        demoType,
        activeProductDemo,
        enableDrawLine,
        activeCompareProductDemo,
        isCompareEnabled
    } = useSelector(getProductDemo);

    // HOOKS
    useEffect(() => {
        if(!activeProductDemo)return;

        const source = mode === 'active' ? activeProductDemo : activeCompareProductDemo;
        let loadAssets = async () => {
            setLeftBlur(await getPreloadedAsset(`${source?.id}_left-blur`));
            setLeftLine(await getPreloadedAsset(`${source?.id}_left-line`));
            setRightBlur(await getPreloadedAsset(`${source?.id}_right-blur`));
            setRightLine(await getPreloadedAsset(`${source?.id}_right-line`));    
        }

        loadAssets();

    }, [activeProductDemo, isCompareEnabled, activeCompareProductDemo])

    // // METHODS
    const getPreloadedAsset = async (id: string) => {
        const asset = await get('lensDemoStore', `${demoType?.demo}_${id}`);
        if(!asset)return undefined;
        return asset.asset;
    }

    return (
        <div className={B(mode)}>
            
            <div className={E('left')}>
                { leftBlur && <img src={leftBlur} alt="" className={E('blur')}/> }
                { leftLine && enableDrawLine && <img src={leftLine} alt="" className={E('line')}/> }
            </div>

            <div className={E('right')}>
                { rightBlur && <img src={rightBlur} alt="" className={E('blur')}/> }
                { rightLine && enableDrawLine && <img src={rightLine} alt="" className={E('line')}/> }
            </div>
        </div>
    )
}

export default DrawLine;