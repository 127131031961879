import { useEffect, useState} from "react";
import useBEM from "../../utils/hooks/useBEM";

const ColoredCircleProgress = ({label, value, index}: any) => {

      const [isNA, setIsNA] = useState(false);
      const [B, E] = useBEM('colored-progress');
    // }
    useEffect(()=>{ 
      (index === 1.74 && value === 0) ? setIsNA(true) :  setIsNA(false);
    },[index, value])

    const dynamicProperty = () => {
      let finalHue = isNA ? 25 : (202 - (value * -1));
      let finalThickness = value * -1;

      if( finalHue < 172 && !isNA) finalHue = 172;
      if( finalThickness > 32 ) finalThickness = 32;

      const additionalHSL: any = isNA ? {
        '--app-sat': '84%',
        '--app-lit': '64%',
      } : {}

      return { 
          '--app-hue': finalHue,
          '--app-thickness': finalThickness,
          ...additionalHSL,
        } as React.CSSProperties
    }

    return (
      <div className={B()} style={dynamicProperty()}>
        <div 
            data-thickness={isNA ? "N/A" : value + "%"}
            className={E('circle', isNA ? 'na' : '')} 
        >
        </div>
        <span className={E('label')}>{label}</span>
      </div>
    );
}
 
export default ColoredCircleProgress;