import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import discoverySlice, { getDiscovery } from '../../../store/slices/discovery.slice';
import useBEM from '../../../utils/hooks/useBEM';

import enhanceLensOuter from './../../../assets/images/discovery/enhance-lens-min.png';
import protectLensOuter from './../../../assets/images/discovery/protect-lens.png';
import correctLensOuter from './../../../assets/images/discovery/correct-lens.png';
import DiscoveryArrows from './DiscoveryArrows';
import LensFamilyType from '../../../types/lens-family.type';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';

interface Props {

}

const DiscoveryLens : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('discovery-lens');
    const dispatch = useDispatch();
    const { selectedLens } = useSelector(getDiscovery);
    const [fadeIn, setFadeIn] = useState(false);
    const lensFamily = ['correct', 'protect', 'enhance'];
    const {t} = useTranslation(useSelector(getApp));
    const translationKey: {
        [key: string]: string
    } = {
        correct: 'discovery_essilor_de_correct',
        protect: 'discovery_essilor_de_protect_',
        enhance: 'discovery_essilor_de_enhance'
    }

    // HOOKS
    useEffect(() => {
        setFadeIn(true);
    }, [])

    // METHODS
    const selectLens = (lens: string) => {
        dispatch(discoverySlice.actions.selectLens(lens as LensFamilyType));
    }

    const showLensClass = (lens: string) => {
        return lens === selectedLens ? 'show' : ''
    }

    const gotoLens = (lens: string) => {
        if(!selectedLens || selectedLens.length < 1)return;
        selectLens(lens);
    }

    const renderArrow = (
        direction: "up" | "down" | "left" | "right", 
        position: 'left' | 'right',
        goto: string
    ) => {
        return (
            <DiscoveryArrows direction={direction} 
                click={() => gotoLens(goto)}             
                className={E('sprite-arrow', position)}/>
        )
    }
    return (
        <div className={B()}>
            <div className={E('background', fadeIn ? 'fadein' : '')}></div>

            <div className={E('lens-wrapper', selectedLens)}>
                <div className={E('lens', 'correct')}>                    
                    <div className={E('sprite', showLensClass('correct'))}> 
                        {renderArrow('right', 'right', 'protect')}
                    </div>
                    <img src={correctLensOuter} alt="" className={E('lens-outline', fadeIn ? 'fadein' : '')}/>
                    <label onClick={() => selectLens('correct')}>
                        {t('discovery_essilor_de_correct')}    
                    </label>          
                </div>
                <div className={E('lens', 'protect')}>
                    <div className={E('sprite', showLensClass('protect'))}>                        
                        {renderArrow('left', 'left', 'correct')}
                        {renderArrow('right', 'right', 'enhance')}
                    </div>
                    <img src={protectLensOuter} alt="" className={E('lens-outline', fadeIn ? 'fadein' : '')}/>
                    <label onClick={() => selectLens('protect')}>
                        {t('discovery_essilor_de_protect_')}
                    </label>            
                </div>
                <div className={E('lens', 'enhance')}>
                    <div className={E('sprite', showLensClass('enhance'))}>
                        {renderArrow('left', 'left', 'protect')}
                    </div>

                    <img src={enhanceLensOuter} alt="" className={E('lens-outline', fadeIn ? 'fadein' : '')}/>
                    <label onClick={() => selectLens('enhance')}>
                        {t('discovery_essilor_de_enhance')}
                    </label>              
                </div>

                <ul className={E('labels', selectedLens)}>
                    {
                        lensFamily.map((item, index) => (
                            <li 
                                className={ (selectedLens === item || !selectedLens) ? 'active' : '' }
                                onClick={() => selectLens(item)} 
                                key={index}>
                                {t(translationKey[item])}
                            </li>
                        ))
                    }
                </ul>

            </div>
        </div>
    )
}

export default DiscoveryLens;