import oceanie from './../assets/images/regions/oceanie.jpg';
import amera from './../assets/images/regions/amera.jpg';
import noam from './../assets/images/regions/noam.jpg';
import europe from './../assets/images/regions/europe.jpg';
import latam from './../assets/images/regions/latam.jpg';
import africa from './../assets/images/regions/africa.jpg'

const regionImages:{
    [key: string]: string
} = {
    oceanie: oceanie,
    amera: amera,
    noam: noam,
    europe,
    latam,
    africa
}



const REGIONS = {
    images: regionImages
}

export default REGIONS;