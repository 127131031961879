import React, { useEffect, useState } from 'react';
import Icon from './ui/Icon';
import Arrow from './ui/Arrow';
import RouterLink from './ui/RouterLink';
import routes from '../constants/routes.constant';
import { useLocation } from 'react-router';
import breadcrumbs from '../constants/breadcrumbs.contant';
import Utils from '../utils/Utils';
import { useSelector } from 'react-redux';
import useTranslation from '../utils/hooks/useTranslation';
import { getApp } from '../store/slices/app.slice';
import useDisableOnVariluxG9 from '../utils/hooks/useDisableOnVariluxG9';

interface Props {
}

const BreadCrumb : React.FC<Props>  = () =>  {
    // VARIABLES
    const location = useLocation();
    const { cloneObject } = Utils();
    const [ activeBreadCrumbs, setActiveBreadCrumbs ] = useState([]);
    const [ activeRoute, setActiveRoute ] = useState(routes.home);
    const { t } = useTranslation(useSelector(getApp));
    const { accountDetails } = useSelector(getApp);
    const [ disableBreadCrumbsOnVarilux ] = useDisableOnVariluxG9(activeRoute?.id)
    
    // HOOKS
    useEffect( () => {
        const clonedbreadcrumbs = cloneObject<any>(breadcrumbs);

        const activeRoute = getActiveRoute(location.pathname);
        setActiveRoute(activeRoute.route);
        setActiveBreadCrumbs(clonedbreadcrumbs[activeRoute.key]);

    }, [location]);

    // METHODS
    const getActiveRoute = (path: string) => {
        const routeKeys = Object.keys(routes);
        const clonedRoutes = cloneObject<any>(routes);
        let activeRouteKey = routeKeys.filter( (k: string) => clonedRoutes[k].path === path)[0];
        return {
            key: activeRouteKey,
            route: clonedRoutes[activeRouteKey]
        };
    }

    return (
        <>
            {!disableBreadCrumbsOnVarilux &&
                <div className={`breadcrumb breadcrumb--${activeRoute.id}`}>
                    <ul>
                        <li className="breadcrumb__item">
                            <RouterLink route={accountDetails ? routes.home : routes.licenseActivation}>
                                <Icon name={activeBreadCrumbs?.length > 0 ? 'home' : 'home-blue'} type="svg" className="breadcrumb__home"/>                        
                            </RouterLink>
                        </li>
                        {
                            activeBreadCrumbs && activeBreadCrumbs.map( (b: any, i) => (
                                <li className="breadcrumb__item" key={i}>
                                    
                                    <Arrow type="right" color="#b3b3b3"/>

                                    <RouterLink route={b}>
                                        {t(b.label)}
                                    </RouterLink>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            }
        </>
    )
}

export default BreadCrumb;