
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iTranslation from '../interface/translation.interface';
import appSlice, { getApp } from '../store/slices/app.slice';
import sidebarSlice, { getSidebar } from '../store/slices/sidebar.slice';
import useBEM from '../utils/hooks/useBEM';
import useTranslation from '../utils/hooks/useTranslation';
import Modal from './Modal';
import Button from './ui/Button';
import { IndexedDBContext, IndexedDBContextType } from '../utils/context/indexedDBContext';
import RegionSelection from './RegionSelection';
import iCountry from '../interface/country.interface';
import iRegion from '../interface/region.interface';


interface Props {

}

type LanguageViewType = 'country' | 'region';

const Language : React.FC<Props>  = () =>  {

    // VARIABLES
    const { selectedMenu, callerId } = useSelector(getSidebar);
    // const { translations } = useSelector(getApp);
    const [translations, setTranslations] = useState<any>(undefined);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showMultiple, setShowMultiple] = useState(false);
    const [B, E] = useBEM('language');
    const { 
        regions, 
        translationRegion,
        translationCountry, 
        translationCountryLanguage,
        accountDetails,
        translation
    } = useSelector(getApp);
    const { t, getTranslations2, generateIconImage, sortCountries } = useTranslation(useSelector(getApp));
    const {get, update} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const [currentRegion, setCurrentRegion] = useState<iRegion | undefined>();
    const online = navigator.onLine;

    const [localTranslation, setLocalTranslation] = useState<any>();
    const [localTranslationCountry, setLocalTranslationCountry] = useState<iCountry | undefined>(translationCountry);    
    const [localTranslationCountryLanguage, setLocalTranslationCountryLanguage] = useState<any>();

    

    const [languageView, setLanguageView] = useState<LanguageViewType>('country');
    const modalSettings = {
        country: {
            title: 'language_lang_select_your_language',
            backButtonText: 'region_language_change_region'
        },
        region: {
            title: 'region_filter',
            backButtonText: 'language_lang__back_'
        }
    }


    const modalStyle = {
        width: "95.4rem",
        height: "69.8rem",
        borderRadius: "15px"
    }
    const popupStyle = {
        width: "50rem",
        height: "30rem",
        borderRadius: "15px"
    }
    

    // HOOKS
    useEffect(() => {
        setLocalTranslation(translation)
    },[translation])
    
    useEffect(() => {
        setLocalTranslationCountry(translationCountry);
    }, [translationCountry])

    useEffect(() => {
        setLocalTranslationCountryLanguage(translationCountryLanguage);
    }, [translationCountryLanguage])

    useEffect(() => {
        setCurrentRegion(translationRegion);
    }, [translationRegion])

    useEffect(() => {
        if(selectedMenu?.id !== 'settings_language')return;

        const getTranslations = async () => {
            const translationData = await get('languageStore', 'translations');
            setTranslations(translationData.value);
            setShow(true);
        }
        getTranslations()

    }, [selectedMenu, translationRegion])


 

    // METHODS

    const onLanguageSelect = (id: string, country:any) => {
        const  languages = translations[id].language;

        const availableTranslations = Object.keys(languages).filter( x => {
            return languages[x] !== null;
        });

        setLocalTranslationCountry({
            id,
            ...country
        })

        //Note: multiple language
        if(availableTranslations.length > 1){                                    
            if(localTranslationCountry?.id !== id){
                setLocalTranslationCountryLanguage(undefined);
            }
            
            setShowMultiple(true);
        } else {

            setLocalTranslation({
                code: availableTranslations[0],
                country: country.label,
                translation: languages[availableTranslations[0]]
            });
            setLocalTranslationCountryLanguage({
                country: id,
                languageCode: availableTranslations[0]
            });

        }    
    }

    const changeLanguage = async () => {
        if(languageView === 'country') {
            dispatch(appSlice.actions.setTranslationCountry(localTranslationCountry as iCountry));
            dispatch(appSlice.actions.setTranslation(localTranslation));
            dispatch(appSlice.actions.setTranslationCountryLanguage(localTranslationCountryLanguage));
            setShow(false);
            dispatch(sidebarSlice.actions.selectMenu({menu: undefined}));

            if(callerId === 'reloadPage'){
                setTimeout(()=> window.location.reload(), 500);
            }
            return;
        }

        // NOTE: Region
        // Dont call api if select the same region
        if(translationRegion?.id === currentRegion?.id) {
            setLanguageView('country');
            return;
        }
        if(!currentRegion)return;

        const {responseData: {data: translationsData}} = await getTranslations2(currentRegion?.id, true);
        if(!translationsData)return;
        await update('languageStore', {
            id: 'translations',
            value: translationsData.country
        });  
        dispatch(appSlice.actions.setTranslationRegion(currentRegion));    
        dispatch(appSlice.actions.setTranslationCountryLanguage(undefined));
  
        setLanguageView('country');
    }

    const modalOnClose = () => {
        if(showMultiple) {
            setShowMultiple(false);
        }
        else{
            dispatch(sidebarSlice.actions.selectMenu({menu: undefined}));
            setLanguageView('country');
            setShow(false);
        }
        
    }


    const disableLanguage = () =>{
        if(languageView === 'country') {
            if(!localTranslationCountryLanguage)return true;
        }
        return false;
    }

    const downloadRegionsData = async (callback: () => void) => {
        const {responseData: {data: translationsData}} = await getTranslations2(accountDetails?.shop.region as string);
        dispatch(appSlice.actions.saveRegions(translationsData.region));
        callback();
    }
    
    const backButtonHandler = async () => {
        const newView = languageView === 'region' ? 'country' : 'region';
        if(newView === 'country'){
            setCurrentRegion(translationRegion);
            
            if((regions || []).length < 0){
                downloadRegionsData(() => setLanguageView(newView));
                return;
            }            
        }
        setLanguageView( newView );
    }

    const isTranslationsAvailable = (key: string, languages: string[]) => {
        const translation = translations[key];
        let available = false;
        if(translation !== undefined) {
            const avalalableTranslationKeys: string[] = [];

            Object.keys(translation.language).forEach( x => {
                if(translation.language[x] !== null) avalalableTranslationKeys.push(x);
            })

            const foundTranslationCount = languages.filter(val => avalalableTranslationKeys.includes(val)).length;
            available = foundTranslationCount > 0;
        } 

        return available;
    }

    const multipleLanguageSelectedClass = (countryId: string, languageCode: string) => {
        return (
            localTranslationCountryLanguage?.languageCode === languageCode &&
            localTranslationCountryLanguage?.country === countryId
        ) ? 'selected' : '';
    }

    const selectCountryLanguage = (key: number) => {
        if(!localTranslationCountry)return;
        const code = localTranslationCountry?.language[key].id;
        const country = translations[localTranslationCountry.id];

        setLocalTranslation({
            code,
            country: country.country,
            translation: country.language[code]
        });
        setLocalTranslationCountryLanguage({
            country: localTranslationCountry.id,
            languageCode: code
        })
        setTimeout( x => setShowMultiple(false), 250);
    }

    return <>
        <Modal 
            show={showMultiple} 
            style={popupStyle} 
            className={B()} 
            onClose={() => modalOnClose()} 
            closeModalOnOutsideClick = {false}   
        >
                <div className={E('modal')}>
                    <div className={E('header')}>
                        <h3>{t('language_lang_select_your_language')}</h3>
                    </div>
                    <div className={E('body multiple')}>
                            <ul>
                                { 
                                    localTranslationCountry && 
                                    localTranslationCountry?.language && 
                                    Object.keys(localTranslationCountry?.language).map((key:any, i:number) => (
                                        <li key={i}>
                                            <Button
                                                text={localTranslationCountry?.language[key].label}       
                                                type="secondary" weight="normal"
                                                iconPosition="left"
                                                outlineType="light"
                                                className={"country-btn " + multipleLanguageSelectedClass(
                                                    localTranslationCountry.id, 
                                                    localTranslationCountry?.language[key].id
                                                )} 
                                                click={() => selectCountryLanguage(key)}
                                            />
                                        </li>
                                    )) 
                                }
                            </ul>               
                    </div>
                </div>
        </Modal>
        <Modal 
            hideCloseButton={ callerId === 'reloadPage' } 
            show={show} 
            style={modalStyle} 
            className={B()} 
            onClose={() => modalOnClose()}
        >
                <div className={E('modal')}>
                    <div className={E('header', languageView)}>
                        <Button                             
                            text={t(modalSettings[languageView].backButtonText)}
                            type="secondary" weight="normal"
                            icon="left-arrow-circle-gray" 
                            iconPosition="left" 
                            click={ backButtonHandler } 
                            disabled={!online}
                        /> 

                        {
                            !online && 
                            <span className={E('offline')}>
                                {t('region_language_offline')}
                            </span>
                        }

                        <h3>{t(modalSettings[languageView].title)}</h3>
                    </div>
                    <div className={E('body')}>

                        { languageView === 'country' && <ul>
                            { translations && translationRegion?.country && sortCountries(translationRegion?.country)
                                .map(([key, value]: any[])=>{
                                return(
                                        <li key={key}>

                                            <Button
                                                disabled={!isTranslationsAvailable(key, value.language.map( (x:any) => x.id))}
                                                text={value.label} 
                                                type="secondary" weight="normal"
                                                icon={generateIconImage(value.label)} 
                                                className={"country-btn "+ (localTranslationCountry?.id === key ? ' selected' : '')}
                                                click={()=>onLanguageSelect(key, value)}
                                                iconPosition="left"
                                                outlineType="light"
                                            />

                                        </li>
                                )
                            }) }
                        </ul> }

                        { 
                            languageView === 'region' && 
                            <RegionSelection 
                                onSelect={setCurrentRegion} 
                                items={regions}
                                currentRegion={currentRegion}
                            /> 
                        }
                        
                    </div>
                    <div className={E('footer')}>
                        <Button 
                            text={t('language_lang_next')} 
                            icon="right-arrow-circle-white" 
                            click={()=>changeLanguage()}
                            disabled={disableLanguage()}
                        />
                    </div>
                </div>                           
        </Modal>
    </>
}

export default Language;