import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/ui/Button';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import variluxProducts from '../../../utils/varilux-products';
import ProductCardVarilux from './demonstrations-varilux/ProductCardVarilux';
import variluxgG9Slice, { getVariluxG9 } from "../../../store/slices/variluxG9.slice";
import ProductCardVariluxModal from './demonstrations-varilux/ProductCardVariluxModal';
import iVariluxProductCard from '../../../interface/varilux-product-card.interface';
import VARILUX_CATEGORY from '../../../constants/variluxG9/varilux-categories';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import Utils from '../../../utils/Utils';
import DEMO_CATEGORY from "../../../constants/variluxG9/varilux-demo-categories";
import variluxG9Videos from '../../../utils/varilux-g9-videos';
import prescriptionOptions from "../../../constants/variluxG9/prescription-options";

const DemonstrationsVarilux = () => {
  const dispatch = useDispatch();
  const { tg9 } = useTranslation(useSelector(getApp));
  const [B, E] = useBEM('varilux-demonstrations');
  const { prescriptionVarilux } = useSelector(getVariluxG9);
  const { productSelected } = prescriptionVarilux;

  const { get, insert } = React.useContext(IndexedDBContext) as IndexedDBContextType;
  const { downloadAssetBase64 } = Utils();
  const {
    needExplorerChoices,
    lensCategorySelected,
    categoryPercentages,
  } = useSelector(getVariluxG9);
  const { g9LanguageSetting } = useSelector(getApp)
  // hooks
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDemo, setShowDemo] = useState<boolean>(false);
  const [iVariluxProducts, setIVariluxProducts] = useState<iVariluxProductCard[]>([]);
  const [previewItem, setPreviewItem] = useState<any>();
  const [previewItemIndex, setPreviewItemIndex] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const loadedCount = useRef(0);
  const totalAssets = 2;
  const nextButtonContainerRef = useRef<HTMLDivElement>(null)

  // constants
  const settings = {
    className: "slider variable-width",
    initialSlide: 0,
    infinite: false,
    centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        }
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          centerMode: false,
        }
      },
      {
        breakpoint: 2048,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 2304,
        settings: {
          slidesToShow: 5,
        }
      },
    ]
  };

  useEffect(() => {
    if (nextButtonContainerRef.current) {
        nextButtonContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [])

  useEffect(() => {
    if (needExplorerChoices.length) {
      if (needExplorerChoices[needExplorerChoices.length - 1].selected) {
        variluxProducts?.map((item) => {
          return item.isRecommended = false;
        })
      } else {
        needExplorerChoices?.map((item: any, i: number) => {
          if (i < variluxProducts.length) {
            variluxProducts[i].isRecommended = item.selected
          }
        })
      }
      setIVariluxProducts(variluxProducts);
    }
    // unsubscribe
    return () => { };
  }, [needExplorerChoices]);

  useEffect(() => {
    // unsubscribe
    setPreviewAssets();
    return () => { };
  }, [lensCategorySelected])

  const setPreviewAssets = () => {
    if (lensCategorySelected) {
      // do apply dynamic video by categoryPercentages for each category
      variluxProducts?.map(async (item: iVariluxProductCard) => {
        const filteredItem = await filterVideoByCategoryPercentage(item.category, item);
        return filteredItem;
      });

    } else {
      // do apply static videos for each category
      variluxProducts?.map((item) => {
        const filteredItem = filterVideoByCategory(item.category, item);
        return filteredItem;
      });
    }
    setIVariluxProducts(variluxProducts);
  }
  const getVideoIndex = (percentage: number) => {
    const index = percentage < 15 ? 1 : percentage < 30 ? 2 : 3;
    return index;
  }

  const filterVideoByCategoryPercentage = async (category: string, item: iVariluxProductCard) => {
    // Logic of filter video by category percentage : TODO
    const resutls: any = categoryPercentages;
    const percentage = resutls[category];
    const videoIndex = getVideoIndex(percentage);
    const demoCategory = getDemoCategory(category);
    const filteredItem = await getPreviewAssets(demoCategory, videoIndex, item);
    return filteredItem;
  }

  const filterVideoByCategory = async (category: string, item: iVariluxProductCard) => {
    // Logic of filter video by category : TODO
    const videoIndex = getVideoIndexByCategory(category);
    const demoCategory = getDemoCategory(category);
    const filteredItem = await getPreviewAssets(demoCategory, videoIndex, item);
    return filteredItem;
  }

  const getPreviewAssets = async (category: string, index: number, item: iVariluxProductCard) => {
    const leftAsset = await get('variluxG9Store', category + '_' + index);
    const rightAsset = await get('variluxG9Store', category + '_0');
    //const Alpha = ["A","B","C","D"];
    if (leftAsset && rightAsset) {
      const leftTitle = prescriptionOptions.find(item => item.value === productSelected);
      //item.previewItems.list[0].title = leftTitle?.label + ' '+ Alpha[index];
      if(leftTitle){
        item.previewItems.list[0].title = tg9(leftTitle.value,leftTitle?.label);
      }
      item.previewItems.list[0].source = leftAsset.asset;
      //item.previewItems.list[1].title = rightAsset.id;
      item.previewItems.list[1].source = rightAsset.asset;
    }
    return item;
  }

  const getDemoCategory = (category: string) => {
    let demoCategory: string = "";
    switch (category) {
      case VARILUX_CATEGORY.INSTANT:
        demoCategory = DEMO_CATEGORY.INSTANT;
        break;
      case VARILUX_CATEGORY.STABILIZED:
        demoCategory = DEMO_CATEGORY.STABILIZED;
        break;
      case VARILUX_CATEGORY.PANORAMIC:
        demoCategory = DEMO_CATEGORY.PANOROMIC;
        break;
      case VARILUX_CATEGORY.SHARP:
        demoCategory = DEMO_CATEGORY.SHARP;
        break;
      case VARILUX_CATEGORY.EXTENDED:
        demoCategory = DEMO_CATEGORY.EXTENDED;
        break;
      default:
        break;
    }
    return demoCategory;
  }

  const loaded = async (data?: any, flag?: boolean) => {
    loadedCount.current += 1;
    if (loadedCount.current >= totalAssets) {
      if (flag) {
        setPreviewAssets();
      }
      setTimeout(() => setIsDownloading(false),500);
    }
  }
  const handlDownloadAsset = async (category: string) => {
    setIsDownloading(true);
    setShowDemo(true);
    const resutls: any = categoryPercentages;
    const videoIndex = resutls ? getVideoIndex(resutls[category]): getVideoIndexByCategory(category);    
    const demoCategory = getDemoCategory(category);
    for (const categoryItem of variluxG9Videos) {
      if (categoryItem.directory === demoCategory) {
        categoryItem?.videos?.map(async (asset, i) => {
          if (i === 0 || i === videoIndex) {
            const isAlreadyDownloaded = await isAlreadyPreloaded(categoryItem.directory, i);
            if (!isAlreadyDownloaded) {
              setIsDownloading(true);
              const base64 = await downloadAssetBase64(asset);
              const itemData = await insert('variluxG9Store', {
                id: `${categoryItem.directory}_${i}`,
                asset: base64,
              });
              await loaded(itemData, true);
            } else {
              await loaded();
            }
          }
        })
      }
    }

  }

  const getVideoIndexByCategory = (category: string) => {
    const prescriptionOption:any = prescriptionOptions.find(item => item.value === productSelected);
    return prescriptionOption?.videoIndex ? prescriptionOption.videoIndex[category]: 0;
  }

  const isAlreadyPreloaded = async (category: string, i: number) => {
    const id = `${category}_${i}`;
    const asset = await get('variluxG9Store', id);
    return asset ? true : false;
  }

  const nextStep = () => {
    dispatch(variluxgG9Slice.actions.changeView('conclusion'));
  }

  const handlControlClick = (index: number) => {
    setPreviewItem(iVariluxProducts[index]);
    setPreviewItemIndex(index)
  }

  const setKoreanHeaderText = () => {
    if (g9LanguageSetting?.country === 'Korea') {
      return (
        <>
          <span className={E('page-title-simple')}>
            다양한 시나리오를
          </span>
          <span className={E('page-title-gradient')}>     
            &nbsp;
            가상체험
            &nbsp;
          </span>
          <span className={E('page-title-simple')}>
            해 보세요.
          </span>
        </>   
      )
    } else {
      return (
        <>
          <span className={E('page-title-simple')}>{tg9('welcome-to-your', 'Welcome to your')} </span><span className={E('page-title-gradient')}>{tg9('personalized-profile', 'personalized profile')}</span>
        </>
      )
    }
  }

  return (
    <div className={B()}>
      <div className={E('page-title')}>
        {/* <span className={E('page-title-simple')}>{tg9('welcome-to-your', 'Welcome to your')} </span><span className={E('page-title-gradient')}>{tg9('personalized-profile', 'personalized profile')}</span> */}
        {setKoreanHeaderText()}
      </div>
      <div className={E('products'+(activeIndex > -1 ? ' fadein-card':''))}>
        <Slider {...settings}>
          {
            iVariluxProducts?.map((item, i) => (
              <ProductCardVarilux
                key={i}
                index={i}
                setActiveIndex={setActiveIndex}
                product={item}
                showDemo={showDemo}
                setShowDemo={() => handlDownloadAsset(item.category)}
                setPreviewItem={setPreviewItem}
                setPreviewItemIndex={setPreviewItemIndex}
              />
            ))
          }
        </Slider>
      </div>
      <br/>
      <br/>
      <br/>
      <div className={E('next-button-container')} ref={nextButtonContainerRef}>
        <Button
          text={tg9('personalize_your_lenses_pyl_next','Next')}
          icon="right-arrow-circle-purple"
          className={E('next-button')}
          outlined={false}
          type='simple'
          click={nextStep}
          id='varilux_demonstration_next_button'
        />
      </div>
      <br/>
      <br/>
      <br/>
      {/** Demo modal */}
      <ProductCardVariluxModal
        showDemo={showDemo}
        setShowDemo={setShowDemo}
        item={previewItem}
        isDownloading={isDownloading}
        index={previewItemIndex}
        setPreviewItem={setPreviewItem}
        handlControlClick={handlControlClick}
      />
    </div>
  )
}

export default DemonstrationsVarilux